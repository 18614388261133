import { combineReducers } from 'redux';
import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual'

// Import custom components
import productReducer from './products';
import slidersReducer from './sliders';
import testimonialReducer from './testimonials'
import categoriesReducer from './categories';
import singleDetailsReducer from './single_details';
import productListReducer from './product_list';
import filterListReducer from './filter_list';
import cartReducer from './cart';
import filtersReducer from './filters';
import wishlistReducer from './wishlist';
import compareReducer from './compare';
import loginDataReducer from './login_data'
// import registerReducer from './register_data';
// Naswiz reducers
import homeDataReducers from './naswiz_get_home'
import userCartReducer from './user_cart'


const rootReducer = combineReducers({
    data: productReducer,
    data_slider: slidersReducer,
    data_testimonialReducer: testimonialReducer,
    categoriesData: categoriesReducer,
    singleDetails: singleDetailsReducer,
    productListData: productListReducer,
    filterListData: filterListReducer,
    cartList: cartReducer,
    filters: filtersReducer,
    wishlist: wishlistReducer,
    compare: compareReducer,
    login: loginDataReducer,
    // registerData: registerReducer,
    homeDataReducers: homeDataReducers,
    cart: userCartReducer,
    Intl
});

export default rootReducer;