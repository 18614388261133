import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'
import Breadcrumb from '../../common/breadcrumb';

class RemunerationSystem extends Component {
   constructor(props) {
      super(props);
      this.state = {
         slugs: props.match.params.id || "unknown",
         items: props.location.state,
         _disc:
            props.location.state != undefined
               ? props.location.state.description
               : "",
         _title: "",
      };
      this.myRef = React.createRef()
   }

   componentDidMount = () => {
      // this.myRef.current.scrollTo(0, 0);
      this.setState({
         _title:
            this.state.items === "" ||
               this.state.items === undefined ||
               this.state.items === "undefined"
               ? ""
               : this.state.items.name,
      });
      // console.log(this.state.items)
   };


   componentWillReceiveProps(nextProps) {
      // this.myRef.current.scrollIntoView();

      this.setState({
         slugs: nextProps.match.params.id,
         items:
            nextProps.location.state === undefined ||
               nextProps.location.state === "undefined"
               ? ""
               : nextProps.location.state,
         _disc:
            nextProps.location.state == undefined ||
               nextProps.location.state === "undefined"
               ? ""
               : nextProps.location.state.description,
      });

      this.setState({
         _title:
            nextProps.location.state == undefined ||
               nextProps.location.state === "undefined"
               ? ""
               : nextProps.location.state.name,
      });
      // window.scrollTo(0, 0)

   }


   render() {
      return (
         <div>

         </div>

      )
   }

}

export default withTranslate(RemunerationSystem);
