import React from 'react'
import { connect } from 'react-redux'
import './innerdashboard.css';
import { Modal, Input, DatePicker, Button, Spin, Radio } from 'antd'
import renderHTML from 'react-render-html';
import { NaswizHelperApi } from '../../../NaswizHelper';
import Notification from '../../../Notification'
import moment from 'moment';
import { Helmet } from 'react-helmet';
import Seo from '../../Seo';



class InnterDashboardNotification extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            arr_notificationData: props.notoficationList,
            viewNotification: false,
            _subject: '',
            _description: '',
            _date: '',
            _viewconunt: '0'
        }
    }


    componentDidMount = async () => {
        this.getNotification()
    }
    componentDidUpdate = async () => {
        await this.props.notoficationList
    }

    getNotification = async () => {

        var res = await NaswizHelperApi.GET_WITH_TOKEN('v1/notification', null, "Error => Customer details api")
        if (res && res.success == 1) {
            await this.setState({ arr_notificationData: res.record })
        }

    }

    getNotificationList = async (id) => {

        var res = await NaswizHelperApi.GET_WITH_TOKEN(`v1/update-notification/${id}`, null, "Error => Customer details api")
        if (res && res.success == 1) {
            await this.setState({ _viewconunt: res.message })
            await this.getNotification();
        }
        // console.log('_viewconunt', this.state._viewconunt)
    }
    DeleteNotification = async (id) => {
        var res = await NaswizHelperApi.GET_WITH_TOKEN(`v1/delete-notification/${id}`, null, "Error => Customer details api")
        // console.log(res);
        if (res && res.success) {
            Notification('success', 'Success!', 'Notification  Deleted');
            await this.getNotification()
        }
    }
    render() {
        return (
            <div className="col-lg-9 order-lg-last dashboard-content">
                {/* <Helmet>
                    <title>Notifications - Naswiz Retails Pvt Ltd</title>
                    <meta name="og_title" property="og:title" content="Notifications - Naswiz Retails Pvt Ltd" />
                    <meta name="twitter:title" content="Notifications - Naswiz Retails Pvt Ltd" />
                    <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                    <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                    <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                    <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                </Helmet> */}
                <Seo title="Notifications" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company."   />
                <div className="notifications_inside">
                    <h2>Notifications</h2>
                    <div className="notifications_list">
                        <ul className="mb-0">
                            {

                                this.state.arr_notificationData.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            {item.subject}
                                            <span>{moment(item.created_at).format("DD-MM-YYYY")}</span>
                                            {item.is_viewed == '0' ?
                                                <button className="btn btn-success" onClick={() => {
                                                    this.getNotificationList(item.id);
                                                    // this._returnOrder(item)

                                                    this.setState({ _subject: item.subject, _description: item.description, _date: item.created_at, viewNotification: true, })
                                                }}><i className="fas fa-eye"></i></button>

                                                :
                                                <div>
                                                    <button className="btn btn-success mx-2" onClick={() => {
                                                        this.setState({ _subject: item.subject, _description: item.description, _date: item.created_at, viewNotification: true, })
                                                    }}><i className="fas fa-eye"></i></button>
                                                    <button className="btn btn-primary continue_btns delete_btns" onClick={() => this.DeleteNotification(item.id)}><i className="far fa-trash-alt"></i></button>
                                                </div>
                                            }


                                            {/* <p>te{item.is_viewed}</p> */}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <Modal
                    title="Notifications"
                    footer={null}
                    visible={this.state.viewNotification}
                    className="Notifications-popup"
                    onCancel={() =>
                        this.setState({ viewNotification: false })}>
                    <div className="notification_head">
                        <h3>{this.state._subject}</h3>
                        <h6>{moment(this.state._date).format("DD-MM-YYYY")}</h6>
                        <p>{renderHTML(String(`${this.state._description}`))} </p>
                    </div>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        allState: state
    }
}

export default connect(mapStateToProps, null)(InnterDashboardNotification)


const ARR_NOTIFICATION = [
    {
        name: "Check Out the new offer Home Care 05% Off",
        time: "Sep, 16 ,2020 08:48 PM"
    },
    {
        name: "Check Out the new offer Home Care 05% Off",
        time: "Sep, 16 ,2020 08:48 PM"
    },
    {
        name: "Check Out the new offer Home Care 05% Off",
        time: "Sep, 16 ,2020 08:48 PM"
    },
    {
        name: "Check Out the new offer Home Care 05% Off",
        time: "Sep, 16 ,2020 08:48 PM"
    },
    {
        name: "Check Out the new offer Home Care 05% Off",
        time: "Sep, 16 ,2020 08:48 PM"
    },
    {
        name: "Check Out the new offer Home Care 05% Off",
        time: "Sep, 16 ,2020 08:48 PM"
    },
    //
    {
        name: "Check Out the new offer Home Care 05% Off",
        time: "Sep, 16 ,2020 08:48 PM"
    },
    {
        name: "Check Out the new offer Home Care 05% Off",
        time: "Sep, 16 ,2020 08:48 PM"
    },
    {
        name: "Check Out the new offer Home Care 05% Off",
        time: "Sep, 16 ,2020 08:48 PM"
    },
    {
        name: "Check Out the new offer Home Care 05% Off",
        time: "Sep, 16 ,2020 08:48 PM"
    },
    {
        name: "Check Out the new offer Home Care 05% Off",
        time: "Sep, 16 ,2020 08:48 PM"
    },
    {
        name: "Check Out the new offer Home Care 05% Off",
        time: "Sep, 16 ,2020 08:48 PM"
    }
]