import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import Slider from 'react-slick';
import { Redirect, Link } from 'react-router-dom';
import Category from './category';
import TopCollection from './top-collection';
import BlogSection from "../common/blogsection";
import LogoBlock from "../common/logo-block";
import MultipleSlider from "./multiple-slider";
import { bindActionCreators } from 'redux';
import HeaderOne from "../../common/headers/header-one";
import FooterOne from "../../common/footers/footer-one";
import { connect } from 'react-redux'
import { getAllCategories, getAllSliders, getAllProducts, getAllTestimonials, getAllHomeData, getWishlistData, getCartData, setMasterCategoryList, setAllMasterCategoryList, setCategoryDataOnHover, getCartDataWithoutLogin } from "../../../actions/index";
import { NaswizHelperApi } from '../../../NaswizHelper';
import Notification from '../../../Notification'
// import { v4 as uuidv4 } from 'uuid';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Seo from '../../Seo';


const InfoSlider = {
    margin: 2,
    items: 3,
    loop: true,
    autoHeight: true,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 2000,
    responsive: {
        0: {
            items: 1,
        },
        576: {
            items: 1,
        },
        992: {
            items: 2,
        },
        1200: {
            items: 3,
        }
    },

}
const options = {
    margin: 20,
    items: 5,
    loop: false,
    autoHeight: true,
    responsiveClass: true,
    nav: false,
    dots: false,
    navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
    autoplay: false,
    smartSpeed: 2000,
    responsive: {
        0: {
            items: 1,
            loop: false,
        },
        576: {
            items: 2,
            loop: false,
        },
        992: {
            items: 3,
            loop: false,
        },
        1200: {
            items: 4,
            loop: false,
        }
    },
}
const home_slider = {
    dots: false,
    infinite: true,
    autoplay: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
}

class Fashion extends Component {

    constructor(props) {
        super(props)
        this.myRef = React.createRef()
        this.state = {
            loading_getHomeData: false,
            arr_social: [],
            arr_slider: [],
            scrollTop: 0,
            arr_category: [],
            arr_product: [],
            arr_feature_product: [],
            arr_best_selling_product: [],
            arr_latest_product: [],
            arr_top_rated_product: [],
            arr_allCategory: [],
            arr_allspecail_promotion: [],
            arr_all_blog_list: [],
            arr_logos: [],
            arr_topbar_cms: [],
            arr_thecompany_cms: [],
            arr_stockpoint_cms: [],
            arr_directseller_cms: [],
            arr_shipping_logo: [],
            arr_testinomial: [],
            call_to_action_1: {},
            call_to_action_2: {},
            call_to_action_3: {},
            uniueId: '',
            masterCategoryList: [],
            allMasterCategoryList: [],
            categoryDataWhenOnHover: [],
            _phone: ''

        }

    }
    onScroll = () => {
        const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
        const scrollTop = this.myRef.current.scrollTop
        this.setState({
            scrollTop: scrollTop
        })
    }
    async generate() {
        var _sym = 'abcdefghijklmnopqrstuvwxyz1234567890';
        var str = '';

        for (var i = 0; i < 20; i++) {
            str += _sym[parseInt(Math.random() * (_sym.length))];
        }
        var uniueId = await localStorage.getItem('deviceId')

        if (uniueId === null) {
            await localStorage.setItem('deviceId', str);
        }
        var uniueId2 = await localStorage.getItem('deviceId')
        this.setState({
            uniueId: uniueId2
        })
    }
    async componentDidMount() {
        await this.generate()
        this.props.getAllCategories();
        this.naswizGetHomeDataApi()
        if (this.props.isUserLogin) {
            var body = new FormData()
            body.append('device_id', this.state.uniueId)
            this.props.getCartData('v1/get-cart-list', body, "Error => GetCartList api.")
        } else {
            var body = new FormData()
            body.append('device_id', this.state.uniueId)
            this.props.getCartDataWithoutLogin('v1/get-cart-list-without-login', body, "Error => get-cart-list-without-login.")
        }

        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);

    }

    naswizGetHomeDataApi = () => {

        var isUserLogin = this.props.isUserLogin
        var userID = this.props.userID

        var url = this.props.isUserLogin ? `v1/get-home/${this.state.uniueId}/1` : `v1/get-home/${this.state.uniueId}/0`

        this.setState({ loading_getHomeData: true }, async () => {

            var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(url, 'Error => v1/get-home')

            if (res && res.success === 1) {
                localStorage.setItem('is_whatsapp', res.records.is_whatsapp + '')
                var phone = res.records.detail.find(item => item.name == 'whatsapp');
                this.setState({
                    arr_topbar_cms: res.records.topbar_cms,
                    arr_thecompany_cms: res.records.thecompany_cms,
                    arr_stockpoint_cms: res.records.stockpoint_cms,
                    arr_directseller_cms: res.records.directseller_cms,
                    arr_social: res.records.detail,
                    arr_shipping_logo: res.records.shipping_logo,
                    arr_slider: res.records.slider,
                    arr_logos: res.records.logos,
                    arr_allspecail_promotion: res.records.specail_promotion,
                    call_to_action_1: res.records.call_to_action_1,
                    call_to_action_2: res.records.call_to_action_2,
                    call_to_action_3: res.records.call_to_action_3,
                    // arr_category: res.records.category,
                    arr_category: res.records.browse_category,
                    arr_allCategory: res.records.allcategories.items,
                    arr_testinomial: res.records.testinomial,
                    arr_all_blog_list: res.records.blog,
                    arr_product: res.records.product,
                    arr_feature_product: res.records.featured_product,
                    arr_best_selling_product: res.records.best_selling_product,
                    arr_latest_product: res.records.latest_product,
                    arr_top_rated_product: res.records.tp_rated_product,
                    loading_getHomeData: false,
                    masterCategoryList: res.records.test_category,
                    allMasterCategoryList: res.records.allcategories.items,
                    _phone: phone.value,
                })


                this.props.setMasterCategoryList(res.records.test_category)
                this.props.setAllMasterCategoryList(res.records.allcategories.items)
            }
            else {
                this.setState({
                    loading_getHomeData: false
                })
            }
            var CategoryDataWhenOnHover = await NaswizHelperApi.GET_WITHOUT_TOKEN('v1/get-category', "Error => CategoryDataWhenOnHover")

            if (CategoryDataWhenOnHover && CategoryDataWhenOnHover.success === 1) {
                this.setState({ categoryDataWhenOnHover: CategoryDataWhenOnHover.records })
                this.props.setCategoryDataOnHover(CategoryDataWhenOnHover.records)
            }

        })
    }
    getTopCollection = () => {
        this.naswizGetHomeDataApi();
        var userID = this.props.userID
        // var url ='v1/get-home/'+this.state.uniueId
        var url = this.props.isUserLogin ? `v1/get-home/${this.state.uniueId}/1` : `v1/get-home//${this.state.uniueId}/0`

        this.setState({ loading_getHomeData: true }, async () => {

            var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(url, 'Error => v1/get-home')

            if (res && res.success === 1) {
                this.setState({
                    arr_product: res.records.product,

                })
            }


        })
    }
    redirectToNextPage = () => {
        return (
            <Redirect to={{ pathname: `${process.env.PUBLIC_URL}/apparels`, state: "apparels" }} />
        )
    }

    render() {
        var { testimonialMain, category_list, All_Data } = this.props
        var { masterCategoryList, allMasterCategoryList, categoryDataWhenOnHover, arr_shipping_logo, arr_thecompany_cms, arr_stockpoint_cms, arr_directseller_cms, arr_slider, arr_call_action_1, arr_call_action_2, arr_allCategory, arr_category, arr_product, arr_feature_product, arr_best_selling_product, arr_latest_product, arr_top_rated_product, arr_social, arr_topbar_cms, call_to_action_3 } = this.state

        return (
            <div className={this.state.scrollTop > 195 ? 'header_active man-wrapper home' : 'man-wrapper'} ref={this.myRef} onScroll={this.onScroll}>

                {/* <Helmet>
                    <title>Naswiz Retails Pvt Ltd</title>
                    <meta name="og_title" property="og:title" content="Naswiz Retails Pvt Ltd" />
                    <meta name="twitter:title" content="Naswiz Retails Pvt Ltd" />
                    <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                    <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                    <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                    <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                </Helmet> */}

                <Seo title="" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />

                {/* header */}
                {/* <HeaderOne
                    logoName={'logo.png'}
                    megaMenuCategoryList={arr_category ? arr_category : []}
                    megaMenuAllCatList={arr_allCategory ? arr_allCategory : []}
                    social_arr={arr_social}
                    topbar_cms={arr_topbar_cms}
                    masterCategoryList={masterCategoryList}
                    allMasterCategoryList={allMasterCategoryList}
                    categoryDataWhenOnHover={categoryDataWhenOnHover}
                /> */}

                <Slider className="show-nav-hover nav-big mb-lg-2 text-uppercase" {...home_slider}>
                    {
                        arr_slider && arr_slider.length > 0 ?

                            arr_slider.map((item, index) => {
                                return (
                                    <div className="home-slide1 banner" key={index}>
                                        <a href={item.link}><img className="slide-bg" src={item.image_full_path} data-src={item.image_full_path} alt="slider image" /></a>
                                    </div>
                                )
                            })

                            : null
                    }
                </Slider>

                <div className="container infoslider_with_icons">

                    <OwlCarousel className='owl-theme nav-outer show-nav-hover nav-image-center mb-lg-2' nav={false} dots={false} {...InfoSlider}>
                        <div className="info-box info-box-icon-left">
                            <div className="border-right-slider">
                                <i className="icon-shipping"></i>
                                <div className="info-box-content">
                                    <h4>Shipping to all major cities</h4>
                                    <p>Free shipping on all orders over ₹1500.</p>
                                </div>
                            </div>
                        </div>

                        <div className="info-box info-box-icon-left">
                            <div className="border-right-slider">
                                <i className="icon-support"></i>

                                <div className="info-box-content">
                                    <h4>ONLINE SUPPORT 24/7</h4>
                                    <p>
                                        <i style={{ fontSize: '14px' }} className="fab fa-whatsapp">
                                            <a href="https://wa.me/+919599283241" target="_blank" className="font1">+91 9599283241</a>
                                        </i>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="info-box info-box-icon-left">
                            <div className="border-right-slider">
                                <i className="icon-tag-line"></i>
                                <div className="info-box-content">
                                    <h4>ASSURED QUALITY</h4>
                                    <p>Premium Quality Products</p>
                                </div>
                            </div>
                        </div>

                        <div className="info-box info-box-icon-left">
                            <div className="border-right-slider">
                                {/* <i className="icon-bag-3"></i> */}
                                <i className=""><img src="../assets/images/bag-icon.png" alt="100% Handpicked Products" /></i>
                                <div className="info-box-content">
                                    <h4>100% Handpicked Products</h4>
                                    <p>You Know You Want It.</p>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>

                </div>
                {
                    this.state.arr_allspecail_promotion.length > 0 ?
                        <section className="bg-gray banners-section text-center">
                            <div className="container py-2 ">
                                <div className="section-title text-center">
                                    <h2>Special Promotions</h2>
                                    <p><span className='bg-gray'>The joy of Getting your Best</span></p>
                                </div>
                                <div className="row">
                                    {
                                        this.state.arr_allspecail_promotion.length > 0 ?
                                            this.state.arr_allspecail_promotion.map((item, index) => {
                                                return (
                                                    // <Link to={{ pathname: `${process.env.PUBLIC_URL}/ProductListing`, state: "apparels" }}>
                                                    <div key={index} className={'col-sm-6 col-lg-3'}>
                                                        <div className="mb-4">
                                                            <a to={item.link} className={'home-banner banner banner-sm-vw'}>
                                                                {/* <Link to={{ pathname: `${process.env.PUBLIC_URL}/ProductListing`, state: "apparels" }}> */}
                                                                <img onClick={() => this.redirectToNextPage()} src={item.image_full_path} alt="" />
                                                                {/* </Link> */}
                                                                <div className={index % 2 === 0 ? index === 0 ? 'banner-layer banner-layer-bottom text-left' : 'banner-layer banner-layer-bottom' : 'banner-layer banner-layer-top'}>

                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    // </Link>
                                                )
                                            }) : null
                                    }

                                </div>
                            </div>
                        </section>
                        : null
                }

                <section className="featured-products-section">
                    <div className="container">
                        <div className="section-title text-center">
                            <h2>Browse Categories</h2>
                            <p><span className=''>Whatever You Want</span></p>
                        </div>
                        <Category
                            category={arr_category ? arr_category : []}
                        />
                    </div>
                </section>
                {
                    Object.keys(this.state.call_to_action_1).length !== 0 ?
                        <section className="promo-section" style={{ backgroundImage: "url(" + this.state.call_to_action_1.image_full_path + ")" }} data-image-src={`${this.state.call_to_action_1.image_full_path}`} >
                            <div className="promo-banner banner container text-uppercase">
                                <div className="banner-content row align-items-center text-center">
                                    <div className="col-md-4 mr-xl-auto text-md-right">
                                        <h4 className="mb-1 coupon-sale-text p-0 d-block ls-10 text-capitalize buy2"><b>Latest Designs</b></h4>
                                        <h5 className="get_fred">
                                            <b> Premium Fabric </b>
                                        </h5>
                                    </div>
                                    <div className="col-md-4 col-xl-3 pb-2 pb-md-0">
                                        <a href={this.state.call_to_action_1 && this.state.call_to_action_1.link ? this.state.call_to_action_1.link : '#'} className="btn btn-black text-white">Naswiz Apparels</a>
                                    </div>
                                    <div className="col-md-4 ml-xl-auto text-md-left">
                                        <h2 className="right-content">
                                            <span>Mens Shirts and Trousers Mens Suits</span>
                                            <p>Women’s Dresses and Tops</p>
                                            <span className="bth_de"> denims </span>
                                        </h2>
                                    </div>

                                </div>
                            </div>
                        </section>
                        : null
                }

                <section className="new-products-section">
                    <div className="container">
                        <div className="section-title text-center">
                            <h2>Featured Products</h2>
                            <p><span>Once You Have It, You Love It</span></p>
                        </div>
                        <TopCollection
                            topCollectionItems={arr_product ? arr_product : []}
                            updateDataAfterAction={() => this.getTopCollection()}

                        />


                        <div style={{ backgroundColor: '#2a95cb', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/banners/banner-4.jpg)` }} className="banner banner-big-sale">
                            <div className="banner-content row align-items-center">
                                <div className="col-md-9">
                                    <h2 className="text-white text-uppercase ls-n-20 mb-2 mb-md-0">
                                        <b className="d-inline-block mr-2 mb-1 mb-md-0 position-relative p-2">Big Sale</b> All New Latest Styles With Upto 25% Off
                                        <small className="text-transform-none align-middle">Online Purchases Only</small>
                                    </h2>
                                </div>
                                <div className="col-md-3 text-center text-md-right mb-0">

                                    {
                                        call_to_action_3 && call_to_action_3.link !== undefined ?
                                            <a href={`${call_to_action_3.link}`} target="_blank" className="btn btn-light">View Sale</a>
                                            :
                                            null
                                    }

                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                {
                    Object.keys(this.state.call_to_action_2).length !== 0 ?
                        <section
                            style={{ backgroundImage: "url(" + this.state.call_to_action_2.image_full_path + ")" }}
                            data-image-src={`${this.state.call_to_action_2.image_full_path}`}
                            className="promo-section"
                            data-parallax="{'speed': 1.8, 'enableOnMobile': true}">
                            <div className="promo-banner banner container text-uppercase">
                                <div className="banner-content row align-items-center text-center">
                                    <div className="col-md-4 ml-xl-auto text-md-right">
                                        <h2 className="mb-md-0 mt20 bg-white-title">Best Shopping Experience</h2>
                                    </div>
                                    <div className="col-md-4 col-xl-3 pb-4 pb-md-0">
                                        <a href={this.state.call_to_action_2 && this.state.call_to_action_2.link ? this.state.call_to_action_2.link : '#'} className="btn btn-dark btn-black ls-10">View Sale</a>
                                    </div>
                                    <div className="col-md-4 mr-xl-auto text-md-left">
                                        <h4 className="mb-1 coupon-sale-text p-0 d-block ls-10 text-transform-none"><b>Exclusive Products</b></h4>
                                        {/* <h5 className="mb-2 coupon-sale-text ls-10 p-0"><i className="ls-0">UP TO </i><b className="bg-secondary">&#x20B9;150</b> OFF</h5> */}
                                    </div>
                                </div>
                            </div>
                        </section>

                        : null
                }

                <div className="blog-section">
                    <div className="container">
                        {
                            this.state.arr_all_blog_list && this.state.arr_all_blog_list.length > 0 ?
                                <div className="section-title text-center">
                                    <h2>Blog</h2>
                                    <p><span>AS Good As It Gets!</span></p>
                                </div>
                                : null
                        }
                        {/* <Slider {...BrandsSlidermew}> */}
                        <div className="blog-slider mb-30 blog">
                            <OwlCarousel className='owl-theme nav-outer show-nav-hover nav-image-center' nav={false} dots={false} {...options}>


                                <div className="slider-items">
                                    <article className="post">
                                        <div className="post-media">
                                            <Link to="/fashion-trends">
                                                <img src="assets/images/blog/home/post-1.jpg" alt="Post" />
                                            </Link>
                                        </div>

                                        <div className="post-body">
                                            <h2 className="post-title text-truncate mb-1 text-truncate">
                                                <Link to="/fashion-trends">Fashion Trends</Link>
                                            </h2>
                                            <div className="post-content">
                                                <p>It’s a wise saying... Fashion is like eating, you shouldn't stick to the same menu. .  The trendiest designs in town are now in stock at Naswiz. The faster you claim, the better you style!</p>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="slider-items">
                                    <article className="post">
                                        <div className="post-media">
                                            <Link to="/fashion-forefront">
                                                <img src="assets/images/blog/home/post-2.jpg" alt="Post" />
                                            </Link>
                                        </div>
                                        <div className="post-body">
                                            <h2 className="post-title text-truncate mb-1">
                                                <Link to="/fashion-forefront">Fashion Forefront</Link>
                                            </h2>
                                            <div className="post-content">
                                                <p>The world is evolving, and so are we.. Make the most modern approach with the Naswiz Range of Male Apparels.</p>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div className="slider-items">
                                    <article className="post">
                                        <div className="post-media">
                                            <Link to="/Herbal-Products">
                                                <img src="assets/images/blog/home/herbal-blog-thumb.jpg" />
                                            </Link>
                                        </div>

                                        <div className="post-body">
                                            <h2 className="post-title text-truncate mb-1">
                                                <Link to="/Herbal-Products">Herbal Products</Link>
                                            </h2>
                                            <div className="post-content">
                                                <p>Ayurveda is an ancient Indian traditional practice of medicine that is believed to be a holistic healing system.</p>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </OwlCarousel>
                        </div>
                        {/* </Slider> */}
                        {/* <hr className="mt-0 m-b-2"></hr> */}
                        <LogoBlock
                            logosliderdata={this.state.arr_logos}
                        />
                        {/* <hr className="mt-0 m-b-5"></hr> */}
                        {/*logo section End*/}
                        <MultipleSlider
                            featureSliderData={this.state.arr_feature_product}
                            bestSellerSliderData={this.state.arr_best_selling_product}
                            latestSliderData={this.state.arr_latest_product}
                            topRatedSliderData={this.state.arr_top_rated_product}
                        />
                    </div>
                </div>
                {/* footer */}
                {/* <FooterOne
                    logoName={'logo.png'}
                    thecompany_cms={arr_thecompany_cms}
                    stockpoint_cms={arr_stockpoint_cms}
                    directseller_cms={arr_directseller_cms}
                    shipping_logo={arr_shipping_logo}
                    social_arr={arr_social}
                /> */}
            </div >
        )


    }
}



const mapStateToProps = (state) => ({
    All_Data: state.data.products,
    testimonialMain: state.data_testimonialReducer.testimonials,
    slidersmain: state.data_slider.sliders,
    category_list: state.categoriesData.categories,
    isUserLogin: state.login.isUserLogin,
    userID: state.login.userID,
    wishlistArray: state.login.userWishlistData.data,
    cartListArray: state.login.userCartData.records.cart_list,
    masterCategoryList: state.login.masterCategoryList,
    allMasterCategoryList: state.login.allMasterCategoryList
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
    // getProductDetails:(slug) => getProductDetails(slug),
    getAllCategories: () => getAllCategories(),
    getWishlistData: (trailUrl, errorMsg) => getWishlistData(trailUrl, errorMsg),
    getCartData: (trailUrl, errorMsg) => getCartData(trailUrl, errorMsg),
    getCartDataWithoutLogin: (trailUrl, body, errorMsg) => getCartDataWithoutLogin(trailUrl, body, errorMsg),
    setMasterCategoryList: (data) => setMasterCategoryList(data),
    setAllMasterCategoryList: (data) => setAllMasterCategoryList(data),
    setCategoryDataOnHover: (data) => setCategoryDataOnHover(data)
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Fashion);


const appColor = '#bd3042'