import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import { Link } from 'react-router-dom';

class FashionForefront extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        return (
            <div className="blog_page">
                <Breadcrumb title={'Fashion Forefront'} />

                {/*Blog Details section*/}
                <section className="blog-details-wrapper allCheckout pb-4">
                    <div className="container">

                        <div className="text-center">
                            <div className="cms-page-title mb-3 line-effect position-relative">
                                <h2 className="d-inline bg-white pl-3 pr-3">FASHION FOREFRONT</h2>
                            </div>
                            <p>Naswiz redefines men’s fashion. From Formal to party look to travel clothes, we have it all. Naswiz apparels is surely a treat for all fashion savvy’s. Don’t let boring typo influence your persona; choose from an eclectic collection of apparels from Naswiz.</p>
                            <img src="assets/images/blog/mens_blog_banner.jpg" alt="mens_blog_banner-img" className="img-fluid mt-3 mb-3" />
                        </div>

                        <div className="blog-inside pt-30 pb-30">
                            <div className="listing-content">
                                <h4>Men’s Suits - For The Self-made</h4>
                            </div>
                            <div className="row mt-md-4 mb-md-4 align-items-center">
                                <div className="col-md-7">
                                    <div className="blog-imgarea position-relative left">
                                        <img src="assets/images/blog/mens_blog_mens-shirt_suits.jpg" alt="mens_blog_mens-shirt_suits-img" className="pb-1 img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="blog-inner-wrapper">
                                        <span className="sub-heading position-relative text-capitalize text-theme-primary">Best Dressed</span>
                                        <blockquote className="font-italic">“Conquer any formal occasion in style with Naswiz’s premium collection in men’s Suits.  This collection has it all that you may need - plains, checks, stripes, and patterns in formal and casual colours. Fetch yourselves a practical and sophisticated style with these perfect fit Men’s suits in premium fabric from Grasim. When its available in Blacks, greys and Blue colours, we would suggest you to get them all, for Naswiz’s uncompeted promise of style and comfort will never let you down. The fits are just perfect for the days when we are heading out for after work drinks on Friday evening or to head for a meeting on Monday Mornings. It’s an absolute hit when you don’t want to be underdressed and appear sloppy, you also don’t want to be overdressed and look like you’re trying too hard.”</blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="blog-inside pt-30 pb-30">
                            <div className="listing-content">
                                <h4>Shirts - Basic Born Buzz</h4>
                            </div>
                            <div className="row mt-md-4 mb-md-4 align-items-center">
                                <div className="col-md-7 order-md-12">
                                    <div className="blog-imgarea position-relative right">
                                        <img src="assets/images/blog/mens_blog_mens-shirt.jpg" alt="mens_blog_mens-shirt-img" className="pb-1 img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-5 order-md-1">
                                    <div className="blog-inner-wrapper">
                                        <span className="sub-heading position-relative text-capitalize text-theme-primary">Alpha-Pret</span>
                                        <blockquote className="font-italic">“Conquer any formal occasion in style with Naswiz’s premium collection in men’s dress shirts. Here we reveal that there’s more to smart than a simple button-up, this collection has it all that you may need - plains, checks, stripes, and patterns. Or ask us about the colors, how hard will it be to make your choice from the black, white, navy, sky blue, purple, off-white, grey, dark purple or blue? Not only that the collection comes in 10 color variations, but is available in two fits as well, regular and slim!”</blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="blog-inside pt-30 pb-30">
                            <div className="listing-content">
                                <h4>Men’s Trousers - Trick Of Trews</h4>
                            </div>
                            <div className="row mt-md-4 mb-md-4 align-items-center">
                                <div className="col-md-6">
                                    <div className="blog-imgarea position-relative left">
                                        <img src="assets/images/blog/mens_blog_mens-shirt_trousers.jpg" alt="mens_blog_mens-shirt_trousers" className="pb-1 img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="blog-inner-wrapper">
                                        <span className="sub-heading position-relative text-capitalize text-theme-primary">Pactful Tacts</span>
                                        <blockquote className="font-italic">“Here are six of the unsung slim fit trouser styles from Naswiz that you might not have considered, but definitely should for their shine, fresh looks and versatility. Here are some of the unsung trouser styles that you might not have considered yet, but definitely should have from Naswiz. Available in black and navy colors, these could be a good piece of work wear that will evidently assert your old-school masculine credentials. With its variety, Naswiz promises the kind of personal attention and care that will make you feel like the best version of awesome you already are. ”
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="blog-inside pt-30 pb-30">
                            <div className="listing-content">
                                <h4>Men’s Casuals T-Shirts / Denims  - Craving The Unconventional</h4>
                            </div>
                            <div className="row mt-md-4 mb-md-4 align-items-center">
                                <div className="col-md-7 order-md-12">
                                    <div className="blog-imgarea position-relative right">
                                        <img src="assets/images/blog/mens_blog_mens-t-shirt.jpg" alt="mens-tshirt-img" className="pb-1 img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-5 order-md-1">
                                    <div className="blog-inner-wrapper">
                                        <span className="sub-heading position-relative text-capitalize text-theme-primary">Style Exponent</span>
                                        <blockquote className="font-italic">“Naswiz brings you T-shirts featuring summer-appropriate motifs each season, at a much more affordable price point. Choose the season’s best from Naswiz’s range of t-shirts for men, all cool and never wrong! Quote: “No matter how you’re built, a t-shirt can make you look more masculine. However, there is a caveat – it has to fit perfectly and highlight the best attributes of your body without looking like a skin-tight workout top.”</blockquote>
                                        <blockquote className="font-italic mt-3">“ Long gone are the days when people would look down on you for wearing jeans to a nice restaurant. Blue jeans look good going to work, doing carpentry, or going out for dinner. You might want to wash them in between, but that same pair of jeans can go just as easily with an evening date or catching up with some friends.”</blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section >

            </div >
        )
    }
}

export default FashionForefront