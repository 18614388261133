import React, { Component, } from 'react';
import Breadcrumb from "../common/breadcrumb";
import { Navigation } from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import { faBell, faCartArrowDown, faCogs, faGift, faHeart, faSignInAlt, faSignOutAlt, faTicketAlt, faTimes, faUser, faUsers, faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect, Link } from 'react-router-dom'
import Modal from 'react-responsive-modal';
import { connect } from "react-redux";
import { NaswizHelperApi } from '../../NaswizHelper';
import BounceLoader from 'react-spinners/BounceLoader'
import { addUserData, removeUserData } from "../../actions/index"; 
import Notification from '../../Notification'
import { Input, DatePicker } from 'antd'
import moment from 'moment'
// 
import InnerDashboardMyOrders from './InnterDashboardComponents/ID_MyOrders'
import InnterDashboardIncentivePoint from './InnterDashboardComponents/ID_IncentivePoints'
import InnerDashboardOrderIssueView from './InnterDashboardComponents/ID_OrderIssueView'
import InnterDashboardNotification from './InnterDashboardComponents/ID_Notification'
import InnerDashboardReferFriends from './InnterDashboardComponents/ID_ReferFriend'
import InnerDashboardMyWallet from './InnterDashboardComponents/ID_MyWallet'
import InnerDashboardMyCart from './InnterDashboardComponents/ID_MyCart'
import { Helmet } from 'react-helmet';
import Seo from '../Seo';


class ProfileDashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            // activeView: 'My Profile',
            activeView: 'My Address',
            // activeView: 'Edit Profile',
            show: false,
            showProfile: true,
            arr_userAddress: [],
            userName: '',
            userPhone: '',
            userPincode: '',
            userAddress: '',
            userAddressType: '',
            loading_userAddress: undefined,
            // User Address Enter
            idNo: '',
            name: '',
            address: '',
            arr_country: [],
            arr_city: [],
            arr_state: [],
            pincode: '',
            mobileNumber: '',
            aadharNumber: '',
            gstNumber: '',
            addType: undefined,
            selectedCountryId: undefined,
            selectedStateId: undefined,
            selectedCityId: undefined,
            loading_addNewUserAddress: false,
            selectedFile: null,
            // Edit profile states
            edited_name: '',
            edited_email_id: '',
            edited_dob: '',
            edited_gender: undefined,
            edited_marital_status: '',
            edited_anniversary_date: '',
            edited_profile_image: null,
            edited_aadhar_card_image: null,
            edited_pan_card_image: null,
            openEdit: false,
            // Edit address states
            edit_address_object: {},
            isEditAddressObject: false,
            edit_address_name: '',
            edit_address_address: '',
            edit_address_pincode: '',
            edit_address_addressType: undefined,
            // Store Issue Modual
            arr_store_ticket: [],
            arr_storeList: [],
            store_id: '',
            store_visitedDate: '',
            store_subject: '',
            store_description: '',
            store_image: null,
            arr_notificationData: props.notoficationList,
            viewNotification: false,
            goToDashboard:false,
            _viewconunt: '',
        }
    }

    componentDidMount = () => {

    this.getNotificationList();

        if (this.props.location.itemviewname != undefined) {
            this.setState({ activeView: this.props.location.itemviewname })
        }
        else {
            this.setState({ activeView: 'My Address' })
        }
      /*   document.getElementById("common").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/common.css`);*/
        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000); 

        if(!this.props.isUserLogin ){

            this.setState({goToDashboard:true})
            return
        }
        this._initApi()
        

        if (this.props.location && this.props.location.state && this.props.location.state === 'show_cart_tab') {
            this.setState({ activeView: ListOfViews.MyCart })
        }
        this.setProfileData()
    }


    getNotificationList = async () => {

        var res = await NaswizHelperApi.GET_WITH_TOKEN(`v1/notification`, null, "Error => Customer details api")
        if (res && res.success == 1) {
            await this.setState({ _viewconunt: res.count })
            // window.location.reload(false);
        }
        // console.log('_viewconunt', this.state._viewconunt)
    }

    componentWillReceiveProps(nextProps) { 
        if (nextProps.location.itemviewname != undefined) {
            this.setState({ activeView: nextProps.location.itemviewname })
        }
        else {
            this.setState({ activeView: 'My Address' })
        }
     
    }
    setProfileData() {
        var image_full_path = this.props.userData && this.props.userData.image_full_path ? this.props.userData.image_full_path : `${process.env.PUBLIC_URL}/assets/images/nia.png`
        var aadhar_image_full_path = this.props.userData && this.props.userData.aadhar_image_full_path ? this.props.userData.aadhar_image_full_path : `${process.env.PUBLIC_URL}/assets/images/nia.png`
        var pan_image_full_path = this.props.userData && this.props.userData.pan_image_full_path ? this.props.userData.pan_image_full_path : `${process.env.PUBLIC_URL}/assets/images/nia.png`



        this.setState({
            edited_name: this.props.userData.name, edited_email_id: this.props.userData.email, edited_dob: this.props.userData.dob,
            edited_anniversary_date: this.props.userData.dob_anniversary, edited_gender: this.props.userData.gender, edited_marital_status: this.props.userData.marital,
        })
  
    }

    _initApi = () => {
        // Address
        this._getCountry()
        this._customerDetails()
        this._getAllUserAddressApi()
        // Address

        // Store
        this._storeIssueModual()
        // Store
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };
    onCloseModal = () => {
        this.setState({ open: false });
    };
    onEditShow = () => {
        this.setState({ activeView: 'Edit Profile' })
    }

    onOpenEditModal = (item, index) => {
        this.setState({
            openEdit: true,
            edit_address_name: item.fullname,
            edit_address_address: item.address,
            edit_address_pincode: item.pincode,
            edit_address_addressType: item.is_status,
            selectedCountryId: item.country_id,
            selectedStateId: item.state_id,
            selectedCityId: item.city_id,
            edit_address_object: item,
            isEditAddressObject: true,
        });

        if (item.country_id != '' && item.country_id != undefined) {
            this._getStateUponCountry(item.country_id)
        }
        if (item.state_id != '' && item.state_id != undefined) {
            this._getCityUponState(item.state_id)
        }
    };


    _getAllUserAddressApi = () => {

        this.setState({ loading_userAddress: true }, async () => {

            var res = await NaswizHelperApi.GET_WITH_TOKEN('v1/view-address', 'Error => v1/view-address')
     
            if (res && res.success == 1) {
                this.setState({
                    loading_userAddress: false,
                    arr_userAddress: res.message
                })
            }
            else {
                this.setState({ loading_userAddress: false })
            }
        })
    }

    _deleteUserAddress = async (id, index) => {

        var body = new FormData()
        body.append('id', id)
        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/delete-address', body, 'Error => Delete user address api')
 
        if (res && res.success == 1) {
            var tempUserAddress = this.state.arr_userAddress.filter((x, i) => i !== index)
            setTimeout(() => {
                this.setState({
                    arr_userAddress: tempUserAddress
                })
            }, 1000)
        }
    }

    _onChangeText = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    _onChangeTextEditProfile = (e) => {  
        this.setState({
            [e.target.id]: e.target.value
        })

    }

    _getCountry = async () => {

        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/countries', null, "Error => Get country api")
  
        if (res && res.success == 1) {
            this.setState({
                arr_country: res.message
            })
        }
    }

    _getStateUponCountry = async (cId) => {
   
        if (cId === 'Select Country') {
            this._resetCountryField()
        }
  
        else {

            this.setState({ selectedCountryId: cId })

            var formData = new FormData()
            formData.append('country_id', cId)

            var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/state', formData, "Error => Get state upon county api")
      
            if (res && res.success == 1) {
                this.setState({
                    arr_state: res.message
                })
            }
        }
    }

    _getCityUponState = async (sId) => {

        this.setState({ selectedStateId: sId })

        var formData = new FormData()
        formData.append('state_id', sId)
        formData.append('country_id', this.state.selectedCountryId)    

        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/cities', formData, "Error => Get state upon county api")
   
        if (res && res.success == 1) {
            this.setState({
                arr_city: res.message
            })
        }
    }

    _addNewAddress = async () => {

        var { idNo, name, address, mobileNumber, aadharNumber, gstNumber, addType, selectedCountryId, selectedStateId, selectedCityId, pincode, selectedFile } = this.state
     
        if (idNo === '') {
            Notification('error', 'Error!', 'Please enter ID No. !')
        }
        else if (idNo.length < 5) {
            Notification('error', 'Error!', 'Please enter valid ID No. !')
        }
        else if (name === '') {
            Notification('error', 'Error!', 'Please enter name !')
        }
        else if (name.length < 3) {
            Notification('error', 'Error!', 'Please enter valid name !')
        }
        else if (address === '') {
            Notification('error', 'Error!', 'Please enter address !')
        }
        else if (address.length < 10) {
            Notification('error', 'Error!', 'Please enter valid address !')
        }
        else if (selectedCountryId == undefined) {
            Notification('error', 'Error!', 'Please select country !')
        }
        else if (selectedStateId == undefined) {
            Notification('error', 'Error!', 'Please select state !')
        }
        else if (selectedCityId == undefined) {
            Notification('error', 'Error!', 'Please select city !')
        }
        else if (pincode === '') {
            Notification('error', 'Error!', 'Please enter pincode !')
        }
        else if (mobileNumber === '') {
            Notification('error', 'Error!', 'Please enter mobile number !')
        }
        else if (mobileNumber.length !== 10) {
            Notification('error', 'Error!', 'Please enter valid mobile number !')
        }    
        else if (aadharNumber && aadharNumber.length !== 12) {
            Notification('error', 'Error!', 'Please enter valid aadhar number !')
        }   
        else if (gstNumber && gstNumber.length !== 15) {
            Notification('error', 'Error!', 'Please enter GST number !')
        }
        else if (addType == undefined) {
            Notification('error', 'Error!', 'Please select address type !')
        }
        else if (aadharNumber.length > 0 && selectedFile == null) {
            Notification('error', 'Error!', 'Please choose valid aadhar card image !')
        }
        else {

            var formData = new FormData()
            formData.append('address_id_no', idNo)
            formData.append('fullname', name)
            formData.append('number', mobileNumber)
            formData.append('pincode', pincode)
            formData.append('address', address)
            formData.append('city_id', selectedCityId)
            formData.append('state_id', selectedStateId)
            formData.append('country_id', selectedCountryId)
            formData.append('is_status', addType)
            if (gstNumber) {
                formData.append('gst_no', gstNumber)
            }
            if (aadharNumber) {
                formData.append('aadhar_no', aadharNumber)
            }
            if (selectedFile !== null) {
                formData.append('aadhar_image', selectedFile, selectedFile.name)
            }

            for (var i of formData.entries()) {
            }

            this.setState({ loading_addNewUserAddress: true }, async () => {
                var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/add-address', formData, "Error => Add new user address api")
              
                if (res && res.success == 1) {

                    this._getAllUserAddressApi()

                    this.setState({
                        open: false,
                        loading_addNewUserAddress: false
                    })
                }
                else {
                    this.setState({
                        loading_addNewUserAddress: false
                    })
                }
            })

        }
    }

    _customerDetails = async () => {

        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/customers-detail', null, "Error => Customer details api")     
        if (res && res.success == 1) {
            await this.props.addUserData(res.record)
        }

    }

    _updateUserProfile = async () => {

        var { edited_name, edited_email_id, edited_dob, edited_anniversary_date, edited_gender, edited_marital_status, edited_profile_image, edited_aadhar_card_image, edited_pan_card_image } = this.state

        var formData = new FormData()

        if (edited_name) {
            if (edited_name.length < 3) {
                alert('Please enter valid name')
            }
            else {
                formData.append('name', edited_name)
            }

        }
        if (edited_email_id) {

            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

            if (re.test(edited_email_id) == false) {
                alert('Please enter valid email id')
            }
            else {
                formData.append('email', edited_email_id)
            }

        }
        if (edited_dob) {

            if (edited_dob.length !== 10) {
                alert('Please enter valid date of birth')
            }
            else {
                formData.append('dob', edited_dob)
            }
        }
        if (edited_anniversary_date) {
            if (edited_anniversary_date.length !== 10) {
                alert('Please enter valid anniversary')
            }
            formData.append('dob_anniversary', edited_anniversary_date)
        }
        if (edited_gender) {

            if (edited_gender === 'Gender') {

            }
            else {
                formData.append('gender', edited_gender)
            }

        }
        if (edited_marital_status) {
            formData.append('marital', edited_marital_status)
        }
        if (edited_profile_image) {
            formData.append('image', edited_profile_image, edited_profile_image.name)
        }
        if (edited_aadhar_card_image) {
            formData.append('aadhar_image', edited_aadhar_card_image, edited_aadhar_card_image.name)
        }
        if (edited_pan_card_image) {
            formData.append('pan_image', edited_pan_card_image, edited_pan_card_image.name)
        }

        var _lenght = 0
        for (var i of formData.entries()) {
            _lenght++
        }

        setTimeout(async () => {
            if (_lenght > 0) {

                var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/update-profile', formData, 'Error => Update profile api')
    
                if (res && res.success == 1) {
                    this._customerDetails().then(() => {
              
                    })
                }
                else {

                }

            }
            else {
                alert('Update atleast one field')
            }
        }, 1000)
    }

    _editAddressApi = async () => {

        var { edit_address_object, edit_address_name, edit_address_address, selectedCountryId, selectedStateId, selectedCityId, edit_address_pincode, edit_address_addressType } = this.state
        var isValid = false

        var body = new FormData()
        body.append('id', edit_address_object.id)

        if (edit_address_name.length > 2) {
            body.append('fullname', edit_address_name)
            if (edit_address_address.length > 9) {
                body.append('address', edit_address_address)
                if (selectedCountryId != undefined) {
                    body.append('country_id', selectedCountryId)
                    if (selectedStateId != undefined) {
                        body.append('state_id', selectedStateId)
                        if (selectedCityId != undefined) {
                            body.append('city_id', selectedCityId)
                            if (edit_address_pincode != '') {
                                body.append('pincode', edit_address_pincode)
                                if (edit_address_addressType) {
                                    body.append('is_status', edit_address_addressType)
                                    isValid = true
                                } else {
                                    isValid = false
                                }
                            }
                            else {
                                alert('Please enter valid pincodee ', edit_address_pincode)
                                isValid = false

                            }
                        }
                        else {
                            alert('Please select city')
                            isValid = false

                        }
                    }
                    else {
                        alert('Please select state')
                        isValid = false

                    }
                }
                else {
                    alert('Please select country')
                    isValid = false

                }
            }
            else {
                alert('Please enter valid address')
                isValid = false

            }
        }
        else {
            alert('Please enter valid name')
            isValid = false

        }        

        var _lenght = 0
        for (var i of body.entries()) {
            _lenght++
        }

        setTimeout(async () => {
            if (_lenght > 1 && isValid) {
                var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/edit-address', body, "Error => Edit user address api.")             
                if (res && res.success == 1) {
                    this._resetEditAddressState()
                    this._getAllUserAddressApi()
                }
                else {
                    alert(res && res.message ? res.message : 'Try again later')
                    this._resetEditAddressState()
                }
            }
            else {
                // alert('Update atleast one field')
            }
        }, 1000)

    }

    _resetEditAddressState = () => {
        this.setState({
            openEdit: false,
            edit_address_object: {},
            isEditAddressObject: false,
            edit_address_name: '',
            edit_address_address: '',
            edit_address_pincode: '',
            edit_address_addressType: undefined,
            selectedCountryId: undefined,
            selectedStateId: undefined,
            selectedCityId: undefined,
        })
    }

    _resetCountryField = () => {

    }

    render() {


        var id_no = this.props.userData && this.props.userData.id_no ? this.props.userData.id_no : 'Not Available'
        var name = this.props.userData && this.props.userData.name ? this.props.userData.name : 'Not Available'
        var email = this.props.userData && this.props.userData.email ? this.props.userData.email : 'Not Available'
        var number = this.props.userData && this.props.userData.number ? this.props.userData.number : 'Not Available'
        var dob = this.props.userData && this.props.userData.dob ? this.props.userData.dob : 'Not Available'
        var marital = this.props.userData && this.props.userData.marital ? this.props.userData.marital : 'Not Available'
        var gender = this.props.userData && this.props.userData.gender ? this.props.userData.gender : 'Not Available'
        var dob_anniversary = this.props.userData && this.props.userData.dob_anniversary ? this.props.userData.dob_anniversary : 'Not Available'
        var image_full_path = this.props.userData && this.props.userData.image_full_path ? this.props.userData.image_full_path : `${process.env.PUBLIC_URL}/assets/images/nia.png`
        var aadhar_image_full_path = this.props.userData && this.props.userData.aadhar_image_full_path ? this.props.userData.aadhar_image_full_path : `${process.env.PUBLIC_URL}/assets/images/nia.png`
        var pan_image_full_path = this.props.userData && this.props.userData.pan_image_full_path ? this.props.userData.pan_image_full_path : `${process.env.PUBLIC_URL}/assets/images/nia.png`

      
        var { edited_name, edited_email_id, edited_dob, edited_anniversary_date, edited_gender, edited_marital_status, edited_profile_image, edited_aadhar_card_image, edited_pan_card_image } = this.state

        var { activeView, showProfile, userName, userPhone, userPincode, isEditAddressObject, edit_address_object } = this.state
     
        if (this.state.goToDashboard) {
            return (
                <Redirect to={{
                    pathname: `/`,
                }} />
            )
        }
        
        if (activeView === ListOfViews.MyCart) {
            return <Redirect to={`${process.env.PUBLIC_URL}/cart`} />
        }
        return (
            <div className="inner-dashboard-div">
                <Breadcrumb title={'Dashboard'} />
                {/* <Helmet>
                <title>My Account - Naswiz Retails Pvt Ltd</title>
                <meta name="og_title" property="og:title" content="My Account - Naswiz Retails Pvt Ltd" />
                <meta name="twitter:title" content="My Account - Naswiz Retails Pvt Ltd" />
                <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />		
                </Helmet> */}
                <Seo title="Dashboard" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company."  />
                <main className="main gap_genrate "> 
                    <div className="container">
                        <div className="row">

                            <div className="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                                <div className="inside_dash">
                                    <Link to={{ pathname: `${process.env.PUBLIC_URL}/page/user/myprofile`, itemviewname: 'My Profile' }}>
                                        <i className="fas fa-user-circle"></i>
                                        <p>My Account</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                                <div className="inside_dash">
                                    <Link to={{ pathname: `${process.env.PUBLIC_URL}/page/user/myorder`, itemviewname: 'My Order' }}>
                                        <i className="fas fa-gift"></i>
                                        <p>My Orders</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                                <div className="inside_dash">
                                <Link to={{ pathname: `${process.env.PUBLIC_URL}/page/user/myaddress`, itemviewname: 'My Address' }}>
                                        <i className="fas fa-location-arrow"></i>
                                        <p>My Addresses</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                                <div className="inside_dash">
                                <Link to={{ pathname: `${process.env.PUBLIC_URL}/page/user/mywallet`, itemviewname: 'My Wallet' }}>
                                        <i className="fas fa-wallet"></i>
                                        <p>My Naswiz Wallet</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                                <div className="inside_dash">
                                    <Link to={{ pathname: `${process.env.PUBLIC_URL}/page/user/notification`, itemviewname: 'Notification' }}>
                                        <span className="inside_icon-dash"> 
                                            <i className="far fa-bell"></i>
                                            <span className="notification_count">{this.state._viewconunt}</span>
                                        </span>
                                        <p>Notifications </p>
                                    </Link> 
                                </div>
                            </div>
                            <div className="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                                <div className="inside_dash">
                                    <Link to={{ pathname: `${process.env.PUBLIC_URL}/page/user/MyFavourites`, itemviewname: 'My Favourites' }}>
                                        <i className="far fa-heart"></i>
                                        <p>My Favorites</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                                <div className="inside_dash">
                                    <Link to={{ pathname: `${process.env.PUBLIC_URL}/cart`, itemviewname: 'My Cart' }}>
                                        <span className="inside_icon-dash cart_count"> 
                                        <i className="fas fa-cart-plus"></i>
                                        {
												this.props.cartListArray.length > 0 ?

													this.props.isUserLogin ?
														<span className="notification_count">{this.props.cartTotalArray.total_qty}</span>
														:
														<span className="notification_count">{this.props.cartTotalArray.total_qty}</span> 
                                                        : 
                                                        <span className="notification_count">0</span> 
											}
                                            
                                        </span>
                                        <p>My Cart</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                                <div className="inside_dash">
                                    <Link to={{ pathname: `${process.env.PUBLIC_URL}/page/user/ReferaFriend`, itemviewname: 'Refer a Friend' }}>
                                        <i className="fas fa-users"></i>
                                        <p>Refer a Friend </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div > 
        )
    }

    /**10.0 Store issue modual & method */

    // 10.2
    _storeIssueModual = () => {
        this._getStoreList()
        this._getStoreTicketView()
    }

    _getStoreList = async () => {

        var api_name = 'v1/get-stores'
        var res = await NaswizHelperApi.GET_WITH_TOKEN(api_name, `Error => ${api_name}`)
     
        if (res && res.success == 1) {
            this.setState({
                arr_storeList: res.record
            })
        }

    }

    _getStoreTicketView = async () => {

        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/store-ticket-view', null, "Error => v1/store-ticket-view api.") 
        if (res && res.success == 1) {
            this.setState({ arr_store_ticket: res.record })
        }

    }

    _createNewStoreIssue = async () => {

        var { store_id, store_visitedDate, store_subject, store_image, store_description } = this.state

        if (store_id === '') {
            Notification('error', 'Error!', 'Please enter store name!')
        }
        else if (store_id.length < 3) {
            Notification('error', 'Error!', 'Please enter valid store name')
        }
        else if (store_visitedDate === '') {
            Notification('error', 'Error!', 'Please select store visited date !')
        }
        else if (store_description === '') {
            Notification('error', 'Error!', 'Please add description')
        }
        else if (store_description.length < 3) {
            Notification('error', 'Error!', 'Please enter valid description')
        }
        else {

            var body = new FormData()
            body.append('store_name', store_id)
            body.append('visit_date', store_visitedDate)
            body.append('subject', store_description)
            body.append('problem_description', store_description)

            var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/store-ticket', body, "Error=> Create store issue api.")      
            if (res && res.success == 1) {
                Notification('success', 'Success!', res.message ? res.message : 'Success!')
                this._resetStoreModualState()
                this._getStoreTicketView()
            }
            else {
                Notification('error', 'Error!', 'Try again later !')
                this._resetStoreModualState()
            }
        }
    }

    _resetStoreModualState = () => {
        this.setState({
            // arr_storeList: [],
            store_id: '',
            store_visitedDate: '',
            store_subject: '',
            store_description: '',
            store_image: null,
            activeView: ListOfViews.StoreIssue
        })
    }
    // 10.2

    /**10.0 Store issue modual & method */

}

const mapStateToProps = (state) => ({
    isUserLogin: state.login.isUserLogin,
    userData: state.login.userData,
	cartListArray: state.login.userCartData.records.cart_list,
	cartTotalArray: state.login.userCartData.records.total,
})

export default connect(mapStateToProps, { addUserData, removeUserData })(ProfileDashboard);

var ListOfViews = {
    MyAccount: "My Account",
    MyProfile: "My Profile",
    MyAddress: "My Address",
    MyOrder: "My Order",
    MyFavourites: "My Favourites",
    MyOffers: "My Offers",
    MyCart: "My Cart",
    MyWallet: "My Wallet",
    LoyaltyPoints: "Loyalty Points",
    ReferAFriend: "Refer a Friend",
    Notification: "Notification",
    MyTickets: "My Tickets",
    OrderIssue: "Order issue",
    StoreIssue: "Store issue",
    Logout: "Logout",
    CreateTicket: 'Create Ticket',
    EditProfile: 'Edit Profile'
}