import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { NaswizHelperApi } from '../NaswizHelper'
import ProductListing from "../components/collection/common/product-listing";
import InputRange from 'react-input-range';
import { Radio, Checkbox } from 'antd'
import Notification from '../Notification'
import './productslisting.css';
// import TopCollectionSideList from './../components/layouts/fashion/top-collection-sidelist';
import Slider from 'react-slick';
import ReactPaginate from 'react-paginate';
import { bindActionCreators } from 'redux';
// import {  getCartData, getCartDataWithoutLogin } from "../../../actions/index";
import { getCartData, getCartDataWithoutLogin } from "../actions/index";
import Seo from '../components/Seo'



class Products extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            slug: props.match.params.id,
            arr_sizes: [],
            arr_colour: [],
            arr_category: [],
            arr_brand: [],
            arr_occasion: [],
            startprice: 0,
            endprice: 0,
            // Filter state
            value: { min: 0, max: 0 },
            filterSizeID: [],
            filterColorSlug: [],
            filterBrand: [],
            filterOccasion: [],
            filterCategory: [],
            filterAllCategotyList: [],

            // checked check box array 
            _checkedSizeList: [],
            _checkedColorList: [],
            _checkedCategoryList: [],
            _checkedBrandList: [],
            _checkedOccasionList: [],

            priceLow: null,
            priceHigh: null,
            isLowToHigh: false,
            isHighToLow: false,
            isOldest: false,
            isLatest: false,
            show_product: 12,
            _mainSlug: props.match.params.id,
            // _breadcrum:props.location.breadcrum
            _breadcrum: [],
            _shortValue: '',
            isPopularity: false,  //for Popularity  :1 
            isRating: false, //for rating  :1 

            _filterCatFromHeader: [],
            _filterCatFromHeadertemp: [],
            _selectedCategoryId: props.match.params.id,
            _selectedCategoryFilter: '',
            _mobilesidebar: false,
            _noOfPage: [],
            _page: 1,
            _per_page: 12,
            _selectedPage: 0,
            uniueId: '',
            arr_product: [], // fetured array
            featured_product: [],
            filter_product: [],
            isPriceFilter: false
        }
    }

    componentDidMount = async () => {

        var uniueId = localStorage.getItem('deviceId')
        await this.setState({ uniueId: uniueId, })
        this._getProductBySlug(this.state.slug)
        this._getFilterList()
        this.getTopCollection()
        this._featuredproductList()
        if (this.props.userID) {
            var body = new FormData()
            body.append('device_id', this.state.uniueId)
            this.props.getCartData('v1/get-cart-list', body, "Error => GetCartList api.")
        } else {
            var body = new FormData()
            body.append('device_id', this.state.uniueId)
            this.props.getCartDataWithoutLogin('v1/get-cart-list-without-login', body, "Error => get-cart-list-without-login.")
        }

    }

    async componentWillReceiveProps(nextProps) {
        this._resetFilterAndData();
        if (this.state._selectedCategoryId !== nextProps.match.params.id) {
            await this.setState({ value: { min: 0, max: 0 }, _selectedCategoryId: nextProps.match.params.id })
            await this._getProductBySlug(nextProps.match.params.id)
            await this.setState({ _selectedCategoryId: nextProps.match.params.id, slug: nextProps.match.params.id, _mainSlug: nextProps.match.params.id, })
            await this._getFilterList()
        }
    }

    _showOnHover = (ID) => {
        if (this.props.categoryDataWhenOnHover && this.props.categoryDataWhenOnHover.length > 0) {
            var dataWhenOnHover = []
            this.props.categoryDataWhenOnHover.map((item, index) => {
                item.items.map((itemsub, index) => {
                    itemsub.items.map((itemsub2, index) => {
                        if (itemsub2.id === ID) {
                            dataWhenOnHover = itemsub2.items
                        }
                    })
                    if (itemsub.id === ID) {
                        dataWhenOnHover = itemsub.items
                    }
                })
                if (item.id === ID) {
                    dataWhenOnHover = item.items
                }
            })
            this.setState({ _filterCatFromHeader: dataWhenOnHover })
        }
    }

    _getProductBySlug = (slug, from) => {
        var { filterSizeID, filterColorSlug, filterBrand, filterOccasion, filterCategory, isLatest, isOldest, isHighToLow, isLowToHigh, value, isPopularity, isRating } = this.state
        // console.log("----slug")
        this.setState({ isPriceFilter: false })
        var userID = this.props.userID
        this.setState({ loading_getProductBySlug: true }, async () => {

            var body = new FormData()
            if (userID) {
                body.append('customer_id', userID)
            }
            body.append('paginate', this.state.show_product)
            body.append('page', this.state._page)
            body.append('device_id', this.state.uniueId)

            //new
            if (isLatest) {
                body.append('latest', 1)
            }
            if (isOldest) {
                body.append('oldest', 1)
            }
            if (filterColorSlug.length > 0) {
                var CSVColor = filterColorSlug.map((item) => { return item.slug }).join(",")
                body.append('colors', CSVColor)
            }
            if (filterSizeID.length > 0) {
                var CSVSize = filterSizeID.map((item) => { return item.id }).join(",")
                body.append('sizes', CSVSize)
            }
            if (filterCategory.length > 0) {
                var CSVCategory = filterCategory.map((item) => { return item.slug }).join(",")
                body.append('category', CSVCategory)
            }
            if (filterBrand.length > 0) {
                var CSVBrand = filterBrand.map((item) => { return item.name }).join(",")
                body.append('brand', CSVBrand)
            }
            if (filterOccasion.length > 0) {
                var CSVOccation = filterOccasion.map((item) => { return item.name }).join(",")
                body.append('occasion', CSVOccation)
            }
            if (isHighToLow) {
                body.append('price_low_to_high', 1)
            }
            if (isLowToHigh) {
                body.append('price_high_to_low', 1)
            }
            if (isPopularity) {
                body.append('popularity', 1)
            } if (isRating) {
                body.append('ratting_avg', 1)
            }
            if (value.min > 0) {
                body.append('startprice', value.min)
            }
            if (value.max !== 0) {
                body.append('endprice', value.max)
            }
            for (var pair of body.entries()) {
                // console.log("pair", pair)
            }
            // await this.setState({ arr_getProductBySlug: [] })

            // console.log("---------", this.state._selectedCategoryId, this.state._mainSlug)
            var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/product/' + this.state._selectedCategoryId, body, 'Error => _getProductBySlug api')
            // console.log(res)
            if (res && res.success === 1) {
                await this.setState({
                    arr_getProductBySlug: res.records.data,
                    loading_getProductBySlug: false,
                    bannerimg: res.main_banner_image,
                    // _per_page:res.records.per_page
                })
                if (res.records.data.length > 0) {
                    if (res.records.data[0].breadcrumb_list.length > 0) {
                        await this.setState({
                            _breadcrum: res.records.data[0].breadcrumb_list
                        })

                        if (this.state._breadcrum.length === 2) {
                            await this._showOnHover(this.state._breadcrum[1].id)

                            // await this.setState({ _filterCatFromHeader: this.state._breadcrum[1].items })
                        } else if (this.state._breadcrum.length === 3) {
                            await this._showOnHover(this.state._breadcrum[2].id)

                            // await this.setState({ _filterCatFromHeader: this.state._breadcrum[2].items })
                        } else if (this.state._breadcrum.length === 1) {
                            await this._showOnHover(this.state._breadcrum[0].id)

                        }
                    }

                }
                var tmp = []
                for (let i = 0; i < res.records.last_page; i++) {
                    tmp.push(i)
                }
                // console.log("--==========", tmp)

                await this.setState({ _noOfPage: tmp })
            }
            else {
                this.setState({
                    loading_getProductBySlug: false
                })
            }

        })

    }

    changeShowValue = async (e) => {
        this.setState({
            _selectedPage: e.target.value
        })

        if (e.target.value == 0) {
            await this.setState({ show_product: this.state._per_page })
        } else if (e.target.value == 1) {
            await this.setState({ show_product: this.state._per_page * 4 })
        } else if (e.target.value == 2) {
            await this.setState({ show_product: this.state._per_page * 8 })
        } else if (e.target.value == 3) {
            await this.setState({ show_product: this.state._per_page * 12.5 })
        }
        await this.setState({ _page: 1 })

        await this._getProductBySlug(this.state.slug)
        // await setTimeout(() => {
        //     this.setPageFilter()
        // }, 1000)

    }

    setPageFilter = async () => {
        // console.log("----", this.state.arr_getProductBySlug.length === 0, this.state.arr_getProductBySlug.length)
        if (this.state.arr_getProductBySlug && this.state.arr_getProductBySlug.length === 0) {
            // console.log(this.state._noOfPage.length)
            await this.setState({ _page: 1 })
            await this._getProductBySlug(this.state.slug)
        }
    }

    handlePagination = async (value2) => {
        var value = value2.selected + 1
        if (value <= this.state._noOfPage.length) {
            await this.setState({ _page: value })
            // if (this.state.isPriceFilter)
            //     this._searchUponSelectedFilter()
            // else
            this._getProductBySlug(this.state.slug)
        }

    }

    _getFilterList = async () => {

        var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(`v1/product-filter/${this.state.slug}`, "Error")
        // console.log("Filter", res)
        if (res && res.success === 1) {
            this.setState({
                arr_sizes: res.records.sizes,
                arr_colour: res.records.colour,
                arr_category: res.records.category,
                arr_brand: res.records.brand,
                arr_occasion: res.records.occasion,
                value: { min: res.records.startprice, max: res.records.endprice },
                startprice: res.records.startprice,
                endprice: res.records.endprice
            })
        }
        else {

        }
    }

    _featuredproductList = async () => {
        var res = await NaswizHelperApi.GET_WITH_TOKEN(`v1/get-featured-product/${this.state.uniueId}`, "Error")
        if (res && res.success === 1) {
            this.setState({
                featured_product: res.records.featured_product,
            })
        }
        else {

        }
    }

    _searchUponSelectedFilter = async () => {
        var { filterSizeID, filterColorSlug, filterBrand, filterOccasion, filterCategory, isLatest, isOldest, isHighToLow, isLowToHigh, value, isPopularity, isRating } = this.state
        this.setState({ isPriceFilter: true })

        var body = new FormData()

        if (isLatest) {
            body.append('latest', 1)
        }
        if (isOldest) {
            body.append('oldest', 1)
        }
        if (filterColorSlug.length > 0) {
            var CSVColor = filterColorSlug.map((item) => { return item.slug }).join(",")
            body.append('colors', CSVColor)
        }
        if (filterSizeID.length > 0) {
            var CSVSize = filterSizeID.map((item) => { return item.id }).join(",")
            body.append('sizes', CSVSize)
        }
        if (filterCategory.length > 0) {
            var CSVCategory = filterCategory.map((item) => { return item.slug }).join(",")
            body.append('category', CSVCategory)
        }
        if (filterBrand.length > 0) {
            var CSVBrand = filterBrand.map((item) => { return item.name }).join(",")
            body.append('brand', CSVBrand)
        }
        if (filterOccasion.length > 0) {
            var CSVOccation = filterOccasion.map((item) => { return item.name }).join(",")
            body.append('occasion', CSVOccation)
        }
        if (isHighToLow) {
            body.append('price_low_to_high', 1)
        }
        if (isLowToHigh) {
            body.append('price_high_to_low', 1)
        }
        if (isPopularity) {
            body.append('popularity', 1)
        } if (isRating) {
            body.append('ratting_avg', 1)
        }
        if (value.min > 0) {
            body.append('startprice', value.min)
        }
        if (value.max !== 0) {
            body.append('endprice', value.max)
        }
        body.append('paginate', this.state.show_product)
        body.append('page', this.state._page)

        for (var pair of body.entries()) {
            // console.log("pair", pair)
        }
        // console.log(this.state._selectedCategoryId, this.state.uniueId)
        var res = await NaswizHelperApi.POST_WITHOUT_TOKEN(`v1/product/${this.state._selectedCategoryId}`, body, "Error => filter products api.")
        // console.log(res.records.data.length)
        this.setState({ arr_getProductBySlug: [] })

        if (res && res.success) {
            // console.log(res.records.data)
            this.setState({
                arr_getProductBySlug: res.records.data
            })
            var tmp = []
            for (let i = 0; i < res.records.last_page; i++) {
                tmp.push(i)
            }
            this.setState({ _noOfPage: tmp })
        }
        else {
            Notification('error', 'Error!', 'No search result found!')
        }

    }

    compareItem(filterArray, checkedItem) {

        for (var i = 0; i < filterArray.length; i++) {

            if (filterArray[i].id === checkedItem.id) {
                return i;
            }
        }
        return -1;
    }

    onSizeFilterChange = async (checkedItem) => {
        var addedItemIndex = this.compareItem(this.state.filterSizeID, checkedItem)

        var temp = this.state.filterSizeID


        var newArroption = [];
        if (addedItemIndex === -1) {
            temp.push(checkedItem)
            newArroption = [...this.state._checkedSizeList, checkedItem.name];
            await this.setState({ filterSizeID: temp, _checkedSizeList: newArroption })

        } else {
            var newArr = temp.filter(a => a.id !== checkedItem.id);
            var newArrChecked = this.state._checkedSizeList.filter(a => a !== checkedItem.name);
            await this.setState({ filterSizeID: newArr, _checkedSizeList: newArrChecked })
        }
        await this.setState({ _page: 1 })
        // this._searchUponSelectedFilter()
        this._getProductBySlug()
    }

    onColorFilterChange = async (checkedItem) => {


        var addedItemIndex = this.compareItem(this.state.filterColorSlug, checkedItem)

        var temp = this.state.filterColorSlug


        var newArroption = [];
        if (addedItemIndex === -1) {
            temp.push(checkedItem)
            newArroption = [...this.state._checkedColorList, checkedItem.name];

            await this.setState({ filterColorSlug: temp, _checkedColorList: newArroption })

        } else {
            var newArr = temp.filter(a => a.id !== checkedItem.id);
            var newArrChecked = this.state._checkedColorList.filter(a => a !== checkedItem.name);
            await this.setState({ filterColorSlug: newArr, _checkedColorList: newArrChecked })
        }
        // this._searchUponSelectedFilter()
        await this.setState({ _page: 1 })
        this._getProductBySlug()
    }

    onCategoryFilterChange = async (checkedItem) => {


        var addedItemIndex = this.compareItem(this.state.filterCategory, checkedItem)

        var temp = this.state.filterCategory


        var newArroption = [];
        await this.setState({ _selectedCategoryId: checkedItem.slug, _selectedCategoryFilter: checkedItem, _page: 1 })

        // this._searchUponSelectedFilter()
        await this.setState({ _page: 1 })
        await this._getProductBySlug()
        await this.setState({ _filterCatFromHeadertemp: this.state._filterCatFromHeader })
        await this.setState({ _filterCatFromHeader: checkedItem.items })


    }

    onBrandFilterChange = async (checkedItem) => {

        var addedItemIndex = this.compareItem(this.state.filterBrand, checkedItem)

        var temp = this.state.filterBrand

        var newArroption = [];
        if (addedItemIndex === -1) {
            temp.push(checkedItem)
            newArroption = [...this.state._checkedBrandList, checkedItem.name];

            await this.setState({ filterBrand: temp, _checkedBrandList: newArroption })

        } else {
            var newArr = temp.filter(a => a.id !== checkedItem.id);
            var newArrChecked = this.state._checkedBrandList.filter(a => a !== checkedItem.name);
            await this.setState({ filterBrand: newArr, _checkedBrandList: newArrChecked })
        }
        // this._searchUponSelectedFilter()
        await this.setState({ _page: 1 })
        this._getProductBySlug()
    }

    onOccationFilterChange = async (checkedItem) => {



        var addedItemIndex = this.compareItem(this.state.filterOccasion, checkedItem)

        var temp = this.state.filterOccasion


        var newArroption = [];
        if (addedItemIndex === -1) {
            temp.push(checkedItem)
            newArroption = [...this.state._checkedOccasionList, checkedItem.name];

            await this.setState({ filterOccasion: temp, _checkedOccasionList: newArroption })

        } else {
            var newArr = temp.filter(a => a.id !== checkedItem.id);
            var newArrChecked = this.state._checkedOccasionList.filter(a => a !== checkedItem.name);
            await this.setState({ filterOccasion: newArr, _checkedOccasionList: newArrChecked })
        }
        await this.setState({ _page: 1 })
        // this._searchUponSelectedFilter()
        this._getProductBySlug()
    }

    async clearFilterOne(item) {
        var newArrChecked = this.state._checkedSizeList.filter(a => a !== item.name);

        await this.setState({ _checkedSizeList: newArrChecked })

    }

    async clearFilterColor(item) {
        var newArrChecked = this.state._checkedColorList.filter(a => a !== item.name);

        await this.setState({ _checkedColorList: newArrChecked })


    }

    async clearFilterCategory(item) {
        // var newArrChecked = this.state._checkedCategoryList.filter(a => a !== item.name);

        // await this.setState({ _checkedCategoryList: newArrChecked })
        await this.setState({ _filterCatFromHeader: this.state._filterCatFromHeadertemp })
        await this.setState({ _selectedCategoryId: this.state.slug })
        // await this._searchUponSelectedFilter()
        await this.setState({ _page: 1 })
        await this._getProductBySlug()

    }

    async clearFilterBrand(item) {
        var newArrChecked = this.state._checkedBrandList.filter(a => a !== item.name);
        await this.setState({ _page: 1 })
        await this.setState({ _checkedBrandList: newArrChecked })


    }

    async clearFilterOccasion(item) {
        var newArrChecked = this.state._checkedOccasionList.filter(a => a !== item.name);

        await this.setState({ _checkedOccasionList: newArrChecked })


    }

    async shortRecords(value) {
        if (value === 'price') {
            await this.setState({ isRating: false, isPopularity: false, isHighToLow: true, isLowToHigh: false, })
        } else if (value === 'price-desc') {
            await this.setState({ isRating: false, isPopularity: false, isHighToLow: false, isLowToHigh: true, })
        } else if (value === 'date') {
            await this.setState({ isRating: false, isPopularity: false, isOldest: false, isLatest: true, })
        } else if (value === 'popularity') {
            await this.setState({ isRating: false, isPopularity: true, isOldest: false, isLatest: false, })
        } else if (value === 'rating') {
            await this.setState({ isRating: true, isPopularity: true, isOldest: false, isLatest: false, })
        } else if (value === 'oldest') {
            await this.setState({ isRating: false, isPopularity: false, isOldest: true, isLatest: false, })
        } else {
            this.setState({ isRating: false, isPopularity: false, isOldest: false, isLatest: false, isHighToLow: false, isLowToHigh: false })
        }

        await this.setState({ _shortValue: value })
        await this.setState({ _page: 1 })
        await this._getProductBySlug()
    }

    async shortByPrice(value) {
        await this.setState({ value })
        await this.setState({ _page: 1 })
        // await this._searchUponSelectedFilter()
        await this._getProductBySlug()
        await setTimeout(() => {
            this.setPageFilter()
        }, 1000)
    }

    _resetFilterAndData = async () => {
        await this.setState({
            value: { min: this.state.startprice, max: this.state.endprice },
            filterSizeID: [],
            filterColorSlug: [],
            filterBrand: [],
            filterOccasion: [],
            filterCategory: [],
            priceLow: null,
            priceHigh: null,
            isLowToHigh: false,
            isHighToLow: false,
            isOldest: false,
            isLatest: false,
            isPopularity: false,
            isRating: false,
            _checkedBrandList: [],
            _checkedCategoryList: [],
            _checkedOccasionList: [],
            _checkedSizeList: [],
            _checkedColorList: [],

        })
        await this.setPageFilter();
        await this.clearFilterCategory();
        this._getProductBySlug(this.state.slug);
        let ref = 'ref_price';
        let ref2 = 'ref_price_des'

        this.refs[ref].checked = false;
        this.refs[ref2].checked = false;


        this.refs['ref_old'].checked = false;
        this.refs['ref_new'].checked = false;
    }

    // featured product
    getTopCollection = () => {

        var url = 'v1/get-home/' + this.state.uniueId + '/0'
        this.setState({ loading_getHomeData: true }, async () => {

            var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(url, 'Error => v1/get-home')

            if (res && res.success === 1) {
                this.setState({

                    arr_product: res.records.product,

                })


            }


        })
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{`${this.state._selectedCategoryId} - Naswiz Retail Pvt Ltd`}</title>
                    <meta name="keywords" content={this.state._selectedCategoryId} />
                    <meta name="description" content={this.state._selectedCategoryId} />
                    <meta name="og_title" property="og:title" content={`${this.state._selectedCategoryId} | Naswiz Retails Pvt Ltd`} />
                    <meta name="twitter:title" content={`${this.state._selectedCategoryId} | Naswiz Retails Pvt Ltd`} />
                    <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                    <meta name="Description" content={this.state._selectedCategoryId} />
                    <meta property="og:description" content={this.state._selectedCategoryId} />
                    <meta name="twitter:description" content={this.state._selectedCategoryId} />

                    <meta charset="utf-8" />
                    <meta name="description"
                        content={this.state._selectedCategoryId} />
                    <meta content={`${this.state._selectedCategoryId} | Naswiz Retails Pvt Ltd`}
                        property="og:title" />
                    <meta content={window.location.href + window.location.search}
                        property="og:url" />
                    <link rel="canonical"
                        href={window.location.href + window.location.search} />
                    <link rel="alternate" href="Image Not Found" hreflang="IN" />
                    <meta name="next-head-count" content="49" />
                    <link rel="shortcut icon" sizes="16x16" href="../../public/assets/icon/favicon-16.png" />
                    <link rel="shortcut icon" sizes="32x32" href="../../public/assets/icon/favicon-32.png" />
                    <link rel="apple-touch-icon" sizes="57x57" href="../../public/assets/icon/favicon-57.png" />
                    <link rel="apple-touch-icon" sizes="72x72" href="../../public/assets/icon/favicon-72.png" />
                    <link rel="apple-touch-icon" sizes="114x114" href="../../public/assets/icon/favicon-114.png" />
                    <link rel="apple-touch-icon" sizes="120x120" href="../../public/assets/icon/favicon-120.png" />
                    <link rel="apple-touch-icon" sizes="128x128" href="../../public/assets/icon/favicon-128.png" />
                    <link rel="apple-touch-icon" sizes="128x128" href="../../public/assets/icon/favicon-128.png" />
                    <link rel="apple-touch-icon" sizes="144x144" href="../../public/assets/icon/favicon-144.png" />
                    <link rel="apple-touch-icon" sizes="152x152" href="../../public/assets/icon/favicon-152.png" />
                    <link rel="apple-touch-icon" sizes="167x167" href="../../public/assets/icon/favicon-167.png" />
                    <link rel="apple-touch-icon" sizes="180x180" href="../../public/assets/icon/favicon-180.png" />
                    <link rel="icon" sizes="128x128" href="../../public/assets/icon/favicon-128.png" />
                    <link rel="icon" sizes="144x144" href="../../public/assets/icon/favicon-144.png" />
                    <link rel="icon" sizes="152x152" href="../../public/assets/icon/favicon-152.png" />
                    <link rel="icon" sizes="167x167" href="../../public/assets/icon/favicon-167.png" />
                    <link rel="icon" sizes="180x180" href="../../public/assets/icon/favicon-180.png" />
                    <link rel="icon" sizes="192x192" href="../../public/assets/icon/favicon-192.png" />
                </Helmet>
                {/* <Seo title={this.state._selectedCategoryId} description={this.state._selectedCategoryId} /> */}
                {/* <div className="promo-section mb-5 bg-dark" style={{  minHeight: '320px', backgroundPosition: 'center', backgroundSize: 'contain', backgroundAttachment: 'unset', height: '358px' }} > */}
                <div className="container">
                    <div className="promo-section bg-dark" style={{ padding: '0px', height: 'unset' }} >
                        <img src={this.state.bannerimg} alt="" className="img-fluid" />

                    </div>
                </div>


                <div className="container">
                    <nav aria-label="breadcrumb" className="breadcrumb-nav">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link
                                to={{ pathname: `${process.env.PUBLIC_URL}/` }}
                            >Home</Link></li>

                            {this.state._breadcrum != null && this.state._breadcrum.length > 0 ? this.state._breadcrum.map((item, index) => {
                                return (
                                    <li key={index} className="breadcrumb-item"><Link
                                        to={{ pathname: `${process.env.PUBLIC_URL}/Products/${item.slug}`, state: index === 1 ? [{ 'slug': this.state._breadcrum[0].slug, 'name': this.state._breadcrum[0].name, 'category': this.state._breadcrum[0] }, { 'slug': item.slug, 'name': item.name, 'category': item.category }] : index == 2 ? [{ 'slug': this.state._breadcrum[0].slug, 'name': this.state._breadcrum[0].name, 'category': this.state._breadcrum[0] }, { 'slug': this.state._breadcrum[1].slug, 'name': this.state._breadcrum[1].name, 'category': this.state._breadcrum[1] }, { 'slug': item.slug, 'name': item.name, 'category': item }] : [{ 'slug': item.slug, 'name': item.name, 'category': item }] }}>
                                        {item.name}
                                    </Link>
                                    </li>
                                )
                            }) : null}
                        </ol>
                    </nav>
                    <div className={this.state._mobilesidebar ? 'row sidebar-opened' : 'row'}>
                        <div className="col-lg-9 main-content ">
                            {this.state.arr_getProductBySlug && this.state.arr_getProductBySlug.length > 0 ?
                                <nav className="toolbox">
                                    <div className="toolbox-left">
                                        <div className="toolbox-item toolbox-sort">
                                            <label>Sort By:</label>

                                            <div className="select-custom">
                                                <select name="orderby" className="form-control" onChange={(event) => {
                                                    this.shortRecords(event.target.value)
                                                }}>
                                                    <option value="menu_order" defaultValue="selected">Default</option>
                                                    <option value="popularity" selected={this.state.isPopularity}>Popularity</option>
                                                    <option value="rating" selected={this.state.isRating}>Average rating</option>
                                                    <option value="date" selected={this.state.isLatest}>Newness</option>
                                                    <option value="price" selected={this.state.isHighToLow}>Price: low to high</option>
                                                    <option value="price-desc" selected={this.state.isLowToHigh}>Price: high to low</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="toolbox-right">
                                        <div className="toolbox-item toolbox-show">
                                            <label>Show:</label>
                                            <div className="select-custom test">
                                                <select name="count" className="form-control"
                                                    value={this.state._selectedPage}
                                                    onChange={(e) => this.changeShowValue(e)}>
                                                    <option value={0}>{this.state._per_page}</option>
                                                    <option value={1}>{this.state._per_page * 4}</option>
                                                    <option value={2}>{this.state._per_page * 8}</option>
                                                    <option value={3}>{this.state._per_page * 12.5}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                                : null

                            }
                            <div className="row" style={{ marginRight: '0px', marginLeft: '0px' }}>
                                <ProductListing
                                    colSize={this.state.layoutColumns}
                                    // records={this.props.productslist.records.data}
                                    records={this.state.arr_getProductBySlug}
                                    mainSlug={this.state._mainSlug}
                                    breadcrum={this.state._breadcrum}
                                    updateDataAfterAction={() => this._getProductBySlug(this.state.slugs, '')}
                                />
                            </div>
                            <nav className="toolbox toolbox-pagination">
                                {this.state.arr_getProductBySlug && this.state.arr_getProductBySlug.length > 0 ?
                                    <div className="toolbox-item toolbox-show">
                                        <label>Show:</label>

                                        <div className="select-custom">
                                            <select name="count" className="form-control"
                                                value={this.state._selectedPage}
                                                onChange={(e) => this.changeShowValue(e)}>
                                                <option value={0}>{this.state._per_page}</option>
                                                <option value={1}>{this.state._per_page * 4}</option>
                                                <option value={2}>{this.state._per_page * 8}</option>
                                                <option value={3}>{this.state._per_page * 12.5}</option>
                                            </select>
                                        </div>
                                    </div>
                                    : null
                                }
                                <div className="" >
                                    {this.state._noOfPage.length > 1 ?
                                        <ReactPaginate
                                            previousLabel={'<'}
                                            nextLabel={'>'}
                                            breakLabel={'...'}
                                            breakClassName={'page-item'}
                                            breakLinkClassName={'page-link'}
                                            pageCount={this.state._noOfPage.length}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={5}
                                            onPageChange={(page) => this.handlePagination(page)}
                                            containerClassName={'pagination toolbox-item'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={'active'}
                                            forcePage={this.state._page - 1}
                                        />

                                        : null}
                                </div>

                            </nav>


                        </div>

                        <div className="sidebar-overlay"></div>
                        <div className="sidebar-toggle" onClick={() => this.setState({ _mobilesidebar: !this.state._mobilesidebar })} ><i className="fas fa-sliders-h"></i></div>

                        <aside className="sidebar-shop col-lg-3 order-lg-first mobile-sidebar">

                            <div className="sidebar-wrapper">
                                <div className="top-bar">
                                    <div className="filter-price-action d-flex align-items-center justify-content-between flex-wrap">
                                        {/* <button onClick={() => this._searchUponSelectedFilter()} type="button" className="btn btn-primary">Filter</button> */}
                                        <button onClick={() => this._getProductBySlug()} type="button" className="btn theme-button">Filter</button>
                                        <button onClick={() => {
                                            this._resetFilterAndData()
                                        }} type="button" className="btn theme-button">Clear Filter</button>
                                    </div>
                                    <div className="filter-box">
                                        {this.state.filterSizeID != null && this.state.filterSizeID.length > 0 ? this.state.filterSizeID.map((item, index) => {

                                            return (
                                                <div className="filter-item" onClick={() => this.clearFilterOne(item)}>
                                                    <label for={item.name}>✕  <span>{item.name}</span> </label>
                                                </div>

                                            )
                                        }) : null}

                                        {this.state.filterColorSlug != null && this.state.filterColorSlug.length > 0 ? this.state.filterColorSlug.map((item, index) => {

                                            return (
                                                <div className="filter-item" onClick={() => this.clearFilterColor(item)}>
                                                    <label for={item.name}>✕ <span> {item.name}</span> </label>
                                                </div>

                                            )
                                        }) : null}

                                        {this.state._selectedCategoryId !== this.state.slug ?
                                            <div className="filter-item" onClick={() => {
                                                this.clearFilterCategory()
                                            }}>
                                                <label htmlFor={this.state._selectedCategoryId}>✕ <span>{this.state._selectedCategoryId}</span></label>
                                            </div> : null}
                                        {this.state.filterBrand != null && this.state.filterBrand.length > 0 ? this.state.filterBrand.map((item, index) => {

                                            return (
                                                <div className="filter-item " onClick={() => this.clearFilterBrand(item)}>
                                                    <label for={item.name}>✕  <span>{item.name}</span></label>
                                                </div>

                                            )
                                        }) : null}
                                        {this.state.filterOccasion != null && this.state.filterOccasion.length > 0 ? this.state.filterOccasion.map((item, index) => {

                                            return (
                                                <div className="filter-item" onClick={() => this.clearFilterOccasion(item)}>
                                                    <label for={item.name}>✕  <span>{item.name}</span></label>
                                                </div>

                                            )
                                        }) : null}
                                    </div>
                                </div>
                                {this.state._filterCatFromHeader.length > 0 ?
                                    <div className="widget">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-3" role="button" aria-expanded="true" aria-controls="widget-body-3">Category</a>
                                        </h3>
                                        <div className="collapse show" id="widget-body-3">
                                            <div className="widget-body category">
                                                <div className="checkbox-list">
                                                    {this.state._filterCatFromHeader.length > 0 ?
                                                        this.state._filterCatFromHeader.map((item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <div className="checkbox">
                                                                        <label htmlFor={item.name}>
                                                                            <input checked={this.state._checkedCategoryList.includes(item.name)} onChange={() => this.onCategoryFilterChange(item)} type="checkbox" id={item.name} name={item.name} value={item.name} />
                                                                            <span>{item.name}</span>
                                                                        </label>
                                                                    </div>
                                                                    <div key={index} >
                                                                        {item.items.map((itemsub, indexsub) => {
                                                                            return (
                                                                                <div key={indexsub} className="checkbox" >

                                                                                    <label for={itemsub.name}>
                                                                                        <input checked={this.state._checkedCategoryList.includes(itemsub.name)} onChange={() => this.onCategoryFilterChange(itemsub)} type="checkbox" id={itemsub.name} name={itemsub.name} value={itemsub.name} />
                                                                                        <span>{itemsub.name}</span>
                                                                                    </label>

                                                                                    <div key={indexsub} className="checkbox">
                                                                                        {
                                                                                            itemsub.items.map((itemsub_sub, indexsub_sub) => {
                                                                                                return (
                                                                                                    <div key={indexsub_sub} className="checkbox">

                                                                                                        <label for={itemsub_sub.name}>
                                                                                                            <input checked={this.state._checkedCategoryList.includes(itemsub_sub.name)} onChange={() => this.onCategoryFilterChange(itemsub_sub)} type="checkbox" id={itemsub_sub.name} name={itemsub_sub.name} value={itemsub_sub.name} />
                                                                                                            <span>{itemsub_sub.name}</span>
                                                                                                        </label>

                                                                                                        <div key={indexsub_sub}></div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }) : null

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                                }
                                {this.state.endprice === this.state.startprice ?
                                    null :
                                    <div className="widget">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-6" role="button" aria-expanded="true" aria-controls="widget-body-6">
                                                Price Range</a>
                                        </h3>
                                        <div className="collapse show" id="widget-body-6">
                                            <div className="widget-body">
                                                <form action="#">
                                                    <div className="price-slider-wrapper">
                                                        <InputRange
                                                            maxValue={this.state.endprice}
                                                            minValue={this.state.startprice}
                                                            value={this.state.value}
                                                            onChange={value => {
                                                                this.shortByPrice(value)
                                                            }}
                                                        />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {this.state.arr_sizes.length > 0 ?
                                    <div className="widget size-box">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-1" role="button" aria-expanded="true" aria-controls="widget-body-1">SIZE</a>
                                        </h3>
                                        <div className="collapse show" id="widget-body-1">
                                            <div className="widget-body">
                                                <div className="checkbox-list">
                                                    {this.state.arr_sizes.map((item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <div className="checkbox">
                                                                    <label for={item.name}>
                                                                        <input checked={this.state._checkedSizeList.includes(item.name)} onChange={() => this.onSizeFilterChange(item)} type="checkbox" id={item.name} name={item.name} value={item.name} />
                                                                        <span>{item.name}</span>
                                                                    </label>
                                                                </div>
                                                                <div key={index}></div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                                {this.state.arr_brand.length > 0 ?
                                    <div className="widget size-box">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-4" role="button" aria-expanded="true" aria-controls="widget-body-4">Brand</a>
                                        </h3>
                                        <div className="collapse show" id="widget-body-4">
                                            <div className="widget-body">
                                                <div className="checkbox-list">
                                                    {this.state.arr_brand.map((item, index) => {
                                                        return (
                                                            <div key={index} >
                                                                <div className="checkbox">
                                                                    <label for={item.name}>
                                                                        <input checked={this.state._checkedBrandList.includes(item.name)} onChange={() => this.onBrandFilterChange(item)} type="checkbox" id={item.name} name={item.name} value={item.name} />
                                                                        <span>{item.name}</span>
                                                                    </label>
                                                                </div>
                                                                <div key={index}></div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                                {this.state.arr_colour.length > 0 ?
                                    <div className="widget">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-2" role="button" aria-expanded="true" aria-controls="widget-body-2">COLOR</a>
                                        </h3>
                                        <div className="collapse show" id="widget-body-2">
                                            <div className="widget-body">
                                                <div className="checkbox-list color-checkbox">
                                                    {
                                                        this.state.arr_colour.map((item, index) => {
                                                            return (
                                                                <div key={index} className="checkbox">
                                                                    <label for={item.name}>
                                                                        <input checked={this.state._checkedColorList.includes(item.name)} onChange={() => this.onColorFilterChange(item)} type="checkbox" id={item.name} name={item.name} value={item.name} />
                                                                        <div className="color-box" style={{ backgroundColor: item.code, }}></div>
                                                                        <span>{item.name}</span>
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                                {this.state.arr_occasion.length > 0 ?
                                    <div className="widget size-box">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-5" role="button" aria-expanded="true" aria-controls="widget-body-5">Occasion</a>
                                        </h3>
                                        <div className="collapse show" id="widget-body-5">
                                            <div className="widget-body">
                                                <div className="checkbox-list">
                                                    {
                                                        this.state.arr_occasion.map((item, index) => {
                                                            return (
                                                                <div key={index} >
                                                                    <div className="checkbox">
                                                                        <label for={item.name}>
                                                                            <input checked={this.state._checkedOccasionList.includes(item.name)} onChange={() => this.onOccationFilterChange(item)} type="checkbox" id={item.name} name={item.name} value={item.name} />
                                                                            <span>{item.name}</span>
                                                                        </label>
                                                                    </div>
                                                                    <div key={index}></div>
                                                                </div>

                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                                <div className="widget">
                                    <h3 className="widget-title">
                                        <a data-toggle="collapse" href="#widget-body-7" role="button" aria-expanded="true" aria-controls="widget-body-7">Price Range By</a>
                                    </h3>
                                    <div className="collapse show" id="widget-body-7">
                                        <div className="widget-body">
                                            <div className="checkbox-list">
                                                <div className="checkbox" >
                                                    <label htmlFor="price-desc">
                                                        <input ref={'ref_price'} type="checkbox" checked={this.state.isLowToHigh} name="example" id="price-desc" value="price-desc" onChange={(event) => {
                                                            this.shortRecords(event.target.value)
                                                        }} />
                                                        <span>Price : High To Low</span>
                                                    </label>
                                                </div>
                                                <div className="checkbox">
                                                    <label htmlFor="price">
                                                        <input ref={'ref_price_des'} type="checkbox" checked={this.state.isHighToLow} name="example" id="price" value="price" onChange={(event) => {
                                                            this.shortRecords(event.target.value)
                                                        }} />
                                                        <span>Price : Low To High</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="widget">
                                    <h3 className="widget-title">
                                        <a data-toggle="collapse" href="#widget-body-8" role="button" aria-expanded="true" aria-controls="widget-body-8">By Collection</a>
                                    </h3>
                                    <div className="collapse show" id="widget-body-8">
                                        <div className="widget-body">
                                            <div className="checkbox-list">
                                                <div className="checkbox">
                                                    <label>
                                                        <input ref={'ref_old'} type="checkbox" checked={this.state.isOldest} name="example" id="oldest" value="oldest" onChange={(event) => {
                                                            this.shortRecords(event.target.value)

                                                        }} />
                                                        <span> By Oldest</span>
                                                    </label>
                                                </div>
                                                <div className="checkbox">
                                                    <label>
                                                        <input ref={'ref_new'} type="checkbox" checked={this.state.isLatest} name="example labelbootmspace" id="date" value="date" onChange={(event) => { this.shortRecords(event.target.value) }} />
                                                        <span>By Latest</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="widget widget-featured d-none">
                                    {this.state.featured_product.length ? <h3 className="widget-title">FEATURED PRODUCTS</h3> : null}

                                    {/* <div className="widget-body">
                                        <TopCollectionSideList
                                            topCollectionItems={this.state.arr_product ? this.state.arr_product : []}
                                            updateDataAfterAction={() => this.getTopCollection()}
                                        />
                                    </div> */}
                                    <div className="widget-body">
                                        {/* <div className="owl-carousel widget-featured-products"> */}
                                        <Slider >
                                            {this.state.featured_product.length ?

                                                this.state.featured_product.map((items, indexs) => {

                                                    return (
                                                        <div key={indexs} className="featured-col">
                                                            {items.map((item, index) => {

                                                                return (
                                                                    <div key={index} className="featured-col">
                                                                        <div className="product-default left-details product-widget">
                                                                            <figure>
                                                                                <a href="javascript:void(0)">
                                                                                    <img src={item.product_images[0].image} />
                                                                                </a>
                                                                            </figure>
                                                                            <div className="product-details">
                                                                                <h2 className="product-title">
                                                                                    <a href="javascript:void(0)">{item.name}</a>
                                                                                </h2>
                                                                                <div className="ratings-container">
                                                                                    <div className="product-ratings">
                                                                                        <span className="ratings" style={{ width: '100%' }}></span>
                                                                                        <span className="tooltiptext tooltip-top"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="price-box">
                                                                                    <span className="product-price">₹{item.price}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )

                                                            })
                                                            }
                                                        </div>
                                                    )

                                                })

                                                : null
                                            }
                                        </Slider>
                                        {/* </div> */}
                                    </div>

                                </div>
                            </div>
                        </aside>
                    </div>
                </div >
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        allState: state,
        userID: state.login.userID,
        categoryDataWhenOnHover: state.login.categoryDataWhenOnHover,

    }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
    // getProductDetails:(slug) => getProductDetails(slug),
    // getAllCategories: () => getAllCategories(),
    // getWishlistData: (trailUrl, errorMsg) => getWishlistData(trailUrl, errorMsg),
    getCartData: (trailUrl, errorMsg) => getCartData(trailUrl, errorMsg),
    getCartDataWithoutLogin: (trailUrl, body, errorMsg) => getCartDataWithoutLogin(trailUrl, body, errorMsg),
    // setMasterCategoryList: (data) => setMasterCategoryList(data),
    // setAllMasterCategoryList: (data) => setAllMasterCategoryList(data),
    // setCategoryDataOnHover: (data) => setCategoryDataOnHover(data)
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Products)