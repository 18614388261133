import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faSearch, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Seo from '../../Seo';


class ProductItem extends Component {

    constructor(props) {
        super(props)

        this.state = {
            open: false,
            stock: 'InStock',
            quantity: 1,
            image: '',
        }
    }

    onClickHandle(img) {
        this.setState({ image: img });
    }
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    minusQty = () => {
        if (this.state.quantity > 1) {
            this.setState({ stock: 'InStock' })
            this.setState({ quantity: this.state.quantity - 1 })
        }
    }

    plusQty = () => {
        if (this.props.product.stock >= this.state.quantity) {
            this.setState({ quantity: this.state.quantity + 1 })
        } else {
            this.setState({ stock: 'Out of Stock !' })
        }
    }
    changeQty = (e) => {
        this.setState({ quantity: parseInt(e.target.value) })
    }

    render() {
        const { product, symbol, onAddToCartClicked, onAddToWishlistClicked, onAddToCompareClicked } = this.props;
        let RatingStars = []
        for (var i = 0; i < 5; i++) {
            RatingStars.push(<i className="fas fa-star" key={i}></i>)
        }
        return (
            <>
                <Seo  description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." image="https://www.myshopwiz.com/assets/images/cms-page/phytowiz-banner-1.png" />
                <div className="product-default inner-quickview inner-icon">
                    <figure>
                        <a href="product.html">
                            <img src={product.product_images[0] ? product.product_images[0].image : `${process.env.PUBLIC_URL}/assets/images/nia.png`} />
                        </a>
                        <div className="btn-icon-group search_top">
                            <a href="ajax/product-quick-view.html" className="btn-icon btn-quickview" title="Quick View"><i className="icon-search"></i></a>
                        </div>
                        <div className="product-action new_styles_ff new_btnsty">
                            <a href="javascript:void(0);" id="show_pm" className="btn btn-dark add-cart new_shades" title="Add to Cart">Add to Cart</a>
                            <div className="product-single-qty hide_area circle_mp">
                                <input className="horizontal-quantity form-control" type="text" />
                            </div>
                        </div>
                    </figure>
                    <div className="product-details">
                        <div className="category-wrap">
                            <div className="category-list">
                                <a href="category.html" className="product-category">category</a>
                            </div>
                            <a href="#" className="btn-icon-wish"><i className="icon-heart"></i></a>
                        </div>
                        <h2 className="product-title">
                            <a href="product.html">Ray Ban 5228</a>
                        </h2>
                        <div className="ratings-container">
                            <div className="product-ratings">
                                <span className="ratings" style={{ width: '100%' }}></span>
                                <span className="tooltiptext tooltip-top"></span>
                            </div>
                        </div>
                        <h3 className="product-title-ip">
                            <a href="javascript:void(0)"> Incentive Point (IP) <span>24</span></a>
                        </h3>
                        <div className="price-box">
                            <span className="product-price">₹33.00</span>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ProductItem;