import {
    ADD_TO_WISHLIST, REMOVE_FROM_WISHLIST
} from "../constants/ActionTypes";


const initialState = {
    wishlistData: [],
};

const wishlistReducer = (state = initialState, action) => {

    switch (action.type) {
        case ADD_TO_WISHLIST: {

            var temp = state.wishlistData
            var isAlreadyInWishlist = temp.some((item, index) => item.product_id == action.product.product_id)
     
            if (isAlreadyInWishlist) {
                return state;
            }

            temp.push(action.product)

            return {
                ...state,
                wishlistData: temp
            }
        }
        case REMOVE_FROM_WISHLIST: {
            var removeItem = state.wishlistData
            var filterData = removeItem.filter((x, i) => x.product_id !== action.product_id)
       
            return {
                ...state,
                wishlistData: filterData
            }
        }
        default: {
            return state;
        }
    }
};
export default wishlistReducer;