import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Link } from 'react-router-dom'
import Slider from 'react-slick';
import Modal from 'react-responsive-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import renderHTML from 'react-render-html';
import { NaswizHelperApi, colors } from './../../../../NaswizHelper';
import { addToCart, addToWishlist, addToCompare, removeFromWishlist, addItemIntoCart, removeItemFromCart, changeLoginState, manageUserIdState, getWishlistData, getCartData } from './../../../../actions'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import PhoneInput from 'react-phone-number-input'
class DetailsWithPrice extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            loginModalVisibility: false,
            registerModalVisibility: false,
            otpModalVisibility: false,
            quantity: 1,
            stock: 'InStock',
            nav3: null,
            wholesale_price: 1127,
            price: 1127,
            color_id: 1127,
            size_id: 1,
            images: [],
            // editModalState
            selectedEditQty: null,
            editModalVisibility: true,
            uniueId: '',
        }
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    componentDidMount() {
        var uniueId = localStorage.getItem('deviceId')
        this.setState({
            nav3: this.slider3,
            uniueId: uniueId
        });
    }

    minusQty = async (cartData) => {

        var { id, quantity } = cartData[0]

        if (quantity == 1) {



            var res = await NaswizHelperApi.GET_WITH_TOKEN(`v1/delete-cart/${id}`, "Error => Remove item from cart api.")


            if (res && res.success == 1) {
                var body = new FormData()
                body.append('device_id', this.state.uniueId)
                this.props.getCartData('v1/get-cart-list', body, 'Error => While getting cart data api.')
                this.props.updateSingleProductDataAfterAction()
            }
            else {
                alert(res && res.message ? res.message : 'Error while remove or de-crease quantity')
            }

        }
        else {

            var minus = quantity - 1

            var body = new FormData()
            body.append('cart_id', id)
            body.append('quantity', minus)

            var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/update-cart', body, "Error => Update(Decrease) cart qty api.")


            if (res && res.success == 1) {
                this.props.updateSingleProductDataAfterAction()
            }
            else {
                alert(res && res.message ? res.message : 'Try again later')
            }
        }
    }

    plusQty = async (cartData) => {


        var { id, quantity } = cartData[0]


        var plus = quantity + 1


        var body = new FormData()
        body.append('cart_id', id)
        body.append('quantity', plus)

        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/update-cart', body, "Error => Update(Increase) cart qty api.")


        if (res && res.success == 1) {
            this.props.updateSingleProductDataAfterAction()
        }
        else {
            alert(res && res.message ? res.message : 'Try again later')
        }
    }

    changeQty = (e) => {


    }

    changeColor = (color_id, wholesale_price, price, images) => {
        this.setState({
            color_id: color_id,
            price: price,
            wholesale_price: wholesale_price,
            images: images
        })
    }

    _onChangeOtpOne = () => {
        if (this.state.otp1.length == 1) {

            this.InputTwo.focus()
        }
    }

    _onChangeOtpTwo = () => {
        if (this.state.otp2.length == 1) {

            this.InputThree.focus()
        }
    }

    _onChangeOtpThree = () => {
        if (this.state.otp3.length == 1) {

            this.InputFour.focus()
        }
    }

    async setLogin() {

        var { phoneNumber } = this.state


        if (phoneNumber === '') {
            alert('Please enter mobile number')
        }
        else if (phoneNumber.length !== 13) {
            alert('Mobile number is not valid')
        }
        else {

            var formdata = new FormData();
            formdata.append("number", this.state.phoneNumber);


            var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/login', formdata, 'Error => Login api')


            if (res && res.success == 1) {
                this.setState({ loginModalVisibility: false, otpModalVisibility: true })

            }

        }
    }

    async setRegister() {

        var { phoneNumber } = this.state

        if (phoneNumber === '') {
            alert('Please enter mobile number')
        }
        else if (phoneNumber.length !== 13) {
            alert('Mobile number is not valid')
        }
        else {

            var formdata = new FormData();
            formdata.append("number", this.state.phoneNumber);




            var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/register', formdata, "Error => Register api.")


            if (res && res.success == 1) {
                this.setState({
                    registerModalVisibility: false,
                    otpModalVisibility: true
                })
            }
            else {
                this.setState({ phoneNumber: '' })
                alert(res && res.message ? res.message : 'Try again later.')
            }
        }
    }

    verifyOtp = async () => {

        var { otp1, otp2, otp3, otp4, phoneNumber } = this.state

        var finalOTP = otp1.concat(otp2.concat(otp3.concat(otp4)))

        var formdata = new FormData();
        formdata.append("number", phoneNumber);
        formdata.append("otp", finalOTP);



        var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/verify-otp', formdata, "Error => Verify OTP with login api")


        if (res && res.success == 1) {
            this.setState({ otpModalVisibility: false, phoneNumber: '', otp1: '', otp2: '', otp3: '', otp4: '' })

            this.props.changeLoginState(true)
            this.props.manageUserIdState(res.id)
            localStorage.setItem('token', res.token)
            // this.props.updateDataAfterAction()

            // localStorage.setItem('user_id', String(res.id))
            alert('Verify Successfully')
            // setTimeout(() => { this.props.getWishlistData('v1/wishlist-details', "Error in wishlist") }, 1000)
        }
        else {
            alert(res && res.message ? res.message : 'Try again later')
        }

        // await this.props.setLogin(formdata)
    }

    _resendOTP = async () => {

        var { phoneNumber } = this.state


        var formdata = new FormData();
        formdata.append("number", phoneNumber);

        var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/login', formdata, 'Error => Resend otp api')


        if (res && res.success == 1) {
            alert(res.message)
        }
        else {
            alert(res && res.message ? res.message : 'Try again later')
        }
    }

    render() {

        const { symbol, item, addToCartClicked, BuynowClicked, addToWishlistClicked, changeLoginState, manageUserIdState, relatedRecords, singleDetailsData, getCartData } = this.props


        var colorsnav = {
            slidesToShow: 6,
            swipeToSlide: true,
            arrows: false,
            dots: false,
            focusOnSelect: true
        };

        var product = {
            product_id: item.product_id,
            quantity: this.state.quantity,
            price: this.state.wholesale_price,
            color_id: this.state.color_id,
            size_id: this.state.size_id,
            image: this.state.images,
            main_price: this.state.price
        }

        return (

            <div className="col-lg-6 rtl-text">
                <ToastContainer />
                {/* <FontAwesomeIcon/> */}
                <div className="product-right">
                    <h2> {item.name} </h2>
                    <div className="rating">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                    </div>
                    <h4>Incentive Points (IP): {item.incentive_point}</h4>
                    <ul className="color-variant">
                        {/* <Slider {...colorsnav} asNavFor={this.props.navOne} ref={slider => (this.slider1 = slider)} className="color-variant">
                            {
                                item.product_type == '1' || item.product_type == '3' ?

                                    item.colors.map((vari, i) => {
                                        return <li className={vari.color_name} style={{ backgroundColor: `${vari.color_name}`, border: `${this.state.color_id == vari.color_id ? '1px solid #000 ' : ''}` }} key={i} title={vari.color_name} onClick={() => this.changeColor(vari.color_id, vari.wholesale_price.wholesale_price, vari.price.price, vari.image[0])}></li>
                                    })
                                    : ""}


                        </Slider> */}
                    </ul>
                    {/* <h4>
                        <del>{"₹"}{relatedRecords.product_prices[0].wholesale_price}</del>
                        <span>{"₹"}{relatedRecords.product_prices[0].wholesale_price}% off</span></h4> */}
                    {/* <h3>{symbol}{item.price-(item.price*item.discount/100)} </h3> */}

                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        {item && item.details ?
                            item.details.map((item, index) => {
                                return (
                                    <div>
                                        <div key={String(index)} style={{ borderColor: 'gray', borderWidth: 1, height: '30px', width: '30px', borderRadius: '1px', backgroundColor: item.color_name, marginRight: '5px' }}>
                                        </div>
                                        <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderColor: 'gray', borderWidth: 2, height: '30px', width: '30px', borderRadius: '1px', backgroundColor: 'white', marginRight: '5px', marginTop: '10px', marginBottom: '10px', }}>
                                            <h4 style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', marginBottom: '0px' }}>{item.size_name}</h4>
                                        </div>
                                    </div>

                                )
                            })
                            : null
                        }
                    </div>


                    <div className="product-description border-product">
                        <span className="instock-cls">{this.state.stock}</span>
                        <h6 className="product-title">quantity</h6>
                        <div className="qty-box">
                            {item && item.cart && item.cart.length > 0 ?
                                <div className="input-group">
                                    <span className="input-group-prepend">
                                        <button type="button" className="btn quantity-left-minus" onClick={() => this.minusQty(item.cart)} data-type="minus" data-field="">
                                            {/* <i className="fa fa-angle-left"></i> */}
                                            <FontAwesomeIcon color={'black'} icon={faAngleLeft} />
                                        </button>
                                    </span>

                                    <h4 className="form-control input-number">
                                        {item.cart[0].quantity}
                                    </h4>

                                    <span className="input-group-prepend">
                                        <button type="button" className="btn quantity-right-plus" onClick={() => this.plusQty(item.cart)} data-type="plus" data-field="">
                                            {/* <i className="fa fa-angle-right"></i> */}
                                            <FontAwesomeIcon color={'black'} icon={faAngleRight} />
                                        </button>
                                    </span>
                                </div>
                                : null
                            }
                        </div>
                    </div>

                    <div className="product-buttons" >
                        {item && item.cart && item.cart.length > 0 ?
                            <a
                                onClick={() => this.setState({ editModalVisibility: true })}
                                style={{ marginRight: '10px' }}
                                className="btn btn-solid">
                                EDIT QUANTITY
                            </a>
                            :
                            null
                        }
                        <a
                            onClick={() => this._addToCartWithApi(item)}
                            className="btn btn-solid">
                            add to cart
                        </a>
                        <Link to={`${process.env.PUBLIC_URL}/checkout`} className="btn btn-solid" onClick={() => BuynowClicked(item, this.state.quantity)} >buy now</Link>
                    </div>
                    {/* <h4>Delivery: Estimated delivery will be on {this.props.estimateDeliveryDate}</h4> */}
                    {/* <div
                        className="border-product">
                        <h6 className="product-title">product details</h6>
                        <p>{item.short_description}</p>
                    </div> */}
                    <div className="border-product">
                        {/* <h6 className="product-title">share it</h6> */}
                        <div className="product-icon">
                            <ul className="product-social">
                                <li><a href="https://www.facebook.com/" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://plus.google.com/discover" target="_blank"><i className="fa fa-google-plus"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                            </ul>
                            <button className="wishlist-btn" onClick={() => this._addToWishlistWithApi(product)}><i
                                className="fa fa-heart"></i><span
                                    className="title-font">Add To WishList</span>
                            </button>
                        </div>
                    </div>
                    {/* <div className="border-product">
                        <h6 className="product-title">Time Reminder</h6>
                        <div className="timer">
                            <p id="demo">
                                <span>25
                                    <span className="padding-l">:</span>
                                    <span className="timer-cal">Days</span>
                                </span>
                                <span>22
                                    <span className="padding-l">:</span>
                                    <span className="timer-cal">Hrs</span>
                                </span>
                                <span>13
                                    <span className="padding-l">:</span>
                                    <span className="timer-cal">Min</span>
                                </span>
                                <span>57
                                    <span className="timer-cal">Sec</span>
                                </span>
                            </p>
                        </div>
                    </div> */}
                </div>
                {/* <Modal open={this.state.open} onClose={this.onCloseModal} center>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Sheer Straight Kurta</h5>
                            </div>
                            <div className="modal-body">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/size-chart.jpg`} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </Modal> */}
                {/* Login modal */}
                <Modal
                    open={this.state.loginModalVisibility}
                    onClose={() => this.setState({ loginModalVisibility: false, phoneNumber: '' })}
                    center id="login_modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="SignupModalIn">
                                <div className="SignupModal modal-body clearfix">
                                    <div className="back_area">
                                        <a>Back</a>
                                    </div>
                                    <div className="modal-title">
                                        <h4>Please login to continue</h4>
                                    </div>

                                    <div className="number_box text-center">
                                        <p>Enter your phone number to Login</p>
                                        {/* <p>Notes: please send messge to whatsapp link for getting otp </p> */}

                                        {/* <a href="https://api.whatsapp.com/send?phone=919540276786&text=hello Naswiz" target="_blank"  className="text-center font1" style={{color: '#BD3042', textDecoration:'underline'}}> Whatsapp Link </a> */}

                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            defaultCountry="IN"
                                            value={this.state.phoneNumber}
                                            onChange={phoneNumber => this.setState({ phoneNumber })}
                                            inputMode="numeric"

                                        />
                                        {/* </div> */}
                                        <button
                                            className="btn next_btn_ready"
                                            id="show_verifications"
                                            type="button"
                                            onClick={() => this.setLogin()}>
                                            Next
                                        </button>
                                        <p>
                                            {/* New account */}
                                            <Link
                                                to="#"
                                                data-toggle="modal"
                                                data-target="#register_modal"
                                                onClick={() => this.setState({ loginModalVisibility: false, registerModalVisibility: true })}
                                                style={{ color: '#000' }}>
                                                <div className="mt-3 text-center">
                                                    <h6 style={{ marginTop: '1px' }}>Don't have an account ?<br></br></h6>
                                                    <h5 style={{ fontWeight: 'bold', color: colors.themeColor }}>Register</h5>
                                                </div>
                                            </Link>
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* Register modal */}
                <Modal
                    open={this.state.registerModalVisibility}
                    onClose={() => this.setState({ registerModalVisibility: false, phoneNumber: '' })}
                    center
                    id="register_modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="SignupModalIn">
                                <div className="SignupModal modal-body clearfix">
                                    <div className="back_area">
                                        <a href="javascript:void(0)">Back</a>
                                    </div>
                                    <div className="modal-title">
                                        <h4>Register</h4>
                                    </div>

                                    <div className="number_box">
                                        <p>Enter your phone number to Sign up</p>

                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            defaultCountry="IN"
                                            value={this.state.phoneNumber}
                                            onChange={phoneNumber => this.setState({ phoneNumber })}
                                            max="10"

                                        />
                                        {/* </div> */}
                                        <button className="btn next_btn_ready" id="show_verifications" type="button" onClick={() => this.setRegister()}>Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* OTP modal */}
                <Modal open={this.state.otpModalVisibility} onClose={() => this.setState({ otpModalVisibility: false, phoneNumber: '', otp1: '', otp2: '', otp3: '', otp4: '' })} center id="verify_modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="SignupModalIn">
                                <div className="SignupModal modal-body clearfix">

                                    <div className="modal-title">
                                        <h4>Phone Number Verification ????</h4>
                                    </div>
                                    <div className="otp_verification">
                                        <p>Enter 4 digit code sent to your phone   <br></br> {this.state.phoneNumber}</p>
                                        <div className="otp-box">
                                            <input
                                                style={{ color: this.state.opt_one_focused ? colors.themeColor : 'black' }}
                                                onFocus={() => this.setState({ opt_one_focused: true })}
                                                onBlur={() => this.setState({ opt_one_focused: false })}
                                                ref={inputOne => this.InputOne = inputOne}
                                                type="text"
                                                className="form-control"
                                                maxLength={1}
                                                onChange={(e) =>
                                                    this.setState({ otp1: e.target.value }, () => this._onChangeOtpOne())
                                                }
                                            />
                                            <input
                                                style={{ color: this.state.opt_two_focused ? colors.themeColor : 'black' }}
                                                onFocus={() => this.setState({ opt_two_focused: true })}
                                                onBlur={() => this.setState({ opt_two_focused: false })}
                                                ref={inputTwo => this.InputTwo = inputTwo}
                                                type="text"
                                                className="form-control"
                                                maxLength={1}
                                                onChange={(e) =>
                                                    this.setState({ otp2: e.target.value }, () => this._onChangeOtpTwo())
                                                }
                                            />
                                            <input
                                                style={{ color: this.state.opt_three_focused ? colors.themeColor : 'black' }}
                                                onFocus={() => this.setState({ opt_three_focused: true })}
                                                onBlur={() => this.setState({ opt_three_focused: false })}
                                                ref={inputThree => this.InputThree = inputThree}
                                                type="text"
                                                className="form-control"
                                                maxLength={1}
                                                onChange={(e) =>
                                                    this.setState({ otp3: e.target.value }, () => this._onChangeOtpThree())
                                                }
                                            />
                                            <input
                                                style={{ color: this.state.opt_four_focused ? colors.themeColor : 'black' }}
                                                onFocus={() => this.setState({ opt_four_focused: true })}
                                                onBlur={() => this.setState({ opt_four_focused: false })}
                                                ref={inputFour => this.InputFour = inputFour}
                                                type="text"
                                                className="form-control"
                                                maxLength={1}
                                                onChange={(e) =>
                                                    this.setState({ otp4: e.target.value }, () => this.setState({ nextButtonVisibility: true }))
                                                }
                                            />
                                        </div>
                                        <button
                                            className="next_btn_ready mt-2"
                                            // className="next_btn mt-2"
                                            type="button"
                                            onClick={() => this.verifyOtp()}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <h6 style={{ color: colors.themeColor }}> <a onClick={() => this._resendOTP()}>Resend OTP</a></h6>
                    </div>
                </Modal>

                {/* Qty modal picker */}

                <Modal
                    id="verify_modal"
                    center
                    onClose={() => this.setState({ selectedEditQty: null, editModalVisibility: false })}
                    open={this.state.editModalVisibility}>

                    <MyView
                        existinQty={item && item.cart && item.cart.lenght > 0 ? item.cart[0].quantity : undefined}
                        that={this}
                    />

                </Modal>
            </div>
        )
    }

    _addToCartWithApi = async (item) => {

        if (!this.props.isUserLogin) {
            this.setState({ loginModalVisibility: true })
        }
        else {

            var body = new FormData()
            body.append('product_id', item.product_id)
            body.append('color_id', item.details[0].color_id)
            body.append('size_id', item.details[0].size_id)
            body.append('main_price', item.details[0].wholesale_price)
            body.append('price', item.details[0].price)
            body.append('quantity', 1)

            if (item.product_images.length > 0) {
                var temp = item.product_images[0].image.includes('http://cranup.in/naswiz//') ? 'http://cranup.in/naswiz//' : item.product_images[0].image.includes('http://cranup.in/naswiz/') ? 'http://cranup.in/naswiz/' : null
                body.append('image', item.product_images[0].image.replace(temp, ''))
            }
            else {
                body.append('image', `storage/uploads/product/Medium${process.env.PUBLIC_URL}/assets/images/nia.png`) //passing static string 
            }


            var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/add-usercart', body, "Error => Add item into user cart")

            if (res && res.success == 1) {
                setTimeout(() => {
                    this.props.updateSingleProductDataAfterAction()
                    var formdata = new FormData()
                    formdata.append('device_id', this.state.uniueId)
                    this.props.getCartData('v1/get-cart-list', formdata, 'Error => While getting cart data api.')
                }, 1000)

            }
            else {
                alert(res && res.message ? res.message : 'Try again later')
            }

        }
    }

}

const MyView = props => {
    var { that, existinQty } = props
    return (
        <div style={{ marginTop: '25px', textAlign: 'center' }}>
            <label htmlFor="cars">Edit Quantity : </label><br />
            <select onChange={e => that.setState({ selectedEditQty: e.target.value })} name="cars" id="cars">
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
            </select> <br />

            <a
                onClick={() => that.changeQty(existinQty)}
                style={{ marginTop: '10px', borderWidth: 1, borderColor: 'black' }}
                className="btn btn-solid">
                add to cart
            </a>
        </div>
    )
}

const mapStateToProps = (state) => ({
    symbol: '₹ ',
    isUserLogin: state.login.isUserLogin,
    cartListArray: state.login.userCartData.records.cart_list
    // : state.login.userWishlistData.data
    // userWishlistData: state.wishlist.wishlistData, //Wishlist array from redux
})

export default connect(mapStateToProps, { removeItemFromCart, changeLoginState, manageUserIdState, getWishlistData, getCartData })(DetailsWithPrice)

const appColor = '#63A23F'

const maxQty = 10