import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import { Navigation } from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import { faBell, faCartArrowDown, faCogs, faGift, faUserCircle, faLocationArrow, faHeart, faSignInAlt, faSignOutAlt, faTicketAlt, faTimes, faUser, faUsers, faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect, withRouter } from 'react-router-dom'
import Modal from 'react-responsive-modal';
import { connect } from "react-redux";
import { NaswizHelperApi } from '../../NaswizHelper';
import BounceLoader from 'react-spinners/BounceLoader'
import { addUserData, changeLoginState, removeUserData, manageUserIdState, getWishlistData } from "../../actions/index";
import Notification from '../../Notification'
import { Input, DatePicker } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import InnerDashboardMyOrders from './InnterDashboardComponents/ID_MyOrders'
import InnterDashboardIncentivePoint from './InnterDashboardComponents/ID_IncentivePoints'
import InnerDashboardOrderIssueView from './InnterDashboardComponents/ID_OrderIssueView'
import InnterDashboardNotification from './InnterDashboardComponents/ID_Notification'
import InnerDashboardReferFriends from './InnterDashboardComponents/ID_ReferFriend'
import InnerDashboardMyWallet from './InnterDashboardComponents/ID_MyWallet'
import InnerDashboardMyCart from './InnterDashboardComponents/ID_MyCart'
import ProductListing from "../../components/collection/common/product-listing"
import Moment from 'moment';
import { Helmet } from 'react-helmet';
import Seo from '../Seo';

class InnerDashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            activeView: '',
            show: false,
            showProfile: true,
            arr_userAddress: [],
            userName: '',
            userPhone: '',
            userPincode: '',
            userAddress: '',
            userAddressType: '',
            loading_userAddress: undefined,
            // User Address Enter
            idNo: '',
            name: '',
            address: '',
            arr_country: [],
            arr_city: [],
            arr_state: [],
            pincode: '',
            mobileNumber: '',
            aadharNumber: '',
            gstNumber: '',
            addType: undefined,
            selectedCountryId: undefined,
            selectedStateId: undefined,
            selectedCityId: undefined,
            loading_addNewUserAddress: false,
            selectedFile: null,
            // Edit profile states
            _id_number: '',
            edited_name: '',
            edited_email_id: '',
            edited_dob: '',
            edited_gender: undefined,
            edited_marital_status: '',
            unmarried: '',
            married: '',
            edited_anniversary_date: '',
            edited_profile_image: null,
            edited_aadhar_card_image: null,
            edited_pan_card_image: null,
            openEdit: false,
            // Edit address states
            edit_address_object: {},
            isEditAddressObject: false,
            edit_address_name: '',
            edit_address_address: '',
            edit_address_pincode: '',

            edit_gst_no: '', // edit for address
            edit_aadhar_no: '', // edit for address
            edit_number: '',
            edit_aadhar_image_address: '',
            edit_aadhar_imagepath_address: '',
            edit_address_addressType: undefined,
            edit_address_id_no: '',
            // Store Issue Modual
            arr_store_ticket: [],
            arr_storeList: [],
            store_id: '',
            store_visitedDate: '',
            store_subject: '',
            store_description: '',
            store_image: null,
            _orderId: '0',
            regexp: /^[0-9\b]+$/,
            regexpname: /^[a-zA-Z]/,
            regexpnamenumber: /[^a-z A-Z 0-9]+/g,
            _offerList: [],
            _mainSlug: 'offer',
            _notoficationList: [],
            __cuurentPath: '',
            loading_wallet: true,
            layoutColumns: 4,
            searchResultArray: props.location.state,
            goToDashboard: false,
            // 
            loading_getAllFilterData: false,
            _wishlistcleare: false,
            _deleteId: '',
            _deleteindex: 0,
            _page: 1,
            show_product: 12,
            _noOfPage: [],
            _page_wishlist: 1,
            _noOfPage_wislist: [],
            wishlistArray: [],
            _noOfPage12: [],
            _page12: 1
        }
    }

    onHandleTelephoneChange = e => {
        let telephone = e.target.value;

        // if value is not blank, then test the regex
        if (telephone === '' || this.state.regexp.test(telephone)) {
            this.setState({ [e.target.id]: telephone })
        }
        else {
            return;
        }
    };
    // new regex for name add / edit
    handleNameChange = evt => {
        const newName = evt.target.value.replace(
            /[^a-zA-Z\s]/g,
            ""
        );
        if (newName === '' || this.state.regexpname.test(newName)) {
            this.setState({ [evt.target.id]: newName })
        }
        else {
            return;
        }
    };

    onHandleNameChange = e => {
        let NameChange = e.target.value;

        // if value is not blank, then test the regex
        if (NameChange === '' || this.state.regexpname.test(NameChange)) {
            this.setState({ [e.target.id]: NameChange })
        }
        else {
            return;
        }
    };

    // onHandleNameandNumberChange = e => {
    //     let NameandNumberChange = e.target.value;

    //     // if value is not blank, then test the regex
    //     if (NameandNumberChange === '' || this.state.regexpnamenumber.test(NameandNumberChange)) {
    //         this.setState({ [e.target.id]: NameandNumberChange })
    //     }
    //     else {
    //         return;
    //     }
    // };

    componentDidMount = async () => {
        this.getWishlist()
        // this.props.getWishlistData('v1/wishlist-details', "Error => Wishlist data");
        /* document.getElementById("common").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/common.css`);
*/
        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 3000);
        if (!this.props.isUserLogin) {

            this.setState({ goToDashboard: true })
            return
        }

        await this.getOfferList()
        await this.setState({ loading_userAddress: true })
        await this._initApi()
        if (this.props.location && this.props.location.state && this.props.location.state === 'show_cart_tab') {
            this.setState({ activeView: ListOfViews.MyCart })
        }
        await this.setProfileData()
        this.setState({ _cuurentPath: this.props.location.pathname })

        await this.setCurrentView(this.props.location)

    }

    getWishlist = async () => {
        var uniueId = await localStorage.getItem('deviceId')
        var userID = this.props.userID
        var body = new FormData()
        body.append('customer_id', userID)
        body.append('device_id', uniueId)
        // console.log("id---", uniueId, this.state._page_wishlist)

        const res = await this.props.getWishlistData('v1/wishlist-details?page=' + this.state._page_wishlist, body, "Error => Wishlist data");
        // console.log("-----", res)
        // const res = await NaswizHelperApi.GET_WITH_TOKEN('v1/wishlist-details/' + uniueId + "&page=" + this.state._page_wishlist, "Error => Wishlist data")

        if (res && res.success == 1) {

            this.setState({
                wishlistArray: res.data.data,
            })

            var tmp = []
            for (let i = 0; i < res.data.last_page; i++) {
                tmp.push(i)
            }
            this.setState({ _noOfPage_wislist: tmp })
        }
        //  var tmp = []
        //             for (let i = 0; i < res.records.last_page; i++) {
        //                 tmp.push(i)
        //             }
        //             this.setState({ _noOfPage: tmp })
    }

    changeQty = (e) => {
        this.setState({ quantity: parseInt(e.target.value) })
    }
    async componentWillReceiveProps(nextProps) {
        if (this.state._cuurentPath != nextProps.location.pathname) {
            await this.getOfferList()
            await this._initApi()

            await this.setCurrentView(nextProps.location)

        }
    }

    async setCurrentView(propsvar) {
        var locationpath = propsvar.pathname
        this.setState({ _cuurentPath: locationpath })
        if (locationpath == '/page/user/notification') {
            await this.setState({ activeView: ListOfViews.Notification })
        } else if (locationpath == '/page/user/myorder') {
            await this.setState({ activeView: ListOfViews.MyOrder })
        } else if (locationpath == '/page/user/myoffer') {
            await this.setState({ activeView: ListOfViews.MyOffers })
        } else if (locationpath == '/page/user/myprofile') {
            await this.setState({ activeView: ListOfViews.MyProfile })
        } else if (locationpath == '/page/user/myaddress') {
            await this.setState({ activeView: ListOfViews.MyAddress })
        } else if (locationpath == '/page/user/loyaltypoints') {
            await this.setState({ activeView: ListOfViews.LoyaltyPoints })
        } else if (locationpath == '/page/user/myfavourites') {
            await this.setState({ activeView: ListOfViews.MyFavourites })
        }
        else if (locationpath == '/page/user/orderissue') {
            await this.setState({ activeView: ListOfViews.OrderIssue })
        }
        else if (locationpath == '/page/user/storeissue') {
            await this.setState({ activeView: ListOfViews.StoreIssue })
        }
        else if (locationpath == '/page/user/referafreiend') {
            await this.setState({ activeView: ListOfViews.ReferAFriend })
        } else if (locationpath == '/page/user/mywallet') {
            await this.setState({ activeView: ListOfViews.MyWallet })
        } else {
            if (propsvar.itemviewname != undefined) {
                await this.setState({ activeView: propsvar.itemviewname })
            }
            else {
                await this.setState({ activeView: 'My Address' })
            }
        }
        document.querySelector(".loader-wrapper").style = "display: none";


    }
    setProfileData() {

        if (this.props.userData) {
            var image_full_path = this.props.userData && this.props.userData.image_full_path ? this.props.userData.image_full_path : `${process.env.PUBLIC_URL}/assets/images/no-image.png`
            var aadhar_image_full_path = this.props.userData && this.props.userData.aadhar_image_full_path ? this.props.userData.aadhar_image_full_path : `${process.env.PUBLIC_URL}/assets/images/no-image.png`
            var pan_image_full_path = this.props.userData && this.props.userData.pan_image_full_path ? this.props.userData.pan_image_full_path : `${process.env.PUBLIC_URL}/assets/images/no-image.png`
            this.setState({
                _id_number: this.props.userData.id_number,
                edited_name: this.props.userData.name, edited_email_id: this.props.userData.email, edited_dob: this.props.userData.dob,
                edited_anniversary_date: this.props.userData.dob_anniversary, edited_gender: this.props.userData.gender, edited_marital_status: this.props.userData.marital,
            })
        }


    }

    getOfferList = async () => {
        var uniueId = localStorage.getItem('deviceId');
        var userID = this.props.userID
        var body = new FormData()
        if (userID) {
            body.append('customer_id', userID)
        }
        body.append('device_id', uniueId)
        body.append('paginate', this.state.show_product)
        body.append('page', this.state._page)

        var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/product/offer', body, 'Error => _getProductBySlug api')
        if (res && res.success == 1) {
            this.setState({
                _offerList: res.records.data,
            })
            var tmp = []
            for (let i = 0; i < res.records.last_page; i++) {
                tmp.push(i)
            }
            this.setState({ _noOfPage: tmp })
        }
    }

    _initApi = async () => {
        // Address
        await this._getCountry()
        await this._customerDetails()
        await this._getAllUserAddressApi()
        // Address

        // Store
        await this._storeIssueModual()
        await this.getNotificationList()
        // Store
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };
    onCloseModal = () => {
        this.setState({ open: false });
    };
    onEditShow = () => {
        this.setState({ activeView: 'Edit Profile' })
    }

    onOpenEditModal = async (item, index) => {
        this.setState({
            openEdit: true,
            edit_address_name: item.fullname,
            edit_address_address: item.address,
            edit_address_pincode: item.pincode,
            edit_address_addressType: item.is_status,
            selectedCountryId: item.country_id,
            selectedStateId: item.state_id,
            selectedCityId: item.city_id,
            edit_number: item.number,
            edit_aadhar_no: item.aadhar_no !== 'null' ? item.aadhar_no : '',
            edit_gst_no: item.gst_no !== 'null' ? item.gst_no : '',
            // edit_aadhar_no: item.aadhar_no,
            // edit_gst_no: item.gst_no,
            edit_aadhar_image_address: item.image_full_path,
            edit_address_object: item,
            edit_address_id_no: item.address_id_no,
            isEditAddressObject: true,
        });

        if (item.country_id != '' && item.country_id != undefined) {
            await this._getStateUponCountry(item.country_id)
        }
        if (item.state_id != '' && item.state_id != undefined) {
            await this._getCityUponState(item.state_id)
        }
    };


    _getAllUserAddressApi = async () => {

        // this.setState({ loading_userAddress: true }, async () => {

        var res = await NaswizHelperApi.GET_WITH_TOKEN('v1/view-address', 'Error => v1/view-address')
        if (res && res.success == 1) {
            this.setState({
                loading_userAddress: false,
                arr_userAddress: res.message
            })
        }
        else {
            this.setState({ loading_userAddress: false })
        }
        // })
    }

    _deleteUserAddress = async (id, index) => {
        var body = new FormData()
        body.append('id', id)
        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/delete-address', body, 'Error => Delete user address api')
        if (res && res.success == 1) {
            var tempUserAddress = this.state.arr_userAddress.filter((x, i) => i !== index)
            // Notification("Success", res.message)
            Notification('success', 'Success!', res.message)
            setTimeout(() => {
                this.setState({
                    arr_userAddress: tempUserAddress,
                })

            }, 1000)
        }
        else if (res && res.success == 2) {
            Notification('error', 'Error!', res.message)
        }
        else {
            Notification('error', 'Error!', "Something went wrong please try again later")
        }
        setTimeout(() => {
            this.setState({ _wishlistcleare: false })
        }, 1000)
    }

    _onChangeText = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })

    }

    onChangeID = (evt) => {
        const newId = evt.target.value.replace(
            /[^a-zA-Z0-9]/g,
            ""
        );
        this.setState({ [evt.target.id]: newId })
    }

    onChangeGST = (evt) => {
        const newId = evt.target.value.replace(
            /[^a-zA-Z0-9]/g,
            ""
        );
        this.setState({ [evt.target.id]: newId })
    }

    _onChangeTextEditProfile = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })

    }

    _getCountry = async () => {

        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/countries', null, "Error => Get country api")
        if (res && res.success == 1) {
            this.setState({
                arr_country: res.message
            })
            if (this.state.selectedCountryId == '' || this.state.selectedCountryId == undefined) {
                this.setState({ selectedCountryId: 101 })
                this._getStateUponCountry(101)

            }
        }
    }

    _getStateUponCountry = async (cId) => {

        if (cId === 'Select Country') {
            this._resetCountryField()
        }

        else {

            this.setState({ selectedCountryId: cId })

            var formData = new FormData()
            formData.append('country_id', cId)

            var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/state', formData, "Error => Get state upon county api")
            if (res && res.success == 1) {
                this.setState({
                    arr_state: res.message
                })

                if (res.message.length > 0) {
                    if (this.state.selectedStateId != '' && this.state.selectedStateId != undefined) {

                    } else {
                        this._getCityUponState(res.message[0].state_id)

                    }
                }
            }
        }
    }

    _getCityUponState = async (sId) => {

        this.setState({ selectedStateId: sId })

        var formData = new FormData()
        formData.append('state_id', sId)
        formData.append('country_id', this.state.selectedCountryId)

        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/cities', formData, "Error => Get state upon county api")
        if (res && res.success == 1) {
            this.setState({
                arr_city: res.message
            })
        }
    }

    _addNewAddress = async () => {

        var { idNo, name, address, mobileNumber, aadharNumber, gstNumber, addType, selectedCountryId, selectedStateId, selectedCityId, pincode, selectedFile } = this.state

        var isVadlid = true;
        if (idNo === '') {
            isVadlid = false;
            Notification('error', 'Error!', 'Please enter ID Number')
        }
        else if (idNo.length < 5) {
            isVadlid = false;

            Notification('error', 'Error!', 'Please enter valid ID Number')
        }
        if (name === '') {
            isVadlid = false;

            Notification('error', 'Error!', 'Please enter name')
        }
        else if (name.length < 3) {
            isVadlid = false;

            Notification('error', 'Error!', 'Please enter valid name')
        }
        if (address === '') {
            isVadlid = false;

            Notification('error', 'Error!', 'Please enter address !')
        }
        else if (address.length < 10) {
            isVadlid = false;

            Notification('error', 'Error!', 'Please enter valid address !')
        }
        if (selectedCountryId == undefined) {
            isVadlid = false;

            Notification('error', 'Error!', 'Please select country !')
        }
        if (selectedStateId == undefined) {
            isVadlid = false;

            Notification('error', 'Error!', 'Please select state !')
        }
        if (selectedCityId == undefined) {
            isVadlid = false;

            Notification('error', 'Error!', 'Please select city !')
        }
        if (pincode === '') {
            isVadlid = false;

            Notification('error', 'Error!', 'Please enter pincode !')
        }
        if (mobileNumber === '') {
            isVadlid = false;

            Notification('error', 'Error!', 'Please enter mobile number !')
        }
        else if (mobileNumber.length !== 10) {
            isVadlid = false;

            Notification('error', 'Error!', 'Please enter valid mobile number !')
        }

        else if (aadharNumber && aadharNumber.length !== 12) {
            isVadlid = false;

            Notification('error', 'Error!', 'Please enter valid aadhar number !')
        }

        if (gstNumber && gstNumber.length !== 15) {
            isVadlid = false;

            Notification('error', 'Error!', 'Please enter GST number !')
        }
        if (addType == undefined) {
            isVadlid = false;

            Notification('error', 'Error!', 'Please select address type !')
        }

        if (isVadlid) {
            var formData = new FormData()
            formData.append('address_id_no', idNo)
            formData.append('fullname', name)
            formData.append('number', mobileNumber)
            formData.append('pincode', pincode)
            formData.append('address', address)
            formData.append('city_id', selectedCityId)
            formData.append('state_id', selectedStateId)
            formData.append('country_id', selectedCountryId)
            formData.append('is_status', addType)
            if (gstNumber) {
                formData.append('gst_no', gstNumber)
            }
            if (aadharNumber) {
                formData.append('aadhar_no', aadharNumber)
            }
            if (selectedFile !== null) {
                formData.append('aadhar_image', selectedFile, selectedFile.name)
            }

            for (var i of formData.entries()) {
            }

            this.setState({ loading_addNewUserAddress: true }, async () => {

                var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/add-address', formData, "Error => Add new user address api")
                if (res && res.success == 1) {
                    await this.setState({ loading_userAddress: true })
                    await this._getAllUserAddressApi()

                    this.setState({
                        open: false,
                        loading_addNewUserAddress: false
                    })
                    Notification('success', 'Success!', 'Address Added Successfully')
                }
                else {
                    this.setState({
                        loading_addNewUserAddress: false
                    })
                }
            })
        } else {

        }
    }

    _customerDetails = async () => {

        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/customers-detail', null, "Error => Customer details api")
        if (res && res.success == 1) {
            await this.props.addUserData(res.record)
        }

    }
    getNotificationList = async () => {

        var res = await NaswizHelperApi.GET_WITH_TOKEN('v1/notification', null, "Error => Customer details api")
        if (res && res.success == 1) {
            await this.setState({ _notoficationList: res.record })
        }

    }

    _updateUserProfile = async () => {

        var isValid = true;
        var { _id_number, edited_name, edited_email_id, edited_dob, edited_anniversary_date, edited_gender, edited_marital_status, edited_profile_image, edited_aadhar_card_image, edited_pan_card_image } = this.state

        var formData = new FormData()
        if (_id_number) {
            formData.append('id_number', _id_number)
            Notification('success', 'Success', 'Update Suceessfull')

        } else {
            isValid = false;

            Notification('error', 'Error!', 'Please enter Id Number')

        }
        if (edited_name) {
            if (edited_name.length < 3) {
                isValid = false;
                Notification('error', 'Error!', 'Please enter valid name')

            }
            else {
                formData.append('name', edited_name)
            }

        } else {
            isValid = false;

            Notification('error', 'Error!', 'Please enter name')

        }

        if (edited_email_id) {

            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

            if (re.test(edited_email_id) == false) {
                isValid = false;

                Notification('error', 'Error!', 'Please enter valid email id')

            }
            else {
                formData.append('email', edited_email_id)
            }

        } else {
            isValid = false;

            Notification('error', 'Error!', 'Please enter email id')

        }
        if (edited_dob) {

            if (edited_dob.length !== 10) {
                isValid = false;
                Notification('error', 'Error!', 'Please select valid date of birth')

            }
            else {
                formData.append('dob', edited_dob)
            }
        } else {
            isValid = false;

            Notification('error', 'Error!', 'Please select date of birth')

        }
        if (this.state.edited_marital_status == '1') {
            if (edited_anniversary_date) {
                if (edited_anniversary_date.length !== 10) {
                    isValid = false;
                    Notification('error', 'Error!', 'Please select valid anniversary')

                }
                formData.append('dob_anniversary', edited_anniversary_date)
            } else {
                isValid = false;

                Notification('error', 'Error!', 'Please select anniversary')

            }
        }

        if (edited_gender) {

            if (edited_gender === 'Gender') {
                isValid = false;

                Notification('error', 'Error!', 'Please select gender')

            }
            else {
                formData.append('gender', edited_gender)
            }

        } else {
            isValid = false;

            Notification('error', 'Error!', 'Please select gender')

        }
        if (edited_marital_status) {
            formData.append('marital', edited_marital_status)
        }
        if (edited_profile_image) {
            formData.append('image', edited_profile_image, edited_profile_image.name)
        }
        if (edited_aadhar_card_image) {
            formData.append('aadhar_image', edited_aadhar_card_image, edited_aadhar_card_image.name)
        }
        if (edited_pan_card_image) {
            formData.append('pan_image', edited_pan_card_image, edited_pan_card_image.name)
        }

        var _lenght = 0
        for (var i of formData.entries()) {
            _lenght++
        }

        setTimeout(async () => {
            if (_lenght > 0) {

                if (isValid) {
                    var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/update-profile', formData, 'Error => Update profile api')

                    if (res && res.success == 1) {
                        this._customerDetails().then(() => {

                        })
                        this.setState({ activeView: ListOfViews.MyProfile })
                    }
                    else {

                    }

                } else {

                }


            }
            else {
                alert('Update atleast one field')
            }
        }, 1000)
    }

    _editAddressApi = async () => {
        var { edit_address_object, edit_address_id_no, edit_address_name, edit_address_address, selectedCountryId, selectedStateId, selectedCityId, edit_address_pincode, edit_address_addressType, edit_number, edit_aadhar_no, edit_gst_no, edit_aadhar_imagepath_address } = this.state
        var isValid = true;
        var body = new FormData()
        body.append('id', edit_address_object.id)

        if (edit_address_id_no != undefined) {
            body.append('address_id_no', edit_address_id_no)
        }
        else {
            Notification('error', 'Error!', 'Please enter address ID number')
            isValid = false
        }
        if (edit_address_name.length > 2) {
            body.append('fullname', edit_address_name)
        }
        else {
            Notification('error', 'Error!', 'Please enter valid name')
            isValid = false
        }

        if (edit_address_address.length > 9) {
            body.append('address', edit_address_address)
        }
        else {
            Notification('error', 'Error!', 'Please enter valid address')
            isValid = false
        }
        if (selectedCountryId != undefined) {
            body.append('country_id', selectedCountryId)
        }
        else {
            Notification('error', 'Error!', 'Please select country')
            isValid = false
        }
        if (selectedStateId != undefined) {
            body.append('state_id', selectedStateId)
        }
        else {
            Notification('error', 'Error!', 'Please select state')
            isValid = false
        }
        if (selectedCityId != undefined) {
            body.append('city_id', selectedCityId)
        }
        else {
            Notification('error', 'Error!', 'Please select city')
            isValid = false
        }
        if (edit_address_pincode != '') {
            body.append('pincode', edit_address_pincode)
        }
        else {
            Notification('error', 'Error!', 'Please enter valid pincode')
            isValid = false
        }
        if (edit_number != '') {
            body.append('number', edit_number)
        }
        else {
            Notification('error', 'Error!', 'Please enter valid mobile number')
            isValid = false
        }
        body.append('aadhar_no', edit_aadhar_no)
        body.append('gst_no', edit_gst_no)

        // if (edit_aadhar_no != '') {
        // }
        // else {
        //     Notification('error', 'Error!', 'Please enter valid adhar card number')
        //     isValid = false
        // }
        // if (edit_gst_no != '') {
        //     body.append('gst_no', edit_gst_no)
        // }
        // else {
        //     Notification('error', 'Error!', 'Please enter valid GST number')
        //     isValid = false
        // }

        if (edit_aadhar_imagepath_address != '') {
            body.append('aadhar_image', edit_aadhar_imagepath_address, edit_aadhar_imagepath_address.name)
        }
        // else {
        //     Notification('error', 'Error!', 'Please select adhar card image')
        //     isValid = false
        // }
        if (edit_address_addressType) {
            body.append('is_status', edit_address_addressType)
        } else {
            isValid = false
        }

        var _lenght = 0
        for (var i of body.entries()) {
            _lenght++
            // console.log("i : ", i)
        }

        // setTimeout(async () => {
        if (_lenght > 1 && isValid) {
            var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/edit-address', body, "Error => Edit user address api.")
            // console.log(res)
            if (res && res.success == 1) {
                await this._resetEditAddressState()
                await this.setState({ loading_userAddress: true })
                await this._getAllUserAddressApi()
            }
            else {
                alert(res && res.message ? res.message : 'Try again later')
                this._resetEditAddressState()
            }
        }
        else {
        }
        // }, 1000)

    }
    logout = () => {
        this.props.changeLoginState(false)
        this.props.removeUserData()
        this.props.manageUserIdState(false)
        localStorage.clear()
        this.props.history.replace("/")
    }
    _resetEditAddressState = () => {
        this.setState({
            openEdit: false,
            edit_address_object: {},
            isEditAddressObject: false,
            edit_address_name: '',
            edit_address_address: '',
            edit_address_pincode: '',
            edit_number: '',
            edit_aadhar_no: '',
            edit_gst_no: '',
            edit_address_id_no: '',
            edit_address_addressType: undefined,
            selectedCountryId: undefined,
            selectedStateId: undefined,
            selectedCityId: undefined,
        })
    }

    _resetCountryField = () => {

    }

    handlePagination = async (value2) => {
        var value = value2.selected + 1
        if (value <= this.state._noOfPage.length) {
            await this.setState({ _page: value })
            await this.getOfferList()
        }

    }

    handlePagination_wishlist = async (value2) => {
        var value = value2.selected + 1
        if (value <= this.state._noOfPage_wislist.length) {
            await this.setState({ _page_wishlist: value })
            await this.getWishlist()
        }

    }

    async shortRecords(value) {
        if (value == 'price') {
            await this.setState({ isRating: false, isPopularity: false, isHighToLow: true, isLowToHigh: false, isOldest: false, isLatest: false, })

        } else if (value == 'price-desc') {
            await this.setState({ isRating: false, isPopularity: false, isHighToLow: false, isLowToHigh: true, isOldest: false, isLatest: false, })

        } else if (value == 'date') {
            this.setState({ isRating: false, isPopularity: false, isOldest: false, isLatest: true, isHighToLow: false, isLowToHigh: false })
        } else if (value == 'popularity') {
            this.setState({ isRating: false, isPopularity: true, isOldest: false, isLatest: false, isHighToLow: false, isLowToHigh: false })
        } else if (value == 'rating') {
            this.setState({ isRating: true, isPopularity: true, isOldest: false, isLatest: false, isHighToLow: false, isLowToHigh: false })
        } else if (value == 'oldest') {
            this.setState({ isRating: false, isPopularity: false, isOldest: true, isLatest: false, isHighToLow: false, isLowToHigh: false })

        } else {
            this.setState({ isRating: false, isPopularity: false, isOldest: false, isLatest: false, isHighToLow: false, isLowToHigh: false })

        }

        await this.setState({ _shortValue: value })
        await this._searchUponSelectedFilter()
    }

    _searchUponSelectedFilter = async () => {
        var { filterSizeID, filterColorSlug, filterBrand, filterOccasion, filterCategory, isLatest, isOldest, isHighToLow, isLowToHigh, value, isPopularity, isRating } = this.state

        var body = new FormData()

        if (isLatest) {
            body.append('latest', 1)
        }
        if (isOldest) {
            body.append('oldest', 1)
        }
        // if (filterColorSlug.length > 0) {
        //     var CSVColor = filterColorSlug.map((item) => { return item.slug }).join(",")
        //     body.append('colors', CSVColor)
        // }
        // if (filterSizeID.length > 0) {
        //     var CSVSize = filterSizeID.map((item) => { return item.id }).join(",")
        //     body.append('sizes', CSVSize)
        // }
        // if (filterCategory.length > 0) {
        //     var CSVCategory = filterCategory.map((item) => { return item.slug }).join(",")
        //     body.append('category', CSVCategory)
        // }
        // if (filterBrand.length > 0) {
        //     var CSVBrand = filterBrand.map((item) => { return item.name }).join(",")
        //     body.append('brand', CSVBrand)
        // }
        // if (filterOccasion.length > 0) {
        //     var CSVOccation = filterOccasion.map((item) => { return item.name }).join(",")
        //     body.append('occasion', CSVOccation)
        // }
        if (isHighToLow) {
            body.append('price_low_to_high', 1)
        }
        if (isLowToHigh) {
            body.append('price_high_to_low', 1)
        }
        if (isPopularity) {
            body.append('popularity', 1)
        }
        if (isRating) {
            body.append('ratting_avg', 1)
        }
        // if (value.min > 0) {
        //     body.append('startprice', value.min)
        // }
        // if (value.max !== 0) {
        //     body.append('endprice', value.max)
        // }


        for (var pair of body.entries()) {
            // console.log("pair", pair)
        }

        var res = await NaswizHelperApi.POST_WITHOUT_TOKEN(`v1/product/offer`, body, "Error => filter products api.")
        if (res && res.success == 1) {
            this.setState({
                _offerList: res.records.data,
            })
            var tmp = []
            for (let i = 0; i < res.records.last_page; i++) {
                tmp.push(i)
            }
            this.setState({ _noOfPage: tmp })
        }
        // if (res && res.success) {
        //     this.setState({
        //         arr_getProductBySlug: res.records.data
        //     })
        //     var tmp = []
        //     for (let i = 0; i < res.records.last_page; i++) {
        //         tmp.push(i)
        //     }
        //     this.setState({ _noOfPage: tmp })
        // }
        // else {
        //     Notification('error', 'Error!', 'No search result found!')
        // }

    }

    handleSubmit(event) {
        this._addNewAddress()
        event.preventDefault();
    }
    render() {
        // console.log(this.props.wishlistArray)
        if (this.state.goToDashboard) {
            return (
                <Redirect to={{
                    pathname: `/`,
                }} />
            )
        }
        var id_no = this.props.userData && this.props.userData.id_number ? this.props.userData.id_number : ''
        var name = this.props.userData && this.props.userData.name ? this.props.userData.name : 'Not Available'
        var email = this.props.userData && this.props.userData.email ? this.props.userData.email : 'Not Available'
        var number = this.props.userData && this.props.userData.number ? this.props.userData.number : 'Not Available'
        var dob = this.props.userData && this.props.userData.dob ? this.props.userData.dob : 'Not Available'
        var marital = this.props.userData && this.props.userData.marital ? this.props.userData.marital : 'Not Available'
        var gender = this.props.userData && this.props.userData.gender ? this.props.userData.gender : 'Not Available'
        var dob_anniversary = this.props.userData && this.props.userData.dob_anniversary ? this.props.userData.dob_anniversary : 'Not Available'
        var image_full_path = this.props.userData && this.props.userData.image_full_path ? this.props.userData.image_full_path : `${process.env.PUBLIC_URL}/assets/images/no-image.png`
        var aadhar_image_full_path = this.props.userData && this.props.userData.aadhar_image_full_path ? this.props.userData.aadhar_image_full_path : `${process.env.PUBLIC_URL}/assets/images/no-image.png`
        var pan_image_full_path = this.props.userData && this.props.userData.pan_image_full_path ? this.props.userData.pan_image_full_path : `${process.env.PUBLIC_URL}/assets/images/no-image.png`

        // dateDMY: Moment(dob).format('DD-MM-YYYY'),



        var { _id_number, edited_name, edited_email_id, edited_dob, edited_anniversary_date, edited_gender, edited_marital_status, edited_profile_image, edited_aadhar_card_image, edited_pan_card_image, edit_aadhar_image_address, edit_aadhar_imagepath_address } = this.state

        var { activeView, showProfile, userName, userPhone, userPincode, isEditAddressObject, edit_address_object } = this.state
        if (activeView === ListOfViews.MyCart) {
            return <Redirect to={`${process.env.PUBLIC_URL}/cart`} />
        }
        if (activeView === ListOfViews.MyFavourites) {
        }

        return (
            <div className="">
                {this.props.isUserLogin ?
                    <Breadcrumb title={'My profile'} /> : null
                }

                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                {/* <div className="account-sidebar">
                                    <a className="popup-btn">
                                        my account
                                    </a>
                                </div> */}
                                <div className="dashboard-left">
                                    {/* <div className="collection-mobile-back">
                                        <span className="filter-back">
                                            <i className="fa fa-angle-left" aria-hidden="true"></i> back
                                        </span>
                                    </div> */}
                                    {
                                        this.props.isUserLogin ?
                                            <div className="block-content hide-arrow test">
                                                <Navigation
                                                    // you can use your own router's api to get pathname
                                                    activeItemId={this.state.activeView}
                                                    onSelect={({ itemId }) => {
                                                        console.log(itemId);
                                                        if (itemId != 'My Account' && itemId != 'My Tickets') {
                                                            this.setState({ activeView: itemId })
                                                        }
                                                        if (itemId == 'My Profile') {
                                                            const location = {
                                                                pathname: '/page/user/myprofile',
                                                                itemviewname: itemId
                                                            }
                                                            this.props.history.replace(location)
                                                        } else if (itemId == 'My Address') {
                                                            const location = {
                                                                pathname: '/page/user/myaddress',
                                                                itemviewname: itemId
                                                            }
                                                            this.props.history.replace(location)
                                                        } else if (itemId == 'My Order') {
                                                            const location = {
                                                                pathname: '/page/user/myorder',
                                                                itemviewname: itemId
                                                            }
                                                            this.props.history.replace(location)
                                                        }
                                                        else if (itemId == 'My Offers') {
                                                            const location = {
                                                                pathname: '/page/user/myoffer',
                                                                itemviewname: itemId
                                                            }
                                                            this.props.history.replace(location)
                                                        }
                                                        else if (itemId == 'My Favourites') {
                                                            const location = {
                                                                pathname: '/page/user/myfavourites',
                                                                itemviewname: itemId
                                                            }
                                                            this.props.history.replace(location)
                                                        }
                                                        else if (itemId == 'Loyalty Points') {
                                                            const location = {
                                                                pathname: '/page/user/loyaltypoints',
                                                                itemviewname: itemId
                                                            }
                                                            this.props.history.replace(location)

                                                        } else if (itemId == 'Loyalty Points') {
                                                            const location = {
                                                                pathname: '/page/user/loyaltypoints',
                                                                itemviewname: itemId
                                                            }
                                                            this.props.history.replace(location)
                                                        } else if (itemId == 'Notification') {
                                                            const location = {
                                                                pathname: '/page/user/notification',
                                                                itemviewname: itemId
                                                            }
                                                            this.props.history.replace(location)
                                                        } else if (itemId == ListOfViews.OrderIssue) {
                                                            const location = {
                                                                pathname: '/page/user/orderissue',
                                                                itemviewname: itemId
                                                            }
                                                            this.props.history.replace(location)
                                                        } else if (itemId == ListOfViews.StoreIssue) {
                                                            const location = {
                                                                pathname: '/page/user/storeissue',
                                                                itemviewname: itemId
                                                            }
                                                            this.props.history.replace(location)
                                                        } else if (itemId == ListOfViews.MyWallet) {
                                                            const location = {
                                                                pathname: '/page/user/mywallet',
                                                                itemviewname: itemId
                                                            }
                                                            this.props.history.replace(location)


                                                        } else if (itemId == ListOfViews.ReferAFriend) {
                                                            const location = {
                                                                pathname: '/page/user/referafreiend',
                                                                itemviewname: itemId
                                                            }
                                                            this.props.history.replace(location)


                                                        } else if (itemId == ListOfViews.Logout) {

                                                            this.logout()

                                                        }

                                                    }}
                                                    items={[


                                                        // profile
                                                        {
                                                            title: ListOfViews.MyProfile,
                                                            itemId: ListOfViews.MyProfile,
                                                            elemBefore: () => <FontAwesomeIcon icon={faUserCircle} />,
                                                        },
                                                        // addressmy
                                                        {
                                                            title: ListOfViews.MyAddress,
                                                            itemId: ListOfViews.MyAddress,
                                                            elemBefore: () => <FontAwesomeIcon icon={faLocationArrow} />,
                                                        },
                                                        // 2
                                                        {
                                                            title: ListOfViews.MyOrder,
                                                            itemId: ListOfViews.MyOrder,
                                                            elemBefore: () => <FontAwesomeIcon icon={faGift} />,
                                                        },
                                                        // 3
                                                        {
                                                            title: ListOfViews.MyFavourites,
                                                            itemId: ListOfViews.MyFavourites,
                                                            elemBefore: () => <FontAwesomeIcon icon={faHeart} />,
                                                        },
                                                        {
                                                            title: ListOfViews.MyOffers,
                                                            itemId: ListOfViews.MyOffers,
                                                            elemBefore: () => <FontAwesomeIcon icon={faBell} />,
                                                        },
                                                        {
                                                            title: ListOfViews.MyCart,
                                                            itemId: ListOfViews.MyCart,
                                                            elemBefore: () => <FontAwesomeIcon icon={faCartArrowDown} />,
                                                        },

                                                        {
                                                            title: ListOfViews.MyWallet,
                                                            itemId: ListOfViews.MyWallet,
                                                            elemBefore: () => <FontAwesomeIcon icon={faWallet} />,
                                                        },
                                                        {
                                                            title: ListOfViews.LoyaltyPoints,
                                                            itemId: ListOfViews.LoyaltyPoints,
                                                            elemBefore: () => <FontAwesomeIcon icon={faCogs} />,
                                                        },
                                                        {
                                                            title: ListOfViews.ReferAFriend,
                                                            itemId: ListOfViews.ReferAFriend,
                                                            elemBefore: () => <FontAwesomeIcon icon={faUsers} />,
                                                        },
                                                        {
                                                            title: ListOfViews.Notification,
                                                            itemId: ListOfViews.Notification,
                                                            elemBefore: () => <FontAwesomeIcon icon={faBell} />,
                                                        },
                                                        {
                                                            title: ListOfViews.MyTickets,
                                                            itemId: ListOfViews.MyTickets,
                                                            elemBefore: () => <FontAwesomeIcon icon={faTicketAlt} />,
                                                            subNav: [
                                                                {
                                                                    title: ListOfViews.OrderIssue,
                                                                    itemId: ListOfViews.OrderIssue,
                                                                },
                                                                {
                                                                    title: ListOfViews.StoreIssue,
                                                                    itemId: ListOfViews.StoreIssue,
                                                                },
                                                            ],
                                                        },
                                                        {
                                                            title: ListOfViews.Logout,
                                                            itemId: ListOfViews.Logout,
                                                            elemBefore: () => <FontAwesomeIcon icon={faSignOutAlt} />,
                                                        },
                                                    ]}
                                                />
                                            </div> : null
                                    }
                                </div>
                            </div>


                            {activeView === ListOfViews.MyProfile ?

                                <div className="col-lg-9 order-lg-last dashboard-content">

                                    {showProfile ?

                                        <div className="my_profile_inside">

                                            <h2>Personal Information</h2>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="inner_profile_in">
                                                        <p>ID Number<span>

                                                            {id_no ? id_no : ''}
                                                        </span></p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="inner_profile_in">
                                                        <p>Name<span>{name ? name : ''}</span></p>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="inner_profile_in">

                                                        <p>Email ID<span>{email ? email : ''}</span></p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="inner_profile_in">

                                                        <p>Mobile Number<span>{number ? number : ''}</span></p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="inner_profile_in">

                                                        <p>Date Of Birth<span>{Moment(dob).format("DD-MM-YYYY")}</span></p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="inner_profile_in">
                                                        <p>Gender<span>{gender && gender == 1 ? 'MALE' : gender && gender == 2 ? 'FEMALE' : gender && gender == 3 ? 'OTHER' : ''}</span></p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="inner_profile_in">
                                                        <p>Marital Status<span>{marital && marital === '1' ? 'Married' : marital && marital === '2' ? 'Single' : ''}</span></p>
                                                    </div>
                                                </div>
                                                <div className={marital && marital === '2' ? 'col-md-6 d-none' : 'col-md-6'} >
                                                    <div className="inner_profile_in">

                                                        <p>Date Of Anniversary<span>{Moment(dob_anniversary).format("DD-MM-YYYY")}</span></p>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="allImg no-profile-img">
                                                        <div className="text-center">
                                                            <h5 style={{ fontWeight: 'bold', color: 'black' }}>Profile Image</h5>
                                                            <img src={image_full_path ? image_full_path : `${process.env.PUBLIC_URL}assets/images/no-image.png`} alt="" />
                                                        </div>
                                                        <div className="text-center">
                                                            <h5 style={{ fontWeight: 'bold', color: 'black' }}>Aadhar Image</h5>
                                                            <img src={aadhar_image_full_path ? aadhar_image_full_path : `${process.env.PUBLIC_URL}assets/images/no-image.png`} alt="" />
                                                        </div>
                                                        <div className="text-center">
                                                            <h5 style={{ fontWeight: 'bold', color: 'black' }}>Pan Image</h5>
                                                            <img src={pan_image_full_path ? pan_image_full_path : `${process.env.PUBLIC_URL}assets/images/no-image.png`} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 text-center mt-3">
                                                    <a className="mt58 btn theme-button profile_btn" style={{ color: '#fff' }} onClick={() => this.setState({ activeView: ListOfViews.EditProfile })}>
                                                        Edit Profile
                                                    </a>
                                                </div>
                                            </div>
                                            {/* </form> */}
                                        </div> : ''}

                                </div>
                                :
                                activeView === ListOfViews.MyAddress ?
                                    <div className="col-lg-9 order-lg-last dashboard-content my_dashboards">
                                        {/* <Helmet>
                                            <title>Manage Address - Naswiz Retails Pvt Ltd</title>
                                            <meta name="og_title" property="og:title" content="Manage Address - Naswiz Retails Pvt Ltd" />
                                            <meta name="twitter:title" content="Manage Address - Naswiz Retails Pvt Ltd" />
                                            <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                                            <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                                            <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                                            <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                                        </Helmet> */}
                                        <Seo title="Manage Address" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                                        {this.state.arr_userAddress.length > 0 ?
                                            <div className="card card_bg_color">
                                                {this.state.arr_userAddress.length > 0 ? <div className="card-header" type="button" data-toggle="modal" data-target="#AddressModal" onClick={this.onOpenModal} style={{ backgroundColor: '#bd3042' }}>
                                                    <i className="fa fa-plus-circle" aria-hidden="true"></i> Add New Address
                                                </div> : null}
                                                {
                                                    this.state.loading_userAddress == true || this.state.loading_userAddress == undefined ?

                                                        <div style={{ margin: '0 auto', marginTop: '10px', marginBottom: '10px' }}>
                                                            <BounceLoader
                                                                color='#BD3042'
                                                                size={50}
                                                            />
                                                        </div>
                                                        :
                                                        this.state.arr_userAddress.length > 0 ?
                                                            this.state.arr_userAddress.map((item, index) => {
                                                                return (
                                                                    <div key={index} className="card-body">
                                                                        <div className="grid_row grid_row_address">
                                                                            <div className="grid_content">
                                                                                <div className="addressList">
                                                                                    <h4>
                                                                                        {item.is_status == 1 ?
                                                                                            <i className="fa fa-home"></i> :
                                                                                            item.is_status == 2 ?
                                                                                                <i className="fa fa-building"></i> :
                                                                                                item.is_status == 3 ?
                                                                                                    <i className="fa fa-location-arrow"></i> : null
                                                                                        }
                                                                                        {item.is_status == 1 ?
                                                                                            'Home' :
                                                                                            item.is_status == 2 ?
                                                                                                'Office' :
                                                                                                item.is_status == 3 ?
                                                                                                    'Other' : null
                                                                                        }
                                                                                    </h4>
                                                                                    <h5>
                                                                                        {item.address_id_no}-  {item.fullname}
                                                                                    </h5>


                                                                                    <address>
                                                                                        {item.address}
                                                                                    </address>
                                                                                </div>
                                                                            </div>

                                                                            <div className="grid_actions">
                                                                                <div className="actions_btns">
                                                                                    <div className="left_edit">
                                                                                        <a data-toggle="modal" data-target="#editaddressModal" onClick={() => this.onOpenEditModal(item, index)} style={{ color: '#BD3042' }}><span className="edit_delete"><img src={`${process.env.PUBLIC_URL}/assets/images/edit.png`} alt="" /></span>Edit</a>
                                                                                    </div>
                                                                                    <div onClick={() => this.setState({ _wishlistcleare: !this.state._wishlistcleare, _deleteId: item.id, _deleteindex: index })} className="right_delete">
                                                                                        <a href="javascript:void(0);"><span className="edit_delete"><img src={`${process.env.PUBLIC_URL}/assets/images/delete.png`} alt="" /></span>Delete</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            null

                                                }
                                            </div>


                                            : <div className="default-div">
                                                <span>You have no saved addresses</span>
                                                <p>Tell us where you want your orders delivered</p>
                                                <button type="button" data-toggle="modal" data-target="#AddressModal" onClick={this.onOpenModal} >Add New Address </button>
                                            </div>
                                        }

                                        <div className={this.state._wishlistcleare ? 'wishlist-popup active' : 'wishlist-popup'}>
                                            <div className="wishlist-bg" onClick={() => this.setState({ _wishlistcleare: !this.state._wishlistcleare })}>
                                            </div>
                                            <div className="wishlist-box">
                                                <div className="text-center">
                                                    <h2>Are you sure you want to delete this address?</h2>
                                                    <button className="btn continue_btns" onClick={() => this._deleteUserAddress(this.state._deleteId, this.state._deleteindex)} >Delete</button>
                                                    <button className="btn cancel_btns ml-2" onClick={() => this.setState({ _wishlistcleare: !this.state._wishlistcleare })}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    activeView === ListOfViews.MyOrder ?
                                        <InnerDashboardMyOrders
                                            onClickIssue={async (orderitem) => {
                                                await this.setState({ activeView: ListOfViews.OrderIssue, _orderId: orderitem.id})
                                            }}
                                            showCreateOrderIssueView={() => this.setState({ activeView: ListOfViews.OrderIssue })}
                                        />
                                        :
                                        activeView === ListOfViews.MyFavourites ?
                                            <div className="col-lg-9">
                                                <Helmet>
                                                    <title>My Favourites  - Naswiz Retails Pvt Ltd</title>
                                                    <meta name="og_title" property="og:title" content="My Favourites  - Naswiz Retails Pvt Ltd" />
                                                    <meta name="twitter:title" content="My Favourites  - Naswiz Retails Pvt Ltd" />
                                                    <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                                                    <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                                                    <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                                                    <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                                                </Helmet>
                                                <div className="dashboard-right ">
                                                    {this.props.wishlistArray && this.props.wishlistArray.length > 0 ?
                                                        <div className="collection-wrapper">
                                                            {/* <div className="select-custom small-width">
                                                                <select name="orderby" className="form-control" onChange={(event) => {
                                                                    this.shortRecords(event.target.value)
                                                                }}>
                                                                    <option value="menu_order" defaultValue="selected">Default</option>
                                                                    <option value="popularity">Popularity</option>
                                                                    <option value="rating">Average rating</option>
                                                                    <option value="date">Newness</option>
                                                                    <option value="price">Price: low to high</option>
                                                                    <option value="price-desc">Price: high to low</option>
                                                                </select>
                                                            </div> */}
                                                            <div className="collection-content">
                                                                <div className="page-main-content">
                                                                    {/*Products Listing Component*/}
                                                                    <ProductListing
                                                                        colSize={this.state.layoutColumns}
                                                                        records={this.props.wishlistArray}
                                                                        // records={this.state.wishlistArray}
                                                                        // _from={'wishlistnew'}
                                                                        _from={'from_wishlist'}
                                                                        page={this.state._page_wishlist}
                                                                        updateDataAfterAction={() => this.getWishlist()}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="" >
                                                                {this.state._noOfPage_wislist.length > 1 ?
                                                                    <ReactPaginate
                                                                        previousLabel={'<'}
                                                                        nextLabel={'>'}
                                                                        breakLabel={'...'}
                                                                        breakClassName={'page-item'}
                                                                        breakLinkClassName={'page-link'}
                                                                        pageCount={this.state._noOfPage_wislist.length}
                                                                        marginPagesDisplayed={1}
                                                                        pageRangeDisplayed={5}
                                                                        onPageChange={(page) => this.handlePagination_wishlist(page)}
                                                                        containerClassName={'pagination toolbox-item'}
                                                                        pageClassName={'page-item'}
                                                                        pageLinkClassName={'page-link'}
                                                                        previousClassName={'page-item'}
                                                                        previousLinkClassName={'page-link'}
                                                                        nextClassName={'page-item'}
                                                                        nextLinkClassName={'page-link'}
                                                                        activeClassName={'active'}
                                                                    />
                                                                    : null}
                                                            </div>
                                                        </div>
                                                        :

                                                        <div className="text-center section-b-space mt-5 no-found" >
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4 ml-auto mr-auto" />
                                                            <h3>You haven't selected your favourite products. Click on the bottom right corner of the product to mark as your favourite product.  </h3>
                                                            {/* <p className="mt-3">Please check if you have misspelt something or try searching with other words.</p> */}
                                                            <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-primary profile_btn mt-3">continue shopping</Link>
                                                        </div>
                                                    }
                                                </div>
                                            </div> :
                                            activeView === ListOfViews.MyOffers ?
                                                <div className="col-lg-9 order-lg-last dashboard-content">
                                                    <Helmet>
                                                        <title>My Offers  - Naswiz Retails Pvt Ltd</title>
                                                        <meta name="og_title" property="og:title" content="My Offers  - Naswiz Retails Pvt Ltd" />
                                                        <meta name="twitter:title" content="My Offers  - Naswiz Retails Pvt Ltd" />
                                                        <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                                                        <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                                                        <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                                                        <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                                                    </Helmet>
                                                    <div className="select-custom small-width">
                                                        <select name="orderby" className="form-control" onChange={(event) => {
                                                            this.shortRecords(event.target.value)
                                                        }}>
                                                            <option value="menu_order" defaultValue="selected">Default</option>
                                                            <option value="popularity">Popularity</option>
                                                            <option value="rating">Average rating</option>
                                                            <option value="date">Newness</option>
                                                            <option value="price">Price: low to high</option>
                                                            <option value="price-desc">Price: high to low</option>
                                                        </select>
                                                    </div>
                                                    <div className="my-offers-inside">
                                                        <ProductListing
                                                            records={this.state._offerList}
                                                            _from={'from_offer'}
                                                            mainSlug={this.state._mainSlug}
                                                            updateDataAfterAction={() => this.getOfferList()}
                                                            colSize={4}
                                                        />
                                                    </div>
                                                    <div className="" >
                                                        {this.state._noOfPage.length > 1 ?
                                                            <ReactPaginate
                                                                previousLabel={'<'}
                                                                nextLabel={'>'}
                                                                breakLabel={'...'}
                                                                breakClassName={'page-item'}
                                                                breakLinkClassName={'page-link'}
                                                                pageCount={this.state._noOfPage.length}
                                                                marginPagesDisplayed={1}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={(page) => this.handlePagination(page)}
                                                                containerClassName={'pagination toolbox-item'}
                                                                pageClassName={'page-item'}
                                                                pageLinkClassName={'page-link'}
                                                                previousClassName={'page-item'}
                                                                previousLinkClassName={'page-link'}
                                                                nextClassName={'page-item'}
                                                                nextLinkClassName={'page-link'}
                                                                activeClassName={'active'}
                                                            />
                                                            : null}
                                                    </div>
                                                </div>
                                                :
                                                activeView === ListOfViews.MyCart ?
                                                    <InnerDashboardMyCart />
                                                    :
                                                    activeView === ListOfViews.MyWallet ?
                                                        <InnerDashboardMyWallet />
                                                        :
                                                        activeView === ListOfViews.LoyaltyPoints ?
                                                            <InnterDashboardIncentivePoint />
                                                            :
                                                            activeView === ListOfViews.ReferAFriend ?
                                                                <InnerDashboardReferFriends />
                                                                :
                                                                activeView === ListOfViews.Notification ?
                                                                    <InnterDashboardNotification notoficationList={this.state._notoficationList} />
                                                                    :
                                                                    activeView === ListOfViews.OrderIssue ?
                                                                        <InnerDashboardOrderIssueView orderId={this.state._orderId} />
                                                                        :
                                                                        activeView === ListOfViews.StoreIssue ?
                                                                            <div className="col-lg-9 order-lg-last dashboard-content">
                                                                                {this.state.loading_wallet ?
                                                                                    <div>
                                                                                        <div className="loader-wrapper">
                                                                                            <div className="loader"></div>
                                                                                        </div>
                                                                                    </div> :
                                                                                    <div className="notification issue_inner">
                                                                                        <div className="row">
                                                                                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                                                                <div className="notification_head ticket_list">
                                                                                                    <h3>Store Issue</h3>
                                                                                                    <div className="add_ticket">
                                                                                                        <a onClick={() => this.setState({ activeView: ListOfViews.CreateTicket })} className="btn theme-button">
                                                                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/add_ticket.png`} className="mr-2" /> Create Ticket
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="ticket_listing_row">
                                                                                            {this.state.arr_store_ticket.length > 0 ?
                                                                                                this.state.arr_store_ticket.map((item, index) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <div key={index} className="ticket_listing">
                                                                                                                <Helmet>
                                                                                                                    <title>Store Issue  - Naswiz Retails Pvt Ltd</title>
                                                                                                                    <meta name="og_title" property="og:title" content="Store Issue  - Naswiz Retails Pvt Ltd" />
                                                                                                                    <meta name="twitter:title" content="Store Issue  - Naswiz Retails Pvt Ltd" />
                                                                                                                    <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                                                                                                                    <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                                                                                                                    <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                                                                                                                    <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                                                                                                                </Helmet>
                                                                                                                <div className="product_issue">
                                                                                                                    <div className="ticket_id">
                                                                                                                        <p>Ticket ID :
                                                                                                                            <span>
                                                                                                                                <a href="#" className="id_no" data-toggle="tooltip" data-placement="top" title="" data-original-title="view details">#{item.ticket_number}</a>
                                                                                                                                <div className="tooltip fade bs-tooltip-top show">
                                                                                                                                    <div className="arrow"></div>
                                                                                                                                    <div className="tooltip-inner">view details</div>
                                                                                                                                </div>
                                                                                                                            </span>
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div className="pending_issue">
                                                                                                                        <span>{item.is_active == 0 ? 'Open' : 'Closed'}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="issue_subject">
                                                                                                                    <p>Subject :<span>  {item.subject}</span></p>
                                                                                                                </div>
                                                                                                                <div className="issue_date">
                                                                                                                    <p>Created On : {moment(item.created_at).format("DD-MM-YYYY")}</p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    )
                                                                                                }) :

                                                                                                <div className="ticket_listing">
                                                                                                    No Ticket Available
                                                                                                </div>

                                                                                            }

                                                                                            {this.state._noOfPage12.length > 1 ?
                                                                                                <ReactPaginate
                                                                                                    previousLabel={'<'}
                                                                                                    nextLabel={'>'}
                                                                                                    breakLabel={'...'}
                                                                                                    breakClassName={'page-item'}
                                                                                                    breakLinkClassName={'page-link'}
                                                                                                    pageCount={this.state._noOfPage12.length}
                                                                                                    marginPagesDisplayed={1}
                                                                                                    pageRangeDisplayed={5}
                                                                                                    onPageChange={(page) => this.handlePagination_issue(page)}
                                                                                                    containerClassName={'pagination toolbox-item'}
                                                                                                    pageClassName={'page-item'}
                                                                                                    pageLinkClassName={'page-link'}
                                                                                                    previousClassName={'page-item'}
                                                                                                    previousLinkClassName={'page-link'}
                                                                                                    nextClassName={'page-item'}
                                                                                                    nextLinkClassName={'page-link'}
                                                                                                    activeClassName={'active'}
                                                                                                // forcePage={this.state._page - 1}
                                                                                                />
                                                                                                : null}
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            :
                                                                            activeView === ListOfViews.Logout ?
                                                                                <div className="col-lg-9">
                                                                                    <div className="dashboard-right">
                                                                                        <div className="dashboard">
                                                                                            <div className="page-title">
                                                                                                <h2>{activeView}</h2>
                                                                                            </div>
                                                                                            <div className="welcome-msg">
                                                                                                <p>Hello, MARK JECNO !</p>
                                                                                                <p>From your My Account Dashboard you have the ability to view a snapshot of
                                                                                                    your recent account activity and update your account information. Select
                                                                                                    a link below to view or edit information.</p>
                                                                                            </div>
                                                                                            <div className="box-account box-info">
                                                                                                <div className="box-head">
                                                                                                    <h2>Account Information</h2>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col-sm-6">
                                                                                                        <div className="box">
                                                                                                            <div className="box-title">
                                                                                                                <h3>Contact Information</h3>
                                                                                                                <a href="#">Edit</a>
                                                                                                            </div>
                                                                                                            <div className="box-content">
                                                                                                                <h6>MARK JECNO</h6>
                                                                                                                <h6>MARk-JECNO@gmail.com</h6>
                                                                                                                <h6><a href="#">Change Password</a></h6>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-sm-6">
                                                                                                        <div className="box">
                                                                                                            <div className="box-title">
                                                                                                                <h3>Newsletters</h3>
                                                                                                                <a href="#">Edit</a>
                                                                                                            </div>
                                                                                                            <div className="box-content">
                                                                                                                <p>
                                                                                                                    You are currently not subscribed to any newsletter.
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <div className="box">
                                                                                                        <div className="box-title">
                                                                                                            <h3>Address Book</h3>
                                                                                                            <a href="#">Manage Addresses</a>
                                                                                                        </div>
                                                                                                        <div className="row">
                                                                                                            <div className="col-sm-6">
                                                                                                                <h6>Default Billing Address</h6>
                                                                                                                <address>
                                                                                                                    You have not set a default billing address.<br />
                                                                                                                    <a href="#">Edit Address</a>
                                                                                                                </address>
                                                                                                            </div>
                                                                                                            <div className="col-sm-6">
                                                                                                                <h6>Default Shipping Address</h6>
                                                                                                                <address>
                                                                                                                    You have not set a default shipping address.<br />
                                                                                                                    <a href="#">Edit Address</a>
                                                                                                                </address>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div> :
                                                                                activeView === ListOfViews.EditProfile ?
                                                                                    <div className="col-lg-9 order-lg-last dashboard-content">
                                                                                        <div className="my_profile_inside">
                                                                                            <h2>Edit Profile</h2>
                                                                                            {/* <form> */}
                                                                                            <div className="row">
                                                                                                <div className="col-md-6">
                                                                                                    {/* <p style={{ fontWeight: 'bold' }}>ID Number<sup className="top_star">*</sup></p> */}
                                                                                                    <div className="form-group">
                                                                                                        <input
                                                                                                            id="_id_number"
                                                                                                            type="text"
                                                                                                            className="form-control forms_inputs"
                                                                                                            placeholder={'Enter your ID Number'}
                                                                                                            value={_id_number}
                                                                                                            onChange={e => this._onChangeTextEditProfile(e)}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    {/* <p style={{ fontWeight: 'bold' }}>Name<sup className="top_star">*</sup></p> */}
                                                                                                    <div className="form-group">
                                                                                                        <input
                                                                                                            id="edited_name"
                                                                                                            type="text"
                                                                                                            className="form-control forms_inputs"
                                                                                                            placeholder={'Enter your name'}
                                                                                                            value={edited_name}
                                                                                                            onChange={e => this._onChangeTextEditProfile(e)}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    {/* <p style={{ fontWeight: 'bold' }}>Email<sup className="top_star">*</sup></p> */}
                                                                                                    <div className="form-group">
                                                                                                        <input
                                                                                                            id="edited_email_id"
                                                                                                            type="email"
                                                                                                            className="form-control forms_inputs"
                                                                                                            onChange={e => this._onChangeTextEditProfile(e)}
                                                                                                            placeholder={'Enter your email ID'}
                                                                                                            value={edited_email_id}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    {/* <p style={{ fontWeight: 'bold' }}>Email<sup className="top_star">*</sup></p> */}
                                                                                                    <div className="form-group">
                                                                                                        <input
                                                                                                            id="edited_name"
                                                                                                            type="text"
                                                                                                            className="form-control forms_inputs"
                                                                                                            // onChange={e => this._onChangeTextEditProfile(e)}
                                                                                                            placeholder={'Mobile Number'}
                                                                                                            value={number}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    {/* <p style={{ fontWeight: 'bold' }}>Date of birth<sup className="top_star">*</sup></p> */}
                                                                                                    <div className="form-group">
                                                                                                        <input
                                                                                                            id="edited_dob"
                                                                                                            type="date"
                                                                                                            className="form-control forms_inputs datepicker"
                                                                                                            placeholder={'Select DOB'}
                                                                                                            value={edited_dob}
                                                                                                            onChange={e => this._onChangeTextEditProfile(e)}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-md-6">
                                                                                                    {/* <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>Gender<sup className="top_star">*</sup></p> */}
                                                                                                    <div className="form-group">
                                                                                                        <div className="form-group">
                                                                                                            {
                                                                                                                this.state.edited_gender !== undefined ?
                                                                                                                    <select
                                                                                                                        id="edited_gender"
                                                                                                                        onChange={e => this._onChangeTextEditProfile(e)}
                                                                                                                        value={this.state.edited_gender}
                                                                                                                        className="form-control slect_pst">
                                                                                                                        <option key={0} value={null}>Gender</option>
                                                                                                                        <option key={1} value={1}>Male</option>
                                                                                                                        <option key={2} value={2}>Female</option>
                                                                                                                        <option key={3} value={3}>Other</option>
                                                                                                                    </select>
                                                                                                                    :
                                                                                                                    <select
                                                                                                                        id="edited_gender"
                                                                                                                        onChange={e => this._onChangeTextEditProfile(e)}
                                                                                                                        value={this.state.edited_gender == 1 ? 'MALE' : this.state.edited_gender == 2 ? 'FEMALE' : gender && gender == 3 ? 'OTHER' : ''}
                                                                                                                        className="form-control slect_pst">
                                                                                                                        <option key={0} value={null}>Gender</option>
                                                                                                                        <option key={1} value={1}>Male</option>
                                                                                                                        <option key={2} value={2}>Female</option>
                                                                                                                        <option key={3} value={3}>Other</option>
                                                                                                                    </select>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <form>
                                                                                                        <div className="mart_status edit_profile_in">
                                                                                                            <p className="pb-0  ">Marital Status</p>
                                                                                                            <div style={{ marginTop: '10px' }} className="form-check-inline" >
                                                                                                                <input
                                                                                                                    id="edited_marital_status"
                                                                                                                    type="radio"
                                                                                                                    className="form-check-input"
                                                                                                                    // defaultChecked={marital && marital === '1' ? true : false}
                                                                                                                    checked={this.state.edited_marital_status === '1' ? true : false}
                                                                                                                    value="1"
                                                                                                                    name="Married"
                                                                                                                    onChange={e => this.setState({ edited_marital_status: '1' })}
                                                                                                                />
                                                                                                                <label className="form-check-label" htmlFor="edited_marital_status" onClick={e => this.setState({ edited_marital_status: '1' })}>Married </label>
                                                                                                            </div>
                                                                                                            <div className="form-check-inline">
                                                                                                                <input
                                                                                                                    id="edited_marital_status"
                                                                                                                    type="radio"
                                                                                                                    name="UnMarried"
                                                                                                                    className="form-check-input"
                                                                                                                    // defaultChecked={marital && marital === '2' ? true : false}
                                                                                                                    checked={this.state.edited_marital_status === '2' ? true : false}
                                                                                                                    value="2"
                                                                                                                    onChange={e => this.setState({ edited_marital_status: '2' })}
                                                                                                                />
                                                                                                                <label className="form-check-label" htmlFor="edited_Unmarried_status" onClick={e => this.setState({ edited_marital_status: '2' })}>Unmarried </label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </form>
                                                                                                </div>
                                                                                                {this.state.edited_marital_status == '1' ? <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <div className="mart_status mb-0 edit_profile_in">
                                                                                                            <p className="pb-0">Anniversary Date</p>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="edited_anniversary_date"
                                                                                                            type="date"
                                                                                                            className="form-control forms_inputs datepicker"
                                                                                                            placeholder={edited_anniversary_date}
                                                                                                            value={edited_anniversary_date}
                                                                                                            onChange={e => this._onChangeTextEditProfile(e)}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div> : null}
                                                                                                <div className="row mt-4 mb-3">
                                                                                                    <div className="col-md-4">
                                                                                                        <div className="gap_top_ot gap_top">

                                                                                                            <div className="allImgNo no-profile-img">
                                                                                                                <div className="text-center">
                                                                                                                    <h5 style={{ fontWeight: 'bold', color: 'black' }}>Upload Photograph</h5>
                                                                                                                    <img src={image_full_path} />
                                                                                                                </div>
                                                                                                            </div>


                                                                                                            <div className="row mb-3 mb-md-0">
                                                                                                                <div className="col-md-12">
                                                                                                                    <div className="input-group">
                                                                                                                        <input
                                                                                                                            onChange={(e) => this.setState({ edited_profile_image: e.target.files[0] })}
                                                                                                                            type="file"
                                                                                                                            className="form-control forms_inputs"
                                                                                                                            ref={refUserImage => this.fileUserImage = refUserImage}
                                                                                                                        />
                                                                                                                        <div
                                                                                                                            onClick={() => {
                                                                                                                                this.setState({ edited_profile_image: null })
                                                                                                                                this.fileUserImage.value = "";
                                                                                                                            }}
                                                                                                                            className="input-group-append">
                                                                                                                            <spa className="input-group-text">
                                                                                                                                <i className="fa fa-times" aria-hidden="true" /></spa>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="col-md-4">
                                                                                                        <div className="gap_top_ot gap_top">

                                                                                                            <div className="allImgNo no-profile-img">
                                                                                                                <div className="text-center">
                                                                                                                    <h5 style={{ fontWeight: 'bold', color: 'black' }}>Upload Adhar Card</h5>
                                                                                                                    <img src={aadhar_image_full_path} />
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="row mb-3 mb-md-0">
                                                                                                                <div className="col-md-12">
                                                                                                                    <div className="input-group">
                                                                                                                        <input
                                                                                                                            onChange={(e) => this.setState({ edited_aadhar_card_image: e.target.files[0] })}
                                                                                                                            type="file"
                                                                                                                            className="form-control forms_inputs"

                                                                                                                            ref={refAadharmImage => this.fileAadharImage = refAadharmImage}
                                                                                                                        />
                                                                                                                        <div
                                                                                                                            onClick={() => {
                                                                                                                                this.setState({ edited_aadhar_card_image: null })
                                                                                                                                this.fileAadharImage.value = "";
                                                                                                                            }}
                                                                                                                            className="input-group-append">
                                                                                                                            <span className="input-group-text"><i className="fa fa-times" aria-hidden="true" /></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-md-4">
                                                                                                        <div className="gap_top_ot gap_top">
                                                                                                            <div className="allImgNo no-profile-img">
                                                                                                                <div className="text-center">
                                                                                                                    <h5 style={{ fontWeight: 'bold', color: 'black' }}>Upload Pan Card</h5>
                                                                                                                    <img src={pan_image_full_path} />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-12">
                                                                                                                    <div className="input-group">
                                                                                                                        <input
                                                                                                                            onChange={(e) => this.setState({ edited_pan_card_image: e.target.files[0] })}
                                                                                                                            type="file"
                                                                                                                            className="form-control forms_inputs"
                                                                                                                            ref={refPanImage => this.filePanImage = refPanImage}
                                                                                                                        />
                                                                                                                        <div
                                                                                                                            onClick={() => {
                                                                                                                                this.setState({ edited_pan_card_image: null })
                                                                                                                                this.filePanImage.value = "";
                                                                                                                            }}
                                                                                                                            className="input-group-append">
                                                                                                                            <span className="input-group-text"><i className="fa fa-times" aria-hidden="true" /></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="form-footer text-center mt-3 mb-0">
                                                                                                <div className="form-footer-center edit_profile_f">
                                                                                                    <button type="button" onClick={() => this._updateUserProfile()} className="btn theme-button profile_btn" style={{ color: '#fff' }}>Update</button>
                                                                                                    <button type="button" className="btn theme-button profile_btn ml-2" style={{ color: '#fff' }} onClick={() => this.setState({ activeView: ListOfViews.MyProfile })}>Back</button>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* </form> */}
                                                                                        </div>
                                                                                    </div> :
                                                                                    activeView === 'Create Ticket' ?
                                                                                        <div className="col-md-9 col-lg-9">
                                                                                            <div className="inside_ticket">

                                                                                                <div className="notification_head">
                                                                                                    <span> <FontAwesomeIcon onClick={() => this.setState({ activeView: ListOfViews.StoreIssue })} icon={faTimes} />   <h3>Store Issue Ticket</h3></span>
                                                                                                </div>
                                                                                                <form>
                                                                                                    <div className="ticket_div personal_detail_form ticket_form">
                                                                                                        <div className="row">
                                                                                                            <div className="col-sm-6 col-xs-12">
                                                                                                                <Input
                                                                                                                    value={this.state.store_id}
                                                                                                                    onChange={e => this.setState({ store_id: e.target.value })}
                                                                                                                    placeholder='Enter store name'
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className="col-sm-6 col-xs-12">
                                                                                                                <div style={{ width: '100%' }} className="ant-input">
                                                                                                                    <DatePicker
                                                                                                                        style={{ width: '100%' }}
                                                                                                                        format="DD-MM-YYYY"
                                                                                                                        placeholder='Select Date'
                                                                                                                        onChange={(a, b) => this.setState({ store_visitedDate: b })}

                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="row">
                                                                                                            <div className="col-sm-6 col-sm-12 col-lg-6 col-xs-12 input_container">
                                                                                                                <Input
                                                                                                                    value={this.state.store_description}
                                                                                                                    onChange={e => this.setState({ store_description: e.target.value })}
                                                                                                                    placeholder='Enter description'
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>

                                                                                                    <button onClick={() => this._createNewStoreIssue()} type='button' className="btn btn-primary continue_btns">
                                                                                                        SUBMIT
                                                                                                    </button>

                                                                                                    <button
                                                                                                        onClick={() => this.setState({ activeView: ListOfViews.StoreIssue })}
                                                                                                        type='button'
                                                                                                        className="btn cancel_btns ml-2">
                                                                                                        CANCEL
                                                                                                    </button>
                                                                                                </form>
                                                                                            </div>
                                                                                        </div>
                                                                                        :

                                                                                        null
                            }
                        </div>
                    </div>
                    {/* Add address */}
                    <Modal open={this.state.open} onClose={this.onCloseModal} center>
                        <div className="editaddressModal vertical-align-center">


                            <div className="adderess_inside">
                                <h4>Add New Delivery Address</h4>
                                <p>Please enter the accurate address, it will help us to serve you better</p>

                            </div>
                            <div className="inside_form_fields">
                                <form>
                                    <div className="theme-form address-form">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="">ID No.<sup className="top_star">*</sup></label>
                                                    <input
                                                        id="idNo"
                                                        onChange={(e) => this.onChangeID(e)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter your ID Number"
                                                        inputMode="numeric"
                                                        value={this.state.idNo}
                                                        autoFocus={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Name<sup className="top_star">*</sup></label>
                                                    <input
                                                        id="name"
                                                        onChange={(e) => this.handleNameChange(e)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter your name"
                                                        value={this.state.name}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-12 col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Address<sup className="top_star">*</sup></label>
                                                    <input
                                                        id="address"
                                                        onChange={(e) => this._onChangeText(e)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter your address"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className="col-12 col-md-4 col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="">Country <sup className="top_star">*</sup></label>
                                                    <select
                                                        value={this.state.selectedCountryId}
                                                        onChange={(e) => this._getStateUponCountry(e.target.value)} className="form-control">
                                                        <option value={undefined}>Select Country</option>
                                                        {

                                                            this.state.arr_country.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item.id}>{item.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4 col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="">State<sup className="top_star">*</sup></label>
                                                    <select
                                                        onChange={(e) => this._getCityUponState(e.target.value)} className="form-control">
                                                        <option value={undefined}>Select State</option>
                                                        {
                                                            this.state.selectedCountryId !== undefined ?
                                                                this.state.arr_state.map((item, index) => {
                                                                    return (
                                                                        <option key={index} value={item.state_id}>{item.name}</option>
                                                                    )
                                                                })
                                                                :
                                                                <option value={undefined}>First Select Country</option>
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4 col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="">City<sup className="top_star">*</sup></label>
                                                    <select
                                                        onChange={(e) => this.setState({ selectedCityId: e.target.value })} className="form-control">
                                                        <option value={undefined}>Select City</option>
                                                        {
                                                            this.state.selectedStateId !== undefined ?
                                                                this.state.arr_city.map((item, index) => {
                                                                    return (
                                                                        <option key={index} value={item.city_id}>{item.name}</option>
                                                                    )
                                                                })
                                                                :
                                                                <option value={undefined}>First Select State</option>
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Pin code <sup className="top_star">*</sup></label>
                                                    <input
                                                        onChange={(e) => this.onHandleTelephoneChange(e)}
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter your pincode"
                                                        inputMode="numeric"
                                                        id="pincode"
                                                        maxLength={6}
                                                        value={this.state.pincode}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Mobile Number<sup className="top_star">*</sup></label>
                                                    <input
                                                        type="tel"
                                                        maxLength={10}
                                                        className="form-control"
                                                        onChange={(e) => this.onHandleTelephoneChange(e)}
                                                        placeholder="Enter your mobile number"
                                                        inputMode="numeric"
                                                        id="mobileNumber"
                                                        value={this.state.mobileNumber}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Adhar Card Number</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter your Adhar Card Number"
                                                        onChange={(e) => this.onHandleTelephoneChange(e)}
                                                        inputMode="numeric"
                                                        id="aadharNumber"
                                                        maxLength={12}
                                                        value={this.state.aadharNumber}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="">GST Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter your GST Number"
                                                        onChange={(e) => this.onChangeGST(e)}
                                                        id="gstNumber"
                                                        maxLength={15}
                                                        value={this.state.gstNumber.toLocaleUpperCase()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-12">
                                                <div className="form-group upload-adhar">
                                                    <label htmlFor="">Upload Adhar Card</label>
                                                    <div className="input-group">
                                                        <input
                                                            onChange={(e) => this.setState({ selectedFile: e.target.files[0] })}
                                                            type="file"
                                                            className="top_rmv_g form-control"
                                                            ref={ref => this.fileInput = ref}
                                                        />
                                                        <div
                                                            onClick={() => {
                                                                this.setState({ selectedFile: null })
                                                                this.fileInput.value = "";
                                                            }}
                                                            className="input-group-append">
                                                            <span className="input-group-text">
                                                                <i className="fa fa-times" aria-hidden="true" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="address_type">
                                            <label className="radio-inline">
                                                <label className="radio-inline"><input onChange={() => this.setState({ addType: 1 })} type="radio" name="optradio" /> Home</label>
                                                <label className="radio-inline"><input onChange={() => this.setState({ addType: 2 })} type="radio" name="optradio" /> Office</label>
                                                <label className="radio-inline"><input onChange={() => this.setState({ addType: 3 })} type="radio" name="optradio" /> Other</label>
                                            </label>
                                        </div>
                                        <div className="both_buttons d-flex">
                                            <button type="button"
                                                onClick={() => this._addNewAddress()}
                                                className="btn continue_btns">Continue</button>
                                            <button type="button" className="btn cancel_btns ml-2" style={{ padding: '10px 20px' }} onClick={this.onCloseModal}>Cancel</button>
                                        </div>
                                    </div>
                                </form>
                            </div>


                        </div>
                    </Modal>
                    <Modal open={this.state.openEdit} onClose={() => this._resetEditAddressState()} center>
                        <div className="editaddressModal  vertical-align-center">


                            <div className="adderess_inside">
                                <h4 style={{ fontSize: '18px', fontWeight: '500', marginBottom: '8px' }}>Edit Delivery Address</h4>
                                <p>Please enter the accurate address, it will help us to serve you better</p>

                            </div>
                            <div className="inside_form_fields">
                                {/* <form
                                        // method="POST"
                                        // action="#"
                                        // role="form" 
                                        className="theme-form address-form "> */}
                                {/* <form> */}
                                <div className="theme-form address-form">
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="">ID No.<sup className="top_star">*</sup></label>
                                                <input
                                                    id="edit_address_id_no"
                                                    onChange={(e) => this.onChangeID(e)}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={'Enter your ID number'}
                                                    inputMode="numeric"
                                                    value={this.state.edit_address_id_no}
                                                    autoFocus={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="">Name<sup className="top_star">*</sup></label>
                                                <input
                                                    id="edit_address_name"
                                                    onChange={(e) => this.handleNameChange(e)}
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.edit_address_name}
                                                    placeholder={'Enter your name'}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-12 col-md-12 col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="">Address<sup className="top_star">*</sup></label>
                                                <input
                                                    id="edit_address_address"
                                                    onChange={(e) => this._onChangeText(e)}
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.edit_address_address}

                                                    placeholder={'Enter your address'}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-12 col-md-4 col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="">Country <sup className="top_star">*</sup></label>
                                                <select
                                                    // placeholder={'hardik'}
                                                    value={this.state.selectedCountryId}
                                                    onChange={(e) => this._getStateUponCountry(e.target.value)}
                                                    className="form-control">

                                                    <option value={undefined}>Select Country</option>
                                                    {
                                                        this.state.arr_country.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.id}>{item.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-4 col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="">State<sup className="top_star">*</sup></label>
                                                <select
                                                    value={this.state.selectedStateId}
                                                    onChange={(e) => this._getCityUponState(e.target.value)}
                                                    className="form-control">
                                                    <option value={undefined}>Select State</option>
                                                    {
                                                        this.state.selectedCountryId !== undefined ?
                                                            this.state.arr_state.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item.state_id}>{item.name}</option>
                                                                )
                                                            })
                                                            :
                                                            <option value={undefined}>First Select Country</option>
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-4 col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="">City<sup className="top_star">*</sup></label>
                                                <select
                                                    value={this.state.selectedCityId}
                                                    onChange={(e) => this.setState({ selectedCityId: e.target.value })}
                                                    className="form-control">
                                                    <option value={undefined}>Select City</option>
                                                    {
                                                        this.state.selectedStateId !== undefined ?
                                                            this.state.arr_city.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item.city_id}>{item.name}</option>
                                                                )
                                                            })
                                                            :
                                                            <option value={undefined}>First Select State</option>
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="">Pin code <sup className="top_star">*</sup></label>
                                                {/* <input
                                                        onChange={(e) => this._onChangeText(e)}
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.edit_address_pincode}
                                                        placeholder={'Enter your pincode'}
                                                        inputMode="numeric"
                                                        id="edit_address_pincode"
                                                        maxLength={6}
                                                    /> */}

                                                <input
                                                    type="number"
                                                    maxLength={6}
                                                    className="form-control"
                                                    onChange={(e) => this.onHandleTelephoneChange(e)}
                                                    placeholder="Enter your pincode"
                                                    inputMode="numeric"
                                                    id="edit_address_pincode"
                                                    value={this.state.edit_address_pincode}
                                                />

                                            </div>
                                        </div>
                                        {/* edit mobilr number */}
                                        <div className="col-12 col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="">Mobile Number <sup className="top_star">*</sup></label>
                                                <input
                                                    type="tel"
                                                    autoComplete='tel'
                                                    maxLength={10}
                                                    className="form-control"
                                                    onChange={(e) => this.onHandleTelephoneChange(e)}
                                                    placeholder="Enter your mobile number"
                                                    inputMode="numeric"
                                                    id="edit_number"
                                                    value={this.state.edit_number}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="">Adhar Card Number</label>
                                                <input
                                                    value={this.state.edit_aadhar_no == 'null' ? null : this.state.edit_aadhar_no}

                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Enter your Adhar Card Number"
                                                    onChange={(e) => this.onHandleTelephoneChange(e)}
                                                    inputMode="numeric"
                                                    id="edit_aadhar_no"
                                                    maxLength={12}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="">GST Number</label>
                                                <input style={{ textTransform: 'uppercase' }}
                                                    value={this.state.edit_gst_no === 'null' ? null : this.state.edit_gst_no}
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Enter your GST Number"
                                                    onChange={(e) => this.onChangeGST(e)}
                                                    id="edit_gst_no"
                                                    maxLength={15}
                                                />
                                            </div>
                                        </div>
                                    </div>



                                    {/* edit adharcard number in address */}
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="gap_top_ot gap_top">

                                                <div className="row align-items-center">
                                                    <div className="col-12">
                                                        <div className="form-group upload-adhar">
                                                            <label>Upload Adhar Card</label>
                                                            <div className="input-group">
                                                                <input
                                                                    onChange={(e) => this.setState({ edit_aadhar_imagepath_address: e.target.files[0] })}
                                                                    type="file"
                                                                    className="top_rmv_g form-control "

                                                                    ref={refAadharmImageaddress => this.fileAadharImageAddress = refAadharmImageaddress}
                                                                />
                                                                <div
                                                                    onClick={() => {
                                                                        this.setState({ edit_aadhar_image_address: null })
                                                                        this.fileAadharImageAddress.value = "";
                                                                    }}
                                                                    className="input-group-append">
                                                                    <span className="input-group-text"><i className="fa fa-times" aria-hidden="true" /></span>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className={!edit_aadhar_image_address ? "d-none" : "allImgNo"}>
                                                    <img className="img-thumbnail" src={edit_aadhar_image_address} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="address_type">
                                        <label className="radio-inline">
                                            <label className="radio-inline"><input onChange={() => this.setState({ edit_address_addressType: 1 })} type="radio" name="optradio"
                                                value="1" id="1" checked={this.state.edit_address_addressType == 1} /> Home</label>
                                            <label className="radio-inline"><input onChange={() => this.setState({ edit_address_addressType: 2 })} type="radio" name="optradio"
                                                value="2" id="2" checked={this.state.edit_address_addressType == 2} /> Office</label>
                                            <label className="radio-inline"><input onChange={() => this.setState({ edit_address_addressType: 3 })} type="radio" name="optradio"
                                                value="3" id="3" checked={this.state.edit_address_addressType == 3} /> Other</label>
                                        </label>
                                    </div>
                                    {/* end edit adhar card number */}
                                    <div className="both_buttons d-flex">
                                        <button onClick={() => this._editAddressApi()} className="btn btn-primary continue_btns">Update</button>
                                        <button type="submit" className="btn cancel_btns ml-2" onClick={() => this._resetEditAddressState()}>Cancel</button>
                                    </div>
                                </div>
                                {/* </form> */}
                            </div>


                        </div>
                    </Modal>
                </section>

            </div >
        )
    }

    /**10.0 Store issue modual & method */

    // 10.2
    _storeIssueModual = () => {
        this._getStoreList()
        this._getStoreTicketView()
    }

    _getStoreList = async () => {

        var api_name = 'v1/get-stores'
        var res = await NaswizHelperApi.GET_WITH_TOKEN(api_name, `Error => ${api_name}`)

        if (res && res.success == 1) {
            this.setState({
                arr_storeList: res.record
            })
        }

    }

    _getStoreTicketView = async () => {

        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/store-ticket-view?page=' + this.state._page12, null, "Error => v1/store-ticket-view api.")
        if (res && res.success == 1) {
            this.setState({ arr_store_ticket: res.record.data, loading_wallet: false })

            var tmp = []
            for (let i = 0; i < res.record.last_page; i++) {
                tmp.push(i)
            }

            this.setState({ _noOfPage12: tmp })
        }

    }

    handlePagination_issue = async (value3) => {
        var value = value3.selected + 1
        if (value <= this.state._noOfPage12.length) {
            await this.setState({ _page12: value })
            // if (this.state.isPriceFilter)
            //     this._searchUponSelectedFilter()
            // else
            this._getStoreTicketView()
        }

    }

    _createNewStoreIssue = async () => {

        var { store_id, store_visitedDate, store_subject, store_image, store_description } = this.state

        if (store_id === '') {
            Notification('error', 'Error!', 'Please enter store name!')
        }
        else if (store_id.length < 3) {
            Notification('error', 'Error!', 'Please enter valid store name')
        }
        else if (store_visitedDate === '') {
            Notification('error', 'Error!', 'Please select store visited date !')
        }
        else if (store_description === '') {
            Notification('error', 'Error!', 'Please add description')
        }
        else if (store_description.length < 3) {
            Notification('error', 'Error!', 'Please enter valid description')
        }
        else {

            var body = new FormData()
            body.append('store_name', store_id)
            body.append('visit_date', store_visitedDate)
            body.append('subject', store_description)
            body.append('problem_description', store_description)


            var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/store-ticket', body, "Error=> Create store issue api.")

            if (res && res.success == 1) {
                Notification('success', 'Success!', res.message ? res.message : 'Success!')
                this._resetStoreModualState()
                this._getStoreTicketView()
            }
            else {
                Notification('error', 'Error!', 'Try again later !')
                this._resetStoreModualState()
            }
        }
    }

    _resetStoreModualState = () => {
        this.setState({
            // arr_storeList: [],
            store_id: '',
            store_visitedDate: '',
            store_subject: '',
            store_description: '',
            store_image: null,
            activeView: ListOfViews.StoreIssue
        })
    }
    // 10.2

    /**10.0 Store issue modual & method */

}

const mapStateToProps = (state) => ({
    isUserLogin: state.login.isUserLogin,
    userID: state.login.userID,
    userData: state.login.userData,
    wishlistArray: state.login.userWishlistData.data

})

// export default connect(mapStateToProps, { addUserData, changeLoginState, removeUserData, manageUserIdState, getWishlistData })(InnerDashboard);
export default withRouter(connect(mapStateToProps,{ addUserData, changeLoginState, removeUserData, manageUserIdState, getWishlistData })(InnerDashboard));
var ListOfViews = {
    MyAccount: "My Account",
    MyProfile: "My Profile",
    MyAddress: "My Address",
    MyOrder: "My Order",
    MyFavourites: "My Favourites",
    MyOffers: "My Offers",
    MyCart: "My Cart",
    MyWallet: "My Wallet",
    LoyaltyPoints: "Loyalty Points",
    ReferAFriend: "Refer a Friend",
    Notification: "Notification",
    MyTickets: "My Tickets",
    OrderIssue: "Order issue",
    StoreIssue: "Store issue",
    Logout: "Logout",
    CreateTicket: 'Create Ticket',
    EditProfile: 'Edit Profile'
}