import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch, Link, Redirect } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import { IntlReducer as Intl, IntlProvider } from "react-redux-multilingual";
import TagManager from "react-gtm-module";
// import './index.scss';
// import Breadcrumb from "../src/components/common/breadcrumb";
// Import custom components
import store from "./store";
import translations from "./constants/translations";
import { getAllProducts, getAllProductsSlider } from "./actions";
import Landing from "./components/landing";

// Layouts
import Fashion from "./components/layouts/fashion/main";
import Cms from "./components/layouts/common/cms";
import AlkalineIonizer from "./components/layouts/common/alkaline-ionizer";
import Testimonial from "./components/layouts/common/testimonial";
// import LegalDocuments from './components/layouts/common/legal-documents';
import NaswizDownloads from "./components/layouts/common/naswiz-downloads";
// import PrivacyPolicy from './components/layouts/common/privacy-policy';
import ProcessOfBecoming from "./components/layouts/common/process-of-becoming";
import RemunerationSystem from "./components/layouts/common/remuneration-system";
import AboutUs from "./components/layouts/common/about-us";
import Phytowiz from "./components/layouts/common/phytowiz";
// import Exchange from './components/layouts/common/Exchange';
import Blog from "./components/layouts/common/blog";
import BlogSingle from "./components/layouts/common/blog-single";
import naswiz_stores from "./new_components/naswiz-stores";
// import Vegetables from './components/layouts/vegetables/main';
// import Kids from './components/layouts/kids/main';
// import Pets from './components/layouts/pets/main';
// import Furniture from './components/layouts/furniture/main';
// import Watch from './components/layouts/watch/main';
// import Beauty from './components/layouts/beauty/main';
// import Electronic from './components/layouts/electronic/main';

//Collection Pages
import CollectionLeftSidebar from "./components/collection/collection-left-sidebar";
import CollectionNoSidebar from "./components/collection/collection-no-sidebar";
import CollectionRightSidebar from "./components/collection/collection-right-sidebar";
import CollectionFullWidth from "./components/collection/collection-full-width";
import CollectionMetro from "./components/collection/collection-metro";

// Product Pages
import LeftSideBar from "./components/products/left-sidebar";
import SliderPage from "./components/products/slider-page";
import RightSideBar from "./components/products/right-sidebar";
import NoSideBar from "./components/products/no-sidebar";
import LeftImage from "./components/products/left-image";
import RightImage from "./components/products/right-image";
import Accordian from "./components/products/accordian";
import ColumnLeft from "./components/products/column-left";
import ColumnRight from "./components/products/column-right";
import Column from "./components/products/column";
import Vertical from "./components/products/vertical";

// Features
import Layout from "./components/app";
import Cart from "./components/cart";
import Compare from "./components/compare/index";
import wishList from "./components/wishlist";
import checkOut from "./components/checkout";
import orderSuccess from "./components/checkout/success-page";
import order_confirm from "./components/checkout/order_confirm";
import order_cancel from "./components/checkout/order_cancel";

// Extra Pages
import aboutUs from "./components/pages/about-us";
import Page from "./components/pages/page";
import PageNotFound from "./components/pages/404";
import lookbook from "./components/pages/lookbook";
import Login from "./components/pages/login";
import Register from "./components/pages/register";
import Search from "./components/pages/search";
import Collection from "./components/pages/collection";
import ForgetPassword from "./components/pages/forget-password";
import Contact from "./components/pages/contact";
import Grievance from "./components/pages/grievance";
import Dashboard from "./components/pages/dashboard";
import Faq from "./components/pages/faq";
import InnerDashboard from "./components/pages/inner-dashboard";

// Blog Pages
import RightSide from "./components/blogs/right-sidebar";
import Details from "./components/blogs/details";
import FashionTrends from "./components/blogs/fashion-trends";
import FashionForefront from "./components/blogs/fashion-forefront";
import HerbalProducts from "./components/blogs/Herbal-Products";
import BlogPage from "./components/blogs/blog-page";

// Theme Element
import ElementTitle from "./components/features/theme/element-title";
import ElementBanner from "./components/features/theme/element-banner";
import ElementSlider from "./components/features/theme/element-slider";
import ElementCategory from "./components/features/theme/element-category";
import ElementService from "./components/features/theme/element-service";
import ElementRatio from "./components/features/theme/element-ratio";

// Product Elements
import ElementProductBox from "./components/features/product/element-product-box";
import ElementProductSlider from "./components/features/product/element-product-slider";
import ElementProductNoSlider from "./components/features/product/element-product-no-slider";
import ElementMultipleSlider from "./components/features/product/element-multiple-slider";
import ElementProductTab from "./components/features/product/element-product-tab";

// Portfolio Features
import GridCols from "./components/features/portfolio/grid-cols";
import MasonaryGridCols from "./components/features/portfolio/masonary-grid-cols";

import Products from "./new_components/products_listing";
import ProductSearch from "./new_components/products_search";
import ProductDetails from "./new_components/products_details";
import profileDashboard from "./components/pages/profile-dashboard";
import InnerDashboardMyOrders from "./components/pages/InnterDashboardComponents/ID_MyOrders";
import Error from "./components/pages/ErrorPage";
import order_confirmatom from "./components/checkout/order_confirm_atom";
import order_fail_atom from "./components/checkout/order_fail_atom";
import payonline_response from "./components/payonline-response";
import thankyou from "./components/pages/thankyou";
import graivence_success from "./components/checkout/graivence_success";
import ErrorBoundary from "./ErrorBoundry";
import ErrorPage from "./components/pages/ErrorPage";
import Feedback from "./components/Feedback";

class Root extends React.Component {
  componentDidMount() {
    // window.scrollTo(0, 0)
    setTimeout(function() {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    TagManager.initialize({ gtmId: "G-4Z2BGYX4DS" });
  }
  render() {
    store.dispatch(getAllProducts());
    return (
      <Provider store={store}>
        <IntlProvider translations={translations} locale="en">
          <BrowserRouter basename={"/"}>
            <ScrollContext>
              <Switch>
                <ErrorBoundary>
                  {/* <Route component={PageNotFound} /> */}
                  {/* <Route path="*" component={PageNotFound} /> */}
                  {/* <Route  path={`${process.env.PUBLIC_URL}/Products/`} component={Fashion} /> */}
                  {/* <Route path={`${process.env.PUBLIC_URL}/vegetables`} component={Vegetables} />
                                <Route path={`${process.env.PUBLIC_URL}/electronic`} component={Electronic} />
                                <Route path={`${process.env.PUBLIC_URL}/furniture`} component={Furniture} />
                                <Route path={`${process.env.PUBLIC_URL}/pets`} component={Pets} />
                                <Route path={`${process.env.PUBLIC_URL}/watch`} component={Watch} />
                            <Route path={`${process.env.PUBLIC_URL}/kids`} component={Kids} /> */}
                  {/* <Route path={`${process.env.PUBLIC_URL}/beauty`} component={Beauty} /> */}

                  <Layout>
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/`}
                      component={Fashion}
                    />
                    {/* <Route exact path="*" component={Error} /> */}

                    {/*Routes For Layouts*/}
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/fashion`}
                      component={Fashion}
                    />
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/:page`} render={(props) => <Cms {...props} />} /> */}
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:page`}
                      component={Cms}
                    />

                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/alkaline-ionizer`}
                      component={AlkalineIonizer}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/testimonial`}
                      component={Testimonial}
                    />
                    {/* <Route path={`${process.env.PUBLIC_URL}/legal-documents`} component={LegalDocuments} /> */}
                    <Route
                      path={`${process.env.PUBLIC_URL}/naswiz-downloads`}
                      component={NaswizDownloads}
                    />
                    {/* <Route path={`${process.env.PUBLIC_URL}/privacy-policy`} component={PrivacyPolicy} /> */}
                    <Route
                      path={`${process.env.PUBLIC_URL}/process-of-becoming`}
                      component={ProcessOfBecoming}
                    />
                    {/* <Route path={`${process.env.PUBLIC_URL}/remuneration-system`} component={Cms} /> */}
                    {/* <Route path={`${process.env.PUBLIC_URL}/about-us`} component={AboutUs} /> */}
                    <Route
                      path={`${process.env.PUBLIC_URL}/phytowiz`}
                      component={Phytowiz}
                    />
                    {/* <Route path={`${process.env.PUBLIC_URL}/Exchange-Return-And-Refund`} component={Exchange} /> */}
                    <Route
                      path={`${process.env.PUBLIC_URL}/blog`}
                      component={Blog}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/blog-single`}
                      component={BlogSingle}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/naswiz_stores`}
                      component={naswiz_stores}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/checkout`}
                      component={checkOut}
                    />

                    {/* <Route path={`${process.env.PUBLIC_URL}/:id`}  component={Products} /> */}
                    <Route
                      path={`${process.env.PUBLIC_URL}/Products/:id`}
                      component={Products}
                    />
                    {/* <Route path={`${process.env.PUBLIC_URL}/Products`} component={Products} /> */}

                    <Route
                      path={`${process.env.PUBLIC_URL}/Search/:var`}
                      component={ProductSearch}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/product-detail/:id1/:type`}
                      component={LeftSideBar}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/slider-page`}
                      component={SliderPage}
                    />
                    {/* <Route path={`${process.env.PUBLIC_URL}/product-detail/:id`} component={LeftSideBar} /> */}
                    <Route
                      path={`${process.env.PUBLIC_URL}/page/user/:profileId`}
                      component={InnerDashboard}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/page/profileDashboard`}
                      component={profileDashboard}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/ProductDetails`}
                      component={ProductDetails}
                    />

                    {/*Routes For Features (Product Collection) */}
                    {/* <Route path={`${process.env.PUBLIC_URL}/`} component={CollectionLeftSidebar} /> */}
                    {/* <Route path={`${process.env.PUBLIC_URL}/left-sidebar/collection/:id`} component={CollectionLeftSidebar} /> */}
                    {/* 21-01-21 */}
                    {/* <Route path={`${process.env.PUBLIC_URL}/:id`} component={CollectionLeftSidebar} /> */}
                    {/* <Route path={`${process.env.PUBLIC_URL}/:id/:subId`} component={CollectionLeftSidebar} /> */}
                    {/* 21-01-21 */}

                    <Route
                      path={`${process.env.PUBLIC_URL}/no-sidebar/collection`}
                      component={CollectionNoSidebar}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/right-sidebar/collection`}
                      component={CollectionRightSidebar}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/full-width/collection`}
                      component={CollectionFullWidth}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/metro/collection`}
                      component={CollectionMetro}
                    />

                    {/*Routes For Single Product*/}
                    {/* <Route path={`${process.env.PUBLIC_URL}/:id/:subCat`} component={LeftSideBar} /> */}

                    <Route
                      path={`${process.env.PUBLIC_URL}/right-sidebar/product/:id`}
                      component={RightSideBar}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/no-sidebar/product/:id`}
                      component={NoSideBar}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/col-left/product/:id`}
                      component={ColumnLeft}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/col-right/product/:id`}
                      component={ColumnRight}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/accordian/product/:id`}
                      component={Accordian}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/column/product/:id`}
                      component={Column}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/left-image/product/:id`}
                      component={LeftImage}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/right-image/product/:id`}
                      component={RightImage}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/vertical/product/:id`}
                      component={Vertical}
                    />

                    {/*Routes For custom Features*/}
                    <Route
                      path={`${process.env.PUBLIC_URL}/cart`}
                      component={Cart}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/wishlist`}
                      component={wishList}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/compare`}
                      component={Compare}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/order_cancel`}
                      component={order_cancel}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/order-success`}
                      component={orderSuccess}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/order_confirm`}
                      component={order_confirm}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/order-success`}
                      component={orderSuccess}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/graivence_success`}
                      component={graivence_success}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/sales/orders`}
                      component={aboutUs}
                    />

                    {/*Routes For Extra Pages*/}
                    {/* <Route path={`${process.env.PUBLIC_URL}/pages/about-us`} component={aboutUs} /> */}
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/404`}
                      component={PageNotFound}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/lookbook`}
                      component={lookbook}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/login`}
                      component={Login}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/register`}
                      component={Register}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/search`}
                      component={Search}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/collection`}
                      component={Collection}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/forget-password`}
                      component={ForgetPassword}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/contact`}
                      component={Contact}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/Grievance`}
                      component={Grievance}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/thankyou`}
                      component={thankyou}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/dashboard`}
                      component={Dashboard}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/faq`}
                      component={Faq}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/:page`}
                      component={Page}
                    />

                    {/*Features*/}
                    {/*Theme Elements*/}
                    <Route
                      path={`${process.env.PUBLIC_URL}/features/element-title`}
                      component={ElementTitle}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/features/element-banner`}
                      component={ElementBanner}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/features/element-slider`}
                      component={ElementSlider}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/features/element-category`}
                      component={ElementCategory}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/features/element-service`}
                      component={ElementService}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/features/element-ratio`}
                      component={ElementRatio}
                    />

                    {/*Product Elements*/}
                    <Route
                      path={`${process.env.PUBLIC_URL}/features/element-product-box`}
                      component={ElementProductBox}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/features/element-product-slider`}
                      component={ElementProductSlider}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/features/element-product-no-slider`}
                      component={ElementProductNoSlider}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/features/element-product-multiple-slider`}
                      component={ElementMultipleSlider}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/features/element-product-tab`}
                      component={ElementProductTab}
                    />

                    {/*Portfolios*/}
                    <Route
                      path={`${process.env.PUBLIC_URL}/features/portfolio-grid/:columns`}
                      component={GridCols}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/features/portfolio-masonary/:columns`}
                      component={MasonaryGridCols}
                    />

                    {/*Blog Pages*/}
                    <Route
                      path={`${process.env.PUBLIC_URL}/blog/right-sidebar`}
                      component={RightSide}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/blog/details`}
                      component={Details}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/fashion-trends`}
                      component={FashionTrends}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/fashion-forefront`}
                      component={FashionForefront}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/Herbal-Products`}
                      component={HerbalProducts}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/blog/blog-page`}
                      component={BlogPage}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/pages/InnterDashboardComponents`}
                      component={InnerDashboardMyOrders}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/payonline-response`}
                      component={payonline_response}
                    />
                    {/* <Route  path="*" component={ErrorPage} /> */}
                    <Route
                      path={`${process.env.PUBLIC_URL}/feedback-review`}
                      component={Feedback}
                    />
                  </Layout>
                </ErrorBoundary>
              </Switch>
            </ScrollContext>
          </BrowserRouter>
        </IntlProvider>
      </Provider>
    );
  }
}

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<Root />, rootElement);
} else {
  ReactDOM.render(<Root />, rootElement);
}
