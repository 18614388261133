import {
    RECEIVE_FILTERLIST_SLUG
} from "../constants/ActionTypes";


const initialState = {
    filterlist: {},
};

const filterListReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_FILTERLIST_SLUG:
            return {
                ...state,
                filterlist: action.filterlist
            };
        

        
        default:
            return state;
    }
};
export default filterListReducer;