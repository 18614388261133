import React  from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { getBestSeller } from "../../services";


const  NewProduct =(props)=> {
    
        const { items, symbol, } = props;

        var arrays = [];   

        return (
            <div className="theme-card">
                <h5 className="title-border">Feature product</h5>
                <Slider className="offer-slider slide-1">
                    {feacherProducts.map((products, index) =>
                        <div key={index}>
                            <div className="media" key={index}>
                                <Link to={`${process.env.PUBLIC_URL}/product-detail/${products.slug}`}>

                                    <img className="img-fluid"
                                        src={products.product_images && products.product_images.length > 0 ? products.product_images[0].image : 'https://www.actbus.net/fleetwiki/images/8/84/Noimage.jpg'}
                                        alt="" /></Link>
                                <div className="media-body align-self-center">
                                    <div className="rating">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </div>
                                    <Link to={`${process.env.PUBLIC_URL}/product-detail/${products.slug}`}><h6>{products.name}</h6></Link>
                                    <h4>{"₹"}{products.price}
                                        <del><span className="money"> {"₹"}{products.price}</span></del></h4>
                                </div>
                            </div>
                            {/* )} */}
                        </div>
                    )}
                </Slider>
            </div>
        )
    
}

function mapStateToProps(state) {
    return {
        items: state.data.products,
        symbol: state.data.symbol
    }
}

export default connect(mapStateToProps, null)(NewProduct);
const feacherProducts = [
    {
        "name": "both s & c 2",
        "product_id": 6,
        "product_type": "4",
        "is_naswiz": 1,
        "product_code": "lkjasd123",
        "incentive_point": "20",
        "description": "asdf",
        "slug": "both-s-c-2",
        "price": 300,
        "wholesale_price": 250,
        "off": 16.67,
        "details": [
            {
                "color_id": 2,
                "color_name": "Red",
                "color_slug": "red",
                "size_id": 2,
                "size_name": "M",
                "price": 300,
                "wholesale_price": 250,
                "off": 16.67,
                "min_order_qty": 1,
                "max_order_qty": 3,
                "inventory": 50,
                "used": 0
            }
        ],
        "product_images": [
            {
                "id": 4,
                "product_id": 6,
                "color_id": 2,
                "image": "http://cranup.in/naswiz//storage/uploads/product/Medium/20201231053108928600zLdlW.jpg",
                "image_type": "image",
                "order": 4,
                "status": 1,
                "created_at": "2020-12-31 00:01:09",
                "updated_at": "2020-12-31 00:01:17"
            },
            {
                "id": 5,
                "product_id": 6,
                "color_id": 2,
                "image": "http://cranup.in/naswiz//storage/uploads/product/Medium/20201231053109649700NL1u6.jpg",
                "image_type": "image",
                "order": 5,
                "status": 1,
                "created_at": "2020-12-31 00:01:10",
                "updated_at": "2020-12-31 00:01:20"
            }
        ],
        "cart": null,
        "wishlist": 0,
        "wishlist_id": null
    },
    {
        "name": "no s",
        "product_id": 4,
        "product_type": "3",
        "is_naswiz": 0,
        "product_code": "12kas",
        "incentive_point": "15",
        "description": "<p>ascxz</p>",
        "slug": "no-s",
        "price": 260,
        "wholesale_price": 250,
        "off": 3.85,
        "details": [
            {
                "color_id": 2,
                "color_name": "Red",
                "color_slug": "red",
                "size_id": 1,
                "size_name": "NO",
                "price": 260,
                "wholesale_price": 250,
                "off": 3.85,
                "min_order_qty": 1,
                "max_order_qty": 5,
                "inventory": 5,
                "used": 0
            },
            {
                "color_id": 3,
                "color_name": "Green",
                "color_slug": "green",
                "size_id": 1,
                "size_name": "NO",
                "price": 450,
                "wholesale_price": 400,
                "off": 11.11,
                "min_order_qty": 3,
                "max_order_qty": 6,
                "inventory": 6,
                "used": 0
            }
        ],
        "product_images": [],
        "cart": null,
        "wishlist": 0,
        "wishlist_id": null
    },
    {
        "name": "Both c & s",
        "product_id": 1,
        "product_type": "4",
        "is_naswiz": 1,
        "product_code": "SKC001",
        "incentive_point": "10",
        "description": "<p>asddf</p>",
        "slug": "both-c-s",
        "price": 300,
        "wholesale_price": 250,
        "off": 16.67,
        "details": [
            {
                "color_id": 2,
                "color_name": "Red",
                "color_slug": "red",
                "size_id": 2,
                "size_name": "M",
                "price": 300,
                "wholesale_price": 250,
                "off": 16.67,
                "min_order_qty": 1,
                "max_order_qty": 2,
                "inventory": 5,
                "used": 0
            },
            {
                "color_id": 2,
                "color_name": "Red",
                "color_slug": "red",
                "size_id": 3,
                "size_name": "S",
                "price": 500,
                "wholesale_price": 350,
                "off": 30,
                "min_order_qty": 1,
                "max_order_qty": 2,
                "inventory": 5,
                "used": 0
            },
            {
                "color_id": 3,
                "color_name": "Green",
                "color_slug": "green",
                "size_id": 2,
                "size_name": "M",
                "price": 500,
                "wholesale_price": 450,
                "off": 10,
                "min_order_qty": 1,
                "max_order_qty": 1,
                "inventory": 0,
                "used": 0
            },
            {
                "color_id": 3,
                "color_name": "Green",
                "color_slug": "green",
                "size_id": 3,
                "size_name": "S",
                "price": 100,
                "wholesale_price": 90,
                "off": 10,
                "min_order_qty": 1,
                "max_order_qty": 1,
                "inventory": 0,
                "used": 0
            }
        ],
        "product_images": [
            {
                "id": 2,
                "product_id": 1,
                "color_id": 2,
                "image": "http://cranup.in/naswiz//storage/uploads/product/Medium/2020122212275733030065m9W.jpg",
                "image_type": "image",
                "order": 2,
                "status": 1,
                "created_at": "2020-12-22 06:57:58",
                "updated_at": "2020-12-22 06:58:05"
            },
            {
                "id": 6,
                "product_id": 1,
                "color_id": 3,
                "image": "http://cranup.in/naswiz//storage/uploads/product/Medium/20210101072227941000PsKKO.jpg",
                "image_type": "image",
                "order": 6,
                "status": 1,
                "created_at": "2021-01-01 01:52:29",
                "updated_at": "2021-01-01 01:52:38"
            },
            {
                "id": 7,
                "product_id": 1,
                "color_id": 2,
                "image": "http://cranup.in/naswiz//storage/uploads/product/Medium/20210101072229923100yOEkl.jpg",
                "image_type": "image",
                "order": 7,
                "status": 1,
                "created_at": "2021-01-01 01:52:30",
                "updated_at": "2021-01-01 01:52:44"
            }
        ],
        "cart": null,
        "wishlist": 0,
        "wishlist_id": null
    }
]