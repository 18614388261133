import React, { Component, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link, Route, BrowserRouter, Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { Helper } from "../../helper";
import Breadcrumb from "../common/breadcrumb";
import {
  removeFromWishlist,
  getCartData,
  addUserData,
  changeLoginState,
  manageUserIdState,
} from "../../actions";
import { getCartTotal } from "../../services";
import { base_url } from "../../helper";
import Modal from "react-responsive-modal";
import "react-phone-number-input/style.css";
import "react-tabs/style/react-tabs.scss";
import Moment from "moment";
import { NaswizHelperApi, BASE_URL, MAIN_URL } from "../../NaswizHelper";
import Notification from "../../Notification";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-number-input";

import { AtomPaynetz } from "./atomcheckout";
import Seo from "../Seo";

const BackToHome = () => {
  return <a href="/"></a>;
};

class checkOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment: "stripe",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      country: "India ",
      address: "",
      city: "",
      state: "",
      pincode: "",
      create_account: "",
      addressID: "",
      full_name: "",
      loading_state: false,
      arr_state: [],
      loading_city: false,
      arr_city: [],
      user_address_id: undefined,
      phoneNumber: "",
      customer_id: "",
      loading_addAddress: false,
      loading_placeOrder: false,
      paymentModalVisibility: false,
      is_status: 0,
      selected_address_ID: "",
      selectedAddressFull: "",
      selectedAddressFull2: "",
      gst_no: "",
      aadhar_no: "",
      aadhar_image: "",
      all_address_data: "",
      added_address: false,
      _currentDate: "",
      _lastDate: "",
      _deliveryDates: [],
      _delivery_list: false,
      _delivery_date: false,
      _payment: false,
      _deliverycheckdone: false,
      _deliverydatecheckdone: false,
      _paymentcheckdone: false,
      _verificationsec: false,
      _id_number: "",
      edited_name: "",
      edited_email_id: "",
      edited_dob: "",
      edited_gender: undefined,

      otpViewOpen: false, //divya
      otp_1: "",
      otp_2: "",
      otp_3: "",
      otp_4: "",
      otp: "",
      // selectedOption: '',
      selectedOption: "",
      goToSuccess: false,
      _isAddressVisible: false,
      _referalCode: "",
      _is_used: "",
      uniueId: "",
      isLoing: "",
      isLoadingPay: false,
      arr_walletcustomer: "",
      is_wallet: "",
      wallet_amount: "",
      isChaked: false,
      _deliverycheckdoneselect: false,

      _timeSlotSelect: false,
      _selectedTimeSlot: "",
      _promocode: "",
      disablebutton: false,
      _counter: 60,
      _counternew: 10,
      _showbutton: false,
      regexp: /^[0-9\b]+$/,
      _isPromocodeValide: false,
      _visiblePromoCode: false,
      goToDashboard: false,
      // Edit profile states
      _id_number: "",
      edited_name: "",
      edited_email_id: "",
      edited_dob: "",
      edited_gender: undefined,
      edited_marital_status: "",
      unmarried: "",
      married: "",
      edited_anniversary_date: "",
      edited_profile_image: null,
      edited_aadhar_card_image: null,
      edited_pan_card_image: null,
      openEdit: false,
      // Edit address states
      edit_address_object: {},
      isEditAddressObject: false,
      edit_address_name: "",
      edit_address_address: "",
      edit_address_pincode: "",
      edit_address_id_no: "",
      edit_gst_no: "", // edit for address
      edit_aadhar_no: "", // edit for address
      edit_number: "",
      edit_aadhar_image_address: "",
      edit_aadhar_imagepath_address: "",
      edit_address_addressType: undefined,
      selectedCountryId: undefined,
      selectedStateId: undefined,
      selectedCityId: undefined,
      arr_country: [],
      arr_city: [],
      arr_state: [],
      open: false,
      _checkRegisteModal: false,
      _isProfileDone: false,
      _is_whatsapp: "",

      // 10aug
      _otherpaymenttype: "", //checque,neft,cash trans
      _choosepamentone: 0,
      _othertrans: "",
      _otheramount: "",
      _otherdate: "",
      _loading_placeOrder: false,
      payment_proof_img: "",
    };
    this.validator = new SimpleReactValidator();
    this.toggleModal = this.toggleModal.bind(this);
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onCloseModalProfile = () => {
    this.setState({ _isProfileDone: false });
  };

  onHandleTelephoneChange = (e) => {
    let telephone = e.target.value;
    if (telephone === "" || this.state.regexp.test(telephone)) {
      this.setState({ [e.target.id]: telephone });
    } else {
      return;
    }
  };

  componentDidMount = async () => {
    var _whatsapp = localStorage.getItem("is_whatsapp");
    this.setState({ _is_whatsapp: _whatsapp });
    this.getUserAddress();
    this.firstTimeGettingStateList();
    this._getMyWalletcustomers();
    await this._getCountry();

    let rajdatea = new Date();
    await this.setState({
      _lastDate: rajdatea.getDate() + 12,
      _currentDate: rajdatea.getDate(),
    });

    var isLoing = localStorage.getItem("is_user_login");
    var uniueId = localStorage.getItem("deviceId");
    this.setState({
      isLoing: isLoing,
      uniueId: uniueId,
      _current_Url: window.location.href,
    });

    let today = Moment(new Date()).format("YYYY-MM-DD");
    if (this.props.cartListArray.length == 0) {
      this.setState({ goToDashboard: true });
      return;
    }
    for (let i = 0; i < 12; i++) {
      let tomorrow = new Date();
      let tomorrowm = new Date();

      tomorrow = Moment(tomorrow)
        .add(i, "day")
        .format("ddd");
      tomorrowm = Moment(tomorrowm)
        .add(i, "day")
        .format("D MMM");

      var obej = { dayname: tomorrow, months: tomorrowm };

      this.state._deliveryDates.push(obej);
    }

    if (!this.props.isUserLogin) {
      Notification("error", "Error!", "Please Login First!");
      this.setState({ _verificationsec: true });
    } else {
      this.setState({ _deliverycheckdone: true });
      this.customerDetails();
    }
    setTimeout(function() {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  };

  _updateUserProfile = async () => {
    var isValid = true;
    var {
      _id_number,
      edited_name,
      edited_email_id,
      edited_dob,
      edited_anniversary_date,
      edited_gender,
      edited_marital_status,
      edited_profile_image,
      edited_aadhar_card_image,
      edited_pan_card_image,
    } = this.state;

    var formData = new FormData();
    if (_id_number) {
      formData.append("id_number", _id_number);
    } else {
      isValid = false;

      Notification("error", "Error!", "Please enter Id Number");
    }
    if (edited_name) {
      if (edited_name.length < 3) {
        isValid = false;
        Notification("error", "Error!", "Please enter valid name");
      } else {
        formData.append("name", edited_name);
      }
    } else {
      isValid = false;

      Notification("error", "Error!", "Please enter name");
    }

    if (edited_email_id) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (re.test(edited_email_id) === false) {
        isValid = false;

        Notification("error", "Error!", "Please enter valid email id");
      } else {
        formData.append("email", edited_email_id);
      }
    } else {
      isValid = false;

      Notification("error", "Error!", "Please enter email id");
    }
    if (edited_dob) {
      if (edited_dob.length !== 10) {
        isValid = false;
        Notification("error", "Error!", "Please select valid date of birth");
      } else {
        formData.append("dob", edited_dob);
      }
    } else {
      isValid = false;

      Notification("error", "Error!", "Please select date of birth");
    }
    if (this.state.edited_marital_status === "1") {
      if (edited_anniversary_date) {
        if (edited_anniversary_date.length !== 10) {
          isValid = false;
          Notification("error", "Error!", "Please select valid anniversary");
        }
        formData.append("dob_anniversary", edited_anniversary_date);
      } else {
        isValid = false;

        Notification("error", "Error!", "Please select anniversary");
      }
    }

    if (edited_gender) {
      if (edited_gender === "Gender") {
        isValid = false;

        Notification("error", "Error!", "Please select gender");
      } else {
        formData.append("gender", edited_gender);
      }
    } else {
      isValid = false;

      Notification("error", "Error!", "Please select gender");
    }
    var _lenght = 0;
    for (var i of formData.entries()) {
      _lenght++;
    }

    if (_lenght > 0) {
      if (isValid) {
        var res = await NaswizHelperApi.POST_WITH_TOKEN(
          "v1/update-profile",
          formData,
          "Error => Update profile api"
        );

        if (res && res.success === 1) {
          this._customerDetails();
          this.setState({ _isProfileDone: false });
        } else {
        }
      } else {
      }
    } else {
    }
  };

  _customerDetails = async () => {
    var res = await NaswizHelperApi.POST_WITH_TOKEN(
      "v1/customers-detail",
      null,
      "Error => Customer details api"
    );
    if (res && res.success === 1) {
      await this.props.addUserData(res.record);
    }
  };

  _onChangeText = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  onChangeID = (evt) => {
    const newId = evt.target.value.replace(/[^a-zA-Z0-9]/g, "");
    this.setState({ addressID: newId });
  };

  _editAddressApi = async () => {
    var {
      edit_address_object,
      edit_address_id_no,
      edit_address_name,
      edit_address_address,
      selectedCountryId,
      selectedStateId,
      selectedCityId,
      edit_address_pincode,
      edit_address_addressType,
      edit_number,
      edit_aadhar_no,
      edit_gst_no,
      edit_aadhar_imagepath_address,
    } = this.state;
    var isValid = true;

    var body = new FormData();
    body.append("id", edit_address_object.id);
    if (edit_address_id_no != undefined) {
      body.append("address_id_no", edit_address_id_no);
    } else {
      Notification("error", "Error!", "Please enter address ID number");
      isValid = false;
    }
    if (edit_address_name.length > 2) {
      body.append("fullname", edit_address_name);
    } else {
      Notification("error", "Error!", "Please enter valid name");
      isValid = false;
    }
    if (edit_address_address.length > 9) {
      body.append("address", edit_address_address);
    } else {
      Notification("error", "Error!", "Please enter valid address");
      isValid = false;
    }
    if (selectedCountryId != undefined) {
      body.append("country_id", selectedCountryId);
    } else {
      Notification("error", "Error!", "Please select country");
      isValid = false;
    }
    if (selectedStateId != undefined) {
      body.append("state_id", selectedStateId);
    } else {
      Notification("error", "Error!", "Please select state");
      isValid = false;
    }
    if (selectedCityId != undefined) {
      body.append("city_id", selectedCityId);
    } else {
      Notification("error", "Error!", "Please select city");
      isValid = false;
    }
    if (edit_address_pincode != "") {
      body.append("pincode", edit_address_pincode);
    } else {
      Notification("error", "Error!", "Please enter valid pincode");
      isValid = false;
    }
    if (edit_number != "") {
      body.append("number", edit_number);
    } else {
      Notification("error", "Error!", "Please enter valid mobile number");
      isValid = false;
    }
    body.append("aadhar_no", edit_aadhar_no);
    body.append("gst_no", edit_gst_no);
    if (edit_aadhar_imagepath_address != "") {
      body.append(
        "aadhar_image",
        edit_aadhar_imagepath_address,
        edit_aadhar_imagepath_address.name
      );
    }
    if (edit_address_addressType) {
      body.append("is_status", edit_address_addressType);
    } else {
      isValid = false;
    }

    var _lenght = 0;
    for await (var i of body.entries()) {
      await _lenght++;
    }
    if (_lenght > 1 && isValid) {
      var res = await NaswizHelperApi.POST_WITH_TOKEN(
        "v1/edit-address",
        body,
        "Error => Edit user address api."
      );

      if (res && res.success === 1) {
        this._resetEditAddressState();
        this.getUserAddress();
      } else {
        alert(res && res.message ? res.message : "Try again later");
        this._resetEditAddressState();
      }

      // var myHeaders = new Headers();
      // myHeaders.append("Accept", "application/x.naswiz.v1+json");
      // myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvbmV3ZGVtby5teXNob3B3aXouY29tXC9tYXN0ZXItbmFzd2l6XC9hcGlcL3YxXC92ZXJpZnktb3RwIiwiaWF0IjoxNjMxODUzMDUzLCJuYmYiOjE2MzE4NTMwNTMsImp0aSI6IjlXYzJPYkRKQ1BSRFVVRWgiLCJzdWIiOjY1LCJwcnYiOiI4YjQyMmU2ZjY1NzkzMmI4YWViY2IxYmYxZTM1NmRkNzZhMzY1YmYyIn0.yCE813HkTZ3kkmWWhpPl9p5Q5XHy9qBQourZJMlm-ug");
      // myHeaders.append("Access-Control-Allow-Origin", "*")

      // var formdata = new FormData();
      // formdata.append("id", "101");
      // formdata.append("fullname", "test abc");
      // formdata.append("number", "9998613834");
      // formdata.append("pincode", "360001");
      // formdata.append("address", "test rajkot");
      // formdata.append("city_id", "904");
      // formdata.append("state_id", "12");
      // formdata.append("country_id", "101");
      // formdata.append("is_status", "1");
      // formdata.append("gst_no", "ADGFVB6756757");
      // formdata.append("aadhar_no", "null");
      // formdata.append("address_id_no", "abcdef45767");

      // var requestOptions = {
      // 	method: 'POST',
      // 	headers: myHeaders,
      // 	body: formdata,
      // 	redirect: 'follow'
      // };

      // var res = await fetch("https://newdemo.myshopwiz.com/master-naswiz/api/v1/edit-address", requestOptions)
      // 	.then(response => response.text())
      // 	.then(result => console.log(result))
      // 	.catch(error => console.log('error', error));
      // if (res && res.success == 1) {
      // 	this._resetEditAddressState()
      // 	this.getUserAddress()
      // }
      // else {
      // 	alert(res && res.message ? res.message : 'Try again later')
      // 	// this._resetEditAddressState()
      // }
    }
    // }, 1000)
  };

  _resetEditAddressState = () => {
    this.setState({
      openEdit: false,
      edit_address_object: {},
      isEditAddressObject: false,
      edit_address_name: "",
      edit_address_address: "",
      edit_address_pincode: "",
      edit_number: "",
      edit_aadhar_no: "",
      edit_gst_no: "",
      edit_address_id_no: "",
      edit_address_addressType: undefined,
      selectedCountryId: undefined,
      selectedStateId: undefined,
      selectedCityId: undefined,
    });
  };

  _getMyWalletcustomers = async () => {
    var res = await NaswizHelperApi.POST_WITH_TOKEN(
      "v1/customers-detail",
      "Error => v1/customers-detail api."
    );

    if (res && res.success === 1) {
      this.setState({
        arr_walletcustomer: res.record.wallet,
        loading_wallet: false,
      });
      if (res.record.wallet === 0) {
        this.setState({ isChaked: false, selectedOption: "" });
      }
    } else {
      this.setState({
        loading_walletcustomer: false,
      });
    }
  };

  toggleModal(visibility) {
    if (visibility === true) {
      this.setState({ paymentModalVisibility: visibility });
    } else if (visibility === false) {
      this.setState({ paymentModalVisibility: visibility });
    } else {
      this.setState({
        paymentModalVisibility: !this.state.paymentModalVisibility,
      });
    }
  }

  async getCartList(addressId) {
    var formdata = new FormData();
    formdata.append("device_id", this.state.uniueId);
    formdata.append("address_id", addressId);
    this.props.getCartData(
      "v1/get-cart-list",
      formdata,
      "Error => GetCartList api."
    );
  }

  setStateFromInput = (event, msg) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);

    if (msg === "call_city_method") {
      this.getCityUponState();
    }
  };

  setStateFromCheckbox = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.checked;
    this.setState(obj);

    if (!this.validator.fieldValid(event.target.name)) {
      this.validator.showMessages();
    }
  };

  checkhandle(value) {
    this.setState({
      payment: value,
    });
  }

  StripeClickAtom = (response) => {
    this.setState({ _loading_placeOrder: true });
    let formData = new FormData();
    formData.append("amt", response.response.amount);
    formData.append("name", response.response.order_id + "");
    formData.append("emailId", response.response.email_id);
    formData.append("connumber", response.response.connumber);
    formData.append("address", response.response.address);

    for (var pair of formData.entries()) {
    }
    // https://newdemo.myshopwiz.com/master-naswiz/payment/request
    fetch(`${BASE_URL}/payment/request`, {
      method: "post",
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => {
        window.location.href = json;
      });
  };

  // openPay_org(atomId) {
  // 	const options = {
  // 		"atomTokenId": atomId,
  // 		// "merchId": "8952",
  // 		"merchId": "330019",
  // 		"custEmail": this.props.userData.email,
  // 		"custMobile": this.props.userData.number,
  // 		//"returnUrl":"http://localhost/Flash%20Checkout%20Project/Archive/response.php"
  // 		//"returnUrl": "https://testing-atom.000webhostapp.com/uat/response.php"
  // 		"returnUrl": "https://newdemo.myshopwiz.com/master-naswiz/naswiz/order/response/atom"
  // 		// "returnUrl": "https://newdemo.myshopwiz.com/order_confirm"
  // 	}
  // 	let atom = AtomPaynetz(options, 'uat');
  // }

  // StripeClickAtom = () => {
  // 	this.setState ({_loading_placeOrder: true});
  // 	let formData = new FormData();
  // 	formData.append('amt', '100.00');
  // 	formData.append('name', 'Atom Dev');
  // 	formData.append('emailId', 'atomdev@gmail.com');
  // 	formData.append('connumber', '8888888888');
  // 	formData.append('address', 'Mumbai');

  // 	fetch("https://newdemo.myshopwiz.com/master-naswiz/payment/request", {
  // 		method: "post",
  // 		body: formData,
  // 	})
  // 		.then(response => response.json())
  // 		.then(json => {
  // 			console.log('parsed json', json);
  // 			window.location.href = json;
  // 		})
  // };
  // cod, payttm, other payment

  StripeClick = async () => {
    var {
      first_name,
      last_name,
      phone,
      email,
      country,
      address,
      city,
      state,
      pincode,
      create_account,
    } = this.state;
    var isValid = true;
    if (
      this.state._otherpaymenttype === "" &&
      this.state._choosepamentone == 1
    ) {
      Notification("error", "Error!", "Please select one payment!");
      isValid = false;
    }
    if (this.state._otheramount === "" && this.state._choosepamentone == 1) {
      Notification("error", "Error!", "Please enter amount");
      isValid = false;
    }
    if (isNaN(this.state._otheramount) && this.state._choosepamentone == 1) {
      Notification("error", "Error!", "Please enter Valid Amount");
      isValid = false;
    }
    if (this.state._otherdate === "" && this.state._choosepamentone == 1) {
      Notification("error", "Error!", "Please select date");
      isValid = false;
    }
    if (this.state._othertrans === "" && this.state._choosepamentone == 1) {
      Notification("error", "Error!", "Please enter transaction detail");
      isValid = false;
    }
    if (this.state.payment_proof_img.size > 2000000) {
      Notification(
        "error",
        "info!",
        "Please Upload Payment Proof less Than 2MB!"
      );
      isValid = false;
    } else if (
      this.state.selectedOption === "" &&
      this.state._choosepamentone == 0
    ) {
      Notification("error", "Error!", "Please select payment!");
      isValid = false;
    }

    if (this.validator.allValid() && isValid) {
      if (this.state.selected_address_ID !== undefined) {
        if (
          this.props.isUserLogin &&
          this.props.userData &&
          this.props.userData.email !== null
        ) {
          if (this.props.cartListArray.length > 0) {
            var cartArray = this.props.cartItems;

            var formData = new FormData();
            formData.append("device_id", this.state.uniueId);
            if (this.state._choosepamentone === 1) {
              formData.append("payment_type", "others");
              formData.append("others_type", this.state._otherpaymenttype);
              if (this.state._otheramount === "") {
                Notification("error", "Error!", "Please enter amount!");
                return;
              }
              if (this.state._otherdate === "") {
                Notification("error", "Error!", "Please enter date!");
                return;
              }
              if (this.state._othertrans === "") {
                Notification(
                  "error",
                  "Error!",
                  "Please enter transaction detail!"
                );
                return;
              }
              formData.append("others_amount", this.state._otheramount);
              formData.append("others_date", this.state._otherdate);
              formData.append("others_trans", this.state._othertrans);
            } else {
              if (
                this.state.arr_walletcustomer <
                  this.props.cartTotalArray.price &&
                this.state.isChaked &&
                this.state.selectedOption == "Wallet"
              ) {
                Notification("error", "Error!", "Choose other option to pay");
                return;
              }
              if (this.state.selectedOption === "Paytm") {
                formData.append("payment_type", "online");
              } else if (this.state.selectedOption === "atom") {
                formData.append("payment_type", "atom");
              } else {
                formData.append("payment_type", "cod");
              }
              if (this.state.isChaked) formData.append("is_wallet", 1);
              if (this.state.isChaked) {
                if (
                  this.state.arr_walletcustomer <
                  this.props.cartTotalArray.price
                ) {
                  formData.append(
                    "wallet_amount_to_use",
                    this.state.arr_walletcustomer
                  );
                } else {
                  formData.append(
                    "wallet_amount_to_use",
                    this.props.cartTotalArray.price
                  );
                }
              }
            }

            formData.append(
              "shipping_address_id",
              this.state.selected_address_ID
            );
            if (this.state.payment_proof_img) {
              formData.append("attachment", this.state.payment_proof_img);
            }

            for (var pair of formData.entries()) {
              // console.log('pair...', pair);
            }
            await this.setState({ isLoadingPay: true });

            // window.location.href = 'https://paynetzuat.atomtech.in/paynetz/epi/fts?login=9132&encdata=EE09C765D78EB9AA8406378CAA3E230917A126B5D52A15A1D953D5E15411C9561A8860BE1AC5716B9D21275D6B01E5064E0687C7FE4DEE7D6219B0AE543C97A79557D96496753B1C4A236CAC1A23D7BB05FD5C5199728E318C84C36A34B02846B15473474A7154FE2B40CF48328EDB5370F3B2B2CC1F991AEF654789BFCDC427A2783AECA38DD0E0BA18109F1B276005CF70D76BD9CD2AC29B01EAB694006D6AD36237F95E6074AE2E64059DB59FF0A9C3767FC7FBF96261613898B5CC17C90F33C1ED93C547271B2545415A91907970E99E37A973B3EBEEADECC986499443FF2489D58BDACD1A73E0CDC68E016F37F9740D770D80CB34AFCC457779659AFE8B992779A0C2A9CCCEF58F61B80D70F983883EE4FA7656D41E2B0C02E871DEE20F5A1833B5DB4389A4E03CC64E50C70D0EB12D20A96E076C3637951D93417F5B24C366D70AA0498CAB0C45BB5F51ADEB17AA3DA92354283B0AD81E5B949ED621391EEBC3916666CB5378934F49F8E183A47B99746FB73B33FEE3E528C69F7ABA3A842AAA7388C3C69DC82223CE1FFBD8BB';

            var res = await NaswizHelperApi.POST_WITH_TOKEN(
              "v1/place-order",
              formData,
              "Error => place order api"
            );

            if (res && res.success === 1) {
              this.setState({ loading_placeOrder: false });
              if (this.state.selectedOption === "Paytm") {
                this.setState({ _orderID: res.response.order_id });
                await localStorage.setItem("order_id", res.response.order_id);
                await localStorage.setItem("payment_type", "online");
                await localStorage.setItem("online_type", "Paytm");
                await displayPaytm(
                  res.response,
                  this.state.uniueId,
                  this.props
                );
                this.setState({ isLoadingPay: false });
              } else if (this.state.selectedOption === "atom") {
                await localStorage.setItem("order_id", res.order_id);
                await localStorage.setItem("payment_type", "online");
                await localStorage.setItem("online_type", "atom");
                this.setState({ isLoadingPay: false });
                if (res.atomTokenId !== null) {
                  const Atom = window.openPay(
                    res.atomTokenId,
                    "330019",
                    this.props.userData.email,
                    this.props.userData.number,
                    `${MAIN_URL}master-naswiz/naswiz/order/response/atom`
                  );
                }
              } else {
                await localStorage.setItem("order_id", res.order_id);
                await localStorage.setItem("payment_type", "cod");

                var formdata = new FormData();
                formdata.append("device_id", this.state.uniueId);
                await this.props.getCartData(
                  "v1/get-cart-list",
                  formdata,
                  "Error => GetCartList api."
                );
                await this.setState({ isLoadingPay: false });
                await this.setState({ goToSuccess: true });
              }
            } else {
              alert("Try again later");
              this.setState({ isLoadingPay: false });
              this.setState({ loading_placeOrder: false });
            }
          }
        } else {
          this.setState({ _isProfileDone: true });
        }
      } else {
        this.setState({ isLoadingPay: false });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  LinkTOHome = () => {
    return <Link to={`${process.env.PUBLIC_URL}/`}> </Link>;
  };

  getUserAddress = async () => {
    var token = localStorage.getItem("token");

    var url = base_url.concat("v1/view-address");

    var res = await NaswizHelperApi.GET_WITH_TOKEN(
      "v1/view-address",
      "Error => v1/view-address"
    );

    if (res && res.success === 1) {
      if (res.message) {
        var data = res.message;
        this.setState({
          all_address_data: res.message,
        });
      } else {
      }
    } else {
    }
  };

  _onChangeTextEditProfile = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  firstTimeGettingStateList = () => {
    this.setState({ loading_state: true }, async () => {
      var formData = new FormData();
      formData.append("country_id", "101");

      var res = await NaswizHelperApi.POST_WITH_TOKEN(
        "v1/state",
        formData,
        "Error => v1/state"
      );
      if (res && res.success === 1) {
        this.setState({
          arr_state: res.message,
          loading_state: false,
        });
      } else {
        this.setState({ loading_state: false });
      }
    });
  };

  getStateUponCountry = () => {
    // passing country id 101

    this.setState({ loading_state: true }, async () => {
      var formData = new FormData();
      formData.append("country_id", "101");

      var res = await Helper.POST("v1/state", formData);

      if (res && res.success === 1) {
        this.setState(
          {
            arr_state: res.message,
            loading_state: false,
          },
          () => this.getCityUponState()
        );
      } else {
        this.setState({ loading_state: false });
      }
    });
  };

  getCityUponState = (value) => {
    this.setState({ loading_city: true }, async () => {
      var formData = new FormData();
      formData.append("state_id", this.state.state);
      formData.append("country_id", "101");

      var res = await NaswizHelperApi.POST_WITH_TOKEN(
        "v1/cities",
        formData,
        "Error => v1/state"
      );

      if (res && res.success === 1) {
        this.setState({
          arr_city: res.message,
          loading_city: false,
        });
      } else {
        this.setState({
          loading_city: false,
        });
      }
    });
  };

  addUserAddress = () => {
    this.setState({ loading_city: false }, async () => {
      var {
        addressID,
        full_name,
        phone,
        pincode,
        address,
        city,
        state,
        is_status,
        gst_no,
        aadhar_no,
        aadhar_image,
      } = this.state;

      var formData = new FormData();

      if (addressID != "") {
        formData.append("address_id_no", addressID);
      } else {
        Notification("error", "Error!", "Please enter addressID first!");
      }

      if (full_name != "") {
        formData.append("fullname", full_name);
      } else {
        Notification("error", "Error!", "Please enter addressID first!");
      }

      if (phone != "") {
        formData.append("number", phone);
      } else {
        Notification("error", "Error!", "Please enter number first!");
      }

      if (address != "") {
        formData.append("address", address);
      } else {
        Notification("error", "Error!", "Please select address first!");
      }

      if (pincode != "") {
        formData.append("pincode", pincode);
      } else {
        Notification("error", "Error!", "Please enter pincode first!");
      }

      if (city != "") {
        formData.append("city_id", city);
      } else {
        Notification("error", "Error!", "Please enter city first!");
      }

      if (state != "") {
        formData.append("state_id", state);
      } else {
        Notification("error", "Error!", "Please enter state first!");
      }
      if (is_status != 0) {
        formData.append("is_status", is_status);
      } else {
        Notification("error", "Error!", "Please select address type first!");
      }

      if (gst_no != "") {
        formData.append("gst_no", gst_no);
      }
      if (aadhar_no != "") {
        formData.append("aadhar_no", aadhar_no);
      }
      if (aadhar_image != "") {
        formData.append("aadhar_image", aadhar_image);
      }

      formData.append("country_id", "101");

      var res = await NaswizHelperApi.POST_WITH_TOKEN(
        "v1/add-address",
        formData,
        "Error => add user address api"
      );

      if (res && res.success === 1) {
        this.setState({
          loading_addAddress: false,
          phone: "",
          address: "",
          city: "",
          state: "",
          pincode: "",
          addressID: "",
          full_name: "",
          arr_city: [],
          gst_no: "",
          aadhar_no: "",
          aadhar_image: "",
          is_status: 0,
          _isAddressVisible: false,
          open: false,
        });
        this.getUserAddress();
        // () => this.StripeClick())
        Notification("success", "Sucess!", "Address Edit Successfully");
      } else {
        this.setState({
          loading_addAddress: false,
        });
      }
    });
  };

  onChangeState = (value) => {
    if (value != "") {
      this.setState({ state: value });
      this.getCityUponState(value);
    }
  };

  customerDetails = async () => {
    var res = await NaswizHelperApi.POST_WITH_TOKEN(
      "v1/customers-detail",
      null,
      "Error => Customer details api"
    );

    if (res && res.success === 1) {
      this.setState({ _is_used: res.record.is_used });
    }
  };

  onOpenEditModal = async (item, index) => {
    // console.log("----", item.aadhar_no, item.aadhar_no === null, item.aadhar_no === 'null')
    this.setState({
      openEdit: true,
      edit_address_name: item.fullname,
      edit_address_address: item.address,
      edit_address_pincode: item.pincode,
      edit_address_addressType: item.is_status,
      selectedCountryId: item.country_id,
      selectedStateId: item.state_id,
      selectedCityId: item.city_id,
      edit_number: item.number,
      edit_aadhar_no: item.aadhar_no !== "null" ? item.aadhar_no : "",
      edit_gst_no: item.gst_no !== "null" ? item.gst_no : "",
      edit_address_id_no: item.address_id_no,
      edit_aadhar_image_address: item.image_full_path,
      edit_address_object: item,
      isEditAddressObject: true,
    });

    if (item.country_id != "" && item.country_id != undefined) {
      await this._getStateUponCountry(item.country_id);
    }
    if (item.state_id != "" && item.state_id != undefined) {
      await this._getCityUponState(item.state_id);
    }
  };

  _getCountry = async () => {
    var res = await NaswizHelperApi.POST_WITH_TOKEN(
      "v1/countries",
      null,
      "Error => Get country api"
    );

    if (res && res.success == 1) {
      this.setState({
        arr_country: res.message,
      });
      if (
        this.state.selectedCountryId == "" ||
        this.state.selectedCountryId == undefined
      ) {
        this.setState({ selectedCountryId: 101 });
        this._getStateUponCountry(101);
      }
    }
  };

  _getStateUponCountry = async (cId) => {
    if (cId === "Select Country") {
      this._resetCountryField();
    } else {
      this.setState({ selectedCountryId: cId });

      var formData = new FormData();
      formData.append("country_id", cId);

      var res = await NaswizHelperApi.POST_WITH_TOKEN(
        "v1/state",
        formData,
        "Error => Get state upon county api"
      );
      if (res && res.success === 1) {
        this.setState({
          arr_state: res.message,
        });

        if (res.message.length > 0) {
          if (
            this.state.selectedStateId != "" &&
            this.state.selectedStateId != undefined
          ) {
          } else {
            this._getCityUponState(res.message[0].state_id);
          }
        }
      }
    }
  };

  _getCityUponState = async (sId) => {
    this.setState({ selectedStateId: sId });

    var formData = new FormData();
    formData.append("state_id", sId);
    formData.append("country_id", this.state.selectedCountryId);

    var res = await NaswizHelperApi.POST_WITH_TOKEN(
      "v1/cities",
      formData,
      "Error => Get state upon county api"
    );

    if (res && res.success === 1) {
      this.setState({
        arr_city: res.message,
      });
    }
  };

  referralCode = async (referal_code) => {
    if (this.state._referalCode == "") {
      Notification("error", "Error!", "Please enter referral code");
      return;
    }
    var formData = new FormData();
    formData.append("referral_code", referal_code);
    var res = await NaswizHelperApi.POST_WITH_TOKEN(
      "v1/check-referral",
      formData,
      "Error => v1/check-referral"
    );

    if (res.success === 1) {
      Notification("success", "Success!", res.message);
      this.customerDetails();
    } else {
      Notification("error", "Error!", "" + res.message);
    }
  };

  // promocode apply
  promoCodeApply = async () => {
    if (this.state.promocode === "") {
      Notification("error", "Error!", "Please enter promo code");
      return;
    }
    var formData = new FormData();
    formData.append("discount_code", this.state._promocode);
    var res = await NaswizHelperApi.POST_WITH_TOKEN(
      "v1/apply-discount",
      formData,
      "Error => v1/check-referral"
    );

    this.setState({ _visiblePromoCode: false });
    if (res.success === 1) {
      Notification("success", "Success!", res.message);
      this.setState({ _isPromocodeValide: true });
      var body = new FormData();
      body.append("device_id", this.state.uniueId);
      this.props.getCartData(
        "v1/get-cart-list",
        body,
        "Error => GetCartList api."
      );
      // this.customerDetails()
    } else {
      Notification("error", "Error!", "" + res.message);
    }
  };

  removePrmocode = async () => {
    var res = await NaswizHelperApi.POST_WITH_TOKEN(
      "v1/remove-discount",
      {},
      "Error => v1/check-referral"
    );

    if (res.success === 1) {
      Notification("success", "Success!", res.message);
      this.setState({ _isPromocodeValide: false });
      var body = new FormData();
      body.append("device_id", this.state.uniueId);
      this.props.getCartData(
        "v1/get-cart-list",
        body,
        "Error => GetCartList api."
      );
    } else {
      Notification("error", "Error!", "" + res.message);
    }
  };

  setCounter = () => {
    var that = this;
    let timer = setInterval(that.manageTimer, 1000);
    that.setState({ timer });
  };

  manageTimer = () => {
    var that = this;
    var { timer, _counter } = that.state;

    if (_counter === 0) {
      clearInterval(timer);
      that.setState({
        _counter: 0,
      });
    } else {
      that.setState({
        _counter: _counter - 1,
      });
    }
  };

  async resendOtp() {
    await this.setState({ _counter: 60 });
    await this._sendOtp();
  }

  // divya
  _sendOtp = async () => {
    var { phoneNumber } = this.state;
    var URL = "v1/login";
    var ERROR = "Error => Login Or Register api";
    var RESPONSE = "Response => Login Or Register api";

    var numberSub = phoneNumber.slice(1, phoneNumber.length); // Returns "Hello"

    if (phoneNumber.length >= 10) {
      this.setState({
        disablebutton: true,
      });
      var body = new FormData();
      body.append("number", numberSub);

      var res = await NaswizHelperApi.POST_WITHOUT_TOKEN(URL, body, ERROR);

      // if (res && res.message == 'Please first send message.') {
      // 	window.open('https://api.whatsapp.com/send?phone=+919540276786&text=Register%20Me');
      // }
      if (res && res.is_status === 0) {
        this.setState({ _checkRegisteModal: true, phoneNumber: "" });
        // window.open('https://api.whatsapp.com/send?phone=+91 9540276786&text=Register%20Me');
      } else if (res && res.success === 1) {
        this.setState({ _checkRegisteModal: false });
        Notification("success", "Success!", res.message);
        this.setCounter();
        this.setState({
          otpViewOpen: true,
          disablebutton: false,
        });
      } else {
        this.setState({ phoneNumber: "", disablebutton: false });
        Notification(
          "error",
          "Error!",
          res.message ? res.message : "Try again later!"
        );
      }
    } else {
      Notification("error", "Error!", "Enter valid phone number !");
    }
  };

  _verifyOtp = async () => {
    var { otp, phoneNumber } = this.state;
    var OTP = otp;

    var numberSub = phoneNumber.slice(1, phoneNumber.length);
    if (OTP.length === 4) {
      var body = new FormData();
      body.append("number", numberSub);
      body.append("otp", OTP);

      for (var pair of body.entries()) {
      }

      var res = await NaswizHelperApi.POST_WITHOUT_TOKEN(
        "v1/verify-otp",
        body,
        "OTP"
      );

      if (res && res.success === 1) {
        if (
          res.message ===
          "Please contact admin. User is not Authorized to access the site."
        ) {
          Notification("error", "Error!", res.message);
        } else {
          this.props.changeLoginState(true);
          this.props.manageUserIdState(res.id);
          localStorage.setItem("token", res.token);
          this.setState({
            otp: "",
            otpViewOpen: false,
            _deliverycheckdone: true,
          });
          Notification("success", "Success!", "OTP Verify Successfully.");
          setTimeout(() => {
            this._getUserInfo();
            this.getUserAddress();
            this._getMyWalletcustomers();
            var body = new FormData();
            body.append("device_id", this.state.uniueId);
            this.props.getCartData(
              "v1/get-cart-list",
              body,
              "Error => GetCartList api."
            );
          }, 2000);
        }
      } else {
        Notification(
          "error",
          "Error!",
          res.message ? res.message : "Try again later."
        );
        this.setState({
          otp: "",
        });
      }
    } else {
      Notification("error", "Error!", "Enter valid OTP!");
      this.setState({
        otp: "",
      });
    }
  };

  _getUserInfo = async () => {
    var res = await NaswizHelperApi.POST_WITH_TOKEN(
      "v1/customers-detail",
      null,
      "Error => v1/customers-detail api."
    );

    if (res && res.success === 1) {
      this.props.manageUserIdState(res.record.id);
      this.props.addUserData(res.record);
    } else {
      this.logout();
    }
  };

  onValueChange = (event) => {
    this.setState({
      selectedOption: event.target.value,
      _otherpaymenttype: "",
    });
  };

  handleChange = (otp) => this.setState({ otp });

  handleChangeWallet = async () => {
    await this.setState({ isChaked: !this.state.isChaked });
    await this.setState({
      selectedOption: this.state.isChaked ? "Wallet" : "",
    });
  };
  handleSubmit = (e) => {
    if (this.state.phoneNumber && this.state.phoneNumber.length >= 13) {
      this._sendOtp();
    }
  };
  handleKeyPress = (event) => {
    // look for the `Enter` keyCode
    if (event.keyCode === 13 || event.which === 13) {
      this.handleSubmit();
    }
  };
  handleOtpSubmit = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      if (this.state.otp.length == 4) {
        this._verifyOtp();
      }
    }
    event.preventDefault();
  };

  // onChangePaymentType = async (type) => {
  // 	this.setState({ _choosepamentone: type })
  // 	if (type == 1) {
  // 		this.setState({ selectedOption: '' })
  // 	}
  // 	else {
  // 		this.setState({ _otherpaymenttype: '' })
  // 	}
  // }

  render() {
    var number =
      this.props.userData && this.props.userData.number
        ? this.props.userData.number
        : "Not Available";
    var gender =
      this.props.userData && this.props.userData.gender
        ? this.props.userData.gender
        : "Not Available";
    var {
      _id_number,
      edited_name,
      edited_email_id,
      edited_dob,
      edited_anniversary_date,
      edited_gender,
      edited_marital_status,
      edited_profile_image,
      edited_aadhar_card_image,
      edited_pan_card_image,
      edit_aadhar_image_address,
      edit_aadhar_imagepath_address,
    } = this.state;
    const { cartItems, symbol, total } = this.props;
    const {
      arr_state,
      arr_city,
      all_address_data,
      selected_address_ID,
    } = this.state;

    // Paypal Integration
    const onSuccess = (payment) => {
      this.props.history.push({
        pathname: "/order-success",
        state: {
          payment: payment,
          items: cartItems,
          orderTotal: total,
          symbol: symbol,
        },
      });
    };
    // if (this.state.goToDashboard) {
    // 	return (
    // 		<Redirect to={{
    // 			pathname: `/`,
    // 		}} />
    // 	)
    // }
    if (this.state.goToSuccess) {
      return (
        <Redirect
          to={{
            pathname: `/order_confirm`,
          }}
        />
      );
    }

    const onCancel = (data) => {};
    const onError = (err) => {};

    // const client = {
    // 	sandbox: 'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
    // 	production: 'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
    // }

    return (
      <div>
        {/* <Helmet>
					<title>Secure Payment: Login - Select Shipping Address - Review Order - Place - Naswiz Retails Pvt Ltd</title>
					<meta name="og_title" property="og:title" content="Secure Payment: Login > Select Shipping Address > Review Order > Place - Naswiz Retails Pvt Ltd" />
					<meta name="twitter:title" content="Secure Payment: Login > Select Shipping Address > Review Order > Place - Naswiz Retails Pvt Ltd" />
					<meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
					<meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
					<meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
					<meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
				</Helmet> */}

        <Seo
          title="Secure Payment: Login - Select Shipping Address - Review Order - Place Order"
          description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company."
          image="https://cdn-icons-png.flaticon.com/512/4211/4211023.png"
        />
        {/*SEO Support End */}

        <Breadcrumb title={"Checkout"} />

        {this.state.isLoadingPay ? (
          <section className="first">
            <div className="circular-spinner"></div>
          </section>
        ) : null}

        <section className="section-b-space">
          <div className="container">
            <div className="allCheckout">
              <div className="checkout_left">
                {this.state.otpViewOpen ? (
                  <div className={"couter_list"}>
                    <h4 className={"register_modal_title"}>
                      {" "}
                      {this.state._is_whatsapp == "0"
                        ? "Mobile Number Verification"
                        : "WhatsApp Number Verification"}
                    </h4>
                    <div
                      className="otp_verification"
                      style={{ display: "block" }}
                    >
                      <p>
                        We need your{" "}
                        {this.state._is_whatsapp == "0" ? "Mobile" : "WhatsApp"}{" "}
                        number so that we can update you about your order.
                      </p>
                      <label>
                        {this.state._is_whatsapp == "0"
                          ? "Enter 4 digit code sent to your Mobile"
                          : "Enter 4 digit code sent to your WhatsApp"}{" "}
                        <b>{this.state.phoneNumber}</b>
                        <a
                          href="javascript:void(0)"
                          onClick={() => this.setState({ otpViewOpen: false })}
                        >
                          (Edit
                          <img
                            className="edit-img"
                            src="assets/images/edit.png"
                            alt=""
                          />
                          )
                        </a>
                      </label>
                      <div className="d-flex">
                        <div className="register_modal_number_box">
                          <OtpInput
                            className={"login_otp_input"}
                            value={this.state.otp}
                            onChange={this.handleChange}
                            numInputs={4}
                            separator={<span className="dash-icon">-</span>}
                            isInputNum={true}
                            shouldAutoFocus
                            onKeyPress={this.handleOtpSubmit}
                          />
                        </div>
                        <button
                          onClick={() => {
                            if (this.state.otp.length == 4) {
                              this._verifyOtp();
                            }
                          }}
                          className={
                            this.state.otp.length == 4
                              ? "register_modal_next_btn common_login_sign_up_btn common_login_sign_up_btn_click"
                              : "register_modal_next_btn common_login_sign_up_btn"
                          }
                          type="button"
                        >
                          Next
                        </button>
                      </div>
                      <p
                        style={{ textAlign: "left !important" }}
                        onClick={() => {
                          this.resendOtp();
                        }}
                        className={
                          this.state._counter != 0
                            ? "register_otp_resend hide mb-0"
                            : "register_otp_resend active mb-0"
                        }
                      >
                        Resend Code <span>(in {this.state._counter} secs)</span>
                      </p>
                    </div>
                  </div>
                ) : (
                  <div
                    className={
                      !this.props.isUserLogin
                        ? "couter_list "
                        : "couter_list active"
                    }
                  >
                    <div className="ifLogin before-none">
                      <h4 className="text-left">
                        {this.state._is_whatsapp == "0"
                          ? "Mobile Number Verification"
                          : "WhatsApp Number Verification"}
                      </h4>
                      {/* <div className="after_login">
												<p>+91 7503782454</p>
											</div> */}
                      {!this.props.isUserLogin ? (
                        <div className="content_inside">
                          <p className="whatsapp-verification-label">
                            We need your{" "}
                            {this.state._is_whatsapp == "0"
                              ? "Mobile"
                              : "WhatsApp"}{" "}
                            number so that we can update you about your order.
                          </p>
                          {this.state._checkRegisteModal ? (
                            <div className={"login_modal_number_box logintext"}>
                              {/* <p className="mantitle">Information</p> */}
                              <div>
                                <span className="title">
                                  For Sign Up, Please register your self on
                                  WhatsApp
                                </span>
                              </div>
                              <div>
                                <span className="subtitle">
                                  Follow the below steps for registration{" "}
                                </span>
                              </div>
                              <div>
                                <span>
                                  1. Save the Naswiz WhatsApp No to your
                                  contacts : +919540276786{" "}
                                </span>
                              </div>
                              <div>
                                <span>
                                  2. Send Hi on WhatsApp On this Naswiz WhatsApp
                                  No +919540276786{" "}
                                </span>
                              </div>
                              <div>
                                <span>3. Press Next </span>
                              </div>
                              {/* <button onClick={() => this.setState({ _checkRegisteModal: false })} className={'login_modal_next_btn_click common_login_sign_up_btn'} type="button">Ok</button> */}
                            </div>
                          ) : null}
                          <div className="login_btn_check">
                            <label htmlFor="">
                              {this.state._is_whatsapp == "0"
                                ? "Your 10 digit mobile number"
                                : "Your 10 digit Whatsapp number"}
                            </label>
                            {/* {console.log('this.state._is_whatsapp ', this.state._is_whatsapp )} */}
                            {/* <input type="tel" maxlength="10" className="form-control" /> */}
                            <div className={"login_modal_phone number-hide"}>
                              <PhoneInput
                                international
                                focusInputOnCountrySelection
                                limitMaxLength
                                defaultCountry="IN"
                                value={this.state.phoneNumber}
                                autoFocus={true}
                                onChange={(value) => {
                                  this.setState({ phoneNumber: value });
                                }}
                                onKeyPress={this.handleKeyPress}
                              />
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              this.handleSubmit();

                              // if (this.state.phoneNumber && this.state.phoneNumber.length >= 13) {
                              // 	this._sendOtp()
                              // }
                            }}
                            className={
                              this.state.phoneNumber &&
                              this.state.phoneNumber.length >= 13 &&
                              !this.state.disablebutton
                                ? "next_btn_check active"
                                : "next_btn_check"
                            }
                            type="button"
                          >
                            Next
                          </button>
                          {/* <button className="next_btn_check" id="show_verifications" type="button">Next</button> */}
                        </div>
                      ) : (
                        <div className={"verified-datetime c-notdone "}>
                          {/* <div className="change_address done">
											<a id="changeadatetime" type="button" className="change-btn" onClick={() => this.setState({ _timeSlotSelect: false })}>Change</a>
										</div> */}
                          {this.props.userData ? (
                            <div className="address_checkout done">
                              <div className="address_box">
                                <i className="fa fa-mobile"></i>
                                <h5>+{this.props.userData.number}</h5>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div
                  className={
                    "couter_list " +
                    (this.props.isUserLogin &&
                    this.state.selected_address_ID != ""
                      ? "active"
                      : "d-none1")
                  }
                >
                  <h4>Delivery Address</h4>

                  <div
                    className={
                      this.state._deliverycheckdone
                        ? "block_edit_add  c-notdone"
                        : " block_edit_add  c-done"
                    }
                  >
                    <div className="editable_check_add">
                      <p className="mt-2">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AddressModal"
                          onClick={this.onOpenModal}
                        >
                          <i
                            className="fa fa-plus-circle"
                            aria-hidden="true"
                          ></i>{" "}
                          Add New Delivery Address
                        </a>
                      </p>
                    </div>
                    <div className="row">
                      {all_address_data && all_address_data.length > 0
                        ? all_address_data.map((address, index) => {
                            return (
                              <div className="col-md-6 mb-4" key={index}>
                                <div className="check_add_box d-flex flex-column h-100">
                                  {address.is_status === 1 ? (
                                    <h3>Home</h3>
                                  ) : address.is_status === 2 ? (
                                    <h3>Office</h3>
                                  ) : (
                                    <h3>Other</h3>
                                  )}
                                  <div className="icon_edit">
                                    <a
                                      data-toggle="modal"
                                      data-target="#editaddressModal"
                                      onClick={() =>
                                        this.onOpenEditModal(address, index)
                                      }
                                    >
                                      <img
                                        src="assets/images/edit.png"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                  <h5>
                                    {address.address_id_no}-{address.fullname}{" "}
                                  </h5>

                                  <p>{address.address}</p>
                                  {/* <a href="javascript:void(0);" className="check_deliver" onClick={this.addAddressID(address.id)}>Deliver Here</a> */}
                                  {this.state.selected_address_ID != "" &&
                                  this.state.selected_address_ID ===
                                    address.id ? (
                                    <a
                                      href="javascript:void(0);"
                                      className="check_deliver checked"
                                      onClick={() =>
                                        this.setState({
                                          selectedAddressFull: address.address,
                                          _deliverycheckdone: false,
                                          _deliverycheckdoneselect: true,
                                          _payment: true,
                                          _delivery_date: true,
                                          selected_address_ID: address.id,
                                          selectedAddressFull2:
                                            address.address_id_no +
                                            "-" +
                                            address.fullname,
                                        })
                                      }
                                    >
                                      Address is selected
                                    </a>
                                  ) : (
                                    <a
                                      href="javascript:void(0);"
                                      className="check_deliver mt-auto"
                                      onClick={() => {
                                        this.setState({
                                          selectedAddressFull2:
                                            address.address_id_no +
                                            "-" +
                                            address.fullname,
                                          selectedAddressFull: address.address,
                                          _deliverycheckdone: false,
                                          _deliverycheckdoneselect: true,
                                          _payment: true,
                                          selected_address_ID: address.id,
                                          _delivery_date: true,
                                        });
                                        this.getCartList(address.id);
                                      }}
                                    >
                                      Deliver Here
                                    </a>
                                  )}
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                  {this.state._deliverycheckdone == false ? (
                    <div
                      className={
                        this.state.selected_address_ID != ""
                          ? "verified-address  c-notdone"
                          : " verified-address  c-done"
                      }
                    >
                      <div className="change_address done">
                        <a
                          id="changeaddress"
                          onClick={() =>
                            this.setState({ _deliverycheckdone: true })
                          }
                          className="change-btn"
                        >
                          Change
                        </a>
                      </div>
                      <div className="address_checkout done">
                        <div className="address_box">
                          <i className="fas fa-home"></i>
                          <h5> {this.state.selectedAddressFull2}</h5>
                          <p>{this.state.selectedAddressFull}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                {/* delivery date end */}
                <div className={"couter_list "}>
                  <h4>Payment</h4>
                  <div
                    className={
                      this.state.selected_address_ID != ""
                        ? " c-notdone"
                        : " c-done"
                    }
                  >
                    {this.props.isUserLogin &&
                    this.props.cartTotalArray != undefined ? (
                      <div className="cartdetails">
                        <div className="totalCalcualte">
                          <div className="top_left_t">
                            <p>
                              IP Points{" "}
                              <span>{this.props.cartTotalArray.total_ip}</span>
                            </p>
                          </div>
                          <div className="top_left_t">
                            <p>
                              MRP Total{" "}
                              <span>
                                ₹{this.props.cartTotalArray.main_price}
                              </span>
                            </p>
                          </div>
                          <div className="top_left_t">
                            <p>
                              Discount{" "}
                              <span>
                                - ₹{this.props.cartTotalArray.discount_price}
                              </span>
                            </p>
                          </div>
                          <div className="top_left_t">
                            <p>
                              Total Price{" "}
                              <span>
                                ₹
                                {
                                  this.props.cartTotalArray
                                    .price_without_shipping
                                }
                              </span>
                            </p>
                          </div>
                          <div className="top_left_t">
                            <p>
                              Delivery Charge{" "}
                              <span>
                                ₹{this.props.cartTotalArray.shipping_charge}
                              </span>
                            </p>
                          </div>

                          <div className="bottom_both">
                            <p>
                              Total Amount{" "}
                              <span>
                                {
                                  this.props.cartTotalArray
                                    .format_total_priceINR
                                }
                              </span>
                            </p>
                          </div>

                          <div className="bottom_both">
                            <p>
                              Total IP{" "}
                              <span>{this.props.cartTotalArray.total_ip}</span>
                            </p>
                          </div>
                        </div>
                        {this.state._is_used === null ||
                        this.state._is_used == "" ? (
                          <div className="referal_code done">
                            <div className="input-group refercode-inputgroup done">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Referral Code"
                                onChange={(e) =>
                                  this.setState({
                                    _referalCode: e.target.value,
                                  })
                                }
                              />
                              <div className="input-group-append done">
                                <a
                                  id="refercode"
                                  className="input-group-text"
                                  onClick={() =>
                                    this.referralCode(this.state._referalCode)
                                  }
                                >
                                  Refer
                                </a>
                              </div>
                            </div>
                            {/* <p className="refferalcodeapplied">Referral Code Apply Successfully</p> */}
                          </div>
                        ) : // <div className="referal_code" style={{ flexDirection: 'row' }}>

                        // 	<input style={{ width: '90%' }} type="text" placeholder="Enter Referral Code" onChange={e => this.setState({ _referalCode: e.target.value })} />
                        // 	<button onClick={() => this.referralCode(this.state._referalCode)} className="next_btn_cop" type="button">Apply</button>

                        // 	{/* <p contenteditable="true">Referral Code  <span><input type="text" className="refInput" placeholder="Enter Referral Code"></span> </p>  */}
                        // </div>
                        null}
                      </div>
                    ) : null}

                    {/*  0 means not to show and if it is 1 mean promo code is available */}
                    {this.props.cartTotalArray &&
                    this.props.cartTotalArray.discount_box == 1 ? (
                      <div className="prodmo_code">
                        <div className="item_sub_check_offer">
                          <a
                            href="javascript:void(0)"
                            onClick={() =>
                              this.setState({ _visiblePromoCode: true })
                            }
                            data-toggle="modal"
                            data-target="#prodmo_code"
                          >
                            <div className="left_check">
                              <p>Promo code & Bank offers</p>
                            </div>

                            <div className="right_check">
                              <p>
                                <i
                                  className="fa fa-angle-right"
                                  aria-hidden="true"
                                ></i>
                              </p>
                            </div>
                          </a>
                        </div>
                        {this.state._isPromocodeValide ? (
                          <button
                            onClick={() => this.removePrmocode()}
                            className="remove"
                            type="button"
                          >
                            Remove Promocode
                          </button>
                        ) : null}
                      </div>
                    ) : null}

                    <div className="payment_tab">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#wallet"
                            onClick={() => {
                              this.setState({
                                _choosepamentone: 0,
                                _otherpaymenttype: "",
                                _otheramount: "",
                                _otherdate: "",
                                _othertrans: "",
                              });
                              // this.onChangePaymentType(0)
                            }}
                          >
                            Online Payment
                          </a>
                        </li>
                        {/* <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#card">Card</a></li>
											<li className="nav-item"><a className="nav-link " data-toggle="tab" href="#cash">Cash</a></li>
											<li className="nav-item"><a className="nav-link" data-toggle="tab" href="#netbanking">Net Banking</a></li>*/}
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#othermethod"
                            onClick={() => {
                              this.setState({
                                _choosepamentone: 1,
                                selectedOption: "",
                              });
                              // this.onChangePaymentType(1)
                            }}
                          >
                            Other Method
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div id="wallet" className="tab-pane container active">
                          <div className="payment-wallets">
                            {this.state.arr_walletcustomer > 0 ? (
                              <div className="indivisual_pay">
                                <label className="wallet-opt card-selection">
                                  <div className="payment-radio-design checkbox">
                                    <input
                                      id="Paytm"
                                      type="checkbox"
                                      className="checkbox"
                                      value=""
                                      defaultChecked={this.state.isChaked}
                                      onChange={() => this.handleChangeWallet()}
                                    />
                                    <span className="radio-element"></span>
                                    <div className="wallet-type">
                                      <i className="fas fa-wallet"></i>
                                      <div>
                                        <p>Wallet Balance</p>
                                        {/* <p className="available-balance">Available Balance <span className="text-theme-primary fw-bold">₹500</span></p> */}
                                        <p className="available-balance">
                                          Available Balance{" "}
                                          <span className="text-theme-primary fw-bold">
                                            {this.state.arr_walletcustomer}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-selection__details">
                                    {/* <span className="">Wallet  (Wallet Balance <strong>{this.state.arr_walletcustomer}</strong>)</span> */}
                                    {this.state.isChaked &&
                                    this.props.cartTotalArray ? (
                                      <span className="">
                                        Wallet (Wallet Balance{" "}
                                        <strong>
                                          {" "}
                                          {this.state.arr_walletcustomer -
                                            this.props.cartTotalArray.price <
                                          0
                                            ? "0"
                                            : this.state.arr_walletcustomer -
                                              this.props.cartTotalArray.price}
                                        </strong>
                                        )
                                      </span>
                                    ) : null}
                                    {this.props.cartTotalArray ? (
                                      this.state.arr_walletcustomer <
                                        this.props.cartTotalArray.price &&
                                      this.state.isChaked ? (
                                        <span className="">
                                          Choose other option to pay{" "}
                                          {this.props.cartTotalArray.price -
                                            this.state.arr_walletcustomer}{" "}
                                        </span>
                                      ) : null
                                    ) : null}
                                  </div>
                                </label>
                              </div>
                            ) : null}
                            <div className="indivisual_pay">
                              <label className="wallet-opt card-selection">
                                <div className="payment-radio-design radio">
                                  <input
                                    id="atom"
                                    type="radio"
                                    className="radio"
                                    value="atom"
                                    checked={
                                      this.state.selectedOption === "atom"
                                    }
                                    onChange={
                                      (event) => {
                                        if (
                                          this.state.isChaked &&
                                          this.state.arr_walletcustomer >=
                                            this.props.cartTotalArray.price
                                        ) {
                                        } else {
                                          this.onValueChange(event);
                                        }
                                      }
                                      // displayPaytm
                                    }
                                  />
                                  <span className="radio-element"></span>

                                  <div className="wallet-type">
                                    <img src="../../assets/images/atom.png" />
                                  </div>
                                </div>
                                <div className="card-selection__details">
                                  <span className="wallet_name">Atom</span>
                                  <span className="details_payemt">
                                    Debit Card / Credit Card / Net Banking /
                                    Paytm / UPI / Wallet Payments
                                  </span>
                                </div>
                              </label>
                            </div>
                            <div className="indivisual_pay">
                              <label className="wallet-opt card-selection">
                                <div className="payment-radio-design radio">
                                  <input
                                    id="Paytm"
                                    type="radio"
                                    className="radio"
                                    value="Paytm"
                                    checked={
                                      this.state.selectedOption === "Paytm"
                                    }
                                    onChange={
                                      (event) => {
                                        if (
                                          this.state.isChaked &&
                                          this.state.arr_walletcustomer >=
                                            this.props.cartTotalArray.price
                                        ) {
                                        } else {
                                          this.onValueChange(event);
                                        }
                                      }
                                      // displayPaytm
                                    }
                                  />
                                  <span className="radio-element"></span>
                                  <div className="wallet-type">
                                    <img src="../../assets/images/paytm.png" />
                                  </div>
                                </div>
                                <div className="card-selection__details">
                                  <span className="wallet_name">Paytm</span>
                                  <span className="details_payemt">
                                    Paytm / UPI / Wallet Payments
                                  </span>
                                </div>
                              </label>
                            </div>
                            {this.props.cartTotalArray &&
                            this.props.cartTotalArray.is_cod == "0" ? null : (
                              <div className="indivisual_pay">
                                <label className="wallet-opt card-selection">
                                  <div className="payment-radio-design radio">
                                    <input
                                      id="COD"
                                      type="radio"
                                      className="radio"
                                      value="COD"
                                      checked={
                                        this.state.selectedOption === "COD"
                                      }
                                      onChange={
                                        (event) => {
                                          if (
                                            this.state.isChaked &&
                                            this.state.arr_walletcustomer >=
                                              this.props.cartTotalArray.price
                                          ) {
                                          } else {
                                            this.onValueChange(event);
                                          }
                                        }
                                        // displayPaytm
                                      }
                                    />
                                    <span className="radio-element"></span>
                                    <div className="wallet-type">
                                      <img src="../../assets/images/cod.png" />
                                    </div>
                                  </div>
                                  <div className="card-selection__details">
                                    <span className="wallet_name">COD</span>
                                    <span className="details_payemt">
                                      Cash On Delivery
                                    </span>
                                  </div>
                                </label>
                              </div>
                            )}
                          </div>

                          {/* {this.state.arr_walletcustomer < this.props.cartTotalArray.price ?		
														<div className="payment-btn-holder">
															<button className={this.state.selectedOption == '' ? "make_payment_new btn" : "make_payment_new btn red"}>Pay Now</button>
															<span>Please Select Other Payment Opction</span>
														</div>
														: */}
                          {this.state._loading_placeOrder ? (
                            //  null :
                            <div
                              className="loader-wrapper"
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                left: "0px",
                              }}
                            >
                              <div className="loader"></div>
                            </div>
                          ) : null}

                          <div className="payment-btn-holder">
                            {/* <button className={this.state.selectedOption == '' ? "make_payment_new btn" : "make_payment_new btn red"} onClick={() => {
															if (this.state.selectedOption == 'atom') {
																this.StripeClickAtom()

															} else {
																this.StripeClick()

															}
														}}
														>
															Pay Now</button> */}
                            <button
                              className={
                                this.state.selectedOption == ""
                                  ? "make_payment_new btn"
                                  : "make_payment_new btn red"
                              }
                              onClick={() => this.StripeClick()}
                            >
                              Pay Now
                            </button>
                          </div>
                          {/* } */}
                        </div>
                        <div
                          id="othermethod"
                          className="tab-pane container fade"
                        >
                          <div className="other_method">
                            <label htmlFor="cheque">
                              <input
                                type="radio"
                                id="cheque"
                                name="gender"
                                value="cheque"
                                checked={
                                  this.state._otherpaymenttype === "cheque"
                                }
                                onChange={(event) => {
                                  this.setState({
                                    _otherpaymenttype: "cheque",
                                    selectedOption: "",
                                  });
                                }}
                              />
                              Cheque
                            </label>
                            <label htmlFor="neft">
                              <input
                                type="radio"
                                id="neft"
                                name="gender"
                                value="neft"
                                checked={
                                  this.state._otherpaymenttype === "neft"
                                }
                                onChange={(event) => {
                                  this.setState({
                                    _otherpaymenttype: "neft",
                                    selectedOption: "",
                                  });
                                }}
                              />
                              NEFT
                            </label>
                            <label htmlFor="imps">
                              <input
                                type="radio"
                                id="imps"
                                name="gender"
                                value="imps"
                                checked={
                                  this.state._otherpaymenttype === "imps"
                                }
                                onChange={(event) => {
                                  this.setState({
                                    _otherpaymenttype: "imps",
                                    selectedOption: "",
                                  });
                                }}
                              />
                              IMPS
                            </label>
                            <label htmlFor="cashdeposit">
                              <input
                                type="radio"
                                id="cashdeposit"
                                name="gender"
                                value="cashdeposit"
                                checked={
                                  this.state._otherpaymenttype === "cashdeposit"
                                }
                                onChange={(event) => {
                                  this.setState({
                                    _otherpaymenttype: "cashdeposit",
                                    selectedOption: "",
                                  });
                                }}
                              />
                              Cash Deposit
                            </label>
                            <label htmlFor="cardswipe">
                              <input
                                type="radio"
                                id="cardswipe"
                                name="gender"
                                value="cardswipe"
                                checked={
                                  this.state._otherpaymenttype === "cardswipe"
                                }
                                onChange={(event) => {
                                  this.setState({
                                    _otherpaymenttype: "cardswipe",
                                    selectedOption: "",
                                  });
                                }}
                              />
                              Showroom Card Swipe
                            </label>
                          </div>
                          {/* <form> */}
                          <div className="input-row">
                            <div className="single-input-item">
                              <label>Amount:</label>
                              <input
                                type="number"
                                name="amount"
                                placeholder="Amount"
                                required
                                value={this.state._otheramount}
                                onChange={(e) =>
                                  this.setState({
                                    _otheramount: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="single-input-item ">
                              <label>Date:</label>
                              <input
                                type="date"
                                name="Date"
                                placeholder="Date"
                                required
                                value={this.state._otherdate}
                                onChange={(e) =>
                                  this.setState({ _otherdate: e.target.value })
                                }
                              />
                            </div>
                            <div className="single-input-item">
                              <label>Transaction Detail:</label>
                              <input
                                type="text"
                                name="amount"
                                placeholder="Transaction Detail"
                                required
                                value={this.state._othertrans}
                                onChange={(e) =>
                                  this.setState({ _othertrans: e.target.value })
                                }
                              />
                            </div>
                            <div className="single-input-item payment-file">
                              <label>Screenshot of payment as proof :</label>
                              <div className="input-group">
                                <div className="input-group-append w-100">
                                  <input
                                    type="file"
                                    className="form-control forms_inputs"
                                    onChange={(e) =>
                                      this.setState({
                                        payment_proof_img: e.target.files[0],
                                      })
                                    }
                                  />
                                  <span
                                    className="input-group-text"
                                    onClick={() =>
                                      this.setState({ payment_proof_img: "" })
                                    }
                                  >
                                    <i
                                      className="fa fa-times"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </div>
                                {this.state.payment_proof_img ? (
                                  <div className="m-2">
                                    <img
                                      src={URL.createObjectURL(
                                        this.state.payment_proof_img
                                      )}
                                      alt="Proof Image"
                                    />
                                  </div>
                                ) : null}
                              </div>
                              <small>
                                Accept .JPG, .JPEG, .PNG and .PDF document with
                                less than 2048 KB size.
                              </small>
                            </div>
                          </div>
                          <div className="payment-btn-holder small">
                            {/* <button  className="make_payment_new btn" onClick={() =>this.StripeClick()}>Pay Now</button> */}
                            <button
                              className={
                                this.state._otherpaymenttype == ""
                                  ? "make_payment_new btn"
                                  : "make_payment_new btn red"
                              }
                              onClick={() => this.StripeClick()}
                            >
                              Pay Now
                            </button>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal open={this.state.open} onClose={this.onCloseModal} center>
            <div className="editaddressModal vertical-align-center">
              {/* <!-- Modal content--> */}
              <div className="adderess_inside">
                <h4>Add New Delivery Address</h4>
                <p>
                  Please enter the accurate address, it will help us to serve
                  you better
                </p>
              </div>
              <div className="inside_form_fields">
                <div className="theme-form address-form">
                  <form role="form">
                    <div className="row">
                      <div className="col-12 col-md-6 col-sm-6">
                        <div className="form-group">
                          <label htmlFor="">
                            ID No.<sup className="top_star">*</sup>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            value={this.state.addressID}
                            onChange={(e) => this.onChangeID(e)}
                            // onChange={e => this.setState({ addressID: e.target.value })}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-sm-6">
                        <div className="form-group">
                          <label htmlFor="">
                            Name<sup className="top_star">*</sup>
                          </label>
                          <input
                            type="text"
                            value={this.state.full_name}
                            className="form-control"
                            onChange={(e) => {
                              const newName = e.target.value.replace(
                                /[^a-zA-Z\s]/g,
                                ""
                              );
                              this.setState({ full_name: newName });
                            }}
                            // onChange={e => this.setState({ full_name: e.target.value })}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="">
                            Address<sup className="top_star">*</sup>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            onChange={(e) =>
                              this.setState({ address: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-4 col-sm-4">
                        <div className="form-group">
                          <label htmlFor="">
                            Country <sup className="top_star">*</sup>
                          </label>
                          <select
                            value={this.state.selectedCountryId}
                            onChange={(e) =>
                              this._getStateUponCountry(e.target.value)
                            }
                            className="form-control"
                          >
                            <option value={undefined}>Select Country</option>
                            {this.state.arr_country.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-12 col-md-4 col-sm-4">
                        <div className="form-group">
                          <label htmlFor="">
                            State <sup className="top_star">*</sup>
                          </label>
                          {/* <select className="form-control" onChange={e => this.setState({state : e.target.value}, this.getCityUponState())}> */}
                          <select
                            className="form-control"
                            onChange={(e) => this.onChangeState(e.target.value)}
                          >
                            <option value="">Select State</option>
                            {arr_state && arr_state.length > 0
                              ? arr_state.map((state, index) => {
                                  return (
                                    <option key={index} value={state.state_id}>
                                      {state.name}
                                    </option>
                                  );
                                })
                              : null}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-sm-4">
                        <div className="form-group">
                          <label htmlFor="">
                            City <sup className="top_star">*</sup>
                          </label>
                          {/* <input type="text" className="form-control" placeholder="" onChange={e => this.setState({city : e.target.value})}/> */}
                          <select
                            className="form-control"
                            onChange={(e) =>
                              this.setState({ city: e.target.value })
                            }
                          >
                            <option value="">Select City</option>
                            {arr_city && arr_city.length > 0
                              ? arr_city.map((city, index) => {
                                  return (
                                    <option key={index} value={city.city_id}>
                                      {city.name}
                                    </option>
                                  );
                                })
                              : null}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-sm-6">
                        <div className="form-group">
                          <label htmlFor="">
                            Pin code <sup className="top_star">*</sup>
                          </label>
                          <input
                            type="text"
                            maxLength={6}
                            className="form-control"
                            onChange={(e) => this.onHandleTelephoneChange(e)}
                            placeholder=""
                            inputMode="numeric"
                            id="pincode"
                            value={this.state.pincode}
                          />
                          {/* <input type="text" className="form-control" placeholder="" onChange={e => this.setState({ pincode: e.target.value })} /> */}
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-sm-6">
                        <div className="form-group">
                          <label htmlFor="">
                            Mobile Number<sup className="top_star">*</sup>
                          </label>
                          <input
                            type="text"
                            maxLength={10}
                            className="form-control"
                            onChange={(e) => this.onHandleTelephoneChange(e)}
                            placeholder=""
                            inputMode="numeric"
                            id="phone"
                            value={this.state.phone}
                          />
                          {/* <input type="text" className="form-control" placeholder="" onChange={e => this.setState({ phone: e.target.value })} /> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-sm-6">
                        <div className="form-group">
                          <label htmlFor="">Adhar Card Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            onChange={(e) => this.onHandleTelephoneChange(e)}
                            inputMode="numeric"
                            id="aadhar_no"
                            maxLength={12}
                            value={this.state.aadhar_no}
                          />
                          {/* <input type="text" className="form-control" placeholder="" onChange={e => this.setState({ aadhar_no: e.target.value })} /> */}
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-sm-6">
                        <div className="form-group">
                          <label htmlFor="">GST Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            value={this.state.gst_no.toLocaleUpperCase()}
                            // onChange={e => this.setState({ gst_no: e.target.value })}
                            onChange={(e) => {
                              const newId = e.target.value.replace(
                                /[^a-zA-Z0-9]/g,
                                ""
                              );
                              this.setState({ gst_no: newId });
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="">Upoad Adhar Card</label>
                          <div className="input-group">
                            <input
                              onChange={(e) =>
                                this.setState({ aadhar_image: e.target.value })
                              }
                              type="file"
                              className="top_rmv_g form-control "
                              ref={(refAadharmImageaddress) =>
                                (this.fileAadharImageAddress = refAadharmImageaddress)
                              }
                            />
                            <div
                              onClick={() => {
                                this.setState({
                                  edit_aadhar_image_address: null,
                                });
                                this.fileAadharImageAddress.value = "";
                              }}
                              className="input-group-append"
                            >
                              <span className="input-group-text">
                                <i className="fa fa-times" aria-hidden="true" />
                              </span>
                            </div>
                          </div>
                          <div
                            className={
                              edit_aadhar_image_address === null
                                ? "d-none"
                                : "allImgNo"
                            }
                          >
                            <img src={edit_aadhar_image_address} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="address_type">
                      <label className="radio-inline">
                        <input
                          type="radio"
                          name="optradio"
                          onChange={(e) => this.setState({ is_status: 1 })}
                        />{" "}
                        Home
                      </label>
                      <label className="radio-inline">
                        <input
                          type="radio"
                          name="optradio"
                          onChange={(e) => this.setState({ is_status: 2 })}
                        />{" "}
                        Office
                      </label>
                      <label className="radio-inline">
                        <input
                          type="radio"
                          name="optradio"
                          onChange={(e) => this.setState({ is_status: 3 })}
                        />{" "}
                        Other
                      </label>
                    </div>

                    <div className="both_buttons">
                      <button
                        type="button"
                        className="btn continue_btns"
                        onClick={this.addUserAddress}
                      >
                        Continue
                      </button>
                      <button
                        type="button"
                        onClick={this.onCloseModal}
                        className="btn cancel_btns ml-2"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal>

          {/* <!-- add card start --> */}
          <div className="modal fade" id="add_card" role="dialog">
            <div className="vertical-alignment-helper">
              <div className="SignupModal modal-dialog vertical-align-center">
                {/* <!-- Modal content--> */}
                <div className="modal-content">
                  <div className="SignupModalIn">
                    <div className="SignupModal modal-body clearfix">
                      <div className="card_box">
                        <div className="row">
                          <div className="col-12 col-md-12 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="usr">Card Number</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Card Number"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-9 col-sm-9">
                            <div className="form-group full_label">
                              <label htmlFor="usr">Valid Through</label>
                              <input
                                type="number"
                                className="form-control half_space_left"
                                placeholder="MM"
                              />
                              <input
                                type="number"
                                className="form-control half_space"
                                placeholder="YY"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-3 col-sm-3">
                            <div className="form-group">
                              <label htmlFor="usr">CVV</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="CVV"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-sm-12">
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                />{" "}
                                Save this card for future use{" "}
                                <span className="save_cr">
                                  It's perfectly secure
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-sm-12">
                            <button type="button" className="btn card_btn">
                              Pay now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--  add card end-->

					<!-- edit profile --> */}
          <Modal
            open={this.state.openEdit}
            onClose={() => this._resetEditAddressState()}
            center
          >
            <div className="editaddressModal vertical-align-center">
              <div className="adderess_inside">
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    marginBottom: "8px",
                  }}
                >
                  Edit Delivery Address
                </h4>
                <p style={{ textAlign: "center" }}>
                  Please enter the accurate address, it will help us to serve
                  you better
                </p>
              </div>
              <div className="inside_form_fields">
                {/* <form
                                        // method="POST"
                                        // action="#"
                                        // role="form" 
                                        className="theme-form address-form "> */}
                {/* <form> */}
                <div className="theme-form address-form">
                  <div className="row">
                    <div className="col-12 col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="">
                          ID No.<sup className="top_star">*</sup>
                        </label>
                        <input
                          id="edit_address_id_no"
                          // onChange={(e) => this._onChangeText(e)}
                          // onChange={(e) => this.onChangeID(e)}
                          onChange={(e) => {
                            const newId = e.target.value.replace(
                              /[^a-zA-Z0-9]/g,
                              ""
                            );
                            this.setState({ edit_address_id_no: newId });
                          }}
                          type="text"
                          className="form-control"
                          placeholder={"Enter your ID number"}
                          inputMode="numeric"
                          value={this.state.edit_address_id_no}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="">
                          Name<sup className="top_star">*</sup>
                        </label>
                        <input
                          id="edit_address_name"
                          // onChange={(e) => this._onChangeText(e)}
                          onChange={(e) => {
                            const newName = e.target.value.replace(
                              /[^a-zA-Z\s]/g,
                              ""
                            );
                            this.setState({ edit_address_name: newName });
                          }}
                          type="text"
                          className="form-control"
                          value={this.state.edit_address_name}
                          placeholder={"Enter your name"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="">
                          Address<sup className="top_star">*</sup>
                        </label>
                        <input
                          id="edit_address_address"
                          onChange={(e) => this._onChangeText(e)}
                          type="text"
                          className="form-control"
                          value={this.state.edit_address_address}
                          placeholder={"Enter your address"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-4 col-sm-4">
                      <div className="form-group">
                        <label htmlFor="">
                          Country <sup className="top_star">*</sup>
                        </label>
                        <select
                          value={this.state.selectedCountryId}
                          onChange={(e) =>
                            this._getStateUponCountry(e.target.value)
                          }
                          className="form-control"
                        >
                          <option value={undefined}>Select Country</option>
                          {this.state.arr_country.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-12 col-md-4 col-sm-4">
                      <div className="form-group">
                        <label htmlFor="">
                          State<sup className="top_star">*</sup>
                        </label>
                        <select
                          value={this.state.selectedStateId}
                          onChange={(e) =>
                            this._getCityUponState(e.target.value)
                          }
                          className="form-control"
                        >
                          <option value={undefined}>Select State</option>
                          {this.state.selectedCountryId !== undefined ? (
                            this.state.arr_state.map((item, index) => {
                              return (
                                <option key={index} value={item.state_id}>
                                  {item.name}
                                </option>
                              );
                            })
                          ) : (
                            <option value={undefined}>
                              First Select Country
                            </option>
                          )}
                        </select>
                      </div>
                    </div>

                    <div className="col-12 col-md-4 col-sm-4">
                      <div className="form-group">
                        <label htmlFor="">
                          City<sup className="top_star">*</sup>
                        </label>
                        <select
                          value={this.state.selectedCityId}
                          onChange={(e) =>
                            this.setState({ selectedCityId: e.target.value })
                          }
                          className="form-control"
                        >
                          <option value={undefined}>Select City</option>
                          {this.state.selectedStateId !== undefined ? (
                            this.state.arr_city.map((item, index) => {
                              return (
                                <option key={index} value={item.city_id}>
                                  {item.name}
                                </option>
                              );
                            })
                          ) : (
                            <option value={undefined}>
                              First Select State
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="">
                          Pin code <sup className="top_star">*</sup>
                        </label>
                        <input
                          onChange={(e) => this.onHandleTelephoneChange(e)}
                          type="text"
                          className="form-control"
                          value={this.state.edit_address_pincode}
                          placeholder={"Enter your pincode"}
                          inputMode="numeric"
                          id="edit_address_pincode"
                          maxLength={6}
                        />
                      </div>
                    </div>
                    {/* edit mobilr number */}
                    <div className="col-12 col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="">
                          Mobile Number <sup className="top_star">*</sup>
                        </label>
                        <input
                          onChange={(e) => this.onHandleTelephoneChange(e)}
                          type="text"
                          className="form-control"
                          value={this.state.edit_number}
                          placeholder={"Enter your mobile number"}
                          inputMode="numeric"
                          id="edit_number"
                          maxLength={10}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="">Adhar Card Number</label>
                        <input
                          value={this.state.edit_aadhar_no}
                          type="text"
                          className="form-control"
                          placeholder="Enter your Adhar Card Number"
                          onChange={(e) => this.onHandleTelephoneChange(e)}
                          inputMode="numeric"
                          id="edit_aadhar_no"
                          maxLength={12}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="">GST Number</label>
                        <input
                          value={
                            this.state.edit_gst_no !== null
                              ? this.state.edit_gst_no.toLocaleUpperCase()
                              : this.state.edit_gst_no
                          }
                          type="text"
                          className="form-control"
                          placeholder="Enter your GST Number"
                          // onChange={(e) => this._onChangeText(e)}
                          onChange={(e) => {
                            const newId = e.target.value.replace(
                              /[^a-zA-Z0-9]/g,
                              ""
                            );
                            this.setState({ edit_gst_no: newId });
                          }}
                          id="edit_gst_no"
                          maxLength={15}
                        />
                      </div>
                    </div>
                  </div>

                  {/* edit adharcard number in address */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="gap_top_ot gap_top">
                        <div className="row align-items-center">
                          <div className="col-12">
                            <div className="form-group input-group">
                              <label>Upload Adhar Card</label>
                              <div className="input-group">
                                <input
                                  onChange={(e) =>
                                    this.setState({
                                      edit_aadhar_imagepath_address:
                                        e.target.files[0],
                                    })
                                  }
                                  type="file"
                                  className="top_rmv_g form-control "
                                  ref={(refAadharmImageaddress) =>
                                    (this.fileAadharImageAddress = refAadharmImageaddress)
                                  }
                                />
                                <div
                                  onClick={() => {
                                    this.setState({
                                      edit_aadhar_image_address: null,
                                    });
                                    this.fileAadharImageAddress.value = "";
                                  }}
                                  className="input-group-append"
                                >
                                  <span className="input-group-text">
                                    <i
                                      className="fa fa-times"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            !edit_aadhar_image_address ? "d-none" : "allImgNo"
                          }
                        >
                          <img src={edit_aadhar_image_address} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="address_type">
                    <label className="radio-inline">
                      <label className="radio-inline">
                        <input
                          onChange={() =>
                            this.setState({ edit_address_addressType: 1 })
                          }
                          type="radio"
                          name="optradio"
                          value="1"
                          id="1"
                          checked={this.state.edit_address_addressType == 1}
                        />{" "}
                        Home
                      </label>
                      <label className="radio-inline">
                        <input
                          onChange={() =>
                            this.setState({ edit_address_addressType: 2 })
                          }
                          type="radio"
                          name="optradio"
                          value="2"
                          id="2"
                          checked={this.state.edit_address_addressType == 2}
                        />{" "}
                        Office
                      </label>
                      <label className="radio-inline">
                        <input
                          onChange={() =>
                            this.setState({ edit_address_addressType: 3 })
                          }
                          type="radio"
                          name="optradio"
                          value="3"
                          id="3"
                          checked={this.state.edit_address_addressType == 3}
                        />{" "}
                        Other
                      </label>
                    </label>
                  </div>
                  {/* end edit adhar card number */}
                  <div className="both_buttons">
                    <button
                      onClick={() => this._editAddressApi()}
                      className="btn continue_btns"
                    >
                      Update
                    </button>
                    <button
                      type="submit"
                      className="btn cancel_btns ml-2"
                      onClick={() => this._resetEditAddressState()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                {/* </form> */}
              </div>
            </div>
          </Modal>

          <div
            className={
              this.state._visiblePromoCode ? "modal fade show" : "modal fade"
            }
            style={{ display: this.state._visiblePromoCode ? "" : "none" }}
            id="prodmo_code"
            role="dialog"
          >
            <div className="vertical-alignment-helper">
              <div className="SignupModal modal-dialog vertical-align-center">
                {/* <!-- Modal content--> */}
                <div className="modal-content">
                  <div className="SignupModalIn">
                    <div className="SignupModal modal-body clearfix">
                      <h4>Have a promo code?</h4>
                      <div className="number_box">
                        <div className="coupon_input">
                          <input
                            type="tel"
                            placeholder="Enter promo code here"
                            className="form-control"
                            onChange={(e) =>
                              this.setState({ _promocode: e.target.value })
                            }
                          />
                          <button
                            onClick={() => this.promoCodeApply()}
                            className="next_btn_cop"
                            type="button"
                          >
                            Apply
                          </button>
                        </div>

                        <div className="coupan_condition">
                          <ul>
                            <li>
                              Some promo codes are not applicable on promo cash.
                            </li>
                            <li>
                              Some promo codes are not valid on purchase of
                              Edible oil, ghee & baby food products.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--  promo code end-->

<!-- Add Cart Modal --> */}
          <div
            className="modal fade"
            id="addCartModal"
            role="dialog"
            aria-labelledby="addCartModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body add-cart-box text-center">
                  <p>
                    You've just added this product to the
                    <br />
                    cart:
                  </p>
                  <h4 id="productTitle"></h4>
                  <img
                    src="#"
                    id="productImage"
                    width="100"
                    height="100"
                    alt="adding cart image"
                  />
                  <div className="btn-actions">
                    <a href="#">
                      <button className="btn-primary">Go to cart page</button>
                    </a>
                    <a href="#">
                      <button className="btn-primary" data-dismiss="modal">
                        Continue
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* update user profile */}
          <Modal
            open={this.state._isProfileDone}
            onClose={this.onCloseModalProfile}
            center
          >
            <div className="editaddressModal  vertical-align-center">
              {/* <!-- Modal content--> */}
              <div className="adderess_inside">
                <h4>Edit Profile</h4>
                {/* <p>Please enter the accurate address, it will help us to serve you better</p> */}
              </div>
              <div className="inside_form_fields">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      {/* <p style={{ fontWeight: 'bold' }}>ID Number<sup className="top_star">*</sup></p> */}
                      <div className="form-group">
                        <input
                          id="_id_number"
                          type="text"
                          className="form-control forms_inputs"
                          placeholder={"Enter your ID Number"}
                          value={_id_number}
                          onChange={(e) => this._onChangeTextEditProfile(e)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      {/* <p style={{ fontWeight: 'bold' }}>Name<sup className="top_star">*</sup></p> */}
                      <div className="form-group">
                        <input
                          id="edited_name"
                          type="text"
                          className="form-control forms_inputs"
                          placeholder={"Enter your name"}
                          value={edited_name}
                          onChange={(e) => this._onChangeTextEditProfile(e)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      {/* <p style={{ fontWeight: 'bold' }}>Email<sup className="top_star">*</sup></p> */}
                      <div className="form-group">
                        <input
                          id="edited_email_id"
                          type="text"
                          className="form-control forms_inputs"
                          onChange={(e) => this._onChangeTextEditProfile(e)}
                          placeholder={"Enter your email ID"}
                          value={edited_email_id}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      {/* <p style={{ fontWeight: 'bold' }}>Email<sup className="top_star">*</sup></p> */}
                      <div className="form-group">
                        <input
                          id="edited_name"
                          type="text"
                          className="form-control forms_inputs"
                          // onChange={e => this._onChangeTextEditProfile(e)}
                          placeholder={"Mobile Number"}
                          value={number}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      {/* <p style={{ fontWeight: 'bold' }}>Date of birth<sup className="top_star">*</sup></p> */}
                      <div className="form-group AAAA">
                        <input
                          id="edited_dob"
                          type="date"
                          className="form-control forms_inputs datepicker"
                          placeholder={"Select DOB"}
                          value={edited_dob}
                          onChange={(e) => this._onChangeTextEditProfile(e)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="form-group">
                          {this.state.edited_gender !== undefined ? (
                            <select
                              id="edited_gender"
                              onChange={(e) => this._onChangeTextEditProfile(e)}
                              value={this.state.edited_gender}
                              className="form-control slect_pst"
                            >
                              <option key={0} value={null}>
                                Gender
                              </option>
                              <option key={1} value={1}>
                                Male
                              </option>
                              <option key={2} value={2}>
                                Female
                              </option>
                              <option key={3} value={3}>
                                Other
                              </option>
                            </select>
                          ) : (
                            <select
                              id="edited_gender"
                              onChange={(e) => this._onChangeTextEditProfile(e)}
                              value={
                                this.state.edited_gender == 1
                                  ? "MALE"
                                  : this.state.edited_gender == 2
                                  ? "FEMALE"
                                  : gender && gender == 3
                                  ? "OTHER"
                                  : ""
                              }
                              className="form-control slect_pst"
                            >
                              <option key={0} value={null}>
                                Gender
                              </option>
                              <option key={1} value={1}>
                                Male
                              </option>
                              <option key={2} value={2}>
                                Female
                              </option>
                              <option key={3} value={3}>
                                Other
                              </option>
                            </select>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-footer text-center mt-5">
                    <div className="form-footer-center edit_profile_f">
                      <button
                        type="button"
                        onClick={() => this._updateUserProfile()}
                        className="btn btn-primary profile_btn"
                        style={{ color: "#fff" }}
                      >
                        Update
                      </button>
                      {/* <button type="button" className="btn btn-primary profile_btn ml-2" style={{ color: '#fff' }} onClick={() => this.setState({ activeView: ListOfViews.MyProfile })}>Back</button> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Modal>
        </section>

        {this.paymentModalView()}
      </div>
    );
  }

  paymentModalView = () => {
    return (
      <div>
        {/* <button onClick={() => this.toggleModal()}>Open Modal</button> */}
        <Modal
          isOpen={this.state.paymentModalVisibility}
          // onAfterOpen={afterOpenModal}
          onRequestClose={() => this.toggleModal()}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <button
            type="button"
            onClick={() => this.toggleModal()}
            className="close"
          >
            ×
          </button>
          <div style={{ paddingBottom: "10px" }}>
            <h2>Payment Mode</h2>
          </div>

          <div>
            <button
              onClick={() => {
                this.toggleModal();
                this.StripeClick();
              }}
              style={{
                width: "100%",
                backgroundColor: "#64a23f",
                color: "#fff",
              }}
            >
              COD
            </button>
          </div>

          <div style={{ height: "25px" }} />

          <div>
            <button
              onClick={() => {
                this.toggleModal();
                this.StripeClick();
              }}
              style={{
                width: "100%",
                backgroundColor: "#64a23f",
                color: "#fff",
              }}
            >
              Prepaid Payment
            </button>
          </div>
        </Modal>
      </div>
    );
  };
}

async function displayPaytm(response, deviceID, props) {
  var res = await loadScript();

  if (!res) {
    alert(" SDK failed to load. Are you online?");
    return;
  }
  var config = {
    root: "",
    flow: "DEFAULT",
    data: {
      orderId: response.order_id + "" /* update order id */,
      token: response.body.txnToken /* update token value */,
      tokenType: "TXN_TOKEN",
      amount: response.amount + "" /* update amount */,
      callbackUrl: `${MAIN_URL}order_confirm` /*  */,
    },
    handler: {
      notifyMerchant: async function(eventName, data) {
        if (eventName == "APP_CLOSED") {
          var formData = new FormData();
          formData.append("device_id", deviceID);
          formData.append("order_id", response.order_id);
          var res = await NaswizHelperApi.POST_WITHOUT_TOKEN(
            "v1/check-payment",
            formData,
            "Error => v1/check-referral"
          );

          if (res.success == 1) {
            Notification("success", "Success!", res.message);
          } else {
            Notification("error", "Error!", "" + res.message);
            window.location.href = "/order_cancel";
            // this.props.history.push({
            // 	pathname: '/order_cancel',
            // })
            // return(
            // 	<Redirect to={{ pathname: `/order_cancel`}} />
            // )
          }
        }
      },
    },
  };

  if (window.Paytm && window.Paytm.CheckoutJS) {
    window.Paytm.CheckoutJS.onLoad(async function excecuteAfterCompleteLoad() {
      window.Paytm.CheckoutJS.init(config)
        .then(async function onSuccess() {
          window.Paytm.CheckoutJS.invoke();
        })
        .catch(function onError(error) {
          console.log(error);
        });
    });
  }
}

function loadScript() {
  return new Promise((res) => {
    var myScript = document.createElement("script");
    myScript.src =
      "https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/Naswiz81262155606177.js";
    document.body.appendChild(myScript);
    myScript.onload = () => {
      res(true);
    };
    myScript.onerror = () => {
      res(false);
    };
    document.body.appendChild(myScript);
  });
}

const mapStateToProps = (state) => ({
  cartItems: state.cartList.cart,
  symbol: "₹ ",
  total: getCartTotal(state.cartList.cart),
  isUserLogin: state.login.isUserLogin,
  cartListArray: state.login.userCartData.records.cart_list,
  cartTotalArray: state.login.userCartData.records.total,
  userData: state.login.userData,
});

export default connect(mapStateToProps, {
  removeFromWishlist,
  getCartData,
  addUserData,
  changeLoginState,
  manageUserIdState,
})(checkOut);

// MODAL CODE
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

/////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////functional component ////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////

// import React, { useState, useEffect, useRef } from 'react';
// import { Helmet } from 'react-helmet'
// import { connect } from 'react-redux'
// import { Link, Route, BrowserRouter, Redirect } from 'react-router-dom'
// import SimpleReactValidator from 'simple-react-validator';
// import { Helper } from '../../helper'
// import Breadcrumb from "../common/breadcrumb";
// import { removeFromWishlist, getCartData, addUserData, changeLoginState, manageUserIdState, } from '../../actions'
// import { getCartTotal } from "../../services";
// import { base_url } from '../../helper';
// import Modal from 'react-responsive-modal';
// import 'react-phone-number-input/style.css'
// import "react-tabs/style/react-tabs.scss";
// import Moment from 'moment';
// import { NaswizHelperApi, BASE_URL } from '../../NaswizHelper';
// import Notification from '../../Notification'
// import OtpInput from 'react-otp-input';
// import PhoneInput from 'react-phone-number-input'

// import { AtomPaynetz } from './atomcheckout'

// const BackToHome = () => {
// 	return (
// 		<a href="/"></a>
// 	)
// }

// const checkOut = (props) => {
// 	// this.state = {
// 	// 	payment: 'stripe',first_name: '',last_name: '',phone: '',email: '',country: 'India ',address: '',city: '',state: '',pincode: '',create_account: '',addressID: '',full_name: '',loading_state: false,arr_state: [],loading_city: false,arr_city: [],user_address_id: undefined,phoneNumber: '',customer_id: '',loading_addAddress: false,loading_placeOrder: false,paymentModalVisibility: false,is_status: 0,selected_address_ID: '',selectedAddressFull: '',selectedAddressFull2: '',gst_no: '',aadhar_no: '',aadhar_image: '',all_address_data: '',added_address: false,_currentDate: '',_lastDate: '',_deliveryDates: [],_delivery_list: false,_delivery_date: false,_payment: false,_deliverycheckdone: false,_deliverydatecheckdone: false,_paymentcheckdone: false,_verificationsec: false,_id_number: '',edited_name: '',edited_email_id: '',edited_dob: '',edited_gender: undefined,
// 	// 	//divya  otpViewOpen: false,  otp_1: '',otp_2: '',otp_3: '',otp_4: '',otp: '',
// 	// 	// selectedOption: '',selectedOption: '',goToSuccess: false,_isAddressVisible: false,_referalCode: '',_is_used: '',uniueId: '',isLoing: '',isLoadingPay: false,arr_walletcustomer: '',is_wallet: '',wallet_amount: '',isChaked: false,_deliverycheckdoneselect: false,_timeSlotSelect: false,_selectedTimeSlot: '',_promocode: '',disablebutton: false,_counter: 60,_counternew: 10,_showbutton: false,regexp: /^[0-9\b]+$/,_isPromocodeValide: false,_visiblePromoCode: false,goToDashboard: false,
// 	// 	// Edit profile states _id_number: '',edited_name: '',edited_email_id: '',edited_dob: '',edited_gender: undefined,edited_marital_status: '',unmarried: '',married: '',edited_anniversary_date: '',edited_profile_image: null,edited_aadhar_card_image: null,edited_pan_card_image: null,openEdit: false,
// 	// 	// Edit address states  == edit_address_object: {},isEditAddressObject: false,edit_address_name: '',edit_address_address: '',edit_address_pincode: '',edit_address_id_no: '',edit_gst_no: '', // edit for addressedit_aadhar_no: '', // edit for addressedit_number: '',edit_aadhar_image_address: '',edit_aadhar_imagepath_address: '',edit_address_addressType: undefined,selectedCountryId: undefined,selectedStateId: undefined,selectedCityId: undefined,arr_country: [],arr_city: [],arr_state: [],open: false,_checkRegisteModal: false,_isProfileDone: false,_is_whatsapp: '',
// 	// 	// 10aug == _otherpaymenttype: '', //checque,neft,cash trans _choosepamentone: 0,_othertrans: '',_otheramount: '',_otherdate: '',_loading_placeOrder: false,
// 	// }
// 	const [Id, setId] = useState([])
// 	const [obj, setobj] = useState({})
// 	const [_orderID, set_orderID] = useState('')
// 	const [payment, setpayment] = useState('stripe')
// 	const [first_name, setfirst_name] = useState('')
// 	const [last_name, setlast_name] = useState('')
// 	const [phone, setphone] = useState('')
// 	const [email, setemail] = useState('')
// 	const [country, setcountry] = useState('India')
// 	const [address, setaddress] = useState('')
// 	const [city, setCity] = useState('')
// 	const [pincode, setPincode] = useState('')
// 	const [create_account, setcreate_account] = useState('')
// 	const [addressID, setAddressID] = useState('')
// 	const [full_name, setFull_name] = useState('')
// 	const [loading_state, setLoading_state] = useState(false)
// 	const [arr_state, setarr_state] = useState('')
// 	const [loading_city, setLoading_city] = useState(false)
// 	const [arr_city, setarr_city] = useState([])
// 	const [user_address_id, setUser_address_id] = useState(undefined)
// 	const [phoneNumber, setPhoneNumber] = useState('')
// 	const [customer_id, setCustomer_id] = useState('')
// 	const [loading_addAddress, setLoading_addAddress] = useState(false)
// 	const [loading_placehOrder, setLoading_placehOrder] = useState(false)
// 	const [paymentModalVisibility, setpaymentModalVisibility] = useState('second')
// 	const [id_status, setid_status] = useState(0)
// 	const [selected_address_ID, setselected_address_ID] = useState('')
// 	const [selectedAddressFull, setselectedAddressFull] = useState('')
// 	const [selectedAddressFull2, setSelectedAddressFull2] = useState('')
// 	const [gst_no, setGst_no] = useState('')
// 	const [aadhar_no, setAadhar_no] = useState('')
// 	const [aadhar_image, setaadhar_image] = useState('')
// 	const [all_address_data, setall_address_data] = useState('')
// 	const [is_status, setis_status] = useState('second')
// 	const [added_address, setAdded_address] = useState(false)
// 	const [_currentDate, set_currentDate] = useState('')
// 	const [_lastDate, set_lastDate] = useState('')
// 	const [_deliveryDates, set_deliveryDates] = useState([])
// 	const [_delivery_list, set_delivery_list] = useState(false)
// 	const [_delivery_date, set_delivery_date] = useState(false)
// 	const [_payment, set_payment] = useState(false)
// 	const [_deliverycheckdone, set_deliverycheckdone] = useState(false)
// 	const [_deliverydatecheckdone, set_deliverydatecheckdone] = useState(false)
// 	const [_paymentcheckdone, set_paymentcheckdone] = useState(false)
// 	const [_verificationsec, set_verificationsec] = useState(false)
// 	const [_id_number, set_id_number] = useState('')
// 	const [edited_name, setEdited_name] = useState('')
// 	const [edited_email_id, setEdited_email_id] = useState('')
// 	const [edited_dob, setEdited_dob] = useState('')
// 	const [edited_gender, setEdited_gender] = useState(undefined)
// 	const [otpViewOpen, setOtpViewOpen] = useState(false)
// 	const [otp_1, setOtp_1] = useState('')
// 	const [otp_2, setOtp_2] = useState('')
// 	const [otp_3, setOtp_3] = useState('')
// 	const [otp_4, setOtp_4] = useState('')
// 	const [otp, setOtp] = useState('')
// 	const [selectedOption, setSelectedOption] = useState('')
// 	const [goToSuccess, setGoToSuccess] = useState(false)
// 	const [_isAddressVisible, set_isAddressVisible] = useState(false)
// 	const [_referalCode, set_referalCode] = useState('')
// 	const [_is_used, set_is_used] = useState('')
// 	const [uniueId, setUniueId] = useState('')
// 	const [isLoing, setisLoing] = useState('second')
// 	const [isLoadingPay, setIsLoadingPay] = useState(false)
// 	const [arr_walletcustomer, setArr_walletcustomer] = useState('')
// 	const [is_wallet, setIs_wallet] = useState('')
// 	const [wallet_amount, setWallet_amount] = useState('')
// 	const [isChaked, setIsChaked] = useState(false)
// 	const [loading_walletcustomer, setloading_walletcustomer] = useState(false)
// 	const [_deliverycheckdoneselect, set_deliverycheckdoneselect] = useState(false)
// 	const [_timeSloatSelect, set_timeSloatSelect] = useState(false)
// 	const [_promocode, set_promocode] = useState('')
// 	const [disablebutton, setDisablebutton] = useState(false)
// 	const [_counter, set_counter] = useState(60)
// 	const [_counternew, set_counternew] = useState(10)
// 	const [_showbutton, set_showbutton] = useState(false)
// 	const [regexp, setregexp] = useState(/^[0-9\b]+$/)
// 	const [_isPromocodeValide, set_isPromocodeValide] = useState(false)
// 	const [_visiblePromocode, set_visiblePromocode] = useState(false)
// 	const [goToDashboard, setGoToDashboard] = useState(false)
// 	const [edited_marital_status, setEdited_marital_status] = useState('')
// 	const [unmarried, setUnmarried] = useState('')
// 	const [married, setMarried] = useState('')
// 	const [edited_anniversary_date, setEdited_anniversary_date] = useState('')
// 	const [edited_profile_image, setEdited_profile_image] = useState(null)
// 	const [edited_aadhar_card_image, setEdited_aadhar_card_image] = useState(null)
// 	const [edited_pan_card_image, setEdited_pan_card_image] = useState(null)
// 	const [openEdit, setOpenEdit] = useState(false)
// 	const [edit_address_object, setEdit_address_object] = useState({})
// 	const [isEditAddressObject, setIsEditAddressObject] = useState(false)
// 	const [edit_address_name, setEdit_address_name] = useState('')
// 	const [edit_address_address, setEdit_address_address] = useState('')
// 	const [edit_address_pincode, setEdit_address_pincode] = useState('')
// 	const [edit_address_id_no, setEdit_address_id_no] = useState('')
// 	const [edit_gst_no, setEdit_gst_no] = useState('')
// 	const [edit_aadhar_no, setEdit_aadhar_no] = useState('')
// 	const [loading_wallet, setloading_wallet] = useState(false)
// 	const [edit_number, setedit_number] = useState('')
// 	const [_current_Url, set_current_Url] = useState('')
// 	const [edit_aadhar_image_address, setEdit_aadhar_image_address] = useState('')
// 	const [edit_aadhar_imagepath_address, setEdit_aadhar_imagepath_address] = useState('')
// 	const [edit_address_addressType, setEdit_address_addressType] = useState(undefined)
// 	const [selectedstateId, setSelectedstateId] = useState(undefined)
// 	const [selectedCountryId, setSelectedCountryId] = useState(undefined)
// 	const [selectedCityId, setSelectedCityId] = useState(undefined)
// 	const [arr_country, setArr_country] = useState([])
// 	const [Arr_city, setArr_city] = useState([])
// 	const [Arr_state, setArr_state] = useState([])
// 	const [open, setOpen] = useState(false)
// 	const [_checkRegisteModal, set_checkRegisteModal] = useState(false)
// 	const [_isProfileDone, set_isProfileDone] = useState(false)
// 	const [_is_whatsapp, set_is_whatsapp] = useState('')
// 	const [_otherpaymenttype, set_otherpaymenttype] = useState('')
// 	const [_choosepamentone, set_choosepamentone] = useState(0)
// 	const [_othertrans, set_othertrans] = useState('')
// 	const [_otheramount, set_otheramount] = useState('')
// 	const [_otherdate, set_otherdate] = useState('')
// 	const [state, setState] = useState('')
// 	const [_loading_placeOrder, set_loading_placeOrder] = useState(false)

// 	const fileAadharImageAddress = useRef(null)
// 	var validator = new SimpleReactValidator();
// 	// this.razorPay = this.razorPay.bind(this);
// 	// this.toggleModal = toggleModal.bind(this)
// 	// toggleModal.bind(toggleModal)

// 	const onOpenModal = () => {
// 		setOpen(true)
// 	};

// 	const onCloseModal = () => {
// 		setOpen(false)
// 	};

// 	const onCloseModalProfile = () => {
// 		set_isProfileDone(false)
// 	};

// 	const onHandleTelephoneChange = e => {
// 		let telephone = e.target.value;
// 		if (telephone === '' || regexp.test(telephone)) {
// 			// this.setState({ [e.target.id]: telephone })
// 			let id1 = [e.target.id]
// 			var id2= {[id1]:telephone}
// 			// setId([e.target.id]);
// 			// telephone = Id
// 			setId(id2);

// 		}
// 		else {
// 			return;
// 		}
// 	};

// 	useEffect(() => {
// 		var _whatsapp = localStorage.getItem('is_whatsapp')
// 		set_is_whatsapp(_whatsapp)
// 		getUserAddress()
// 		firstTimeGettingStateList()
// 		_getMyWalletcustomers()
// 		_getCountry()

// 		let rajdatea = new Date();
// 		set_lastDate(rajdatea.getDate() + 12)
// 		set_currentDate(rajdatea.getDate())

// 		var isLoing = localStorage.getItem('is_user_login')
// 		var uniueId = localStorage.getItem('deviceId')
// 		setisLoing(isLoing)
// 		setUniueId(uniueId)
// 		set_current_Url(window.location.href)

// 		let today = Moment(new Date()).format('YYYY-MM-DD');
// 		if (props.cartListArray.length === 0) {
// 			goToDashboard(true)
// 			return
// 		}
// 		// tomorrow = Moment(tomorrow).add(5, 'day').format('YYYY-MM-DD');
// 		for (let i = 0; i < 12; i++) {
// 			let tomorrow = new Date();
// 			let tomorrowm = new Date();

// 			tomorrow = Moment(tomorrow).add(i, 'day').format('ddd');
// 			tomorrowm = Moment(tomorrowm).add(i, 'day').format('D MMM');

// 			var obej = { 'dayname': tomorrow, 'months': tomorrowm }

// 			set_deliveryDates(obej)

// 		}

// 		if (!props.isUserLogin) {
// 			Notification('error', 'Error!', 'Please Login First!')
// 			set_verificationsec(true)
// 		}
// 		else {
// 			set_deliverycheckdone(true)
// 			customerDetails()
// 		}
// 		setTimeout(function () {
// 			document.querySelector(".loader-wrapper").style = "display: none";
// 		}, 2000);

// 	}, [''])
// 	const _updateUserProfile = async () => {

// 		var isValid = true;
// 		// var { _id_number, edited_name, edited_email_id, edited_dob, edited_anniversary_date, edited_gender, edited_marital_status, edited_profile_image, edited_aadhar_card_image, edited_pan_card_image } = this.state
// 		// { _id_number, edited_name, edited_email_id, edited_dob, edited_anniversary_date, edited_gender, edited_marital_status, edited_profile_image, edited_aadhar_card_image, edited_pan_card_image }

// 		var formData = new FormData()
// 		if (_id_number) {
// 			formData.append('id_number', _id_number)

// 		} else {
// 			isValid = false;

// 			Notification('error', 'Error!', 'Please enter Id Number')

// 		}
// 		if (edited_name) {
// 			if (edited_name.length < 3) {
// 				isValid = false;
// 				Notification('error', 'Error!', 'Please enter valid name')

// 			}
// 			else {
// 				formData.append('name', edited_name)
// 			}

// 		} else {
// 			isValid = false;

// 			Notification('error', 'Error!', 'Please enter name')

// 		}

// 		if (edited_email_id) {

// 			const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// 			if (re.test(edited_email_id) === false) {
// 				isValid = false;

// 				Notification('error', 'Error!', 'Please enter valid email id')

// 			}
// 			else {
// 				formData.append('email', edited_email_id)
// 			}

// 		} else {
// 			isValid = false;

// 			Notification('error', 'Error!', 'Please enter email id')

// 		}
// 		if (edited_dob) {

// 			if (edited_dob.length !== 10) {
// 				isValid = false;
// 				Notification('error', 'Error!', 'Please select valid date of birth')

// 			}
// 			else {
// 				formData.append('dob', edited_dob)
// 			}
// 		} else {
// 			isValid = false;

// 			Notification('error', 'Error!', 'Please select date of birth')

// 		}
// 		if (edited_marital_status === '1') {
// 			if (edited_anniversary_date) {
// 				if (edited_anniversary_date.length !== 10) {
// 					isValid = false;
// 					Notification('error', 'Error!', 'Please select valid anniversary')

// 				}
// 				formData.append('dob_anniversary', edited_anniversary_date)
// 			} else {
// 				isValid = false;

// 				Notification('error', 'Error!', 'Please select anniversary')

// 			}
// 		}

// 		if (edited_gender) {

// 			if (edited_gender === 'Gender') {
// 				isValid = false;

// 				Notification('error', 'Error!', 'Please select gender')

// 			}
// 			else {
// 				formData.append('gender', edited_gender)
// 			}

// 		} else {
// 			isValid = false;

// 			Notification('error', 'Error!', 'Please select gender')

// 		}
// 		var _lenght = 0
// 		for (var i of formData.entries()) {
// 			_lenght++
// 		}

// 		// setTimeout(async () => {
// 		if (_lenght > 0) {

// 			if (isValid) {
// 				var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/update-profile', formData, 'Error => Update profile api')

// 				if (res && res.success === 1) {
// 					// this._customerDetails().then(() => {

// 					// })
// 					_customerDetails()
// 					set_isProfileDone(false)
// 				}
// 				else {

// 				}

// 			} else {
// 			}

// 		}
// 		else {
// 		}
// 		// }, 1000)
// 	}

// 	const _customerDetails = async () => {

// 		var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/customers-detail', null, "Error => Customer details api")
// 		if (res && res.success === 1) {
// 			await props.addUserData(res.record)
// 		}

// 	}

// 	const _onChangeText = (e) => {
// 		let id = { [e.target.id]: e.target.value }
// 		setId(id);
// 	}

// 	const onChangeID = (evt) => {
// 		const newId = evt.target.value.replace(
// 			/[^a-zA-Z0-9]/g,
// 			""
// 		);
// 		setAddressID(newId)
// 	}

// 	const _editAddressApi = async () => {
// 		// var { edit_address_object, edit_address_id_no, edit_address_name, edit_address_address, selectedCountryId, selectedStateId, selectedCityId, edit_address_pincode, edit_address_addressType, edit_number, edit_aadhar_no, edit_gst_no, edit_aadhar_imagepath_address } = this.state
// 		var isValid = true;

// 		var body = new FormData()
// 		body.append('id', setEdit_address_object.id)
// 		if (edit_address_id_no != undefined) {
// 			body.append('address_id_no', setEdit_address_object)
// 		}
// 		else {
// 			Notification('error', 'Error!', 'Please enter address ID number')
// 			isValid = false
// 		}
// 		if (edit_address_name.length > 2) {
// 			body.append('fullname', setEdit_address_name)
// 		}
// 		else {
// 			Notification('error', 'Error!', 'Please enter valid name')
// 			isValid = false
// 		}
// 		if (edit_address_address.length > 9) {
// 			body.append('address', setEdit_address_address)
// 		}
// 		else {
// 			Notification('error', 'Error!', 'Please enter valid address')
// 			isValid = false
// 		}
// 		if (selectedCountryId != undefined) {
// 			body.append('country_id', setSelectedCountryId)
// 		}
// 		else {
// 			Notification('error', 'Error!', 'Please select country')
// 			isValid = false
// 		}
// 		if (selectedstateId != undefined) {
// 			body.append('state_id', setSelectedstateId)
// 		}
// 		else {
// 			Notification('error', 'Error!', 'Please select state')
// 			isValid = false
// 		}
// 		if (selectedCityId != undefined) {
// 			body.append('city_id', setSelectedCityId)
// 		}
// 		else {
// 			Notification('error', 'Error!', 'Please select city')
// 			isValid = false
// 		}
// 		if (edit_address_pincode != '') {
// 			body.append('pincode', setEdit_address_pincode)
// 		}
// 		else {
// 			Notification('error', 'Error!', 'Please enter valid pincode')
// 			isValid = false
// 		}
// 		if (edit_number != '') {
// 			body.append('number', setedit_number)
// 		}
// 		else {
// 			Notification('error', 'Error!', 'Please enter valid mobile number')
// 			isValid = false
// 		}
// 		body.append('aadhar_no', setEdit_aadhar_no)
// 		body.append('gst_no', setEdit_gst_no)

// 		// if (edit_aadhar_no != '') {
// 		// }
// 		// else {
// 		// 	Notification('error', 'Error!', 'Please enter valid adhar card number')
// 		// 	isValid = false
// 		// }
// 		// if (edit_gst_no != '') {
// 		// }
// 		// else {
// 		// 	Notification('error', 'Error!', 'Please enter valid GST number')
// 		// 	isValid = false
// 		// }

// 		if (edit_aadhar_imagepath_address != '') {
// 			body.append('aadhar_image', setEdit_aadhar_imagepath_address, setEdit_aadhar_imagepath_address.name)
// 		}
// 		if (edit_address_addressType) {
// 			body.append('is_status', setEdit_address_addressType)
// 		} else {
// 			isValid = false
// 		}

// 		var _lenght = 0
// 		for await (var i of body.entries()) {
// 			await _lenght++
// 			// console.log(i)
// 		}

// 		// setTimeout(async () => {
// 		if (_lenght > 1 && isValid) {
// 			var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/edit-address', body, "Error => Edit user address api.")

// 			// var userToken = await 'Bearer '.concat(localStorage.getItem('token'))
// 			// var res = await fetch(BASE_URL.concat('v1/edit-address'), {
// 			// 	method: 'POST', headers: {
// 			// 		"Accept": 'application/x.naswiz.v1+json',
// 			// 		"Authorization": userToken,
// 			// 	}, body: body
// 			// }).then(res => res.json())
// 			// 	.then(resData => resData)
// 			// 	.catch(e => {
// 			// 		"err : " ? console.log("err : ", e) : console.log("Error in post api", e)
// 			// 	})

// 			// console.log("-----edit : ", res)
// 			if (res && res.success === 1) {
// 				_resetEditAddressState()
// 				getUserAddress()
// 			}
// 			else {
// 				alert(res && res.message ? res.message : 'Try again later')
// 				_resetEditAddressState()
// 			}

// 			// var myHeaders = new Headers();
// 			// myHeaders.append("Accept", "application/x.naswiz.v1+json");
// 			// myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvbmV3ZGVtby5teXNob3B3aXouY29tXC9tYXN0ZXItbmFzd2l6XC9hcGlcL3YxXC92ZXJpZnktb3RwIiwiaWF0IjoxNjMxODUzMDUzLCJuYmYiOjE2MzE4NTMwNTMsImp0aSI6IjlXYzJPYkRKQ1BSRFVVRWgiLCJzdWIiOjY1LCJwcnYiOiI4YjQyMmU2ZjY1NzkzMmI4YWViY2IxYmYxZTM1NmRkNzZhMzY1YmYyIn0.yCE813HkTZ3kkmWWhpPl9p5Q5XHy9qBQourZJMlm-ug");
// 			// myHeaders.append("Access-Control-Allow-Origin", "*")

// 			// var formdata = new FormData();
// 			// formdata.append("id", "101");
// 			// formdata.append("fullname", "test abc");
// 			// formdata.append("number", "9998613834");
// 			// formdata.append("pincode", "360001");
// 			// formdata.append("address", "test rajkot");
// 			// formdata.append("city_id", "904");
// 			// formdata.append("state_id", "12");
// 			// formdata.append("country_id", "101");
// 			// formdata.append("is_status", "1");
// 			// formdata.append("gst_no", "ADGFVB6756757");
// 			// formdata.append("aadhar_no", "null");
// 			// formdata.append("address_id_no", "abcdef45767");

// 			// var requestOptions = {
// 			// 	method: 'POST',
// 			// 	headers: myHeaders,
// 			// 	body: formdata,
// 			// 	redirect: 'follow'
// 			// };

// 			// var res = await fetch("https://newdemo.myshopwiz.com/master-naswiz/api/v1/edit-address", requestOptions)
// 			// 	.then(response => response.text())
// 			// 	.then(result => console.log(result))
// 			// 	.catch(error => console.log('error', error));
// 			// if (res && res.success == 1) {
// 			// 	this._resetEditAddressState()
// 			// 	this.getUserAddress()
// 			// }
// 			// else {
// 			// 	alert(res && res.message ? res.message : 'Try again later')
// 			// 	// this._resetEditAddressState()
// 			// }
// 		}
// 		// }, 1000)

// 	}

// 	const _resetEditAddressState = () => {

// 		setOpenEdit(false)
// 		setEdit_address_object({})
// 		setIsEditAddressObject(false)
// 		setEdit_address_name('')
// 		setEdit_address_address('')
// 		setEdit_address_pincode('')
// 		setedit_number('')
// 		setEdit_aadhar_no('')
// 		setEdit_gst_no('')
// 		setEdit_address_id_no('')
// 		setEdit_address_addressType(undefined)
// 		setSelectedCountryId(undefined)
// 		setSelectedstateId(undefined)
// 		setSelectedCityId(undefined)

// 	}

// 	const _getMyWalletcustomers = async () => {
// 		var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/customers-detail', "Error => v1/customers-detail api.")

// 		if (res && res.success === 1) {

// 			setArr_walletcustomer(res.record.wallet)
// 			setloading_wallet(false)

// 			if (res.record.wallet === 0) {
// 				setIsChaked(false)
// 				setSelectedOption('')
// 			}
// 		}
// 		else {

// 			setloading_walletcustomer(false)

// 		}
// 	}

// 	const toggleModal = (visibility) => {

// 		if (visibility === true) {
// 			setpaymentModalVisibility(visibility)
// 		}
// 		else if (visibility === false) {
// 			setpaymentModalVisibility(visibility)
// 		}
// 		else {
// 			setpaymentModalVisibility(!paymentModalVisibility)
// 		}

// 	}

// 	const getCartList = async (addressId) => {
// 		var formdata = new FormData()
// 		formdata.append('device_id', uniueId)
// 		formdata.append('address_id', addressId)
// 		getCartData('v1/get-cart-list', formdata, "Error => GetCartList api.")

// 	}

// 	const setStateFromInput = (event, msg) => {
// 		var obj = {};
// 		obj[event.target.name] = event.target.value;
// 		setobj(obj);

// 		if (msg === 'call_city_method') {
// 			getCityUponState()
// 		}
// 	}

// 	const setStateFromCheckbox = (event) => {
// 		var obj = {};
// 		obj[event.target.name] = event.target.checked;
// 		setobj(obj);

// 		if (!validator.fieldValid(event.target.name)) {
// 			validator.showMessages();
// 		}
// 	}

// 	const checkhandle = (value) => {
// 		setpayment(value)
// 	}

// 	const StripeClickAtom = (response) => {
// 		console.log('response ... ', response.response)
// 		set_loading_placeOrder(true)
// 		let formData = new FormData();
// 		formData.append('amt', response.response.amount);
// 		formData.append('name', response.response.order_id + '');
// 		formData.append('emailId', response.response.email_id);
// 		formData.append('connumber', response.response.connumber);
// 		formData.append('address', response.response.address);

// 		for (var pair of formData.entries()) {
// 			console.log('pair atomm...', pair);

// 		}
// 		fetch("https://newdemo.myshopwiz.com/master-naswiz/payment/request", {
// 			method: "post",
// 			body: formData,
// 		})
// 			.then(response => response.json())
// 			.then(json => {
// 				console.log('parsed json', json);
// 				window.location.href = json;
// 			})
// 	};

// 	const openPay_org = (atomId) => {
// 		const options = {
// 			"atomTokenId": atomId,
// 			// "merchId": "8952",
// 			"merchId": "330019",
// 			"custEmail": props.userData.email,
// 			"custMobile": props.userData.number,
// 			//"returnUrl":"http://localhost/Flash%20Checkout%20Project/Archive/response.php"
// 			//"returnUrl": "https://testing-atom.000webhostapp.com/uat/response.php"
// 			//"returnUrl": "https://newdemo.myshopwiz.com/master-naswiz/naswiz/order/response/atom"
// 			"returnUrl": "https://newdemo.myshopwiz.com/order_confirm"
// 		}
// 		let atom = AtomPaynetz(options, 'uat');
// 	}

// 	// StripeClickAtom = () => {
// 	// 	this.setState ({_loading_placeOrder: true});
// 	// 	let formData = new FormData();
// 	// 	formData.append('amt', '100.00');
// 	// 	formData.append('name', 'Atom Dev');
// 	// 	formData.append('emailId', 'atomdev@gmail.com');
// 	// 	formData.append('connumber', '8888888888');
// 	// 	formData.append('address', 'Mumbai');

// 	// 	fetch("https://newdemo.myshopwiz.com/master-naswiz/payment/request", {
// 	// 		method: "post",
// 	// 		body: formData,
// 	// 	})
// 	// 		.then(response => response.json())
// 	// 		.then(json => {
// 	// 			console.log('parsed json', json);
// 	// 			window.location.href = json;
// 	// 		})
// 	// };
// 	// cod, payttm, other payment

// 	const StripeClick = async () => {
// 		// var { first_name, last_name, phone, email, country, address, city, state, pincode, create_account } = this.state
// 		var isValid = true
// 		if (_otherpaymenttype === '' && _choosepamentone == 1) {
// 			Notification('error', 'Error!', 'Please select one payment!')
// 			isValid = false
// 		} if (_otheramount === '' && _choosepamentone == 1) {
// 			Notification('error', 'Error!', 'Please enter amount')
// 			isValid = false
// 		} if (_otherdate === '' && _choosepamentone == 1) {
// 			Notification('error', 'Error!', 'Please select date')
// 			isValid = false
// 		} if (_othertrans === '' && _choosepamentone == 1) {
// 			Notification('error', 'Error!', 'Please enter transaction detail')
// 			isValid = false
// 		}
// 		else if (selectedOption === '' && _choosepamentone == 0) {
// 			Notification('error', 'Error!', 'Please select payment!')
// 			isValid = false
// 		}
// 		if (validator.allValid() && isValid) {
// 			if (selected_address_ID !== undefined) {
// 				if (props.isUserLogin && props.userData && props.userData.email !== null) {
// 					if (props.cartListArray.length > 0) {
// 						var cartArray = props.cartItems

// 						var formData = new FormData()
// 						formData.append('device_id', uniueId)
// 						if (_choosepamentone === 1) {

// 							formData.append('payment_type', 'others')
// 							formData.append('others_type', _otherpaymenttype)
// 							if (_otheramount === '') {
// 								Notification('error', 'Error!', 'Please enter amount!')
// 								return
// 							}
// 							if (_otherdate === '') {
// 								Notification('error', 'Error!', 'Please enter date!')
// 								return

// 							} if (_othertrans === '') {
// 								Notification('error', 'Error!', 'Please enter transaction detail!')
// 								return
// 							}
// 							formData.append('others_amount', _otheramount)
// 							formData.append('others_date', _otherdate)
// 							formData.append('others_trans', _othertrans)

// 						} else {
// 							if (arr_walletcustomer < props.cartTotalArray.price && isChaked && selectedOption == 'Wallet') {
// 								Notification('error', 'Error!', 'Choose other option to pay')
// 								return;
// 							}
// 							if (selectedOption === "Paytm") {
// 								console.log('payment type  online')
// 								formData.append('payment_type', 'online')

// 							} else if (selectedOption === "atom") {
// 								console.log('payment type  atom')
// 								formData.append('payment_type', 'atom')

// 							} else {
// 								formData.append('payment_type', 'cod')

// 							}
// 							if (isChaked)
// 								formData.append('is_wallet', 1)
// 							if (isChaked) {
// 								if (arr_walletcustomer < props.cartTotalArray.price) {
// 									formData.append('wallet_amount_to_use', arr_walletcustomer)
// 								}
// 								else {
// 									formData.append('wallet_amount_to_use', props.cartTotalArray.price)
// 								}
// 							}
// 						}

// 						formData.append('shipping_address_id', selected_address_ID)

// 						for (var pair of formData.entries()) {
// 							// console.log('pair...', pair);
// 						}
// 						await setIsLoadingPay(true)

// 						// window.location.href = 'https://paynetzuat.atomtech.in/paynetz/epi/fts?login=9132&encdata=EE09C765D78EB9AA8406378CAA3E230917A126B5D52A15A1D953D5E15411C9561A8860BE1AC5716B9D21275D6B01E5064E0687C7FE4DEE7D6219B0AE543C97A79557D96496753B1C4A236CAC1A23D7BB05FD5C5199728E318C84C36A34B02846B15473474A7154FE2B40CF48328EDB5370F3B2B2CC1F991AEF654789BFCDC427A2783AECA38DD0E0BA18109F1B276005CF70D76BD9CD2AC29B01EAB694006D6AD36237F95E6074AE2E64059DB59FF0A9C3767FC7FBF96261613898B5CC17C90F33C1ED93C547271B2545415A91907970E99E37A973B3EBEEADECC986499443FF2489D58BDACD1A73E0CDC68E016F37F9740D770D80CB34AFCC457779659AFE8B992779A0C2A9CCCEF58F61B80D70F983883EE4FA7656D41E2B0C02E871DEE20F5A1833B5DB4389A4E03CC64E50C70D0EB12D20A96E076C3637951D93417F5B24C366D70AA0498CAB0C45BB5F51ADEB17AA3DA92354283B0AD81E5B949ED621391EEBC3916666CB5378934F49F8E183A47B99746FB73B33FEE3E528C69F7ABA3A842AAA7388C3C69DC82223CE1FFBD8BB';

// 						var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/place-order', formData, "Error => place order api")

// 						if (res && res.success === 1) {
// 							set_loading_placeOrder(false)
// 							if (selectedOption === "Paytm") {
// 								set_orderID(res.response.order_id)
// 								await localStorage.setItem('order_id', res.response.order_id)
// 								await localStorage.setItem('payment_type', 'online')
// 								await displayPaytm(res.response, uniueId, props)
// 								setIsLoadingPay(false)
// 							} else if (selectedOption === "atom") {
// 								console.log("---------atom : ", res)
// 								await localStorage.setItem('order_id', res.order_id)
// 								await localStorage.setItem('payment_type', 'online')
// 								await localStorage.setItem("online_type", "atom")
// 								setIsLoadingPay(false)
// 								// await this.StripeClickAtom(res)
// 								if (res.atomTokenId !== null)
// 									window.openPay(res.atomTokenId, "8952", props.userData.email, props.userData.number, "https://newdemo.myshopwiz.com/order_confirm");
// 								// window.location.href = res.response.url;
// 							}
// 							else {
// 								await localStorage.setItem('order_id', res.order_id)
// 								await localStorage.setItem('payment_type', 'cod')

// 								var formdata = new FormData()
// 								formdata.append('device_id', uniueId)
// 								await props.getCartData('v1/get-cart-list', formdata, "Error => GetCartList api.")
// 								await setIsLoadingPay(false)
// 								await setGoToSuccess(true)
// 							}
// 						}
// 						else {
// 							alert(res ? res : 'Try again later')
// 							setIsLoadingPay(false)
// 							set_loading_placeOrder(false)
// 						}
// 					}
// 				} else {
// 					set_isProfileDone(true)
// 				}
// 			}
// 			else {
// 				setIsLoadingPay(false)
// 			}
// 		} else {
// 			validator.showMessages();
// 			//forceUpdate();

// 		}
// 	}

// 	const LinkTOHome = () => {
// 		return (
// 			<Link to={`${process.env.PUBLIC_URL}/`}> </ Link>
// 		)
// 	}

// 	const getUserAddress = async () => {

// 		var token = localStorage.getItem('token')

// 		var url = base_url.concat('v1/view-address')

// 		var res = await NaswizHelperApi.GET_WITH_TOKEN('v1/view-address', 'Error => v1/view-address')

// 		if (res && res.success === 1) {

// 			if (res.message) {

// 				var data = res.message
// 				// setall_address_data(res.message)
// 				setall_address_data(data)
// 			}
// 			else {
// 			}
// 		}
// 		else {

// 		}
// 	}

// 	const _onChangeTextEditProfile = (e) => {

// 		let id = { [e.target.id]: e.target.value }
// 		setId(id)
// 	}

// 	const firstTimeGettingStateList = () => {
// 		setLoading_state(true)
// 		return (async () => {

// 			var formData = new FormData()
// 			formData.append('country_id', '101')

// 			var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/state', formData, 'Error => v1/state')
// 			if (res && res.success === 1) {
// 				setArr_state(res.message)
// 				setLoading_state(false)

// 			}
// 			else {
// 				setLoading_state(false)
// 			}

// 		})
// 	}

// 	const getStateUponCountry = () => {
// 		// passing country id 101
// 		setLoading_state(true)
// 			(getStateUponCountry = async () => {

// 				var formData = new FormData();
// 				formData.append('country_id', '101');

// 				var res = await Helper.POST('v1/state', formData);

// 				if (res && res.success === 1) {
// 					setArr_state(res.message)
// 					setLoading_state(false)
// 					_getCityUponState()

// 				}
// 				else {

// 					setLoading_state(false);
// 				}

// 			})

// 	}

// 	const getCityUponState = (value) => {
// 		setLoading_city(true)
// 			getCityUponState = async () => {

// 				var formData = new FormData()
// 				// formData.append('state_id', this.state.state)
// 				formData.append('state_id', state)
// 				formData.append('country_id', '101')

// 				var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/cities', formData, 'Error => v1/state')

// 				if (res && res.success === 1) {

// 					setarr_city(res.message)
// 					setLoading_city(false)

// 				}
// 				else {
// 					setLoading_city(false)
// 				}
// 			}
// 	}

// 	const addUserAddress = () => {
// 		setLoading_city(false)
// 			(addUserAddress = async () => {

// 				// var { addressID, full_name, phone, pincode, address, city, state, is_status, gst_no, aadhar_no, aadhar_image } = this.state

// 				var formData = new FormData()

// 				if (addressID != '') {
// 					formData.append('address_id_no', addressID)
// 				}
// 				else {
// 					Notification('error', 'Error!', 'Please enter addressID first!')
// 				}

// 				if (full_name != '') {
// 					formData.append('fullname', full_name)
// 				}
// 				else {
// 					Notification('error', 'Error!', 'Please enter addressID first!')
// 				}

// 				if (phone != '') {
// 					formData.append('number', phone)
// 				}
// 				else {
// 					Notification('error', 'Error!', 'Please enter number first!')
// 				}

// 				if (address != '') {
// 					formData.append('address', address)
// 				}
// 				else {
// 					Notification('error', 'Error!', 'Please select address first!')
// 				}

// 				if (pincode != '') {
// 					formData.append('pincode', pincode)
// 				}
// 				else {
// 					Notification('error', 'Error!', 'Please enter pincode first!')
// 				}

// 				if (city != '') {
// 					formData.append('city_id', city)
// 				}
// 				else {
// 					Notification('error', 'Error!', 'Please enter city first!')
// 				}

// 				if (state != '') {
// 					formData.append('state_id', state)
// 				}
// 				else {
// 					Notification('error', 'Error!', 'Please enter state first!')
// 				}
// 				if (is_status != 0) {
// 					formData.append('is_status', is_status)
// 				}
// 				else {
// 					Notification('error', 'Error!', 'Please select address type first!')
// 				}

// 				if (gst_no != '') {
// 					formData.append('gst_no', gst_no)
// 				}
// 				if (aadhar_no != '') {
// 					formData.append('aadhar_no', aadhar_no)
// 				}
// 				if (aadhar_image != '') {
// 					formData.append('aadhar_image', aadhar_image)
// 				}

// 				formData.append('country_id', '101')

// 				var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/add-address', formData, 'Error => add user address api')

// 				if (res && res.success === 1) {

// 					setLoading_addAddress(false)
// 					setphone('')
// 					setaddress('')
// 					setCity('')
// 					setState('')
// 					setPincode('')
// 					setAddressID('')
// 					setFull_name('')
// 					setarr_city([])
// 					setGst_no('')
// 					setAadhar_no('')
// 					setaadhar_image('')
// 					setis_status(0)
// 					set_isAddressVisible(false)
// 					setOpen(false)

// 					getUserAddress()
// 					// () => this.StripeClick())
// 					Notification('success', 'Sucess!', 'Address Edit Successfully')

// 				}
// 				else {
// 					setLoading_addAddress(false)
// 				}

// 			})
// 	}

// 	const onChangeState = (value) => {
// 		if (value != '') {
// 			// setState(value)
// 			_getCityUponState(value)
// 		}
// 	}

// 	const customerDetails = async () => {
// 		var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/customers-detail', null, "Error => Customer details api")

// 		if (res && res.success === 1) {
// 			set_is_used(res.record.is_used)
// 		}

// 	}

// 	const onOpenEditModal = async (item, index) => {
// 		// console.log("----", item.aadhar_no, item.aadhar_no === null, item.aadhar_no === 'null')

// 		setOpenEdit(true)
// 		setEdit_address_name(item.fullname)
// 		setEdit_address_address(item.address)
// 		setEdit_address_pincode(item.pincode)
// 		setEdit_address_addressType(item.is_status)
// 		setSelectedCountryId(item.country_id)
// 		setSelectedstateId(item.state_id)
// 		setSelectedCityId(item.city_id)
// 		setedit_number(item.number)
// 		setEdit_aadhar_no(item.aadhar_no !== 'null' ? item.aadhar_no : '')
// 		setEdit_gst_no(item.gst_no !== 'null' ? item.gst_no : '')
// 		setEdit_address_id_no(item.address_id_no)
// 		setEdit_aadhar_image_address(item.image_full_path)
// 		setEdit_address_object(item)
// 		setIsEditAddressObject(true)

// 		if (item.country_id != '' && item.country_id != undefined) {
// 			await _getStateUponCountry(item.country_id)
// 		}
// 		if (item.state_id != '' && item.state_id != undefined) {
// 			await _getCityUponState(item.state_id)
// 		}
// 	};

// 	const _getCountry = async () => {

// 		var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/countries', null, "Error => Get country api")

// 		if (res && res.success == 1) {

// 			setArr_country(res.message)

// 			if (selectedCountryId == '' || selectedCountryId == undefined) {
// 				setSelectedCountryId(101)
// 				_getStateUponCountry(101)

// 			}
// 		}
// 	}
// 	const _resetCountryField = () => {
// 		// setArr_country(undefined)
// 	}

// 	const _getStateUponCountry = async (cId) => {

// 		if (cId === 'Select Country') {
// 			_resetCountryField()
// 		}
// 		else {
// 			setSelectedCountryId(cId)

// 			var formData = new FormData()
// 			formData.append('country_id', cId)

// 			var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/state', formData, "Error => Get state upon county api")

// 			if (res && res.success === 1) {
// 				setArr_state(res.message)

// 				if (res.message.length > 0) {
// 					if (selectedstateId != '' && selectedstateId != undefined) {
// 					} else {
// 						_getCityUponState(res.message[0].state_id)

// 					}
// 				}
// 			}
// 		}
// 	}

// 	const _getCityUponState = async (sId) => {

// 		setSelectedstateId(sId)

// 		var formData = new FormData()
// 		formData.append('state_id', sId)
// 		formData.append('country_id', selectedCountryId)

// 		var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/cities', formData, "Error => Get state upon county api")

// 		if (res && res.success === 1) {

// 			setarr_city(res.message)
// 		}
// 	}

// 	const referralCode = async (referal_code) => {
// 		if (_referalCode == '') {
// 			Notification('error', 'Error!', 'Please enter referral code')
// 			return;

// 		}
// 		var formData = new FormData()
// 		formData.append('referral_code', referal_code)
// 		var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/check-referral', formData, 'Error => v1/check-referral')

// 		if (res.success === 1) {
// 			Notification('success', 'Success!', res.message)
// 			customerDetails()

// 		} else {
// 			Notification('error', 'Error!', '' + res.message)
// 		}
// 	}

// 	// promocode apply
// 	const promoCodeApply = async () => {
// 		if (_promocode === '') {
// 			Notification('error', 'Error!', 'Please enter promo code')
// 			return;

// 		}
// 		var formData = new FormData()
// 		formData.append('discount_code', _promocode)
// 		var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/apply-discount', formData, 'Error => v1/check-referral')

// 		set_visiblePromocode(false)
// 		if (res.success === 1) {
// 			Notification('success', 'Success!', res.message)
// 			set_isPromocodeValide(true)
// 			var body = new FormData()
// 			body.append('device_id', uniueId)
// 			props.getCartData('v1/get-cart-list', body, "Error => GetCartList api.")
// 			// this.customerDetails()

// 		} else {
// 			Notification('error', 'Error!', '' + res.message)
// 		}
// 	}

// 	const removePrmocode = async () => {

// 		var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/remove-discount', {}, 'Error => v1/check-referral')

// 		if (res.success === 1) {
// 			Notification('success', 'Success!', res.message)
// 			set_isPromocodeValide(false)
// 			var body = new FormData()
// 			body.append('device_id', this.state.uniueId)
// 			props.getCartData('v1/get-cart-list', body, "Error => GetCartList api.")

// 		} else {
// 			Notification('error', 'Error!', '' + res.message)

// 		}
// 	}

// 	const setCounter = () => {
// 		// var that = this
// 		var timer = setInterval(manageTimer, 1000);
// 		setState(timer);

// 	}

// 	const manageTimer = () => {

// 		// var that = this
// 		// var { timer, _counter } = that.state
// 		// var { timer, _counter } = this.state

// 		if (_counter === 0) {
// 			var timer = setInterval(1000);
// 			clearInterval(timer)
// 			set_counter(0)
// 		}
// 		else {

// 			set_counter(_counter - 1)

// 		}
// 	}

// 	const resendOtp = async () => {
// 		await set_counter(60)
// 		await _sendOtp();
// 	}

// 	// divya
// 	const _sendOtp = async () => {

// 		// var { phoneNumber } = this.state
// 		var URL = 'v1/login'
// 		var ERROR = 'Error => Login Or Register api'
// 		var RESPONSE = 'Response => Login Or Register api'

// 		var numberSub = phoneNumber.slice(1, phoneNumber.length)   // Returns "Hello"

// 		if (phoneNumber.length >= 10) {

// 			setDisablebutton(true)
// 			var body = new FormData()
// 			body.append('number', numberSub)

// 			var res = await NaswizHelperApi.POST_WITHOUT_TOKEN(URL, body, ERROR)

// 			// if (res && res.message == 'Please first send message.') {
// 			// 	window.open('https://api.whatsapp.com/send?phone=+919540276786&text=Register%20Me');
// 			// }
// 			if (res && res.is_status === 0) {

// 				set_checkRegisteModal(true)
// 				setPhoneNumber('')
// 				// window.open('https://api.whatsapp.com/send?phone=+91 9540276786&text=Register%20Me');
// 			}
// 			else if (res && res.success === 1) {
// 				set_checkRegisteModal(false)
// 				Notification('success', 'Success!', res.message)
// 				setCounter()
// 				setOtpViewOpen(true)
// 				setDisablebutton(false)

// 			}
// 			else {
// 				setPhoneNumber('')
// 				setDisablebutton(false)
// 				Notification('error', 'Error!', res.message ? res.message : 'Try again later!')
// 			}
// 		}
// 		else {
// 			Notification('error', 'Error!', 'Enter valid phone number !')
// 		}

// 	}

// 	const _verifyOtp = async () => {

// 		// var { otp, phoneNumber } = this.state
// 		var OTP = otp

// 		var numberSub = phoneNumber.slice(1, phoneNumber.length)   // Returns "Hello"

// 		if (OTP.length === 4) {

// 			var body = new FormData()
// 			body.append('number', numberSub)
// 			body.append('otp', OTP)

// 			for (var pair of body.entries()) {
// 			}

// 			var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/verify-otp', body, "OTP")

// 			if (res && res.success === 1) {
// 				if (res.message === 'Please contact admin. User is not Authorized to access the site.') {
// 					Notification('error', 'Error!', res.message)
// 				}
// 				else {
// 					props.changeLoginState(true)
// 					props.manageUserIdState(res.id)
// 					localStorage.setItem('token', res.token)
// 					setOtp('')
// 					setOtpViewOpen(false)
// 					set_deliverycheckdone(true)

// 					Notification('success', 'Success!', 'OTP Verify Successfully.')
// 					setTimeout(() => {
// 						_getUserInfo()
// 						getUserAddress()
// 						_getMyWalletcustomers()
// 						var body = new FormData()
// 						body.append('device_id', uniueId)
// 						props.getCartData('v1/get-cart-list', body, "Error => GetCartList api.")
// 					}, 2000)

// 				}
// 			}
// 			else {
// 				Notification('error', 'Error!', res.message ? res.message : 'Try again later.')

// 				setOtp('')

// 			}
// 		}
// 		else {
// 			Notification('error', 'Error!', 'Enter valid OTP!')

// 			setOtp('')
// 		}
// 	}

// 	const _getUserInfo = async () => {

// 		var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/customers-detail', null, "Error => v1/customers-detail api.")

// 		if (res && res.success === 1) {
// 			props.manageUserIdState(res.record.id)
// 			props.addUserData(res.record)
// 		}
// 		else {
// 			logout()
// 		}
// 	}

// 	const logout = () => {
// 		this.props.changeLoginState(false)
// 		this.props.removeUserData()
// 		this.props.manageUserIdState(false)
// 		localStorage.clear()
// 		this.props.history.replace("/")

// 	}

// 	const onValueChange = (event) => {

// 		setSelectedOption(event.target.value)
// 		set_otherpaymenttype('')
// 	}

// 	const handleChange = otp => setOtp()

// 	const handleChangeWallet = async () => {
// 		await setIsChaked(!isChaked)
// 		await setSelectedOption(isChaked ? 'Wallet' : '')
// 	}

// 	// onChangePaymentType = async (type) => {
// 	// 	this.setState({ _choosepamentone: type })
// 	// 	if (type == 1) {
// 	// 		this.setState({ selectedOption: '' })
// 	// 	}
// 	// 	else {
// 	// 		this.setState({ _otherpaymenttype: '' })
// 	// 	}
// 	// }

// 	var number = props.userData && props.userData.number ? props.userData.number : 'Not Available'
// 	var gender = props.userData && props.userData.gender ? props.userData.gender : 'Not Available'
// 	// var { _id_number, edited_name, edited_email_id, edited_dob, edited_anniversary_date, edited_gender, edited_marital_status, edited_profile_image, edited_aadhar_card_image, edited_pan_card_image, edit_aadhar_image_address, edit_aadhar_imagepath_address } = this.state
// 	// { _id_number, edited_name, edited_email_id, edited_dob, edited_anniversary_date, edited_gender, edited_marital_status, edited_profile_image, edited_aadhar_card_image, edited_pan_card_image, edit_aadhar_image_address, edit_aadhar_imagepath_address }
// 	// const { cartItems, symbol, total } = this.props;
// 	// const { arr_state, arr_city, all_address_data, selected_address_ID } = this.state;
// 	// { cartItems, symbol, total }
// 	// { arr_state, arr_city, all_address_data, selected_address_ID }

// 	// Paypal Integration
// 	const onSuccess = (payment) => {
// 		props.history.push({
// 			pathname: '/order-success',
// 			////state: { payment: payment, items: cartItems, orderTotal: total, symbol: symbol }
// 			//  {payment(payment)
// 			// items(cartItems)
// 			// orderTotal(total)
// 			// symbol(symbol) }
// 		})

// 	}
// 	if (goToDashboard) {
// 		return (
// 			<Redirect to={{
// 				pathname: `/`,
// 			}} />
// 		)
// 	}
// 	if (goToSuccess) {
// 		return (
// 			<Redirect to={{
// 				pathname: `/order_confirm`,
// 			}} />
// 		)
// 	}

// 	const onCancel = (data) => {
// 	}
// 	const onError = (err) => {
// 	}

// 	const client = {
// 		sandbox: 'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
// 		production: 'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
// 	}

// 	return (
// 		<div>
// 			<Helmet>
// 				<title>Secure Payment: Login - Select Shipping Address - Review Order - Place - Naswiz Retails Pvt Ltd</title>
// 				<meta name="og_title" property="og:title" content="Secure Payment: Login > Select Shipping Address > Review Order > Place - Naswiz Retails Pvt Ltd" />
// 				<meta name="twitter:title" content="Secure Payment: Login > Select Shipping Address > Review Order > Place - Naswiz Retails Pvt Ltd" />
// 				<meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
// 				<meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
// 				<meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
// 				<meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
// 			</Helmet>
// 			{/*SEO Support End */}

// 			<Breadcrumb title={'Checkout'} />

// 			{isLoadingPay ?
// 				<section className="first">
// 					<div className="circular-spinner"></div>
// 				</section>
// 				: null}

// 			<section className="section-b-space">
// 				<div className="container">
// 					<div className="allCheckout">
// 						<div className="checkout_left">
// 							{otpViewOpen ?
// 								<div className={'couter_list'}>
// 									<h4 className={'register_modal_title'}> {_is_whatsapp == '0' ? 'Mobile Number Verification' : 'WhatsApp Number Verification'}</h4>
// 									<div className="otp_verification" style={{ display: 'block' }}>
// 										<p>We need your {_is_whatsapp == '0' ? 'Mobile' : 'WhatsApp'} number so that we can update you about your order.</p>
// 										<label>{_is_whatsapp == '0' ? 'Enter 4 digit code sent to your Mobile' : 'Enter 4 digit code sent to your WhatsApp'} <b>{phoneNumber}</b>
// 											<a href="javascript:void(0)" onClick={() => setOtpViewOpen(false)}>(Edit<img className="edit-img" src="assets/images/edit.png" alt="" />)</a></label>
// 										<div className="d-flex">
// 											<div className="register_modal_number_box">
// 												<OtpInput
// 													className={'login_otp_input'}
// 													value={otp}
// 													onChange={handleChange}
// 													numInputs={4}
// 													separator={<span className="dash-icon">-</span>}
// 													isInputNum={true}
// 													shouldAutoFocus
// 												/>
// 											</div>
// 											<button
// 												onClick={() => {
// 													if (otp.length == 4) {
// 														_verifyOtp()
// 													}
// 												}}
// 												className={otp.length == 4 ? 'register_modal_next_btn common_login_sign_up_btn common_login_sign_up_btn_click' : 'register_modal_next_btn common_login_sign_up_btn'}
// 												type="button">
// 												Next
// 											</button>
// 										</div>
// 										<p style={{ textAlign: 'left !important' }} onClick={() => { resendOtp() }} className={state._counter != 0 ? 'register_otp_resend hide mb-0' : 'register_otp_resend active mb-0'}>Resend Code <span>(in {_counter} secs)</span></p>
// 									</div>
// 								</div> :
// 								<div className={!props.isUserLogin ? 'couter_list ' : 'couter_list active'}>
// 									<div className="ifLogin before-none">
// 										<h4 className='text-left'>{_is_whatsapp == '0' ? 'Mobile Number Verification' : 'WhatsApp Number Verification'}</h4>
// 										{/* <div className="after_login">
// 												<p>+91 7503782454</p>
// 											</div> */}
// 										{!props.isUserLogin ?
// 											<div className="content_inside">
// 												<p className="whatsapp-verification-label">We need your {this.state._is_whatsapp == '0' ? 'Mobile' : 'WhatsApp'} number so that we can update you about your order.</p>
// 												{_checkRegisteModal ?
// 													<div className={'login_modal_number_box logintext'}>
// 														{/* <p className="mantitle">Information</p> */}
// 														<div><span className="title">For Sign Up, Please register your self on WhatsApp</span></div>
// 														<div><span className="subtitle">Follow the below steps for registration </span></div>
// 														<div><span >1. Save the Naswiz WhatsApp No to your contacts :  +919540276786 </span></div>
// 														<div><span >2. Send Hi on WhatsApp On this Naswiz WhatsApp No +919540276786 </span></div>
// 														<div><span >3. Press Next </span></div>
// 														{/* <button onClick={() => this.setState({ _checkRegisteModal: false })} className={'login_modal_next_btn_click common_login_sign_up_btn'} type="button">Ok</button> */}
// 													</div>
// 													: null}
// 												<div className="login_btn_check">
// 													<label htmlFor="">{_is_whatsapp == '0' ? 'Your 10 digit mobile number' : 'Your 10 digit Whatsapp number'}</label>
// 													{/* {console.log('this.state._is_whatsapp ', this.state._is_whatsapp )} */}
// 													{/* <input type="tel" maxlength="10" className="form-control" /> */}
// 													<div className={'login_modal_phone number-hide'}>
// 														<PhoneInput
// 															international
// 															defaultCountry="IN"
// 															value={phoneNumber}
// 															onChange={(value) => {
// 																setPhoneNumber(value)
// 																// console.log('value number ', value)
// 															}}
// 														/>
// 													</div>
// 												</div>
// 												<button onClick={() => {
// 													if (phoneNumber && phoneNumber.length >= 13) {
// 														_sendOtp()
// 													}
// 												}} className={phoneNumber && phoneNumber.length >= 13 && !disablebutton ? 'next_btn_check active' : 'next_btn_check'} type="button">Next</button>
// 												{/* <button className="next_btn_check" id="show_verifications" type="button">Next</button> */}
// 											</div>
// 											:
// 											<div className={'verified-datetime c-notdone '} >
// 												{/* <div className="change_address done">
// 											<a id="changeadatetime" type="button" className="change-btn" onClick={() => this.setState({ _timeSlotSelect: false })}>Change</a>
// 										</div> */}
// 												{props.userData ? <div className="address_checkout done">
// 													<div className='address_box'><i className="fa fa-mobile"></i><h5>+{props.userData.number}</h5></div>
// 												</div> : null}
// 											</div>}
// 									</div>
// 								</div>
// 							}

// 							<div className={"couter_list " + (props.isUserLogin && selected_address_ID != '' ? 'active' : 'd-none1')}>
// 								<h4>Delivery Address</h4>

// 								<div className={(_deliverycheckdone ? 'block_edit_add  c-notdone' : ' block_edit_add  c-done')}>
// 									<div className="editable_check_add">
// 										<p className="mt-2">
// 											<a href="javascript:void(0);" data-toggle="modal" data-target="#AddressModal" onClick={onOpenModal}>
// 												<i className="fa fa-plus-circle" aria-hidden="true"></i> Add New Delivery Address
// 											</a>
// 										</p>
// 									</div>
// 									<div className="row">
// 										{all_address_data && all_address_data.length > 0 ?
// 											all_address_data.map((address, index) => {
// 												return (
// 													<div className="col-md-6 mb-4" key={index}>
// 														<div className="check_add_box d-flex flex-column h-100">
// 															{address.is_status === 1 ? <h3>Home</h3> : address.is_status === 2 ? <h3>Office</h3> : <h3>Other</h3>}
// 															<div className="icon_edit">
// 																<a data-toggle="modal" data-target="#editaddressModal" onClick={() => onOpenEditModal(address, index)} >
// 																	<img src="assets/images/edit.png" alt="" />
// 																</a>
// 															</div>
// 															<h5>{address.address_id_no}-{address.fullname} </h5>

// 															<p>{address.address}</p>
// 															{/* <a href="javascript:void(0);" className="check_deliver" onClick={this.addAddressID(address.id)}>Deliver Here</a> */}
// 															{selected_address_ID != '' && selected_address_ID === address.id ?
// 																<a href="javascript:void(0);" className="check_deliver checked" onClick={() => {
// 																	setselectedAddressFull(address.address)
// 																	set_deliverycheckdone(false)
// 																	set_deliverycheckdoneselect(true)
// 																	set_payment(true)
// 																	set_delivery_date(true)
// 																	setselected_address_ID(address.id)
// 																	setSelectedAddressFull2(address.address_id_no + '-' + address.fullname)
// 																}}>Address is selected</a>
// 																:
// 																<a href="javascript:void(0);" className="check_deliver mt-auto" onClick={() => {
// 																	setSelectedAddressFull2(address.address_id_no + '-' + address.fullname)
// 																	setselectedAddressFull(address.address)
// 																	set_deliverycheckdone(false)
// 																	set_deliverycheckdoneselect(true)
// 																	set_payment(true)
// 																	setselected_address_ID(address.id)
// 																	set_delivery_date(true)
// 																	getCartList(address.id)
// 																}}>Deliver Here</a>
// 															}
// 														</div>
// 													</div>
// 												)
// 											})
// 											: null
// 										}
// 									</div>
// 								</div>
// 								{_deliverycheckdone == false ?
// 									<div className={selected_address_ID != '' ? 'verified-address  c-notdone' : ' verified-address  c-done'} >
// 										<div className="change_address done">
// 											<a id="changeaddress" onClick={() => set_deliverycheckdone(true)} className="change-btn">Change</a>
// 										</div>
// 										<div className="address_checkout done">
// 											<div className='address_box'><i className="fas fa-home"></i><h5> {selectedAddressFull2}</h5>
// 												<p>{selectedAddressFull}</p></div>
// 										</div>
// 									</div>
// 									: null
// 								}
// 							</div>

// 							{/* delivery date end */}
// 							<div className={"couter_list "}>
// 								<h4>Payment</h4>
// 								<div className={selected_address_ID != '' ? ' c-notdone' : ' c-done'}>
// 									{props.isUserLogin && props.cartTotalArray != undefined ?

// 										<div className="cartdetails">
// 											<div className="totalCalcualte">
// 												<div className="top_left_t">
// 													<p>IP Points <span>{props.cartTotalArray.total_ip}</span></p>
// 												</div>
// 												<div className="top_left_t">
// 													<p>MRP Total <span>₹{props.cartTotalArray.main_price}</span></p>
// 												</div>
// 												<div className="top_left_t">
// 													<p>Discount <span>- ₹{props.cartTotalArray.discount_price}</span></p>
// 												</div>
// 												<div className="top_left_t">
// 													<p>Total Price <span>₹{props.cartTotalArray.price_without_shipping}</span></p>
// 												</div>
// 												<div className="top_left_t">
// 													<p>Delivery Charge <span>₹{props.cartTotalArray.shipping_charge}</span></p>
// 												</div>

// 												<div className="bottom_both">
// 													<p>Total Amount <span>{props.cartTotalArray.format_total_priceINR}</span></p>
// 												</div>

// 												<div className="bottom_both">
// 													<p>Total IP <span>{props.cartTotalArray.total_ip}</span></p>
// 												</div>
// 											</div>
// 											{state._is_used === null || state._is_used == '' ?
// 												<div className="referal_code done">

// 													<div className="input-group refercode-inputgroup done">
// 														<input type="text" className="form-control" placeholder="Enter Referral Code" onChange={e => set_referalCode(e.target.value)} />
// 														<div className="input-group-append done">
// 															<a id="refercode" className="input-group-text" onClick={() => this.referralCode(_referalCode)}>Refer</a>
// 														</div>
// 													</div>
// 													{/* <p className="refferalcodeapplied">Referral Code Apply Successfully</p> */}
// 												</div>
// 												// <div className="referal_code" style={{ flexDirection: 'row' }}>

// 												// 	<input style={{ width: '90%' }} type="text" placeholder="Enter Referral Code" onChange={e => this.setState({ _referalCode: e.target.value })} />
// 												// 	<button onClick={() => this.referralCode(this.state._referalCode)} className="next_btn_cop" type="button">Apply</button>

// 												// 	{/* <p contenteditable="true">Referral Code  <span><input type="text" className="refInput" placeholder="Enter Referral Code"></span> </p>  */}
// 												// </div>
// 												: null
// 											}
// 										</div>
// 										: null
// 									}

// 									{/*  0 means not to show and if it is 1 mean promo code is available */}
// 									{props.cartTotalArray && props.cartTotalArray.discount_box == 1 ? <div className="prodmo_code">
// 										<div className="item_sub_check_offer">
// 											<a href="javascript:void(0)" onClick={() => set_visiblePromocode(true)} data-toggle="modal" data-target="#prodmo_code">
// 												<div className="left_check">
// 													<p>Promo code & Bank offers</p>
// 												</div>

// 												<div className="right_check">
// 													<p><i className="fa fa-angle-right" aria-hidden="true"></i></p>
// 												</div>
// 											</a>

// 										</div>
// 										{_isPromocodeValide ?
// 											<button onClick={() => removePrmocode()} className="remove" type="button">Remove Promocode</button>
// 											: null}
// 									</div> : null}

// 									<div className="payment_tab">
// 										<ul className="nav nav-tabs">
// 											<li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#wallet" onClick={() => {
// 												set_choosepamentone(0)
// 												set_otherpaymenttype('')
// 												set_otheramount('')
// 												set_otherdate('')
// 												set_othertrans('')
// 												// this.onChangePaymentType(0)
// 											}}>Online Payment</a></li>
// 											{/* <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#card">Card</a></li>
// 											<li className="nav-item"><a className="nav-link " data-toggle="tab" href="#cash">Cash</a></li>
// 											<li className="nav-item"><a className="nav-link" data-toggle="tab" href="#netbanking">Net Banking</a></li>*/}
// 											<li className="nav-item"><a className="nav-link" data-toggle="tab" href="#othermethod" onClick={() => {
// 												set_choosepamentone(1)
// 												setSelectedOption('')
// 												// this.onChangePaymentType(1)
// 											}}>Other Method</a></li>
// 										</ul>
// 										<div className="tab-content">
// 											<div id="wallet" className="tab-pane container active">
// 												<div className="payment-wallets">
// 													{arr_walletcustomer > 0 ?
// 														<div className="indivisual_pay">
// 															<label className="wallet-opt card-selection">
// 																<div className="payment-radio-design checkbox">
// 																	<input id="Paytm" type="checkbox" className="checkbox" value="" defaultChecked={isChaked} onChange={() => handleChangeWallet()} />
// 																	<span className="radio-element"></span>
// 																	<div className="wallet-type">
// 																		<i className="fas fa-wallet"></i>
// 																		<div>
// 																			<p>Wallet Balance</p>
// 																			<p className="available-balance">Available Balance <span className="text-theme-primary fw-bold">{arr_walletcustomer}</span></p>
// 																		</div>
// 																	</div>
// 																</div>
// 																<div className="card-selection__details" >
// 																	<span className="wallet_name">Wallet  (Wallet Balance <strong>{arr_walletcustomer}</strong>)</span>
// 																	{props.cartTotalArray ?
// 																		arr_walletcustomer < props.cartTotalArray.price && state.isChaked ?
// 																			<span className="wallet_name">Choose other option to pay {props.cartTotalArray.price - arr_walletcustomer}  </span> : null
// 																		: null}
// 																</div>
// 															</label>
// 														</div>
// 														: null
// 													}
// 													<div className="indivisual_pay">

// 														<label className="wallet-opt card-selection">
// 															<div className="payment-radio-design radio">
// 																<input id="atom" type="radio" className="radio" value="atom"
// 																	checked={selectedOption === "atom"}
// 																	onChange={(event) => {
// 																		if (isChaked && arr_walletcustomer >= props.cartTotalArray.price) {
// 																		} else {
// 																			onValueChange(event)
// 																		}
// 																	}
// 																		// displayPaytm
// 																	}
// 																/>
// 																<span className="radio-element"></span>

// 																<div className="wallet-type">
// 																	<img src="../../assets/images/atom.png" />
// 																</div>
// 															</div>
// 															<div className="card-selection__details" ><span className="wallet_name">Atom</span>
// 																<span className="details_payemt">Debit Card / Credit Card / Net Banking / Paytm / UPI / Wallet Payments</span>
// 															</div>
// 														</label>
// 													</div>
// 													<div className="indivisual_pay">
// 														<label className="wallet-opt card-selection">
// 															<div className="payment-radio-design radio">
// 																<input id="Paytm" type="radio" className="radio" value="Paytm"
// 																	checked={selectedOption === "Paytm"}
// 																	onChange={(event) => {
// 																		if (isChaked && arr_walletcustomer >= props.cartTotalArray.price) {
// 																		} else {
// 																			onValueChange(event)
// 																		}
// 																	}
// 																		// displayPaytm
// 																	}
// 																/>
// 																<span className="radio-element"></span>
// 																<div className="wallet-type">
// 																	<img src="../../assets/images/paytm.png" />
// 																</div>
// 															</div>
// 															<div className="card-selection__details" ><span className="wallet_name">Paytm</span>
// 																<span className="details_payemt">Paytm / UPI / Wallet Payments</span>
// 															</div>
// 														</label>
// 													</div>
// 													{props.cartTotalArray && props.cartTotalArray.is_cod == '0' ?
// 														null
// 														:
// 														<div className="indivisual_pay">
// 															<label className="wallet-opt card-selection">
// 																<div className="payment-radio-design radio">
// 																	<input id="COD" type="radio" className="radio" value="COD"
// 																		checked={selectedOption === "COD"}
// 																		onChange={(event) => {
// 																			if (isChaked && arr_walletcustomer >= props.cartTotalArray.price) {
// 																			} else {
// 																				onValueChange(event)
// 																			}
// 																		}
// 																			// displayPaytm
// 																		} />
// 																	<span className="radio-element"></span>
// 																	<div className="wallet-type">
// 																		<img src="../../assets/images/cod.png" />
// 																	</div>
// 																</div>
// 																<div className="card-selection__details"><span className="wallet_name">COD</span>
// 																	<span className="details_payemt">Cash On Delivery</span>
// 																</div>
// 															</label>
// 														</div>
// 													}
// 												</div>

// 												{/* {this.state.arr_walletcustomer < this.props.cartTotalArray.price ?
// 														<div className="payment-btn-holder">
// 															<button className={this.state.selectedOption == '' ? "make_payment_new btn" : "make_payment_new btn red"}>Pay Now</button>
// 															<span>Please Select Other Payment Opction</span>
// 														</div>
// 														: */}
// 												{_loading_placeOrder ?
// 													//  null :
// 													<div className="loader-wrapper" style={{ position: 'absolute', width: '100%', height: '100%', left: '0px' }}>
// 														<div className="loader"></div>
// 													</div>
// 													:
// 													null
// 												}

// 												<div className="payment-btn-holder">
// 													{/* <button className={this.state.selectedOption == '' ? "make_payment_new btn" : "make_payment_new btn red"} onClick={() => {
// 															if (this.state.selectedOption == 'atom') {
// 																this.StripeClickAtom()

// 															} else {
// 																this.StripeClick()

// 															}
// 														}}
// 														>
// 															Pay Now</button> */}
// 													<button className={selectedOption == '' ? "make_payment_new btn" : "make_payment_new btn red"}
// 														onClick={() => StripeClick()}>
// 														Pay Now</button>

// 												</div>
// 												{/* } */}
// 											</div>
// 											<div id="othermethod" className="tab-pane container fade">
// 												<div className="other_method">
// 													<label htmlFor="cheque"><input type="radio" id="cheque" name="gender" value="cheque"
// 														checked={(_otherpaymenttype === "cheque")}
// 														onChange={(event) => {
// 															set_otherpaymenttype('cheque')
// 															setSelectedOption('')
// 														}}
// 													/>Cheque</label>
// 													<label htmlFor="neft"><input type="radio" id="neft" name="gender" value="neft"
// 														checked={(_otherpaymenttype === "neft")}
// 														onChange={(event) => {
// 															set_otherpaymenttype('neft')
// 															setSelectedOption('')
// 														}} />NEFT</label>
// 													<label htmlFor="imps"><input type="radio" id="imps" name="gender" value="imps"
// 														checked={(_otherpaymenttype === "imps")}
// 														onChange={(event) => {
// 															set_otherpaymenttype('imps')
// 															setSelectedOption('')
// 														}} />IMPS</label>
// 													<label htmlFor="cashdeposit"><input type="radio" id="cashdeposit" name="gender" value="cashdeposit"
// 														checked={(_otherpaymenttype === "cashdeposit")}
// 														onChange={(event) => {
// 															set_otherpaymenttype('cashdeposit')
// 															setSelectedOption('')
// 														}} />Cash Deposit</label>
// 													<label htmlFor="cardswipe"><input type="radio" id="cardswipe" name="gender" value="cardswipe"
// 														checked={(_otherpaymenttype === "cardswipe")}
// 														onChange={(event) => {
// 															set_otherpaymenttype('cardswipe')
// 															setSelectedOption('')
// 														}} />Showroom Card Swipe</label>
// 												</div>
// 												{/* <form> */}
// 												<div className="input-row">

// 													<div className="single-input-item">
// 														<label>Amount:</label>
// 														<input type="text" name="amount" placeholder="Amount" required value={_otheramount}
// 															onChange={e => set_otheramount(e.target.value)} />
// 													</div>
// 													<div className="single-input-item ">
// 														<label>Date:</label>
// 														<input type="date" name="Date" placeholder="Date" required value={_otherdate} onChange={e => set_otherdate(e.target.value)} />
// 													</div>
// 													<div className="single-input-item">
// 														<label>Transaction Detail:</label>
// 														<input type="text" name="amount" placeholder="Transaction Detail" required value={_othertrans} onChange={e => set_othertrans(e.target.value)} />
// 													</div>
// 													<div className="single-input-item payment-file">
// 														<label>Screenshot of payment as proof :</label>
// 														<div className="input-group">
// 															<div className="input-group-append w-100">
// 																<input type="file" className="form-control forms_inputs " />
// 																<span className="input-group-text">
// 																	<i className="fa fa-times" aria-hidden="true"></i>
// 																</span>
// 															</div>
// 														</div>
// 														<small>Accept .JPG, .JPEG, .PNG and .PDF document with less than 1024 KB size.</small>
// 													</div>

// 												</div>
// 												<div className="payment-btn-holder small">
// 													{/* <button  className="make_payment_new btn" onClick={() =>this.StripeClick()}>Pay Now</button> */}
// 													<button className={_otherpaymenttype == '' ? "make_payment_new btn" : "make_payment_new btn red"} onClick={() => StripeClick()}>Pay Now</button>
// 												</div>
// 												{/* </form> */}
// 											</div>
// 										</div>
// 									</div>
// 								</div>
// 							</div>

// 						</div>
// 					</div>

// 				</div>

// 				<Modal open={open} onClose={onCloseModal} center>
// 					<div className="editaddressModal vertical-align-center">
// 						{/* <!-- Modal content--> */}
// 						<div className="adderess_inside">
// 							<h4>Add New Delivery Address</h4>
// 							<p>Please enter the accurate address, it will help us to serve you better</p>

// 						</div>
// 						<div className="inside_form_fields">
// 							<div className='theme-form address-form'>
// 								<form role="form">
// 									<div className="row">
// 										<div className="col-12 col-md-6 col-sm-6">
// 											<div className="form-group">
// 												<label htmlFor="">ID No.<sup className="top_star">*</sup></label>
// 												<input
// 													type="text"
// 													className="form-control"
// 													placeholder=""
// 													value={addressID}
// 													onChange={(e) => onChangeID(e)}
// 												// onChange={e => this.setState({ addressID: e.target.value })}
// 												/>
// 											</div>
// 										</div>
// 										<div className="col-12 col-md-6 col-sm-6">
// 											<div className="form-group">
// 												<label htmlFor="">Name<sup className="top_star">*</sup></label>
// 												<input
// 													type="text"
// 													value={full_name}
// 													className="form-control"
// 													onChange={e => {
// 														const newName = e.target.value.replace(
// 															/[^a-zA-Z\s]/g,
// 															""
// 														);
// 														setFull_name(newName)
// 													}}
// 												// onChange={e => this.setState({ full_name: e.target.value })}
// 												/>
// 											</div>
// 										</div>
// 									</div>

// 									<div className="row">
// 										<div className="col-12 col-md-12 col-sm-12">
// 											<div className="form-group">
// 												<label htmlFor="">Address<sup className="top_star">*</sup></label>
// 												<input type="text" className="form-control" placeholder="" onChange={e => setaddress(e.target.value)} />
// 											</div>
// 										</div>
// 									</div>
// 									<div className="row">

// 										<div className="col-12 col-md-4 col-sm-4">
// 											<div className="form-group">
// 												<label htmlFor="">Country <sup className="top_star">*</sup></label>
// 												<select
// 													value={selectedCountryId}
// 													onChange={(e) => _getStateUponCountry(e.target.value)}
// 													className="form-control">

// 													<option value={undefined}>Select Country</option>
// 													{
// 														arr_country.map((item, index) => {
// 															return (
// 																<option key={index} value={item.id}>{item.name}</option>
// 															)
// 														})
// 													}
// 												</select>
// 											</div>
// 										</div>

// 										<div className="col-12 col-md-4 col-sm-4">
// 											<div className="form-group">
// 												<label htmlFor="">State <sup className="top_star">*</sup></label>
// 												{/* <select className="form-control" onChange={e => this.setState({state : e.target.value}, this.getCityUponState())}> */}
// 												<select className="form-control" onChange={e => onChangeState(e.target.value)}>
// 													<option value="">Select State</option>
// 													{
// 														Arr_state && Arr_state.length > 0 ?
// 															Arr_state.map((state, index) => {
// 																return (
// 																	<option key={index} value={state.state_id}>{state.name}</option>
// 																)
// 															})
// 															: null
// 													}

// 												</select>
// 											</div>
// 										</div>
// 										<div className="col-12 col-md-4 col-sm-4">
// 											<div className="form-group">
// 												<label htmlFor="">City <sup className="top_star">*</sup></label>
// 												{/* <input type="text" className="form-control" placeholder="" onChange={e => this.setState({city : e.target.value})}/> */}
// 												<select className="form-control" onChange={e => setCity(e.target.value)}>
// 													<option value="">Select City</option>
// 													{arr_city && arr_city.length > 0 ?
// 														arr_city.map((city, index) => {
// 															return (
// 																<option key={index} value={city.city_id}>{city.name}</option>
// 															)
// 														})
// 														: null
// 													}
// 												</select>
// 											</div>
// 										</div>
// 									</div>
// 									<div className="row">
// 										<div className="col-12 col-md-6 col-sm-6">
// 											<div className="form-group">
// 												<label htmlFor="">Pin code <sup className="top_star">*</sup></label>
// 												<input
// 													type="text"
// 													maxLength={6}
// 													className="form-control"
// 													onChange={(e) => onHandleTelephoneChange(e)}
// 													placeholder=""
// 													inputMode="numeric"
// 													id="pincode"
// 													value={pincode}
// 												/>
// 												{/* <input type="text" className="form-control" placeholder="" onChange={e => this.setState({ pincode: e.target.value })} /> */}
// 											</div>
// 										</div>
// 										<div className="col-12 col-md-6 col-sm-6">
// 											<div className="form-group">
// 												<label htmlFor="">Mobile Number<sup className="top_star">*</sup></label>
// 												<input
// 													type="text"
// 													maxLength={10}
// 													className="form-control"
// 													onChange={(e) => onHandleTelephoneChange(e)}
// 													placeholder=""
// 													inputMode="numeric"
// 													id="phone"
// 													value={phone}
// 												/>
// 												{/* <input type="text" className="form-control" placeholder="" onChange={e => this.setState({ phone: e.target.value })} /> */}
// 											</div>
// 										</div>
// 									</div>
// 									<div className="row">
// 										<div className="col-12 col-md-6 col-sm-6">
// 											<div className="form-group">
// 												<label htmlFor="">Aadhar Card Number</label>
// 												<input
// 													type="text"
// 													className="form-control"
// 													placeholder=""
// 													onChange={(e) => onHandleTelephoneChange(e)}
// 													inputMode="numeric"
// 													id="aadhar_no"
// 													maxLength={12}
// 													value={aadhar_no}
// 												/>
// 												{/* <input type="text" className="form-control" placeholder="" onChange={e => this.setState({ aadhar_no: e.target.value })} /> */}
// 											</div>
// 										</div>
// 										<div className="col-12 col-md-6 col-sm-6">
// 											<div className="form-group">
// 												<label htmlFor="">GST Number</label>
// 												<input
// 													type="text"
// 													className="form-control"
// 													placeholder=""
// 													value={gst_no.toLocaleUpperCase()}
// 													// onChange={e => this.setState({ gst_no: e.target.value })}
// 													onChange={e => {
// 														const newId = e.target.value.replace(
// 															/[^a-zA-Z0-9]/g,
// 															""
// 														);
// 														setGst_no(newId)
// 													}}
// 												/>
// 											</div>
// 										</div>
// 									</div>

// 									<div className="row">
// 										<div className="col-12 col-md-12 col-sm-12">
// 											<div className="form-group">
// 												<label htmlFor="">Upoad Adhar Card</label>
// 												<div className="input-group">
// 													<input
// 														onChange={e => setaadhar_image(e.target.value)}
// 														type="file"
// 														className="top_rmv_g form-control "
// 														ref={fileAadharImageAddress}
// 													/>
// 													<div
// 														onClick={() => {
// 															setEdit_aadhar_image_address(null)
// 															fileAadharImageAddress.value = "";
// 														}}
// 														className="input-group-append">
// 														<span className="input-group-text"><i className="fa fa-times" aria-hidden="true" /></span>
// 													</div>

// 												</div>
// 												<div className="allImgNo">
// 													<img src={edit_aadhar_image_address} />
// 												</div>
// 											</div>
// 										</div>
// 									</div>

// 									<div className="address_type">
// 										<label className="radio-inline"><input type="radio" name="optradio" onChange={e => setis_status(1)} /> Home</label>
// 										<label className="radio-inline"><input type="radio" name="optradio" onChange={e => setis_status(2)} /> Office</label>
// 										<label className="radio-inline"><input type="radio" name="optradio" onChange={e => setis_status(3)} /> Other</label>
// 									</div>

// 									<div className="both_buttons">
// 										<button type="button" className="btn continue_btns" onClick={addUserAddress}>Continue</button>
// 										<button type="button" onClick={onCloseModal} className="btn cancel_btns ml-2">Cancel</button>
// 									</div>

// 								</form>
// 							</div>
// 						</div>
// 					</div>
// 				</Modal>

// 				{/* <!-- add card start --> */}
// 				<div className="modal fade" id="add_card" role="dialog">
// 					<div className="vertical-alignment-helper">
// 						<div className="SignupModal modal-dialog vertical-align-center">
// 							{/* <!-- Modal content--> */}
// 							<div className="modal-content">
// 								<div className="SignupModalIn">
// 									<div className="SignupModal modal-body clearfix">
// 										<div className="card_box">
// 											<div className="row">
// 												<div className="col-12 col-md-12 col-sm-12">
// 													<div className="form-group">
// 														<label htmlFor="usr">Card Number</label>
// 														<input type="text" className="form-control" placeholder="Card Number" />
// 													</div>
// 												</div>
// 												<div className="col-12 col-md-9 col-sm-9">
// 													<div className="form-group full_label">
// 														<label htmlFor="usr">Valid Through</label>
// 														<input type="number" className="form-control half_space_left" placeholder="MM" />
// 														<input type="number" className="form-control half_space" placeholder="YY" />
// 													</div>
// 												</div>
// 												<div className="col-12 col-md-3 col-sm-3">
// 													<div className="form-group">
// 														<label htmlFor="usr">CVV</label>
// 														<input type="text" className="form-control" placeholder="CVV" />
// 													</div>
// 												</div>
// 												<div className="col-12 col-md-12 col-sm-12">
// 													<div className="form-check-inline">
// 														<label className="form-check-label">
// 															<input type="checkbox" className="form-check-input" /> Save this card for future use <span className="save_cr">It's perfectly secure</span>
// 														</label>
// 													</div>
// 												</div>
// 												<div className="col-12 col-md-12 col-sm-12">
// 													<button type="button" className="btn card_btn">Pay now</button>
// 												</div>
// 											</div>
// 										</div>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 				{/* <!--  add card end-->

// 					<!-- edit profile --> */}
// 				<Modal open={openEdit} onClose={() => _resetEditAddressState()} center>
// 					<div className="editaddressModal vertical-align-center">

// 						<div className="adderess_inside">
// 							<h4 style={{ fontSize: '18px', fontWeight: '500', marginBottom: '8px' }}>Edit Delivery Address</h4>
// 							<p style={{ textAlign: 'center' }}>Please enter the accurate address, it will help us to serve you better</p>
// 						</div>
// 						<div className="inside_form_fields">
// 							{/* <form
//                                         // method="POST"
//                                         // action="#"
//                                         // role="form"
//                                         className="theme-form address-form "> */}
// 							{/* <form> */}
// 							<div className="theme-form address-form">
// 								<div className="row">
// 									<div className="col-12 col-md-6 col-sm-6">
// 										<div className="form-group">
// 											<label htmlFor="">ID No.<sup className="top_star">*</sup></label>
// 											<input
// 												id="edit_address_id_no"
// 												// onChange={(e) => this._onChangeText(e)}
// 												// onChange={(e) => this.onChangeID(e)}
// 												onChange={(e) => {
// 													const newId = e.target.value.replace(
// 														/[^a-zA-Z0-9]/g,
// 														""
// 													);
// 													setEdit_address_id_no(newId)
// 												}}
// 												type="text"
// 												className="form-control"
// 												placeholder={'Enter your ID number'}
// 												inputMode="numeric"
// 												value={edit_address_id_no}
// 											/>
// 										</div>
// 									</div>
// 									<div className="col-12 col-md-6 col-sm-6">
// 										<div className="form-group">
// 											<label htmlFor="">Name<sup className="top_star">*</sup></label>
// 											<input
// 												id="edit_address_name"
// 												// onChange={(e) => this._onChangeText(e)}
// 												onChange={e => {
// 													const newName = e.target.value.replace(
// 														/[^a-zA-Z\s]/g,
// 														""
// 													);
// 													setEdit_address_name(newName)
// 												}}
// 												type="text"
// 												className="form-control"
// 												value={edit_address_name}
// 												placeholder={'Enter your name'}
// 											/>
// 										</div>
// 									</div>
// 								</div>

// 								<div className="row">
// 									<div className="col-12 col-md-12 col-sm-12">
// 										<div className="form-group">
// 											<label htmlFor="">Address<sup className="top_star">*</sup></label>
// 											<input
// 												id="edit_address_address"
// 												onChange={(e) => _onChangeText(e)}
// 												type="text"
// 												className="form-control"
// 												value={edit_address_address}
// 												placeholder={'Enter your address'}
// 											/>
// 										</div>
// 									</div>
// 								</div>

// 								<div className="row">

// 									<div className="col-12 col-md-4 col-sm-4">
// 										<div className="form-group">
// 											<label htmlFor="">Country <sup className="top_star">*</sup></label>
// 											<select
// 												value={selectedCountryId}
// 												onChange={(e) => _getStateUponCountry(e.target.value)}
// 												className="form-control">

// 												<option value={undefined}>Select Country</option>
// 												{
// 													arr_country.map((item, index) => {
// 														return (
// 															<option key={index} value={item.id}>{item.name}</option>
// 														)
// 													})
// 												}
// 											</select>
// 										</div>
// 									</div>

// 									<div className="col-12 col-md-4 col-sm-4">
// 										<div className="form-group">
// 											<label htmlFor="">State<sup className="top_star">*</sup></label>
// 											<select
// 												value={selectedstateId}
// 												onChange={(e) => _getCityUponState(e.target.value)}
// 												className="form-control">
// 												<option value={undefined}>Select State</option>
// 												{
// 													selectedCountryId !== undefined ?
// 														Arr_state.map((item, index) => {
// 															return (
// 																<option key={index} value={item.state_id}>{item.name}</option>
// 															)
// 														})
// 														:
// 														<option value={undefined}>First Select Country</option>
// 												}
// 											</select>
// 										</div>
// 									</div>

// 									<div className="col-12 col-md-4 col-sm-4">
// 										<div className="form-group">
// 											<label htmlFor="">City<sup className="top_star">*</sup></label>
// 											<select
// 												value={selectedCityId}
// 												onChange={(e) => setSelectedCityId(e.target.value)}
// 												className="form-control">
// 												<option value={undefined}>Select City</option>
// 												{
// 													selectedstateId !== undefined ?
// 														arr_city.map((item, index) => {
// 															return (
// 																<option key={index} value={item.city_id}>{item.name}</option>
// 															)
// 														})
// 														:
// 														<option value={undefined}>First Select State</option>
// 												}
// 											</select>
// 										</div>
// 									</div>
// 								</div>
// 								<div className="row">
// 									<div className="col-12 col-md-6 col-sm-6">
// 										<div className="form-group">
// 											<label htmlFor="">Pin code <sup className="top_star">*</sup></label>
// 											<input
// 												onChange={(e) => onHandleTelephoneChange(e)}
// 												type="text"
// 												className="form-control"
// 												value={edit_address_pincode}
// 												placeholder={'Enter your pincode'}
// 												inputMode="numeric"
// 												id="edit_address_pincode"
// 												maxLength={6}
// 											/>

// 										</div>
// 									</div>
// 									{/* edit mobilr number */}
// 									<div className="col-12 col-md-6 col-sm-6">
// 										<div className="form-group">
// 											<label htmlFor="">Mobile Number <sup className="top_star">*</sup></label>
// 											<input
// 												onChange={(e) => onHandleTelephoneChange(e)}
// 												type="text"
// 												className="form-control"
// 												value={edit_number}
// 												placeholder={'Enter your mobile number'}
// 												inputMode="numeric"
// 												id="edit_number"
// 												maxLength={10}
// 											/>

// 										</div>
// 									</div>
// 								</div>
// 								<div className="row">
// 									<div className="col-12 col-md-6 col-sm-6">
// 										<div className="form-group">
// 											<label htmlFor="">Adhar Card Number</label>
// 											<input
// 												value={edit_aadhar_no}
// 												type="text"
// 												className="form-control"
// 												placeholder="Enter your Adhar Card Number"
// 												onChange={(e) => onHandleTelephoneChange(e)}
// 												inputMode="numeric"
// 												id="edit_aadhar_no"
// 												maxLength={12}
// 											/>
// 										</div>
// 									</div>
// 									<div className="col-12 col-md-6 col-sm-6">
// 										<div className="form-group">
// 											<label htmlFor="">GST Number</label>
// 											<input
// 												value={edit_gst_no !== null ? edit_gst_no.toLocaleUpperCase() : edit_gst_no}
// 												type="text"
// 												className="form-control"
// 												placeholder="Enter your GST Number"
// 												// onChange={(e) => this._onChangeText(e)}
// 												onChange={e => {
// 													const newId = e.target.value.replace(
// 														/[^a-zA-Z0-9]/g,
// 														""
// 													);
// 													setEdit_gst_no(newId)
// 												}}
// 												id="edit_gst_no"
// 												maxLength={15}
// 											/>
// 										</div>
// 									</div>
// 								</div>

// 								{/* edit adharcard number in address */}
// 								<div className="row">
// 									<div className="col-md-12">
// 										<div className="gap_top_ot gap_top">
// 											<div className="row align-items-center">
// 												<div className="col-12">
// 													<div className="form-group input-group">
// 														<label>Upload Adhar Card</label>
// 														<div className="input-group">
// 															<input
// 																onChange={(e) => setEdit_aadhar_imagepath_address(e.target.files[0])}
// 																type="file"
// 																className="top_rmv_g form-control "

// 																ref={fileAadharImageAddress}
// 															/>
// 															<div
// 																onClick={() => {
// 																	setEdit_aadhar_image_address(null)
// 																	fileAadharImageAddress.value = "";
// 																}}
// 																className="input-group-append">
// 																<span className="input-group-text"><i className="fa fa-times" aria-hidden="true" /></span>
// 															</div>
// 														</div>
// 													</div>
// 												</div>

// 											</div>
// 											<div className="allImgNo">
// 												<img src={edit_aadhar_image_address} />
// 											</div>
// 										</div>
// 									</div>
// 								</div>
// 								<div className="address_type">
// 									<label className="radio-inline">
// 										<label className="radio-inline"><input onChange={() => setEdit_address_addressType(1)} type="radio" name="optradio"
// 											value="1" id="1" checked={edit_address_addressType == 1} /> Home</label>
// 										<label className="radio-inline"><input onChange={() => setEdit_address_addressType(2)} type="radio" name="optradio"
// 											value="2" id="2" checked={edit_address_addressType == 2} /> Office</label>
// 										<label className="radio-inline"><input onChange={() => setEdit_address_addressType(3)} type="radio" name="optradio"
// 											value="3" id="3" checked={edit_address_addressType == 3} /> Other</label>
// 									</label>
// 								</div>
// 								{/* end edit adhar card number */}
// 								<div className="both_buttons">
// 									<button onClick={() => _editAddressApi()} className="btn continue_btns">Update</button>
// 									<button type="submit" className="btn cancel_btns ml-2" onClick={() => _resetEditAddressState()}>Cancel</button>
// 								</div>
// 							</div>
// 							{/* </form> */}
// 						</div>

// 					</div>
// 				</Modal>

// 				<div className={_visiblePromocode ? "modal fade show" : "modal fade"} style={{ display: _visiblePromocode ? '' : 'none' }} id="prodmo_code" role="dialog">
// 					<div className="vertical-alignment-helper">
// 						<div className="SignupModal modal-dialog vertical-align-center">
// 							{/* <!-- Modal content--> */}
// 							<div className="modal-content">
// 								<div className="SignupModalIn">
// 									<div className="SignupModal modal-body clearfix">
// 										<h4>Have a promo code?</h4>
// 										<div className="number_box">
// 											<div className="coupon_input">
// 												<input type="tel" placeholder="Enter promo code here" className="form-control" onChange={e => set_promocode(e.target.value)} />
// 												<button onClick={() => promoCodeApply()} className="next_btn_cop" type="button">Apply</button>
// 											</div>

// 											<div className="coupan_condition">
// 												<ul>
// 													<li>Some promo codes are not applicable on promo cash.</li>
// 													<li>Some promo codes are not valid on purchase of Edible oil, ghee & baby food products.</li>
// 												</ul>
// 											</div>
// 										</div>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 				{/* <!--  promo code end-->

// <!-- Add Cart Modal --> */}
// 				<div className="modal fade" id="addCartModal" role="dialog" aria-labelledby="addCartModal" aria-hidden="true">
// 					<div className="modal-dialog" role="document">
// 						<div className="modal-content">
// 							<div className="modal-body add-cart-box text-center">
// 								<p>You've just added this product to the<br />cart:</p>
// 								<h4 id="productTitle"></h4>
// 								<img src="#" id="productImage" width="100" height="100" alt="adding cart image" />
// 								<div className="btn-actions">
// 									<a href="#"><button className="btn-primary">Go to cart page</button></a>
// 									<a href="#"><button className="btn-primary" data-dismiss="modal">Continue</button></a>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				</div>

// 				{/* update user profile */}
// 				<Modal open={_isProfileDone} onClose={onCloseModalProfile} center>
// 					<div className="editaddressModal  vertical-align-center">
// 						{/* <!-- Modal content--> */}
// 						<div className="adderess_inside">
// 							<h4>Edit Profile</h4>
// 							{/* <p>Please enter the accurate address, it will help us to serve you better</p> */}
// 						</div>
// 						<div className="inside_form_fields">
// 							<form>
// 								<div className="row">
// 									<div className="col-md-6">
// 										{/* <p style={{ fontWeight: 'bold' }}>ID Number<sup className="top_star">*</sup></p> */}
// 										<div className="form-group">
// 											<input
// 												id="_id_number"
// 												type="text"
// 												className="form-control forms_inputs"
// 												placeholder={'Enter your ID Number'}
// 												value={_id_number}
// 												onChange={e => _onChangeTextEditProfile(e)}
// 											/>
// 										</div>
// 									</div>
// 									<div className="col-md-6">
// 										{/* <p style={{ fontWeight: 'bold' }}>Name<sup className="top_star">*</sup></p> */}
// 										<div className="form-group">
// 											<input
// 												id="edited_name"
// 												type="text"
// 												className="form-control forms_inputs"
// 												placeholder={'Enter your name'}
// 												value={edited_name}
// 												onChange={e => _onChangeTextEditProfile(e)}
// 											/>
// 										</div>
// 									</div>
// 									<div className="col-md-6">
// 										{/* <p style={{ fontWeight: 'bold' }}>Email<sup className="top_star">*</sup></p> */}
// 										<div className="form-group">
// 											<input
// 												id="edited_email_id"
// 												type="text"
// 												className="form-control forms_inputs"
// 												onChange={e => _onChangeTextEditProfile(e)}
// 												placeholder={'Enter your email ID'}
// 												value={edited_email_id}
// 											/>
// 										</div>
// 									</div>
// 									<div className="col-md-6">
// 										{/* <p style={{ fontWeight: 'bold' }}>Email<sup className="top_star">*</sup></p> */}
// 										<div className="form-group">
// 											<input
// 												id="edited_name"
// 												type="text"
// 												className="form-control forms_inputs"
// 												// onChange={e => this._onChangeTextEditProfile(e)}
// 												placeholder={'Mobile Number'}
// 												value={number}
// 											/>
// 										</div>
// 									</div>
// 									<div className="col-md-6">
// 										{/* <p style={{ fontWeight: 'bold' }}>Date of birth<sup className="top_star">*</sup></p> */}
// 										<div className="form-group AAAA">
// 											<input
// 												id="edited_dob"
// 												type="date"
// 												className="form-control forms_inputs datepicker"
// 												placeholder={'Select DOB'}
// 												value={edited_dob}
// 												onChange={e => _onChangeTextEditProfile(e)}
// 											/>
// 										</div>
// 									</div>

// 									<div className="col-md-6">
// 										<div className="form-group">
// 											<div className="form-group">
// 												{edited_gender !== undefined ?
// 													<select
// 														id="edited_gender"
// 														onChange={e => _onChangeTextEditProfile(e)}
// 														value={this.state.edited_gender}
// 														className="form-control slect_pst">
// 														<option key={0} value={null}>Gender</option>
// 														<option key={1} value={1}>Male</option>
// 														<option key={2} value={2}>Female</option>
// 														<option key={3} value={3}>Other</option>
// 													</select>
// 													:
// 													<select
// 														id="edited_gender"
// 														onChange={e => _onChangeTextEditProfile(e)}
// 														value={edited_gender == 1 ? 'MALE' : edited_gender == 2 ? 'FEMALE' : gender && gender == 3 ? 'OTHER' : ''}
// 														className="form-control slect_pst">
// 														<option key={0} value={null}>Gender</option>
// 														<option key={1} value={1}>Male</option>
// 														<option key={2} value={2}>Female</option>
// 														<option key={3} value={3}>Other</option>
// 													</select>
// 												}
// 											</div>
// 										</div>
// 									</div>
// 								</div>

// 								<div className="form-footer text-center mt-5">
// 									<div className="form-footer-center edit_profile_f">
// 										<button type="button" onClick={() => _updateUserProfile()} className="btn btn-primary profile_btn" style={{ color: '#fff' }}>Update</button>
// 										{/* <button type="button" className="btn btn-primary profile_btn ml-2" style={{ color: '#fff' }} onClick={() => this.setState({ activeView: ListOfViews.MyProfile })}>Back</button> */}
// 									</div>
// 								</div>
// 							</form>
// 						</div>
// 					</div>
// 				</Modal>

// 			</section>

// 			{/* {paymentModalView()} */}
// 			<div>
// 				{/* <button onClick={() => this.toggleModal()}>Open Modal</button> */}
// 				<Modal
// 					isOpen={paymentModalVisibility}
// 					// onAfterOpen={afterOpenModal}
// 					onRequestClose={() => toggleModal()}
// 					style={customStyles}
// 					contentLabel="Example Modal">

// 					<button type="button" onClick={() => toggleModal()} className="close">×</button>
// 					<div style={{ paddingBottom: '10px' }}>
// 						<h2>Payment Mode</h2>
// 					</div>

// 					<div>
// 						<button
// 							onClick={() => {
// 								toggleModal()
// 								StripeClick()
// 							}}
// 							style={{ width: '100%', backgroundColor: '#64a23f', color: '#fff' }}>
// 							COD
// 						</button>
// 					</div>

// 					<div style={{ height: '25px' }} />

// 					<div>
// 						<button onClick={() => {
// 							toggleModal()
// 							StripeClick()
// 						}} style={{ width: '100%', backgroundColor: '#64a23f', color: '#fff' }}>Prepaid Payment</button>
// 					</div>

// 				</Modal>
// 			</div>

// 		</div>
// 	)

// 	// const paymentModalView = () => {
// 	// 	return (

// 	// 	);
// 	// }
// }

// async function displayPaytm(response, deviceID, props) {

// 	var res = await loadScript()

// 	if (!res) {
// 		alert(' SDK failed to load. Are you online?')
// 		return
// 	}
// 	var config = {
// 		"root": "",
// 		"flow": "DEFAULT",
// 		"data": {
// 			"orderId": response.order_id + '', /* update order id */
// 			"token": response.body.txnToken, /* update token value */
// 			"tokenType": "TXN_TOKEN",
// 			"amount": response.amount + '', /* update amount */
// 			'callbackUrl': 'https://newdemo.myshopwiz.com/order_confirm', /*  */

// 		},
// 		"handler": {
// 			"notifyMerchant": async function (eventName, data) {
// 				if (eventName == 'APP_CLOSED') {

// 					var formData = new FormData()
// 					formData.append('device_id', deviceID)
// 					formData.append('order_id', response.order_id)
// 					var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/check-payment', formData, 'Error => v1/check-referral')

// 					if (res.success == 1) {
// 						Notification('success', 'Success!', res.message)
// 					} else {

// 						Notification('error', 'Error!', '' + res.message)
// 						window.location.href = "/order_cancel"
// 						// this.props.history.push({
// 						// 	pathname: '/order_cancel',
// 						// })
// 						// return(
// 						// 	<Redirect to={{ pathname: `/order_cancel`}} />
// 						// )

// 					}

// 				}
// 			}
// 		}
// 	};

// 	if (window.Paytm && window.Paytm.CheckoutJS) {
// 		window.Paytm.CheckoutJS.onLoad(async function excecuteAfterCompleteLoad() {
// 			window.Paytm.CheckoutJS.init(config).then(async function onSuccess() {
// 				window.Paytm.CheckoutJS.invoke();
// 			}).catch(function onError(error) {
// 			});
// 		});
// 	}

// }

// function loadScript() {

// 	return new Promise(res => {
// 		var myScript = document.createElement('script')
// 		myScript.src = 'https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/Naswiz81262155606177.js'
// 		document.body.appendChild(myScript)
// 		myScript.onload = () => {
// 			res(true)
// 		}
// 		myScript.onerror = () => {
// 			res(false)
// 		}
// 		document.body.appendChild(myScript)
// 	})

// }

// const mapStateToProps = (state) => ({
// 	cartItems: state.cartList.cart,
// 	symbol: '₹ ',
// 	total: getCartTotal(state.cartList.cart),
// 	isUserLogin: state.login.isUserLogin,
// 	cartListArray: state.login.userCartData.records.cart_list,
// 	cartTotalArray: state.login.userCartData.records.total,
// 	userData: state.login.userData
// })

// export default connect(mapStateToProps, { removeFromWishlist, getCartData, addUserData, changeLoginState, manageUserIdState, })(checkOut)

// // MODAL CODE
// const customStyles = {
// 	content: {
// 		top: '50%',
// 		left: '50%',
// 		right: 'auto',
// 		bottom: 'auto',
// 		marginRight: '-50%',
// 		transform: 'translate(-50%, -50%)'
// 	}
// };
