import {
    RECEIVE_SINGLE_DETAILS
} from "../constants/ActionTypes";


const initialState = {
    details: {},
};

const singleDetailsReducer = (state = initialState, action) => {

    switch (action.type) {
        case RECEIVE_SINGLE_DETAILS:
            return {
                ...state,
                details: action.details
            };
        

        
        default:
            return state;
    }
};
export default singleDetailsReducer;