import shop from '../api/shop'
import * as types from '../constants/ActionTypes'
import store from "../store";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { createContext } from 'react';
import { base_url, AUTH_BEARER } from '../helper'
import { NaswizHelperApi } from '../NaswizHelper'

/**
 * 
 * @NASWIZ_START
 */


/** 1 HOME API GET & SET */

export const receiveNaswizGetAllHomeData = getHomeData => ({
    type: types.GET_HOME_DATA,
    getHomeData
})

export const getAllHomeData = (trailUrl, errorMsg) => async dispatch => {
    dispatch(receiveNaswizGetAllHomeData(await NaswizHelperApi.GET_WITHOUT_TOKEN(trailUrl, errorMsg)))
}

export const changeLoginState = (value) => ({
    type: types.CHANGE_LOGIN_STATE,
    value
});

export const manageUserIdState = (userID) => ({
    type: types.MANAGE_USER_ID_STATE,
    userID
})

export const removeUserData = () => ({
    type: types.REMOVE_USER_DATA
})

export const addUserData = userDataObject => ({
    type: types.ADD_USER_DATA,
    userDataObject
})

export const addItemIntoCart = (cartItem) => ({
    type: types.ADD_ITEM_INTO_CART,
    cartItem
})

export const removeItemFromCart = (item) => ({
    type: types.REMOVE_ITEM_FROM_CART,
    item
})

// Wishlist data
export const setWishlistData = wishListDataObject => ({
    type: types.GET_WISHLIST_DATA_FROM_API,
    wishListDataObject
})

export const getWishlistData = (trailUrl, errorMsg) => async dispatch => {
    dispatch(setWishlistData(await NaswizHelperApi.POST_WITH_TOKEN(trailUrl, errorMsg)))
    const res = await NaswizHelperApi.POST_WITH_TOKEN(trailUrl, errorMsg)
    return res;
}

// cart data
export const setCartData = cartDataObject => ({
    type: types.GET_CART_DATA_FROM_API,
    cartDataObject
})

export const getCartData = (trailUrl, body, errorMsg) => async dispatch => {
    dispatch(setCartData(await NaswizHelperApi.POST_WITH_TOKEN(trailUrl, body, errorMsg)))
}

export const getCartDataWithoutLogin = (trailUrl, body, errorMsg) => async dispatch => {
    dispatch(setCartData(await NaswizHelperApi.POST_WITHOUT_TOKEN(trailUrl, body, errorMsg)))
}

// Set Master Categoty List Data 
export const setMasterCategoryList = data => ({
    type: types.SET_MASTER_CATEGORY_LIST_DATA,
    data
})

// Set All Master Categoty List Data
export const setAllMasterCategoryList = data => ({
    type: types.SET_ALL_MASTER_CATEGORY_LIST_DATA,
    data
})
// Categoyr Date On Hover 
export const setCategoryDataOnHover = data => ({
    type: types.CATEGORY_DATA_ON_HOVER,
    data
})

// }

/** 2  GET & SET */



/**
 * 
* @NASWIZ_END
*/

export const fetchProductsBegin = () => ({
    type: types.FETCH_PRODUCTS_BEGIN
});

export const receiveProducts = products => ({
    type: types.RECEIVE_PRODUCTS,
    products
})

export const receiveSlider = sliders => ({
    type: types.RECEIVE_SLIDER,
    sliders
})

export const receiveTestimonials = testimonials => ({
    type: types.RECEIVE_TESTIMONIALS,
    testimonials
})

export const receiveCategories = categories => ({
    type: types.RECEIVE_CATEGORIES,
    categories
})

export const receiveDetails = details => ({
    type: types.RECEIVE_SINGLE_DETAILS,
    details
})

export const receiveProductsBySlug = productslist => ({
    type: types.RECEIVE_PRODUCTBY_SLUG,
    productslist
})
export const receiveFilterBySlug = filterlist => ({
    type: types.RECEIVE_FILTERLIST_SLUG,
    filterlist
})
export const receiveRegisterResponse = registerdata => ({
    type: types.RECEIVE_REGISTER,
    registerdata
})
export const receiveLoginResponse = logindata => ({
    type: types.RECEIVE_LOGIN,
    logindata
})

export const getAllProducts = () => dispatch => {

    // dispatch(fetchProductsBegin());
    // shop.getProducts(products => {

    //     dispatch(receiveProducts(products.product));
    //     dispatch(receiveSlider(products.slider));
    //     return products.product;
    // })
}

export const getAllSliders = () => dispatch => {

    // dispatch(fetchProductsBegin());
    shop.getProducts(products => {

        dispatch(receiveSlider(products.slider));
        return products.slider;
    })
}

export const getAllTestimonials = () => dispatch => {

    // dispatch(fetchProductsBegin());
    shop.getProducts(products => {
        dispatch(receiveTestimonials(products.testinomial));
        return products.testinomial;
    })
}

export const getAllCategories = () => dispatch => {

    dispatch(fetchProductsBegin());
    shop.getCategories(categories => {
        dispatch(receiveCategories(categories));
        return categories;
    })
}

export const getProductDetails = productSlug => dispatch => {
    getProductDetailsApi(productSlug).then((response) => {
        dispatch(receiveDetails(response));

    })
}
export const getProductBySlug = (productSlug, commaSepratedValues) => dispatch => {
    // dispatch(fetchProductsBegin());
    getProductsBySlugApi(productSlug, commaSepratedValues).then((response) => {
        dispatch(receiveProductsBySlug(response));

    })
}

export const getFilterBySlug = productSlug => dispatch => {
    // dispatch(fetchProductsBegin());
    getFilterListBySlugApi(productSlug).then((response) => {
        dispatch(receiveFilterBySlug(response));

    })
}

// register
export const setRegister = paramsJson => dispatch => {
    // dispatch(fetchProductsBegin());
    getRegsterResponse(paramsJson).then((response) => {
        dispatch(receiveRegisterResponse(response));

    })
}

//login api call

// register
export const setLogin = paramsJson => dispatch => {
    getLoginResponse(paramsJson).then((response) => {
        dispatch(receiveLoginResponse(response));

    })
}
export const fetchSingleProduct = productId => ({
    type: types.FETCH_SINGLE_PRODUCT,
    productId
})




//it seems that I should probably use this as the basis for "Cart"
export const addToCart = (product, qty) => (dispatch) => {
    toast.success("Item Added to Cart", product);
    dispatch(addToCartUnsafe(product, qty))

}
export const addToCartAndRemoveWishlist = (product, qty) => (dispatch) => {
    toast.success("Item Added to Cart");
    dispatch(addToCartUnsafe(product, qty));
    dispatch(removeFromWishlist(product));
}
export const addToCartUnsafe = (product, qty) => ({
    type: types.ADD_TO_CART,
    product,
    qty
});
export const removeFromCart = product_id => (dispatch) => {
    toast.error("Item Removed from Cart");
    dispatch({
        type: types.REMOVE_FROM_CART,
        product_id
    })
};
export const incrementQty = (product, qty) => (dispatch) => {
    toast.success("Item Added to Cart");
    dispatch(addToCartUnsafe(product, qty))

}
export const decrementQty = productId => (dispatch) => {
    toast.warn("Item Decrement Qty to Cart");

    dispatch({
        type: types.DECREMENT_QTY,
        productId
    })
};



//it seems that I should probably use this as the basis for "Wishlist"
export const addToWishlist = (product) => (dispatch) => {
    toast.success("Item Added to Wishlist");
    dispatch(addToWishlistUnsafe(product))

}
export const addToWishlistUnsafe = (product) => ({
    type: types.ADD_TO_WISHLIST,
    product
});
export const removeFromWishlist = product_id => (dispatch) => {
    toast.error("Item Removed from Wishlist");
    dispatch({
        type: types.REMOVE_FROM_WISHLIST,
        product_id
    })
};


//Compare Products
export const addToCompare = (product) => (dispatch) => {
    toast.success("Item Added to Compare");
    dispatch(addToCompareUnsafe(product))

}
export const addToCompareUnsafe = (product) => ({
    type: types.ADD_TO_COMPARE,
    product
});
export const removeFromCompare = product_id => ({
    type: types.REMOVE_FROM_COMPARE,
    product_id
});


// Filters
export const filterBrand = (brand) => ({
    type: types.FILTER_BRAND,
    brand
});
export const filterColor = (color) => ({
    type: types.FILTER_COLOR,
    color
});
export const filterPrice = (value) => ({
    type: types.FILTER_PRICE,
    value
});
export const filterSort = (sort_by) => ({
    type: types.SORT_BY,
    sort_by
});


// Currency
export const changeCurrency = (symbol) => ({
    type: types.CHANGE_CURRENCY,
    symbol
});




async function getProductDetailsApi(productSlug) {

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/x.shrayati.v1+json");
    myHeaders.append("Authorization", 'Bearer ' + AUTH_BEARER);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    var res = await fetch(`${base_url}v1/new/` + productSlug, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => console.log('error', error));
    return res

}

// get product list by slug
async function getProductsBySlugApi(productSlug, commaSepratedValues) {


    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/x.shrayati.v1+json");
    myHeaders.append("Authorization", 'Bearer ' + AUTH_BEARER);



    var opts = { "page": 1 }
    var formData = new FormData()
    formData.append('design', commaSepratedValues)


    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: commaSepratedValues ? formData : JSON.stringify(opts)


    };

    var res = await fetch(`${base_url}v1/product/` + productSlug, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => console.log('error', error));
    return res

}

// register api call
async function getRegsterResponse(paramsJson) {

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/x.shrayati.v1+json");

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: paramsJson,
        redirect: 'follow'


    };

    var res = await fetch(`${base_url}v1/register`, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => console.log('error', error));


    return res

}


// login api call
async function getLoginResponse(paramsJson) {

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/x.shrayati.v1+json");

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: paramsJson,
        redirect: 'follow'


    };

    var res = await fetch(`${base_url}v1/login`, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => console.log('error', error));


    return res

}

// fliter list by slug

async function getFilterListBySlugApi(productSlug) {

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/x.shrayati.v1+json");
    myHeaders.append("Authorization", 'Bearer ' + AUTH_BEARER);

    var opts = {



    }
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify(opts)


    };

    var res = await fetch(`${base_url}v1/product-filter/` + productSlug, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => console.log('error', error));
    return res

}



/**
 * @NASWIZ_API_FUNCTIONS_STARTS
 */




/**
* @NASWIZ_API_FUNCTIONS_ENDS
*/