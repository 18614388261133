import React, { Component } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import Moment from 'moment';

const BlogSlider = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
            }
        },
        {
            breakpoint: 1100,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
            }
        },
    ]
}
//import { Slider4 } from "../../../services/script"


class BlogSection extends Component {
    render() {

        const { blogsliderdata } = this.props;

        return (

            <div className="blogslider-content">
                <Slider className="" {...BlogSlider}>
                {                    
                    blogsliderdata && blogsliderdata.length > 0 ?
                        blogsliderdata.map((item, index) => {
                            return(
                                <article key={index} className="post" style={{position: 'relative'}}>
                                    <div className="post-media" style={{backgroundColor: '#fff'}}>
                                        <Link to={`${process.env.PUBLIC_URL}/blog/details`} >
                                            <img src={item.image_full_path ? item.image_full_path : `${process.env.PUBLIC_URL}/assets/images/nia.png`} alt="Post" width="225" height="280" />
                                        </Link>
                                        <div className="post-date">
                                            {/* <span className="day">{Moment(item.created_at).format('d MMM yyyy')}</span> */}
                                            <span className="day">{Moment(item.created_at).format('d')}</span>
                                            <span className="month">{Moment(item.created_at).format('MMM yyyy')}</span>
                                        </div>
                                    </div>
                                    <div className="post-body">
                                        <h2 className="post-title">
                                            <Link to={`${process.env.PUBLIC_URL}/blog/details`} >{item.name}</Link>
                                        </h2>
                                        <div className="post-content">
                                            <p>{item.description}</p>
                                        </div>
                                        <Link className="post-comment" to={`${process.env.PUBLIC_URL}/blog/details`} >0 Comments</Link>
                                    </div>
                                </article>
                            )
                        })
                        : null                        
                }
                </Slider>
            </div>
        )
    }
}

export default BlogSection;