import React, { Component } from 'react';
import Slider from 'react-slick';
// import about_us from '../../../public/assets/images/about_us.png'
import Breadcrumb from "../common/breadcrumb";
import { Slider2, Team4 } from "../../services/script"
import { NaswizHelperApi } from '../../NaswizHelper';
// import './about_us.css'

class Page extends Component {

    constructor(props) {
        super(props)
        this.state = {
            slugs: props.match.params.page || 'unknown',
            arr_topbar_cms: []
        } 
    }

    async componentDidMount() {

        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000); 
    }

    render() {

        var { arr_topbar_cms } = this.state

        return (
            <div className="page_details">
                {
                    arr_topbar_cms && arr_topbar_cms.length > 0 ?
                        arr_topbar_cms.map((top, index) => {
                            return(
                                <div key={index}>
                                    {
                                        top.slug === this.state.slugs ?                                        
                                        <div>
                                            <h1 className="page_title">{top.subject}</h1>
                                            <div className="page_description">{top.description}</div>
                                        </div>
                                        :null
                                    }
                                </div>
                            )
                        })
                    : null
                }
            </div>
        )
    }
}

export default Page


const appColor = '#63A23F'