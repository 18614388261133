import {
    RECEIVE_TESTIMONIALS
} from "../constants/ActionTypes";


const initialState = {
    testimonials: [],
};

const testimonialReducer = (state = initialState, action) => {

    switch (action.type) {

        case RECEIVE_TESTIMONIALS:
            return {
                ...state,
                testimonials: action.testimonials
            };

        default:
            return state;
    }
};
export default testimonialReducer;