import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'
import Modal from 'react-responsive-modal';
import { NaswizHelperApi } from '../../../NaswizHelper';
import Breadcrumb from '../../common/breadcrumb';
import Seo from '../../Seo';

class NaswizDownloads extends Component {
   constructor(props) {
      super(props);
      this.state = {
         open: false,
         downloads: '',
         uniueId: ''
      }
   }
   async componentDidMount() {
      var uniueId = await localStorage.getItem('deviceId')
      await this.setState({ uniueId: uniueId })
      await this.naswizGetHomeDataApi()

   }
   naswizGetHomeDataApi = async () => {

      var url = 'v1/get-home/' + this.state.uniueId + '/0'

      var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(url, 'Error => v1/get-home')
      if (res && res.success == 1) {
         this.setState({
            downloads: res.records.downloads
         })
      }
      else {
         this.setState({
            loading_getHomeData: false
         })
      }
   }

   render() {
      const { translate, _data, top_bar_cms, social_arr } = this.props;
      return (
         <div>
            {/* <Helmet>
            <title>Naswiz Downloads  - Naswiz Retails Pvt Ltd</title>
            <meta name="og_title" property="og:title" content="Naswiz Downloads  - Naswiz Retails Pvt Ltd" />
            <meta name="twitter:title" content="Naswiz Downloads  - Naswiz Retails Pvt Ltd" />
            <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
            <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
            <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
            <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />		
            </Helmet> */}
            <Seo title="Naswiz Downloads" description="Naswiz Downloads  - Naswiz Retails Pvt Ltd" />
            <Breadcrumb title={'Naswiz Downloads'} />

            <section className="naswiz-download-wrapper">
               <div className="container">

                  <div className="cms-page-title mb-30">
                     <h2>Naswiz Downloads</h2>
                  </div>

                  <div className="row mt-4">
                     {
                        this.state.downloads.length > 0 ?
                           this.state.downloads.map((item, index) => {
                              return (
                                 <div className="col-md-4">
                                    <div className="download-box text-center border">
                                       <div className="download-icon d-flex align-items-center justify-content-center">
                                          <i className="far d-flex justify-content-center"><img src={item.icon_full_path} alt="" className="img-fluid" /></i>
                                       </div>
                                       <div className="download-content">
                                          <h5>{item.name}</h5>
                                          <a href={item.image} target="_blank" className="theme-button text-uppercase border-0">Download</a>
                                       </div>
                                    </div>
                                 </div>
                              )
                           }) : null
                     }
                  </div>


               </div>
            </section>

         </div>
      )
   }

}

export default withTranslate(NaswizDownloads);
