import React, { Component } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { getTrendingCollection } from '../../../services/index'
import { Product4, Product5 } from '../../../services/script'
import { addToCart, addToWishlist, addToCompare } from "../../../actions/index";

const CategorySlider = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay:true,
    autoplaySpeed:2500,
    slidesToShow: 5,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows : false,
    responsive: [
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                arrows : false,
            }
        },
        {
            breakpoint: 1100,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                arrows : false,
            }
        },
    ]
}

class Category extends Component {

    render() {

        const { category, items, symbol, addToCart, addToWishlist, addToCompare, type } = this.props;     

        var properties;
        if (type === 'kids') {
            properties = Product5
        } else {
            properties = Product4
        }
        return (

            <div className="CategorySlider-content">
                <Slider className="show-nav-hover nav-outer" {...CategorySlider}>

                    {
                        category && category.length > 0 ?
                            category.map((product, index) =>
                                <div key={index}>
                                    <div className="product-category">
                                        <Link to={{ pathname: `${process.env.PUBLIC_URL}/Products/${product.slug}`,state: [] }}>
                                            <figure>
                                                <img src={product.image_full_path ? product.image_full_path : `${process.env.PUBLIC_URL}/assets/images/products/categories/alkaline-ionizer.jpg`} alt="category" />
                                            </figure>
                                            <div className="category-content">
                                                <h3>{product.name}</h3>
                                                <span><mark className="count">{product.total_product}</mark> products</span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                            )
                            : null
                    }
                </Slider>
            </div>

        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    items: getTrendingCollection(state.data.products, ownProps.type),
    symbol: state.data.symbol
})

export default connect(mapStateToProps, { addToCart, addToWishlist, addToCompare })(Category);