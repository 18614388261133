import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import { Link } from 'react-router-dom';

class FashionTrends extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        return (
            <div className="blog_page">
                <Breadcrumb title={'Fashion Trends'} />

                {/*Blog Details section*/}
                <section className="blog-details-wrapper allCheckout pb-4">
                    <div className="container">

                        <div className="text-center">
                            <div className="cms-page-title mb-3 line-effect position-relative">
                                <h2 className="d-inline bg-white pl-3 pr-3">FASHION TRENDS</h2>
                            </div>
                            <p>If you are clear on your fashion goals and are yet to strike that pose, get a hold on Naswiz’s latest collection Female Apparels that are surely going to win your heart but still won’t burn a hole in the pocket. Rocking the runway for a while, Naswiz designs are everyone’s favourite trend. Embrace some Work and causal wear from Naswiz into your wardrobes.</p>
                            <img src="assets/images/blog/female-appareles.png" className="img-fluid mt-3 mb-3" />
                        </div>

                        <div className="blog-inside pt-30 pb-30">
                            <div className="listing-content">
                                <h4>Female Formals - Bars Essentials</h4>
                                <p>Naswiz presents a whole lot of collection in the formals, from the basics to the chicest of chic; you get to choose that what makes your style.</p>
                            </div>
                            <div className="row mt-md-4 mb-md-4 align-items-center">
                                <div className="col-md-7">
                                    <div className="blog-imgarea position-relative left">
                                        <img src="assets/images/blog/bare-essentials.png" alt="bare-essentials-img" className="pb-1 img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="blog-inner-wrapper">
                                        <span className="sub-heading position-relative text-capitalize text-theme-primary">Less Is More</span>
                                        <blockquote className="font-italic">“Every wardrobe needs at least one pair of classic trouser and some formal shirts that are versatile enough to suit a variety of office settings.” These trousers and shirts are the perfect go-to, especially on days when you want to skip the thoughts of choosing your outfit, still want to look professional.”</blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="blog-inside pt-30 pb-30">
                            <div className="listing-content">
                                <h4>Female Dresses-Friending Fabulous</h4>
                                <p>Naswiz pioneers an incredible collection of digital and graphic prints, solids, textures all in the latest designs, which celebrates Individualistic style and confidence. Home some of our styles today.</p>
                            </div>
                            <div className="row mt-md-4 mb-md-4 align-items-center">
                                <div className="col-md-6 order-md-12">
                                    <div className="blog-imgarea position-relative right">
                                        <img src="assets/images/blog/friending-fabulous.png" alt="friending-fabulous-img" className="pb-1 img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-6 order-md-1">
                                    <div className="blog-inner-wrapper">
                                        <span className="sub-heading position-relative text-capitalize text-theme-primary">Bold Is Beautiful</span>
                                        <blockquote className="font-italic">“ I like my dresses to have some drama and contrast. At the same time, they should belong classic, serve me right, during the day or the night” Up to set some flash with a high-value contrast style? Then these picks shall work best for formal and semi-formal looks! Naswiz lets you make statements day after day, some bolder, some stronger. Strike some envious poses in that formal look, swaying all that in some confidence.”</blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="blog-inside pt-30 pb-30">
                            <div className="listing-content">
                                <h4>Naswiz Female Tops - Chic Wish</h4>
                                <p>Let that be shopping, a football match screening or a happy hour with some friends, get ready to flaunt that niche and preppy look with Naswiz’s range of styles. Fill your wardrobe with a series of basics that are still not basic but a splash of elegant colours all together that ensure your glam stays up all night from Naswiz.</p>
                            </div>
                            <div className="row mt-md-4 mb-md-4 align-items-center">
                                <div className="col-md-6">
                                    <div className="blog-imgarea position-relative left">
                                        <img src="assets/images/blog/chic-wish.png" alt="chic-wish-img" className="pb-1 img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="blog-inner-wrapper">
                                        <span className="sub-heading position-relative text-capitalize text-theme-primary">Print Me Pretty</span>
                                        <blockquote className="font-italic">“You get the hallmark of style not when you wear an outfit, but only when you style it right. Tuck-in some Stylish tops into denim skirts, layer them or style the singlet over wide-leg culottes, you are now a star!” Get them for the beach, the movies, the office, the plane or lunch; an ode to everyday wears. Street style stars, bloggers, and celebrities know that these Naswiz tops can look every bit as chic as—and actually, probably a thousand times cooler than—any carefully coordinated designer outfit. Get your bit from Naswiz.”</blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="blog-inside pt-30 pb-30">
                            <div className="listing-content">
                                <h4>Casual Wear - Basic Bests</h4>
                                <p>"Solids, Graphic prints, or Normal Tees are the very reflections of your personal style. It’s a choice we make deliberately and consciously unlike following a classic trend."</p>
                                <p>Get addicted to this stylish stuff.</p>
                            </div>
                            <div className="row mt-md-4 mb-md-4 align-items-center">
                                <div className="col-md-7 order-md-12">
                                    <div className="blog-imgarea position-relative right">
                                        <img src="assets/images/blog/casual-wear.png" alt="casual-wear-img" className="pb-1 img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-5 order-md-1">
                                    <div className="blog-inner-wrapper">
                                        <span className="sub-heading position-relative text-capitalize text-theme-primary">Monochrome Madness</span>
                                        <blockquote className="font-italic">“Set some right style notes and create some flauntable outfits from Naswiz’s collection of Casual wear. These super comfy styles can make you ready for any occasion.”</blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="blog-inside pt-30 pb-30">
                            <div className="listing-content">
                                <h4>Denims - Eat. Sleep. Denim</h4>
                                <p>Denim is that casual wardrobe fixture that’s been reinvented endlessly and is one of those overwhelming outfit staples that can ever be celebrated.</p>
                                <p>The perfect jeans can feel just next to impossible to find, but luckily, there are more options than ever at Naswiz depending on the cut, fit, length, and wash you prefer.</p>
                            </div>
                            <div className="row mt-md-4 mb-md-4 align-items-center">
                                <div className="col-md-7">
                                    <div className="blog-imgarea position-relative left">
                                    <img src="assets/images/blog/denims.png" alt="denims-img" className="pb-1 img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="blog-inner-wrapper">
                                        <span className="sub-heading position-relative text-capitalize text-theme-primary">Denimology</span>
                                        <blockquote className="font-italic">“Highly durable and working out in all type of weathers alike as compared to other fabrics; Jeans is the most popular casual wear. It is worn by both genders of any age group due to its comfort and low maintenance. Look no further if you were searching for the ‘made-for-me’ pair of jeans that will look equally chic while dressing it up or dressing it down. Choosing the right pair of jeans can indeed be tricky but why not attempt this with someone who has it all? Naswiz presents the denim collection, something of the sort that you would have never seen before! Slim fits, skinny fits, flared fits, you name it and you will find it with us! With a wide range of washes, choose the one that gives you the power to slay. Rinse wash, jet-black wash, enzyme wash, bleach grey was, towel full bleach wash, dark blue to sea blue to tint blue wash, even carbon blue wash, the list is never ending though! This particular style is trending on the street style scene and makes a statement.”</blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section >

            </div>
        )
    }
}

export default FashionTrends