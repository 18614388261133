import {
    RECEIVE_PRODUCTBY_SLUG
} from "../constants/ActionTypes";


const initialState = {
    productslist: {},
};

const productListReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_PRODUCTBY_SLUG:
            return {
                ...state,
                productslist: action.productslist
            };
        default:
            return state;
    }
};
export default productListReducer;