import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import Slider from 'react-slick';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { addToCart, addToCartUnsafe, addToWishlist, removeFromWishlist, getProductDetails, manageUserIdState, changeLoginState, getCartData, getCartDataWithoutLogin } from '../../actions'
import Service from "./common/service";
import BrandBlock from "./common/brand-block";
import RealatedProducts from "../common/realated-products";
import Breadcrumb from "../common/breadcrumb";
import DetailsWithPrice from "./common/product/details-price";
import DetailsTopTabs from "./common/details-top-tabs";
import ImageZoom from './common/product/image-zoom'
import SmallImages from './common/product/small-image'
import renderHTML from 'react-render-html';
import { BASE_URL, RUPEE_SYMBOL, NaswizHelperApi, MAIN_URL } from '../../NaswizHelper';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css'; 
import { Row, Tooltip, Col, Checkbox, Icon, Progress, Input, Tag, Select, Rate } from 'antd';
import 'antd/dist/antd.css';
import { SideBySideMagnifier, } from "react-image-magnifiers";
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import '../../new_components/productslisting.css';
import ReactReadMoreReadLess from "react-read-more-read-less";
import Notification from '../../Notification'
import SliderImage from 'react-zoom-slider';

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton, WhatsappIcon
} from "react-share";
const colorStyle = { border: '1px solid #ff7272' }


const ProductsSlider = {
    loop: true,
    autoplay: false,
    dots: false,
    nav: true,
    navText: ['<i className="icon-angle-left">', '<i className="icon-angle-right">'],
    margin: 0,
    items: 1,
}

const RelatedSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 586,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

const testImages = [{
    'normalImg': 'https://myshopwiz.com/master-naswiz//storage/uploads/product/202105220653511145008HQX7.jpg',
    'bigImg': 'https://myshopwiz.com/master-naswiz//storage/uploads/product/202105220653511145008HQX7.jpg'
},
{
    'normalImg': 'https://myshopwiz.com/master-naswiz//storage/uploads/product/202105261041119896006u7dZ.jpg',
    'bigImg': 'https://myshopwiz.com/master-naswiz//storage/uploads/product/202105261041119896006u7dZ.jpg'
},
{
    'normalImg': 'https://myshopwiz.com/master-naswiz//storage/uploads/product/20210629045734285800TFUVP.jpg',
    'bigImg': 'https://myshopwiz.com/master-naswiz//storage/uploads/product/20210629045734285800TFUVP.jpg'
},
{
    'normalImg': 'https://myshopwiz.com/master-naswiz//storage/uploads/product/20210521082500011100J6aor.jpg',
    'bigImg': 'https://myshopwiz.com/master-naswiz//storage/uploads/product/20210521082500011100J6aor.jpg'
},
]

class SliderPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            slugs: props.match.params.type || 'unknown',
            // _breadcrum: props.location.state,
            _breadcrum: [],
            open: false,
            nav1: null,
            nav2: null,
            quicknav1: null,
            quicknav2: null,
            loading_detail: false,
            product_detail: [],
            related_record: [],
            product_rating: 0,
            product_review: '',
            reviewer_name: '',
            reviewer_email: '',
            product_id: null,
            quick_product_detail: [],
            quick_related_record: [],
            quick_product_id: null,
            quickviewModalVisibility: false,
            selectedColorId: 0,
            selectedColorIndex: null,
            selectedSizeId: null,
            selectedSizeIndex: null,
            value: 0,
            valuemodal: 1,
            isSizeSelected: false,
            notSelectedText: '',
            isColorSelected: false,
            colorNotSelectText: '',
            isLoing: '',
            uniueId: '',
            _wishlist: 0,
            _readMore: false,
            _leftQty: 0,
            _notifyQty: 0,
            _shippingcode: false,
            _current_Url: '',
            _product_description: '',
            _product_description2: '',
            _cartItem: [],
            _product_type: '',
            _stateData: [],
            msgRating: '',
            _isBtnEnble: true,
            _isReviewShow: false,
            _productName: false,
            _maxQty: 0,
            _leftUsedQty: 0


        }
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.location.state != '' && nextProps.location.state.length > 0) {
            if (nextProps.location.state[0].color != 'No') {
                await this.setState({ selectedColorId: nextProps.location.state[0].color_id, selectedColorIndex: 0, isColorSelected: true, colorNotSelectText: 'Color Selected' })

            }
            if (nextProps.location.state[0].size != 'No') {
                await this.setState({ selectedSizeId: nextProps.location.state[0].size_id, selectedSizeIndex: 0, isSizeSelected: true, notSelectedText: 'Size Selected' })

            }
            // await this.setState({ selectedColorId: nextProps.location.state[0].color,selectedSizeId:nextProps.location.state[0].size})



        }

        await this.setState({ slugs: nextProps.match.params.type, _current_Url: window.location.href, })
        await this.productDetailApiWithSlug(nextProps.match.params.type, 'Did mount');
    }

    async componentDidMount() {

        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
            quicknav1: this.quickslider1,
            quicknav2: this.quickslider2
        });
        var _sym = 'abcdefghijklmnopqrstuvwxyz1234567890';
        var str = '';

        for (var i = 0; i < 20; i++) {
            str += _sym[parseInt(Math.random() * (_sym.length))];
        }
        var uniueId = await localStorage.getItem('deviceId')

        if (uniueId === null) {
            await localStorage.setItem('deviceId', str)


        }
        var isLoing = localStorage.getItem('is_user_login')
        var uniueId = localStorage.getItem('deviceId')
        await this.setState({ isLoing: isLoing, uniueId: uniueId, _current_Url: window.location.href })

        if (this.props.location.state != '' && this.props.location.state != undefined) {
            if (this.props.location.state.length > 0) {

                //   await this.setState({ selectedColorId: this.props.location.state[0].color,selectedSizeId:this.props.location.state[0].size})
                if (this.props.location.state[0].color != 'No') {
                    await this.setState({ selectedColorId: this.props.location.state[0].color_id, selectedColorIndex: 0, isColorSelected: true, colorNotSelectText: 'Color Selected' })

                }
                if (this.props.location.state[0].size != 'No') {
                    await this.setState({ selectedSizeId: this.props.location.state[0].size_id, selectedSizeIndex: 0, isSizeSelected: true, notSelectedText: 'Size Selected' })

                }
            }

        }
        await this.productDetailApiWithSlug(this.state.slugs, 'Did mount');
        /* document.getElementById("common").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/common.css`);*/


    }

    decrease = (dataImage) => {
        if (this.state.value > 1) {
            if (this.props.isUserLogin) {
                this._addToCart(dataImage, 2)

            } else {
                this._addToCartWithoutLogin(dataImage, 2)

                // }
            }
        }
    }

    increase = async (dataImage) => {
        if (this.props.isUserLogin) {
            this._addToCart(dataImage, 1)

        } else {
            this._addToCartWithoutLogin(dataImage, 1)

            // }
        }
    }
    decreasemodal = () => {
        this.setState({ valuemodal: this.state.valuemodal - 1 });
    }

    increasemodal = () => {
        this.setState({ valuemodal: this.state.valuemodal + 1 });
    }

    productDetailApiWithSlug = (slug, from) => {

        var userID = this.props.userID        // var url = userID ? `v1/product-detail/${slug}/${userID}` : `v1/product-detail/${slug}/${this.state.uniueId}`
        var url = userID ? `v1/product-detail/${slug}/${this.state.uniueId}/${userID}` : `v1/product-detail/${slug}/${this.state.uniueId}/0`


        this.setState({ loading_detail: true }, async () => {

            var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(url, 'Error => products details with slug api.')

            if (res && res.success == 1) {

                this.setState({
                    product_detail: res.records,
                    related_record: res.related_product,
                    product_id: res.records.product_id,
                    _wishlist: res.records.wishlist,
                    _notifyQty: res.records.notify,
                    _cartItem: res.records.cart,
                    _product_type: res.records.product_type,
                    _breadcrum: res.records.breadcrumb_list,
                    _productName: res.records.name


                })
                if (res.records.reviewrating_box == 1) {
                    this.setState({ _isReviewShow: true })
                }
                var myHTML = res.records.description

                var strippedHtml = myHTML.replace(/<[^>]+>/g, '');

                // Jimbo.
                // That's what she said
                if (
                    res.records.description.length > 150
                ) {
                    this.setState({ _product_description: res.records.description.substring(0, 150), _product_description2: res.records.description })
                } else {
                    this.setState({ _product_description: res.records.description, _product_description2: res.records.description })
                }

                if (this.state.selectedColorId == '' || this.state.selectedColorId == 0 || this.state.selectedColorId == null) {

                    if (res.records.details.length > 0 && (res.records.product_type == '3' || res.records.product_type == '4')) {
                        var isBtnEnbl = res.records.details[0].inventory - res.records.details[0].used

                        await this.setState({ _leftUsedQty: isBtnEnbl, _maxQty: res.records.details[0].max_order_qty, selectedColorId: res.records.details[0].color_id, selectedColorIndex: 0, isColorSelected: true, colorNotSelectText: 'Color Selected' })
                        //    check stock 0 then set btn disable
                        if (isBtnEnbl == 0) {
                            this.setState({ _isBtnEnble: false })
                        }

                    }
                }

                if (this.state.selectedSizeId == '' || this.state.selectedSizeId == 0 || this.state.selectedSizeId == null) {
                    if (res.records.details.length > 0 && (res.records.product_type == '2' || res.records.product_type == '4')) {
                        var isBtnEnbl = res.records.details[0].inventory - res.records.details[0].used
                        await this.setState({ _leftUsedQty: isBtnEnbl, _maxQty: res.records.details[0].max_order_qty, selectedSizeId: res.records.details[0].size_id, selectedSizeIndex: 0, isSizeSelected: true, notSelectedText: 'Size Selected' })
                        if (isBtnEnbl == 0) {
                            this.setState({ _isBtnEnble: false })
                        }
                    }
                }

                this.checkCartItem(this.state.selectedColorId, this.state.selectedSizeId)

                if (from === 'inner') {
                    this.TopRef.scrollIntoView({ behavior: 'auto', block: 'start' })
                }
            }
            else {
                this.setState({
                    loading_detail: false
                })
            }

        })


    }


    _quickView = (slug_quick) => {
        var userID = this.props.userID
        // var url = userID ? `v1/product-detail/${slug_quick}/${userID}` : `v1/product-detail/${slug_quick}/0`
        var url = userID ? `v1/product-detail/${slug_quick}/${this.state.uniueId}/${userID}` : `v1/product-detail/${slug_quick}/${this.state.uniueId}/0`

        this.setState({ loading_detail: true }, async () => {

            var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(url, 'Error => products details with slug api.')

            if (res && res.success == 1) {

                this.setState({
                    quick_product_detail: res.records,
                    quick_related_record: res.related_product,
                    quick_product_id: res.records.product_id
                })

                this.setState({ quickviewModalVisibility: true });
            }
            else {
                this.setState({ quickviewModalVisibility: false });
                this.setState({
                    loading_detail: false
                })
            }

        })
    }


    filterClick() {
        document.getElementById("filter").style.left = "-15px";
    }
    backClick() {
        document.getElementById("filter").style.left = "-365px";
    }

    submitReview = async () => {

        if (this.props.isUserLogin) {
            var { reviewer_email, reviewer_name, product_review, product_rating } = this.state
            const emailValidate = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (product_rating === 0) {
                // alert('Enter Rating')
            }
            else if (product_review === '') {
                // alert('Enter Review')
            }
            else {
                var body = new FormData()
                body.append('product_id', this.state.product_id)
                body.append('rating', product_rating)
                body.append('review', product_review)



                // var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/add-reviewrating', body, "Error => Submit user review api.")
                await NaswizHelperApi.POST_WITH_TOKEN('v1/add-reviewrating', body, "Error => Submit user review api.")
                    .then(async res => {


                        if (res && res.success == 1) {
                            await this.setState({ msgRating: res.message })
                        }
                        else {
                            if (res.message == "You are not Authorized to give Review & Rating") {
                                // alert("You can give review after order")
                            }
                            else {
                                // alert("Review not submitted")
                            }
                            await this.setState({ msgRating: res.message })
                        }
                    }).catch(err => {
                    })

            }
        }
        else {
            alert("Please login to review")
        }
    }

    _addToCart = async (dataimage, type) => {


        var { product_detail, selectedColorId, selectedSizeId } = this.state

        if ((product_detail.product_type == '3' || product_detail.product_type == '4')) {
            if (selectedColorId == null || !this.state.isColorSelected) {
                Notification('error', 'Error!', 'Please select color !')
                return;
            }
        }
        if ((product_detail.product_type == '2' || product_detail.product_type == '4')) {
            if (selectedSizeId == null || !this.state.isSizeSelected) {
                Notification('error', 'Error!', 'Please select size !')
                return;
            }
        }

        var qty = this.state.value
        if (type == 2) {
            qty = this.state.value - 1

        } else {
            qty = this.state.value + 1

        }
        var body = new FormData()
        body.append('product_id', product_detail.product_id)
        body.append('color_id', selectedColorId)
        body.append('size_id', selectedSizeId)
        body.append('main_price', product_detail.price)
        body.append('price', product_detail.wholesale_price)
        body.append('quantity', qty)
        body.append('device_id', this.state.uniueId)
        // body.append('image', product_detail.product_images[0].image)

        if (product_detail.product_images.length > 0) {

            var temp = product_detail.product_images[0].image.includes(`${MAIN_URL}master-naswiz//`) ? `${MAIN_URL}master-naswiz//` : product_detail.product_images[0].image.includes(`${MAIN_URL}master-naswiz/`) ? `${MAIN_URL}master-naswiz/` : null
            body.append('image', dataimage[0].image.replace(temp, ''))
        }
        else {
            body.append('image', `storage/uploads/product/Medium${process.env.PUBLIC_URL}/assets/images/nia.png`)
        }

        for (var pair of body.entries()) {
        }

        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/add-usercart', body, "Error => v1/add-usercart")


        if (res && res.success == 1) {
            Notification('success', 'Success!', res.message ? res.message : 'Product Add to Cart Successfully.')
            this.productDetailApiWithSlug(this.state.slugs, 'After added to cart');
            var formdata = new FormData()
            formdata.append('device_id', this.state.uniueId)
            this.props.getCartData('v1/get-cart-list', formdata, "Error => GetCartList api.")
            var qty = this.state.value
            if (type == 2) {
                await this.setState({ value: this.state.value - 1 });

            } else {
                await this.setState({ value: this.state.value + 1 });

            }
        }
        else {
            Notification('error', 'Error!', res.message + '')
        }

    }

    _addToCartWithoutLogin = async (dataimage, type) => {

        var { product_detail, selectedColorId, selectedSizeId } = this.state
        if ((product_detail.product_type == '3' || product_detail.product_type == '4')) {
            if (selectedColorId == null || !this.state.isColorSelected) {
                Notification('error', 'Error!', 'Please select color !')
                return;
            }
        }
        if ((product_detail.product_type == '2' || product_detail.product_type == '4')) {
            if (selectedSizeId == null || !this.state.isSizeSelected) {
                Notification('error', 'Error!', 'Please select size !')
                return;
            }
        }
        var qty = this.state.value
        if (type == 2) {
            qty = this.state.value - 1

        } else {
            qty = this.state.value + 1

        }


        var body = new FormData()
        body.append('product_id', product_detail.product_id)
        body.append('color_id', selectedColorId)
        body.append('size_id', selectedSizeId)
        body.append('main_price', product_detail.price)
        body.append('price', product_detail.wholesale_price)
        body.append('device_id', this.state.uniueId)
        body.append('quantity', qty)
        // body.append('image', product_detail.product_images[0].image)

        if (dataimage.length > 0) {

            var temp = dataimage[0].image.includes(`${MAIN_URL}master-naswiz//`) ? `${MAIN_URL}master-naswiz//` : dataimage[0].image.includes(`${MAIN_URL}master-naswiz/`) ? `${MAIN_URL}master-naswiz/` : null
            body.append('image', dataimage[0].image.replace(temp, ''))
        }
        else {
            body.append('image', `storage/uploads/product/Medium${process.env.PUBLIC_URL}/assets/images/nia.png`)
        }

        for (var pair of body.entries()) {
        }

        var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/add-usercart-without-login', body, "Error => v1/add-usercart")

        if (res && res.success == 1) {
            Notification('success', 'Success!', res.message ? res.message : 'Product Add to Cart Successfully.')
            this.productDetailApiWithSlug(this.state.slugs, 'After added to cart');
            var body = new FormData()
            body.append('device_id', this.state.uniueId)
            this.props.getCartDataWithoutLogin('v1/get-cart-list-without-login', body, "Error => get-cart-list-without-login.")
            var qty = this.state.value
            if (type == 2) {
                await this.setState({ value: this.state.value - 1 });

            } else {
                await this.setState({ value: this.state.value + 1 });

            }

        }
        else {
            Notification('error', 'Error!', res.message + '')
        }



    }

    //add to wishlist 
    _addToWishlistWithApi = async () => {
        var pId = this.state.product_id;

        if (!this.props.isUserLogin) {
            Notification('error', 'Error!', 'Please Login First!')
        }
        else {

            var res = await NaswizHelperApi.GET_WITH_TOKEN('v1/wishlist/'.concat(pId), "Error => Add to wishlist api")

            if (res && res.success == 1) {
                if (res.message === 'Product is already in Wishlist.') {
                    Notification('error', 'Error!', res.message)
                }
                else {
                    this.setState({ _wishlist: 1 })
                    Notification('success', 'Success!', 'Product added to Favourites')
                    this.productDetailApiWithSlug(this.state.slugs, '');
                    setTimeout(() => {
                    }, 500)
                }
            }

        }
    }
    _removeFromWishListApi = async () => {

        if (!this.props.isUserLogin) {
            Notification('error', 'Error!', 'Please Login First!')
        }
        else {

            if (this.props._from === "from_wishlist") {
                var res = await NaswizHelperApi.GET_WITH_TOKEN('v1/removewishlist/'.concat(this.state.product_detail.wishlist_id), "Error => remove item from user wishlist")

                if (res && res.success == 1) {
                    this.setState({ _wishlist: 0 })

                    setTimeout(() => {
                        // this.props.updateDataAfterAction()
                        // this.props.getWishlistData('v1/wishlist-details', "Error => Get all wishlist data")
                    }, 1000)
                } else {


                }
            }
            else {
                var res = await NaswizHelperApi.GET_WITH_TOKEN(`v1/removewishlist/${this.state.product_detail.wishlist_id}`, "Error => remove item from user wishlist")

                if (res && res.success == 1) {
                    this.setState({ _wishlist: 0 })
                    Notification('success', 'Success!', 'Item removed from Favourites!')
                    // setTimeout(() => {
                    //     this.props.updateDataAfterAction()
                    //     this.props.getWishlistData('v1/wishlist-details', "Error => Get all wishlist data")
                    // }, 1000)
                }
            }


        }

    }

    // check this item in cart or not

    async checkCartItem(colorid, sizeid) {
        var isMatch = false;

        for (let i = 0; i < this.state._cartItem.length; i++) {

            if (this.state._product_type == '4' && colorid != '' && sizeid != '') {
                if (colorid == this.state._cartItem[i].color_id && sizeid == this.state._cartItem[i].size_id) {
                    this.setState({ value: this.state._cartItem[i].total_quantity })
                    isMatch = true;
                }
            } else if (this.state._product_type == '3' && colorid != '') {
                if (colorid == this.state._cartItem[i].color_id) {
                    this.setState({ value: this.state._cartItem[i].total_quantity })
                    isMatch = true;
                }
            } else if (this.state._product_type == '2' && sizeid != '') {

                if (sizeid == this.state._cartItem[i].size_id) {
                    this.setState({ value: this.state._cartItem[i].total_quantity })
                    isMatch = true;
                }
            } else if (this.state._product_type == '1') {
                this.setState({ value: this.state._cartItem[i].total_quantity })
                isMatch = true;
            }
        }
        if (!isMatch) {
            this.setState({ value: 0 })

        }
    }
    render() {

        const { symbol, item, addToCart, addToCartUnsafe, addToWishlist, singleDetails, } = this.props
        const { product_detail, related_record, quick_product_detail, quick_product_id, quick_related_record } = this.state;


        var products = { slidesToShow: 1, slidesToScroll: 1, dots: false, arrows: true, fade: true };

        var quickviewrelated = { slidesToShow: 1, slidesToScroll: 1, dots: false, arrows: true, fade: true };

        var productsnav = { slidesToShow: 4, slidesToScroll: 1, swipeToSlide: true, arrows: false, dots: false, focusOnSelect: true };

        var quickproductsnav = { slidesToShow: 3, slidesToScroll: 1, swipeToSlide: true, arrows: false, dots: false, focusOnSelect: true };

        var colorsFilter = product_detail && product_detail.details ? Array.from(new Set(product_detail.details.map(s => s.color_code))).map(id => {
            return {
                color_name: id,
                color_id: product_detail.details.find(item => {
                    if (id === item.color_code) {
                        return item.color_id
                    }
                })
            }
        }) : null

        var sizeFilter = product_detail && product_detail.details ? Array.from(new Set(product_detail.details.map(s => s.size_name))).map(id => {
            return {
                size_name: id,
                size_id: product_detail.details.find(item => {
                    if (id === item.size_name) {
                        return item.size_id
                    }
                })
            }
        }) : null
        var dataImage = []
        if (this.state.selectedColorId != '' && this.state.selectedColorId != null && this.state.selectedColorId != 0 && this.state.selectedSizeId != '' && this.state.selectedSizeId != null && this.state.selectedSizeId != 0) {

            dataImage = product_detail && product_detail.product_images ? product_detail.product_images.filter((image, index) => {

                if (this.state.selectedColorId == image.color_id) {
                    return {
                        image: image.image
                    }
                }

            }) : []
        }
        if (dataImage.length == 0) {
            dataImage = product_detail && product_detail.product_images ? product_detail.product_images.filter((image, index) => {
                return {
                    image: image.image

                }

            }) : []
        }


        const leftQtyObj = product_detail && product_detail.details ? product_detail.details.filter((image, index) => {
            if (this.state.selectedColorId == image.color_id && this.state.selectedSizeId == image.size_id) {
                return {
                    image
                }
            }

        }) : ''

        var leftQty = leftQtyObj.length > 0 ? (leftQtyObj[0].inventory - leftQtyObj[0].used) : ''


        const data = [
            {
                image: '../../../assets/images/products/product-2.jpg'

            },
            {
                image: '../../../assets/images/products/categories/female-fashion.jpg'
            },
            {
                image: '../../../assets/images/products/product-14.jpg'
            },
            {
                image: '../../../assets/images/products/product-11.jpg'
            }
        ];

        return (

            <div ref={topRef => this.TopRef = topRef} className="p-detail">
                {product_detail.product_meta != null && product_detail.product_meta.length > 0 ? product_detail.product_meta.map((item, index) => {
                    return (
                        <Helmet>
                            <title>{`${item.meta_title} - Naswiz Retail Pvt Ltd`}</title>
                            <meta name="keywords" content={item.meta} />
                            <meta name="description" content={item.meta_description} />
                        </Helmet>
                    )
                }) : null}


                {product_detail ?
                    <div className="container">
                        <div className="container-box">
                            <div className="product-single-container product-single-default">
                                <div className="row">
                                    <div className="col-md-5 product-single-gallery">
                                        <Slider {...products} asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)} className="product-slick">
                                            {dataImage && dataImage.length > 0 ?
                                                dataImage.map((data, index) => {
                                                    return (
                                                        <div className="product-item" key={index}>
                                                            <SideBySideMagnifier
                                                                imageSrc={data.image}
                                                                alwaysInPlace={true}
                                                                largeImageSrc={data.image}
                                                            />
                                                        </div>
                                                    )
                                                }) : null
                                            }

                                        </Slider>

                                        {dataImage && dataImage && dataImage.length > 1 ?
                                            <SmallImages item={dataImage} settings={productsnav} navOne={this.state.nav1} ref={slider => (this.slider2 = slider)} /> :
                                            null
                                        }
                                        <div className="product-slider-container">
                                            <div className="product-single-carousel owl-carousel owl-theme">
                                                {testImages.map((image) => {
                                                    return <div className="product-item">
                                                        <img className="product-single-image" src={image.normalImg} data-zoom-image={image.bigImg} />
                                                        <span className="prod-full-screen">
                                                            <i className="icon-plus"></i>
                                                        </span>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                        <div className="prod-thumbnail owl-dots" id='carousel-custom-dots'>
                                            {testImages.map((image) => {
                                                return <div className="owl-dot">
                                                    <img src={image.normalImg} />
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                    <div className="col-md-7 product-single-details">
                                        <h1 className="product-title">{product_detail.name}</h1>
                                        <div className="ratings-container">
                                            {product_detail.avg_rating != undefined ?
                                                <Rate defaultValue={product_detail.avg_rating} disabled />
                                                : null
                                            }
                                            <a className="rating-link">( {product_detail.reviewrating ? product_detail.reviewrating.length : ''} Reviews )</a>
                                        </div>
                                        <hr className="short-divider"></hr>
                                        <div className="price-box">
                                            <span className="ippoints">Incentive Point (IP) {product_detail.incentive_point}</span>
                                            {product_detail.wholesale_price > 0 ?
                                                <span className="old-price">₹{product_detail.price}</span>
                                                : null
                                            }

                                            <span className="product-price">₹{product_detail.wholesale_price > 0 ? product_detail.wholesale_price : product_detail.price}</span>
                                            <span className="percentage-dis">({product_detail.off}%)</span>
                                        </div>


                                        <div className={this.state._readMore ? "product-desc active" : "product-desc"}>
                                            {/* {renderHTML(String(`${product_detail.description}`))}    */}
                                            {this.state._product_description2.length > 150 ? this.state._readMore ? <div dangerouslySetInnerHTML={{ __html: this.state._product_description2 }} /> :
                                                <div dangerouslySetInnerHTML={{ __html: this.state._product_description }} />
                                                : <div dangerouslySetInnerHTML={{ __html: this.state._product_description }} />
                                            }
                                        </div>
                                        {this.state._product_description2.length > 150 ?
                                            <button onClick={() => this.setState({ _readMore: !this.state._readMore })}>({this.state._readMore ? "Less More" : "Read More"})</button>
                                            : null}
                                        <div className="product-filters-container">
                                            {(product_detail.product_type == '3' || product_detail.product_type == '4') ?
                                                <div className="product-single-filter testecce">
                                                    <label>Colors:</label>
                                                    <ul className="config-swatch-list detail-color">
                                                        {colorsFilter && colorsFilter.length > 0 ?
                                                            colorsFilter.map((color, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        {/* <Checkbox onChange={() => this.onColorFilterChange(item)} /> */}
                                                                        <li key={index}><input type="radio" name="name"
                                                                            checked={this.state.selectedColorId === color.color_id.color_id}
                                                                            onClick={() => {
                                                                                this.setState({ selectedColorIndex: index, selectedColorId: color.color_id.color_id, isColorSelected: true, colorNotSelectText: 'Color Selected' })
                                                                                this.checkCartItem(color.color_id.color_id, this.state.selectedSizeId)
                                                                            }} />
                                                                            {/* <div style={this.state.selectedColorIndex == index ? colorStyle : null}></div>  */}
                                                                            <a style={{ backgroundColor: `${color.color_name}`, marginLeft: '5px' }}></a>
                                                                        </li>
                                                                    </div>
                                                                )
                                                            }) : null
                                                        }
                                                    </ul>
                                                    {!this.state.isColorSelected ? <p className="color-red">{this.state.colorNotSelectText}</p> : <p className="color-green">{this.state.colorNotSelectText}</p>}
                                                </div> : null}
                                        </div>
                                        <div className="product-filters-container">
                                            {(product_detail.product_type == '2' || product_detail.product_type == '4') ?
                                                <div className="product-single-filter mb-2">
                                                    <label>Sizes:</label>
                                                    <ul className="config-size-list">
                                                        {
                                                            sizeFilter && sizeFilter.length > 0 ?

                                                                sizeFilter.map((size, index) => {
                                                                    return (
                                                                        <li>
                                                                            <div
                                                                                onClick={() => {

                                                                                    var isUsed = size.size_id.inventory - size.size_id.used
                                                                                    this.setState({ _leftUsedQty: isUsed, _isBtnEnble: true, _maxQty: size.size_id.max_order_qty, selectedSizeIndex: index, selectedSizeId: size.size_id.size_id, isSizeSelected: true, notSelectedText: 'Size Selected' })
                                                                                    this.checkCartItem(this.state.selectedColorId, size.size_id.size_id)

                                                                                    if ((size.size_id.inventory - size.size_id.used) == 0) {
                                                                                        this.setState({ _isBtnEnble: false })
                                                                                    }

                                                                                }}
                                                                            >
                                                                                <li key={index}><a style={this.state.selectedSizeId == size.size_id.size_id ? colorStyle : null}>{size.size_name}</a></li>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                }) : null
                                                        }
                                                    </ul>
                                                    {!this.state.isSizeSelected ? <p className="color-red">{this.state.notSelectedText}</p> : <p className="color-green">{this.state.notSelectedText}</p>}
                                                </div> : null}
                                        </div>
                                        <div className="product-filters-container">
                                            {leftQty != '' && leftQty <= this.state._notifyQty ? <div className="product-single-filter mb-2">
                                                <h3>{leftQty} Quantity Left</h3>
                                            </div> : ''}
                                        </div>
                                        {/* {product_detail.details[this.state.selectedSizeIndex].inventory === product_detail.details[this.state.selectedSizeIndex].used && product_detail.details.length > 0 && this.state.selectedSizeIndex !== ''
                                                ?
                                                <h3>Quantity left</h3>
                                                :
                                                null
                                                } */}
                                        <hr className="divider"></hr>
                                        <div className="product-action custom-design">
                                            {this.state.value > 0 ? <div className="def-number-input number-input">
                                                <button onClick={() => this.decrease(dataImage)} className="minus"></button>
                                                <input className="quantity" name="quantity" value={this.state.value} type="number" />
                                                <button onClick={() => this.increase(dataImage)} className={this.state.value == this.state._maxQty ? "plus" : this.state.value == this.state._leftUsedQty ? "plus" : "plus"}></button>
                                                {/* //plus disable */}
                                            </div> :

                                                <a onClick={() => {

                                                    if (this.props.isUserLogin) {

                                                        this._addToCart(dataImage, 1)

                                                    } else {
                                                        this._addToCartWithoutLogin(dataImage, 1)

                                                        // }
                                                    }

                                                }} className={this.state._isBtnEnble ? "btn btn-dark add-cart icon-shopping-cart" : "btn btn-dark add-cart icon-shopping-cart disable"} title="Add to Cart">Add to Cart </a>

                                            }
                                            <div className={this.state._shippingcode ? "pincode-field-warp hide" : "pincode-field-warp"}>
                                                <div className="pincode-info-warp-inner">
                                                    <div className="delivery-text-wrap clearfix">
                                                        <div className="delivery-text">
                                                            <img src="assets/images/location_marker.png" />DELIVERY OPTIONS</div>
                                                        <div className="check-pincode-wrap">
                                                            <div className="product-des__details-delivery-wrap --input">
                                                                <div className="product-des__details-delivery">
                                                                    <div className="pincode-field-warp">
                                                                        <div className="row">
                                                                            <div className="col-md-12 col-sm-9 col-xs-9">
                                                                                <div><input type="text" name="pincode" placeholder="Enter Pincode" className="form-control" maxlength="6" /></div>
                                                                            </div>
                                                                            <div className="adjust-mobile-desktop col-sm-3 col-xs-3"><button onClick={() => this.setState({ _shippingcode: !this.state._shippingcode })} type="submit" className="product-des__details-delivery-btn" name="button">CHECK</button></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className={this.state._shippingcode ? "delivery-info-wrap-inner show" : "delivery-info-wrap-inner hide"}>
                                                <div className="open">
                                                    <div className="clearfix">
                                                        <div className="pull-left delivery-area">
                                                            <img src="assets/images/location_marker.png" />
                                                            <span className="help-text">Delivery options for</span> <span className="pincode">360311</span>
                                                        </div>
                                                        <div onClick={() => this.setState({ _shippingcode: !this.state._shippingcode })} className="pull-left delivery-change-btn"><a href="javascript:void(0)"> Change</a></div>
                                                    </div>
                                                    <ul className="delivery-details">
                                                        <div className="shipping-city-details">
                                                            Shipping To: <span className="text-style"> Gondal India </span>
                                                        </div>
                                                        <li>
                                                            <i className="success">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 18.35">
                                                                    <title>checkmark</title>
                                                                    <path d="M21.13 0L8.48 12.65 2.87 7.04 0 9.91l7.53 7.53 1 .91.95-.91L24 2.87z" fill="#4fcc71"></path>
                                                                </svg>
                                                            </i>Dispatch in 2 days </li>
                                                        <li>
                                                            <i className="success">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 18.35">
                                                                    <title>checkmark</title>
                                                                    <path d="M21.13 0L8.48 12.65 2.87 7.04 0 9.91l7.53 7.53 1 .91.95-.91L24 2.87z" fill="#4fcc71"></path>
                                                                </svg>
                                                            </i>Cash on Delivery available on orders above ₹700</li>
                                                    </ul>
                                                    <div className="more-info">
                                                        <p>Free shipping on orders above ₹500 & Cash on Delivery available on orders above ₹700</p>
                                                        <span className="more-info"><i className="info-icon">i</i>MORE INFO</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="divider mb-1"></hr>
                                        <div className="product-single-share test">
                                            <label className="sr-only">Share:</label>
                                            <div className="social-icons mr-2">
                                                <FacebookShareButton
                                                    url={this.state._current_Url}
                                                    quote={"Share"}
                                                    hashtag=""
                                                >
                                                    <a className="social-icon social-facebook icon-facebook" target="_blank" title="Facebook"></a>

                                                    {/* <FacebookIcon size={36} /> */}
                                                </FacebookShareButton>
                                                <TwitterShareButton
                                                    url={this.state._current_Url}
                                                    title={"Share "}
                                                    hashtag="#test"
                                                //  className={classes.socialMediaButton}
                                                >
                                                    <a className="social-icon social-twitter icon-twitter" target="_blank" title="Twitter"></a>
                                                </TwitterShareButton>
                                                <WhatsappShareButton
                                                    url={this.state._current_Url}
                                                    title={"Share "}
                                                    separator=":: "
                                                //  className={classes.socialMediaButton}
                                                >
                                                    <WhatsappIcon size={28} />
                                                </WhatsappShareButton>
                                                <LinkedinShareButton
                                                    url={this.state._current_Url}
                                                    title={"Share "}
                                                    summary={""}
                                                >
                                                    <a className="social-icon social-linkedin fab fa-linkedin-in" target="_blank" title="Linkedin"></a>
                                                </LinkedinShareButton>
                                                <EmailShareButton
                                                    // url={this.state._current_Url}
                                                    body={this.state._current_Url}
                                                    subject={"Share "}
                                                    separator=" "
                                                //  className={classes.socialMediaButton}
                                                >
                                                    <a className="social-icon social-mail icon-mail-alt" target="_blank" title="Mail"></a>
                                                </EmailShareButton>
                                            </div>
                                            <a className={this.state._wishlist == 1 ? "fa fa-heart" : "icon-heart add-wishlist"} title="Add to Wishlist" onClick={() => this.state._wishlist == 1 ? this._removeFromWishListApi(item) : this._addToWishlistWithApi(item)}><span>Add to Wishlist</span></a>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                    : null
                }



            </div >

        )

    }

}

const mapStateToProps = (state, ownProps) => {
    let productId = ownProps.match.params.id2;
    return {
        // item: state.data.products.find(el => el.id == productId),
        symbol: RUPEE_SYMBOL,
        singleDetails: state.singleDetails.details,
        userID: state.login.userID,
        isUserLogin: state.login.isUserLogin,
        cartListArray: state.login.userCartData.records.cart_list,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addToCart: (item, qty) => addToCart(item, qty),
    addToCartUnsafe: () => addToCartUnsafe(),
    addToWishlist: () => addToWishlist(),
    removeFromWishlist: () => removeFromWishlist(),
    getCartData: (trailUrl, errorMsg) => getCartData(trailUrl, errorMsg),
    getCartDataWithoutLogin: (trailUrl, errorMsg) => getCartDataWithoutLogin(trailUrl, errorMsg)

}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SliderPage);