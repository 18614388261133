import React, { Component, ReactPropTypes, useEffect, useState } from 'react';
import * as Scroll from 'react-scroll';
import { Link, NavLink, Redirect, Route, animateScroll as scroll, scrollSpy, scroller, withRouter } from 'react-router-dom';
import { changeCurrency, changeLoginState, removeUserData, manageUserIdState, addUserData, getCartData, getCartDataWithoutLogin } from '../../../actions'
import { connect } from "react-redux";
import Notification from '../../../Notification'
import 'react-phone-number-input/style.css'
import { NaswizHelperApi } from '../../../NaswizHelper';
import Voice from './Voice'
import { detect } from 'detect-browser'
const browser = detect()
var SpeechRecognition = ''
var mic = ''
if (browser) {

	if (browser.name == 'chrome') {
		SpeechRecognition =
			window.SpeechRecognition || window.webkitSpeechRecognition
		mic = new SpeechRecognition()

		mic.continuous = true
		mic.interimResults = true
		mic.lang = 'en-US'
	}
}

class HeaderMobile extends Component {

	constructor(props) {
		super(props);

		this.state = {
			masterCategoryList: props.masterCategoryList,
			allMasterCategoryList: props.allMasterCategoryList,
			categoryDataWhenOnHover: props.categoryDataWhenOnHover,
			top_bar_cms: props.top_bar_cms,
			social_arr: props.social_arr,
			dataWhenOnHover: [],

			phoneNumberModalVisibility: false,
			otpModalVisibility: false,
			phoneNumber: '',
			otp_1: '',
			otp_2: '',
			otp_3: '',
			otp_4: '',
			otp: '',
			activeModalType: '',
			arr_searchResult: [],
			searchTerm: '',
			isListening: false,
			note: '',
			savedNotes: [],
			sentmessage: '',
			clientWhatsapp: '',
			uniueId: '',
			_search_text: '',
			_browsName: '',
			_mainCateSlecred: '',
			_counter: 60,
			_counternew: 10,
			_showbutton: false,
			_is_whatsapp: '',
			__is_search: false,
			__isredirest: false,
			timer: null,
			_counternew: 10,
			_showbutton: false,
			regexp: /^[0-9\b]+$/,
			mobilesubmenu: false,
			_parent_id: "",
			_closeMenu: false,
		}


	}
	async setNote(transaction) {
		await this.setState({ note: transaction })
		this.handleSaveNote()
	}
	onHandleTelephoneChange = e => {
		let telephone = e.target.value;
		if (telephone === '' || this.state.regexp.test(telephone)) {
			this.setState({ phoneNumber: telephone })

		}
		else {
			return;
		}
	};
	handleSaveNote = async () => {
		await this.setState({ savedNotes: this.state.note })
		this.setState({ note: '' })
		document.getElementById("GlobalSearchInput").value = this.state.savedNotes;
		await this._search(this.state.savedNotes)
		mic.stop()
		mic.onend = () => {
		}
		await this.setState({ isListening: false })

	}

	handleListen = async () => {
		await this.setState({ isListening: !this.state.isListening })

		if (!this.state.isListening) {
			await this.setState({ note: '' })
			await this.setState({ savedNotes: '' })
		}

		if (this.state.isListening) {
			mic.start()
			mic.onend = () => {
				mic.start()
			}
		} else {
			mic.stop()
			mic.onend = () => {
			}
			// mic.abort()
		}
		mic.onstart = () => {
		}

		mic.onresult = event => {
			this.setState({ note: '' })
			const transcript = Array.from(event.results)
				.map(result => result[0])
				.map(result => result.transcript)
				.join('')
			this.setState({
				_search_text: transcript
			})
			this.setNote(transcript)

			mic.onerror = event => {
			}
		}
	}


	componentDidMount() {
		var _whatsapp = localStorage.getItem('is_whatsapp')
		this.setState({ _is_whatsapp: _whatsapp })
		if (browser) {

			this.setState({ _browsName: browser.name })
		}
		var uniueId = localStorage.getItem('deviceId')
		this.setState({ uniueId: uniueId })

	}

	setCounter = () => {

		var that = this
		let timer = setInterval(that.manageTimer, 1000);
		that.setState({ timer });
	}
	manageTimer = () => {

		var that = this
		var { timer, _counter } = that.state
		if (_counter === 0) {
			clearInterval(timer)
			that.setState({
				_counter: 0
			})
		}
		else {
			that.setState({
				_counter: _counter - 1
			});

		}
	}

	async resendOtp() {
		await this.setState({ _counter: 60, })
		await this._sendOtp();

	}

	componentDidUpdate() {

	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}
	handleChange = otp => this.setState({ otp });

	_voice = () => {
		var { recognization } = this.state
		recognization.onstart = () => {
		}

		recognization.onresult = (e) => {
		}
	}

	openNav() {
		var openmyslide = document.getElementById("mySidenav");
		if (openmyslide) {
			openmyslide.classList.add('open-side')
		}
	}

	openSearch() {
		document.getElementById("search-overlay").style.display = "block";
	}

	closeSearch() {
		document.getElementById("search-overlay").style.display = "none";
	}
	handleListen22 = async () => {
		await this.setState({ isListening: !this.state.isListening })
		if (!this.state.isListening) {
			await this.setState({ note: '' })
		}
		if (this.state.isListening) {
			mic.start()
			mic.onend = () => {
				mic.start()
			}
		} else {
			mic.stop()
			mic.onend = () => {
			}
		}
		mic.onstart = () => {
		}

		mic.onresult = event => {
			const transcript = Array.from(event.results)
				.map(result => result[0])
				.map(result => result.transcript)
				.join('')
			this.setNote(transcript)
			mic.onerror = event => {
			}
		}
	}
	_showOnHover = (ID) => {
		if (this.props.categoryDataWhenOnHover && this.props.categoryDataWhenOnHover.length > 0) {
			var dataWhenOnHover = []
			this.props.categoryDataWhenOnHover.map((item, index) => {
				if (item.id == ID) {
					dataWhenOnHover = item.items
				}
			})
			this.setState({ dataWhenOnHover })
		}
	}


	_search = async (searchTerm) => {
		this.setState({ searchTerm, _search_text: searchTerm, __is_search: false })

		if (searchTerm.length > 3) {

			var body = new FormData()
			body.append('search', searchTerm)

			var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/search/product', body, "Error => v1/search api.")
			if (res && res.success == 1) {
				this.setState({
					arr_searchResult: res.records.data,
					__isredirest: true
				})
				this.forceUpdate()
			}
			if (res.records.data.length == 0) {
				this.setState({
					__is_search: true
				})
			}
		}
		if (searchTerm.length == 0) {
			this.setState({
				arr_searchResult: []
			})
		}
		else {
			this.setState({
			})
		}

	}

	redirectToSearchList() {

	}
	handleClick = async (item, qty) => {
		var body = new FormData()
		body.append('cart_id', item.cart_id)
		body.append('quantity', item.total_quantity + qty)

		for (var pair of body.entries()) {
		}

		var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/update-cart', body, "Error => v1/add-usercart")

		if (res && res.success == 1) {
			Notification('success', 'Success!', res.message ? res.message : 'Product update successfully.')
			if (this.props.isUserLogin) {
				var body = new FormData()
				body.append('device_id', this.state.uniueId)
				this.props.getCartData('v1/get-cart-list', body, "Error => GetCartList api.")

			} else {
				var body = new FormData()
				body.append('device_id', this.state.uniueId)
				this.props.getCartDataWithoutLogin('v1/get-cart-list-without-login', body, "Error => get-cart-list-without-login.")

			}

		}
		else {
			Notification('error', 'Error!', res.message ? res.message : 'Login First')
		}


	}
	deleteCart = async (item) => {


		var res = await NaswizHelperApi.GET_WITHOUT_TOKEN('v1/delete-cart/' + item.cart_id, body, "Error => v1/add-usercart")

		if (res && res.success == 1) {
			Notification('success', 'Success!', res.message ? res.message : 'Product deleted successfully.')
			if (this.props.isUserLogin) {
				var body = new FormData()
				body.append('device_id', this.state.uniueId)
				this.props.getCartData('v1/get-cart-list', body, "Error => GetCartList api.")

			} else {
				var body = new FormData()
				body.append('device_id', this.state.uniueId)
				this.props.getCartDataWithoutLogin('v1/get-cart-list-without-login', body, "Error => get-cart-list-without-login.")

			}

		}
		else {
			Notification('error', 'Error!', res.message ? res.message : 'Login First')
		}

	}
	render() {
		var { top_bar_cms, social_arr, allMasterCategoryList, cartListArray, cartTotalArray, masterCategoryList } = this.props
		return (
			<div>

				{!this.props.isMenuOpen ? null :
					<div>
						<div className="mobile-menu-overlay"></div>
						<div className="mobile-menu-container">
							<div className="mobile-menu-wrapper">
								<span className="mobile-menu-close"><i className="fas fa-times" onClick={() => this.props.closeMenu()}></i></span>
								<nav className="mobile-nav">
									<ul className="mobile-menu">
										<li className="active"><Link to="/">Home</Link></li>
										{
											masterCategoryList && masterCategoryList.length > 0 ?
												masterCategoryList.map((item, index) => {
													return (
														<li key={index} className={item.slug == this.state._mainCateSlecred ? "open" : "drop-down"}>
															<div className="top-arrowmenu" style={{ position: 'relative' }}>
																<Link
																	to={{ pathname: `${process.env.PUBLIC_URL}/Products/${item.slug}`, state: [{ 'slug': item.slug, 'name': item.name, 'category': item }] }}
																	// onMouseEnter={() => this._showOnHover(item.id)}
																	onClick={() => this.props.closeMenu()}
																>
																	{item.meta_title}

																</Link>
																{item.is_subcategory > 0 ?
																	<span className="mmenu-btn" onClick={() => {
																		this.setState({ _mainCateSlecred: item.slug, mobilesubmenu: false })
																		this._showOnHover(item.id)

																	}}></span> : null
																}

															</div>
															<ul style={{ display: item.slug == this.state._mainCateSlecred ? 'block' : 'none' }}>
																{this.state.dataWhenOnHover.length > 0 ?
																	<div className="second_menu" style={{ paddingLeft: '10px' }}>
																		<div className="row">

																			{
																				this.state.dataWhenOnHover.length > 0 ?
																					this.state.dataWhenOnHover.map((itm, inx) => {
																						return (
																							<div key={inx} className="col-lg-12">
																								{/* <a href="#" className="nolink">{itm.name}</a> */}
																								<div className="submenuarrow" style={{ position: 'relative' }}>
																									<Link
																										onClick={() => {
																											this.setState({ _mainCateSlecred: item.slug, })
																											this.props.closeMenu()
																										}}
																										to={{ pathname: `${process.env.PUBLIC_URL}/Products/${itm.slug}`, state: [{ 'slug': itm.slug, 'name': itm.name, 'category': itm }] }}>{itm.name}

																									</Link>
																									{itm.items.length > 0 ?
																										<span className="mmenu-btn" onClick={() => this.setState({ _mainCateSlecred: item.slug, mobilesubmenu: !this.state.mobilesubmenu, _parent_id: itm.id })}></span>
																										: null
																									}
																								</div>
																								<ul style={{ display: this.state.mobilesubmenu ? 'block' : 'none' }}>

																									{
																										itm.items && itm.items.length > 0 ?
																											itm.items.map((i, x) => {

																												if (this.state._parent_id == i.parent_id) {
																													return (
																														<li><Link
																															onClick={() => {
																																this.setState({ _mainCateSlecred: item.slug })
																																this.props.closeMenu()
																															}}
																															to={{ pathname: `${process.env.PUBLIC_URL}/Products/${i.slug}`, state: [{ 'slug': i.slug, 'name': i.name, 'category': i }] }}>{i.name} </Link> </li>

																													)
																												}
																											})
																											: null
																									}
																								</ul>
																							</div>
																						)
																					})
																					: null
																			}
																		</div>
																	</div>
																	: null}
															</ul>
														</li>
													)
												})
												:
												null
										}
										{
											name.map((item, index) => {
												return (
													<li key={index} className="drop-down">
														<Link
															onClick={() =>{
																this.setState({ _mainCateSlecred: 'all' })
																this.props.closeMenu()
															}
															}
															to={{ pathname: `${process.env.PUBLIC_URL}/Products/all`, state: [] }}>{item}
															<span className="mmenu-btn"  ></span>
														</Link>
														<ul className="submenu" style={{ display: 'all' == this.state._mainCateSlecred ? 'block' : 'none' }}>
															{
																allMasterCategoryList && allMasterCategoryList.length > 0 ?
																	allMasterCategoryList.map((itm, inx) => {
																		return (
																			<div key={inx} className="col-lg-3">
																				<Link
																					onClick={() => this.props.closeMenu()}
																					to={{ pathname: `${process.env.PUBLIC_URL}/Products/${itm.slug}`, state: [{ 'b_slug': itm.slug, 'b_name': itm.name, 'category': itm }] }}>
																					{itm.name}
																				</Link>
																			</div>
																		)
																	}) : null
															}
														</ul>
													</li>
												)
											})
										}


									</ul>
									<ul className="mobile-menu">
										{
											top_bar_cms && top_bar_cms.length > 0 ?
												top_bar_cms.map((topitems, index) => {
													return (
														topitems.slug == 'about' ? <li key={index}> <Link onClick={() => this.props.closeMenu()} to={{ pathname: `${process.env.PUBLIC_URL}/about-us` }}>{topitems.name}</Link></li>
															:
															topitems.slug == 'Our-Stores' ? <li key={index}> <Link onClick={() => this.props.closeMenu()} to={{ pathname: `${process.env.PUBLIC_URL}/naswiz_stores` }}>{topitems.name}</Link></li>
																:
																topitems.slug == 'Blog' ? <li key={index}> <Link onClick={() => this.props.closeMenu()} to={{ pathname: `${process.env.PUBLIC_URL}/blog` }}>{topitems.name}</Link></li>
																	:
																	topitems.link_type == 0 ?
																		<li key={index}><Link to={{ pathname: `/${topitems.slug}`, state: topitems }}
																			// onClick={() => localStorage.setItem("company", JSON.stringify(topitems))}  >
																			onClick={() => {
																				localStorage.setItem("company", JSON.stringify(topitems))
																				this.props.closeMenu()
																			}}>
																			{topitems.name}
																		</Link></li>
																		: <li key={index}><a href={`${topitems.link}`} target="_blank">{topitems.name}</a></li>
														// <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/pages/${topitems.slug}`, state: topitems }}>{topitems.name}</Link></li>
													)
												}) : null
										}
										<li><Link onClick={() => this.props.closeMenu()} to="/testimonial">Testimonial</Link></li>
									</ul>

									<ul className="mobile-menu">
										<li><Link onClick={() => this.props.closeMenu()} to="/phytowiz">Phytowiz</Link></li>
										<li><Link onClick={() => this.props.closeMenu()} to="/alkaline-ionizer">Alkaline Ionizer</Link></li>

									</ul>

									{/* <li className="float-right"><a href="/phytowiz" className="px-4">Phytowiz<span className="tip tip-new tip-top">New</span></a></li>
									<li className="float-right mr-0"><a href="/alkaline-ionizer" className="px-4">Alkaline Ionizer<span className="tip tip-new tip-top">New</span></a></li> */}
								</nav>

								<div className="social-icons">
									{
										social_arr && social_arr.length > 0 ?
											social_arr.map((socialitems, index) => {
												if (socialitems.name === 'instagram' || socialitems.name === 'twitter' || socialitems.name === 'facebook' || socialitems.name === 'youtube') {
													return (
														<a className="social-icon" key={index} title={socialitems.name} href={`${socialitems.value}`} target="_blank"><i className={socialitems.icon}></i></a>
													)
												}

											}) : null
									}
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		)
	}

	_sendOtp = async () => {
		var { activeModalType, phoneNumber } = this.state
		var URL = 'v1/login'
		var ERROR = 'Error => Login Or Register api'
		var RESPONSE = 'Response => Login Or Register api'

		if (phoneNumber.length == 10) {
			this.setState({
				_showbutton: true
			})
			var body = new FormData()
			body.append('number', '91'.concat(this.state.phoneNumber))

			var res = await NaswizHelperApi.POST_WITHOUT_TOKEN(URL, body, ERROR)

			if (res && res.message == 'Please first send message.') {

				window.open('https://wa.me/+919540276786&text=Register%20Me');
			}
			else if (res && res.success == 1) {
				Notification('success', 'Success!', res.message)
				this.setCounter()

				this.setState({ phoneNumberModalVisibility: false, otpModalVisibility: true, otp: '' })
				this.setState({
					_showbutton: false,
				})
			}
			else {
				this.setState({ phoneNumber: '' })
				Notification('error', 'Error!', res.message ? res.message : 'Try again later!')
				this.setState({
					_showbutton: false,
				})
			}
		}
		else {
			Notification('error', 'Error!', 'Enter valid phone number !')
		}

	}

	_verifyOtp = async () => {
		var { otp } = this.state
		var OTP = otp

		if (OTP.length == 4) {

			var body = new FormData()
			body.append('number', '91'.concat(this.state.phoneNumber))
			body.append('otp', OTP)

			for (var pair of body.entries()) {
			}
			var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/verify-otp', body, "OTP")
			if (res && res.success == 1) {
				if (res.message === 'Please contact admin. User is not Authorized to access the site.') {
					Notification('error', 'Error!', res.message)
				}
				else {
					this.props.changeLoginState(true)
					this.props.manageUserIdState(res.id)
					localStorage.setItem('token', res.token)
					this.setState({
						otp: '',
						otpModalVisibility: false
					})
					Notification('success', 'Success!', 'OTP Verify Successfully.')
					setTimeout(() => {
						this._getUserInfo()
						var body = new FormData()
						body.append('device_id', this.state.uniueId)
						this.props.getCartData('v1/get-cart-list', body, "Error => GetCartList api.")
					}, 2000)
				}
			}
			else {
				Notification('error', 'Error!', res.message ? res.message : 'Try again later.')
				this.setState({
					otp: '',
				})
			}
		}
		else {
			Notification('error', 'Error!', 'Enter valid OTP!')
			this.setState({
				otp: '',
			})
		}
	}

	_getUserInfo = async () => {


		var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/customers-detail', null, "Error => v1/customers-detail api.")
		if (res && res.success == 1) {
			this.props.manageUserIdState(res.record.id)
			this.props.addUserData(res.record)
		}
		else {
			this.logout()
		}
	}

	logout = () => {
		this.props.changeLoginState(false)
		this.props.removeUserData()
		this.props.manageUserIdState(false)
		localStorage.clear()
		this.props.history.replace("/")

	}

}

const mapStateToProps = (state) => ({
	isUserLogin: state.login.isUserLogin,
	userID: state.login.userID,
	useData: state.login.userData,
	cartListArray: state.login.userCartData.records.cart_list,
	cartTotalArray: state.login.userCartData.records.total,
})

export default connect(mapStateToProps, { changeCurrency, changeLoginState, removeUserData, manageUserIdState, addUserData, getCartData, getCartDataWithoutLogin })(withRouter(HeaderMobile));

const name = ['All Categories']
