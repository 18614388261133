import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'
import { Helmet } from 'react-helmet';
import Breadcrumb from '../../common/breadcrumb';
import Slider from 'react-slick';
import Seo from '../../Seo';

const BrandsSlider = {
   dots: false,
   infinite: true,
   speed: 500,
   autoplay: true,
   autoplaySpeed: 2500,
   slidesToShow: 5,
   arrows: false,
   slidesToScroll: 1,
   responsive: [
      {
         breakpoint: 500,
         settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
         }
      },
      {
         breakpoint: 1100,
         settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
         }
      },
   ]
}


class Phytowiz extends Component {
   constructor(props) {
      super(props)
      this.myRef = React.createRef()
   }
   componentDidMount() {
      window.scrollTo(0, 0)
   }
   componentDidUpdate() {
      this.myRef.current.scrollIntoView();
      window.scrollTo(0, 0);
   }
   render() {
      const { translate, _data, top_bar_cms, social_arr } = this.props;
      return (
         <div ref={this.myRef}>
            {/* <Breadcrumb title={'Phytowiz'} /> */}
            {/* <Helmet>
               <title>Phytowiz - Naswiz Retails Pvt Ltd</title>
               <meta name="og_title" property="og:title" content="Phytowiz - Naswiz Retails Pvt Ltd" />
               <meta name="twitter:title" content="Phytowiz - Naswiz Retails Pvt Ltd" />
               <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
               <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
               <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
               <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
            </Helmet> */}
            <Seo title="Phytowiz" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company."  image="https://www.myshopwiz.com/assets/images/cms-page/phytowiz-banner-1.png" />
            <main className="main phytowiz-page">

               <div className="phytowiz-banner" style={{ backgroundImage: "url(/assets/images/cms-page/phytowiz-banner-1.png)" }}>
                  <div className="container">
                     <div className="alkaline-banner-inner phytowiz-banner-inner">
                        <div className="row align-items-center">
                           <div className="col-sm-12 col-md-12 col-lg-8 col-xl-7 ml-auto mr-xl-5">
                              <div className="banner-content text-md-center">
                                 <h1>Phytowiz The <span>Miracle Product</span></h1>
                                 <p className="mt-0">One sachet of phytowiz a day keeps the doctor away!</p>
                                 <Link to="/product-detail/phytowiz/phytowiz" className="btn theme-button ls-10">SHOP NOW</Link>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="phytowiz-miracle-ingredients">
                  <div className="container">
                     <div className="box-title">
                        <h3 className="text-center">Phytowiz Miracle Ingredients</h3>
                     </div>
                     <div className="row">
                        <div className="col-sm-12 text-center">
                           <img src="/assets/images/cms-page/phytowiz-miracle-ingredients.png" alt="phytowiz-miracle-ingredients-bg-img" className="img-fluid mx-auto" />
                        </div>
                     </div>
                  </div>
               </div>

               <div className="power-phytowiz-ingredients">
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6 order-lg-2">
                           <div className="box-title">
                              <h3>Power of Phytowiz Ingredients</h3>
                           </div>
                           <ul className="ingredients-listing mb-30">
                              <li className="d-flex">
                                 <div className="phytowiz-product-img">
                                    <img src="/assets/images/cms-page/matches-80-percentage.png" alt="img" className="img-fluid rounded-circle" />
                                 </div>
                                 <div className="phytowiz-product-content ml-3">
                                    <p>
                                       <span className="d-block">Matches 80% of Human Epidermal Stem Cell</span> Vitamin C, A, High Fiber, Glucose, Potassium
                                    </p>
                                 </div>
                              </li>
                              <li className="d-flex">
                                 <div className="phytowiz-product-img">
                                    <img src="/assets/images/cms-page/stem-cells.png" alt="img" className="img-fluid rounded-circle" />
                                 </div>
                                 <div className="phytowiz-product-content ml-3">
                                    <p>
                                       <span className="d-block">Stem Cells Protect us from Ultraviolet (UV)
                                          Radiation</span> Proanthocyanin its more than (Vitamin A, C & E) Collagen maintains (Skin Elasticity)
                                    </p>
                                 </div>
                              </li>
                           </ul>

                           <Link to="/product-detail/phytowiz/phytowiz" className="btn theme-button ls-10">SHOP NOW</Link>
                        </div>
                        <div className="col-md-12 col-lg-6">
                           <img src="/assets/images/cms-page/power-of-phytowiz-ingredients.png" alt="power-of-phytowiz-ingredients" className="img-fluid mx-auto" />
                        </div>

                     </div>
                  </div>
               </div>

               <div className="phytowiz-works">
                  <div className="container">
                     <div className="box-title">
                        <h3 className="text-center">Phytowiz Works In 6 Main Ways</h3>
                     </div>
                     <div className="phytowiz-work-inner mt-3 mb-3">
                        <img src="/assets/images/cms-page/phytowiz-works-in-6-main-ways.png" alt="img" className="img-fluid" />
                        <ul>
                           <li>
                              <span className="numbering numbering-1" title="Replicating Good Cells">#1</span>
                              <span className="workstep-content">Replicating <br /> Good Cells</span>
                           </li>
                           <li>
                              <span className="numbering numbering-2" title="Rejuvenating Aged Cells">#2</span>
                              <span className="workstep-content">Rejuvenating <br /> Aged Cells</span>
                           </li>
                           <li>
                              <span className="numbering numbering-3" title="Cleanse The Cells/Blood">#3</span>
                              <span className="workstep-content">Cleanse The <br /> Cells/Blood </span>
                           </li>
                           <li>
                              <span className="numbering numbering-4" title="Repairing Damaged Cells">#4</span>
                              <span className="workstep-content">Repairing <br /> Damaged Cells</span>
                           </li>
                           <li>
                              <span className="numbering numbering-5" title="Replacing Dead Cells">#5</span>
                              <span className="workstep-content">Replacing <br /> Dead Cells</span>
                           </li>
                           <li>
                              <span className="numbering numbering-6" title="Restoring Sick Cells">#6</span>
                              <span className="workstep-content">Restoring <br /> Sick Cells</span>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>

               <div className="sublingual">
                  <div className="container">
                     <div className="box-title">
                        <h3 className="text-center">Why? Sublingual</h3>
                     </div>
                     <div className="row align-items-center">
                        <div className="col-sm-12 col-md-6 col-lg-6 order-md-2">
                           <ul>
                              <li>
                                 <span>#1</span> Rapid Absorption
                              </li>
                              <li>
                                 <span>#2</span> Avoid First – Pass Effect
                              </li>
                              <li>
                                 <span>#3</span> Faster And More Effective
                              </li>
                           </ul>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 text-center">
                           <img src="/assets/images/cms-page/why-sublingual.png" alt="images" className="img-fluid mx-auto" />
                        </div>
                     </div>
                  </div>
               </div>

               <div className="how-to-use">
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-md-12 col-lg-7">
                           <div className="box-title text-lg-left">
                              <h3>How To Use?</h3>
                           </div>
                           <div className="use-box">
                              <h4>Usage Recommendation </h4>
                              <div className="d-flex align-items-center">
                                 <img src="/assets/images/cms-page/usage-recommendation.svg" alt="img" className="img-fluid" />
                                 <div className="use-content ml-4">
                                    <h6>Morning - 1 sachet - dissolve under tongue</h6>
                                    <p className="mb-0">(Works best when you take it while your stomach is empty drink plenty of water) </p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-12 col-lg-5">
                           <img src="/assets/images/cms-page/how-to-use.png" alt="img" className="img-fluid mx-auto" />
                        </div>
                     </div>
                  </div>
               </div>

               <div className="recommendation-section">
                  <div className="container">
                     <div className="box-title">
                        <h3 className="text-center">Recommendation For Consumption</h3>
                     </div>
                     <div className="pl-3 pr-3">
                        <div className="row mt-3">
                           <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="recommendation-box">
                                 <img src="/assets/images/cms-page/3to12.svg" alt="img" className="img-fluid" />
                                 <p className="mb-0">Recommended Half Sachet For Consumers Aged Between 3 to 12</p>
                              </div>
                           </div>
                           <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="recommendation-box">
                                 <img src="/assets/images/cms-page/13-years.svg" alt="img" className="img-fluid" />
                                 <p className="mb-0">Consumers Who are 13 Years of Age And Above have to use 1 full sachet </p>
                              </div>
                           </div>
                           <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="recommendation-box">
                                 <img src="/assets/images/cms-page/250ml.svg" alt="img" className="img-fluid" />
                                 <p className="mb-0">Drink 250ml of water immediately & take a break For Atleast (30-mins to 1 hour) For Your Breakfast</p>
                              </div>
                           </div>
                           <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="recommendation-box">
                                 <img src="/assets/images/cms-page/drink-3to4.svg" alt="img" className="img-fluid" />
                                 <p className="mb-0">Drink 3 to 4 Liters of Water In A Day For Better Result</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="benefits-section">
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                           <div className="box-title">
                              <h3>Benefits Of Plant Stem Cell</h3>
                           </div>
                           <ul className="benefits-ul">
                              <li>Boost Immune System in the body</li>
                              <li>Protect Epidermal Skin Stem Cells Against UV Stress</li>
                              <li>Better Heart Health</li>
                              <li>Combat Chronological Aging</li>
                              <li>Neutralizes Free Radicals</li>
                              <li>Aids in Constipation & Digestion</li>
                              <li>Better Blood Circulation</li>
                              <li>Help with Digestive upset</li>
                              <li>Aids in Weight loss</li>
                              <li>Improved Cellular Health</li>
                              <li>Protect longevity of Skin Stem Cell</li>
                              <li>For a Vital and Healthy Looking Skin</li>
                           </ul>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                           <img src="/assets/images/cms-page/benefits-of-plant-stem-cell.png" alt="img" className="img-fluid mx-auto" />
                        </div>
                     </div>
                  </div>
               </div>

               <div className="miracle-product">
                  <div className="container">
                     <div className="box-title text-center">
                        <h3 className="mb-2">Phytowiz Our Miracle Product</h3>
                        <p className="text-theme-primary-dark mb-4 text-capitalize">One sachet of phytowiz a day keeps the doctor away!</p>
                     </div>
                     <div className="row">
                        <div className="col-sm-12">
                           <div className="text-center">
                              <div className="miracle-section">
                                 <img src="/assets/images/cms-page/phytowiz-our-miracle-product.png" alt="img" className="img-fluid d-block mx-auto" />
                                 <div className="miracle-content">
                                    <img src="/assets/images/cms-page/2pack.png" alt="img" className="img-fluid" />
                                    <span>Mrp. 15000/- (IP 1000)</span>
                                    <Link to="/product-detail/phytowiz/phytowiz" className="btn theme-button ls-10">SHOP NOW</Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>


                     <div className="miracle-product-listing pb-5 mb-5">
                        <div className="miracle-product-title">
                           <h4 className="text-center">30*2 Sachets of 1.5gm Each</h4>
                        </div>

                        <Slider className="" {...BrandsSlider}>
                           <div>
                              <Link to="#">
                                 <img src="/assets/images/cms-page/miracle-products/mibellebiochemistry.png" alt="img" className="img-fluid d-block mx-auto" />
                              </Link>
                           </div>
                           <div>
                              <Link to="#">
                                 <img src="/assets/images/cms-page/miracle-products/halal-logo.png" alt="img" className="img-fluid d-block mx-auto" />
                              </Link>
                           </div>
                           <div>
                              <Link to="#">
                                 <img src="/assets/images/cms-page/miracle-products/haccp-logo.png" alt="img" className="img-fluid d-block mx-auto" />
                              </Link>
                           </div>
                           <div>
                              <Link to="#">
                                 <img src="/assets/images/cms-page/miracle-products/innovationaward.png" alt="img" className="img-fluid d-block mx-auto" />
                              </Link>
                           </div>
                           <div>
                              <Link to="#">
                                 <img src="/assets/images/cms-page/miracle-products/natural-product.png" alt="img" className="img-fluid d-block mx-auto" />
                              </Link>
                           </div>
                           <div>
                              <Link to="#">
                                 <img src="/assets/images/cms-page/miracle-products/iso-certified-logo.png" alt="img" className="img-fluid d-block mx-auto" />
                              </Link>
                           </div>
                           <div>
                              <Link to="#">
                                 <img src="/assets/images/cms-page/miracle-products/gmp-logo.png" alt="img" className="img-fluid d-block mx-auto" />
                              </Link>
                           </div>
                           <div>
                              <Link to="#">
                                 <img src="/assets/images/cms-page/miracle-products/swiss-quality-formula-logo.png" alt="img" className="img-fluid d-block mx-auto" />
                              </Link>
                           </div>
                        </Slider>
                     </div>
                  </div>
               </div>

            </main>

         </div>
      )
   }

}

export default withTranslate(Phytowiz);
