import React from 'react'
import {withRouter} from 'react-router-dom'
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import { colors, NaswizHelperApi, Alerts } from '../../../NaswizHelper'
import { Modal, Input, DatePicker, Button, Spin, Radio } from 'antd'
import moment from "moment";
import './innerdashboard.css';
import Notification from '../../../Notification'
import { getCartData, } from '../../../actions'
import Moment from 'moment';


import logo from '../../../assets/images/Naswiz-logo.png'
import logo2 from '../../../assets/images/cancellation.png'
import ReactPaginate from 'react-paginate';
// import { Helmet } from 'react-helmet';
import Seo from '../../Seo';

class InnerDashboardMyOrders extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            arr_userOrder: [],
            arr_orderIssueList: [],
            createOrderIssueModal: false,
            orderDetailModal: false,
            orderDetailsObject: {},
            loading_orderDetailsObject: false,
            open_order_number: '',
            open_order_subject: '',
            open_order_description: '',
            cancleOrderModalVisibility: false,
            cancleOrderModalVisibilitynew: false,
            selectedOrderIndex: null,
            selected_payment_return_mode: 2,
            _uniueId: '',
            goToSuccess: false,
            loading_wallet: true,
            selectedOrder: [],
            _noOfPage: [],
            _page: 1,
            model_notReturnable: false,
            isPriceFilter: true,

            _detail: '',
            _email: '',
            _phone: '',
            _whatsapp: '',
            loading_Cancel: false,
        }
    }

    componentDidMount = async () => {
        var uniueId = await localStorage.getItem('deviceId')
        await this.setState({ _uniueId: uniueId })
        await this._getOrderList()
        await this.naswizGetHomeDataApi()
    }


    _getOrderList = async () => {

        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/get-order-list?page=' + this.state._page, null, "Error => v1/get-order-list api.")
        if (res && res.success == 1) {
            this.setState({
                arr_userOrder: res.records.data,
                loading_wallet: false
            })
            var tmp = []
            for (let i = 0; i < res.records.last_page; i++) {
                tmp.push(i)
            }

            this.setState({ _noOfPage: tmp })
        }
        else {
            this.setState({
                loading_wallet: true,
            })
        }
    }

    handlePagination = async (value2) => {
        var value = value2.selected + 1
        if (value <= this.state._noOfPage.length) {
            await this.setState({ _page: value })
            this._getOrderList()
        }

    }

    _getOrderTickitList = async () => {

        // var { } = this.state

        // if ()

        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/order-ticket-view', null, "Error => v1/order-ticket-view")
        if (res && res.success == 1) {
            this.setState({
                arr_orderIssueList: res.record
            })
        }
    }

    _getClickedOrderDetails = async (id) => {

        this.setState({ loading_orderDetailsObject: true, open_order_number: id })
        var res = await NaswizHelperApi.GET_WITH_TOKEN(`v1/get-order-detail/${id}`, "Error => v1/get-order-detail/ api.")

        if (res && res.success == 1) {
            this.setState({
                orderDetailsObject: res.records,
                loading_orderDetailsObject: false,
            })
        }
        else {
            this.setState({ loading_orderDetailsObject: false })
        }
    }

    _downloadOrder = async (id) => {

        var body = new FormData()
        body.append("order_number", id)
        var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/download-invoice', body, "Error => v1/download-invoice")

        if (res && res.success == 1) {
            if (this.isValidHttpUrl(res.records)) {
                window.open(res.records)
            }
            else {
                Notification('error', 'Error!', 'PDF URL IS NOT VALID')
            }
        }
        else {
            Notification('error', 'Error!', 'Invoice still not generated')
        }
    }

    isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    _returnOrder = async item => {

        if (this.state.selectedOrderIndex == null) {
            Notification('error', 'Error!', 'Please select order item!')
        }
        else if (this.state.selected_payment_return_mode == null) {
            Notification('error', 'Error!', 'Please select return payment mode type !')
        }
        else {
            var body = new FormData()
            body.append('order_lines_id', item.id)
            body.append('order_number', this.state.open_order_number)
            body.append('is_return_status', this.state.selected_payment_return_mode)

            for (var pair of body.entries()) {
            }

            var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/return', body, "Error => v1/return api.")

            if (res && res.success == 1) {
                Notification('success', 'Success!', 'Success!')
                this._resetCancleOrderReqState()
                this._getOrderList()
            }
            else {
                Notification('error', 'Error!', res && res.message ? res.message : 'Try again later!')
                this._resetCancleOrderReqState()
            }
        }
    }
    _returnFullOrder = async item => {
        if (this.state.orderDetailsObject.order_lines.length > 0) {
            if (this.state.selected_payment_return_mode == null) {
                Notification('error', 'Error!', 'Please select return payment mode type !')
                return;
            }
            var orderId = ''
            if (this.state.selectedOrder.length > 0) {
                orderId = this.state.selectedOrder.map((item) => { return item }).join(",")

            } else {
                Notification('error', 'Error!', 'Please select order !')
                return;
            }

            var body = new FormData()
            body.append('order_lines_id', orderId)
            body.append('order_number', this.state.open_order_number)
            body.append('is_return_status', this.state.selected_payment_return_mode)

            for (var pair of body.entries()) {
            }

            this.setState({ loading_Cancel: true })
            var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/return', body, "Error => v1/return api.")
            // console.log("cancel : ", res)
            if (res && res.success == 1) {
                Notification('success', 'Success!', 'Success!')
                this._resetCancleOrderReqState()
                this._getOrderList()
                this.setState({ loading_Cancel: false })
            }
            else {
                Notification('error', 'Error!', res && res.message ? res.message : 'Try again later!')
                this._resetCancleOrderReqState()
                this.setState({ loading_Cancel: false })
                this.setState({ model_notReturnable: true })
            }
        }
    }
    compareItem(filterArray, checkedItem) {

        for (var i = 0; i < filterArray.length; i++) {

            if (filterArray[i] == checkedItem.id) {
                return i;
            }
        }
        return -1;
    }

    async removeOrder(item) {
        var addedItemIndex = await this.compareItem(this.state.selectedOrder, item)

        var newArroption = [];
        if (addedItemIndex == -1) {
            // temp.push(item)
            newArroption = [...this.state.selectedOrder, item.id];

            await this.setState({ selectedOrder: newArroption })

        } else {
            var newArrChecked = this.state.selectedOrder.filter(a => a !== item.id);
            await this.setState({ selectedOrder: newArrChecked })
        }
    }

    async selectAllDefult() {
        if (this.state.orderDetailsObject.order_lines.length > 0) {
            for (let i = 0; i < this.state.orderDetailsObject.order_lines.length; i++) {
                await this.removeOrder(this.state.orderDetailsObject.order_lines[i])
            }
        }
    }
    _createOrderTicket = async () => {

        var { open_order_number, open_order_subject, open_order_description } = this.state

        if (open_order_subject === '') {
            Notification('error', 'Error!', 'Please enter subject')
        }
        else if (open_order_subject.length < 3) {
            Notification('error', 'Error!', 'Please enter valid subject')
        }
        else if (open_order_description === '') {
            Notification('error', 'Error!', 'Please enter description')
        }
        else if (open_order_description.length < 10) {
            Notification('error', 'Error!', 'Please enter valid description')
        }
        else {


            var body = new FormData()
            body.append("order_number", open_order_number)
            body.append("product_name", 'ORDER_' + open_order_number)
            body.append("subject", open_order_subject)
            body.append("problem_description", open_order_description)

            for (var pair of body.entries()) {
            }

            var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/order-ticket', body, "Error => v1/order-ticket")
            if (res && res.success == 1) {
                Notification('success', 'Success!', 'Success!')
                this._resetCreateOrderIssueState()
            }
            else {
                Notification('error', 'Error!', 'Try again later !')
                this._resetCreateOrderIssueState()
            }

        }
    }
    _reOrder = async (item) => {
        var body = new FormData()
        body.append("order_header_id", item.id)
        body.append("device_id", this.state._uniueId)

        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/re-order', body, "Error => v1/order-ticket")
        if (res && res.success == 1) {
            var formdata = new FormData()
            formdata.append('device_id', this.state._uniueId)
            await this.props.getCartData('v1/get-cart-list', formdata, "Error => GetCartList api.")
            // Notification('success', 'Success!', 'Success!')
            await this.setState({ goToSuccess: true })

        }
        else {
            Notification('error', 'Error!', 'Try again later !')
        }


    }
    _resetCreateOrderIssueState = () => {
        this.setState({
            open_order_number: '',
            open_order_subject: '',
            open_order_description: '',
            createOrderIssueModal: false
        })
    }

    _resetCancleOrderReqState = () => {
        this.setState({ cancleOrderModalVisibility: false, cancleOrderModalVisibilitynew: false, orderDetailsObject: {}, selectedOrderIndex: null, open_order_number: '' })
    }

    naswizGetHomeDataApi = async () => {
        var userID = this.props.userID
        var url = 'v1/get-home/' + this.state.uniueId + '/0'


        var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(url, 'Error => v1/get-home')
        if (res && res.success == 1) {
            this.setState({
                _detail: res.records.detail
            })
            if (this.state._detail.length > 0) {
                var phone = res.records.detail.find(item => item.name == 'phone');
                this.setState({
                    _phone: phone.value,
                })

                var whatsapp = res.records.detail.find(item => item.name == 'whatsapp');
                this.setState({
                    _whatsapp: whatsapp.value,
                })

                var email = res.records.detail.find(item => item.name == 'email');
                this.setState({
                    _email: email.value,
                })
            }
        }
        else {
        }

    }

    IssueClick = () => {
        this.props.history.push("/page/user/orderissue")
    }




    render() {

        if (this.state.goToSuccess) {
            return (
                <Redirect to={{
                    pathname: `/cart`,
                }} />
            )
        }
        return (
            <div className="col-lg-9 order-lg-last dashboard-content">
                {/* <Helmet>
                    <title>Order History - Naswiz Retails Pvt Ltd</title>
                    <meta name="og_title" property="og:title" content="Order History - Naswiz Retails Pvt Ltd" />
                    <meta name="twitter:title" content="Order History - Naswiz Retails Pvt Ltd" />
                    <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                    <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                    <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                    <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                </Helmet> */}

                <Seo title="Order History" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />

                {this.state.loading_wallet ?
                    <div>
                        <div className="loader-wrapper">
                            <div className="loader"></div>
                        </div>
                    </div> :
                    <div className="order-test">
                        {this.state.arr_userOrder.length > 0 ?
                            <div className="order_history">
                                <div className="table-responsive-sm table-responsive-md table-responsive">

                                    {this.state.arr_userOrder.length > 0 ?
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Order Date</th>
                                                    <th>Order ID</th>
                                                    <th>ID No.</th>
                                                    <th>Order Total</th>
                                                    <th>Total IP</th>
                                                    <th>Order Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.arr_userOrder.length > 0 ?
                                                    this.state.arr_userOrder.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{moment(item.created_at).format('DD-MM-YYYY')}</td>
                                                                <td>{item.order_number}</td>
                                                                <td>{item.address.address_id_no}</td>
                                                                <td>{item.formated_price}</td>
                                                                <td>{item.dulpicate_total_incentive_point}</td>
                                                                <td>
                                                                    <span className={item.payment_type === 'online' && item.payment_status === null ? 'status_cancelled badge' : item.order_status_id == 1 ? 'status_waiting badge' : item.order_status_id == 2 ? 'status_preparing badge   ' : item.order_status_id == 3 ? 'status_on_the_way badge' : item.order_status_id == 4 ? 'status_delevered badge' : item.order_status_id == 5 ? 'status_cancelled badge' : item.order_status_id == 6 ? 'status_returned badge' : null}>
                                                                        {item.payment_type === 'online' && item.payment_status === null ? 'PAYMENT FAIL'
                                                                            : item.order_status_id == 1 ? 'WAITING'
                                                                                : item.order_status_id == 2 ? 'PREPARING'
                                                                                    : item.order_status_id == 3 ? 'ON THE WAY'
                                                                                        : item.order_status_id == 4 ? 'DELIVERED'
                                                                                            : item.order_status_id == 5 ? 'CANCELLED'
                                                                                                : item.order_status_id == 6 ? 'RETURNED'
                                                                                                    : null}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <div className="actions_btns_list">
                                                                        <a onClick={() => {
                                                                            this.setState({ orderDetailModal: true })
                                                                            this._getClickedOrderDetails(item.order_number)
                                                                        }} title="View order"><i className="fa fa-gift"></i></a>
                                                                        <a onClick={() => this._reOrder(item)} title="Reorder"><i className="fas fa-file-invoice"></i></a>

                                                                        {item.payment_type === 'online' && item.payment_status === null ?
                                                                            null
                                                                            : item.order_status_id == 1 ?
                                                                                <a onClick={async () => {
                                                                                    await this.setState({ cancleOrderModalVisibility: true, open_order_number: item.order_number, selectedOrder: [] })
                                                                                    await this._getClickedOrderDetails(item.order_number)
                                                                                    await this.selectAllDefult()
                                                                                }} title="Cancel order">
                                                                                    <i className="fas fa-window-close"></i></a>
                                                                                : <a title="Cancel order" onClick={() => {
                                                                                    this.setState({ cancleOrderModalVisibilitynew: true, })

                                                                                }}>
                                                                                    <i className="fas fa-window-close"></i></a>
                                                                        }

                                                                        {item.payment_type === 'online' && item.payment_status === null ? null
                                                                            : <a onClick={() => {
                                                                                this._downloadOrder(item.order_number)
                                                                            }} title="Download invoice"><i className="fa fa-download"></i></a>
                                                                        }
                                                                        {item.payment_type === 'online' && item.payment_status === null ? null
                                                                            // : <a onClick={() => {this.props.onClickIssue(item)}} title="Raise ticket"><i className="fa fa-tags"></i></a>
                                                                            : <a onClick={() => {this.IssueClick();this.props.onClickIssue(item)}} title="Raise ticket"><i className="fa fa-tags"></i></a>
                                                                            // : <Link to={{ pathname: `${process.env.PUBLIC_URL}/page/user/orderissue`, orderId :item.order_number }} title="Raise ticket">
                                                                            //     <i className="fa fa-tags"></i>
                                                                            // </Link>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }) : null

                                                }
                                            </tbody>
                                        </table> :

                                        <div className="card card_bg_color">
                                            <div style={{ backgroundColor: colors.themeColor }} className="card-header">
                                                Orders
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="addressList">
                                                            <h4>
                                                                <i className="fa fa-frown-o"></i> Sorry No Orders Found
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            :
                            <div className="default-div">
                                <span>You have not placed an order with us yet</span>
                                <p>Let's get you started</p>
                                <Link to="/">Start Shopping</Link>
                            </div>
                        }
                        {this.state._noOfPage.length > 1 ?
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'page-item'}
                                breakLinkClassName={'page-link'}
                                pageCount={this.state._noOfPage.length}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={5}
                                onPageChange={(page) => this.handlePagination(page)}
                                containerClassName={'pagination toolbox-item mt-2'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                                activeClassName={'active'}
                            // forcePage={this.state._page - 1}
                            />
                            : null}
                    </div>
                }
                {
                    <Modal
                        footer={null}
                        title="Create Order Issue"
                        visible={this.state.createOrderIssueModal}
                        onCancel={() => this._resetCreateOrderIssueState()}>
                        <div className="col-md-12 col-lg-12">
                            <div className="inside_ticket">
                                <div className="tion_head">
                                    <span>  <h3>Order Issue Ticket</h3></span>
                                </div>

                                <div className="ticket_div personal_detail_form ticket_form">
                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12">
                                            <div className="col-sm-12 col-sm-12 col-lg-12 col-xs-12 input_container">
                                                {/* <input type="text" name="description" id="description" placeholder="Description" /> */}
                                                <Input
                                                    value={this.state.open_order_subject}
                                                    onChange={e => this.setState({ open_order_subject: e.target.value })}
                                                    placeholder='Enter subject'
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12">
                                            <div className="row">
                                                <div className="col-sm-12 col-sm-12 col-lg-12 col-xs-12 input_container">
                                                    {/* <input type="text" name="description" id="description" placeholder="Description" /> */}
                                                    <Input
                                                        value={this.state.open_order_description}
                                                        onChange={e => this.setState({ open_order_description: e.target.value })}
                                                        placeholder='Enter description'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <button onClick={() => this._createOrderTicket()} type='button' className="button_desing" style={{ marginRight: '20px' }}>
                                    SUBMIT
                                </button>

                                <button
                                    onClick={() => this._resetCreateOrderIssueState()}
                                    type='button'
                                    className="button_desing">
                                    CANCEL
                                </button>
                            </div>
                        </div>
                    </Modal>
                }

                {/* order detail */}
                <Modal
                    onCancel={() => this.setState({ orderDetailModal: false })}
                    title="Order Detail"
                    footer={null}
                    visible={this.state.orderDetailModal}
                    className="order_details_modal"
                    width={1000}
                >
                    {this.state.loading_orderDetailsObject ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100px' }}>
                            <Spin />
                        </div> :
                        <div className="cart-table-container">
                            <div className="top-order-view">
                                <h4 className="checkout-title">
                                    Order Number:
                                    <span>#{this.state.orderDetailsObject && this.state.orderDetailsObject && this.state.orderDetailsObject.order_number ? this.state.orderDetailsObject.order_number : 'Naswis User'}</span>
                                </h4>
                                <div className="order_status">
                                    <span className={this.state.orderDetailsObject.payment_type === 'online' && this.state.orderDetailsObject.payment_status === null ? 'status_cancelled badge' : this.state.orderDetailsObject.order_status_id == 1 ? 'status_waiting badge' : this.state.orderDetailsObject.order_status_id == 2 ? 'status_preparing badge' : this.state.orderDetailsObject.order_status_id == 3 ? 'status_on_the_way badge' : this.state.orderDetailsObject.order_status_id == 4 ? 'status_delevered badge' : this.state.orderDetailsObject.order_status_id == 5 ? 'status_cancelled badge' : this.state.orderDetailsObject.order_status_id == 6 ? 'status_returned badge' : null}>
                                        {this.state.orderDetailsObject.payment_type === 'online' && this.state.orderDetailsObject.payment_status === null ? 'PAYMENT FAIL'
                                            :
                                            this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id == '1' ? 'WAITING'
                                                :
                                                this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id == '2' ? 'PREPARING'
                                                    :
                                                    this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id == '3' ? 'ON THE WAY'
                                                        :
                                                        this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id == '4' ? 'COMPLETED'
                                                            :
                                                            this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id == '5' ? 'CANCELLED'
                                                                :
                                                                this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id == '6' ? 'RETURNED'
                                                                    :
                                                                    ''
                                        }
                                    </span>
                                </div>
                                <div className="ml-auto order-date">
                                    <h4><span>Order Date:</span> {Moment(this.state.orderDetailsObject.created_at).format("DD-MM-YYYY")}</h4>
                                </div>
                            </div>

                            {this.state.orderDetailsObject.payment_type === 'online' && this.state.orderDetailsObject.payment_status === null ?
                                <div className="checkout-status">
                                    <h4 className="checkout-title">Your payment of <span className='text-theme-primary'>₹{this.state.orderDetailsObject.total_price}</span> could not be completed. Pls wait for <span className='text-theme-primary'>30 minutes</span>, if still the payment is not completed the order will be removed from your order list, and you will be required to place fresh order..</h4>
                                </div>
                                :
                                this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id == '5' ?
                                    <div className="checkout-status">
                                        <h4 className="checkout-title">Order is Cancelled
                                            {
                                                this.state.orderDetailsObject.payment_type !== "cod" || this.state.orderDetailsObject.payment_type !== "others" ?
                                                    this.state.orderDetailsObject.order_lines[0].is_return_status == 1 ? ` and returned credit in wallet Rs  ${this.state.orderDetailsObject.total_price}`
                                                        : this.state.orderDetailsObject.order_lines[0].is_return_status == 2 ? <h4 className="checkout-title"> and returned credit as per mode {this.state.orderDetailsObject.is_wallet == 1 ?
                                                            `in wallet Rs ${this.state.orderDetailsObject.wallet_amount}`
                                                            : this.state.orderDetailsObject.payment_type === 'online' ? 'in Paytm'
                                                                : this.state.orderDetailsObject.razorpay_response === 'pendisng' ? 'is Pending'
                                                                    : 'is returned successfully with'}
                                                            {this.state.orderDetailsObject.is_wallet == 1 ? 'Rs ' + this.state.orderDetailsObject.total_price - this.state.orderDetailsObject.wallet_amount :
                                                                'Rs ' + this.state.orderDetailsObject.total_price} </h4>
                                                            : " by Naswiz"
                                                    : null
                                            }</h4>
                                    </div>
                                    :
                                    <div className="checkout-status">
                                        <h4 className="checkout-title">Order Status:</h4>
                                        <ul className="checkout-progress-bar">
                                            <li
                                                className={
                                                    this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id == 1 ? 'one active'
                                                        :
                                                        this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id == 2 ? 'one active'
                                                            :
                                                            this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id == 3 ? 'one active'
                                                                :
                                                                this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id == 4 ? 'one active'
                                                                    :
                                                                    ''
                                                }
                                            // className={this.state.orderDetailsObject && this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id === 1  ? 'one active' :''}
                                            > <span>Waiting</span> </li>
                                            <li
                                                // className={'two active'}
                                                className={
                                                    this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id == 2 ? 'two active'
                                                        :
                                                        this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id == 3 ? 'two active'
                                                            :
                                                            this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id == 4 ? 'two active'
                                                                :
                                                                ''
                                                }
                                            // className={this.state.orderDetailsObject && this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id === 2 || 3 || 4 ? 'two active' : ""}
                                            > <span>Preparing</span> </li>
                                            <li
                                                className={
                                                    this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id == 3 ? 'three active'
                                                        :
                                                        this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id == 4 ? 'three active'
                                                            :
                                                            ''
                                                }
                                            // className={'Three active'}
                                            // className={this.state.orderDetailsObject && this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id == 3 || 4 ? 'three active' : ""}
                                            > <span>On the way</span> </li>
                                            <li
                                                className={
                                                    this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id == 4 ? 'four active'
                                                        :
                                                        ''
                                                }
                                            // className={'three active'}
                                            // className={this.state.orderDetailsObject && this.state.orderDetailsObject.order_status_id && this.state.orderDetailsObject.order_status_id ==  4 ? 'four active' : ""}
                                            > <span>Completed</span> </li>
                                        </ul>
                                    </div>
                            }
                            <div className="customer_add_name">
                                <div className="row">
                                    <div className="col-lg-4 col-xs-12 col-sm-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4><b>Customer Name</b></h4>
                                            </div>
                                            <div className="col-md-6">
                                                <h4>{this.state.orderDetailsObject.shipping_fullname}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-xs-12 col-sm-12">
                                        <div className="row">
                                            <div className="col-md-6 text-lg-right">
                                                <h4><b>ID No.</b></h4>
                                            </div>
                                            <div className="col-md-6 ">
                                                <h4>{this.state.orderDetailsObject && this.state.orderDetailsObject.address && this.state.orderDetailsObject.address.address_id_no && this.state.orderDetailsObject.address.address_id_no}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 text-lg-right col-xs-12 col-sm-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4><b>Mobile No.</b></h4>
                                            </div>
                                            <div className="col-md-6">
                                                <h4>+91 {this.state.orderDetailsObject.shipping_mobile}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row same-height">
                                <div className="col-lg-6">
                                    <div className="shipping_address_value">
                                        <h4 className="checkout-title">Shipping Address</h4>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <p>
                                                    {this.state.orderDetailsObject && this.state.orderDetailsObject.address && this.state.orderDetailsObject.address.address ? this.state.orderDetailsObject.address.address : 'Address Not Found'} {this.state.orderDetailsObject.shipping_pincode} ,
                                                    {this.state.orderDetailsObject.shipping_city_name} ,
                                                    {this.state.orderDetailsObject.shipping_state_name} ,
                                                    {this.state.orderDetailsObject.shipping_country_name}
                                                </p>
                                            </div>
                                        </div>
                                        {this.state.orderDetailsObject && this.state.orderDetailsObject.address && this.state.orderDetailsObject.address.gst_no && this.state.orderDetailsObject.address.gst_no.length > 0 ?
                                            this.state.orderDetailsObject.address.gst_no === null ? null : <p className="mt-2"><span>GST Number: </span> {this.state.orderDetailsObject && this.state.orderDetailsObject.address && this.state.orderDetailsObject.address.gst_no && this.state.orderDetailsObject.address.gst_no}</p>
                                            : null}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="shipping_details">
                                        <h4 className="checkout-title text-center">Shipping Details</h4>
                                        <p>
                                            <label>Shipping Method :</label>
                                            <span>
                                                {
                                                    this.state.orderDetailsObject.type == '1' ? 'Courier'
                                                        :
                                                        this.state.orderDetailsObject.type == '2' ? 'By Hand '
                                                            :
                                                            this.state.orderDetailsObject.type == '3' ? 'Self Pick Up'
                                                                : null
                                                }</span>
                                        </p>
                                        {this.state.orderDetailsObject.type == '1' ?
                                            <>
                                                {this.state.orderDetailsObject.courier_name ?
                                                    <p>
                                                        <label>Courier Tracking :</label>
                                                        <span>{this.state.orderDetailsObject.courier_name}</span>
                                                    </p>
                                                    : null
                                                }
                                                {this.state.orderDetailsObject.docket_no ?
                                                    <p>
                                                        <label>Courier Docket no :</label>
                                                        <span>{this.state.orderDetailsObject.docket_no}</span>
                                                    </p>
                                                    : null
                                                }
                                                {this.state.orderDetailsObject.remark_courier ?
                                                    <p>
                                                        <label>Date of courier :</label>
                                                        <span>{Moment(this.state.orderDetailsObject.date_courier).format("DD-MM-YYYY")}</span>
                                                    </p>
                                                    : null
                                                }
                                                <p>
                                                    <label>Remarks :</label>
                                                    <span>{this.state.orderDetailsObject.remark_courier}</span>
                                                </p>
                                            </>
                                            : null
                                        }
                                        {this.state.orderDetailsObject.type == '2' ?
                                            <>
                                                {this.state.orderDetailsObject.delivery ?
                                                    <p>
                                                        <label>Delivery Boy Name :</label>
                                                        <span>{this.state.orderDetailsObject.delivery}</span>
                                                    </p>
                                                    : null
                                                }
                                                {this.state.orderDetailsObject.number ?
                                                    <p>
                                                        <label>Mobile No :</label>
                                                        <span>{this.state.orderDetailsObject.number}</span>
                                                    </p>
                                                    : null
                                                }
                                                {this.state.orderDetailsObject.date_of_dispatch ?
                                                    <p>
                                                        <label>Date of dispatch :</label>
                                                        <span>{Moment(this.state.orderDetailsObject.date_of_dispatch).format("DD-MM-YYYY")}</span>
                                                    </p>
                                                    : null
                                                }
                                                {this.state.orderDetailsObject.hand_remark ?
                                                    <p>
                                                        <label>Remarks :</label>
                                                        <span>{this.state.orderDetailsObject.hand_remark}</span>
                                                    </p>
                                                    : null
                                                }
                                            </>
                                            : null
                                        }
                                        {this.state.orderDetailsObject.type == '3' ?
                                            <>
                                                <p>
                                                    <label>Naswiz Showroom :</label>
                                                    <span>{this.state.orderDetailsObject.naswiz_showroom}</span>
                                                </p>
                                                <p>
                                                    <label>Naswiz Stock Point :</label>
                                                    <span>{this.state.orderDetailsObject.naswiz_sp}</span>
                                                </p>
                                                <p>
                                                    <label>Remarks :</label>
                                                    <span>{this.state.orderDetailsObject.self_remark}</span>
                                                </p>
                                            </>
                                            : null
                                        }
                                        {/* <p>
                                                <label>Courier Tracking :</label>
                                                <span><a target="_blank" href="javascript:;">www.google.com</a></span>
                                            </p>
                                            <p>
                                                <label>Courier Docket No :</label>
                                                <span>NMHYVJ4646654</span>
                                            </p>
                                            <p>
                                                <label>Date Of Courier :</label>
                                                <span>{ Moment( this.state.orderDetailsObject.created_at).format("DD-MM-YYYY")}</span>
                                            </p>
                                            <p>
                                                <label>Remarks :</label>
                                                <span>Lorem Ipsum is simply dummy text of the industry. </span>
                                            </p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="payment_details">
                                        <h4 className="checkout-title">Payment</h4>
                                        {this.state.orderDetailsObject.payment_type !== 'wallet' ?
                                            <p>
                                                <label>Payment Method :</label>
                                                <span>
                                                    {this.state.orderDetailsObject.payment_type == 'atom' ? 'Online'
                                                        :
                                                        this.state.orderDetailsObject.payment_type == 'online' ? 'Online'
                                                            :
                                                            this.state.orderDetailsObject.payment_type == 'others' ? 'Others'
                                                                :
                                                                this.state.orderDetailsObject.payment_type == 'wallet' ? 'Online'
                                                                    :
                                                                    this.state.orderDetailsObject.payment_type == 'cod' ? 'Cod' : null}
                                                </span>
                                            </p>
                                            : null
                                        }
                                        <p>
                                            <label>Payment Type :</label>
                                            <span>{this.state.orderDetailsObject.payment_type == 'online' ? 'Paytm' : this.state.orderDetailsObject.payment_type}</span>
                                        </p>
                                        {this.state.orderDetailsObject.payment_type == 'online' || this.state.orderDetailsObject.payment_type == 'atom' ?
                                            <>
                                                <p>
                                                    <label>Transaction ID / Detail:</label>
                                                    <span>{this.state.orderDetailsObject.payment_reference}</span>
                                                </p>
                                                {/* <p>
                                                        <label>Transaction Detail :</label>
                                                        <span>Lorem Ipsum is simply dummy text of the industry. </span>
                                                    </p> */}
                                            </>
                                            : null
                                        }
                                        {this.state.orderDetailsObject.payment_type == 'others' ?
                                            <>
                                                <p>
                                                    <label>Others Type :</label>
                                                    <span>{this.state.orderDetailsObject.others_type}</span>
                                                </p>
                                                <p>
                                                    <label>Others Amount :</label>
                                                    <span>{this.state.orderDetailsObject.others_amount}</span>
                                                </p>
                                                <p>
                                                    <label>Others Date :</label>
                                                    <span>{Moment(this.state.orderDetailsObject.others_date).format("DD-MM-YYYY")}</span>
                                                </p>
                                                <p>
                                                    <label>Others Transaction :</label>
                                                    <span>{this.state.orderDetailsObject.others_trans}</span>
                                                </p>
                                            </>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="summary_details">
                                        <h4 className="checkout-title">Order Summary</h4>
                                        <p>
                                            <label>Total IP Points :</label>
                                            <span>{this.state.orderDetailsObject.dulpicate_total_incentive_point}</span>
                                        </p>
                                        <p>
                                            <label>MRP Total :</label>
                                            <span>₹{Number(this.state.orderDetailsObject.price) + Number(this.state.orderDetailsObject.taxable_price)}</span>
                                        </p>
                                        <p>
                                            <label>Discount :</label>
                                            <span>-₹{this.state.orderDetailsObject.taxable_price}</span>
                                        </p>
                                        <p>
                                            <label>Total Price :</label>
                                            {/* <span>₹{Number(this.state.orderDetailsObject.price) - Number(this.state.orderDetailsObject.taxable_price)}</span> */}
                                            <span>₹{this.state.orderDetailsObject.price}</span>
                                        </p>
                                        <p>
                                            <label>Delivery Charge :</label>
                                            <span>₹{this.state.orderDetailsObject.shipping_rate}</span>
                                        </p>
                                        <p className="total_amount">
                                            <label>Total Amount :</label>
                                            <span>₹{this.state.orderDetailsObject.total_price}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="items-listing">
                                <h4 className="checkout-title">Total Items <span>{this.state.orderDetailsObject && this.state.orderDetailsObject.order_lines && this.state.orderDetailsObject.order_lines.length > 0 ? "(" + this.state.orderDetailsObject.order_lines.length + ")" : 0}</span></h4>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th className="text-center">Price</th>
                                                <th className="text-center">IP Point</th>
                                                <th className="text-center">Qty</th>
                                                <th className="text-right">Total Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.orderDetailsObject && this.state.orderDetailsObject.order_lines && this.state.orderDetailsObject.order_lines.length > 0 ?
                                                this.state.orderDetailsObject.order_lines.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="product-checkout">
                                                                    <figure className="product-image-container">
                                                                        <a href="javascript:void(0)" className="product-image"> <img src={item.product_image} alt="product" /> </a>
                                                                    </figure>
                                                                    <div className="product-content">
                                                                        <h2 className="product_title">
                                                                            {/* <a href="javascript:void(0)">{item.product_name}</a> */}
                                                                            <Link to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.product_slug}/${item.product_slug}` }} target="_blank">{item.product_name}</Link>
                                                                            {/* <Link target="_blank" to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.product_slug}/${item.product_slug}` }}>{item.product_name}</Link> */}
                                                                        </h2>
                                                                        {item.size === 'No' || item.size === 'no' || item.size === '' ? null :
                                                                            <div className="product-filter"> Size: <span>{item.size}</span></div>}
                                                                        {item.color === 'No' || item.color === 'no' || item.color === '' ? null :
                                                                            <div className="product-filter"> Color: <span>{item.color}</span></div>}
                                                                        {this.state.orderDetailsObject.order_status_id == '4' ?
                                                                            <div className="ratings-container">
                                                                                <div className="product-ratings"> <span className="ratings"></span></div>
                                                                            </div>
                                                                            : null}
                                                                        {this.state.orderDetailsObject.payment_type === 'online' && this.state.orderDetailsObject.payment_status === null ?
                                                                            null :
                                                                            <span>
                                                                                {this.state.orderDetailsObject.order_status_id == '4' ?
                                                                                    <Link to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/myorder/${item.product_slug}`, state: [] }} className="btn-move"> Rate &amp; Review Product  </Link>
                                                                                    : null}
                                                                                {/* <a href="#" className="btn-move"> Return</a> */}
                                                                            </span>}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-center"><span>₹{item.price}</span></td>
                                                            <td className="text-center">{item.incentive_point}</td>
                                                            <td className="text-center">{item.total_quantity}</td>
                                                            <td className="text-right">₹{item.total_price}</td>
                                                        </tr>
                                                    )
                                                }) : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                </Modal>

                {/* cancel order */}
                <Modal
                    title="Cancel Order"
                    footer={null}
                    visible={this.state.cancleOrderModalVisibility}
                    className="cancel_order_modal"
                    onCancel={() => this._resetCancleOrderReqState()}>

                    <div className="modal_main_div">
                        {this.state.orderDetailsObject.payment_type === "cod" || this.state.orderDetailsObject.payment_type === "others" ?
                            null
                            :
                            <div className="radio_btns mb-2">
                                <Radio.Group onChange={(e) => this.setState({ selected_payment_return_mode: e.target.value })} >
                                    {this.state.orderDetailsObject.payment_type === "cod" || this.state.orderDetailsObject.payment_type === "others" ?
                                        <Radio className='d-none' value={2} selected>Wallet</Radio>
                                        // null
                                        :
                                        <>
                                            <Radio value={1}>Wallet</Radio>
                                            <Radio value={2}>As per mode</Radio>
                                        </>
                                    }
                                </Radio.Group>
                            </div>
                        }
                        <div className="cancel_order_details">
                            {this.state.orderDetailsObject && this.state.orderDetailsObject.order_lines && this.state.orderDetailsObject.order_lines.length > 0 ?
                                this.state.orderDetailsObject.order_lines.map((item, index) => {
                                    return (
                                        <div key={index} className="inside_crt">
                                            <div className="media_cart">
                                                <img src={item.product_image} alt="" />
                                            </div>
                                            <div className="list_iten_cart">
                                                <div className="cart_content">
                                                    <h3 className="product-name">{item.product_name}</h3>
                                                    <div className="price-list">
                                                        <span className="product-price">₹{item.price}</span>
                                                        <span className="old-price">₹{item.main_price}</span>
                                                        <span className="off-price">You Save ₹{Number(item.main_price) - Number(item.price)}</span>
                                                    </div>
                                                    <div className="product-filter">
                                                        IP Point
                                                        <span> {item.incentive_point}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="increase_product circle_mp ">
                                                <div className="product-action">
                                                    <div className="product-single-qty circle_mp">
                                                        {/* <input checked={this.state.selectedOrder.includes(item.id)} onChange={() => this.removeOrder(item)} type="checkbox" id={item.id} value={item.id} /> */}
                                                        <input checked={this.state.selectedOrder.includes(item.id)} type="checkbox" id={item.id} value={item.id} />
                                                        <label className="checkbox"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : null
                            }
                        </div>
                        <div className="both_buttons mt-1">
                            {/* <button onClick={() => this.selectAllDefult()} type='button' className="btn btn-primary continue_btns">All </button> */}
                            {this.state.loading_Cancel ?
                                <div class="spinner-border text-danger"></div>
                                :
                                <button onClick={() => this._returnFullOrder()} type='button' className="btn cancel_btns">Cancel Order</button>
                            }
                        </div>
                    </div>
                </Modal>

                <Modal
                    title="Cancel Order"
                    footer={null}
                    visible={this.state.cancleOrderModalVisibilitynew}
                    className="cancel_order_modal not-cancel"
                    onCancel={() => this._resetCancleOrderReqState()}>
                    <div className="logo"><img src={logo} alt="" /></div>
                    <div className="modal_main_div">
                        <img src={logo2} alt="" />
                        <h1 className="text-theme-primary text-center">Order Cannot be canceled after it is processed</h1>
                        {/* <div className="product-filter"> Phone: <span>{this.state._phone}</span></div>
                        <div className="product-filter"> Whatsapp: <span>{this.state._whatsapp}</span></div>
                        <div className="product-filter"> Email: <span>{this.state._email}</span></div> */}
                        <ul>
                            <li>
                                <label><i class="fa fa-mobile mr-2"></i>Phone</label>
                                <a href="tel:011-48101010">{this.state._phone}</a>
                            </li>
                            <li>
                                <label><i class="fab fa-whatsapp mr-2"></i>Whatsapp</label>
                                <a href="https://wa.me/+919717033786" target="_blank">{this.state._whatsapp}</a>
                            </li>
                            <li>
                                <label><i class="fa fa-envelope mr-2"></i>Email</label>
                                <a href="mailto:Info@naswiz.com" target="_blank">{this.state._email}</a>
                            </li>
                        </ul>
                    </div>

                </Modal>

                {/* return failed */}
                <Modal
                    title="Cancel Order"
                    footer={null}
                    visible={this.state.model_notReturnable}
                    className="cancel_order_modal not-cancel"
                    onCancel={() => this.setState({ model_notReturnable: false })}>
                    <div className="logo"><img src={logo} alt="" /></div>
                    <div className="modal_main_div">
                        <h1 className="product-title text-theme-primary text-center">Your Order is not returnable please contact to admin.</h1>
                        {/* <div className="product-filter"> Phone: <span>{this.state._phone}</span></div>
                        <div className="product-filter"> Whatsapp: <span>{this.state._whatsapp}</span></div>
                        <div className="product-filter"> Email: <span>{this.state._email}</span></div> */}
                        <ul>
                            <li>
                                <label><i class="fa fa-mobile mr-2"></i>Phone</label>
                                <a href="tel:011-48101010">{this.state._phone}</a>
                            </li>
                            <li>
                                <label><i class="fab fa-whatsapp mr-2"></i>Whatsapp</label>
                                <a href="https://wa.me/+919717033786" target="_blank">{this.state._whatsapp}</a>
                            </li>
                            <li>
                                <label><i class="fa fa-envelope mr-2"></i>Email</label>
                                <a href="mailto:Info@naswiz.com" target="_blank">{this.state._email}</a>
                            </li>
                        </ul>
                    </div>

                </Modal>

            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        allState: state
    }
}

// export default connect(mapStateToProps, { getCartData })(InnerDashboardMyOrders)
export default withRouter(connect(mapStateToProps, { getCartData })(InnerDashboardMyOrders));