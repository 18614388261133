// import React, { Component, useState } from 'react';
// import { Helmet } from 'react-helmet'
// import { connect } from 'react-redux'
// import { Link, Route, BrowserRouter } from 'react-router-dom'
// import { getCartData } from '../../actions'
// import { base_url } from '../../helper';
// import { NaswizHelperApi } from '../../NaswizHelper';
// import Notification from '../../Notification'



// class order_cancel extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             arr_userOrder: [],
//             _order_id: '',
//             uniueId: '',

//         }
//     }

//     componentDidMount = async () => {

//         var orderid = await localStorage.getItem('order_id')
//         var uniueId = localStorage.getItem('deviceId')

//         window.scrollTo(0, 0)

//         // var payment_type = localStorage.getItem('payment_type')
//         // this.setState({ uniueId: uniueId })
//         // this.setState({ _order_id: orderid })
//         // if (orderid != '' && orderid != null) {
//         //     if (payment_type == 'online') {
//         //         await localStorage.setItem('payment_type', '')

//         //         var formData = new FormData()
//         //         formData.append('order_id', orderid)
//         //         formData.append('device_id', uniueId)

//         //         var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/check-payment', formData, 'Error => v1/check-referral')

//         //         if (res.success == 1) {
//         //             Notification('success', 'Success!', res.message)
//         //             await localStorage.setItem('order_id', '')
//         //             this.getCartList()
//         //         } else {
//         //             Notification('error', 'Error!', '' + res.message)

//         //         }
//         //         await localStorage.setItem('payment_type', '')
//         //     }
//         // }
//         setTimeout(function () {
//             document.querySelector(".loader-wrapper").style = "display: none";
//         }, 2000); 
//     }
//     async getCartList() {
//         var formdata = new FormData()
//         formdata.append('device_id', this.state.uniueId)
//         this.props.getCartData('v1/get-cart-list', formdata, "Error => GetCartList api.")

//     }

//     render() {

//         return (
//             <div>
//                 <section className="section-b-space light-layout">
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-12">
//                                 <div className="success-text">
//                                     <p className="small-title">Your order is not confirmed,</p>
//                                     <h2>as your payment could not be processed.</h2>
//                                     <div className="content">
//                                     <a className="btn btn-dark add-cart" href="checkout" title="Add to Cart">Try Again</a>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//             </div>
//         )
//     }


// }
// const mapStateToProps = (state) => ({
//     isUserLogin: state.login.isUserLogin,
//     cartListArray: state.login.userCartData.records.cart_list,

// })

// export default connect(mapStateToProps, { getCartData })(order_cancel)


import React, { Component, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link, Route, BrowserRouter } from 'react-router-dom'
import { getCartData } from '../../actions'
import { base_url } from '../../helper';
import { NaswizHelperApi } from '../../NaswizHelper';
import Notification from '../../Notification'
import Seo from '../Seo';



const order_cancel = (props) => {
    const [arr_userOrder, setArr_userOrder] = useState([])
    const [_order_id, set_order_id] = useState('')
    const [uniueId, setUniqueId] = useState('')


    useEffect(() => {
        // var orderid = localStorage.getItem('order_id')
        // var uniueId = localStorage.getItem('deviceId')

        window.scrollTo(0, 0)
        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
    }, [''])
    // const getCartList = async () => {
    //     var formdata = new FormData()
    //     formdata.append('device_id', uniueId)
    //     props.getCartData('v1/get-cart-list', formdata, "Error => GetCartList api.")

    // }

    return (
        <div>
            <Seo title="Order-cancel" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />

            <section className="section-b-space light-layout">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="success-text">
                                <p className="small-title">Your order is not confirmed,</p>
                                <h2>as your payment could not be processed.</h2>
                                <div className="content">
                                    <Link className="btn btn-dark add-cart" to="checkout" title="Add to Cart">Try Again</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
const mapStateToProps = (state) => ({
    isUserLogin: state.login.isUserLogin,
    cartListArray: state.login.userCartData.records.cart_list,

})

export default connect(mapStateToProps, { getCartData })(order_cancel)
