import React, { Component, ReactPropTypes, useEffect, useState } from 'react';
import * as Scroll from 'react-scroll';
import { Link, NavLink, Redirect, Route, animateScroll as scroll, scrollSpy, scroller, withRouter, useHistory } from 'react-router-dom';
import Pace from 'react-pace-progress'
import TopBar from "./common/topbar";
import LogoImage from "./common/logo";
import { changeCurrency, changeLoginState, removeUserData, manageUserIdState, addUserData, getCartData, getCartDataWithoutLogin } from '../../../actions'
import { connect } from "react-redux";
import Modal from 'react-responsive-modal';
import Notification from '../../../Notification'
import { Row, Tooltip, Col, Checkbox, Icon, Progress, Input, Tag, Select } from 'antd';
import { NaswizHelperApi } from '../../../NaswizHelper';
import OtpInput from 'react-otp-input';
import cart_icon from '../../../assets/images/emptycart2.jpeg'
import Voice from './Voice'
import { detect } from 'detect-browser'
import HeaderMobile from './header-mobile'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import PropTypes from 'prop-types'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'

import whatsappicon from '../../../assets/images/whatsapp-icon.png'

const browser = detect()
var SpeechRecognition = ''
var mic = ''
if (browser) {
	if (browser.name == 'chrome') {
		SpeechRecognition =
			window.SpeechRecognition || window.webkitSpeechRecognition
		mic = new SpeechRecognition()

		mic.continuous = true
		mic.interimResults = true
		mic.lang = 'en-US'
	}
}

class HeaderOne extends Component {

	constructor(props) {
		super(props);

		this.state = {
			megaMenuCategoryList: props.megaMenuCategoryList,
			megaMenuAllCatList: props.megaMenuAllCatList,
			masterCategoryList: props.masterCategoryList,
			allMasterCategoryList: props.allMasterCategoryList,
			categoryDataWhenOnHover: props.categoryDataWhenOnHover,
			//
			dataWhenOnHover: [],

			phoneNumberModalVisibility: false,
			otpModalVisibility: false,
			phoneNumber: '',
			otp_1: '',
			otp_2: '',
			otp_3: '',
			otp_4: '',
			otp: '',
			activeModalType: '',
			arr_searchResult: [],
			searchTerm: '',
			isListening: false,
			note: '',
			savedNotes: [],
			sentmessage: '',
			clientWhatsapp: '',
			uniueId: '',
			_search_text: '',
			_searchTotal: '',
			_browsName: '',
			_mainCateSlecred: '',
			_counter: 60,
			_counternew: 10,
			_showbutton: false,
			_is_whatsapp: '',
			__is_search: false,
			__isredirest: false,
			timer: null,
			_counternew: 10,
			_showbutton: false,
			regexp: /^[0-9\b]+$/,
			show_product: 12,
			isEmailAdd: false,
			_isMenuOpen: false,
			setCountryCode: undefined,
			_isRegWhatsapp: 0, //check condition from login api 0: not register as a wahtsapp,1: register
			_checkRegisteModal: false,
			search: "",
		}
		this.handleSubmit = this.handleSubmit.bind(this);


	}
	async setNote(transaction) {
		await this.setState({ note: transaction })
		this.handleSaveNote()
	}
	onHandleTelephoneChange = e => {
		let telephone = e.target.value;
		// if value is not blank, then test the regex
		if (telephone === '' || this.state.regexp.test(telephone)) {
			this.setState({ phoneNumber: telephone })

		}
		else {
			return;
		}
	};
	handleSaveNote = async () => {
		await this.setState({ savedNotes: this.state.note })
		this.setState({ note: '' })
		document.getElementById("GlobalSearchInput").value = this.state.savedNotes;
		// console.log('search......1111')
		await this._search(this.state.savedNotes, 1)

		mic.stop()
		mic.onend = () => {
		}
		await this.setState({ isListening: false })

	}

	handleListen = async () => {
		await this.setState({ isListening: !this.state.isListening })

		if (!this.state.isListening) {
			await this.setState({ note: '' })
			await this.setState({ savedNotes: '' })
		}

		if (this.state.isListening) {
			mic.start()
			mic.onend = () => {
				mic.start()
			}
		} else {
			mic.stop()
			mic.onend = () => {
			}
			// mic.abort()
		}
		mic.onstart = () => {
		}

		mic.onresult = event => {
			this.setState({ note: '' })
			const transcript = Array.from(event.results)
				.map(result => result[0])
				.map(result => result.transcript)
				.join('')
			this.setState({
				_search_text: transcript
			})
			this.setNote(transcript)
			mic.onerror = event => {
			}
		}
	}


	componentDidMount() {
		var _whatsapp = localStorage.getItem('is_whatsapp')
		this.setState({ _is_whatsapp: _whatsapp })
		if (browser) {

			this.setState({ _browsName: browser.name })
		}
		var uniueId = localStorage.getItem('deviceId')
		this.setState({ uniueId: uniueId })

	}
	setCounter = () => {

		var that = this
		let timer = setInterval(that.manageTimer, 1000);
		that.setState({ timer });

	}

	manageTimer = () => {

		var that = this
		var { timer, _counter } = that.state

		if (_counter === 0) {
			clearInterval(timer)
			that.setState({
				_counter: 0
			})
		}
		else {
			that.setState({
				_counter: _counter - 1
			});

		}
	}

	async resendOtp() {
		await this.setState({ _counter: 60, })
		await this._sendOtp();

	}

	componentDidUpdate() {

	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}
	handleChange = otp => this.setState({ otp });

	_voice = () => {
		var { recognization } = this.state
		recognization.onstart = () => {
		}
		recognization.onresult = (e) => {
		}
	}

	openNav() {
		var openmyslide = document.getElementById("mySidenav");
		if (openmyslide) {
			openmyslide.classList.add('open-side')
		}
	}

	openSearch() {
		document.getElementById("search-overlay").style.display = "block";
	}

	closeSearch() {
		document.getElementById("search-overlay").style.display = "none";
	}
	handleListen22 = async () => {
		await this.setState({ isListening: !this.state.isListening })
		if (!this.state.isListening) {
			await this.setState({ note: '' })
		}
		if (this.state.isListening) {
			mic.start()
			mic.onend = () => {
				mic.start()
			}
		} else {
			mic.stop()
			mic.onend = () => {
			}
		}
		mic.onstart = () => {
		}

		mic.onresult = event => {
			const transcript = Array.from(event.results)
				.map(result => result[0])
				.map(result => result.transcript)
				.join('')
			this.setNote(transcript)
			mic.onerror = event => {
			}
		}
	}
	_showOnHover = (ID) => {
		if (this.props.categoryDataWhenOnHover && this.props.categoryDataWhenOnHover.length > 0) {
			var dataWhenOnHover = []
			this.props.categoryDataWhenOnHover.map((item, index) => {
				if (item.id == ID) {
					dataWhenOnHover = item.items
				}
			})
			this.setState({ dataWhenOnHover })
		}
	}

	async handleSubmit(event) {
		await this.setState({
			__isredirest: true
		})
		setTimeout(() => {
			this.setState({ _search_text: '' })
		}, 5000)
		event.preventDefault();
	}

	_search = async (searchTerm, type) => {
		await this.setState({
			__isredirest: false
		})
		// return;	

		this.setState({ searchTerm, _search_text: searchTerm, __is_search: false })
		if (searchTerm.length > 1) {
			this.setState({ search: searchTerm })
			var body = new FormData()
			body.append('search', searchTerm)
			body.append('paginate', this.state.show_product)
			body.append('device_id', this.state.uniueId)

			var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/search/product', body, "Error => v1/search api.")
			if (type == 1) {

				await this.setState({
					__isredirest: true
				})

			}
			if (res && res.success == 1) {
				this.setState({
					arr_searchResult: res.records.data,
					_searchTotal: res.records.total
					// __isredirest: true
				})
				this.forceUpdate()
			}
			// console.log(res);
			// console.log('search test 222', searchTerm, type)


		}
		if (searchTerm.length == 0) {
			this.setState({
				arr_searchResult: []
			})
		}
		else {
			this.setState({
				// arr_searchResult: []
			})
		}

	}

	redirectToSearchList() {

	}

	handleClick = async (item, qty) => {
		var body = new FormData()
		body.append('cart_id', item.cart_id)
		body.append('quantity', item.total_quantity + qty)

		for (var pair of body.entries()) {
		}

		var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/update-cart', body, "Error => v1/add-usercart")
		if (res && res.success == 1) {
			Notification('success', 'Success!', res.message ? res.message : 'Product update successfully.')
			// this.productDetailApiWithSlug(this.state.slugs, 'After added to cart');
			if (this.props.isUserLogin) {
				var body = new FormData()
				body.append('device_id', this.state.uniueId)
				this.props.getCartData('v1/get-cart-list', body, "Error => GetCartList api.")

			} else {
				var body = new FormData()
				body.append('device_id', this.state.uniueId)
				this.props.getCartDataWithoutLogin('v1/get-cart-list-without-login', body, "Error => get-cart-list-without-login.")

			}

		}
		else {
			Notification('error', 'Error!', res.message ? res.message : 'Login First')
		}


	}

	deleteCart = async (item) => {

		var res = await NaswizHelperApi.GET_WITHOUT_TOKEN('v1/delete-cart/' + item.cart_id, body, "Error => v1/add-usercart")
		if (res && res.success == 1) {
			Notification('success', 'Success!', res.message ? res.message : 'Product deleted successfully.')
			if (this.props.isUserLogin) {
				var body = new FormData()
				body.append('device_id', this.state.uniueId)
				this.props.getCartData('v1/get-cart-list', body, "Error => GetCartList api.")

			} else {
				var body = new FormData()
				body.append('device_id', this.state.uniueId)
				this.props.getCartDataWithoutLogin('v1/get-cart-list-without-login', body, "Error => get-cart-list-without-login.")

			}
		}
		else {
			Notification('error', 'Error!', res.message ? res.message : 'Login First')
		}

	}
	handleinputSubmit = (e) => {
		if (this.state.phoneNumber && this.state.phoneNumber.length >= 13) {
			this._sendOtp()
		}
	}
	handleKeyPress = (event) => {
		// look for the `Enter` keyCode
		if (event.keyCode === 13 || event.which === 13) {
			this.handleinputSubmit();
		}
	}

	handleSubmit(event) {
		if (this.state.phoneNumber && this.state.phoneNumber.length >= 13) {
			this._sendOtp()
		}
		event.preventDefault();
	}

	handleSubmit = (event) => {
		if (this.state.otp.length == 4) {
			this._verifyOtp()
		}
		event.preventDefault();
	}
	handlesearchSubmit = (e) => {
		if (e.keyCode === 13 || e.which === 13) {
			if (this.state._search_text && this.state._search_text.trimStart()) {
				this.props.history.push({
					pathname: `/search/${this.state._search_text}`,
					state: this.state.arr_searchResult
				});
			}
		}
	}

	render() {
		var { masterCategoryList, allMasterCategoryList, social_arr, topbar_cms, useData, cartListArray, cartTotalArray } = this.props
		return (
			<div>
				<HeaderMobile closeMenu={() => this.setState({ _isMenuOpen: false })} isMenuOpen={this.state._isMenuOpen} top_bar_cms={topbar_cms} social_arr={social_arr} categoryDataWhenOnHover={this.props.categoryDataWhenOnHover} masterCategoryList={this.props.masterCategoryList} allMasterCategoryList={allMasterCategoryList} />

				<div className="page-wrapper" >
					<div className="voiceSearch" style={{ display: this.state.isListening ? ' block ' : 'none' }} >
						<div className="text_speak">
							<input value={this.state._search_text} onChange={e => this._search(e.target.value, 1)} onClick={e => this._search(e.target.value, 1)} autoComplete="off" type="search" className="form-control" name="q" id="GlobalSearchInput" placeholder="Search for products, brands and more" required />
						</div>
						<div className="icon_speak">
							<div className="div_mf">
								<div className="pulse">
									<div className="pulse custom microphonepulse">
										<i className="fas fa-microphone"></i>
									</div>
								</div>
							</div>
						</div>
						<div className="cross_speak">
							<a href="javascript:void();" onClick={() => this.handleListen()}><i className="fas fa-times"></i></a>
						</div>
					</div>
					<header id="sticky" className="header" >
						<TopBar
							top_bar_cms={topbar_cms}
							social_arr={social_arr}
						/>

						<div>
							{this.state.isLoading ? <Pace color="#27ae60" /> : null}

							<div className="mobile-fix-option"></div>

							{/* <div className="sticky-wrapper"> */}
							<div className="header-middle sticky-header c-fix-header">
								<div className="container d-flex justify-content-between align-items-center">
									<div className="header-left col-lg-2 w-auto pl-0">
										<button className="mobile-menu-toggler text-theme-primary mr-2" type="button">
											<i className="icon-menu" onClick={() => {
												this.setState({ _isMenuOpen: true })
											}}></i>
										</button>
										<LogoImage logo={this.props.logoName} />
									</div>

									<div className="header-right w-lg-max">

										<div className="posirel header-search header-search-inline header-search-category w-lg-max text-right">
											<a href="/" className="search-toggle" role="button"><i className="icon-search-3"></i></a>


											<form onSubmit={this.handleSubmit} className="header-search-wrapper">
												<input value={this.state._search_text} onChange={e => this._search(e.target.value, 2)} onKeyDown={this.handlesearchSubmit} onClick={e => this._search(e.target.value, 2)} autoComplete="off" type="search" className="form-control" name="q" id="GlobalSearchInput" placeholder="Search for products, brands and more" required />

											</form>
											{
												this.state.arr_searchResult && this.state._search_text != '' && this.state.arr_searchResult.length >= 1 ?
												
													<div className="search_drp d-none" id="showSearchDiv" >
														<div className="searched_heading">
															<p>Showing result for <b>{this.state.searchTerm}</b> {this.state._searchTotal} record found</p>
														</div>
														{/* <Redirect to={{ pathname: `${process.env.PUBLIC_URL}/Search/${this.state._search_text}`, state: this.state.arr_searchResult }} /> */}
														<ul>
															{
																this.state.arr_searchResult.map((item, index) => {
																	// console.log(item);
																	return (
																		<li key={index}>
																			{/* <Link to=""></Link> */}
																			<Link to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.slug}/${item.slug}` }}>
																				{/* <Link to="#"> */}
																				<img src={item.product_images.length > 0 ? item.product_images[0].image : "assets/images/my-profile.svg"} alt="" />
																				<p className="search-title">{item.name}</p>
																				<span className="brder_bt">{item.wholesale_price ? '₹' + item.wholesale_price : '₹' + item.price}</span>
																			</Link>
																		</li>
																	)
																})
															}
														</ul>
													</div> :
													<div className="search_drp" id="showSearchDiv">
														<div className={this.state._search_text.length > 0 && this.state._search_text.length < 3 ? 'searched_heading' : 'd-none'}>
															<p>Minimum 3 Character</p>
														</div>
													</div>
											}
											{
												this.state._searchTotal === 0 ?
													<div className="search_drp" id="showSearchDiv">
														<div className={this.state._search_text.length < 3 ? 'd-none' : 'searched_heading'}>
															<p>Showing result for <b>{this.state.searchTerm}</b>  record  not found</p>
														</div>
													</div>
													:
													null
											}

											{
												this.state.__isredirest ? <Redirect to={{ pathname: `${process.env.PUBLIC_URL}/Search/${this.state._search_text}`, state: this.state.arr_searchResult }} /> : null
											}
										</div>


										{this.state._browsName == 'chrome' ? <div className={this.state.isListening ? 'box active' : 'box'}>
											<button onClick={() => this.handleListen()} className={this.state.isListening ? 'pulse' : 'pulse'}>
												{this.state.isListening ? <i className="fas fa-microphone"></i> : <i className="fas fa-microphone"></i>}
											</button>
											{/* <p>{this.state.savedNotes}</p> */}
										</div> : null}
										{this.state._search_text && this.state._search_text.trimStart()  ?
											<Link className="icon-search-three p-0" to={{ pathname: `${process.env.PUBLIC_URL}/Search/${this.state._search_text}`, state: this.state.arr_searchResult }} >
												<i className="fas fa-search"></i>
											</Link>
											:
											<Link className="icon-search-three p-0" to="#" >
												<i className="fas fa-search"></i>
											</Link>
										}
									</div>
									{
										social_arr && social_arr.length > 0 ?
											social_arr.map((item, index) => {
												return (

													item.name === 'whatsapp' && item.value ?
														<div key={index} className="header-contact d-none d-lg-flex">
															<img alt="phone" src={whatsappicon} width="30" height="30" />
															<h6>Chat now<a href={`https://wa.me/${item.value}`} target="_blank" className="text-dark font1">{item.value}</a></h6>
														</div> : null
												)
											}) : null
									}

									<div className="dropdown cart-dropdown top-index user_log_dp">
										<a href="#" className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
											<i className="icon-user-2"></i>
										</a>
										<div className="dropdown-menu">
											<div className="dropdownmenu-wrapper">
												<div className="login_dp">
													<ul className="list-unstyled">
														{this.props.isUserLogin ?
															<div>
																<li>
																	<div className="full_widths">
																		<div className="pro_media">
																			<img src="assets/images/my-profile.svg" alt="" />
																		</div>
																		<div className="pro_content">
																			<p>{useData && useData.number ? useData.number : ''}</p>
																		</div>
																	</div>
																</li>

																<li className="dashboard">
																	<Link to={{ pathname: `${process.env.PUBLIC_URL}/page/profileDashboard`, itemviewname: 'My Profile' }}>
																		<i className="fas fa-user-circle"></i> My Account
																	</Link>
																</li>

																<li className="dashboard">
																	<Link to={{ pathname: `${process.env.PUBLIC_URL}/page/user/myorder`, itemviewname: 'My Order' }}>
																		<i className="fas fa-gift"></i> My Orders
																	</Link>
																</li>

																<li className="dashboard">
																	<Link to={{ pathname: `${process.env.PUBLIC_URL}/page/user/myaddress`, itemviewname: 'My Address' }}>
																		<i className="fas fa-search-location"></i> My Addresses
																	</Link>
																</li>
																<li className="dashboard">
																	<Link to={{ pathname: `${process.env.PUBLIC_URL}/page/user/mywallet`, itemviewname: 'My Wallet' }}>
																		<i className="fas fa-wallet"></i> My Wallet
																	</Link>
																</li>
																<li className="my_logout">
																	<a onClick={() => this.logout()} href="javascript:void(0)">
																		<i className="fas fa-sign-out-alt"></i>
																		Logout
																	</a>
																</li>
															</div> :
															<li>
																<a href="javascript:void(0)"
																	type="button"
																	onClick={() => {
																		this.setState({ phoneNumber: '', _checkRegisteModal: false, phoneNumberModalVisibility: true, activeModalType: 'LOGIN', _showbutton: false })
																	}}
																	data-toggle="modal"
																	data-target={this.state.modalID}
																	className={'login_btn_a active_signup avtiev_login'}
																	style={{ width: '100%!important' }}
																>
																	<i className="fas fa-sign-in-alt" style={{ marginRight: '7px' }}></i>Login or Sign up
																</a>
															</li>
														}
													</ul>
												</div>
											</div>
										</div>
									</div>

									{this.props.isUserLogin ?
										<Link to={{ pathname: `${process.env.PUBLIC_URL}/page/user/myfavourites`, itemviewname: 'My Favourites' }} className="header-icon wishlist-icon">
											<i className="icon-wishlist-2"></i></Link>
										: <a href="javascript:void(0)" onClick={() => {
											this.setState({ phoneNumberModalVisibility: true, activeModalType: 'LOGIN' })
										}} data-toggle="modal" data-target={this.state.modalID} className="header-icon"><i className="icon-wishlist-2"></i></a>
									}

									<div className="dropdown cart-dropdown top-index cart">
										<a href="#" className="dropdown-toggle dropdown-arrow" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
											<svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
												<path fillRule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
											</svg>
											{this.props.cartListArray.length > 0 ?
												this.props.isUserLogin ?
													<span className="cart-count badge-circle">{this.props.cartTotalArray.total_qty}</span>
													:
													<span className="cart-count badge-circle">{this.props.cartTotalArray.total_qty}</span> : null
											}
										</a>

										<div className="dropdown-menu">
											<div className="dropdownmenu-wrapper">
												<div className="my_cart_top">
													<p>My Cart</p>
												</div>
												{this.props.cartListArray.length > 0 ?
													<div>
														<div className="fix_scroll">

															<div className="product_list_d">
																<div className="sub_t">
																	<p>Sub Total<span>Delivery Charges <i className="fa fa-question-circle" aria-hidden="true"></i></span> </p>
																</div>
																<div className="sub_r">
																	<p>₹{this.props.cartTotalArray.price - this.props.cartTotalArray.shipping_charge}<span>+ ₹{this.props.cartTotalArray.shipping_charge}</span></p>
																</div>
															</div>
															<div className="product_list_d brder_top brder_btm">
																<div className="sub_t font_differ">
																	<p>Total IP points</p>
																</div>
																<div className="sub_r font_differ">
																	<p className="redrCOlor">{this.props.cartTotalArray.total_ip}</p>
																</div>
															</div>
															<div className="start_bootm_pro">
																{/* <div className="notifica_deli">
																		<p>Item(s) not available will be removed on checkout.</p>
																	</div> */}

																{
																	this.props.cartListArray.map((item, index) => {
																		return (
																			<div key={index} className="items_w_img">
																				<div className="procut_rows">
																					<div className="left_media">
																						<Link
																							to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.slug}/${item.slug}`, state: [{ 'size': item.size, 'color': item.color_name, 'size_id': item.size_id, 'color_id': item.color_id }] }}
																						><img src={`${item.image}`} alt="product" /></Link>
																					</div>
																					<div className="right_media">
																						<div className="available_items">
																							<p className="off_perS">IP Point : {item.total_incentive_points}</p>
																							<div className="clearfix"></div>
																							<Link
																								to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.slug}/${item.slug}`, state: [{ 'size': item.size, 'color': item.color_name, 'size_id': item.size_id, 'color_id': item.color_id }] }}
																							><p>
																									{item.product_name}
																								</p></Link>
																							{item.size != 'No' ? <div className="combine_prices" >
																								<span className="product-price">Size : {item.size}</span>


																							</div> : null}
																							{item.color_name != 'No' ? <div className="combine_prices">
																								<span className="product-price">Color : {item.color_name}</span>
																							</div> : null}
																							<div className="bottom_rmv">
																								<div className="cart_left">
																									<div className="product-single-qty  circle_mp_cart">
																										<div className="def-number-input number-input">
																											<button className="minus" onClick={() => {
																												if (item.total_quantity > 1) {
																													this.handleClick(item, -1)
																												} else {
																													this.deleteCart(item)
																												}
																											}}></button>
																											<input className="quantity" name="quantity" type="number" value={item.total_quantity} onChange={this.handleChange} />
																											<button className={item.total_quantity == item.max_order_qty ? "plus disable" : "plus"} onClick={() => {
																												if (item.total_quantity == item.max_order_qty) {
																												} else {
																													this.handleClick(item, 1)
																												}
																											}}></button>

																										</div>
																									</div>
																								</div>
																								{/* <div className='cart_delete'onClick={() => this.deleteCart(item)}><i className="fa-solid fa-trash-can"></i></div> */}
																								<div className='cart_delete' onClick={() => this.deleteCart(item)}></div>
																								<div className="cart_middle">
																									<div className="combine_prices">

																										<span className="product-price">₹{item.price}</span>&nbsp;&nbsp;
																										<span className="old-price">₹{item.main_price}</span>
																									</div>
																								</div>
																								<div className="cart_right">
																									<p>₹{item.total_price}</p>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		)
																	})
																}
															</div>
														</div>
														<div className="item_sub_check">
															<Link to={{ pathname: `${process.env.PUBLIC_URL}/cart` }} onClick={() => localStorage.removeItem("company")} >
																<div className="left_check">
																	<p>Proceed to Cart</p>
																</div>
																<div className="right_check">
																	<p>₹{this.props.cartTotalArray.price}   <i className="fa fa-angle-right" aria-hidden="true"></i></p>
																</div>
															</Link>
														</div>
													</div>
													:
													<div className="" style={{ minHeight: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
														{/* <h3>YOUR CART IS EMPTY</h3> */}
														<img alt="phone" src={cart_icon} className="pb-1" />

													</div>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="header-bottom sticky-header c-fix-header d-none d-lg-block">
								<div className="container">
									<nav className="main-nav w-100">
										<ul className="menu">
											{masterCategoryList && masterCategoryList.length > 0 ?
												masterCategoryList.map((item, index) => {
													return (
														<li key={index} className={item.is_subcategory == 0 ? item.slug == this.state._mainCateSlecred ? "active" : "" : item.slug == this.state._mainCateSlecred ? "drop-down active" : "drop-down"}>
															<Link
																to={{ pathname: `${process.env.PUBLIC_URL}/Products/${item.slug}`, state: [{ 'slug': item.slug, 'name': item.name, 'category': item }] }}
																onMouseEnter={() => this._showOnHover(item.id)}
																onClick={() => this.setState({ _mainCateSlecred: item.slug })}
															>
																{item.meta_title}
															</Link>
															{this.state.dataWhenOnHover.length > 0 ?
																<div className="megamenu megamenu-fixed-width megamenu-3cols">
																	<div className="row">
																		<div className="col-lg-8 pt-0">
																			<div className="row">
																				{this.state.dataWhenOnHover.length > 0 ?
																					this.state.dataWhenOnHover.map((itm, inx) => {
																						return (
																							<div key={inx} className="col-lg-4">
																								<Link
																									onClick={() => this.setState({ _mainCateSlecred: item.slug })}
																									to={{ pathname: `${process.env.PUBLIC_URL}/Products/${itm.slug}`, state: [{ 'slug': itm.slug, 'name': itm.name, 'category': itm }] }}>{itm.name}</Link>
																								{itm.items && itm.items.length > 0 ?
																									itm.items.map((i, x) => {
																										return (
																											<ul key={x} className="submenu">
																												<li>
																													<Link
																														onClick={() => this.setState({ _mainCateSlecred: item.slug })}
																														to={{ pathname: `${process.env.PUBLIC_URL}/Products/${i.slug}`, state: [{ 'slug': i.slug, 'name': i.name, 'category': i }] }}>{i.name}</Link>
																												</li>
																											</ul>
																										)
																									})
																									: null
																								}
																							</div>
																						)
																					})
																					: null
																				}
																			</div>
																		</div>
																		<div className="col-lg-4 p-0">
																			<img src={item.banner_image_full_path ? item.banner_image_full_path : "assets/images/menu-banner.jpg"} alt="Menu banner" />
																		</div>
																	</div>
																</div>
																: null
															}
														</li>
													)
												})
												:
												null
											}
											{name.map((item, index) => {
												return (
													<li key={index} className={this.state._mainCateSlecred == 'all' ? 'drop-down active' : "drop-down"}>
														{allMasterCategoryList && allMasterCategoryList.length > 0 ?
															<>
																<Link
																	to={{ pathname: `${process.env.PUBLIC_URL}/Products/all`, state: [] }}
																	onClick={() => this.setState({ _mainCateSlecred: 'all' })}>{item}</Link>
																<div className="megamenu megamenu-fixed-width megamenu-6cols" style={{ maxHeight: '500px', overflowY: 'scroll', marginLeft: '0', marginRight: '0', right: '0' }}>
																	<div className="row">
																		<div className="col-lg-8 p-0">
																			<div className="row">
																				{allMasterCategoryList && allMasterCategoryList.length > 0 ?
																					allMasterCategoryList.map((itm, inx) => {
																						return (
																							<div key={inx} className="col-lg-3">
																								<ul className="submenu">
																									<Link to={{ pathname: `${process.env.PUBLIC_URL}/Products/${itm.slug}`, state: [{ 'b_slug': itm.slug, 'b_name': itm.name, 'category': itm }] }}>
																										{itm.name}
																									</Link>
																								</ul>
																							</div>
																						)
																					}) : null
																				}
																			</div>
																		</div>
																		<div className="col-lg-4 p-0">
																			<img src={item.backend_banner_image ? item.backend_banner_image : "assets/images/menu-banner.jpg"} alt="Menu banner" />
																		</div>
																	</div>
																</div>
															</> : null
														}
													</li>
												)
											})
											}

											<li className="float-right"><Link to="/phytowiz" className="px-4" onClick={() => localStorage.removeItem("company")} >Phytowiz<span className="tip tip-new tip-top">New</span></Link></li>
											<li className="float-right mr-0"><Link to="/alkaline-ionizer" className="px-4" onClick={() => localStorage.removeItem("company")} >Alkaline Ionizer<span className="tip tip-new tip-top">New</span></Link></li>
										</ul>
									</nav>
								</div>
							</div>
							{/* </div> */}
						</div>
					</header>
					{
						// login flow
						<Modal
							onClose={() => this.setState({ phoneNumberModalVisibility: false })}
							// autoFocus={true} 
							open={this.state.phoneNumberModalVisibility}
							center>
							<div className={'login_modal'}>
								{this.state._is_whatsapp == '0' ? <h4 className={'login_modal_title'}><i className="fas fa-sms"></i> Mobile Number Verification</h4> : <h4 className={'login_modal_title'}><i className="fab fa-whatsapp"></i> WhatsApp Number Verification</h4>}
								{this.state._checkRegisteModal ? <div className={'login_modal_number_box logintext'}>
									{/* <p className="mantitle">Information</p> */}
									<div><span className="title">For Sign Up, Please register your self on WhatsApp</span></div>
									<div><span className="subtitle">Follow the below steps for registration </span></div>
									<div><span >1. Save the Naswiz WhatsApp No to your contacts :  +919540276786 </span></div>
									<div><span >2. Send Hi on WhatsApp On this Naswiz WhatsApp No +919540276786 </span></div>
									<div><span >3. Press Next </span></div>


									{/* <button onClick={() => this.setState({ _checkRegisteModal: false })} className={'login_modal_next_btn_click common_login_sign_up_btn'} type="button">Ok</button> */}
								</div> : null}
								<div className={'login_modal_number_box'}>

									{this.state._is_whatsapp == '0' ? <p>Enter your Mobile number to Login</p> : <p>Enter your WhatsApp number to Login</p>}

									<div className={'login_modal_phone number-hide'}>
										<PhoneInput
											international
											defaultCountry="IN"
											focusInputOnCountrySelection
											value={this.state.phoneNumber}
											limitMaxLength={true}
											autoFocus={true}
											onChange={(value) => {
												this.setState({ phoneNumber: value })
												// console.log('value number ', value)
											}}
											onKeyPress={this.handleKeyPress}

										/>
									</div>
									<button type="button"
										onClick={() => {
											this.handleinputSubmit()
											// if (this.state.phoneNumber && this.state.phoneNumber.length >= 13) {
											// 	this._sendOtp()
											// }
										}}
										className={this.state.phoneNumber && this.state.phoneNumber.length >= 13 && !this.state._showbutton ? 'login_modal_next_btn_click common_login_sign_up_btn' : 'login_modal_next_btn common_login_sign_up_btn'} >Next</button>

								</div>
							</div>
						</Modal>
					}

					{
						<Modal
							onClose={() => this.setState({ otpModalVisibility: false })}
							open={this.state.otpModalVisibility}
							// autoFocus={true} 
							center>
							<div className={'register_modal'}>
								{this.state._is_whatsapp == '0' ? <h4 className={'login_modal_title'}><i className="fas fa-sms"></i> Mobile Number Verification</h4> : <h4 className={'login_modal_title'}><i className="fab fa-whatsapp"></i> WhatsApp Number Verification</h4>}
								<div className={'register_modal_number_box'}>
									{this.state._is_whatsapp == '0' ? <p>
										{this.state._is_whatsapp == '0' ? 'Enter 4 digit code sent to your Mobile' : 'Enter 4 digit code sent to your Whatsapp'}<br />
										{
											<h4 style={{ fontWeight: 'bold', marginTop: '5px', marginBottom: '5px' }}>{this.state.phoneNumber}</h4>
										}
									</p> :
										<p>
											Enter 4 digit code sent to your WhatsApp <br />
											{
												<h4 style={{ fontWeight: 'bold', marginTop: '5px', marginBottom: '5px' }}>{this.state.phoneNumber}</h4>
											}
										</p>
									}
									<form onSubmit={this.handleSubmit}>
										<div className={'register_modal_otp_box'}>
											<OtpInput
												className={'login_otp_input'}
												value={this.state.otp}
												// onChange={e => this.setState({ otp_1: e.target.value })}
												onChange={this.handleChange}
												numInputs={4}
												separator={<span>-</span>}
												isInputNum={true}
												shouldAutoFocus
											/>

										</div>
										<button type="submit"
											// onClick={() => {
											// 	if (this.state.otp.length == 4) {
											// 		this._verifyOtp()
											// 	}
											// }}
											// className={this.state.otp_1.length == 1 && this.state.otp_2.length == 1 && this.state.otp_3.length == 1 && this.state.otp_4.length == 1 ? 'register_modal_next_btn common_login_sign_up_btn common_login_sign_up_btn_click' : 'register_modal_next_btn common_login_sign_up_btn'}
											className={this.state.otp.length == 4 ? 'register_modal_next_btn common_login_sign_up_btn common_login_sign_up_btn_click' : 'register_modal_next_btn common_login_sign_up_btn'}
										>
											Next
										</button>
									</form>
									<p onClick={() => {
										this.resendOtp()

									}
									} className={this.state._counter != 0 ? 'register_otp_resend hide' : 'register_otp_resend active'} >Resend Code <span>(in {this.state._counter} secs)</span></p>
								</div>
							</div>
						</Modal>
					}

					{/* if not register  */}
					{/*
						login flow
						<Modal
							onClose={() => this.setState({ _checkRegisteModal: false })}
							open={this.state._checkRegisteModal}
							center>
							<div className={'login_modal'}>

								<div className={'login_modal_number_box logintext'}>
									<p className="mantitle">Information</p>
									<div><span className="title">For Sign Up, Please register your self on WhatsApp</span></div>
									<div><span className="subtitle">Follow the below steps for registration </span></div>
									<div><span >1. Save the Naswiz WhatsApp No to your contacts :  +919540276786 </span></div>
									<div><span >2. Send Hi on WhatsApp On this Naswiz WhatsApp No +919540276786 </span></div>
									<div><span >3. Press Next </span></div>


									<button onClick={() => this.setState({ _checkRegisteModal: false })} className={'login_modal_next_btn_click common_login_sign_up_btn'} type="button">Ok</button>
								</div>
							</div>
						</Modal>
					*/}
				</div>
			</div >

		)
	}

	_sendOtp = async () => {

		var { activeModalType, phoneNumber, setCountryCode } = this.state
		var URL = 'v1/login'
		var ERROR = 'Error => Login Or Register api'
		var RESPONSE = 'Response => Login Or Register api'

		var numberSub = phoneNumber.slice(1, phoneNumber.length)   // Returns "Hello"

		// await this.setState({phoneNumber:numberSub})
		// console.log('phoneNumber _sendOtp', numberSub, this.state.phoneNumber)

		// if (phoneNumber.length == 10) {
		this.setState({
			_showbutton: true
		})
		var body = new FormData()
		body.append('number', numberSub)

		var res = await NaswizHelperApi.POST_WITHOUT_TOKEN(URL, body, ERROR)

		// console.log('send mobile number ', res)
		if (res && res.is_status == 0) {

			this.setState({ _checkRegisteModal: true, phoneNumber: '' })
			// window.open('https://api.whatsapp.com/send?phone=+91 9540276786&text=Register%20Me');
		}
		else if (res && res.success == 1) {
			this.setState({ _checkRegisteModal: false })

			Notification('success', 'Success!', res.message)
			this.setCounter()

			this.setState({ phoneNumberModalVisibility: false, otpModalVisibility: true, otp: '', })
			this.setState({
				_showbutton: false,
			})
		}
		else {

			Notification('error', 'Error!', res.message ? res.message : 'Try again later!')
			this.setState({
				_showbutton: false,
			})
		}
		// }
		// else {
		// 	Notification('error', 'Error!', 'Enter valid phone number !')
		// }

	}

	_verifyOtp = async () => {

		// var { otp_1, otp_2, otp_3, otp_4 } = this.state
		// var OTP = otp_1.concat(otp_2.concat(otp_3).concat(otp_4))
		var { otp } = this.state
		var OTP = otp
		if (OTP.length == 4) {
			var numberSub = this.state.phoneNumber.slice(1, this.state.phoneNumber.length)   // Returns "Hello"

			var body = new FormData()
			body.append('number', numberSub)
			body.append('otp', OTP)
			body.append('device_id', this.state.uniueId)

			for (var pair of body.entries()) {
			}

			var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/verify-otp', body, "OTP")

			if (res && res.success == 1) {
				if (res.message === 'Please contact admin. User is not Authorized to access the site.') {
					Notification('error', 'Error!', res.message)
				}
				else {
					this.props.changeLoginState(true)
					this.props.manageUserIdState(res.id)
					localStorage.setItem('token', res.token)
					this.setState({
						otp: '',
						otpModalVisibility: false
					})
					Notification('success', 'Success!', 'OTP Verify Successfully.')
					setTimeout(() => {
						this._getUserInfo()
						var body = new FormData()
						body.append('device_id', this.state.uniueId)
						this.props.getCartData('v1/get-cart-list', body, "Error => GetCartList api.")
					}, 2000)
				}
			}
			else {
				Notification('error', 'Error!', res.message ? res.message : 'Try again later.')
				this.setState({
					otp: '',
				})
			}
		}
		else {
			Notification('error', 'Error!', 'Enter valid OTP!')
			this.setState({
				otp: '',
			})
		}
	}

	_getUserInfo = async () => {


		var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/customers-detail', null, "Error => v1/customers-detail api.")

		if (res && res.success == 1) {
			this.props.manageUserIdState(res.record.id)
			this.props.addUserData(res.record)
		}
		else {
			this.logout()
		}
	}

	logout = () => {
		this.props.changeLoginState(false)
		this.props.removeUserData()
		this.props.manageUserIdState(false)
		this.props.history.replace("/")
	}
}
const CountrySelect = ({ value, onChange, labels, ...rest }) => (
	<select
		{...rest}
		value={value}
		onChange={event => onChange(event.target.value || undefined)}>
		<option value="">
			{labels['ZZ']}
		</option>
		{getCountries().map((country) => (
			<option key={country} value={getCountryCallingCode(country)}>
				{labels[country]}
				{/* {labels[country]} +{getCountryCallingCode(country)} */}
			</option>
		))}
	</select>
)
const mapStateToProps = (state) => ({
	isUserLogin: state.login.isUserLogin,
	userID: state.login.userID,
	useData: state.login.userData,
	cartListArray: state.login.userCartData.records.cart_list,
	cartTotalArray: state.login.userCartData.records.total,
})
CountrySelect.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	labels: PropTypes.objectOf(PropTypes.string).isRequired
}

export default connect(mapStateToProps, { changeCurrency, changeLoginState, removeUserData, manageUserIdState, addUserData, getCartData, getCartDataWithoutLogin })(withRouter(HeaderOne));

const name = ['All Categories']
