import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'
import Modal from 'react-responsive-modal';
import { NaswizHelperApi } from '../../../NaswizHelper';

import Background from '../../../assets/images/cms-page/bg.jpg';
import Background2 from '../../../assets/images/cms-page/pink_bg.png';
import Background3 from '../../../assets/images/cms-page/green_bg.png';
import { Helmet } from 'react-helmet';
import ReactPaginate from 'react-paginate';
import Seo from '../../Seo';

class AlkalineIonizer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			testimonial_alkaline: '',
			_mediaType: 0,
			_mediaUrl: '',
			_page: 1,
			_noOfPage: [],
			mopen: false,
			mopens: false,
			videoUrl: ''
		};
		this.myRef = React.createRef()
	}

	componentDidUpdate() {
		this.myRef.current.scrollTo(0, 0);

	}
	async componentDidMount() {
		this.naswizGetHomeDataApi()
		this.myRef.current.scrollTo(0, 0);

	}
	naswizGetHomeDataApi = () => {

		var userID = this.props.userID
		var url = 'v1/get-home/' + this.state.uniueId + '/0?' + 'page=' + this.state._page

		this.setState({ loading_getHomeData: true }, async () => {

			var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(url, 'Error => v1/get-home')

			if (res && res.success == 1) {
				this.setState({
					testimonial_alkaline: res.records.testimonial_alkaline.data
				})
				var tmp = []

				for (let i = 0; i < res.records.testimonial_alkaline.last_page; i++) {
					tmp.push(i)
				}
				this.setState({ _noOfPage: tmp })
			}
			else {
				this.setState({
					loading_getHomeData: false
				})
			}


		})
	}
	onOpenModal = () => {
		this.setState({ open: true });
	};
	onCloseModal = () => {
		this.setState({ open: false });
	};

	onOpenModaln = (url) => {
		this.setState({
			videoUrl: url,
			mopen: true
		});
	};

	onOpenModalns = () => {
		this.setState({ mopens: true });
	};
	onCloseModaln = () => {
		this.setState({ mopen: false });
	};
	onCloseModalns = () => {
		this.setState({ mopens: false });
	};

	handlePagination = async (value2) => {
		var value = value2.selected + 1
		if (value <= this.state._noOfPage.length) {
			await this.setState({ _page: value })

			await this.naswizGetHomeDataApi()
		}

	}



	render() {
		const { translate, _data, top_bar_cms, social_arr } = this.props;
		return (
			<div ref={this.myRef}>
				{/* <Helmet>
					<title>Alkaline Ionizer - Naswiz Retails Pvt Ltd</title>
					<meta name="og_title" property="og:title" content="Alkaline Ionizer - Naswiz Retails Pvt Ltd" />
					<meta name="twitter:title" content="Alkaline Ionizer - Naswiz Retails Pvt Ltd" />
					<meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
					<meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
					<meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
					<meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
				</Helmet> */}
				<Seo title="Alkaline Ionizer - Naswiz Retails Pvt Ltd" description="Alkaline Ionizer Platinized Titanium Smart Design Efficient SOLID Plates Technology."  image="https://www.myshopwiz.com/assets/images/cms-page/alkaline-ionizer.png"/>
				<main className="main">

					<div className="alkaline-banner" style={{ backgroundImage: "url(" + Background + ")" }}>
						<div className="container">
							<div className="alkaline-banner-inner">
								<div className="row">
									<div className="col-lg-4">
										<div className="banner-content">
											<h1 className="text-theme-primary">Alkaline Ionizer</h1>
											<p className="text-theme-secondary">Platinized Titanium Smart Design Efficient SOLID Plates Technology. </p>
											<a href="/" className="btn theme-button border ls-10 corner-button">SHOP NOW</a>
										</div>
									</div>
									<div className="col-lg-8">
										<div className="banner-img">
											<img src="assets/images/cms-page/alkaline-ionizer.png" className="img-fluid img-center" alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<section className="pb-3">

						<div className="alkaline-section d-none d-xl-block d-lg-block">
							<div className="container">
								<div id="maincontent">
									<ul className="alkaline-tabs nav nav-justified content-tabs">
										<li className="nav-item border border-right-0">
											<a className="nav-link active d-flex align-items-center" data-toggle="tab" href="#plate_alkaline5">5 plate Alkaline Ionizer</a>
										</li>
										<li className="nav-item border border-right-0">
											<a className="nav-link d-flex align-items-center" data-toggle="tab" href="#plate_Alkaline9">9 plate Alkaline Ionizer</a>
										</li>
										<li className="nav-item border border-right-0">
											<a className="nav-link d-flex align-items-center" data-toggle="tab" href="#benifits_alkaline">Benifits of Alkaline Ionizer</a>
										</li>
										<li className="nav-item border border-right-0">
											<a className="nav-link d-flex align-items-center" data-toggle="tab" href="#alkaline_use">How to use the alkaline water</a>
										</li>
										<li className="nav-item border border-right-0">
											<a className="nav-link d-flex align-items-center" data-toggle="tab" href="#alkaline_certificates">Alkaline Certificates</a>
										</li>
										<li className="nav-item border border-right-0">
											<a className="nav-link d-flex align-items-center" data-toggle="tab" href="#manufacturing">Manufacturing Process</a>
										</li>
										<li className="nav-item border">
											<a className="nav-link d-flex align-items-center" data-toggle="tab" href="#client_testimonial">Client Testimonial</a>
										</li>
									</ul>

									<div className="tab-content mt-5">
										<div className="tab-pane fade show active" id="plate_alkaline5">

											<div className="plate_alkaline5">
												<div className="box-title mb-5">
													<h3>5 Plate Alkaline Ionizer</h3>
													<p>Our huge range of offerings is inclusive of PL-B 502 Violet Water Ionizer. This item is greatly recommended to make drinking water cleaner and healthier. For making available this product at market leading prices, we have earned great recognition in our segment.</p>
												</div>

												<div className="plate-banner mb-5" style={{ backgroundImage: "url(" + Background2 + ")" }}>
													<div className="row align-items-center">
														<div className="col-lg-6">
															<div className="plate-banner-content pl-md-3 pl-lg-4">
																<h2 className="text-white">5 PLATE ALKALINE IONIZER</h2>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="plate-banner-img">
																<img src="assets/images/cms-page/plate-5.png" className="img-fluid img-center" alt="" />
															</div>
														</div>
													</div>
													<div className="plate-shape">
														<img src="assets/images/cms-page/plate-shape.png" className="img-fluid img-center" alt="" />
													</div>
												</div>

												<div className="product-details mb-5">
													<div className="alkaline_subtitle">
														<h4>Product Details</h4>
													</div>
													<div className="table-responsive">
														<table className="table table-bordered">
															<tbody>
																<tr>
																	<th>Electrodes Technology</th>
																	<td>Platinized- Titanium Smart Design Efficient SOLID Plates Technology.</td>
																</tr>
																<tr>
																	<th>Electrolysis Method</th>
																	<td>Continuous Electrolysis</td>
																</tr>
																<tr>
																	<th>No of Electrodes</th>
																	<td>5 Plates</td>
																</tr>
																<tr>
																	<th>Electrode Size(cm)</th>
																	<td>7.4cm  x 14cm</td>
																</tr>
																<tr>
																	<th>pH Range</th>
																	<td>Highest pH level : 10.5 pH, Lowest pH level :  4.5 pH</td>
																</tr>
																<tr>
																	<th>Filter Life</th>
																	<td>9000 Litres</td>
																</tr>
																<tr>
																	<th>ORP Performance</th>
																	<td>Up to -500mV ~</td>
																</tr>
																<tr>
																	<th>Molecular Hydrogen(H2) Performance</th>
																	<td>Up to 600ppb </td>
																</tr>
																<tr>
																	<th>Filter Type</th>
																	<td>High performance composite filter with Carbon ,Calcium Sulphite & Non-Woven Fabric</td>
																</tr>
																<tr>
																	<th>Electrolysis stage</th>
																	<td>4 Alkaline Levels  / 2 Acidic Levels / 1  Purified Level</td>
																</tr>
																<tr>
																	<th>Cleaning Method</th>
																	<td>DARC (Dual Automatic Reverse Cleaning)</td>
																</tr>
																<tr>
																	<th>Water Flow Rate</th>
																	<td>1.5 to 2 litre/min.  (Flow indicator displayed on LCD)</td>
																</tr>
																<tr>
																	<th>Rated Power Supply</th>
																	<td>110V- 250V, 130 Watt</td>
																</tr>
																<tr>
																	<th>LCD Display Functions</th>
																	<td>pH Level, Water Flow Rate, Digital Filter Life Indicator</td>
																</tr>
																<tr>
																	<th>Voice Guidance System</th>
																	<td>Voice Guidance for different Alkaline pH Levels & Acidic pH Levels</td>
																</tr>
																<tr>
																	<th>Water Pressure</th>
																	<td>0.7~6.0kgf/cm2 (70~500kPa)</td>
																</tr>
																<tr>
																	<th>Protection Devices</th>
																	<td>Built in 2 Temp. sensors (Automatic Restoration) & Low water pressure sensor. </td>
																</tr>
																<tr>
																	<th>Water Supply</th>
																	<td>Tap Water /Purified Water (Up to 250TDS with proper Pre filtration Equipment)</td>
																</tr>
																<tr>
																	<th>Operating Temperature</th>
																	<td>5-30C.(41-86F)</td>
																</tr>
																<tr>
																	<th>Installation</th>
																	<td>Suitable for Wall Mount And Table Top</td>
																</tr>
																<tr>
																	<th>Weight</th>
																	<td>6 KG</td>
																</tr>
																<tr>
																	<th>Faucet</th>
																	<td>180 Degree Adjustment Faucet</td>
																</tr>
																<tr>
																	<th>Dimensions</th>
																	<td>280(W) x 360(H) x 155(D) mm</td>
																</tr>
																<tr>
																	<th>Country of Origin</th>
																	<td>South Korea</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>

												<div className="plate-info">
													<div className="row">
														<div className="col-lg-6">
															<div className="plate-info-box">
																<div className="alkaline_subtitle">
																	<h4>Mechanism Of Making Ionized Water</h4>
																</div>
																<p>Each water molecule is with positive and negative ions. Remove them requires a process called electrolysis. Purified water is sent to the “electrolyzer”.</p>
																<p>Here, water is electrolyzed by creating a flow of electric current, where the positive ions, such as sodium, magnesium and  calcium are gathered toward the negative pole, making alkaline ionized water, and the negative ions are gathered toward the positive pole, making acidic ionized water.</p>
																<p>Acidic water is drained and alkaline ionized water is released through the faucet.</p>
																<div className="plate-info-img">
																	<img src="assets/images/cms-page/ionized-water.png" className="img-fluid img-center" alt="" />
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="plate-info-box mb-2">
																<div className="alkaline_subtitle">
																	<h4>High-performance Composite Filter</h4>
																</div>
																<p>The alkaline water ionizer is a class 2 medical equipment approved by KFDA.Electrolyte is achieved by the ionizer to make alkaline ionized water with pH 7.5 to 10.0.</p>
																<div className="plate-info-img">
																	<img src="assets/images/cms-page/composite-filter.png" className="img-fluid img-center" alt="" />
																</div>
															</div>
														</div>
													</div>
												</div>

											</div>

										</div>

										<div className="tab-pane fade" id="plate_Alkaline9">

											<div className="plate_alkaline9">
												<div className="box-title mb-5">
													<h3>9 Plate Alkaline Ionizer</h3>
												</div>

												<div className="plate-banner mb-5 second" style={{ backgroundImage: "url(" + Background3 + ")" }}>
													<div className="row align-items-center">
														<div className="col-lg-5 offset-lg-1">
															<div className="plate-banner-content">
																<h2 className="text-white">9 PLATE ALKALINE IONIZER</h2>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="plate-banner-img">
																<img src="assets/images/cms-page/plate-9.png" className="img-fluid img-center" alt="" />
															</div>
														</div>
													</div>
													<div className="plate-shape">
														<img src="assets/images/cms-page/plate-shape.png" className="img-fluid img-center" alt="" />
													</div>
												</div>

												<div className="product-details mb-5">
													<div className="alkaline_subtitle">
														<h4>Product Details</h4>
													</div>
													<div className="table-responsive">
														<table className="table table-bordered">
															<tbody>
																<tr>
																	<th>Electrodes Technology</th>
																	<td>Platinized- Titanium Smart Design Efficient SOLID Plates Technology.</td>
																</tr>
																<tr>
																	<th>Electrolysis&nbsp;Method</th>
																	<td>Continuous Electrolysis</td>
																</tr>
																<tr>
																	<th>No of Electrodes</th>
																	<td>9 Plates</td>
																</tr>
																<tr>
																	<th>Electrode Size(cm)</th>
																	<td>7.4cm  x 14cm</td>
																</tr>
																<tr>
																	<th>pH Range</th>
																	<td>Highest pH level : upto 11 pH, Lowest pH level : 3.5 pH</td>
																</tr>
																<tr>
																	<th>Filter Life</th>
																	<td>Filter-1:  7,000 Litres, Filter-2:  7000 Litres.</td>
																</tr>
																<tr>
																	<th>ORP Performance</th>
																	<td>Up to -700mV ~</td>
																</tr>
																<tr>
																	<th>Molecular Hydrogen(H2) Performance</th>
																	<td>Up to 900ppb </td>
																</tr>
																<tr>
																	<th>Filter Type</th>
																	<td>High performance composite filter with Carbon ,Calcium Sulphite &amp; Non-Woven Fabric</td>
																</tr>
																<tr>
																	<th>Electrolysis stage</th>
																	<td>5 Alkaline Levels  / 2 Acidic Levels / 1  Purified Level</td>
																</tr>
																<tr>
																	<th>Cleaning Method</th>
																	<td>DARC (Dual Automatic Reverse Cleaning)</td>
																</tr>
																<tr>
																	<th>Water Flow Rate</th>
																	<td>1.5 to 2 litre/min.  (Flow indicator displayed on LCD)</td>
																</tr>
																<tr>
																	<th>Rated Power Supply</th>
																	<td>110V- 250V, 200 Watt</td>
																</tr>
																<tr>
																	<th>LCD Display Functions</th>
																	<td>pH Level with Colour, Water Flow Rate, Digital Filter Life Indicator.</td>
																</tr>
																<tr>
																	<th>Voice Guidance System</th>
																	<td>Voice Guidance for different Alkaline pH Levels &amp; Acidic Levels</td>
																</tr>
																<tr>
																	<th>Water Pressure</th>
																	<td>0.7~6.0kgf/cm2 (70kPa~500kPa)</td>
																</tr>
																<tr>
																	<th>Protection Devices</th>
																	<td>Built in 2 Temp. sensors (Automatic Restoration) &amp; Low water pressure sensor. </td>
																</tr>
																<tr>
																	<th>Water Supply</th>
																	<td>Tap Water /Purified Water (Up to 250TDS with proper Pre filtration Equipment)</td>
																</tr>
																<tr>
																	<th>Operating Temperature</th>
																	<td>5-30C.(41-86F)</td>
																</tr>
																<tr>
																	<th>Installation</th>
																	<td>Suitable for Wall Mount And Table Top</td>
																</tr>
																<tr>
																	<th>Weight</th>
																	<td>7 KG</td>
																</tr>
																<tr>
																	<th>Faucet</th>
																	<td>180 Degree Adjustment Faucet</td>
																</tr>
																<tr>
																	<th>Dimensions</th>
																	<td>310(W) x 360(H) x 160(D) mm</td>
																</tr>
																<tr>
																	<th>Country of Origin</th>
																	<td>South Korea</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>

												<div className="plate-info">
													<div className="row">
														<div className="col-lg-12">
															<div className="plate-info-box mb-3">
																<div className="alkaline_subtitle">
																	<h4>Mechanism Of Making Ionized Water</h4>
																</div>
																<p>Each water molecule is with positive & negative ions. Remove them requires a process called electrolysis. Purified water is sent to the “electrolyzer”.</p>
																<p>Here, water is electrolyzed by creating a flow of electric current, where the positive ions, such as sodium, magnesium and  calcium are gathered toward the negative pole, making alkaline ionized water, and the negative ions are gathered toward the positive pole, making acidic ionized water.</p>
																<p>Acidic water is drained and alkaline ionized water is released through the faucet.</p>
																<div className="plate-info-img">
																	<img src="assets/images/cms-page/ionized-water-9.png" className="img-fluid" alt="" />
																</div>
															</div>
														</div>
														<div className="col-lg-12">
															<div className="plate-info-box ">
																<div className="alkaline_subtitle">
																	<h4>High-performance Composite Filter</h4>
																</div>
																<div className="plate-info-img">
																	<img src="assets/images/cms-page/composite-filter-9.png" className="img-fluid img-center" alt="" />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

										</div>

										<div className="tab-pane fade" id="benifits_alkaline">

											<div className="benifits-section">
												<div className="row mb-3">
													<div className="col-lg-7">
														<div className="benifits-content">

															<div className="box-title mb-5">
																<h3>Benefits of Naswiz Ionized Water</h3>
															</div>

															<ul className="benifits-points">
																<li>Antioxidant (Anti-Aging)</li>
																<li>Detoxifies the Body</li>
																<li>Neutralizes Acidity</li>
																<li>Boosts Immunity</li>
																<li>Hydrates Skin</li>
																<li>Weight Loss</li>
																<li>Heart Health / Blood Pressure</li>
																<li>Digestive Health</li>
																<li>Reduces the Muscles and Joint Discomfort</li>
																<li>Energized Human Body</li>
																<li>Effective Cleansing & Washing</li>
															</ul>
														</div>
													</div>
													<div className="col-lg-5">
														<div className="benifits-img">
															<img src="assets/images/cms-page/benefits-naswiz.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
												</div>
											</div>

										</div>

										<div className="tab-pane fade" id="alkaline_use">

											<div className="how-to-use-section">
												<div className="box-title mb-5">
													<h3>How to use the Ionized water</h3>
													<p>The alkaline water ionizer is a class 2 medical equipment approved by KFDA. Electrolyte is achieved by the ionizer to make alkaline ionized water with pH 7.5 to 10.0.</p>
												</div>
												<div className="how-to-use-box">
													<div className="alkaline_subtitle">
														<h4>How to use Alkaline Ionized water</h4>
													</div>
													<div className="how-to-use-box-listing">
														<div className="row mb-3">
															<div className="col-lg-6">
																<div className="box-listing-details d-flex align-items-center">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/drinking.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4>Drinking</h4>
																		<p>Drink regularly to help improve the four most popular gastrointestinal diseases.</p>
																	</div>
																</div>
															</div>
															<div className="col-lg-6">
																<div className="box-listing-details d-flex align-items-center">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/given-pets.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4>Given to pets</h4>
																		<p>Reduces odors and makes the fur shinier.</p>
																	</div>
																</div>
															</div>
															<div className="col-lg-6">
																<div className="box-listing-details d-flex align-items-center">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/vegetables.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4>Washing vegetables and fruit</h4>
																		<p>Helps with freshness</p>
																	</div>
																</div>
															</div>
															<div className="col-lg-6">
																<div className="box-listing-details d-flex align-items-center">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/cooking.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4>Cooking meat and fish</h4>
																		<p>Removes odor, enhances taste and flavors, and helps with freshness.</p>
																	</div>
																</div>
															</div>
															<div className="col-lg-6">
																<div className="box-listing-details d-flex align-items-center">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/coffee.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4>Brewing coffee or tea</h4>
																		<p>Helps with removing the bitter taste and enhancing the depth of taste or flavors.</p>
																	</div>
																</div>
															</div>
															<div className="col-lg-6">
																<div className="box-listing-details d-flex align-items-center">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/rice.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4>Cooking rice</h4>
																		<p>Dip rice in alkaline ionized water for 30-60 minutes before cooking and the rice tastes better, looks shinier and stickier, and will not discolor easily.</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="how-to-use-box">
													<div className="alkaline_subtitle">
														<h4>How to use acidic ionized water</h4>
													</div>
													<div className="how-to-use-box-listing">
														<div className="row mb-3">
															<div className="col-lg-6">
																<div className="box-listing-details d-flex align-items-center">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/bathing-cleaning.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4>Bathing and cleaning</h4>
																		<p>Helps with smoother, more resilient skin. Use after shampooing to neutralize the alkaline content of soap and leave your hair sleeker.</p>
																	</div>
																</div>
															</div>
															<div className="col-lg-6">
																<div className="box-listing-details d-flex align-items-center">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/cleaning-house.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4>Cleaning the house</h4>
																		<p>Acidic ionized water can be used to remove dust and clean the bathrooms.</p>
																	</div>
																</div>
															</div>
															<div className="col-lg-6">
																<div className="box-listing-details d-flex align-items-center">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/brushing-shaving.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4>Brushing teeth and shaving</h4>
																		<p>Acidic ionized water has the cleansing effect, so it keeps the teeth healthier with sterilizing effect & soothes the skin when shaving.</p>
																	</div>
																</div>
															</div>
															<div className="col-lg-6">
																<div className="box-listing-details d-flex align-items-center">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/arranging-flowers.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4>Arranging flowers</h4>
																		<p>Use diluted acidic ionized water on the bottom of flowers to help extend the lifespan of flowers.</p>
																	</div>
																</div>
															</div>
															<div className="col-lg-6">
																<div className="box-listing-details d-flex align-items-center">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/cutting-boards.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4>Washing dish & cutting boards</h4>
																		<p>Dip cutting boards, kitchen cloths, and knives in acidic ionized water for about 20 minutes to remove odors and help with bleaching and sterilizing.</p>
																	</div>
																</div>
															</div>
															<div className="col-lg-6">
																<div className="box-listing-details d-flex align-items-center">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/grooming-pets.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4>Grooming pets</h4>
																		<p>Reduces shedding hair and helps with sleeker fur and deodorization.</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="how-to-use-box">
													<div className="alkaline_subtitle">
														<h4>How to use purified water</h4>
													</div>
													<div className="how-to-use-box-listing">
														<div className="row">
															<div className="col-lg-12">
																<div className="box-listing-details d-flex align-items-center">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/taking-medication.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4>Taking medication</h4>
																		<p>Use the purified water when taking medication. The ionized water has less cluster and purified ionized water helps with faster penetration of medication.</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

										</div>

										<div className="tab-pane fade" id="alkaline_certificates">

											<div className="alkaline_certificates_section">
												<div className="box-title mb-5">
													<h3>Alkaline Certificates</h3>
													<p>The Alkaline Water Certificates has introduced Alkaline Water Filter Pitcher to provide you and your family with safe, clean and germ-free water with the right pH levels.</p>
												</div>
												<div className="row">
													<div className="col-md-8 mx-auto">
														<img src="assets/images/Distributor-Certificates.jpg" className="img-fluid img-center" alt="" />
													</div>
												</div>
												<div className="row mt-3">
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/pse-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/korean-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/a-iso-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/fda-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/nrtl-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/korea-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/iecee-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/ce-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/medical-device-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/iso-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/gmp-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/tuv-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>



													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/best-hit-product.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/best-new-technology.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/brand-of-the-year.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/customer-trust.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/fcs.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/inno-biz.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/kfda.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/koita.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/korea-first-brand.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/korea-innovation-company.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/korea-institute.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/korea-standard-quality.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>


													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/master-brand.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/million-dollar-export-tower.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/minstry-of-smes.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/quality-satisfaction.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/the-company-of-korea.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/via-asia.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>

												</div>
											</div>

										</div>

										<div className="tab-pane fade" id="manufacturing">
											<div className="manufacturing-section">

												<div className="box-title mb-5">
													<h3>Manufacturing Process</h3>
												</div>

												<div className="mb-5">
													<div className="alkaline_subtitle icon_subtitle d-flex align-items-center">
														<img src={require("./../../../assets/images/cms-page/title-icon.png")} alt="" />
														<h4 className="ml-2">Message From The CEO Of BIONTECH</h4>
													</div>
													<div className="row">
														<div className="col-lg-6">
															<div className="manufacturing-video position-relative">
																<img src={require("./../../../assets/images/cms-page/biontech.jpeg")} className="img-fluid img-center video-poster" alt="" />
																<div className="play-video">
																	<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/dAxPYdWQ9lA')} data-backdrop="static" title="play video">
																		<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
																	</a>
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="manufacturing-video position-relative">
																<img src={require("./../../../assets/images/cms-page/biontech_naswiz.jpeg")} className="img-fluid img-center video-poster" alt="" />
																<div className="play-video">
																	<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/xq5wNNdcFok')} data-backdrop="static" title="play video">
																		<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="mb-5">
													<div className="alkaline_subtitle icon_subtitle d-flex align-items-center">
														<img src={require("./../../../assets/images/cms-page/title-icon.png")} alt="" />
														<h4 className="ml-2">manufacturing process</h4>
													</div>
													<div className="row">
														<div className="col-lg-6 position-relative">
															<div className="manufacturing-video">
																<img src={require("./../../../assets/images/cms-page/manufacturing-process.jpeg")} className="img-fluid img-center video-poster" alt="" />
																<div className="play-video">
																	<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/QoIFRdE-Sdc')} data-backdrop="static" title="play video">
																		<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
																	</a>
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="manufacturing-video position-relative">
																<img src={require("./../../../assets/images/cms-page/manufacturing-process2.jpeg")} className="img-fluid img-center video-poster" alt="" />
																<div className="play-video">
																	<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/MO2C2Nd6V-8')} data-backdrop="static" title="play video">
																		<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="mb-5">
													<div className="alkaline_subtitle icon_subtitle d-flex align-items-center">
														<img src={require("./../../../assets/images/cms-page/title-icon.png")} alt="" />
														<h4 className="ml-2">Electroytic Cell</h4>
													</div>
													<div className="row">
														<div className="col-lg-6">
															<div className="manufacturing-content ul li manufacturing-section">
																<p>Biontech employs only outstanding electrolytic cells. An electrolytic cell is an essential component for ionizing purified water.</p>
																<ul>
																	<li>Outstanding ionization capability through increased use of pure titanium and 99% platinum coating.</li>
																	<li>Enhanced ionization by embossing effect from sanded electrode surface</li>
																	<li>High anti-corrosion and continual electrolytic function</li>
																	<li>Outstanding technology that generates high ORP value even in low pH</li>
																	<li>DARC System is equipped with a ceramic valve to prevent scale build-ups inside the electrolytic cell</li>
																	<li>Flawless leak prevention through rigid moulding</li>
																</ul>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="manufacturing-video">
																<img src={require("./../../../assets/images/cms-page/electrolytic-cell.jpeg")} className="img-fluid img-center video-poster" alt="" />
																<div className="play-video">
																	<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/mmQKi-_EzhY')} data-backdrop="static" title="play video">
																		<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="mb-5">
													<div className="alkaline_subtitle icon_subtitle d-flex align-items-center">
														<img src={require("./../../../assets/images/cms-page/title-icon.png")} alt="" />
														<h4 className="ml-2">Anti-scale System / Darc</h4>
													</div>
													<div className="row">
														<div className="col-lg-6">
															<div className="manufacturing-video position-relative">
																<img src={require("./../../../assets/images/cms-page/anti-scale-system.jpeg")} className="img-fluid img-center video-poster" alt="" />
																<div className="play-video">
																	<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/0IfocWAQQjc')} data-backdrop="static" title="play video">
																		<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
																	</a>
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="manufacturing-content ul li manufacturing-section">
																<p>Anti-Scale System is an exclusive BionTech technology that innovatively prevents scale buildups inside the electrolytic cell.</p>
																<ul>
																	<li>Enhanced durability using ceramic discs</li>
																	<li>Automatic disc conversion notification sensor prevents mixing of alkaline and acidic water.</li>
																</ul>
															</div>
														</div>
													</div>
												</div>

												<div className="mb-5">
													<div className="alkaline_subtitle icon_subtitle d-flex align-items-center">
														<img src={require("./../../../assets/images/cms-page/title-icon.png")} alt="" />
														<h4 className="ml-2">Filter Function</h4>
													</div>
													<div className="row">
														<div className="col-lg-6">
															<div className="manufacturing-content ul li manufacturing-section">
																<p>It is Biontech's firm belief that filters must enable water to fulfill its role and function. Biontech strictly selects raw materials whose quality have been certified under international standards.</p>
																<ul>
																	<li>Bottom-up flow structure enables precision water purification</li>
																	<li>Pyramid structure enhances compression within filters for better purification function</li>
																	<li>High quality active carbon produces cleaner water</li>
																	<li>Fillers made from high quality materials result in effective purification</li>
																	<li>Water purification ability is magnified using compressed 7-layer purification materials</li>
																	<li>Hollow fiber membrane filtering removes harmful heavy metals but leaves various minerals</li>
																	<li>Use of smart filter prevents use of counterfeits</li>
																</ul>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="manufacturing-video">
																<img src={require("./../../../assets/images/cms-page/filter-function.jpeg")} className="img-fluid img-center video-poster" alt="" />
																<div className="play-video">
																	<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/A__VBzIBAZ8')} data-backdrop="static" title="play video">
																		<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>

											</div>
										</div>

										<div className="tab-pane fade " id="client_testimonial">
											<div className="testimonial-wrapper">
												<div className="box-title mb-5">
													<h3>Testimonial</h3>
												</div>

												<ul className="row">
													{this.state.testimonial_alkaline.length > 0 ?
														this.state.testimonial_alkaline.map((item, index) => {
															return (
																<li key={index} className="col-md-3 col-sm-4">
																	<div className="testimonial-box d-flex flex-column border h-100">
																		<div className="manufacturing-video position-relative">
																			{item.is_check == 0 ?
																				<img
																					onClick={async () => {
																						await this.setState({
																							_mediaType: item.is_check,
																							_mediaUrl: item.image_full_path
																						})
																						if (item.image_full_path != '') {
																							await this.onOpenModal()

																						}
																					}}
																					src={item.image_full_path == '' ? 'assets/images/cms-page/testimonial-video-poster-1.jpg' : item.image_full_path} alt="" className="img-fluid img-center video-poster" style={{ maxHeight: '278px', minHeight: '278px', objectFit: 'cover', objectPosition: 'center' }} />
																				:
																				<img src={item.thumbnail_full_path} className="img-fluid img-center video-poster w-100" alt="video-img" style={{ maxHeight: '278px', minHeight: '278px', objectFit: 'cover', objectPosition: 'center' }} />
																			}
																			{item.is_check == 1 ? <div className="play-video">
																				<a href="javascript:void(0)" onClick={async () => {
																					await this.setState({
																						_mediaType: item.is_check,
																						_mediaUrl: item.youtube_link
																					})
																					await this.onOpenModal()
																				}}>
																					<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="play-video-icon-img" />
																				</a>
																			</div> : null}


																			{/* <Modal open={this.state.open} onClose={this.onCloseModal} center>
																				<div className="video-modal12">
																					<div className="video-responsive122">
																						{this.state._mediaType == 0 ?
																							<img src={this.state._mediaUrl} alt="video-img" className="img-fluid" />
																							:
																							<iframe src={this.state._mediaUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
																						}
																					</div>
																				</div>
																			</Modal> */}
																		</div>
																		<div className="testimonial-content d-flex flex-column h-100">
																			<p className="pb-2 text-capitalize">{item.description}</p>
																			<div className="bottom-txt text-right">
																				<h5 className="text-capitalize text-truncate">{item.name} </h5>
																				<h6 className="text-capitalize text-truncate">{item.designation} </h6>
																			</div>
																		</div>
																	</div>
																</li>
															)
														}) : null
													}
												</ul>

												<Modal open={this.state.open} onClose={this.onCloseModal} center>
													<div className="editaddressModal  modal-dialog vertical-align-center">
														{this.state._mediaType == 0 ?
															<img src={this.state._mediaUrl} alt="" className="img-fluid" />
															:
															<iframe src={this.state._mediaUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
														}
													</div>
												</Modal>

											</div>
											<div className="text-center" >
												{this.state._noOfPage.length > 1 ?
													<ReactPaginate
														previousLabel={'<'}
														nextLabel={'>'}
														breakLabel={'...'}
														breakClassName={'page-item'}
														breakLinkClassName={'page-link'}
														pageCount={this.state._noOfPage.length}
														marginPagesDisplayed={1}
														pageRangeDisplayed={5}
														onPageChange={(page) => this.handlePagination(page)}
														containerClassName={'pagination toolbox-item justify-content-center'}
														pageClassName={'page-item'}
														pageLinkClassName={'page-link'}
														previousClassName={'page-item'}
														previousLinkClassName={'page-link'}
														nextClassName={'page-item'}
														nextLinkClassName={'page-link'}
														activeClassName={'active'}
													/>

													: null}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="container">
							<div className="panel-group d-block d-lg-none d-xl-none" id="maincontent-accordion">
								<div className="panel panel-default">
									<div className="panel-heading">
										<a className="nav-link active js-tabcollapse-panel-heading collapsed" data-toggle="collapse" href="#plate_alkaline5-collapse" data-parent="#maincontent-accordion" aria-expanded="false">5 plate Alkaline Ionizer</a>
									</div>
									<div id="plate_alkaline5-collapse" className="panel-collapse collapse">
										<div className="panel-body js-tabcollapse-panel-body">
											<div className="plate_alkaline5">
												<div className="box-title mb-5">
													<h3>5 Plate Alkaline Ionizer</h3>
													<p>Our huge range of offerings is inclusive of PL-B 502 Violet Water Ionizer. This item is greatly recommended to make drinking water cleaner and healthier. For making available this product at market leading prices, we have earned
														great recognition in our segment.</p>
												</div>

												{/* -------new add------- */}
												<div className="plate-banner mb-3 mb-sm-5" style={{ backgroundImage: "url(" + Background2 + ")" }}>
													<div className="row align-items-center">
														<div className="col-sm-6 col-lg-6">
															<div className="plate-banner-content pl-3 pl-md-4 d-none d-sm-block">
																<h2 className="text-white">5 PLATE ALKALINE IONIZER</h2>
															</div>
														</div>
														<div className="col-sm-6 col-lg-6">
															<div className="plate-banner-img text-center">
																<img src="assets/images/cms-page/plate-5.png" className="img-fluid img-center" alt="" />
															</div>
														</div>
													</div>
													<div className="plate-shape">
														<img src="assets/images/cms-page/plate-shape.png" className="img-fluid img-center" alt="" />
													</div>
												</div>
												{/* -------------- */}

												{/* <div className="plate-banner mb-5">
												<div className="row align-items-center">
													<div className="col-lg-5 offset-lg-1">
														<div className="plate-banner-content-5-menu">
															<h2>5 PLATE ALKALINE IONIZER</h2>
														</div>
													</div>
													<div className="col-lg-6">
														<div className="plate-banner-img text-center">
															<img src="assets/images/cms-page/plate-5.png" className="img-fluid img-center" alt="" />
														</div>
													</div>
												</div>
												<div className="plate-shape">
													<img src="assets/images/cms-page/plate-shape.png" className="img-fluid img-center" alt="" />
												</div>
											</div> */}

												<div className="product-details mb-3">
													<div className="alkaline_subtitle">
														<h4>Product Details</h4>
													</div>
													<div className="table-responsive">
														<table className="table table-bordered">
															<tbody>
																<tr>
																	<th>Electrodes Technology</th>
																	<td>Platinized- Titanium Smart Design Efficient SOLID Plates Technology.</td>
																</tr>
																<tr>
																	<th>Electrolysis&nbsp;Method</th>
																	<td>Continuous Electrolysis</td>
																</tr>
																<tr>
																	<th>No of Electrodes</th>
																	<td>5 Plates</td>
																</tr>
																<tr>
																	<th>Electrode Size(cm)</th>
																	<td>7.4cm x 14cm</td>
																</tr>
																<tr>
																	<th>pH Range</th>
																	<td>Highest pH level : 10.5 pH, Lowest pH level : 4.5 pH</td>
																</tr>
																<tr>
																	<th>Filter Life</th>
																	<td>9000 Litres</td>
																</tr>
																<tr>
																	<th>ORP Performance</th>
																	<td>Up to -500mV ~</td>
																</tr>
																<tr>
																	<th>Molecular Hydrogen(H2) Performance</th>
																	<td>Up to 600ppb </td>
																</tr>
																<tr>
																	<th>Filter Type</th>
																	<td>High performance composite filter with Carbon ,Calcium Sulphite &amp; Non-Woven Fabric</td>
																</tr>
																<tr>
																	<th>Electrolysis stage</th>
																	<td>4 Alkaline Levels / 2 Acidic Levels / 1 Purified Level</td>
																</tr>
																<tr>
																	<th>Cleaning Method</th>
																	<td>DARC (Dual Automatic Reverse Cleaning)</td>
																</tr>
																<tr>
																	<th>Water Flow Rate</th>
																	<td>1.5 to 2 litre/min. (Flow indicator displayed on LCD)</td>
																</tr>
																<tr>
																	<th>Rated Power Supply</th>
																	<td>110V- 250V, 130 Watt</td>
																</tr>
																<tr>
																	<th>LCD Display Functions</th>
																	<td>pH Level, Water Flow Rate, Digital Filter Life Indicator</td>
																</tr>
																<tr>
																	<th>Voice Guidance System</th>
																	<td>Voice Guidance for different Alkaline pH Levels &amp; Acidic pH Levels</td>
																</tr>
																<tr>
																	<th>Water Pressure</th>
																	<td>0.7~6.0kgf/cm2 (70~500kPa)</td>
																</tr>
																<tr>
																	<th>Protection Devices</th>
																	<td>Built in 2 Temp. sensors (Automatic Restoration) &amp; Low water pressure sensor. </td>
																</tr>
																<tr>
																	<th>Water Supply</th>
																	<td>Tap Water /Purified Water (Up to 250TDS with proper Pre filtration Equipment)</td>
																</tr>
																<tr>
																	<th>Operating Temperature</th>
																	<td>5-30C.(41-86F)</td>
																</tr>
																<tr>
																	<th>Installation</th>
																	<td>Suitable for Wall Mount And Table Top</td>
																</tr>
																<tr>
																	<th>Weight</th>
																	<td>6 KG</td>
																</tr>
																<tr>
																	<th>Faucet</th>
																	<td>180 Degree Adjustment Faucet</td>
																</tr>
																<tr>
																	<th>Dimensions</th>
																	<td>280(W) x 360(H) x 155(D) mm</td>
																</tr>
																<tr>
																	<th>Country of Origin</th>
																	<td>South Korea</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>

												<div className="plate-info mb-3">
													<div className="row">
														<div className="col-lg-6">
															<div className="plate-info-box">
																<div className="alkaline_subtitle">
																	<h4>Mechanism Of Making Ionized Water</h4>
																</div>
																<p>Each water molecule is with positive and negative ions. Remove them requires a process called electrolysis. Purified water is sent to the “electrolyzer”.</p>
																<p>Here, water is electrolyzed by creating a flow of electric current, where the positive ions, such as sodium, magnesium and calcium are gathered toward the negative pole, making alkaline ionized water, and the negative
																	ions are gathered toward the positive pole, making acidic ionized water.</p>
																<p>Acidic water is drained and alkaline ionized water is released through the faucet.</p>
																<div className="plate-info-img mb-3">
																	<img src="assets/images/cms-page/ionized-water.png" className="img-fluid img-center" alt="" />
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="plate-info-box">
																<div className="alkaline_subtitle">
																	<h4>High-performance Composite Filter</h4>
																</div>
																<p>The alkaline water ionizer is a class 2 medical equipment approved by KFDA.Electrolyte is achieved by the ionizer to make alkaline ionized water with pH 7.5 to 10.0.</p>
																<div className="plate-info-img">
																	<img src="assets/images/cms-page/composite-filter.png" className="img-fluid img-center" alt="" />
																</div>
															</div>
														</div>
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>

								<div className="panel panel-default">
									<div className="panel-heading">
										<a className="nav-link js-tabcollapse-panel-heading collapsed" data-toggle="collapse" href="#plate_Alkaline9-collapse" data-parent="#maincontent-accordion" aria-expanded="false">9 plate Alkaline Ionizer</a>
									</div>
									<div id="plate_Alkaline9-collapse" className="panel-collapse collapse">
										<div className="panel-body js-tabcollapse-panel-body">
											<div className="plate_alkaline9">
												<div className="box-title mb-5">
													<h3>9 Plate Alkaline Ionizer</h3>
												</div>

												<div className="plate-banner mb-3 mb-sm-5 second" style={{ backgroundImage: "url(" + Background3 + ")" }}>
													<div className="row align-items-center">
														<div className="col-sm-6 col-lg-6">
															<div className="plate-banner-content pl-3 pl-md-4 d-none d-sm-block">
																<h2 className="text-white">9 PLATE ALKALINE IONIZER</h2>
															</div>
														</div>
														<div className="col-sm-6 col-lg-6">
															<div className="plate-banner-img text-center">
																<img src="assets/images/cms-page/plate-9.png" className="img-fluid img-center" alt="" />
															</div>
														</div>
													</div>
													<div className="plate-shape">
														<img src="assets/images/cms-page/plate-shape.png" className="img-fluid img-center" alt="" />
													</div>
												</div>

												{/* <div className="plate-banner mb-5"
											// style="background-image:url('assets/images/cms-page/green_bg.png');"
											> */}
												{/* <div className="row align-items-center">
													<div className="col-lg-5 offset-lg-1">
														<div className="plate-banner-content-menu">
															<h2>9 PLATE ALKALINE IONIZER</h2>
														</div>
													</div>
													<div className="col-lg-6">
														<div className="plate-banner-img">
															<img src="assets/images/cms-page/plate-9.png" className="img-fluid img-center" alt="" />
														</div>
													</div>
												</div>
												<div className="plate-shape">
													<img src="assets/images/cms-page/plate-shape.png" className="img-fluid img-center" alt="" />
												</div>
											</div> */}

												<div className="product-details mb-3" style={{ marginTop: '5%' }} >
													<div className="alkaline_subtitle">
														<h4>Product Details</h4>
													</div>
													<div className="table-responsive">
														<table className="table table-bordered">
															<tbody>
																<tr>
																	<th>Electrodes Technology</th>
																	<td>Platinized- Titanium Smart Design Efficient SOLID Plates Technology.</td>
																</tr>
																<tr>
																	<th>Electrolysis&nbsp;Method</th>
																	<td>Continuous Electrolysis</td>
																</tr>
																<tr>
																	<th>No of Electrodes</th>
																	<td>9 Plates</td>
																</tr>
																<tr>
																	<th>Electrode Size(cm)</th>
																	<td>7.4cm x 14cm</td>
																</tr>
																<tr>
																	<th>pH Range</th>
																	<td>Highest pH level : upto 11 pH, Lowest pH level : 3.5 pH</td>
																</tr>
																<tr>
																	<th>Filter Life</th>
																	<td>Filter-1: 7,000 Litres, Filter-2: 7000 Litres.</td>
																</tr>
																<tr>
																	<th>ORP Performance</th>
																	<td>Up to -700mV ~</td>
																</tr>
																<tr>
																	<th>Molecular Hydrogen(H2) Performance</th>
																	<td>Up to 900ppb</td>
																</tr>
																<tr>
																	<th>Filter Type</th>
																	<td>High performance composite filter with Carbon ,Calcium Sulphite & Non-Woven Fabric</td>
																</tr>
																<tr>
																	<th>Electrolysis stage</th>
																	<td>5 Alkaline Levels / 2 Acidic Levels / 1 Purified Level</td>
																</tr>
																<tr>
																	<th>Cleaning Method</th>
																	<td>DARC (Dual Automatic Reverse Cleaning)</td>
																</tr>
																<tr>
																	<th>Water Flow Rate</th>
																	<td>1.5 to 2 litre/min. (Flow indicator displayed on LCD)</td>
																</tr>
																<tr>
																	<th>Rated Power Supply</th>
																	<td>110V- 250V, 200 Watt</td>
																</tr>
																<tr>
																	<th>LCD Display Functions</th>
																	<td>pH Level with Colour, Water Flow Rate, Digital Filter Life Indicator.</td>
																</tr>
																<tr>
																	<th>Voice Guidance System</th>
																	<td>Voice Guidance for different Alkaline pH Levels & Acidic Levels</td>
																</tr>
																<tr>
																	<th>Water Pressure</th>
																	<td>0.7~6.0kgf/cm2 (70kPa~500kPa)</td>
																</tr>
																<tr>
																	<th>Protection Devices</th>
																	<td>Built in 2 Temp. sensors (Automatic Restoration) & Low water pressure sensor.</td>
																</tr>
																<tr>
																	<th>Water Supply</th>
																	<td>Tap Water /Purified Water (Up to 250TDS with proper Pre filtration Equipment)</td>
																</tr>
																<tr>
																	<th>Operating Temperature</th>
																	<td>5-30C.(41-86F)</td>
																</tr>
																<tr>
																	<th>Installation</th>
																	<td>Suitable for Wall Mount And Table Top</td>
																</tr>
																<tr>
																	<th>Weight</th>
																	<td>7 KG</td>
																</tr>
																<tr>
																	<th>Faucet</th>
																	<td>180 Degree Adjustment Faucet</td>
																</tr>
																<tr>
																	<th>Dimensions</th>
																	<td>310(W) x 360(H) x 160(D) mm</td>
																</tr>
																<tr>
																	<th>Country of Origin</th>
																	<td>South Korea</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>

												<div className="plate-info mb-3">
													<div className="row">
														<div className="col-lg-12">
															<div className="plate-info-box mb-3">
																<div className="alkaline_subtitle">
																	<h4>Mechanism Of Making Ionized Water</h4>
																</div>
																<p>Each water molecule is with positive &amp; negative ions. Remove them requires a process called electrolysis. Purified water is sent to the “electrolyzer”.</p>
																<p>Here, water is electrolyzed by creating a flow of electric current, where the positive ions, such as sodium, magnesium and calcium are gathered toward the negative pole, making alkaline ionized water, and the negative
																	ions are gathered toward the positive pole, making acidic ionized water.</p>
																<p>Acidic water is drained and alkaline ionized water is released through the faucet.</p>
																<div className="plate-info-img">
																	<img src="assets/images/cms-page/ionized-water-9.png" className="img-fluid" alt="" />
																</div>
															</div>
														</div>
														<div className="col-lg-12">
															<div className="plate-info-box ">
																<div className="alkaline_subtitle">
																	<h4>High-performance Composite Filter</h4>
																</div>
																<div className="plate-info-img">
																	<img src="assets/images/cms-page/composite-filter-9.png" className="img-fluid img-center" alt="" />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="panel panel-default">
									<div className="panel-heading">
										<a className="nav-link js-tabcollapse-panel-heading collapsed" data-toggle="collapse" href="#benifits_alkaline-collapse" data-parent="#maincontent-accordion" aria-expanded="false">Benifits of Alkaline Ionizer</a>
									</div>
									<div id="benifits_alkaline-collapse" className="panel-collapse collapse ">
										<div className="panel-body js-tabcollapse-panel-body">
											<div className="benifits-section">
												<div className="row">
													<div className="col-lg-7">
														<div className="benifits-content">
															<div className="box-title mb-4">
																<h3 className="mb-0">Benefits of NASWIZ Ionized Water</h3>
															</div>
														</div>
														<div className="benifits-content">
															<ul className="benifits-points">
																<li>Antioxidant (Anti-Aging)</li>
																<li>Detoxifies the Body</li>
																<li>Neutralizes Acidity</li>
																<li>Boosts Immunity</li>
																<li>Hydrates Skin</li>
																<li>Weight Loss</li>
																<li>Heart Health / Blood Pressure</li>
																<li>Digestive Health</li>
																<li>Reduces the Muscles and Joint Discomfort</li>
																<li>Energized Human Body</li>
																<li>Effective Cleansing &amp; Washing</li>
															</ul>
														</div>
													</div>
													<div className="col-lg-5">
														<div className="benifits-img mb-3 mt-3 text-center">
															<img src="assets/images/cms-page/benefits-naswiz.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="panel panel-default">
									<div className="panel-heading">
										<a className="nav-link js-tabcollapse-panel-heading collapsed" data-toggle="collapse" href="#alkaline_use-collapse" data-parent="#maincontent-accordion" aria-expanded="false">How to use the alkaline water</a>
									</div>
									<div id="alkaline_use-collapse"
										className="panel-collapse collapse">
										<div className="panel-body js-tabcollapse-panel-body">
											<div className="how-to-use-section">
												<div className="box-title mb-5">
													<h3>How to use the Ionized water</h3>
													<p>The alkaline water ionizer is a class 2 medical equipment approved by KFDA. Electrolyte is achieved by the ionizer to make alkaline ionized water with pH 7.5 to 10.0.</p>
												</div>


												{/* -----add new ------- */}
												<div className="how-to-use-box">
													<div className="alkaline_subtitle">
														<h4>How to use Alkaline Ionized water</h4>
													</div>
													<div className="how-to-use-box-listing">
														<div className="row mb-3">
															<div className="col-md-6 col-lg-6">
																<div className="box-listing-details d-flex">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/drinking.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4 className="text-truncate">Drinking</h4>
																		<p>Drink regularly to help improve the four most popular gastrointestinal diseases.</p>
																	</div>
																</div>
															</div>
															<div className="col-md-6 col-lg-6">
																<div className="box-listing-details d-flex">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/given-pets.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4 className="text-truncate">Given to pets</h4>
																		<p>Reduces odors and makes the fur shinier.</p>
																	</div>
																</div>
															</div>
															<div className="col-md-6 col-lg-6">
																<div className="box-listing-details d-flex">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/vegetables.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4 className="text-truncate">Washing vegetables and fruit</h4>
																		<p>Helps with freshness</p>
																	</div>
																</div>
															</div>
															<div className="col-md-6 col-lg-6">
																<div className="box-listing-details d-flex">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/cooking.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4 className="text-truncate">Cooking meat and fish</h4>
																		<p>Removes odor, enhances taste and flavors, and helps with freshness.</p>
																	</div>
																</div>
															</div>
															<div className="col-md-6 col-lg-6">
																<div className="box-listing-details d-flex">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/coffee.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4 className="text-truncate">Brewing coffee or tea</h4>
																		<p>Helps with removing the bitter taste and enhancing the depth of taste or flavors.</p>
																	</div>
																</div>
															</div>
															<div className="col-md-6 col-lg-6">
																<div className="box-listing-details d-flex">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/rice.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4 className="text-truncate">Cooking rice</h4>
																		<p>Dip rice in alkaline ionized water for 30-60 minutes before cooking and the rice tastes better, looks shinier and stickier, and will not discolor easily.</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="how-to-use-box">
													<div className="alkaline_subtitle">
														<h4>How to use acidic ionized water</h4>
													</div>
													<div className="how-to-use-box-listing">
														<div className="row mb-3">
															<div className="col-md-6 col-lg-6">
																<div className="box-listing-details d-flex">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/bathing-cleaning.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4 className="text-truncate">Bathing and cleaning</h4>
																		<p>Helps with smoother, more resilient skin. Use after shampooing to neutralize the alkaline content of soap and leave your hair sleeker.</p>
																	</div>
																</div>
															</div>
															<div className="col-md-6 col-lg-6">
																<div className="box-listing-details d-flex">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/cleaning-house.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4 className="text-truncate">Cleaning the house</h4>
																		<p>Acidic ionized water can be used to remove dust and clean the bathrooms.</p>
																	</div>
																</div>
															</div>
															<div className="col-md-6 col-lg-6">
																<div className="box-listing-details d-flex">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/brushing-shaving.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4 className="text-truncate">Brushing teeth and shaving</h4>
																		<p>Acidic ionized water has the cleansing effect, so it keeps the teeth healthier with sterilizing effect & soothes the skin when shaving.</p>
																	</div>
																</div>
															</div>
															<div className="col-md-6 col-lg-6">
																<div className="box-listing-details d-flex">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/arranging-flowers.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4 className="text-truncate">Arranging flowers</h4>
																		<p>Use diluted acidic ionized water on the bottom of flowers to help extend the lifespan of flowers.</p>
																	</div>
																</div>
															</div>
															<div className="col-md-6 col-lg-6">
																<div className="box-listing-details d-flex">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/cutting-boards.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4 className="text-truncate">Washing dish & cutting boards</h4>
																		<p>Dip cutting boards, kitchen cloths, and knives in acidic ionized water for about 20 minutes to remove odors and help with bleaching and sterilizing.</p>
																	</div>
																</div>
															</div>
															<div className="col-md-6 col-lg-6">
																<div className="box-listing-details d-flex">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/grooming-pets.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4 className="text-truncate">Grooming pets</h4>
																		<p>Reduces shedding hair and helps with sleeker fur and deodorization.</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="how-to-use-box">
													<div className="alkaline_subtitle">
														<h4>How to use purified water</h4>
													</div>
													<div className="how-to-use-box-listing">
														<div className="row">
															<div className="col-lg-12">
																<div className="box-listing-details d-flex">
																	<div className="box-listing-img">
																		<img src="assets/images/cms-page/taking-medication.jpg" className="img-fluid img-center" alt="" />
																	</div>
																	<div className="box-listing-content">
																		<h4 className="text-truncate">Taking medication</h4>
																		<p>Use the purified water when taking medication. The ionized water has less cluster and purified ionized water helps with faster penetration of medication.</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												{/* --------old sturucture---------- */}
												{/* <div className="how-to-use-box">
												<div className="alkaline_subtitle">
													<h2>How to use Alkaline Ionized water</h2>
												</div>
												<div className="how-to-use-box-listing">
													<div className="row">
														<div className="col-lg-6">
															<div className="box-listing-details">
																<div className="box-listing-img">
																	<img src="assets/images/cms-page/drinking.jpg" className="img-fluid img-center" alt="" />
																</div>
																<div className="box-listing-content">
																	<h4>Drinking</h4>
																	<p>Drink regularly to help improve the four most popular gastrointestinal diseases.</p>
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="box-listing-details">
																<div className="box-listing-img">
																	<img src="assets/images/cms-page/given-pets.jpg" className="img-fluid img-center" alt="" />
																</div>
																<div className="box-listing-content">
																	<h4>Given to pets</h4>
																	<p>Reduces odors and makes the fur shinier.</p>
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="box-listing-details">
																<div className="box-listing-img">
																	<img src="assets/images/cms-page/vegetables.jpg" className="img-fluid img-center" alt="" />
																</div>
																<div className="box-listing-content">
																	<h4>Washing vegetables and fruit</h4>
																	<p>Helps with freshness</p>
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="box-listing-details">
																<div className="box-listing-img">
																	<img src="assets/images/cms-page/cooking.jpg" className="img-fluid img-center" alt="" />
																</div>
																<div className="box-listing-content">
																	<h4>Cooking meat and fish</h4>
																	<p>Removes odor, enhances taste and flavors, and helps with freshness.</p>
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="box-listing-details">
																<div className="box-listing-img">
																	<img src="assets/images/cms-page/coffee.jpg" className="img-fluid img-center" alt="" />
																</div>
																<div className="box-listing-content">
																	<h4>Brewing coffee or tea</h4>
																	<p>Helps with removing the bitter taste and enhancing the depth of taste or flavors.</p>
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="box-listing-details">
																<div className="box-listing-img">
																	<img src="assets/images/cms-page/rice.jpg" className="img-fluid img-center" alt="" />
																</div>
																<div className="box-listing-content">
																	<h4>Cooking rice</h4>
																	<p>Dip rice in alkaline ionized water for 30-60 minutes before cooking and the rice tastes better, looks shinier and stickier, and will not discolor easily.</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="how-to-use-box">
												<div className="alkaline_subtitle">
													<h2>How to use acidic ionized water</h2>
												</div>
												<div className="how-to-use-box-listing">
													<div className="row">
														<div className="col-lg-6">
															<div className="box-listing-details">
																<div className="box-listing-img">
																	<img src="assets/images/cms-page/bathing-cleaning.jpg" className="img-fluid img-center" alt="" />
																</div>
																<div className="box-listing-content">
																	<h4>Bathing and cleaning</h4>
																	<p>Helps with smoother, more resilient skin. Use after shampooing to neutralize the alkaline content of soap and leave your hair sleeker.</p>
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="box-listing-details">
																<div className="box-listing-img">
																	<img src="assets/images/cms-page/cleaning-house.jpg" className="img-fluid img-center" alt="" />
																</div>
																<div className="box-listing-content">
																	<h4>Cleaning the house</h4>
																	<p>Acidic ionized water can be used to remove dust and clean the bathrooms.</p>
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="box-listing-details">
																<div className="box-listing-img">
																	<img src="assets/images/cms-page/brushing-shaving.jpg" className="img-fluid img-center" alt="" />
																</div>
																<div className="box-listing-content">
																	<h4>Brushing teeth and shaving</h4>
																	<p>Acidic ionized water has the cleansing effect, so it keeps the teeth healthier with sterilizing effect &amp; soothes the skin when shaving.</p>
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="box-listing-details">
																<div className="box-listing-img">
																	<img src="assets/images/cms-page/arranging-flowers.jpg" className="img-fluid img-center" alt="" />
																</div>
																<div className="box-listing-content">
																	<h4>Arranging flowers</h4>
																	<p>Use diluted acidic ionized water on the bottom of flowers to help extend the lifespan of flowers.</p>
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="box-listing-details">
																<div className="box-listing-img">
																	<img src="assets/images/cms-page/cutting-boards.jpg" className="img-fluid img-center" alt="" />
																</div>
																<div className="box-listing-content">
																	<h4>Washing dish &amp; cutting boards</h4>
																	<p>Dip cutting boards, kitchen cloths, and knives in acidic ionized water for about 20 minutes to remove odors and help with bleaching and sterilizing.</p>
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="box-listing-details">
																<div className="box-listing-img">
																	<img src="assets/images/cms-page/grooming-pets.jpg" className="img-fluid img-center" alt="" />
																</div>
																<div className="box-listing-content">
																	<h4>Grooming pets</h4>
																	<p>Reduces shedding hair and helps with sleeker fur and deodorization.</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="how-to-use-box">
												<div className="alkaline_subtitle">
													<h2>How to use purified water</h2>
												</div>
												<div className="how-to-use-box-listing">
													<div className="row">
														<div className="col-lg-12">
															<div className="box-listing-details">
																<div className="box-listing-img">
																	<img src="assets/images/cms-page/taking-medication.jpg" className="img-fluid img-center" alt="" />
																</div>
																<div className="box-listing-content">
																	<h4>Taking medication</h4>
																	<p>Use the purified water when taking medication. The ionized water has less cluster and purified ionized water helps with faster penetration of medication.</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div> */}
											</div>

										</div>
									</div>
								</div>

								<div className="panel panel-default">
									<div className="panel-heading">
										<a className="nav-link js-tabcollapse-panel-heading collapsed" data-toggle="collapse" href="#alkaline_certificates-collapse" data-parent="#maincontent-accordion" aria-expanded="false">Alkaline Certificates</a>
									</div>
									<div id="alkaline_certificates-collapse"
										className="panel-collapse collapse ">
										<div className="panel-body js-tabcollapse-panel-body">
											<div className="alkaline_certificates_section">
												<div className="box-title mb-4">
													<h3>Alkaline Certificates</h3>
													<p>The Alkaline Water Certificates has introduced Alkaline Water Filter Pitcher to provide you and your family with safe, clean and germ-free water with the right pH levels.</p>
												</div>
												<div className="row">
													<div className="col-11 col-md-8 mx-auto">
														<img src="assets/images/Distributor-Certificates.jpg" className="img-fluid img-center" alt="" />
													</div>
												</div>
												<div className="row mt-3">
													<div className="col-sm-4 col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/pse-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-sm-4 col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/korean-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-sm-4 col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/a-iso-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-sm-4 col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/fda-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-sm-4 col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/nrtl-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-sm-4 col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/korea-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-sm-4 col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/iecee-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-sm-4 col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/ce-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-sm-4 col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/medical-device-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-sm-4 col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/iso-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-sm-4 col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/gmp-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
													<div className="col-sm-4 col-md-3 col-6">
														<div className="certificates-logo">
															<img src="assets/images/cms-page/tuv-logo.jpg" className="img-fluid img-center" alt="" />
														</div>
													</div>
												</div>
											</div>

										</div>
									</div>
								</div>

								<div className="panel panel-default">
									<div className="panel-heading">
										<a className="nav-link js-tabcollapse-panel-heading collapsed" data-toggle="collapse" href="#manufacturing-collapse" data-parent="#maincontent-accordion" aria-expanded="false">Manufacturing Process</a>
									</div>
									<div id="manufacturing-collapse" className="panel-collapse collapse ">
										<div className="panel-body js-tabcollapse-panel-body">
											{/* <div className="manufacturing-section">
											<div className="box-title mb-5">
												<h3>Manufacturing Process</h3>
											</div>
											<div className="box-listing-details mt-5">
												<img src={require("./../../../assets/images/cms-page/title-icon.png")} alt="" />
												<h3 >MESSAGE FROM CEO - BIONTECH</h3>
											</div>
											<div className="row">
												<div className="col-lg-6">
													<div className="manufacturing-video">
														<img src={require("./../../../assets/images/cms-page/biontech.jpeg")} className="img-fluid img-center video-poster" alt="" />
														<div className="play-video">
															<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/dAxPYdWQ9lA')} data-backdrop="static" title="play video">
																<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
															</a>
														</div>
													</div>
													<div className="manufacturing-video">
														<img src={require("./../../../assets/images/cms-page/biontech_naswiz.jpeg")} className="img-fluid img-center video-poster" alt="" />
														<div className="play-video">
															<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/xq5wNNdcFok')} data-backdrop="static" title="play video">
																<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
															</a>
														</div>
													</div>
												</div>
											</div>
											<div className="manufacturing-section ul">
												<div className="box-listing-details mt-5">
													<img src={require("./../../../assets/images/cms-page/title-icon.png")} alt="" />
													<h3 >MANUFACTURING PROCESS</h3>
												</div>
											</div>
											<div className="row">
												<div className="col-lg-6">
													<div className="manufacturing-video">
														<img src={require("./../../../assets/images/cms-page/manufacturing-process.jpeg")} className="img-fluid img-center video-poster" alt="" />
														<div className="play-video">
															<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/QoIFRdE-Sdc')} data-backdrop="static" title="play video">
																<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
															</a>
														</div>
													</div>
													<div className="manufacturing-video">
														<img src={require("./../../../assets/images/cms-page/manufacturing-process2.jpeg")} className="img-fluid img-center video-poster" alt="" />
														<div className="play-video">
															<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/MO2C2Nd6V-8')} data-backdrop="static" title="play video">
																<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
															</a>
														</div>
													</div>
												</div>
												{/* <div className="col-lg-6">
												<div className="manufacturing-video">
													<img src={require("./../../../assets/images/cms-page/manufacturing-process2.jpeg")} className="img-fluid img-center video-poster" alt="" />
													<div className="play-video">
														<a href="javascript:void(0)" onClick={() => this.onOpenModaln()} data-backdrop="static" title="play video">
															<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
														</a>
													</div>
												</div>
											</div>}
											</div>
											<div className="box-listing-details mt-5">
												<img src={require("./../../../assets/images/cms-page/title-icon.png")} alt="" />
												<h3 >ELECTROLYTIC CELL</h3>
											</div>
											<div className="row">
												<div className="col-lg-6">
													<div className="manufacturing-content ul li manufacturing-section">
														<p>Biontech employs only outstanding electrolytic cells. An electrolytic cell is an essential component for ionizing purified water.</p>
														<ul>
															<li>Outstanding ionization capability through increased use of pure titanium and 99% platinum coating.</li>
															<li>Enhanced ionization by embossing effect from sanded electrode surface</li>
															<li>High anti-corrosion and continual electrolytic function</li>
															<li>Outstanding technology that generates high ORP value even in low pH</li>
															<li>DARC System is equipped with a ceramic valve to prevent scale build-ups inside the electrolytic cell</li>
															<li>Flawless leak prevention through rigid moulding</li>
														</ul>
													</div>

												</div>
												<div className="col-lg-6">
													<div className="manufacturing-video">
														<img src={require("./../../../assets/images/cms-page/electrolytic-cell.jpeg")} className="img-fluid img-center video-poster" alt="" />
														<div className="play-video">
															<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/mmQKi-_EzhY')} data-backdrop="static" title="play video">
																<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
															</a>
														</div>
													</div>
													{/* <div className="manufacturing-video">
														<img src="assets/images/cms-page/manufacturing-video-1.jpg" className="img-fluid img-center video-poster" alt="" />
														<div className="play-video">
															<a href="javascript:void(0)" onClick={() => this.onOpenModaln()} data-backdrop="static" title="play video">
																<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
															</a>
														</div>
													</div>
													<div className="manufacturing-video">
														<img src="assets/images/cms-page/manufacturing-video-2.jpg" className="img-fluid img-center video-poster" alt="" />
														<div className="play-video">
															<a href="javascript:void(0)" onClick={() => this.onOpenModalns()} data-backdrop="static" title="play video">
																<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
															</a>
														</div>
													</div>}
												</div>
											</div>
											<div className="box-listing-details mt-5">
												<img src={require("./../../../assets/images/cms-page/title-icon.png")} alt="" />
												<h3 >ANTI-SCALE SYSTEM / DARC</h3>
											</div>
											<div className="row">

												<div className="col-lg-6">
													<div className="manufacturing-video">
														<img src={require("./../../../assets/images/cms-page/anti-scale-system.jpeg")} className="img-fluid img-center video-poster" alt="" />
														<div className="play-video">
															<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/0IfocWAQQjc')} data-backdrop="static" title="play video">
																<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
															</a>
														</div>
													</div>
													{/* <div className="manufacturing-video">
														<img src="assets/images/cms-page/manufacturing-video-1.jpg" className="img-fluid img-center video-poster" alt="" />
														<div className="play-video">
															<a href="javascript:void(0)" onClick={() => this.onOpenModaln()} data-backdrop="static" title="play video">
																<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
															</a>
														</div>
													</div>
													<div className="manufacturing-video">
														<img src="assets/images/cms-page/manufacturing-video-2.jpg" className="img-fluid img-center video-poster" alt="" />
														<div className="play-video">
															<a href="javascript:void(0)" onClick={() => this.onOpenModalns()} data-backdrop="static" title="play video">
																<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
															</a>
														</div>
													</div>}
												</div>
												<div className="col-lg-6">
													<div className="manufacturing-content ul li manufacturing-section">
														<p>Anti-Scale System is an exclusive BionTech technology that innovatively prevents scale buildups inside the electrolytic cell.</p>
														<ul>
															<li>Enhanced durability using ceramic discs</li>
															<li>Automatic disc conversion notification sensor prevents mixing of alkaline and acidic water.</li>
														</ul>
													</div>

												</div>
											</div>
											<div className="box-listing-details mt-5">
												<img src={require("./../../../assets/images/cms-page/title-icon.png")} alt="" />
												<h3 >FILTER FUNCTION</h3>
											</div>
											<div className="row">
												<div className="col-lg-6">
													<div className="manufacturing-content ul li manufacturing-section">
														<p>It is Biontech's firm belief that filters must enable water to fulfill its role and function. Biontech strictly selects raw materials whose quality have been certified under international standards.</p>
														<ul>
															<li>Bottom-up flow structure enables precision water purification</li>
															<li>Pyramid structure enhances compression within filters for better purification function</li>
															<li>High quality active carbon produces cleaner water</li>
															<li>Fillers made from high quality materials result in effective purification</li>
															<li>Water purification ability is magnified using compressed 7-layer purification materials</li>
															<li>Hollow fiber membrane filtering removes harmful heavy metals but leaves various minerals</li>
															<li>Use of smart filter prevents use of counterfeits</li>
														</ul>
													</div>

												</div>
												<div className="col-lg-6">
													<div className="manufacturing-video">
														<img src={require("./../../../assets/images/cms-page/filter-function.jpeg")} className="img-fluid img-center video-poster" alt="" />
														<div className="play-video">
															<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/A__VBzIBAZ8')} data-backdrop="static" title="play video">
																<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
															</a>
														</div>
													</div>
													{/* <div className="manufacturing-video">
														<img src="assets/images/cms-page/manufacturing-video-1.jpg" className="img-fluid img-center video-poster" alt="" />
														<div className="play-video">
															<a href="javascript:void(0)" onClick={() => this.onOpenModaln()} data-backdrop="static" title="play video">
																<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
															</a>
														</div>
													</div>
													<div className="manufacturing-video">
														<img src="assets/images/cms-page/manufacturing-video-2.jpg" className="img-fluid img-center video-poster" alt="" />
														<div className="play-video">
															<a href="javascript:void(0)" onClick={() => this.onOpenModalns()} data-backdrop="static" title="play video">
																<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
															</a>
														</div>
													</div> }
												</div>
											</div>
										</div> */}

											<div className="manufacturing-section">

												<div className="box-title mb-5">
													<h3>Manufacturing Process</h3>
												</div>

												<div className="mb-5">
													<div className="alkaline_subtitle icon_subtitle d-flex align-items-center">
														<img src={require("./../../../assets/images/cms-page/title-icon.png")} alt="" />
														<h4 className="ml-2">Message From The CEO Of BIONTECH</h4>
													</div>
													<div className="row">
														<div className="col-md-6 mb-4 mb-md-0">
															<div className="manufacturing-video position-relative">
																<img src={require("./../../../assets/images/cms-page/biontech.jpeg")} className="img-fluid img-center video-poster" alt="" />
																<div className="play-video">
																	<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/dAxPYdWQ9lA')} data-backdrop="static" title="play video">
																		<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
																	</a>
																</div>
															</div>
														</div>
														<div className="col-md-6">
															<div className="manufacturing-video position-relative">
																<img src={require("./../../../assets/images/cms-page/biontech_naswiz.jpeg")} className="img-fluid img-center video-poster" alt="" />
																<div className="play-video">
																	<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/xq5wNNdcFok')} data-backdrop="static" title="play video">
																		<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="mb-5">
													<div className="alkaline_subtitle icon_subtitle d-flex align-items-center">
														<img src={require("./../../../assets/images/cms-page/title-icon.png")} alt="" />
														<h4 className="ml-2">manufacturing process</h4>
													</div>
													<div className="row">
														<div className="col-md-6 mb-4 mb-md-0">
															<div className="manufacturing-video position-relative">
																<img src={require("./../../../assets/images/cms-page/manufacturing-process.jpeg")} className="img-fluid img-center video-poster" alt="" />
																<div className="play-video">
																	<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/QoIFRdE-Sdc')} data-backdrop="static" title="play video">
																		<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
																	</a>
																</div>
															</div>
														</div>
														<div className="col-md-6">
															<div className="manufacturing-video position-relative">
																<img src={require("./../../../assets/images/cms-page/manufacturing-process2.jpeg")} className="img-fluid img-center video-poster" alt="" />
																<div className="play-video">
																	<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/MO2C2Nd6V-8')} data-backdrop="static" title="play video">
																		<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="mb-5">
													<div className="alkaline_subtitle icon_subtitle d-flex align-items-center">
														<img src={require("./../../../assets/images/cms-page/title-icon.png")} alt="" />
														<h4 className="ml-2">Electroytic Cell</h4>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="manufacturing-content ul li manufacturing-section">
																<p>Biontech employs only outstanding electrolytic cells. An electrolytic cell is an essential component for ionizing purified water.</p>
																<ul>
																	<li>Outstanding ionization capability through increased use of pure titanium and 99% platinum coating.</li>
																	<li>Enhanced ionization by embossing effect from sanded electrode surface</li>
																	<li>High anti-corrosion and continual electrolytic function</li>
																	<li>Outstanding technology that generates high ORP value even in low pH</li>
																	<li>DARC System is equipped with a ceramic valve to prevent scale build-ups inside the electrolytic cell</li>
																	<li>Flawless leak prevention through rigid moulding</li>
																</ul>
															</div>
														</div>
														<div className="col-md-6">
															<div className="manufacturing-video">
																<img src={require("./../../../assets/images/cms-page/electrolytic-cell.jpeg")} className="img-fluid img-center video-poster" alt="" />
																<div className="play-video">
																	<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/mmQKi-_EzhY')} data-backdrop="static" title="play video">
																		<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="mb-5">
													<div className="alkaline_subtitle icon_subtitle d-flex align-items-center">
														<img src={require("./../../../assets/images/cms-page/title-icon.png")} alt="" />
														<h4 className="ml-2">Anti-scale System / Darc</h4>
													</div>
													<div className="row">
														<div className="col-lg-6 mb-2">
															<div className="manufacturing-video position-relative">
																<img src={require("./../../../assets/images/cms-page/anti-scale-system.jpeg")} className="img-fluid img-center video-poster" alt="" />
																<div className="play-video">
																	<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/0IfocWAQQjc')} data-backdrop="static" title="play video">
																		<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
																	</a>
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="manufacturing-content ul li mb-3">
																<p>Anti-Scale System is an exclusive BionTech technology that innovatively prevents scale buildups inside the electrolytic cell.</p>
																<ul>
																	<li>Enhanced durability using ceramic discs</li>
																	<li>Automatic disc conversion notification sensor prevents mixing of alkaline and acidic water.</li>
																</ul>
															</div>
														</div>
													</div>
												</div>

												<div className="mb-5">
													<div className="alkaline_subtitle icon_subtitle d-flex align-items-center">
														<img src={require("./../../../assets/images/cms-page/title-icon.png")} alt="" />
														<h4 className="ml-2">Filter Function</h4>
													</div>
													<div className="row">
														<div className="col-lg-6">
															<div className="manufacturing-content ul li manufacturing-section">
																<p>It is Biontech's firm belief that filters must enable water to fulfill its role and function. Biontech strictly selects raw materials whose quality have been certified under international standards.</p>
																<ul>
																	<li>Bottom-up flow structure enables precision water purification</li>
																	<li>Pyramid structure enhances compression within filters for better purification function</li>
																	<li>High quality active carbon produces cleaner water</li>
																	<li>Fillers made from high quality materials result in effective purification</li>
																	<li>Water purification ability is magnified using compressed 7-layer purification materials</li>
																	<li>Hollow fiber membrane filtering removes harmful heavy metals but leaves various minerals</li>
																	<li>Use of smart filter prevents use of counterfeits</li>
																</ul>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="manufacturing-video">
																<img src={require("./../../../assets/images/cms-page/filter-function.jpeg")} className="img-fluid img-center video-poster" alt="" />
																<div className="play-video">
																	<a href="javascript:void(0)" onClick={() => this.onOpenModaln('https://www.youtube.com/embed/A__VBzIBAZ8')} data-backdrop="static" title="play video">
																		<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="" />
																	</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="panel panel-default">
									<div className="panel-heading">
										<a className="nav-link js-tabcollapse-panel-heading collapsed" data-toggle="collapse" href="#client_testimonial-collapse" data-parent="#maincontent-accordion" aria-expanded="false">Client Testimonial</a>
									</div>
									<div id="client_testimonial-collapse" className="panel-collapse collapse ">
										<div className="panel-body js-tabcollapse-panel-body">

											<div className="testimonial-wrapper">
												<div className="box-title mb-4">
													<h3>Testimonial</h3>
												</div>

												<ul className="row">
													{this.state.testimonial_alkaline.length > 0 ?
														this.state.testimonial_alkaline.map((item, index) => {
															return (
																<li key={index} className="col-md-4 col-sm-6">
																	<div className="testimonial-box d-flex flex-column border h-100">
																		<div className="manufacturing-video position-relative">
																			{item.is_check == 0 ?
																				<img
																					onClick={async () => {
																						await this.setState({
																							_mediaType: item.is_check,
																							_mediaUrl: item.image_full_path
																						})
																						if (item.image_full_path != '') {
																							await this.onOpenModal()

																						}
																					}}
																					src={item.image_full_path == '' ? 'assets/images/cms-page/testimonial-video-poster-1.jpg' : item.image_full_path} alt="" className="img-fluid img-center video-poster" style={{ maxHeight: '278px', minHeight: '278px', objectFit: 'cover', objectPosition: 'center' }} />
																				:
																				<img src={item.thumbnail_full_path} className="img-fluid img-center video-poster w-100" alt="video-img" style={{ maxHeight: '278px', minHeight: '278px', objectFit: 'cover', objectPosition: 'center' }} />
																			}
																			{item.is_check == 1 ? <div className="play-video">
																				<a href="javascript:void(0)" onClick={async () => {
																					await this.setState({
																						_mediaType: item.is_check,
																						_mediaUrl: item.youtube_link
																					})
																					await this.onOpenModal()
																				}}>
																					<img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="play-video-icon-img" />
																				</a>
																			</div> : null}
																			{/* <Modal open={this.state.open} onClose={this.onCloseModal} center>
																				<div className="editaddressModal  modal-dialog vertical-align-center">
																					{this.state._mediaType == 0 ?
																						<img src={this.state._mediaUrl} alt="" className="img-fluid" />
																						:
																						<iframe src={this.state._mediaUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
																					}
																				</div>
																			</Modal> */}

																			{/* <Modal open={this.state.open} onClose={this.onCloseModal} center>
																				<div className="video-modal12">
																					<div className="video-responsive122">
																						{this.state._mediaType == 0 ?
																							<img src={this.state._mediaUrl} alt="video-img" className="img-fluid" />
																							:
																							<iframe src={this.state._mediaUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
																						}
																					</div>
																				</div>
																			</Modal> */}
																		</div>
																		<div className="testimonial-content d-flex flex-column h-100">
																			<p className="pb-2 text-capitalize">{item.description}</p>
																			<div className="bottom-txt text-right">
																				<h5 className="text-capitalize text-truncate">{item.name} </h5>
																				<h6 className="text-capitalize text-truncate">{item.designation} </h6>
																			</div>
																		</div>
																	</div>
																</li>
															)
														}) : null
													}
												</ul>

											</div>
											<div className="text-center" >
												{this.state._noOfPage.length > 1 ?
													<ReactPaginate
														previousLabel={'<'}
														nextLabel={'>'}
														breakLabel={'...'}
														breakClassName={'page-item'}
														breakLinkClassName={'page-link'}
														pageCount={this.state._noOfPage.length}
														marginPagesDisplayed={1}
														pageRangeDisplayed={5}
														onPageChange={(page) => this.handlePagination(page)}
														containerClassName={'pagination toolbox-item justify-content-center'}
														pageClassName={'page-item'}
														pageLinkClassName={'page-link'}
														previousClassName={'page-item'}
														previousLinkClassName={'page-link'}
														nextClassName={'page-item'}
														nextLinkClassName={'page-link'}
														activeClassName={'active'}
													/>

													: null}
											</div>
										</div>
									</div>
								</div>
								<Modal open={this.state.mopen} onClose={this.onCloseModaln} center>
									<div className="video-modal12">
										<div className="video-responsive122">
											{/* <iframe src="https://www.youtube.com/embed/xq5wNNdcFok" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
											<iframe src={this.state.videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
										</div>
									</div>
								</Modal>
								<Modal open={this.state.mopens} onClose={this.onCloseModalns} center>
									<div className="video-modal12">
										<div className="video-responsive122">
											<iframe src="https://www.youtube.com/embed/QoIFRdE-Sdc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
										</div>
									</div>
								</Modal>


							</div>
						</div>

					</section>
				</main>

			</div>
		)
	}

}

export default withTranslate(AlkalineIonizer);
