import React, { Component } from 'react';
import { faAt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NaswizHelperApi } from '../../NaswizHelper';
import Breadcrumb from "../common/breadcrumb";
import { send } from 'emailjs-com';
import Notification from '../../Notification'
import renderHTML from 'react-render-html';
import { Helmet } from 'react-helmet';
import Modal from 'react-responsive-modal';
import { Redirect } from 'react-router-dom'
import Seo from '../Seo';


class componentName extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _countword: 0,
            _totalword: 500,
            limWords: null,
            wordCount: 0,
            _type: '',
            _grievance: '',
            _id_no: '',
            _name: '',
            _email: '',
            _mobile: '',
            _details: '',
            regexp: /^[0-9\b]+$/,
            openModel: false,
            gra_id: ''
        };
    }

    componentDidMount = () => {
        window.scrollTo(0, 0)
    }

    countWords(event) {

        const wordCount =
            event.target.value === "" ? 0 : event.target.value.split(" ").length;
        this.setState({
            _details: event.target.value,
            wordCount: wordCount,
            limWords:
                this.state._totalword - wordCount < 0
                    ? this.state._details.length
                    : null
        });
    }

    submit = async () => {
        var isValide = true;
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (this.state._type == '') {
            Notification('error', 'Error!', 'Please select type.')
            isValide = false;
        }
        if (this.state._grievance == '') {
            Notification('error', 'Error!', 'Please select grievance.')
            isValide = false;
        }
        if (this.state._id_no == '') {
            Notification('error', 'Error!', 'Please enter ID No.')
            isValide = false;
        }
        if (this.state._name == '') {
            Notification('error', 'Error!', 'Please enter Name.')
            isValide = false;
        }

        // if (this.state._email ==  null ) {
        //     Notification('error', 'Error!', 'Please enter Email.')
        //     isValide = false;
        // }

        if (re.test(this.state._email) === false) {
            isValide = false;
            Notification('error', 'Error!', 'Please enter valid email id')
        }
        if (this.state._mobile == '') {
            Notification('error', 'Error!', 'Please enter Mobile.')
            isValide = false;
        }
        if (this.state._details == '') {
            Notification('error', 'Error!', 'Please enter Details.')
            isValide = false;
        }

        if (isValide) {
            var formData = new FormData()
            formData.append("type", this.state._type)
            formData.append("grievance", this.state._grievance)
            formData.append("id_no", this.state._id_no)
            formData.append("name", this.state._name)
            formData.append("email", this.state._email)
            formData.append("mobile_no", this.state._mobile)
            formData.append("details", this.state._details)

            this.setState({ isLoading: true })

            var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/get-grievance', formData, "Error => Get state upon county api")
            this.setState({ isLoading: false })

            if (res && res.success == 1) {
                Notification('success', 'Success!', res.message)
                // this.setState({ gra_id: res.data.gri_no, openModel: true })
                this.props.history.push({ pathname: "/graivence_success", state: { gra_no: res.data.gri_no } })
                // this.props.history.push({
                //     pathname: 'thankyou',
                //     state: { gri_no: res.data.gri_no }
                // })
            }
        }
    }

    onChangeID = (evt) => {
        const newId = evt.target.value.replace(
            /[^a-zA-Z0-9]/g,
            ""
        );
        this.setState({ _id_no: newId })
    }

    onHandleTelephoneChange = e => {
        let telephone = e.target.value;
        if (telephone === '' || this.state.regexp.test(telephone)) {
            this.setState({ _mobile: telephone })
        }
        else {
            return;
        }
    };


    render() {
        return (
            <div>
                {/* <Helmet>
                    <title>Help Center | Customer Care Support  - Naswiz Retails Pvt Ltd</title>
                    <meta name="og_title" property="og:title" content="Help Center | Customer Care Support  - Naswiz Retails Pvt Ltd" />
                    <meta name="twitter:title" content="Help Center | Customer Care Support  - Naswiz Retails Pvt Ltd" />
                    <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                    <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                    <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                    <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                </Helmet> */}
                <Seo title="Help Center - Customer Care Support" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company."  />
                <Breadcrumb title={'Grievance'} />

                <section className="cms-wrapper pb-3">
                    <div className="container">                    
                        <div className="cms-inner-wrapper">

                            <div className="cms-page-title mb-30">
                                <h2>Grievance Redressal</h2>
                                <p>If You Have Any Grievance Related To Product/remuneration Plan/direct Sellers Etc, Kindly Fill The Details In The Form Given Below</p>
                            </div>
                            
                            <div className="row">
                                <div className="col-sm-12">
                                    <form className="">
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Type<sup className="top_star">*</sup></label>
                                                    <select
                                                        onChange={(e) => this.setState({ _type: e.target.value })} className="form-control">
                                                        <option value={''}>Select</option>
                                                        <option value={'Customer'}>Customer</option>
                                                        <option value={'Direct Seller'}>Direct Seller</option>

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Grievance<sup className="top_star">*</sup></label>
                                                    <select
                                                        onChange={(e) => this.setState({ _grievance: e.target.value })} className="form-control">
                                                        <option value={''}>Select</option>
                                                        <option value={'Related to Dispatch'}>Related to Dispatch</option>
                                                        <option value={'Related to Commission'}>Related to Commission</option>
                                                        <option value={'Related to KYC Update'}>Related to KYC Update</option>
                                                        <option value={'Related to Product Damage'}>Related to Product Damage</option>
                                                        <option value={'Related to Activation'}>Related to Activation</option>

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="name">ID No<sup className="top_star">*</sup></label>
                                                    <input type="text" className="form-control" id="_fname"
                                                        value={this.state._id_no}
                                                        // onChange={e => this.setState({ _id_no: e.target.value })}
                                                        onChange={(e) => this.onChangeID(e)}
                                                        required="" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="name">Name<sup className="top_star">*</sup></label>
                                                    <input type="text" className="form-control" id="_fname"
                                                        value={this.state._name}
                                                        // onChange={e => this.setState({ _name: e.target.value })}
                                                        onChange={e => {
                                                            const newName = e.target.value.replace(
                                                                /[^a-zA-Z\s]/g,
                                                                ""
                                                            );
                                                            this.setState({ _name: newName })
                                                        }}
                                                        required="" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="name">Email<sup className="top_star">*</sup></label>
                                                    <input type="email" className="form-control" id="_fname"
                                                        value={this.state._email}
                                                        onChange={e => this.setState({ _email: e.target.value })}
                                                        required="" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="name">Mobile<sup className="top_star">*</sup></label>
                                                    <input type="tel" autoComplete='tel' maxLength={10} className="form-control" id="_fname"
                                                        value={this.state._mobile}
                                                        inputMode="numeric"
                                                        // onChange={e => this.setState({ _mobile: e.target.value })}
                                                        onChange={e => this.onHandleTelephoneChange(e)}
                                                        required="" />
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="review">Details<sup className="top_star">*</sup></label>
                                                    <textarea
                                                        maxLength={this.state.limWords} //500 words near
                                                        className="form-control" placeholder="Write Your Message"
                                                        id="_descriptionmsg" value={this.state._details}
                                                        onChange={e => this.countWords(e)}></textarea>
                                                    {this.state.wordCount > this.state._totalword ? <h6>*Allow 500 words</h6> : null}
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="subment-button" onClick={() => this.submit()}>
                                                    {this.state.isLoading ?
                                                        <a className="btninner" href="javascript:void(0)">
                                                            <div className="loader-wrapper">
                                                                <div className="loader"></div>
                                                            </div>
                                                        </a> :
                                                        <a className="btn theme-button corner-button" href="javascript:void(0)">Submit</a>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                {/* <Modal open={this.state.openModel} onClose={() => this.setState({ openModel: false })} center>
                    <div className="editaddressModal vertical-align-center">

                        <div className="adderess_inside">
                            <h4 style={{ fontSize: '18px', fontWeight: '500', marginBottom: '8px' }}>Grievance added successully.</h4>
                            <p style={{ textAlign: 'center' }}>Your Grievance no is {this.state.gra_id}</p>
                        </div>

                    </div>
                </Modal> */}
            </div >
        );
    }
}

export default componentName;
