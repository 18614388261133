import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import ReactDOM from 'react-dom';
import { NaswizHelperApi } from '../NaswizHelper';
import Breadcrumb from "../components/common/breadcrumb";

class naswiz_stores extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading_pages: false,
            arr_pageData: [],
            activeSlug: undefined
        }
    }

    componentDidMount = () => {
        this.getPages()
    }

    getPages = async (slug) => {



        var res = await NaswizHelperApi.GET_WITHOUT_TOKEN('v1/store-details', "Error => v1/store-details")

        if (res && res.success == 1) {
            await this.setState({
                arr_pageData: res.record,
                loading_pages: false
            })
        }
        else {
            this.setState({ loading_pages: false })
        }
    }

    render() {
        const { arr_pageData } = this.state;
        return (
            <div>
                <Breadcrumb title={'Naswiz Stores'} />
                <section className="store-location-listing pb-3">
                    <div className="container" >

                        <div className="section-title">
                            <h2 className="text-capitalize">Naswiz Stores</h2>
                        </div>

                        {
                            arr_pageData && arr_pageData.length > 0 ?
                                arr_pageData.map((item) => {
                                    return (
                                        item.store_location.length > 0 ?
                                            <div className="state-box" style={{ backgroundColor: item.box_color }}>
                                                <div className="row">

                                                    <div className="col-md-4 mb-2 mb-md-0">
                                                        <div className="state-img">
                                                            <img className="mx-auto d-block" alt="state-img" src={item.image_full_path} />
                                                            <h2 className="text-capitalize text-white text-center mb-0">{item.name}</h2>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-8">
                                                        <div className="state-content">
                                                            {item.store_location && item.store_location.length > 0 ?
                                                                item.store_location.map((sub_item) => {
                                                                    return (
                                                                        <div className="state-content-inner">
                                                                            <div className="row align-items-center">
                                                                                <div className="col-sm-9 col-md-9">
                                                                                    <ul className="content-list mb-0">
                                                                                        <li className="text-white"><label className="mb-0">Location :</label> <span>{sub_item.location_name} </span></li>
                                                                                        <li className="text-white"><label className="mb-0">Address :</label> <span> <strong>{sub_item.store_name}</strong><br /> {sub_item.address}</span></li>
                                                                                    </ul>
                                                                                </div>
                                                                                {sub_item.image_full_path ?
                                                                                <div className="col-sm-3 col-md-3 mt-2 mt-sm-0">
                                                                                    <div className="qr-img text-sm-center">
                                                                                        {/* <p>{sub_item.image_full_path}</p> */}
                                                                                        <a href={sub_item.link} target="_blank">
                                                                                            <img alt="" src={sub_item.image_full_path} />
                                                                                        </a>
                                                                                    </div>
                                                                                </div> :null}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                : null}

                                                        </div>
                                                    </div>

                                                </div>
                                            </div> : null
                                    )
                                }) : null
                        }

                    </div>
                </section>
            </div>
        )
    }

}

export default (naswiz_stores);



