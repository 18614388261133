import React, { Component, useState } from 'react';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link, Route, BrowserRouter } from 'react-router-dom'
import { getCartData } from '../../actions'
import { base_url } from '../../helper';
import { NaswizHelperApi } from '../../NaswizHelper';
import Notification from '../../Notification'
import Seo from '../Seo';



class order_confirmatom extends Component {
    constructor(props) {
        super(props)
        this.state = {
            arr_userOrder: [],
            _order_id: '',
            uniueId: '',

        }
    }

    componentDidMount = async () => {
        var orderid = await localStorage.getItem('order_id')
        var uniueId = localStorage.getItem('deviceId')
        // var payment_type = localStorage.getItem('payment_type')
        // this.setState({ uniueId: uniueId })
        // this.setState({ _order_id: orderid })
        // if (orderid != '' && orderid != null) {
        //     if (payment_type == 'online') {
        //         await localStorage.setItem('payment_type', '')

        //         var formData = new FormData()
        //         formData.append('order_id', orderid)
        //         formData.append('device_id', uniueId)

        //         var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/check-payment', formData, 'Error => v1/check-referral')

        //         if (res.success == 1) {
        //             Notification('success', 'Success!', res.message)
        //             await localStorage.setItem('order_id', '')
        //             this.getCartList()
        //         } else {
        //             Notification('error', 'Error!', '' + res.message)

        //         }
        //         await localStorage.setItem('payment_type', '')
        //     }
        // }
        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000); 
    }
    async getCartList() {
        var formdata = new FormData()
        formdata.append('device_id', this.state.uniueId)
        this.props.getCartData('v1/get-cart-list', formdata, "Error => GetCartList api.")

    }

    render() {

        return (
            <div>
            <Seo title="Order-Confirm" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                <section className="section-b-space light-layout">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="success-text">
                                    <p className="small-title">THANK YOU FOR YOUR PURCHASE!</p>
                                    <h2>Your Order Has Been Received.</h2>
                                    <div className="content">
                                        <ul>
                                            <li>Your Order # is: <a href="page/user/myorder">{this.state._order_id}.</a></li>
                                            <li>You Will receive an Order confirmation email with datails of your order and a link to track its progress</li>
                                            <li>click <a href="">here to print</a> a copy of your order confirmation.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }


}
const mapStateToProps = (state) => ({
    isUserLogin: state.login.isUserLogin,
    cartListArray: state.login.userCartData.records.cart_list,

})

export default connect(mapStateToProps, { getCartData })(order_confirmatom)

/////////////////////////////////////////////////////////////////////////
//////////////////// funtional component/////////////////////////////////
//////////////////////////////////////////////////////////////////////////

// import React, { Component, useState, useEffect } from 'react';
// import { Helmet } from 'react-helmet'
// import { connect } from 'react-redux'
// import { Link, Route, BrowserRouter } from 'react-router-dom'
// import { getCartData } from '../../actions'
// import { base_url } from '../../helper';
// import { NaswizHelperApi } from '../../NaswizHelper';
// import Notification from '../../Notification'



// const order_confirmatom = (props) => {
//     const [arr_userOrder, setArr_userOrder] = useState([])
//     const [_order_id, set_order_id] = useState('')
//     const [uniueId, setUniueId] = useState('')

//     useEffect(() => {
//         const orderid = async () => await localStorage.getItem('order_id')
//         var uniueId = localStorage.getItem('deviceId')
//         // var payment_type = localStorage.getItem('payment_type')
//         // this.setState({ uniueId: uniueId })
//         // this.setState({ _order_id: orderid })
//         // if (orderid != '' && orderid != null) {
//         //     if (payment_type == 'online') {
//         //         await localStorage.setItem('payment_type', '')

//         //         var formData = new FormData()
//         //         formData.append('order_id', orderid)
//         //         formData.append('device_id', uniueId)

//         //         var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/check-payment', formData, 'Error => v1/check-referral')

//         //         if (res.success == 1) {
//         //             Notification('success', 'Success!', res.message)
//         //             await localStorage.setItem('order_id', '')
//         //             this.getCartList()
//         //         } else {
//         //             Notification('error', 'Error!', '' + res.message)

//         //         }
//         //         await localStorage.setItem('payment_type', '')
//         //     }
//         // }
//         setTimeout(function () {
//             document.querySelector(".loader-wrapper").style = "display: none";
//         }, 2000);
//     }, [''])

//     const getCartList = async () => {
//         var formdata = new FormData()
//         formdata.append('device_id', this.state.uniueId)
//         props.getCartData('v1/get-cart-list', formdata, "Error => GetCartList api.")

//     }



//     return (
//         <div>
//             <section className="section-b-space light-layout">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-md-12">
//                             <div className="success-text">
//                                 <p className="small-title">THANK YOU FOR YOUR PURCHASE!</p>
//                                 <h2>Your Order Has Been Received.</h2>
//                                 <div className="content">
//                                     <ul>
//                                         <li>Your Order # is: <Link to="page/user/myorder">{_order_id}.</Link></li>
//                                         <li>You Will receive an Order confirmation email with datails of your order and a link to track its progress</li>
//                                         <li>click <Link to="">here to print</Link> a copy of your order confirmation.</li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </div>
//     )

// }
// const mapStateToProps = (state) => ({
//     isUserLogin: state.login.isUserLogin,
//     cartListArray: state.login.userCartData.records.cart_list,

// })

// export default connect(mapStateToProps, { getCartData })(order_confirmatom)
