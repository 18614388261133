import React, { Component } from 'react';
import { withTranslate } from 'react-redux-multilingual'

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';


// Custom Components
import HeaderOne from './common/headers/header-one';

import FooterOne from "./common/footers/footer-one";
import LogoBlock from "../../src/components/layouts/common/logo-block";

// ThemeSettings 
import ThemeSettings from "./common/theme-settings"
import { NaswizHelperApi } from '../NaswizHelper';

class App extends Component {

    constructor(props) {
        super(props)
        this.myRef = React.createRef()
        this.state = {
            loading_getHomeData: false,
            arr_social: [],
            scrollTop: 0,
            arr_thecompany_cms: [],
            arr_stockpoint_cms: [],
            arr_directseller_cms: [],
            arr_shipping_logo: [],
            arr_category: [],
            arr_allCategory: [],
            arr_topbar_cms: [],
            categoryDataWhenOnHover: [],
            masterCategoryList: [],
            allMasterCategoryList: [],

        }
    }
    onScroll = () => {
        const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
        const scrollTop = this.myRef.current.scrollTop
        this.setState({
            scrollTop: scrollTop
        })
    }
    componentDidMount() {
        this.naswizGetHomeDataApi();

    }

    naswizGetHomeDataApi = async () => {

        var userID = ''
        var url = userID ? `v1/get-home/${userID}/0` : 'v1/get-home/0/0'
        var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(url, 'Error => v1/get-home')

        if (res && res.success == 1) {
            this.setState({
                arr_topbar_cms: res.records.topbar_cms,
                arr_thecompany_cms: res.records.thecompany_cms,
                arr_stockpoint_cms: res.records.stockpoint_cms,
                arr_directseller_cms: res.records.directseller_cms,
                arr_social: res.records.detail,
                arr_category: res.records.browse_category,
                arr_shipping_logo: res.records.shipping_logo,

                arr_allCategory: res.records.allcategories.items,
                loading_getHomeData: false,
                masterCategoryList: res.records.test_category,
                allMasterCategoryList: res.records.allcategories.items
            })

            var CategoryDataWhenOnHover = await NaswizHelperApi.GET_WITHOUT_TOKEN('v1/get-category', "Error => CategoryDataWhenOnHover")

            if (CategoryDataWhenOnHover && CategoryDataWhenOnHover.success == 1) {
                this.setState({ categoryDataWhenOnHover: CategoryDataWhenOnHover.records })
            }

        }

    }

    render() {
        var { masterCategoryList, allMasterCategoryList, categoryDataWhenOnHover, arr_shipping_logo, arr_thecompany_cms, arr_stockpoint_cms, arr_category, arr_directseller_cms, arr_allCategory, arr_social, arr_topbar_cms } = this.state
        return (
            <div className={this.state.scrollTop > 195 ? 'header_active man-wrapper' : 'man-wrapper'} ref={this.myRef} onScroll={this.onScroll}>
                <HeaderOne
                    logoName={'logo.png'}
                    megaMenuCategoryList={arr_category ? arr_category : []}
                    megaMenuAllCatList={arr_allCategory ? arr_allCategory : []}
                    social_arr={arr_social}
                    topbar_cms={arr_topbar_cms}
                    masterCategoryList={masterCategoryList}
                    allMasterCategoryList={allMasterCategoryList}
                    categoryDataWhenOnHover={categoryDataWhenOnHover}

                />
                {this.props.children}

                {/*Blog Section end*/}
                {/* <section className="blog-section">
                    <div className="container"> 
                        <LogoBlock /> 
                    </div>
                </section> */}
                {/*Blog Section End*/}

                <FooterOne
                    key={Math.random()}
                    logoName={'logo.png'}
                    thecompany_cms={arr_thecompany_cms}
                    stockpoint_cms={arr_stockpoint_cms}
                    directseller_cms={arr_directseller_cms}
                    shipping_logo={arr_shipping_logo}
                    social_arr={arr_social}
                />
                {/* <ThemeSettings /> */}

            </div>
        );
    }
}

export default withTranslate(App);
