import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'
import Breadcrumb from "../../common/breadcrumb";
import { Helmet } from 'react-helmet';
import Seo from '../../Seo';

class Blog extends Component {
    constructor(props) {
        super(props)

    }


    render() {
        const { translate, _data, top_bar_cms, social_arr } = this.props;
        return (
            <div>
                {/* <Helmet>
                <title>Blog - Naswiz Retails Pvt Ltd</title>
                <meta name="og_title" property="og:title" content="Blog - Naswiz Retails Pvt Ltd" />
                <meta name="twitter:title" content="Blog - Naswiz Retails Pvt Ltd" />
                <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />		
                </Helmet> */}
                <Seo title="Blog" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company."  />
                <Breadcrumb title={'Blog'} />
                <section className="blog-listing-wrapper pb-0 blog">
                    <div className="container">
                        <div className="row">

                            <div className="col-md-4 mb-4">
                                <article className="post h-100">
                                    <div className="post-media">
                                        <Link to="/fashion-trends">
                                            <img src="assets/images/blog/home/post-1.jpg" alt="Post" width="225" height="280" />
                                        </Link>
                                    </div>
                                    <div className="post-body">
                                        <h2 className="post-title text-truncate mb-1">
                                            <Link to="/fashion-trends">Fashion Trends</Link>
                                        </h2>
                                        <div className="post-content">
                                            <p>It’s a wise saying... Fashion is like eating, you shouldn't stick to the same menu. .  The trendiest designs in town are now in stock at Naswiz. The faster you claim, the better you style!</p>
                                        </div>
                                    </div>
                                </article>
                            </div>

                            <div className="col-md-4 mb-4">
                                <article className="post h-100">
                                    <div className="post-media">
                                        <Link to="/fashion-forefront">
                                            <img src="assets/images/blog/home/post-2.jpg" alt="Post" width="225" height="280" />
                                        </Link>
                                    </div>
                                    <div className="post-body">
                                        <h2 className="post-title text-truncate mb-1">
                                            <Link to="/fashion-forefront">Fashion Forefront</Link>
                                        </h2>
                                        <div className="post-content">
                                            <p>The world is evolving, and so are we.. Make the most modern approach with the Naswiz Range of Male Apparels.</p>
                                        </div>
                                    </div>
                                </article>
                            </div>

                            <div className="col-md-4 mb-4">
                                <article className="post h-100">
                                    <div className="post-media">
                                        <Link to="/Herbal-Products">
                                            <img src="assets/images/blog/home/herbal-blog-thumb.jpg" alt="Post" width="225" height="280" />
                                        </Link>
                                    </div>

                                    <div className="post-body">
                                        <h2 className="post-title text-truncate mb-1">
                                            <Link to="/Herbal-Products">Herbal Products</Link>
                                        </h2>
                                        <div className="post-content">
                                            <p>Ayurveda is an ancient Indian traditional practice of medicine that is believed to be a holistic healing system.</p>
                                        </div>
                                    </div>
                                </article>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        )
    }

}

export default withTranslate(Blog);
