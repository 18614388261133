import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'
import { Helmet } from 'react-helmet';
import Breadcrumb from '../../common/breadcrumb';
import Seo from '../../Seo';

class AboutUs extends Component {
   constructor(props) {
      super(props)

   }
   componentDidMount() {
      window.scrollTo(0, 0);

 
   }
   render() {
      const { translate, _data, top_bar_cms, social_arr } = this.props;
      return (
         <div>
            {/* <Helmet>
               <title>About Us - Naswiz Retail Pvt Ltd</title>
               <meta name="keywords" content='Aboutus' />
               <meta name="description" content='Aboutus' />
            </Helmet> */}
            <Seo title="About Us" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
            <Breadcrumb title={'About Us'} />

            <div className="aboutus-inner-wrapper">

               <div className="cms-page-title mb-30">
                  <h2>Introduction about Naswiz</h2>
               </div>

               <div className="listing-content">
                  <h4>Naswiz</h4>
                  <p>One of the Best Direct Selling Companies in India! ccccc</p>
               </div>

               <div className="row mt-30 mb-30">
                  <div className="col-sm-6 col-lg-6">
                     <div className="about-img-area text-center">
                        <img src="assets/images/icon/who-we-are.png" />
                     </div>
                  </div>
                  <div className="col-sm-6 col-lg-6">
                     <div className="listing-content">
                        <h4>Who We Are</h4>
                        <p>“We are a team committed to improving health, beauty, lifestyles and agricultural sector offering an opportunity through superior products.”</p>
                        <p>Naswiz seeks to be a leader in the direct selling industry serving the beauty, health and wellness marketplace by penetrating markets and positively impacting lives with best quality products and a rewarding global business opportunity. We aim to enrich the lives of our customers and enable our direct sellers to benefit financially from the sale of our products. We seek to grow our wellness tradition by staying committed to innovation and new trends, maintaining a competitive position in the industry and creating value for our customers and direct sellers</p>
                     </div>
                  </div>
               </div>

               <div className="row mt-30 mb-30">
                  <div className="col-sm-6 order-sm-12 col-lg-6">
                     <div className="about-img-area text-center">
                        <img src="assets/images/icon/who-we-do.png" />
                     </div>
                  </div>
                  <div className="col-sm-6 order-sm-1 col-lg-6">
                     <div className="listing-content">
                        <h4>What We Do</h4>
                        <p>We strive to leave a positive impact on the world around us. Empathy, understanding, and dedication to the apparel industry drive us to cultivate true craftsmanship and to leave behind a legacy we are proud of. For us, sustainability is more than one aspect of our supply chain. It’s how we do business. </p>
                        <p>Over the past years, we have grown, expanded, and changed. Over the next years, we will become bigger, better and stronger. But, we will always remain loyal, compassionate, and a family.</p>
                     </div>
                  </div>
               </div>

               <div className="row mt-30 mb-30">
                  <div className="col-sm-6 col-lg-6">
                     <div className="about-img-area text-center">
                        <img src="assets/images/icon/our-mision.png" />
                     </div>
                  </div>
                  <div className="col-sm-6 col-lg-6">
                     <div className="listing-content">
                        <h4>Our Mission</h4>
                        <p>Our mission is to contribute to the community through the daily application of Naswiz. We do this by helping individuals achieve their goals and lead better lives through a combination of an entrepreneurial business opportunity with life-enhancing products. Naswiz’s mission is to uplift, empower, validate, and ultimately build self-esteem in the people of India and around the world through high-quality products that encourage both inner and outer beauty and spiritual enlightenment while also providing opportunities for personal growth and financial reward.</p>
                     </div>
                  </div>
               </div>

               <div className="row mt-30 mb-30">
                  <div className="col-sm-6 order-sm-12 col-lg-6">
                     <div className="about-img-area text-center">
                        <img src="assets/images/icon/our-vision.png" />
                     </div>
                  </div>
                  <div className="col-sm-6 order-sm-1 col-lg-6">
                     <div className="listing-content">
                        <h4>Our Vision</h4>
                        <p><span>Our VISION</span> is to be the global e-Commerce leader within the direct selling industry, leveraging this position so as to be a positive influencer in the development of sustainable and professional network marketing communities around the world.
                        </p>
                        <p>We are committed to developing a positive, harmonious and respectable working community and our continual investment in their development will keep our direct sellers and customers motivated, improving productivity and efficiency, thereby maximizing our brand value.</p>
                     </div>
                  </div>
               </div>

               <div className="row mb-30"> 
                  <div className="col-sm-12">
                     <div className="listing-content title-with-icon">
                        <div className="listing-title d-flex align-items-center">
                           <span className="title-icone">
                              <img src="assets/images/icon/product.svg" />
                           </span>
                           <h4>Our products and packages </h4>
                        </div>
                        <p>We believe in the importance of delivering natural, pure, and genuine products to fit every person and lifestyle. Our product lines are improving many lives and helping others fulfill their individual dreams, hopes, and missions of success. You will believe in the power of Naswiz once you try our products and take advantage of the premium lifestyle enhancing benefits.</p>
                        <p>Our aim is to provide complete range of Men’s wear and women’s wear to high quality consumer products, which includes hair care, skin care, beauty, herbal range, personal care products for daily use, agricultural products. Our products include:</p>
                        <ol className="product-listing">
                           <li>
                              <p>Male Apparels</p>
                           </li>
                           <li>
                              <p>Female Apparels</p>
                           </li>
                           <li>
                              <p>Leather Accessories</p>
                           </li>
                           <li>
                              <p>Electronics</p>
                           </li>
                           <li>
                              <p>Personal Care Products</p>
                           </li>
                           <li>
                              <p>Color Cosmetics </p>
                           </li>
                           <li>
                              <p>Family Packages</p>
                           </li>
                           <li>
                              <p>Herbal Products</p>
                           </li>
                           <li>
                              <p>Alkaline Water Ionizer</p>
                           </li>
                           <li>
                              <p>House keeping products</p>
                           </li>
                           <li>
                              <p>Groceries</p>
                           </li>
                           <li>
                              <p>Agricultural Products.</p>
                           </li>
                           <li>
                              <p>Food supplements </p>
                           </li>
                           <li>
                              <p>COVID-19 Protection</p>
                           </li>
                        </ol>
                     </div>
                  </div>
               </div>

               <div className="row mb-30">
                  <div className="col-sm-12">
                     <div className="listing-content">
                        <div className="listing-title d-flex align-items-center">
                           <span className="title-icone">
                              <img src="assets/images/icon/selling.svg" />
                           </span>
                           <h4>Benefits Of Direct Selling</h4>
                        </div>
                        <ol className="inner-ol">
                           <li>
                              <h5 className="text-capitalize">Increase in income.</h5>
                              <p>The first and foremost advantage is a high-profit margin for both parent companies and sellers. Many entrepreneurs adopted this method to earn high profits.</p>
                           </li>
                           <li>
                              <h5 className="text-capitalize">Flexible schedule.</h5>
                              <p>
                                 It’s extremely flexible. People can choose to work a few hours a week, a few hours a day or more. You can work at morning while the kids are at school, or at night while they are sleeping. You can “take the day off” when you don’t feel well without penalty.
                           </p>
                           </li>
                           <li>
                              <h5 className="text-capitalize">Be your own boss and hence,</h5>
                              <p> The primary benefit of going into the direct selling business is that it allows an individual to be his own boss. As an independent consultant, he must create a business plan and set goals based solely on his own personal desires.</p>
                           </li>
                           <li>
                              <h5 className="text-capitalize">flexibility to work from anywhere.</h5>
                              <p>You can work from your house and even your patio, anywhere in your home town or online. You can work at a Starbucks or even when on vacation!</p>
                           </li>
                           <li>
                              <h5 className="text-capitalize">Extra Income.</h5>
                              <p>There is a lot of money to be made within the direct selling industry. The amount earned, however, is up to each individual. Because an independent consultant can work as many or as little hours as she chooses, the amount of money she may potentially earn is unlimited.</p>
                           </li>
                           <li>
                              <h5 className="text-capitalize">Financial Freedom.</h5>
                              <p>In direct selling, there is no limitation to your earning capabilities. The more you put into it, the more you get out of it. Just like in any other business, you need to start with a clear goal, draw up a step-by-step plan to achieve it.</p>
                           </li>
                           <li>
                              <h5 className="text-capitalize">Increase Sales With Promotions</h5>
                              <p>Sending deals and offers that are linked to events that are important to your customers, can be an effective way to grow sales. Doing this regularly, with a controlled pricing segmentation, could even have an effect on the lifetime value from which you get from these customers.</p>
                           </li>
                           <li>
                              <h5 className="text-capitalize">Personal Development.</h5>
                              <p>Direct selling inadvertently will offer personal growth training and after a few years, many reps have said that the training they received and personal growth that took place  far outweighed the income in terms of satisfaction.</p>
                           </li>
                           <li>
                              <h5 className="text-capitalize">Meeting New People</h5>
                              <p>Working in the direct selling business requires a person to interact with a lot of people. This aspect of the occupation makes this a wonderful way to meet and socialize with new individuals of all demographics, affording an independent consultant the opportunity to make money while making new friends. </p>
                           </li>
                           <li>
                              <h5 className="text-capitalize">Feedback directly from customers</h5>
                              <p> When you meet your customers in a non-retail environment, then you have an opportunity to have a conversation with them, where they will feel comfortable to give you honest feedback about your products.</p>
                           </li>
                           <li>
                              <h5 className="text-capitalize">Customers’ satisfaction</h5>
                              <p> You can keep your customers satisfied. Most customers get annoyed when they are not given proper attention in stores, and they have to spend hours looking for a product in the store.</p>
                           </li>
                        </ol>
                     </div>
                  </div>
               </div>

               <div className="row mb-30">
                  <div className="col-sm-12">
                     <div className="listing-content">
                        <div className="listing-title d-flex align-items-center">
                           <span className="title-icone">
                              <img src="assets/images/icon/diret-selling.svg" />
                           </span>
                           <h4>Why Naswiz Direct Selling</h4>
                        </div>
                        <p>Naswiz, a leading name in Direct Selling Industry, is winning hearts of many through its excellent products and services. Our aim is to provide complete range of Men’s wear and women’s wear to high quality consumer products, which includes hair care, skin care, beauty and personal care products for daily use.</p>
                        <p>Our aim is to provide total customer satisfaction and we grow with a large network of satisfied customers and business associates.</p>
                        <p>Here are a few reasons to rely on us:</p>
                        <ol className="product-listing">
                           <li>
                              <p>very Strong, Very Stable, 19 Years Old Indian Company </p>
                           </li>
                           <li>
                              <p>pure Hybrid Binary Plan </p>
                           </li>
                           <li>
                              <p>the Most Easy Business As It Is Only 2 Leg Business </p>
                           </li>
                           <li>
                              <p>no Every Month Purchase Pressure </p>
                           </li>
                           <li>
                              <p>one Time Purchase – Lifetime Income</p>
                           </li>
                           <li>
                              <p>business Based On Recommendation  </p>
                           </li>
                           <li>
                              <p>fix Income, No Trimming </p>
                           </li>
                           <li>
                              <p>weekly Payout System</p>
                           </li>
                           <li>
                              <p>no Monthly Flush Out </p>
                           </li>
                           <li>
                              <p>one Month Refund Policy – Naswiz Has Quality Products, If Anyone Is Not Satisfied, We Give Refund In 30 Days.</p>
                           </li>
                           <li>
                              <p>consistency Offers – We Have 3 Consistency Maturity In An Year. No Other Company Has That</p>
                           </li>
                        </ol>
                     </div>
                  </div>
               </div>

            </div>

         </div>
      )
   }

}

export default withTranslate(AboutUs);
