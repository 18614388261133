import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { NaswizHelperApi } from '../../../NaswizHelper'
import moment from 'moment'
import './innerdashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCartArrowDown, faCogs, faGift, faHeart, faSignInAlt, faSignOutAlt, faTicketAlt, faTimes, faUser, faUsers, faWallet } from '@fortawesome/free-solid-svg-icons';
import { Input, DatePicker } from 'antd'
import Notification from '../../../Notification'
import ReactPaginate from 'react-paginate'
import { Helmet } from 'react-helmet'
import Seo from '../../Seo'

class InnerDashboardOrderIssueView extends React.Component {

    constructor(props) {
        super(props)
        this.handleInputChange = this.handleInputChange.bind(this);
        this.state = {
            arr_orderIssueList: [],
            activeView: '0',  //2 create ticket
            orderId: props.orderId,
            orderProductList: [],
            store_id: '',
            product_name: '',
            store_subject: '',
            store_description: '',
            store_image: '',
            _orderId: '0',
            selectedIssue: 1,
            selectedProduct: [],
            fileName: '',
            isVisibleModal: false,
            selectedIndex: '',
            is_active: '',
            loading_wallet: true,
            _noOfPage: [],
            _page: 1
        }
    }
    async componentWillReceiveProps(nextProps) {
        if (this.state.orderId != nextProps.orderId) {
            if (this.state.orderId != 0) {
                await this.setState({ orderId: nextProps.orderId })
                await this._getClickedOrderDetails(this.state.orderId)

            }
        }
    }

    componentDidMount = async () => {
        if (this.state.orderId != 0) {
            this.setState({ activeView: '2' })
            this._getClickedOrderDetails(this.state.orderId)
        }
        this._getOrderIssueList()
    }
    _getClickedOrderDetails = async (id) => {
        var res = await NaswizHelperApi.GET_WITH_TOKEN(`v1/get-order-detail/${id}`, "Error => v1/get-order-detail/ api.")
        if (res && res.success == 1) {
            this.setState({
                orderProductList: res.records.order_lines,
            })
            if (res.records.order_lines.length > 0) {
                this.setState({ product_name: res.records.order_lines[0].product_name })
            }
        }
        else {
        }
    }
    _getOrderIssueList = async () => {

        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/order-ticket-view?page=' + this.state._page, null, "Errpr => v1/order-ticket-view api.")

        if (res && res.success == 1) {
            this.setState({
                arr_orderIssueList: res.record.data,
                loading_wallet: false,
            })
            var tmp = []
            for (let i = 0; i < res.record.last_page; i++) {
                tmp.push(i)
            }

            this.setState({ _noOfPage: tmp })
        }

    }

    handlePagination = async (value2) => {
        var value = value2.selected + 1
        if (value <= this.state._noOfPage.length) {
            await this.setState({ _page: value })
            // if (this.state.isPriceFilter)
            //     this._searchUponSelectedFilter()
            // else
            this._getOrderList()
        }

    }
    onChangeIssue = (value) => {
        this.setState({ selectedIssue: value })

    }
    selectProduct = async (value) => {
        for (var i = 0; i < this.state.orderProductList.length; i++) {
            if (this.state.orderProductList[i].id == value) {
                await this.setState({ product_name: this.state.orderProductList[i].product_name })
            }
        }

    }
    _createNewStoreIssue = async () => {
        var { orderId, product_name, store_subject, store_image, store_description } = this.state

        if (orderId === '') {
            Notification('error', 'Error!', 'Please enter store name!')
        }
        else if (product_name.length < 3) {
            Notification('error', 'Error!', 'Please enter product name')
        }
        else if (store_description === '') {
            Notification('error', 'Error!', 'Please add description')
        }
        else if (store_description.length < 3) {
            Notification('error', 'Error!', 'Please enter valid description')
        }
        else {

            var body = new FormData()
            body.append('order_number', orderId)
            body.append('product_name', product_name)
            body.append('subject', store_subject)
            body.append('problem_description', store_description)
            if (this.state.fileName != '') {
                body.append('image', store_image, this.state.fileName)

            }

            var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/order-ticket', body, "Error=> Create store issue api.")

            if (res && res.success == 1) {
                Notification('success', 'Success!', res.message ? res.message : 'Success!')
                this.setState({ activeView: '0' })

                this._getOrderIssueList()
            }
            else {
                Notification('error', 'Error!', 'Try again later !')
            }
        }


    }


    async handleInputChange(e) {
        e.preventDefault();

        // let reader = new FileReader();
        let file = e.target.files[0];

        await this.setState({
            store_image: e.target.files[0],
            fileName: this.fileInput.value
                ? this.fileInput.files[0].name
                : ''
        });
    }

    render() {
        return (
            <div className="col-lg-9 order-lg-last dashboard-content">
                {/* <Helmet> 
                <title>My Tickets - Naswiz Retails Pvt Ltd</title>
                <meta name="og_title" property="og:title" content="My Tickets - Naswiz Retails Pvt Ltd" />
                <meta name="twitter:title" content="My Tickets - Naswiz Retails Pvt Ltd" />
                <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />		
                </Helmet> */}
                <Seo title="My Tickets" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                {
                    this.state.loading_wallet ?
                        <div>
                            <div className="loader-wrapper">
                                <div className="loader"></div>
                            </div>
                        </div> :
                        <div className="notification issue_inner">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <div className="notification_head ticket_list">
                                        <h3>Order Issue</h3>
                                        <div className="add_ticket">
                                            {/* <a onClick={() => this.setState({ activeView: '2' })}> */}
                                            <Link className="btn theme-button" to={{ pathname: `${process.env.PUBLIC_URL}/page/user/myorder`, itemviewname: 'My Order' }}>
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/add_ticket.png`} /> Create Ticket
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.arr_orderIssueList.length > 0 && this.state.activeView === '0' ?
                                    this.state.arr_orderIssueList.map((item, index) => {
                                        return (
                                            <>
                                                <div className="ticket_listing" key={index}>
                                                    <div className="product_issue">
                                                        <div className="ticket_id">
                                                            <p>Ticket ID :<span>
                                                                <a href="javascript:void(0)" onClick={() => this.setState({ selectedIndex: index, isVisibleModal: true })} className="id_no" data-toggle="tooltip" data-placement="top" title="" data-original-title="view details">#{item.ticket_number}</a></span></p>
                                                        </div>
                                                        <div className="pending_issue">
                                                            <span>{item.is_active == 0 ? 'Active' : 'Answered'}</span>
                                                        </div>
                                                    </div>
                                                    <div className="issue_subject">
                                                        <p>Subject :<span>  {item.subject}</span></p>
                                                    </div>
                                                    <div className="issue_date">
                                                        <p>Created On : {moment(item.created_at).format("YYYY-MM-DD")}</p>
                                                    </div>


                                                    {this.state.selectedIndex === index && this.state.isVisibleModal ?
                                                        <div className="order-detail">
                                                            <div className="inside_ticket">
                                                                <div className="orderissue-header">
                                                                    <h2>Order Issue Ticket</h2>
                                                                    <span onClick={() => this.setState({ isVisibleModal: false })}> <FontAwesomeIcon icon={faTimes} /> </span>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="orderissue-box">
                                                                            <span>Ticket Number:</span>
                                                                            <label>{item.ticket_number}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="orderissue-box">
                                                                            <span>Product Name:</span>
                                                                            <label>{item.product_name}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="orderissue-box">
                                                                            <span>Subject:</span>
                                                                            <label>{item.subject}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="orderissue-box">
                                                                            <span>Problem Description:</span>
                                                                            <label>{item.problem_description}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="orderissue-box">
                                                                            <span>Product Images:</span>
                                                                            <label><Link to="#">{item.image_full_path}</Link></label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="orderissue-box">
                                                                            <span>Status:</span>
                                                                            <label>
                                                                                {/* {this.state.is_active === '0' ? 'Active' : this.state.is_active === '1' ? 'Answered' : ''} */}
                                                                                {item.is_active == 0 ? 'Active' : 'Answered'}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        : null}
                                                </div>

                                                {this.state._noOfPage.length > 1 ?
                                                    <ReactPaginate
                                                        previousLabel={'<'}
                                                        nextLabel={'>'}
                                                        breakLabel={'...'}
                                                        breakClassName={'page-item'}
                                                        breakLinkClassName={'page-link'}
                                                        pageCount={this.state._noOfPage.length}
                                                        marginPagesDisplayed={1}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={(page) => this.handlePagination(page)}
                                                        containerClassName={'pagination toolbox-item'}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link'}
                                                        previousClassName={'page-item'}
                                                        previousLinkClassName={'page-link'}
                                                        nextClassName={'page-item'}
                                                        nextLinkClassName={'page-link'}
                                                        activeClassName={'active'}
                                                    // forcePage={this.state._page - 1}
                                                    />
                                                    : null}
                                            </>
                                        )
                                    }) : this.state.activeView === '2' ?

                                        <div className="order-issue">
                                            <div className="inside_ticket">
                                                <div className="notification_head">
                                                    <span> <FontAwesomeIcon onClick={() => this.setState({ activeView: '0' })} icon={faTimes} /> </span>
                                                </div>

                                                <div className="ticket_div personal_detail_form ticket_form">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-xs-12">
                                                            <div className="form-group">
                                                                <label>Select issue </label>
                                                                <select className="form-control" onChange={e => this.onChangeIssue(e.target.value)}>
                                                                    <option value="1">Product issue</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {this.state.orderProductList.length > 0 ?
                                                            <div className="col-sm-6 col-xs-12">
                                                                <div className="form-group">
                                                                    <label>Select Product </label>
                                                                    <select className="form-control" onChange={(e) => this.selectProduct(e.target.value)}>
                                                                        {
                                                                            this.state.orderProductList && this.state.orderProductList.length > 0 ?

                                                                                this.state.orderProductList.map((item, index) => {
                                                                                    return (
                                                                                        <option value={item.id}>{item.product_name}</option>

                                                                                    )
                                                                                })

                                                                                : null
                                                                        }

                                                                        )

                                                                    </select>
                                                                </div>
                                                            </div> :
                                                            <div className="col-sm-6 col-xs-12">
                                                                <div className="form-group">
                                                                    <label>Select Product </label>
                                                                    <select className="form-control">
                                                                        <option value="4">All</option>

                                                                    </select>
                                                                </div>
                                                            </div>}

                                                    </div>
                                                </div>
                                                <div className="row">

                                                    <div className="col-sm-6 col-xs-12">
                                                        <div className="form-group">
                                                            <label>Subject </label>
                                                            <input
                                                                className="form-control"
                                                                value={this.state.store_subject}
                                                                onChange={e => this.setState({ store_subject: e.target.value })}
                                                                placeholder='Subject'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-xs-12">
                                                        <div className="form-group">
                                                            <label>Description</label>
                                                            <input
                                                                className="form-control"
                                                                value={this.state.store_description}
                                                                onChange={e => this.setState({ store_description: e.target.value })}
                                                                placeholder='Enter description'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Upload</label>
                                                    <input type="file"
                                                        onChange={this.handleInputChange}
                                                        ref={input => this.fileInput = input}
                                                        className="form-control" />

                                                </div>

                                                <div className="both_buttons d-flex flex-wrap mt-3">
                                                    <button onClick={() => this._createNewStoreIssue()} type='button' className="btn theme-button continue_btns mb-2 mr-2">SUBMIT</button>
                                                    <button onClick={() => this.setState({ activeView: '0' })} type='button' className="btn cancel_btns mb-2">CANCEL</button>
                                                </div>

                                            </div>
                                        </div>
                                        : <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                            <div className="ticket_listing pt-20"> No Ticket Available</div>
                                        </div>

                            }

                        </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        allState: state
    }
}

export default connect(mapStateToProps, null)(InnerDashboardOrderIssueView)