import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'
import Breadcrumb from '../../common/breadcrumb';
import Seo from '../../Seo';

class ProcessOfBecoming extends Component {
   constructor(props) {
      super(props)

   }

   render() {
      const { translate, _data, top_bar_cms, social_arr } = this.props;
      return (
         <div>
            <Seo title="Process Of Becoming" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company."  image="https://www.myshopwiz.com/assets/images/cms-page/phytowiz-banner-1.png" />
            <Breadcrumb title={'Process Of Becoming'} />

            <div className="container">
               <main className="main gap_genrate">
                  <div className="container">
                     <div className="content-listing">
                        <div className="listing-head">
                           <h2>Process Of Becoming A Direct Seller</h2>
                        </div>
                        <div className="listing-content">
                           <h4>Process of Becoming a Direct Seller</h4>
                           <p>To become a Direct Seller, existing Direct Seller should introduce an individual. He should have valid ID proof, address proof and PAN card number. He should register himself with company and sign an agreement if he is willing to further sell the products. There are no entry charges/registration fees or compulsory purchase of any sales promotional material for being a direct seller. He should not be convicted of any criminal offence punishable with imprisonment in last 5 years. Along with the above-mentioned process, every Direct Seller has to abide by below given guidelines issued by Ministry of Consumer Affairs</p>
                        </div>
                        <div className="listing-content">
                           <h4>Clause 5: Certain obligations of Direct Sellers</h4>
                           <ol className="offer-listing mt-3">
                              <li>
                                 <p>Direct Seller engaged in direct selling should carry their identity card and not visit the customer’s premises without prior appointment/approval</p>
                              </li>
                              <li>
                                 <p>At the initiation of a sales representation, without request, truthfully and clearly identify themselves, the identity of the direct selling entity, the nature of the goods or services sold and the purpose of the solicitation to the prospective consumer</p>
                              </li>
                              <li>
                                 <p>Offer a prospective consumer accurate and complete explanations and demonstrations of goods and services, prices, credit terms, terms of payment, return policies, terms of guarantee, after-sales service</p>
                              </li>
                              <li>
                                 <p>Provide the following information to the prospect / consumers at the time of sale, namely</p>
                                 <ul className="inner-ul">
                                    <li>
                                       <p>Name, address, registration number or enrollment number, identity proof and telephone number of the direct seller and details of direct selling entity</p>
                                    </li>
                                    <li>
                                       <p>A description of the goods or services to be supplied</p>
                                    </li>
                                    <li>
                                       <p>Explain to the consumer about the goods return policy of the company in the details before the transaction</p>
                                    </li>
                                    <li>
                                       <p>The Order date, the total amount to be paid by the consumer along with the bill and receipt</p>
                                    </li>
                                    <li>
                                       <p>Time and place for inspection of the sample and delivery of good</p>
                                    </li>
                                    <li>
                                       <p>Information of his/her rights to cancel the order and / or to return the product in saleable condition and avail full refund on sums paid</p>
                                    </li>
                                    <li>
                                       <p>Details regarding the complaint redressal mechanism</p>
                                    </li>
                                 </ul>
                              </li>
                              <li>
                                 <p>A direct seller shall keep proper book of accounts stating the details of the products, price, tax and the quantity and such other details in respect of the goods sold by him/her, in such form as per applicable law</p>
                              </li>
                              <li>
                                 <p>A direct seller shall not</p>
                              </li>
                              <li>
                                 <p>Use misleading, deceptive and / or unfair trade practices</p>
                              </li>
                              <li>
                                 <p>Use misleading, false, deceptive, and / or unfair recruiting practices, including misrepresentation of actual or potential sales or earnings and advantages of Direct Selling to any prospective direct seller, in their interaction with prospective direct sellers</p>
                              </li>
                              <li>
                                 <p>Make any factual representation to a prospective direct seller that cannot be verified or make any promise that cannot be fulfilled</p>
                              </li>
                              <li>
                                 <p>Present any advantages of Direct Selling to any prospective direct seller in a false and / or a deceptive manner</p>
                              </li>
                              <li>
                                 <p>Knowingly make, omit, engage, or cause, or permit to be made, any representation relating to the Direct Selling operation, including remuneration system and agreement between the Direct Selling entity and the direct seller, or the goods and / or services being sold by such direct seller which is false and / or misleading</p>
                              </li>
                              <li>
                                 <p>Require or encourage direct sellers recruited by the first mentioned direct seller to purchase goods and / or services in unreasonably large amounts</p>
                              </li>
                              <li>
                                 <p>Provide any literature and / or training material not restricted to collateral issued by the Direct Selling entity, to a prospective and / or existing direct sellers both within and outside the parent Direct Selling entity, which has not been approved by the parent Direct Selling entity</p>
                              </li>
                              <li>
                                 <p>Require prospective or existing direct sellers to purchase any literature or training materials or sales demonstration equipment</p>
                              </li>
                           </ol>
                        </div>
                     </div>

                  </div>
                  <div className="mb-5"></div>
               </main>
            </div>
         </div>
      )
   }

}

export default withTranslate(ProcessOfBecoming);
