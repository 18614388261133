/**
 * Mocking client-server processing
 */
import _products from './data.json'
import { base_url, AUTH_BEARER, } from '../helper'
import { BASE_URL, HEADERS } from '../NaswizHelper'

var product_response = []

async function HomeAPI() {

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/x.naswiz.v1+json");
    myHeaders.append("Authorization", 'Bearer ' + AUTH_BEARER);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    var res = await fetch(`${BASE_URL}v1/get-home/0/0`, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => console.log('error', error));
    
    return res.records
}



async function CategoryAPI() {

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/x.naswiz.v1+json");
    myHeaders.append("Authorization", 'Bearer ' + AUTH_BEARER);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    var res = await fetch(`${BASE_URL}v1/get-category`, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => console.log('error', error));
    return res.records

}

async function getProductDetailsShop(productSlug) {

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/x.naswiz.v1+json");
    myHeaders.append("Authorization", 'Bearer ' + AUTH_BEARER);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    var res = await fetch(`${base_url}v1/` + productSlug, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => console.log('error', error));
    return res

}

(async () => {

    var res = await HomeAPI()

    product_response = res


})()



const TIMEOUT = 100


export default {
    getProducts: (cb, timeout) => setTimeout(async () => {
        var _products = await HomeAPI()
        cb(_products)
    }, timeout || TIMEOUT),
    getCategories: (cb, timeout) => setTimeout(async () => {
        var _products = await CategoryAPI()
        cb(_products)
    }, timeout || TIMEOUT),

    getSingleProduct: (productSlug, cb, timeout) => setTimeout(async () => {
        var _products = await getProductDetailsShop(productSlug)
        return _products;

    }, timeout || TIMEOUT),

    buyProducts: (payload, cb, timeout) => setTimeout(() => cb(), timeout || TIMEOUT)
}


