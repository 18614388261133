import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { getCartTotal } from "../../../services";
import { colors, NaswizHelperApi, Alerts } from '../../../NaswizHelper'
import { getCartData } from '../../../actions/index'
import { Modal, Input, DatePicker, Button, Spin, Radio } from 'antd'
import moment from "moment";
import './innerdashboard.css';
import Notification from '../../../Notification'
import { Helmet } from 'react-helmet';
import Seo from '../../Seo';

class InnerDashboardMyCart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            total_ip: '',
            format_total_priceINR: '',
            format_discount_priceINR: '',
            format_discounted_priceINR: '',
            arr_cartItemList: [],
            loading: true,
            placeOrderModal: false,
            selected_payment_mode: null
        }
    }

    componentDidMount = () => {
        this.checkUserLoginStatus()
        /* document.getElementById("common").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/common.css`);*/
        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000); 
        this._getCartDetails()
    }

    checkUserLoginStatus = () => {
        var isLoing = localStorage.getItem('is_user_login')
        this.setState({ isLoing: isLoing })
    }

    _getCartDetails = async () => {


        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/get-cart-list', null, "Error => v1/get-cart-list api.")
        if (res && res.success == 1) {
            this.setState({
                total_ip: res.records && res.records.total ? res.records.total.total_ip : '',
                format_total_priceINR: res.records && res.records.total ? res.records.total.format_total_priceINR : '',
                format_discount_priceINR: res.records && res.records.total ? res.records.total.format_discount_priceINR : '',
                format_discounted_priceINR: res.records && res.records.total ? res.records.total.format_discounted_priceINR : '',
                arr_cartItemList: res.records.cart_list,
                loading: false
            })
        }
        else {
            this.setState({
                loading: false
            })
        }
    }

    render() {      
        return (
            <div className="col-lg-9 notification no-padding">
                {/* <Helmet>
                <title>My Cart - Naswiz Retails Pvt Ltd</title>
                <meta name="og_title" property="og:title" content="My Cart - Naswiz Retails Pvt Ltd" />
                <meta name="twitter:title" content="My Cart - Naswiz Retails Pvt Ltd" />
                <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />		
                </Helmet> */}
                <Seo title="My Cart" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company."   />
                {
                    this.state.loading ?
                        <div>
                            <div className="loader-wrapper">
                                <div className="loader"></div>
                            </div>
                        </div> :
                        this.state.arr_cartItemList.length == 0 ?
                            <div style={{ height: '100px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid' }}>
                                <h3>
                                    Your Cart Is Empty
                                </h3>
                            </div> :
                            <div className="gap_genrate">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="whole_cart_left">                                                
                                                {
                                                    this.state.arr_cartItemList && this.state.arr_cartItemList.length > 0 ?
                                                        this.state.arr_cartItemList.map((item, index) => {
                                                            return (
                                                                <div className="inside_crt">

                                                                    <div className="list_iten_cart">
                                                                        <div className="media_cart">
                                                                            <img src={`${item.image}`} alt="" />
                                                                        </div>
                                                                        <div className="cart_content">
                                                                            <h3>{item.product_name}</h3>
                                                                            <p>₹{item.price}<span><s>₹{item.main_price}</s> You Save ₹ {Number(item.main_price - item.price)}</span></p>
                                                                            <p>IP Point 24</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="increase_product circle_mp">
                                                                        <div className="product-action">
                                                                            <div className="product-single-qty circle_mp">
                                                                                <input className="horizontal-quantity  form-control" type="text" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }) : null
                                                }

                                            </div>
                                        </div>
                                        <div className="col-md-5">                                            
                                            <div className="right_cart_inside">
                                                <h4>Payment Details</h4>
                                                <ul>
                                                    <li>IP Point <span>{this.state.total_ip}</span></li>
                                                    <li>MRP Total <span>₹ {this.state.format_total_priceINR}</span></li>
                                                    <li>Discount <span>- ₹ {this.state.format_discount_priceINR}</span></li>
                                                    <li>Total Amount <span>₹ {this.state.format_discounted_priceINR}</span> <span className="save_cart">You Save ₹ {this.state.format_discount_priceINR}</span></li>
                                                </ul>
                                            </div>
                                            <div className="crat_bt">
                                                <a onClick={() => this.setState({ placeOrderModal: true })}>Place Order</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                }

                <Modal
                    visible={this.state.placeOrderModal}
                    onCancel={() => this._resetPlaceOrderState()}
                    footer={null}
                    title="PLACE ORDER">
                    <div>
                        <div className="radio_btns" style={{ marginTop: '20px' }}>
                            <Radio.Group onChange={(e) => this.setState({ selected_payment_mode: e.target.value })} >
                                <Radio value={1}>ONLINE</Radio>
                                <Radio value={2}>COD</Radio>
                            </Radio.Group>
                        </div>
                        <div className="submit_frm" style={{ marginTop: '20px' }}>
                            <button onClick={() => this._placeOrder()} type='button' className="button_desing">
                                SUBMIT
                            </button>
                        </div>
                    </div>

                </Modal>
            </div>
        )
    }

    _resetPlaceOrderState = () => {
        this.setState({
            placeOrderModal: false,
            selected_payment_mode: null
        })
    }
    _placeOrder = async () => {
        var { selected_payment_mode } = this.state

        if (selected_payment_mode == null) {

        }
        else {

            var body = new FormData()
            body.append('shipping_address_id', 2)
            body.append('payment_type', this.state.selected_payment_mode)
            body.append('is_wallet', 1)

            for (var pair of body.entries()) {
            }

            var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/place-order', body, "Error => v1/place-order api.")          

            if (res && res.success == 1) {

            }
            else {

            }

        }
    }
}

function mapStateToProps(state) {
    return {
        allState: state,
        cartItems: state.cartList.cart,
        symbol: '₹',
        total: getCartTotal(state.cartList.cart),
        cartListArray: state.login.userCartData.records.cart_list,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getCartData: (trailUrl, errorMsg) => getCartData(trailUrl, errorMsg),
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(InnerDashboardMyCart)