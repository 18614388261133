import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import Slider from 'react-slick';
import { NaswizHelperApi, RUPEE_SYMBOL, } from '../NaswizHelper'
import { addToCart, addToCartUnsafe, addToWishlist, getProductDetails, manageUserIdState, changeLoginState } from '../actions'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ImageZoom from '../components/products/common/product/image-zoom'

import DetailsWithPrice from "../components/products/common/product/details-price";

class ProductDetails extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            productDetailSlug: props.location.state,
            product_detail: null,
            related_record: [],
        }
    }

    componentDidMount = () => {
        this.productDetailApiWithSlug(this.state.productDetailSlug)
        
    }

    productDetailApiWithSlug = (slug, from) => {

        var userID = this.props.userID
        var url = userID ? `v1/product-detail/${slug}/${userID}` : `v1/product-detail/${slug}/0`

        this.setState({ loading_detail: true }, async () => {

            var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(url, 'Error => products details with slug api.')

            if (res && res.success == 1) {
                this.setState({
                    product_detail: res.records,
                    related_record: res.related_product
                })

            }
            else {
                this.setState({
                    loading_detail: false
                })
            }

        })
    }

    render() {
        var { product_detail, related_record } = this.state

        var products_props = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            fade: true
        };
        var productsnav_props = {
            slidesToShow: 3,
            swipeToSlide: true,
            arrows: false,
            dots: false,
            focusOnSelect: true
        };
        return (
            <div>
                {(product_detail) ?
                    <section className="section-b-space">
                        <ToastContainer />
                        <div className="collection-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12 col-xs-12">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="filter-main-btn mb-2">
                                                        <span onClick={this.filterClick} className="filter-btn" >
                                                            <i className="fa fa-filter" aria-hidden="true"></i> filter</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 product-thumbnail">
                                                    {product_detail.product_images.map((item, index) =>

                                                        <Slider {...products_props} asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)} className="product-slick">
                                                            {/* {
                                                                        item.map((row, i) =>

                                                                            <div key={i}> */}
                                                            <ImageZoom image={item.image} />
                                                            {/* </div>
                                                                        )
                                                                    } */}
                                                        </Slider>
                                                    )}
                                                </div>
                                                <DetailsWithPrice
                                                    symbol={RUPEE_SYMBOL}
                                                    estimateDeliveryDate={"ESTIMATE DATE IS NOT AVAILABLE"}
                                                    item={product_detail.details}
                                                    // relatedRecords={related_record}
                                                    navOne={this.state.nav1}
                                                    addToCartClicked={() => this._addToCartWithApi(product_detail[0])}
                                                    BuynowClicked={addToCartUnsafe}
                                                    addToWishlistClicked={addToWishlist}
                                                    updateDataAfterAction={() => this.productDetailApiWithSlug(this.state.slugs)}
                                                />

                                            </div>
                                            {/* {product_detail[0] != 0 && product_detail[0] != null ? <DetailsTopTabs item={product_detail[0]} /> : null} */}
                                        </div>
                                        {/* <RealatedProducts items={related_record} /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    ''
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        allState: state
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addToCart: (item, qty) => addToCart(item, qty),
    addToCartUnsafe: () => addToCartUnsafe(),
    addToWishlist: () => addToWishlist(),


}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails)