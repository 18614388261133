import React, { Component } from 'react';


class thankyou extends Component {

    constructor(props) {
        super(props)

    }

    componentDidMount() {
        // console.log(this.props.location.state)
    }

    render() {
        return (
            <div>
                <h2>thank yoy</h2>
            </div>
        )
    }
}
export default thankyou