
import React, { Component } from 'react';
import Slider from 'react-slick';

class SmallImages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav2: null
        }
    }
    componentDidMount() {
        this.setState({
            nav2: this.slider2
        });
    }

    render() {
        const { item, settings } = this.props;
        var productsnav = settings;

        return (
            <div className="row" style={{ marginTop: '15px' }}>
                <div className="col-12 p-0">
                    <Slider {...productsnav} asNavFor={this.props.navOne} ref={slider => (this.slider2 = slider)} className="slider-nav">
                        {
                            item.map((vari, index) =>
                                <div key={index}>
                                    {/* <img src={`${vari ? vari : 'https://www.actbus.net/fleetwiki/images/8/84/Noimage.jpg'}`} key={index} alt="" className="img-fluid" /> */}
                                    <img src={`${vari ? vari.image : `${process.env.PUBLIC_URL}/assets/images/nia.png`}`} key={index} alt="" className="img-fluid" />
                                </div>
                            )}
                    </Slider>
                </div>
            </div>
        );
    }
}

export default SmallImages;