import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

class Breadcrumb extends Component {
    render() {
        const { title, parent } = this.props;   
        return (
            <div className="breadcrumb-section">
                <Helmet>
                    <title>{`${title} | Naswiz Retails Pvt Ltd`}</title>
                </Helmet>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6"> 
                            <h2 className="mb-0 text-uppercase text-center text-md-left">{title}</h2>
                        </div>
                        <div className="col-md-6">
                            <nav aria-label="breadcrumb" className="theme-breadcrumb ml-auto">
                                <ol className="breadcrumb p-0 m-0 justify-content-center justify-content-md-end">
                                    <li className="breadcrumb-item text-uppercase"><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
                                    {parent ?
                                        <li className="breadcrumb-item text-uppercase" aria-current="page">{parent}</li> : ''}
                                    <li className="breadcrumb-item text-uppercase active" aria-current="page">{title}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Breadcrumb;