import React, { Component } from 'react';
import Slider from 'react-slick';

const BrandsSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
            }
        },
        {
            breakpoint: 1100,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
            }
        },
    ]
}


class LogoBlock extends Component {

    render() {

        const { logosliderdata } = this.props;

        return (

            <div className="brands-slider-content mt-30 mb-30">

                <Slider className="" {...BrandsSlider}>

                    {
                        logosliderdata && logosliderdata.length > 0 ?
                            logosliderdata.map((item, index) => {
                                return (
                                    <img
                                        key={index}
                                        src={item.image_full_path ? item.image_full_path : `${process.env.PUBLIC_URL}/assets/images/nia.png`}
                                        width="140"
                                        height="60"
                                        alt="brand"
                                    />
                                )
                            })
                            : null
                    }

                </Slider>
            </div>

        )
    }
}

export default LogoBlock;