import React, { Component } from 'react';
import { connect } from 'react-redux'
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { SlideToggle } from 'react-slide-toggle';
import { getBrands, getColors, getMinMaxPrice } from '../../../services';
import { filterBrand, filterColor, filterPrice, getProductBySlug, getFilterBySlug } from '../../../actions'


class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openFilter: false,
            filterArray: [],
            activeFilter: []
        }
        this.onFilterButtonPressed = this.onFilterButtonPressed.bind(this)
    }

    componentDidMount = () => {
    }

    closeFilter = () => {
        document.querySelector(".collection-filter").style = "left: -365px";
    }

    clickBrandHendle(event, brands) {

        var index = brands.indexOf(event.target.value);
        if (event.target.checked)
            brands.push(event.target.value); // push in array checked value
        else
            brands.splice(index, 1); // removed in array unchecked value

        this.props.filterBrand(brands);
    }

    colorHandle(event, color) {
        var elems = document.querySelectorAll(".color-selector ul li");
        [].forEach.call(elems, function (el) {
            el.classList.remove("active");
        });
        event.target.classList.add('active');
        this.props.filterColor(color)
    }

    render() {
        const filteredBrands = this.props.filters.brand;
        if (Object.keys(this.props.filterlist).length !== 0 && Object.keys(this.props.filterlist).length !== undefined) {
            return (
                <div className="collection-filter-block">
                    <div className="collection-mobile-back">
                        <span className="filter-back" onClick={(e) => this.closeFilter(e)} >
                            <i className="fa fa-angle-left" aria-hidden="true"></i> back
                        </span>
                    </div>

                    {/* filter for design  */}
                    {this.props.filterlist.records.design !== 0 ? <SlideToggle>
                        {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block">
                                <h3 className="collapse-block-title" onClick={onToggle}>{this.props.filterlist.records.design.name}</h3>
                                <div className="collection-collapse-block-content" ref={setCollapsibleElement}>
                                    <div className="collection-brand-filter">
                                        {this.props.filterlist.records.design.option_values.map((brand, index) => {
                                            return (
                                                <div className="custom-control custom-checkbox collection-filter-checkbox" key={index}>
                                                    <input
                                                        style={{ marginRight: '10px' }}
                                                        type='checkbox'
                                                        defaultChecked={brand.ischecked}
                                                        onChange={(e) => this.handleChangeChk(e, this.props.filterlist.records.design.name)}
                                                        value={brand.id}
                                                        name={brand.name}
                                                    />

                                                    <label
                                                        htmlFor={brand.name}>
                                                        {brand.name}
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </SlideToggle> : null}

                    {/* filter for material */}
                    {this.props.filterlist.records.material !== 0 ? <SlideToggle>
                        {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block">
                                <h3 className="collapse-block-title" onClick={onToggle}>{this.props.filterlist.records.material.name}</h3>
                                <div className="collection-collapse-block-content" ref={setCollapsibleElement}>
                                    <div className="collection-brand-filter">
                                        {this.props.filterlist.records.material.option_values.map((brand, index) => {
                                            return (
                                                <div className="custom-control custom-checkbox collection-filter-checkbox" key={index}>
                                                    <input
                                                        style={{ marginRight: '10px' }}
                                                        type='checkbox'
                                                        defaultChecked={brand.ischecked}
                                                        onChange={(e) => this.handleChangeChk(e, this.props.filterlist.records.material.name)}
                                                        value={brand.id}
                                                        name={brand.name}
                                                    />

                                                    <label
                                                        htmlFor={brand.name}>
                                                        {brand.name}
                                                    </label>

                                                </div>)
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </SlideToggle> : null}

                    {/* filter for properties */}
                    {this.props.filterlist.records.property != 0 ? <SlideToggle>
                        {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block">
                                <h3 className="collapse-block-title" onClick={onToggle}>{this.props.filterlist.records.property.name}</h3>
                                <div className="collection-collapse-block-content" ref={setCollapsibleElement}>
                                    <div className="collection-brand-filter">
                                        {this.props.filterlist.records.property.option_values.map((brand, index) => {
                                            return (
                                                <div className="custom-control custom-checkbox collection-filter-checkbox" key={index}>
                                                    <input
                                                        style={{ marginRight: '10px' }}
                                                        type='checkbox'
                                                        defaultChecked={brand.ischecked}
                                                        onChange={(e) => this.handleChangeChk(e, this.props.filterlist.records.material.name)}
                                                        value={brand.id}
                                                        name={brand.name}
                                                    />

                                                    <label
                                                        htmlFor={brand.name}>
                                                        {brand.name}
                                                    </label>
                                                </div>)
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </SlideToggle> : null}
                    {/*color filter start here*/}
                    <SlideToggle>
                        {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block">
                                <h3 className="collapse-block-title" onClick={onToggle}>colors</h3>
                                <div className="collection-collapse-block-content" ref={setCollapsibleElement}>
                                    <div className="color-selector">
                                        <ul>
                                            {this.props.colors.map((color, index) => {
                                                return (
                                                    <li className={color} title={color} onClick={(e) => this.colorHandle(e, color)} key={index}></li>)
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                    </SlideToggle>

                    {/*price filter start here */}
                    <SlideToggle>
                        {({ onToggle, setCollapsibleElement }) => (
                            <div className="collection-collapse-block open">
                                <h3 className="collapse-block-title" onClick={onToggle}>price</h3>
                                <div className="collection-collapse-block-content block-price-content" ref={setCollapsibleElement}>
                                    <div className="collection-brand-filter">
                                        <div className="custom-control custom-checkbox collection-filter-checkbox">
                                            <InputRange
                                                maxValue={this.props.prices.max}
                                                minValue={this.props.prices.min}
                                                value={this.props.filters.value}
                                                onChange={value => this.props.filterPrice({ value })} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </SlideToggle>


                    <div style={{ paddingBottom: '30px', paddingTop: '30px' }}>
                        <button onClick={() => this.onFilterButtonPressed()}>
                            Filter
                        </button>
                    </div>



                </div>
            )
        } else {
            return (<div></div>)
        }
    }

    handleChangeChk = (e, name) => {

        var temp = this.state.filterArray
        var _temp = this.state.activeFilter
        var checkBoxValueId = Number(e.target.value)
        var checkBoxValueName = e.target.name

        var isItemFound = undefined
        var isItemNotFound = undefined
        var ifFoundThenIndex = undefined

        var obj = {
            id: checkBoxValueId,
            name: checkBoxValueName
        }

        if (temp.length > 0) {

            temp.map((item, index) => {
                if (item.id === checkBoxValueId) {
                    isItemFound = true
                    ifFoundThenIndex = index
                }
            })

            setTimeout(() => {
                if (isItemFound) {
                    var removeItemFromArray = temp.filter((x, i) => i !== ifFoundThenIndex)
                    this.setState({ filterArray: removeItemFromArray })

                }
                else {
                    temp.push(obj)
                    this.setState({ filterArray: temp })

                }
            }, 1000)
        }
        else {
            temp.push(obj)
            var status = _temp.includes(name)
            _temp.push(name)
            this.setState({ filterArray: temp, activeFilter: _temp })
        }

    }

    async onFilterButtonPressed() {

        var temp = this.state.filterArray

        var commaSepratedValues = ''

        temp.map((item, index) => {
            var a = commaSepratedValues.concat(item.name).concat(',')
            commaSepratedValues = a

        })

        this.props.getProductBySlug(this.props._slug, commaSepratedValues)


    }
}


const mapStateToProps = state => ({
    filterlist: state.filterListData.filterlist,
    brands: getBrands(state.data.products),
    colors: getColors(state.data.products),
    prices: getMinMaxPrice(state.data.products),
    filters: state.filters,
    getProductBySlug: (slug, commaSepratedValues) => getProductBySlug(slug, commaSepratedValues),
})

export default connect(
    mapStateToProps,
    { filterBrand, filterColor, filterPrice, getProductBySlug }
)(Filter);

///////////////////////////////////////////////////////////////////////////////////
///////////////////// functional component ////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

// import React, { useState } from 'react';
// import { connect } from 'react-redux'
// import InputRange from 'react-input-range';
// import 'react-input-range/lib/css/index.css';
// import { SlideToggle } from 'react-slide-toggle';
// import { getBrands, getColors, getMinMaxPrice } from '../../../services';
// import { filterBrand, filterColor, filterPrice, getProductBySlug, getFilterBySlug } from '../../../actions'


// const Filter = (props) => {

    
//         const [openFilter, setOpenFilter] = useState(false)
//         const [filterArray, setfilterArray] = useState([])
//         const [activeFilter, setActiveFilter] = useState([])
//         // this.state = {
//         //     openFilter: false,
//         //     filterArray: [],
//         //     activeFilter: []
//         // }
//         onFilterButtonPressed = onFilterButtonPressed.bind()

//     // componentDidMount = () => {
//     // }

//     const closeFilter = () => {
//         document.querySelector(".collection-filter").style = "left: -365px";
//     }

//     const clickBrandHendle = (event, brands) => {

//         var index = brands.indexOf(event.target.value);
//         if (event.target.checked)
//             brands.push(event.target.value); // push in array checked value
//         else
//             brands.splice(index, 1); // removed in array unchecked value

//         props.filterBrand(brands);
//     }

//     const colorHandle = (event, color) => {
//         var elems = document.querySelectorAll(".color-selector ul li");
//         [].forEach.call(elems, function (el) {
//             el.classList.remove("active");
//         });
//         event.target.classList.add('active');
//         this.props.filterColor(color)
//     }
//     const handleChangeChk = (e, name) => {

//         var temp = filterArray
//         var _temp = activeFilter
//         var checkBoxValueId = Number(e.target.value)
//         var checkBoxValueName = e.target.name

//         var isItemFound = undefined
//         var isItemNotFound = undefined
//         var ifFoundThenIndex = undefined

//         var obj = {
//             id: checkBoxValueId,
//             name: checkBoxValueName
//         }

//         if (temp.length > 0) {

//             temp.map((item, index) => {
//                 if (item.id === checkBoxValueId) {
//                     isItemFound = true
//                     ifFoundThenIndex = index
//                 }
//             })

//             setTimeout(() => {
//                 if (isItemFound) {
//                     var removeItemFromArray = temp.filter((x, i) => i !== ifFoundThenIndex)
//                      setfilterArray(removeItemFromArray) 

//                 }
//                 else {
//                     temp.push(obj)
//                     filterArray(temp) 

//                 }
//             }, 1000)
//         }
//         else {
//             temp.push(obj)
//             var status = _temp.includes(name)
//             _temp.push(name)
//             setfilterArray(temp) 
//             setActiveFilter( _temp)
//         }

//     }

//     const  onFilterButtonPressed =()=> {

//         var temp = filterArray

//         var commaSepratedValues = ''

//         temp.map((item, index) => {
//             var a = commaSepratedValues.concat(item.name).concat(',')
//             commaSepratedValues = a

//         })

//         props.getProductBySlug(props._slug, commaSepratedValues)


//     }

//     const filteredBrands = props.filters.brand;
//     if (Object.keys(props.filterlist).length !== 0 && Object.keys(props.filterlist).length !== undefined) {
//         return (
//             <div className="collection-filter-block">
//                 <div className="collection-mobile-back">
//                     <span className="filter-back" onClick={(e) => closeFilter(e)} >
//                         <i className="fa fa-angle-left" aria-hidden="true"></i> back
//                     </span>
//                 </div>

//                 {/* filter for design  */}
//                 {props.filterlist.records.design !== 0 ? <SlideToggle>
//                     {({ onToggle, setCollapsibleElement }) => (
//                         <div className="collection-collapse-block">
//                             <h3 className="collapse-block-title" onClick={onToggle}>{props.filterlist.records.design.name}</h3>
//                             <div className="collection-collapse-block-content" ref={setCollapsibleElement}>
//                                 <div className="collection-brand-filter">
//                                     {props.filterlist.records.design.option_values.map((brand, index) => {
//                                         return (
//                                             <div className="custom-control custom-checkbox collection-filter-checkbox" key={index}>
//                                                 <input
//                                                     style={{ marginRight: '10px' }}
//                                                     type='checkbox'
//                                                     defaultChecked={brand.ischecked}
//                                                     onChange={(e) => handleChangeChk(e, props.filterlist.records.design.name)}
//                                                     value={brand.id}
//                                                     name={brand.name}
//                                                 />

//                                                 <label
//                                                     htmlFor={brand.name}>
//                                                     {brand.name}
//                                                 </label>
//                                             </div>
//                                         )
//                                     })}
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                 </SlideToggle> : null}

//                 {/* filter for material */}
//                 {props.filterlist.records.material !== 0 ? <SlideToggle>
//                     {({ onToggle, setCollapsibleElement }) => (
//                         <div className="collection-collapse-block">
//                             <h3 className="collapse-block-title" onClick={onToggle}>{props.filterlist.records.material.name}</h3>
//                             <div className="collection-collapse-block-content" ref={setCollapsibleElement}>
//                                 <div className="collection-brand-filter">
//                                     {props.filterlist.records.material.option_values.map((brand, index) => {
//                                         return (
//                                             <div className="custom-control custom-checkbox collection-filter-checkbox" key={index}>
//                                                 <input
//                                                     style={{ marginRight: '10px' }}
//                                                     type='checkbox'
//                                                     defaultChecked={brand.ischecked}
//                                                     onChange={(e) => handleChangeChk(e, props.filterlist.records.material.name)}
//                                                     value={brand.id}
//                                                     name={brand.name}
//                                                 />

//                                                 <label
//                                                     htmlFor={brand.name}>
//                                                     {brand.name}
//                                                 </label>

//                                             </div>)
//                                     })}
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                 </SlideToggle> : null}

//                 {/* filter for properties */}
//                 {props.filterlist.records.property != 0 ? <SlideToggle>
//                     {({ onToggle, setCollapsibleElement }) => (
//                         <div className="collection-collapse-block">
//                             <h3 className="collapse-block-title" onClick={onToggle}>{props.filterlist.records.property.name}</h3>
//                             <div className="collection-collapse-block-content" ref={setCollapsibleElement}>
//                                 <div className="collection-brand-filter">
//                                     {props.filterlist.records.property.option_values.map((brand, index) => {
//                                         return (
//                                             <div className="custom-control custom-checkbox collection-filter-checkbox" key={index}>
//                                                 <input
//                                                     style={{ marginRight: '10px' }}
//                                                     type='checkbox'
//                                                     defaultChecked={brand.ischecked}
//                                                     onChange={(e) => handleChangeChk(e, props.filterlist.records.material.name)}
//                                                     value={brand.id}
//                                                     name={brand.name}
//                                                 />

//                                                 <label
//                                                     htmlFor={brand.name}>
//                                                     {brand.name}
//                                                 </label>
//                                             </div>)
//                                     })}
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                 </SlideToggle> : null}
//                 {/*color filter start here*/}
//                 <SlideToggle>
//                     {({ onToggle, setCollapsibleElement }) => (
//                         <div className="collection-collapse-block">
//                             <h3 className="collapse-block-title" onClick={onToggle}>colors</h3>
//                             <div className="collection-collapse-block-content" ref={setCollapsibleElement}>
//                                 <div className="color-selector">
//                                     <ul>
//                                         {props.colors.map((color, index) => {
//                                             return (
//                                                 <li className={color} title={color} onClick={(e) => colorHandle(e, color)} key={index}></li>)
//                                         })}
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                 </SlideToggle>

//                 {/*price filter start here */}
//                 <SlideToggle>
//                     {({ onToggle, setCollapsibleElement }) => (
//                         <div className="collection-collapse-block open">
//                             <h3 className="collapse-block-title" onClick={onToggle}>price</h3>
//                             <div className="collection-collapse-block-content block-price-content" ref={setCollapsibleElement}>
//                                 <div className="collection-brand-filter">
//                                     <div className="custom-control custom-checkbox collection-filter-checkbox">
//                                         <InputRange
//                                             maxValue={props.prices.max}
//                                             minValue={props.prices.min}
//                                             value={props.filters.value}
//                                             onChange={value => props.filterPrice({ value })} />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                 </SlideToggle>


//                 <div style={{ paddingBottom: '30px', paddingTop: '30px' }}>
//                     <button onClick={() => onFilterButtonPressed()}>
//                         Filter
//                     </button>
//                 </div>



//             </div>
//         )
//     } else {
//         return (<div></div>)
//     }


// }


// const mapStateToProps = state => ({
//     filterlist: state.filterListData.filterlist,
//     brands: getBrands(state.data.products),
//     colors: getColors(state.data.products),
//     prices: getMinMaxPrice(state.data.products),
//     filters: state.filters,
//     getProductBySlug: (slug, commaSepratedValues) => getProductBySlug(slug, commaSepratedValues),
// })

// export default connect(
//     mapStateToProps,
//     { filterBrand, filterColor, filterPrice, getProductBySlug }
// )(Filter);