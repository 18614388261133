import React, { Component } from 'react';
import { connect } from 'react-redux'
import { getCartData } from '../../actions'
import { Helmet } from 'react-helmet'
import Seo from '../Seo';

class graivence_success extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        const { gra_no, } = this.props.location.state;

        return (
            <>
                <Seo title="graivence-success" description="Naswiz Retails Private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company."/>
                <div className="order-confirm-page">
                    <section className="section-b-space light-layout">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="success-text">
                                        <i className="fa fa-check-circle" aria-hidden="true"></i>
                                        <p className="small-title">THANK YOU</p>
                                        <h2>Your Grievance added successully</h2>
                                        <p style={{ marginTop: 5 }} >Grievance No: {gra_no}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    isUserLogin: state.login.isUserLogin,
    cartListArray: state.login.userCartData.records.cart_list,

})
export default connect(mapStateToProps, { getCartData })(graivence_success)

