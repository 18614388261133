//Naswiz

export const GET_HOME_DATA = "GET_HOME_DATA"
export const CHANGE_LOGIN_STATE = "CHANGE_LOGIN_STATE"
// User data
export const ADD_USER_DATA = "ADD_USER_DATA"
export const REMOVE_USER_DATA = "REMOVE_USER_DATA"
// Cart
export const ADD_ITEM_INTO_CART = "ADD_ITEM_INTO_CART"
export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART"
// WishList
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST'
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST'
export const GET_WISHLIST_DATA_FROM_API = 'GET_WISHLIST_DATA_FROM_API'
//User ID
export const MANAGE_USER_ID_STATE = "MANAGE_USER_ID_STATE"

//Cart
export const GET_CART_DATA_FROM_API = 'GET_CART_DATA_FROM_API'

// Set Master Categoty List Data
export const SET_MASTER_CATEGORY_LIST_DATA = "SET_MASTER_CATEGORY_LIST_DATA"
// Set All Master Categoty List Data
export const SET_ALL_MASTER_CATEGORY_LIST_DATA = "SET_ALL_MASTER_CATEGORY_LIST_DATA"

// Categoyr Date On Hover
export const CATEGORY_DATA_ON_HOVER = "CATEGORY_DATA_ON_HOVER"

// Get Products
export const FETCH_PRODUCTS_BEGIN = 'FETCH_PRODUCTS_BEGIN'
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'
export const RECEIVE_SLIDER = 'RECEIVE_SLIDER'
export const RECEIVE_TESTIMONIALS = 'RECEIVE_TESTIMONIALS'
export const FETCH_SINGLE_PRODUCT = 'FETCH_SINGLE_PRODUCT'

// categories
export const FETCH_PRODUCTS_CATEGORIES = 'FETCH_PRODUCTS_CATEGORIES'
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES'

// details
export const RECEIVE_SINGLE_DETAILS = 'RECEIVE_SINGLE_DETAILS'
export const RECEIVE_PRODUCTBY_SLUG = 'RECEIVE_PRODUCTBY_SLUG'
export const RECEIVE_FILTERLIST_SLUG = 'RECEIVE_FILTERLIST_SLUG'

// auth
export const RECEIVE_REGISTER = 'RECEIVE_REGISTER'
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN'


// FILTERS
export const FILTER_BRAND = 'FILTER_BRAND'
export const FILTER_COLOR = 'FILTER_COLOR'
export const FILTER_PRICE = 'FILTER_PRICE'
export const SORT_BY = 'SORT_BY'

export const CHANGE_CURRENCY = 'CHANGE_CURRENCY'


// Cart
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const INCREMENT_QTY = 'INCREMENT_QTY'
export const DECREMENT_QTY = 'DECREMENT_QTY'






// Compare
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE'
export const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE'



// CheckOut Process
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST'
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS'
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE'