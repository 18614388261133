import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAllCategories, getAllSliders, getAllProducts, getAllTestimonials, getAllHomeData, getWishlistData } from "../../actions/index";
import { bindActionCreators } from 'redux';

import ProductListing from "../../components/collection/common/product-listing"

import Breadcrumb from '../common/breadcrumb';
import { addToCartAndRemoveWishlist, removeFromWishlist } from '../../actions'

class wishList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layoutColumns: 4,
            searchResultArray: props.location.state,
            // 
            loading_getAllFilterData: false
        }
    }

    async componentDidMount() {
        var uniueId = await localStorage.getItem('deviceId')

        await this.props.getWishlistData('v1/wishlist-details/' + uniueId, "Error => Wishlist data");

        // document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color1.css`);
       // document.getElementById("common").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/common.css`);

        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
    }

    changeQty = (e) => {
        this.setState({ quantity: parseInt(e.target.value) })
    }

    render() {

        return (

            <div>

                {
                    this.props.wishlistArray.length > 0 ?
                        <div className="gap_genrate">
                            <div className="collection-wrapper">
                                <div className="container">
                                    <div className="row">

                                        <div className="collection-content">
                                            <div className="page-main-content">
                                                <div className="">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            {/*Products Listing Component*/}
                                                            <ProductListing
                                                                colSize={this.state.layoutColumns}
                                                                records={this.props.wishlistArray}
                                                                _from={'from_wishlist'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        :
                        <div className="gap_genrate">
                            <div className="collection-wrapper">
                                <div className="container">
                                    <div className="row">

                                        <div className="collection-content">
                                            <div className="page-main-content ">
                                                <div className="">
                                                    <div className="row">
                                                        <div className="col-sm-12">

                                                            {/*Products Listing Component*/}
                                                            <ProductListing colSize={this.state.layoutColumns} records={this.state.searchResultArray} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                }
            </div>
        )
    }
}



const mapStateToProps = (state) => ({
    wishlistArray: state.login.userWishlistData.data
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getAllProducts: () => getAllProducts(),
    getWishlistData: (trailUrl, errorMsg) => getWishlistData(trailUrl, errorMsg)
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(wishList);