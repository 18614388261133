import { ADD_ITEM_INTO_CART, REMOVE_ITEM_FROM_CART } from "../constants/ActionTypes";


const initialState = {
    cartData: [],
};

const userCartReducer = (state = initialState, action) => {

    switch (action.type) {

        case ADD_ITEM_INTO_CART: {

            var temp = state.cartData
            var isAlreadyInCartData = temp.some((item, index) => item.product_id == action.cartItem.product_id)
     

            if (isAlreadyInCartData) {
                return state;
            }

            var tempCart = state.cartData
            tempCart.push(action.cartItem)
            return {
                ...state,
                cartData: tempCart
            }
        }

        case REMOVE_ITEM_FROM_CART: {
            return state
        }

        default: {
            return state;
        }
    }
};
export default userCartReducer;