import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { addToCart, addToWishlist, addToCompare, removeFromWishlist, addItemIntoCart, removeItemFromCart, changeLoginState, manageUserIdState, getWishlistData, getCartData } from '../../../actions'
import { getVisibleproducts } from '../../../services';
import ProductListItem from "./product-list-item";
import { NaswizHelperApi, colors, MAIN_URL } from '../../../NaswizHelper';
// Login modal
import Modal from 'react-responsive-modal';
import PhoneInput from 'react-phone-number-input'
import './collection.css'
import Notification from '../../../Notification'

class ProductListing extends Component {

    constructor(props) {
        super(props)

        this.state = {
            limit: 5,
            hasMoreItems: false,
            loginModalVisibility: false,
            registerModalVisibility: false,
            otpModalVisibility: false,
            phoneNumber: '',
            otp1: '',
            otp2: '',
            otp3: '',
            otp4: '',
            nextButtonVisibility: false,
            opt_one_focused: false,
            opt_two_focused: false,
            opt_three_focused: false,
            opt_four_focused: false,
            selectedColorId: null,
            selectedColorIndex: null,
            selectedSizeId: null,
            selectedSizeIndex: null,
            product_detail: [],
            quick_qty: 0,
            _mainSlug: this.props.mainSlug,
            _breadcrum: this.props.breadcrum,
            uniueId: '',
            _goToDashboard: false
        };

    }

    componentWillMount() {

    }

    async componentDidMount() {

        // this.fetchMoreItems();
        const { products, addToCart, symbol, addToWishlist, addToCompare, records } = this.props;
        var uniueId = localStorage.getItem('deviceId')
        this.setState({ uniueId: uniueId })
        var productId = this.props.mainSlug
        if (!this.props.isUserLogin && productId == "offer") {
            this.setState({ _goToDashboard: true })
        }
        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
        
    }

    componentWillReceiveProps(nextProps) {
        this.fetchMoreItems(nextProps.records);
        this.setState({ _mainSlug: nextProps.mainSlug, _breadcrum: nextProps.breadcrum })
        var productId = nextProps.mainSlug
        if (!this.props.isUserLogin && productId == "offer") {
            this.setState({ _goToDashboard: true })
        }
    }

    fetchMoreItems = async (records) => {

        if (records !== undefined) {

            if (this.state.limit >= records.length) {
                this.setState({ hasMoreItems: false });
                return;
            }
            // a fake async api call
            setTimeout(() => {
                this.setState({
                    limit: this.state.limit + 5
                });
            }, 1000);
        }
    }

    quickViewQuantityChange = async (qty, item) => {
        var selectedColorId = this.state.selectedColorId;
        var selectedSizeId = this.state.selectedSizeId;
        if (selectedColorId === undefined || selectedColorId === null) {
            Notification('error', 'Error!', 'Please select color first!')
        }
        else if (selectedSizeId === undefined || selectedSizeId === null) {
            Notification('error', 'Error!', 'Please select size first!')
        }
        if (qty === '' || qty === '0') {
            Notification('error', 'Error!', 'Please enter quantity!')
        }
        else {
            if (item.details.length > 0) {
                item.details.map((i, index) => {
                    if (i.color_id === selectedColorId && i.size_id === selectedSizeId) {
                        if (i.min_order_qty > qty) {
                            Notification('error', 'Error!', 'Qyantity should be greater than or euale to ' + i.min_order_qty)
                        }
                        else if (i.max_order_qty < qty) {
                            Notification('error', 'Error!', 'Qyantity should be less than or euale to ' + i.max_order_qty)
                        }
                        else {
                            setTimeout(() => {
                                this.setState({
                                    quick_qty: qty
                                });
                            }, 1000);
                        }
                    }
                })
            }
        }
    }

    _removeFromWishListApi = async (item, page) => {

        if (!this.props.isUserLogin) {
            this.setState({ loginModalVisibility: true })
        }
        else {
            if (this.props._from === "from_wishlist") {
                var res = await NaswizHelperApi.GET_WITH_TOKEN('v1/removewishlist/'.concat(item.id), "Error => remove item from user wishlist")
                if (res && res.success === 1) {
                    setTimeout(() => {
                        this.props.updateDataAfterAction()
                        this.props.getWishlistData('v1/wishlist-details/' + this.state.uniueId, "Error => Get all wishlist data")
                    }, 1000)
                } else {
                    setTimeout(() => {
                        this.props.updateDataAfterAction()
                        this.props.getWishlistData('v1/wishlist-details/' + this.state.uniueId, "Error => Get all wishlist data")
                    }, 1000)

                }
            }
            else {
                var res = await NaswizHelperApi.GET_WITH_TOKEN(`v1/removewishlist/${item.wishlist_id}`, "Error => remove item from user wishlist")
                if (res && res.success === 1) {
                    Notification('success', 'Success!', 'Item removed from Favourites!')
                    setTimeout(() => {
                        this.props.updateDataAfterAction()
                        this.props.getWishlistData('v1/wishlist-details/' + this.state.uniueId, "Error => Get all wishlist data")
                    }, 1000)
                }
            }


        }

    }

    _addToWishlistWithApi = async (item) => {

        if (!this.props.isUserLogin) {
            this.setState({ loginModalVisibility: true })
            Notification('error', 'Error!', 'Please Login First!')
        }
        else {

            var res = await NaswizHelperApi.GET_WITH_TOKEN('v1/wishlist/'.concat(item.product_id), "Error => Add to wishlist api")

            if (res && res.success === 1) {
                if (res.message === 'Product is already in Wishlist.') {
                    Notification('error', 'Error!', res.message)
                }
                else {
                    Notification('success', 'Success!', 'Product added to Favourites')
                    setTimeout(() => {
                        this.props.updateDataAfterAction()
                        this.props.getWishlistData('v1/wishlist-details/' + this.state.uniueId, "Error => Get all wishlist data")
                    }, 500)
                }
            }

        }
    }

    quickViewAddToCart = async (product_detail) => {
        var selectedColorId = this.state.selectedColorId;
        var selectedSizeId = this.state.selectedSizeId;
        var qty = this.state.quick_qty;

        if (!this.props.isUserLogin) {
            Notification('error', 'Error!', 'Please Login First!')
        }
        else {

            if (selectedColorId === undefined || selectedColorId === null) {
                Notification('error', 'Error!', 'Please select color first!')
            }
            else if (selectedSizeId === undefined || selectedSizeId === null) {
                Notification('error', 'Error!', 'Please select size first!')
            }
            else {

                var body = new FormData()
                body.append('product_id', product_detail.product_id)
                body.append('color_id', selectedColorId)
                body.append('size_id', selectedSizeId)


                if (product_detail.details.length > 0) {

                    product_detail.details.map((i, index) => {
                        if (i.color_id === selectedColorId && i.size_id === selectedSizeId) {
                            body.append('main_price', i.price)
                            body.append('price', i.wholesale_price)
                        }
                        else {
                            body.append('main_price', product_detail.price)
                            body.append('price', product_detail.wholesale_price)
                        }
                    })
                }
                else {
                    body.append('main_price', product_detail.price)
                    body.append('price', product_detail.wholesale_price)
                }

                if (product_detail.product_images.length > 0) {

                    var temp = product_detail.product_images[0].image.includes(`${MAIN_URL}master-naswiz//`) ? `${MAIN_URL}master-naswiz//` : product_detail.product_images[0].image.includes(`${MAIN_URL}master-naswiz/`) ? `${MAIN_URL}master-naswiz/` : null
                    body.append('image', product_detail.product_images[0].image.replace(temp, ''))
                }
                else {
                    body.append('image', `storage/uploads/product/Medium${process.env.PUBLIC_URL}/assets/images/nia.png`)
                }

                if (qty === 0) {
                    Notification('error', 'Error!', 'Please enter quantity!')
                }
                else {
                    body.append('quantity', qty)
                    var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/add-usercart', body, "Error => v1/add-usercart")
                    if (res && res.success === 1) {
                        Notification('success', 'Success!', res.message ? res.message : 'Product Add to Cart Successfully.')
                        var formdata = new FormData()
                        formdata.append('device_id', this.state.uniueId)
                        this.props.getCartData('v1/get-cart-list', formdata, "Error => GetCartList api.")
                        this.setState({
                            selectedColorId: null,
                            selectedColorIndex: null,
                            selectedSizeId: null,
                            selectedSizeIndex: null,
                            quick_qty: 0
                        })
                    }
                    else {
                        Notification('error', 'Error!', res.status ? res.status : 'Try again later.')
                    }
                }

            }
        }
    }

    render() {
        if (this.state._goToDashboard) {
            return (
                <Redirect to={{
                    pathname: `/`,
                }} />
            )
        }
        const { products, addToCart, symbol, addToWishlist, addToCompare, records, page } = this.props;
        return (
            <div>
                <ToastContainer />
                <div className="product-wrapper-grid">
                    <div className="">

                        {
                            records && records.length > 0 ?
                                <InfiniteScroll
                                    dataLength={this.state.limit} //This is important field to render the next data
                                    next={this.fetchMoreItems}
                                    hasMore={this.state.hasMoreItems}
                                    loader={<div className="loading-cls"></div>}
                                    endMessage={
                                        <p className="seen-cls  seen-it-cls">
                                        </p>
                                    }>

                                    <div className="row">
                                        {records.slice(0).map((records, index) =>
                                            <div className={'col-6 col-md-4 col-sm-4 col-xl-3 mb-0'} key={index}>
                                                <ProductListItem
                                                    _from={this.props._from}
                                                    product={records}
                                                    symbol={symbol}
                                                    onAddToCompareClicked={() => addToCompare(records)}
                                                    onAddToCartClicked={() => this._addToCartWithApi(records)}
                                                    onRemoveFromCartClicked={() => this._onRemoveFromCartClicked()}
                                                    onAddToWishlistClicked={() => this._addToWishlistWithApi(records)}
                                                    onRemoveFromWishlistClicked={() => this._removeFromWishListApi(records, page)}
                                                    page={page}
                                                    quickViewAddToCart={() => this.quickViewAddToCart(records)}
                                                    quickViewQuantityChange={() => this.quickViewQuantityChange()}
                                                    key={index}
                                                    mainSlug={this.state._mainSlug}
                                                    breadcrum={this.state._breadcrum}
                                                    onClickCart={this.props.onClickCart}
                                                    updateDataAfterAction={this.props.updateDataAfterAction}
                                                />
                                            </div>)
                                        }
                                    </div>
                                </InfiniteScroll>
                                :
                                <div className="row">
                                    {this.props._from === "from_wishlist" ? <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                        <img style={{ margin: '0 auto' }} src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" />
                                        <h3>You haven't selected your favourite products. Click on the bottom right corner of the product to mark as your favourite product.</h3>

                                        <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid mt-3">continue shopping</Link>
                                    </div> 
                                    : 
                                    <div className="col-sm-12 text-center section-b-space mt-5 no-found" ><h3>There are no products in this category.</h3></div>}
                                </div>
                        }
                    </div>
                </div>

                {/* Login modal */}
                <Modal
                    open={this.state.loginModalVisibility}
                    onClose={() => this.setState({ loginModalVisibility: false, phoneNumber: '' })}
                    center id="login_modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="SignupModalIn">
                                <div className="SignupModal modal-body clearfix">
                                    <div className="back_area">
                                        <a>Back</a>
                                    </div>
                                    <div className="modal-title">
                                        <h4>Please login to continue</h4>
                                    </div>

                                    <div className="number_box text-center">
                                        <p>Enter your phone number to Login</p>
                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            defaultCountry="IN"
                                            value={this.state.phoneNumber}
                                            onChange={phoneNumber => this.setState({ phoneNumber })}
                                            inputMode="numeric"

                                        />
                                        {/* </div> */}
                                        <button
                                            className="btn next_btn_ready"
                                            id="show_verifications"
                                            type="button"
                                            onClick={() => this.setLogin()}>
                                            Next
                                        </button>
                                        <p>
                                            {/* New account */}
                                            <Link
                                                to="#"
                                                data-toggle="modal"
                                                data-target="#register_modal"
                                                onClick={() => this.setState({ loginModalVisibility: false, registerModalVisibility: true })}
                                                style={{ color: '#000' }}>
                                                <div className="mt-3 text-center">
                                                    <h6 style={{ marginTop: '1px' }}>Don't have an account ?<br></br></h6>
                                                    <h5 style={{ fontWeight: 'bold', color: colors.themeColor }}>Register</h5>
                                                </div>
                                            </Link>
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* Register modal */}
                <Modal
                    open={this.state.registerModalVisibility}
                    onClose={() => this.setState({ registerModalVisibility: false, phoneNumber: '' })}
                    center
                    id="register_modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="SignupModalIn">
                                <div className="SignupModal modal-body clearfix">
                                    <div className="back_area">
                                        <a href="javascript:void(0)">Back</a>
                                    </div>
                                    <div className="modal-title">
                                        <h4>Register</h4>
                                    </div>

                                    <div className="number_box">
                                        <p>Enter your phone number to Sign up</p>

                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            defaultCountry="IN"
                                            value={this.state.phoneNumber}
                                            onChange={phoneNumber => this.setState({ phoneNumber })}
                                            max="10"

                                        />
                                        {/* </div> */}
                                        <button className="btn next_btn_ready" id="show_verifications" type="button" onClick={() => this.setRegister()}>Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* OTP modal */}
                <Modal open={this.state.otpModalVisibility} onClose={() => this.setState({ otpModalVisibility: false, phoneNumber: '', otp1: '', otp2: '', otp3: '', otp4: '' })} center id="verify_modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="SignupModalIn">
                                <div className="SignupModal modal-body clearfix">

                                    <div className="modal-title">
                                        <h4>Phone Number Verification</h4>
                                    </div>
                                    <div className="otp_verification">
                                        <p>Enter 4 digit code sent to your phone   <br></br> {this.state.phoneNumber}</p>
                                        <div className="otp-box">
                                            <input
                                                style={{ color: this.state.opt_one_focused ? colors.themeColor : 'black' }}
                                                onFocus={() => this.setState({ opt_one_focused: true })}
                                                onBlur={() => this.setState({ opt_one_focused: false })}
                                                ref={inputOne => this.InputOne = inputOne}
                                                type="text"
                                                className="form-control"
                                                maxLength={1}
                                                onChange={(e) =>
                                                    this.setState({ otp1: e.target.value }, () => this._onChangeOtpOne())
                                                }
                                            />
                                            <input
                                                style={{ color: this.state.opt_two_focused ? colors.themeColor : 'black' }}
                                                onFocus={() => this.setState({ opt_two_focused: true })}
                                                onBlur={() => this.setState({ opt_two_focused: false })}
                                                ref={inputTwo => this.InputTwo = inputTwo}
                                                type="text"
                                                className="form-control"
                                                maxLength={1}
                                                onChange={(e) =>
                                                    this.setState({ otp2: e.target.value }, () => this._onChangeOtpTwo())
                                                }
                                            />
                                            <input
                                                style={{ color: this.state.opt_three_focused ? colors.themeColor : 'black' }}
                                                onFocus={() => this.setState({ opt_three_focused: true })}
                                                onBlur={() => this.setState({ opt_three_focused: false })}
                                                ref={inputThree => this.InputThree = inputThree}
                                                type="text"
                                                className="form-control"
                                                maxLength={1}
                                                onChange={(e) =>
                                                    this.setState({ otp3: e.target.value }, () => this._onChangeOtpThree())
                                                }
                                            />
                                            <input
                                                style={{ color: this.state.opt_four_focused ? colors.themeColor : 'black' }}
                                                onFocus={() => this.setState({ opt_four_focused: true })}
                                                onBlur={() => this.setState({ opt_four_focused: false })}
                                                ref={inputFour => this.InputFour = inputFour}
                                                type="text"
                                                className="form-control"
                                                maxLength={1}
                                                onChange={(e) =>
                                                    this.setState({ otp4: e.target.value }, () => this.setState({ nextButtonVisibility: true }))
                                                }
                                            />
                                        </div>
                                        <button
                                            className="next_btn_ready mt-2"
                                            // className="next_btn mt-2"
                                            type="button"
                                            onClick={() => this.verifyOtp()}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <h6 style={{ color: colors.themeColor }}> <a onClick={() => this._resendOTP()}>Resend OTP</a></h6>
                    </div>
                </Modal>

            </div >
        )
    }


    _onRemoveFromCartClicked = async (item) => {
        setTimeout(() => {
            this.props.updateDataAfterAction()
            var body = new FormData()
            body.append('device_id', this.state.uniueId)
            this.props.getCartData('v1/get-cart-list', body, 'Error => While getting cart data api.')
        }, 1000)
    }

    _addToCartWithApi = async (item) => {
        if (!this.props.isUserLogin) {
            this.setState({ loginModalVisibility: true })
        }
        else {

            var body = new FormData()
            body.append('product_id', item.product_id)
            body.append('color_id', item.details[0].color_id)
            body.append('size_id', item.details[0].size_id)
            body.append('main_price', item.details[0].wholesale_price)
            body.append('price', item.details[0].price)
            body.append('quantity', 1)

            if (item.product_images.length > 0) {
                var temp = item.product_images[0].image.includes('http://cranup.in/naswiz//') ? 'http://cranup.in/naswiz//' : item.product_images[0].image.includes('http://cranup.in/naswiz/') ? 'http://cranup.in/naswiz/' : null
                body.append('image', item.product_images[0].image.replace(temp, ''))
            }
            else {
                body.append('image', `storage/uploads/product/Medium${process.env.PUBLIC_URL}/assets/images/nia.png`) //passing static string 
            }

            var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/add-usercart', body, "Error => Add item into user cart")

            if (res && res.success === 1) {
                setTimeout(() => {
                    this.props.updateDataAfterAction()
                    var body = new FormData()
                    body.append('device_id', this.state.uniueId)
                    this.props.getCartData('v1/get-cart-list', body, 'Error => While getting cart data api.')
                }, 1000)

            }
            else {
                alert(res && res.message ? res.message : 'Try again later')
            }

        }
    }


    _onChangeOtpOne = () => {
        if (this.state.otp1.length === 1) {
            this.InputTwo.focus()
        }
    }

    _onChangeOtpTwo = () => {
        if (this.state.otp2.length === 1) {
            this.InputThree.focus()
        }
    }

    _onChangeOtpThree = () => {
        if (this.state.otp3.length === 1) {
            this.InputFour.focus()
        }
    }

    async setLogin() {

        var { phoneNumber } = this.state
        if (phoneNumber === '') {
            Notification('error', 'Error!', 'Please enter mobile number !')
        }
        else if (phoneNumber.length !== 13) {
            Notification('error', 'Error!', 'Mobile number is not valid !')
        }
        else {

            var formdata = new FormData();
            formdata.append("number", this.state.phoneNumber);

            var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/login', formdata, 'Error => Login api')

            if (res && res.success === 1) {
                this.setState({ loginModalVisibility: false, otpModalVisibility: true })

            }

        }
    }

    async setRegister() {

        var { phoneNumber } = this.state

        if (phoneNumber === '') {
            Notification('error', 'Error!', 'Please enter mobile number !')
        }
        else if (phoneNumber.length !== 13) {
            Notification('error', 'Error!', 'Mobile number is not valid !')
        }
        else {

            var formdata = new FormData();
            formdata.append("number", this.state.phoneNumber);

            for (var i of formdata.entries()) {
            }

            var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/register', formdata, "Error => Register api.")

            if (res && res.success === 1) {
                this.setState({
                    registerModalVisibility: false,
                    otpModalVisibility: true
                })
            }
            else {
                this.setState({ phoneNumber: '' })
                res && res.message ?
                    Notification('error', 'Error!', res.message)
                    :
                    Notification('error', 'Error!', 'Mobile number is not valid !')
            }
        }
    }

    verifyOtp = async () => {

        var { otp1, otp2, otp3, otp4, phoneNumber } = this.state

        var finalOTP = otp1.concat(otp2.concat(otp3.concat(otp4)))

        var formdata = new FormData();
        formdata.append("number", phoneNumber);
        formdata.append("otp", finalOTP);

        var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/verify-otp', formdata, "Error => Verify OTP with login api")

        if (res && res.success === 1) {
            this.setState({ otpModalVisibility: false, phoneNumber: '', otp1: '', otp2: '', otp3: '', otp4: '' })

            this.props.changeLoginState(true)
            this.props.manageUserIdState(res.id)
            localStorage.setItem('token', res.token)
            this.props.updateDataAfterAction()
            Notification('success', 'Success!', 'Verify Successfully!')
            setTimeout(() => { this.props.getWishlistData('v1/wishlist-details/' + this.state.uniueId, "Error in wishlist") }, 1000)
        }
        else {
            // alert(res && res.message ? res.message : 'Try again later')
            res && res.message ?
                Notification('error', 'Error!', res.message)
                :
                Notification('error', 'Error!', 'Mobile number is not valid !')
        }

    }

    _resendOTP = async () => {

        var { phoneNumber } = this.state
        var formdata = new FormData();
        formdata.append("number", phoneNumber);

        var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/login', formdata, 'Error => Resend otp api')

        if (res && res.success === 1) {
            Notification('error', 'Error!', res.message)
        }
        else {
            res && res.message ?
                Notification('error', 'Error!', res.message)
                :
                Notification('error', 'Error!', 'Mobile number is not valid !')
        }
    }
}

const mapStateToProps = (state) => ({
    products: getVisibleproducts(state.data, state.filters),
    symbol: '₹ ',
    isUserLogin: state.login.isUserLogin,
    userCart: state.cart.cartData,
    userID: state.login.userID,
    cartListArray: state.login.userCartData.records.cart_list
})



export default connect(mapStateToProps, { addToCart, addToWishlist, addToCompare, removeFromWishlist, addItemIntoCart, removeItemFromCart, changeLoginState, manageUserIdState, getWishlistData, getCartData })(ProductListing)

/////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////// functional component //////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////

// import React, { useState, useEffect, useRef } from 'react';
// import { connect } from 'react-redux'
// import { Link, Redirect } from 'react-router-dom';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.min.css';
// import { addToCart, addToWishlist, addToCompare, removeFromWishlist, addItemIntoCart, removeItemFromCart, changeLoginState, manageUserIdState, getWishlistData, getCartData } from '../../../actions'
// import { getVisibleproducts } from '../../../services';
// import ProductListItem from "./product-list-item";
// import { NaswizHelperApi, colors } from '../../../NaswizHelper';
// // Login modal
// import Modal from 'react-responsive-modal';
// import PhoneInput from 'react-phone-number-input'
// import './collection.css'
// import Notification from '../../../Notification'

// const ProductListing = (props) => {

//     const [limit, setLimit] = useState(5)
//     const [hasMoreItems, setHasMoreItems] = useState(false)
//     const [loginModalVisibility, setLoginModalVisibility] = useState(false)
//     const [registerModalVisibility, setRegisterModalVisibility] = useState(false)
//     const [otpModalVisibility, setOtpModalVisibility] = useState(false)
//     const [phoneNumber, setPhoneNumber] = useState('')
//     const [otp1, setOtp1] = useState('')
//     const [otp2, setOtp2] = useState('')
//     const [otp3, setOtp3] = useState('')
//     const [otp4, setOtp4] = useState('')
//     const [nextButtonVisibility, setNextButtonVisibility] = useState(false)
//     const [otp_one_focused, setOtp_one_focused] = useState(false)
//     const [otp_two_focused, setOtp_two_focused] = useState(false)
//     const [otp_three_focused, setOtp_three_focused] = useState(false)
//     const [otp_four_focused, setOtp_four_focused] = useState(false)
//     const [selectedColorId, setSelectedColorId] = useState(null)
//     const [selectedColorIndex, setSelectedColorIndex] = useState(null)
//     const [selectedSizeId, setselectedSizeId] = useState(null)
//     const [selectedSizeIndex, setSelectedSizeIndex] = useState(null)
//     const [product_detail, setProduct_detail] = useState([])
//     const [quick_qty, setQuick_qty] = useState(0)
//     const [_mainSlug, set_mainSlug] = useState(props.mainSlug)
//     const [_breadcrum, set_breadcrum] = useState(props.breadcrum)
//     const [uniqueId, setUniqueId] = useState('')
//     const [_goToDashboard, set_goToDashboard] = useState(false)

//     // this.state = {
//     //     limit: 5,hasMoreItems: false,loginModalVisibility: false,registerModalVisibility: false,otpModalVisibility: false,phoneNumber: '',otp1: '',otp2: '',otp3: '',otp4: '',nextButtonVisibility: false, opt_one_focused: false,opt_two_focused: false,opt_three_focused: false,opt_four_focused: false,selectedColorId: null,selectedColorIndex: null,selectedSizeId: null,selectedSizeIndex: null,product_detail: [],quick_qty: 0,_mainSlug: this.props.mainSlug,_breadcrum: this.props.breadcrum,uniueId: '',_goToDashboard: false
//     // };



//     // componentWillMount() {

//     // }
//     const InputOne = useRef()
//     const InputTwo = useRef()
//     const InputThree = useRef()
//     const InputFour = useRef()
//     useEffect(() => {
//         const { products, addToCart, symbol, addToWishlist, addToCompare, records } = props;
//         var uniueId = localStorage.getItem('deviceId')
//         setUniqueId(uniueId)
//         var productId = props.mainSlug
//         if (!props.isUserLogin && productId == "offer") {
//             set_goToDashboard(true)
//         }
//         setTimeout(function () {
//             document.querySelector(".loader-wrapper").style = "display: none";
//         }, 2000);
//     },[''])
//     useEffect((nextProps) => {
//         const { mainSlug, breadcrum, products, addToCart, symbol, addToWishlist, addToCompare, records } = props;
//         fetchMoreItems(records);
//         set_mainSlug(mainSlug)
//         set_breadcrum(breadcrum)
//         set_mainSlug(props.mainSlug)
//         set_breadcrum(props.breadcrum)
//         var productId = mainSlug
//         if (!props.isUserLogin && productId == "offer") {
//             set_goToDashboard(true)
//         }
//     }, [records,props.mainSlug,props.breadcrum,props.mainSlug, props.breadcrum])

//     //  componentDidMount() {
//     //     // this.fetchMoreItems();
//     // }

//     // componentWillReceiveProps(nextProps) {

//     // }

//     const fetchMoreItems = async (records) => {

//         if (records !== undefined) {

//             if (limit >= records.length) {
//                 setHasMoreItems(false)
//                 return;
//             }
//             // a fake async api call
//             setTimeout(() => {
//                 setLimit(limit + 5)
//             }, 1000);
//         }
//     }

//     const quickViewQuantityChange = async (qty, item) => {
//         // var selectedColorId = selectedColorId;
//         // var selectedSizeId = selectedSizeId;
//         if (selectedColorId === undefined || selectedColorId === null) {
//             Notification('error', 'Error!', 'Please select color first!')
//         }
//         else if (selectedSizeId === undefined || selectedSizeId === null) {
//             Notification('error', 'Error!', 'Please select size first!')
//         }
//         if (qty === '' || qty === '0') {
//             Notification('error', 'Error!', 'Please enter quantity!')
//         }
//         else {
//             if (item.details.length > 0) {
//                 item.details.map((i, index) => {
//                     if (i.color_id === selectedColorId && i.size_id === selectedSizeId) {
//                         if (i.min_order_qty > qty) {
//                             Notification('error', 'Error!', 'Qyantity should be greater than or euale to ' + i.min_order_qty)
//                         }
//                         else if (i.max_order_qty < qty) {
//                             Notification('error', 'Error!', 'Qyantity should be less than or euale to ' + i.max_order_qty)
//                         }
//                         else {
//                             setTimeout(() => {
//                                 setQuick_qty(qty)
//                             }, 1000);
//                         }
//                     }
//                 })
//             }
//         }
//     }

//     const _removeFromWishListApi = async (item, page) => {

//         if (!props.isUserLogin) {
//             setLoginModalVisibility(true)
//         }
//         else {
//             if (props._from === "from_wishlist") {
//                 var res = await NaswizHelperApi.GET_WITH_TOKEN('v1/removewishlist/'.concat(item.id), "Error => remove item from user wishlist")
//                 if (res && res.success === 1) {
//                     setTimeout(() => {
//                         props.updateDataAfterAction()
//                         props.getWishlistData('v1/wishlist-details/' + uniqueId + "?page=" + page, "Error => Get all wishlist data")
//                     }, 1000)
//                 } else {
//                     setTimeout(() => {
//                         props.updateDataAfterAction()
//                         props.getWishlistData('v1/wishlist-details/' + uniqueId + "?page=" + page, "Error => Get all wishlist data")
//                     }, 1000)

//                 }
//             }
//             else {
//                 var res = await NaswizHelperApi.GET_WITH_TOKEN(`v1/removewishlist/${item.wishlist_id}`, "Error => remove item from user wishlist")
//                 if (res && res.success === 1) {
//                     Notification('success', 'Success!', 'Item removed from Favourites!')
//                     setTimeout(() => {
//                         props.updateDataAfterAction()
//                         props.getWishlistData('v1/wishlist-details/' + uniqueId, "Error => Get all wishlist data")
//                     }, 1000)
//                 }
//             }


//         }

//     }

//     const _addToWishlistWithApi = async (item) => {

//         if (!props.isUserLogin) {
//             setLoginModalVisibility(true)
//             Notification('error', 'Error!', 'Please Login First!')
//         }
//         else {

//             var res = await NaswizHelperApi.GET_WITH_TOKEN('v1/wishlist/'.concat(item.product_id), "Error => Add to wishlist api")

//             if (res && res.success === 1) {
//                 if (res.message === 'Product is already in Wishlist.') {
//                     Notification('error', 'Error!', res.message)
//                 }
//                 else {
//                     Notification('success', 'Success!', 'Product added to Favourites')
//                     setTimeout(() => {
//                         props.updateDataAfterAction()
//                         props.getWishlistData('v1/wishlist-details/' + uniqueId, "Error => Get all wishlist data")
//                     }, 500)
//                 }
//             }

//         }
//     }

//     const quickViewAddToCart = async (product_detail) => {
//         var selectedColorId = selectedColorId;
//         var selectedSizeId = selectedSizeId;
//         var qty = quick_qty;

//         if (!props.isUserLogin) {
//             Notification('error', 'Error!', 'Please Login First!')
//         }
//         else {

//             if (selectedColorId === undefined || selectedColorId === null) {
//                 Notification('error', 'Error!', 'Please select color first!')
//             }
//             else if (selectedSizeId === undefined || selectedSizeId === null) {
//                 Notification('error', 'Error!', 'Please select size first!')
//             }
//             else {

//                 var body = new FormData()
//                 body.append('product_id', product_detail.product_id)
//                 body.append('color_id', selectedColorId)
//                 body.append('size_id', selectedSizeId)


//                 if (product_detail.details.length > 0) {

//                     product_detail.details.map((i, index) => {
//                         if (i.color_id === selectedColorId && i.size_id === selectedSizeId) {
//                             body.append('main_price', i.price)
//                             body.append('price', i.wholesale_price)
//                         }
//                         else {
//                             body.append('main_price', product_detail.price)
//                             body.append('price', product_detail.wholesale_price)
//                         }
//                     })
//                 }
//                 else {
//                     body.append('main_price', product_detail.price)
//                     body.append('price', product_detail.wholesale_price)
//                 }

//                 if (product_detail.product_images.length > 0) {

//                     var temp = product_detail.product_images[0].image.includes('https://newdemo.myshopwiz.com/master-naswiz//') ? 'https://newdemo.myshopwiz.com/master-naswiz//' : product_detail.product_images[0].image.includes('https://newdemo.myshopwiz.com/master-naswiz/') ? 'https://newdemo.myshopwiz.com/master-naswiz/' : null
//                     body.append('image', product_detail.product_images[0].image.replace(temp, ''))
//                 }
//                 else {
//                     body.append('image', `storage/uploads/product/Medium${process.env.PUBLIC_URL}/assets/images/nia.png`)
//                 }

//                 if (qty === 0) {
//                     Notification('error', 'Error!', 'Please enter quantity!')
//                 }
//                 else {
//                     body.append('quantity', qty)
//                     var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/add-usercart', body, "Error => v1/add-usercart")
//                     if (res && res.success === 1) {
//                         Notification('success', 'Success!', res.message ? res.message : 'Product Add to Cart Successfully.')
//                         var formdata = new FormData()
//                         formdata.append('device_id', uniqueId)
//                         props.getCartData('v1/get-cart-list', formdata, "Error => GetCartList api.")

//                         setSelectedColorId(null)
//                         setSelectedColorIndex(null)
//                         setselectedSizeId(null)
//                         setSelectedSizeIndex(null)
//                         setQuick_qty(0)
//                     }
//                     else {
//                         Notification('error', 'Error!', res.status ? res.status : 'Try again later.')
//                     }
//                 }

//             }
//         }
//     }


//     const _onRemoveFromCartClicked = async (item) => {
//         setTimeout(() => {
//             props.updateDataAfterAction()
//             var body = new FormData()
//             body.append('device_id', uniqueId)
//             props.getCartData('v1/get-cart-list', body, 'Error => While getting cart data api.')
//         }, 1000)
//     }

//     const _addToCartWithApi = async (item) => {
//         if (!props.isUserLogin) {
//             setLoginModalVisibility(true)
//         }
//         else {

//             var body = new FormData()
//             body.append('product_id', item.product_id)
//             body.append('color_id', item.details[0].color_id)
//             body.append('size_id', item.details[0].size_id)
//             body.append('main_price', item.details[0].wholesale_price)
//             body.append('price', item.details[0].price)
//             body.append('quantity', 1)

//             if (item.product_images.length > 0) {
//                 var temp = item.product_images[0].image.includes('http://cranup.in/naswiz//') ? 'http://cranup.in/naswiz//' : item.product_images[0].image.includes('http://cranup.in/naswiz/') ? 'http://cranup.in/naswiz/' : null
//                 body.append('image', item.product_images[0].image.replace(temp, ''))
//             }
//             else {
//                 body.append('image', `storage/uploads/product/Medium${process.env.PUBLIC_URL}/assets/images/nia.png`) //passing static string
//             }

//             var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/add-usercart', body, "Error => Add item into user cart")

//             if (res && res.success === 1) {
//                 setTimeout(() => {
//                     props.updateDataAfterAction()
//                     var body = new FormData()
//                     body.append('device_id', uniqueId)
//                     props.getCartData('v1/get-cart-list', body, 'Error => While getting cart data api.')
//                 }, 1000)

//             }
//             else {
//                 alert(res && res.message ? res.message : 'Try again later')
//             }

//         }
//     }


//     const _onChangeOtpOne = () => {
//         if (otp1.length === 1) {
//             InputTwo.focus()
//         }
//     }

//     const _onChangeOtpTwo = () => {
//         if (otp2.length === 1) {
//             InputThree.focus()
//         }
//     }

//     const _onChangeOtpThree = () => {
//         if (otp3.length === 1) {
//             InputFour.focus()
//         }
//     }

//     const setLogin = async () => {

//         // var { phoneNumber } = this.state
//         if (phoneNumber === '') {
//             Notification('error', 'Error!', 'Please enter mobile number !')
//         }
//         else if (phoneNumber.length !== 13) {
//             Notification('error', 'Error!', 'Mobile number is not valid !')
//         }
//         else {

//             var formdata = new FormData();
//             formdata.append("number", phoneNumber);

//             var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/login', formdata, 'Error => Login api')

//             if (res && res.success === 1) {
//                 setLoginModalVisibility(false)
//                 setOtpModalVisibility(true)

//             }

//         }
//     }

//     const setRegister = async () => {

//         // var { phoneNumber } = this.state

//         if (phoneNumber === '') {
//             Notification('error', 'Error!', 'Please enter mobile number !')
//         }
//         else if (phoneNumber.length !== 13) {
//             Notification('error', 'Error!', 'Mobile number is not valid !')
//         }
//         else {

//             var formdata = new FormData();
//             formdata.append("number", phoneNumber);

//             for (var i of formdata.entries()) {
//             }

//             var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/register', formdata, "Error => Register api.")

//             if (res && res.success === 1) {
//                 setRegisterModalVisibility(false)
//                 setOtpModalVisibility(true)
//             }
//             else {
//                 setPhoneNumber('')
//                 res && res.message ?
//                     Notification('error', 'Error!', res.message)
//                     :
//                     Notification('error', 'Error!', 'Mobile number is not valid !')
//             }
//         }
//     }

//     const verifyOtp = async () => {

//         // var { otp1, otp2, otp3, otp4, phoneNumber } = this.state

//         var finalOTP = otp1.concat(otp2.concat(otp3.concat(otp4)))

//         var formdata = new FormData();
//         formdata.append("number", phoneNumber);
//         formdata.append("otp", finalOTP);

//         var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/verify-otp', formdata, "Error => Verify OTP with login api")

//         if (res && res.success === 1) {
//             setOtpModalVisibility(false)
//             setPhoneNumber('')
//             setOtp1('')
//             setOtp2('')
//             setOtp3('')
//             setOtp4('')

//             props.changeLoginState(true)
//             props.manageUserIdState(res.id)
//             localStorage.setItem('token', res.token)
//             props.updateDataAfterAction()
//             Notification('success', 'Success!', 'Verify Successfully!')
//             setTimeout(() => { props.getWishlistData('v1/wishlist-details/' + uniqueId, "Error in wishlist") }, 1000)
//         }
//         else {
//             // alert(res && res.message ? res.message : 'Try again later')
//             res && res.message ?
//                 Notification('error', 'Error!', res.message)
//                 :
//                 Notification('error', 'Error!', 'Mobile number is not valid !')
//         }

//     }

//     const _resendOTP = async () => {

//         // var { phoneNumber } = this.state
//         var formdata = new FormData();
//         formdata.append("number", phoneNumber);

//         var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/login', formdata, 'Error => Resend otp api')

//         if (res && res.success === 1) {
//             Notification('error', 'Error!', res.message)
//         }
//         else {
//             res && res.message ?
//                 Notification('error', 'Error!', res.message)
//                 :
//                 Notification('error', 'Error!', 'Mobile number is not valid !')
//         }
//     }
//     if (_goToDashboard) {
//         return (
//             <Redirect to={{
//                 pathname: `/`,
//             }} />
//         )
//     }
//     const { products, addToCart, symbol, addToWishlist, addToCompare, records, page } = props;
//     return (
//         <div>
//             <ToastContainer />
//             <div className="product-wrapper-grid test">
//                 <div className="">

//                     {
//                         records && records.length > 0 ?
//                             <InfiniteScroll
//                                 dataLength={limit} //This is important field to render the next data
//                                 next={fetchMoreItems}
//                                 hasMore={hasMoreItems}
//                                 loader={<div className="loading-cls"></div>}
//                                 endMessage={
//                                     <p className="seen-cls  seen-it-cls">
//                                     </p>
//                                 }>

//                                 <div className="row test">
//                                     {records.slice(0).map((records, index) =>
//                                         <div className={'col-6 col-md-4 col-sm-4 col-xl-3 mb-0'} key={index}>
//                                             <ProductListItem
//                                                 _from={props._from}
//                                                 product={records}
//                                                 symbol={symbol}
//                                                 onAddToCompareClicked={() => addToCompare(records)}
//                                                 onAddToCartClicked={() => _addToCartWithApi(records)}
//                                                 onRemoveFromCartClicked={() => _onRemoveFromCartClicked()}
//                                                 onAddToWishlistClicked={() => _addToWishlistWithApi(records)}
//                                                 onRemoveFromWishlistClicked={() => _removeFromWishListApi(records, page)}
//                                                 page={page}
//                                                 quickViewAddToCart={() => quickViewAddToCart(records)}
//                                                 quickViewQuantityChange={() => quickViewQuantityChange()}
//                                                 key={index}
//                                                 mainSlug={_mainSlug}
//                                                 breadcrum={_breadcrum}
//                                                 onClickCart={props.onClickCart}
//                                                 updateDataAfterAction={props.updateDataAfterAction}
//                                             />
//                                         </div>)
//                                     }
//                                 </div>
//                             </InfiniteScroll>
//                             :
//                             <div className="row">
//                                 {props._from === "from_wishlist" ? <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
//                                     <img style={{ margin: '0 auto' }} src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" />
//                                     <h3>You haven't selected your favourite products. Click on the bottom right corner of the product to mark as your favourite product.</h3>

//                                     <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid mt-3">continue shopping</Link>
//                                 </div> : <div className="col-sm-12 text-center section-b-space mt-5 no-found" ><h3>There are no products in this category.</h3></div>}
//                             </div>
//                     }
//                 </div>
//             </div>

//             {/* Login modal */}
//             <Modal
//                 open={loginModalVisibility}
//                 onClose={() => {
//                     setLoginModalVisibility(false)
//                     setPhoneNumber('')
//                 }
//                 }
//                 center id="login_modal">
//                 <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
//                     <div className="modal-content quick-view-modal">
//                         <div className="SignupModalIn">
//                             <div className="SignupModal modal-body clearfix">
//                                 <div className="back_area">
//                                     <a>Back</a>
//                                 </div>
//                                 <div className="modal-title">
//                                     <h4>Please login to continue</h4>
//                                 </div>

//                                 <div className="number_box text-center">
//                                     <p>Enter your phone number to Login</p>
//                                     <PhoneInput
//                                         placeholder="Enter phone number"
//                                         defaultCountry="IN"
//                                         value={phoneNumber}
//                                         onChange={phoneNumber => setPhoneNumber(phoneNumber)}
//                                         inputMode="numeric"

//                                     />
//                                     {/* </div> */}
//                                     <button
//                                         className="btn next_btn_ready"
//                                         id="show_verifications"
//                                         type="button"
//                                         onClick={() => setLogin()}>
//                                         Next
//                                     </button>
//                                     <p>
//                                         {/* New account */}
//                                         <Link
//                                             to="/"
//                                             data-toggle="modal"
//                                             data-target="#register_modal"
//                                             onClick={() => {
//                                                 setLoginModalVisibility(false)
//                                                 setRegisterModalVisibility(true)
//                                             }
//                                             }
//                                             style={{ color: '#000' }}>
//                                             <div className="mt-3 text-center">
//                                                 <h6 style={{ marginTop: '1px' }}>Don't have an account ?<br></br></h6>
//                                                 <h5 style={{ fontWeight: 'bold', color: colors.themeColor }}>Register</h5>
//                                             </div>
//                                         </Link>
//                                     </p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </Modal>

//             {/* Register modal */}
//             <Modal
//                 open={registerModalVisibility}
//                 onClose={() => {
//                     setRegisterModalVisibility(false)
//                     setPhoneNumber('')
//                 }}
//                 center
//                 id="register_modal">
//                 <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
//                     <div className="modal-content quick-view-modal">
//                         <div className="SignupModalIn">
//                             <div className="SignupModal modal-body clearfix">
//                                 <div className="back_area">
//                                     <a href="javascript:void(0)">Back</a>
//                                 </div>
//                                 <div className="modal-title">
//                                     <h4>Register</h4>
//                                 </div>

//                                 <div className="number_box">
//                                     <p>Enter your phone number to Sign up</p>

//                                     <PhoneInput
//                                         placeholder="Enter phone number"
//                                         defaultCountry="IN"
//                                         value={phoneNumber}
//                                         onChange={phoneNumber => setPhoneNumber(phoneNumber)}
//                                         max="10"

//                                     />
//                                     {/* </div> */}
//                                     <button className="btn next_btn_ready" id="show_verifications" type="button" onClick={() => setRegister()}>Next</button>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </Modal>

//             {/* OTP modal */}
//             <Modal open={otpModalVisibility} onClose={() => {
//                 setOtpModalVisibility(false)
//                 setPhoneNumber('')
//                 setOtp1('')
//                 setOtp2('')
//                 setOtp3('')
//                 setOtp4('')
//             }} center id="verify_modal">
//                 <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
//                     <div className="modal-content quick-view-modal">
//                         <div className="SignupModalIn">
//                             <div className="SignupModal modal-body clearfix">

//                                 <div className="modal-title">
//                                     <h4>Phone Number Verification</h4>
//                                 </div>
//                                 <div className="otp_verification">
//                                     <p>Enter 4 digit code sent to your phone   <br></br> {phoneNumber}</p>
//                                     <div className="otp-box">
//                                         <input
//                                             style={{ color: otp_one_focused ? colors.themeColor : 'black' }}
//                                             onFocus={() => setOtp_one_focused(true)}
//                                             onBlur={() => setOtp_one_focused(false)}
//                                             ref={InputOne}
//                                             type="text"
//                                             className="form-control"
//                                             maxLength={1}
//                                             onChange={(e) => {
//                                                 setOtp1(e.target.value)
//                                                 _onChangeOtpOne()
//                                             }
//                                             }
//                                         />
//                                         <input
//                                             style={{ color: otp_two_focused ? colors.themeColor : 'black' }}
//                                             onFocus={() => setOtp_two_focused(true)}
//                                             onBlur={() => setOtp_two_focused(false)}
//                                             ref={InputTwo}
//                                             type="text"
//                                             className="form-control"
//                                             maxLength={1}
//                                             onChange={(e) => {
//                                                 setOtp2(e.target.value)
//                                                 _onChangeOtpTwo()
//                                             }
//                                             }
//                                         />
//                                         <input
//                                             style={{ color: otp_three_focused ? colors.themeColor : 'black' }}
//                                             onFocus={() => setOtp_three_focused(true)}
//                                             onBlur={() => setOtp_three_focused(false)}
//                                             ref={InputThree}
//                                             type="text"
//                                             className="form-control"
//                                             maxLength={1}
//                                             onChange={(e) => {
//                                                 setOtp3(e.target.value)
//                                                 _onChangeOtpThree()
//                                             }
//                                             }
//                                         />
//                                         <input
//                                             style={{ color: otp_four_focused ? colors.themeColor : 'black' }}
//                                             onFocus={() => setOtp_four_focused(true)}
//                                             onBlur={() => setOtp_four_focused(false)}
//                                             ref={InputFour}
//                                             type="text"
//                                             className="form-control"
//                                             maxLength={1}
//                                             onChange={(e) => {
//                                                 setOtp4(e.target.value)
//                                                 setNextButtonVisibility(true)
//                                             }
//                                             }
//                                         />
//                                     </div>
//                                     <button
//                                         className="next_btn_ready mt-2"
//                                         // className="next_btn mt-2"
//                                         type="button"
//                                         onClick={() => verifyOtp()}>
//                                         Next
//                                     </button>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="text-center mt-3">
//                     <h6 style={{ color: colors.themeColor }}> <a onClick={() => _resendOTP()}>Resend OTP</a></h6>
//                 </div>
//             </Modal>

//         </div >
//     )




// }

// const mapStateToProps = (state) => ({
//     products: getVisibleproducts(state.data, state.filters),
//     symbol: '₹ ',
//     isUserLogin: state.login.isUserLogin,
//     userCart: state.cart.cartData,
//     userID: state.login.userID,
//     cartListArray: state.login.userCartData.records.cart_list
// })



// export default connect(mapStateToProps, { addToCart, addToWishlist, addToCompare, removeFromWishlist, addItemIntoCart, removeItemFromCart, changeLoginState, manageUserIdState, getWishlistData, getCartData })(ProductListing)