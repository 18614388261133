import React from 'react'
import { connect } from 'react-redux'
import { colors, NaswizHelperApi } from '../../../NaswizHelper'
import moment from "moment";
import './innerdashboard.css';
import Notification from '../../../Notification'
import bg_img from '../../../assets/images/wallet_bg.jpg'
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';
import Seo from '../../Seo';
class InnerDashboardIncentivePoint extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            total_ip: '',
            ip_conversion: '',
            converted_ip: '',
            arr_ip_data: [],
            loading: true,
            _noOfPage:[],
            _page:1
        }
    }

    componentDidMount = () => {
        this._getIncentivePointList()
    }

    _getIncentivePointList = async () => {

        var res = await NaswizHelperApi.GET_WITH_TOKEN('v1/incentive_points?page='+this.state._page, "Error => v1/incentive_points api.")
        if (res && res.success == 1) {
            this.setState({
                total_ip: res.record.data.total_ip,
                ip_conversion: res.record.data.ip_conversion,
                converted_ip: res.record.data.converted_ip,
                arr_ip_data: res.record.data.data,
                loading: false
            })
            var tmp = []
                for (let i = 0; i < res.record.data.last_page; i++) {
                    tmp.push(i);
                }
             
                this.setState({ _noOfPage: tmp })
        }
        else {
            this.setState({
                loading: false
            })
        }

    }

    handlePagination = async (value2) => {
        var value = value2.selected + 1
        if (value <= this.state._noOfPage.length) {
            await this.setState({ _page: value })
            // if (this.state.isPriceFilter)
            //     this._searchUponSelectedFilter()
            // else
            this._getIncentivePointList();
        }

    }
    graboffer = async (item) => {
        var res = await NaswizHelperApi.GET_WITH_TOKEN('v1/ip/redeem/' + item.id, "Error => v1/incentive_points api.")
        Notification('success', 'Success!', res.message)
        this._getIncentivePointList()
    }

    render() {
        return (
            <div className="col-lg-9">
                {/* <Helmet>
                <title>Loyalty Points - Naswiz Retails Pvt Ltd</title>
                <meta name="og_title" property="og:title" content="Loyalty Points - Naswiz Retails Pvt Ltd" />
                <meta name="twitter:title" content="Loyalty Points - Naswiz Retails Pvt Ltd" />
                <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />		
                </Helmet> */}
                <Seo title="Loyalty Points" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company."  image="assets/images/lp_icon.png" />
                {
                    this.state.loading ?
                        <div>
                            <div className="loader-wrapper">
                                <div className="loader"></div>
                            </div>
                        </div> :
                    
                        <div className="order-lg-last dashboard-content">
                            <div className="inner_loyalty_point">
                                <div className="top_full">
                                    <div className="notification_head loyality_div">
                                        <h3>Loyalty Points</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 ">
                                        <div className="not_div lb_div">
                                            <h4>Total Loyalty Points</h4>
                                            <div className="row">
                                                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                                                    <div className="loyalty_div" style={{ backgroundImage: `url(${bg_img})` }}>
                                                        <div className="wallet_logo">
                                                            <img src={`${process.env.PUBLIC_URL}assets/images/lp_icon.png`} />
                                                        </div>
                                                        <div className="lp">
                                                            <p>Loyalty Points</p><span>Redeem Limit : {this.state.total_ip}</span>
                                                            <h4><strong>{this.state.ip_conversion}</strong></h4>
                                                            <a href="javascript:void(0)">Loyalty Point in Rupees</a>₹ ({this.state.converted_ip})
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    this.state.arr_ip_data.length > 0 ?
                                        this.state.arr_ip_data.map((item, index) => {
                                            return (
                                                <div key={index} className="mt-3">
                                                    <div className="not_div lp_div_2">
                                                        <div className="offer_head ">
                                                            <h4>Earned Points</h4>
                                                        </div>
                                                        <div className="paddtext">
                                                            <div className="offer_div">
                                                                <div className="offer_details">
                                                                    <div className="offer_logo">
                                                                        <img src={`${process.env.PUBLIC_URL}assets/images/lp_icon.png`} />
                                                                    </div>
                                                                    <div className="offer_para">
                                                                        <p>You have earned <b className='text-theme-primary'>{item.incentive_point}</b> Loyalty Points by registration
                                                                        </p>
                                                                    </div>
                                                                    <div className="offer_para">
                                                                        <span className='text-theme-secondary'>{moment(item.expire_date).format('DD-MM-YYYY')}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="offer_link">
                                                                    <a onClick={() => this.graboffer(item)} > Grab this Offer</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : null
                                }

                                {this.state._noOfPage.length > 1 ?
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                        pageCount={this.state._noOfPage.length}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={5}
                                        onPageChange={(page) => this.handlePagination(page)}
                                        containerClassName={'pagination toolbox-item'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        activeClassName={'active'}
                                        // forcePage={this.state._page - 1}
                                    />
                                    : null } 
                            </div>
                        </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        allState: state
    }
}

export default connect(mapStateToProps, null)(InnerDashboardIncentivePoint)