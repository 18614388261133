import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'
import { NaswizHelperApi } from '../../../../NaswizHelper';

class TopBar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading_pages: false,
            arr_pageData: [],
            activeSlug: undefined
        }
    }

    componentDidMount = () => {
    }

    getPages = (slug) => {

        var formData = new FormData()
        formData.append('type', slug)
        for (var pair of formData.entries()) {
        }

        this.setState({ loading_pages: true }, async () => {

            var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/get-page', formData, "Error => v1/get-page")
            if (res && res.success == 1) {
                this.setState({
                    arr_pageData: res.records,
                    loading_pages: false
                })
            }
            else {
                this.setState({ loading_pages: false })
            }
        })
    }

    render() {
        const { translate, _data, top_bar_cms, social_arr } = this.props;
        return (
            <div>

                {/*  */}
                <div className="header-top">
                    <div className="container d-flex justify-content-between align-items-center">
                        <div className="header-left d-none d-sm-block">
                            <p className="top-message text-uppercase">Naswiz Retails Pvt Ltd</p>
                        </div>

                        <div className="header-right header-dropdowns ml-0 ml-sm-auto w-sm-100 d-flex align-items-center">
                            <div className="header-dropdown dropdown-expanded d-none d-lg-block">
                                <a href="/">Links</a>
                                <div className="header-menu">
                                    <ul className="header_all_menus">
                                        {top_bar_cms && top_bar_cms.length > 0 ?
                                            top_bar_cms.map((topitems, index) => {
                                                return (
                                                    topitems.slug == 'about' ? <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/about-us` }}>{topitems.name}</Link></li>
                                                        :
                                                        topitems.slug == 'Our-Stores' ? <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/naswiz_stores` }}>{topitems.name}</Link></li>
                                                            :
                                                            topitems.slug == 'Blog' ? <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/blog` }}>{topitems.name}</Link></li>
                                                                :
                                                                topitems.slug == 'Contact' ? <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/Contact` }} onClick={() => localStorage.removeItem("company")} >{topitems.name}</Link></li>
                                                                    :
                                                                    <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/${topitems.slug}`, state: topitems }} onClick={() => localStorage.setItem("company", JSON.stringify(topitems))} >{topitems.name}</Link></li>
                                                )
                                            }) : null
                                        }
                                        <li><Link to="/testimonial" onClick={() => localStorage.removeItem("company")} >Testimonial</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <span className="separator"></span>
                            <p className="top-message text-uppercase">SHOW US SOME LOVE ON </p>
                            <div className="social-icons">
                                {
                                    social_arr && social_arr.length > 0 ?
                                        social_arr.map((socialitems, index) => {
                                            if (socialitems.name === 'instagram' || socialitems.name === 'twitter' || socialitems.name === 'facebook' || socialitems.name === 'youtube') {
                                                return (
                                                    <a key={index} title={socialitems.name} href={`${socialitems.value}`} target="_blank"><i className={socialitems.icon}></i></a>
                                                )
                                            }

                                        }) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default withTranslate(TopBar);

////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////functional component///////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { withTranslate } from 'react-redux-multilingual'
// import { NaswizHelperApi } from '../../../../NaswizHelper';

// const  TopBar =(props)=>{

//         const [loading_pages, setLoading_pages] = useState(false)
//         const [arr_pageData, setArr_pageData] = useState([])
//         const [activeSlug, setActiveSlug] = useState(undefined)
//         // this.state = {
//         //     loading_pages: false,
//         //     arr_pageData: [],
//         //     activeSlug: undefined
//         // }
    

//     // componentDidMount = () => {
//     //     // this.getPages()
//     // }

//     const getPages = (slug) => {

//         var formData = new FormData()
//         formData.append('type', slug)
//         for (var pair of formData.entries()) {
//         }
//         setLoading_pages(true)
//               getPages=async () => {

//             var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/get-page', formData, "Error => v1/get-page")
//             if (res && res.success == 1) {
//                     setArr_pageData(res.records)
//                     setLoading_pages(false)
//             }
//             else {
//                 setLoading_pages(false) 
//             }
//         }
//     }

//         const { translate, _data, top_bar_cms, social_arr } = props;
//         return (
//             <div>

//                 {/*  */}
//                 <div className="header-top">
//                     <div className="container d-flex justify-content-between align-items-center">
//                         <div className="header-left d-none d-sm-block">
//                             <p className="top-message text-uppercase">Naswiz Retails Pvt Ltd</p>
//                         </div>

//                         <div className="header-right header-dropdowns ml-0 ml-sm-auto w-sm-100 d-flex align-items-center">
//                             <div className="header-dropdown dropdown-expanded d-none d-lg-block">
//                                 <a href="/">Links</a>
//                                 <div className="header-menu">
//                                     <ul className="header_all_menus">
//                                         {top_bar_cms && top_bar_cms.length > 0 ?
//                                             top_bar_cms.map((topitems, index) => {
//                                                 return (
//                                                     topitems.slug == 'about' ? <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/about-us` }}>{topitems.name}</Link></li>
//                                                         :
//                                                         topitems.slug == 'Our-Stores' ? <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/naswiz_stores` }}>{topitems.name}</Link></li>
//                                                             :
//                                                             topitems.slug == 'Blog' ? <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/blog` }}>{topitems.name}</Link></li>
//                                                                 :
//                                                                 topitems.slug == 'Contact' ? <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/Contact` }} onClick={() => localStorage.removeItem("company")} >{topitems.name}</Link></li>
//                                                                     :
//                                                                     <li key={index}><Link to={{ pathname: `${process.env.PUBLIC_URL}/${topitems.slug}`, state: topitems }} onClick={() => localStorage.setItem("company", JSON.stringify(topitems))} >{topitems.name}</Link></li>
//                                                 )
//                                             }) : null
//                                         }
//                                         <li><Link to="/testimonial" onClick={() => localStorage.removeItem("company")} >Testimonial</Link></li>
//                                     </ul>
//                                 </div>
//                             </div>

//                             <span className="separator"></span>
//                             <p className="top-message text-uppercase">SHOW US SOME LOVE ON </p>
//                             <div className="social-icons">
//                                 {
//                                     social_arr && social_arr.length > 0 ?
//                                         social_arr.map((socialitems, index) => {
//                                             if (socialitems.name === 'instagram' || socialitems.name === 'twitter' || socialitems.name === 'facebook' || socialitems.name === 'youtube') {
//                                                 return (
//                                                     <a key={index} title={socialitems.name} href={`${socialitems.value}`} target="_blank"><i className={socialitems.icon}></i></a>
//                                                 )
//                                             }

//                                         }) : null
//                                 }
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )
    
// }


// export default withTranslate(TopBar);