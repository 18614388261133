import React, { Component } from "react";
import Notification from "../Notification";
import { NaswizHelperApi } from "../NaswizHelper";

class Feedback extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = async () => {
    var { customer, order_id, numbers, status } = "";
    this.props.location.search.split("&").map(async (f) => {
      if (f.split("=")[0] === "?customer") {
        customer = f.split("=")[1];
      }
      if (f.split("=")[0] === "order_id") {
        order_id = f.split("=")[1];
      }
      if (f.split("=")[0] === "number") {
        numbers = f.split("=")[1];
      }
      if (f.split("=")[0] === "status") {
        status = f.split("=")[1];
      }
    });
    var body = new FormData();
    body.append("customer", customer);
    body.append("order_id", order_id);
    body.append("number", numbers);
    body.append("status", status);

    var res = await NaswizHelperApi.POST_WITHOUT_TOKEN(
      "v1/feedback-review",
      body,
      "feedback"
    );
    if (res.message) {
      Notification("success", "Success!", res.message);
      this.props.history.push("/");
    }
  };

  render() {
    return <></>;
  }
}

export default Feedback;
