import React, {Component} from 'react';
import { connect } from 'react-redux'
import {Link} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { addToCart, addToWishlist, addToCompare } from '../../actions/index'
import {getVisibleproducts} from '../../services/index';
import ProductListItem from "./common/product-list-item";
import Breadcrumb from "../common/breadcrumb";
import Seo from '../Seo';

class CollectionFullWidth extends Component {

    constructor (props) {
        super (props)
        this.state = {
            limit: 5,
            hasMoreItems: true,
        };
    }

    componentWillMount(){
        this.fetchMoreItems();
    }

    fetchMoreItems = () => {
        if (this.state.limit >= this.props.products.length) {
            this.setState({ hasMoreItems: false });
            return;
        }
        setTimeout(() => {
            this.setState({
                limit: this.state.limit + 5
            });
        }, 3000);


    }

    render (){
        const {products, addToCart, symbol, addToWishlist, addToCompare} = this.props;
        return (
            <div>
                <Seo title="Collection" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                <Breadcrumb title={'Collection'}/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-t-space portfolio-section portfolio-padding metro-section port-col">
                                {products.length > 0 ?
                                    <InfiniteScroll
                                        dataLength={this.state.limit} //This is important field to render the next data
                                        next={this.fetchMoreItems}
                                        hasMore={this.state.hasMoreItems}
                                        loader={<div className="loading-cls"></div>}
                                        endMessage={
                                            <p className="seen-cls seen-it-cls">
                                                <b>Yay! You have seen it all</b>
                                            </p>
                                        }
                                    >
                                        <div className="isotopeContainer row">
                                            { products.slice(0, this.state.limit).map((product, index) =>
                                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 isotopeSelector" key={index}>
                                                    <ProductListItem product={product} symbol={symbol}
                                                                 onAddToCompareClicked={() => addToCompare(product)}
                                                                 onAddToWishlistClicked={() => addToWishlist(product)}
                                                                 onAddToCartClicked={addToCart} key={index}/>
                                                </div>)
                                            }
                                        </div>
                                    </InfiniteScroll>
                                    :
                                    <div className="row">
                                        <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" />
                                            <h3>Sorry! Couldn't find the product you were looking For!!!    </h3>
                                            <p>Please check if you have misspelt something or try searching with other words.</p>
                                            <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue shopping</Link>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    products: getVisibleproducts(state.data, state.filters),
    symbol: state.data.symbol,
})

export default connect(
    mapStateToProps, {addToCart, addToWishlist, addToCompare}
)(CollectionFullWidth)

////////////////////////////////////////////////////////////////////////
/////////////////////////// functional component ///////////////////////
///////////////////////////////////////////////////////////////////////

// import React, {useState,useEffect} from 'react';
// import { connect } from 'react-redux'
// import {Link} from 'react-router-dom';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import { addToCart, addToWishlist, addToCompare } from '../../actions/index'
// import {getVisibleproducts} from '../../services/index';
// import ProductListItem from "./common/product-list-item";
// import Breadcrumb from "../common/breadcrumb";

// const  CollectionFullWidth =(props)=>  {

   
//         const [limit, setLimit] = useState(5)
//         const [hasMoreItems, setHasMoreItems] = useState(true)
//         // this.state = {
//         //     limit: 5,
//         //     hasMoreItems: true,
//         // };
        
//     useEffect(() => {
//         fetchMoreItems();
//     }, [''])
    

//     // componentWillMount(){
//     //     this.fetchMoreItems();
//     // }

//     const fetchMoreItems = () => {
//         if (limit >= props.products.length) {
//             setHasMoreItems(false) 
//             return;
//         }
//         setTimeout(() => {
//                 setLimit(limit + 5)
//         }, 3000);


//     }

//         const {products, addToCart, symbol, addToWishlist, addToCompare} = props;
//         return (
//             <div>
//                 <Breadcrumb title={'Collection'}/>

//                 <div className="container-fluid">
//                     <div className="row">
//                         <div className="col-12">
//                             <div className="section-t-space portfolio-section portfolio-padding metro-section port-col">
//                                 {products.length > 0 ?
//                                     <InfiniteScroll
//                                         dataLength={limit} //This is important field to render the next data
//                                         next={fetchMoreItems}
//                                         hasMore={hasMoreItems}
//                                         loader={<div className="loading-cls"></div>}
//                                         endMessage={
//                                             <p className="seen-cls seen-it-cls">
//                                                 <b>Yay! You have seen it all</b>
//                                             </p>
//                                         }
//                                     >
//                                         <div className="isotopeContainer row">
//                                             { products.slice(0, limit).map((product, index) =>
//                                                 <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 isotopeSelector" key={index}>
//                                                     <ProductListItem product={product} symbol={symbol}
//                                                                  onAddToCompareClicked={() => addToCompare(product)}
//                                                                  onAddToWishlistClicked={() => addToWishlist(product)}
//                                                                  onAddToCartClicked={addToCart} key={index}/>
//                                                 </div>)
//                                             }
//                                         </div>
//                                     </InfiniteScroll>
//                                     :
//                                     <div className="row">
//                                         <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
//                                             <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" />
//                                             <h3>Sorry! Couldn't find the product you were looking For!!!    </h3>
//                                             <p>Please check if you have misspelt something or try searching with other words.</p>
//                                             <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">Continue Shopping</Link>
//                                         </div>
//                                     </div>
//                                 }
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )
    
// }
// const mapStateToProps = (state) => ({
//     products: getVisibleproducts(state.data, state.filters),
//     symbol: state.data.symbol,
// })

// export default connect(
//     mapStateToProps, {addToCart, addToWishlist, addToCompare}
// )(CollectionFullWidth)