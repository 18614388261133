import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { NaswizHelperApi } from '../NaswizHelper'
import ProductListing from "../components/collection/common/product-listing";
import InputRange from 'react-input-range';
import { Radio, Checkbox } from 'antd'
import Notification from '../Notification'
import './productslisting.css';
import { changeLoginState, manageUserIdState, getWishlistData, getCartData } from '../actions'
import ReactPaginate from 'react-paginate';
import ProductListItem from '../components/collection/common/product-list-item';
import Seo from '../components/Seo';



class ProductSearch extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            slug: props.match.params.id,
            arr_sizes: [],
            arr_colour: [],
            arr_category: [],
            arr_brand: [],
            arr_occasion: [],
            startprice: 0,
            endprice: 0,
            // Filter state
            value: { min: 0, max: 0 },
            filterSizeID: [],
            filterColorSlug: [],
            filterBrand: [],
            filterOccasion: [],
            filterCategory: [],
            filterAllCategotyList: [],

            // checked check box array 
            _checkedSizeList: [],
            _checkedColorList: [],
            _checkedCategoryList: [],
            _checkedBrandList: [],
            _checkedOccasionList: [],

            priceLow: null,
            priceHigh: null,
            isLowToHigh: false,
            isHighToLow: false,
            isOldest: false,
            isLatest: false,
            show_product: 12,
            _per_page: 12,
            _page: 1,
            _selectedCount: 1,
            _mainSlug: props.match.params.var,
            // _searchRecord: props.location.state,
            _searchRecord: [],
            _selectedPage: 0,
            uniueId: '',
            _noOfPage: [],
            _totalProduct: 0,
            _record: []
        }
    }

    componentDidMount = async () => {

        var uniueId = await localStorage.getItem('deviceId')
        await this.setState({ uniueId: uniueId })
        this._search()
        // console.log("User ID", this.props.userID);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ _mainSlug: nextProps.match.params.var, })
        this._search()


    }
    _search = async () => {


        // this.setState({ searchTerm, _search_text: searchTerm, __is_search: false })

        // if (searchTerm.length > 3) {
        var userID = this.props.userID
        var body = new FormData()
        if (userID) {
            body.append('customer_id', userID)
        }
        body.append('search', this.state._mainSlug)
        body.append('paginate', this.state.show_product)
        body.append('device_id', this.state.uniueId)
        body.append('page', this.state._page)

        var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/search/product', body, "Error => v1/search api.")
        if (res && res.success == 1) {
            // console.log(res.records.data)
            this.setState({
                _searchRecord: res.records.data,
                _totalProduct: res.records.total,
                _record: res.records.data
                // __isredirest: true
            })
            var tmp = []
            for (let i = 0; i < res.records.last_page; i++) {
                tmp.push(i)
            }
            this.setState({ _noOfPage: tmp })
            // this.forceUpdate()
        }

        // }


    }
    handlePagination = async (value2) => {
        var value = value2.selected + 1
        if (value <= this.state._noOfPage.length) {
            await this.setState({ _page: value })

            this._search()
        }

    }
    _getProductBySlug = (slug, from) => {

        var userID = this.props.userID

        this.setState({ loading_getProductBySlug: true }, async () => {

            var body = new FormData()
            body.append('customer_id', userID)
            body.append('paginate', this.state.show_product)

            // if(this.state.show_product === 0)
            // {
            //     body.append('paginate', 12)
            // }
            // else
            // {
            //     body.append('paginate', this.state.show_product)
            // }

            var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/product/'.concat(slug), userID ? body : null, 'Error => _getProductBySlug api')
            if (res && res.success == 1) {
                this.setState({
                    arr_getProductBySlug: res.records.data,
                    loading_getProductBySlug: false
                })
            }
            else {
                this.setState({
                    loading_getProductBySlug: false
                })
            }

        })

    }

    changeShowValue = async (e,) => {
        this.setState({
            _selectedPage: e.target.value
        })

        if (e.target.value == 0) {
            await this.setState({ show_product: this.state._per_page })

        } else if (e.target.value == 1) {
            await this.setState({ show_product: this.state._per_page * 4.166666666666667 })

        } else if (e.target.value == 2) {
            await this.setState({ show_product: this.state._per_page * 8.3333333333333333 })

        } else if (e.target.value == 3) {
            await this.setState({ show_product: this.state._per_page * 12.5 })

        }


        this._search()
    }

    _getFilterList = async () => {

        var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(`v1/product-filter/apparels`, "Error")
        if (res && res.success == 1) {
            this.setState({
                arr_sizes: res.records.sizes,
                arr_colour: res.records.colour,
                arr_category: res.records.category,
                arr_brand: res.records.brand,
                arr_occasion: res.records.occasion,
                value: { min: res.records.startprice, max: res.records.endprice },
                startprice: res.records.startprice,
                endprice: res.records.endprice
            })
        }
        else {

        }
    }


    _searchUponSelectedFilter = async () => {
        var { filterSizeID, filterColorSlug, filterBrand, filterOccasion, filterCategory, isLatest, isOldest, isHighToLow, isLowToHigh, value } = this.state

        var body = new FormData()

        if (isLatest) {
            body.append('latest', 1)
        }
        if (isOldest) {
            body.append('oldest', 1)
        }
        if (filterColorSlug.length > 0) {
            var CSVColor = filterColorSlug.map((item) => { return item.slug }).join(",")
            body.append('colors', CSVColor)
        }
        if (filterSizeID.length > 0) {
            var CSVSize = filterSizeID.map((item) => { return item.id }).join(",")
            body.append('sizes', CSVSize)
        }
        if (filterCategory.length > 0) {
            var CSVCategory = filterCategory.map((item) => { return item.slug }).join(",")
            body.append('category', CSVCategory)
        }
        if (filterBrand.length > 0) {
            var CSVBrand = filterBrand.map((item) => { return item.name }).join(",")
            body.append('brand', CSVBrand)
        }
        if (filterOccasion.length > 0) {
            var CSVOccation = filterOccasion.map((item) => { return item.name }).join(",")
            body.append('occasion', CSVOccation)
        }
        if (isHighToLow) {
            body.append('price_low_to_high', 1)
        }
        if (isLowToHigh) {
            body.append('price_high_to_low', 1)
        }
        if (value.min > 0) {
            body.append('startprice', value.min)
        }
        if (value.max !== 0) {
            body.append('endprice', value.max)
        }


        for (var pair of body.entries()) {
        }

        var res = await NaswizHelperApi.POST_WITHOUT_TOKEN(`v1/product/${this.state.slug}`, body, "Error => filter products api.")

        if (res && res.success) {
            this.setState({
                arr_getProductBySlug: res.records.data
            })
        }
        else {
            Notification('error', 'Error!', 'No search result found!')
        }

    }

    compareItem(filterArray, checkedItem) {

        for (var i = 0; i < filterArray.length; i++) {

            if (filterArray[i].id == checkedItem.id) {
                return i;
            }
        }
        return -1;
    }
    onSizeFilterChange = async (checkedItem) => {
        var addedItemIndex = this.compareItem(this.state.filterSizeID, checkedItem)

        var temp = this.state.filterSizeID


        var newArroption = [];
        if (addedItemIndex == -1) {
            temp.push(checkedItem)
            newArroption = [...this.state._checkedSizeList, checkedItem.name];
            await this.setState({ filterSizeID: temp, _checkedSizeList: newArroption })

        } else {
            var newArr = temp.filter(a => a.id !== checkedItem.id);
            var newArrChecked = this.state._checkedSizeList.filter(a => a !== checkedItem.name);
            await this.setState({ filterSizeID: newArr, _checkedSizeList: newArrChecked })
        }


        this._searchUponSelectedFilter()




    }

    onColorFilterChange = async (checkedItem) => {


        var addedItemIndex = this.compareItem(this.state.filterColorSlug, checkedItem)

        var temp = this.state.filterColorSlug


        var newArroption = [];
        if (addedItemIndex == -1) {
            temp.push(checkedItem)
            newArroption = [...this.state._checkedColorList, checkedItem.name];

            await this.setState({ filterColorSlug: temp, _checkedColorList: newArroption })

        } else {
            var newArr = temp.filter(a => a.id !== checkedItem.id);
            var newArrChecked = this.state._checkedColorList.filter(a => a !== checkedItem.name);
            await this.setState({ filterColorSlug: newArr, _checkedColorList: newArrChecked })
        }
        this._searchUponSelectedFilter()

    }

    onCategoryFilterChange = async (checkedItem) => {

        var addedItemIndex = this.compareItem(this.state.filterCategory, checkedItem)

        var temp = this.state.filterCategory


        var newArroption = [];
        if (addedItemIndex == -1) {
            temp.push(checkedItem)
            newArroption = [...this.state._checkedCategoryList, checkedItem.name];

            await this.setState({ filterCategory: temp, _checkedCategoryList: newArroption })

        } else {
            var newArr = temp.filter(a => a.id !== checkedItem.id);
            var newArrChecked = this.state._checkedCategoryList.filter(a => a !== checkedItem.name);
            await this.setState({ filterCategory: newArr, _checkedCategoryList: newArrChecked })
        }

        this._searchUponSelectedFilter()


    }

    onBrandFilterChange = async (checkedItem) => {

        var addedItemIndex = this.compareItem(this.state.filterBrand, checkedItem)

        var temp = this.state.filterBrand

        var newArroption = [];
        if (addedItemIndex == -1) {
            temp.push(checkedItem)
            newArroption = [...this.state._checkedBrandList, checkedItem.name];

            await this.setState({ filterBrand: temp, _checkedBrandList: newArroption })

        } else {
            var newArr = temp.filter(a => a.id !== checkedItem.id);
            var newArrChecked = this.state._checkedBrandList.filter(a => a !== checkedItem.name);
            await this.setState({ filterBrand: newArr, _checkedBrandList: newArrChecked })
        }
        this._searchUponSelectedFilter()

    }

    onOccationFilterChange = async (checkedItem) => {



        var addedItemIndex = this.compareItem(this.state.filterOccasion, checkedItem)

        var temp = this.state.filterOccasion


        var newArroption = [];
        if (addedItemIndex == -1) {
            temp.push(checkedItem)
            newArroption = [...this.state._checkedOccasionList, checkedItem.name];

            await this.setState({ filterOccasion: temp, _checkedOccasionList: newArroption })

        } else {
            var newArr = temp.filter(a => a.id !== checkedItem.id);
            var newArrChecked = this.state._checkedOccasionList.filter(a => a !== checkedItem.name);
            await this.setState({ filterOccasion: newArr, _checkedOccasionList: newArrChecked })
        }

        this._searchUponSelectedFilter()
    }
    async clearFilterOne(item) {
        var newArrChecked = this.state._checkedSizeList.filter(a => a !== item.name);

        await this.setState({ _checkedSizeList: newArrChecked })

    }
    async clearFilterColor(item) {
        var newArrChecked = this.state._checkedColorList.filter(a => a !== item.name);

        await this.setState({ _checkedColorList: newArrChecked })

    }

    async clearFilterCategory(item) {
        var newArrChecked = this.state._checkedCategoryList.filter(a => a !== item.name);

        await this.setState({ _checkedCategoryList: newArrChecked })

    }

    async clearFilterBrand(item) {
        var newArrChecked = this.state._checkedBrandList.filter(a => a !== item.name);

        await this.setState({ _checkedBrandList: newArrChecked })


    }

    async clearFilterOccasion(item) {
        var newArrChecked = this.state._checkedOccasionList.filter(a => a !== item.name);

        await this.setState({ _checkedOccasionList: newArrChecked })


    }

    _resetFilterAndData = () => {
        // this._getProductBySlug(this.state.slug)
        this.setState({
            value: { min: 0, max: 0 },
            filterSizeID: [],
            filterColorSlug: [],
            filterBrand: [],
            filterOccasion: [],
            filterCategory: [],
            priceLow: null,
            priceHigh: null,
            isLowToHigh: false,
            isHighToLow: false,
            isOldest: false,
            isLatest: false,
            _checkedBrandList: [],
            _checkedCategoryList: [],
            _checkedOccasionList: [],
            _checkedSizeList: [],
            _checkedColorList: [],
        })
    }

    render() {
        return (
            <div>
                <Seo title={this.state._mainSlug} description={this.state._mainSlug} />
                <div className="container">
                    <div className="promo-section mb-2 bg-dark" style={{ padding: '0px', height: 'unset' }} >
                        <img src={`${process.env.PUBLIC_URL}/assets/images/all-category-banner.jpg`} alt="" className="img-fluid" />

                    </div>
                </div>
                <div className="container">
                    {/* <nav aria-label="breadcrumb" className="breadcrumb-nav">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link
                                to={{ pathname: `${process.env.PUBLIC_URL}/` }}
                            >Home</Link></li>
                        </ol>
                    </nav> */}

                    <div className="category-navs__current" >Showing <strong>{this.state._totalProduct}</strong> results for <strong>“ {this.state._mainSlug} ”</strong> from Super Store</div>
                    {
                        this.state._totalProduct > 0 ?
                            <div className="row">
                                <div className="col-lg-12 main-content">
                                    <nav className="toolbox">
                                        <div className="toolbox-left">
                                            <div className="toolbox-item toolbox-sort">
                                                <label>Sort By:</label>

                                                <div className="select-custom">
                                                    <select name="orderby" className="form-control">
                                                        <option value="menu_order" defaultValue="selected">Default</option>
                                                        <option value="popularity">Popularity</option>
                                                        <option value="rating">Average rating</option>
                                                        <option value="date">Newness</option>
                                                        <option value="price">Price: low to high</option>
                                                        <option value="price-desc">Price: high to low</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="toolbox-right">
                                            <div className="toolbox-item toolbox-show">
                                                <label>Show:</label>
                                                <div className="select-custom">
                                                    <select name="count" className="form-control"
                                                        value={this.state._selectedPage}
                                                        onChange={(e) => this.changeShowValue(e)}>
                                                        <option value={0}>{this.state._per_page}</option>
                                                        <option value={1}>{this.state._per_page * 4}</option>
                                                        <option value={2}>{this.state._per_page * 8}</option>
                                                        <option value={3}>{this.state._per_page * 12.5}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                    <div className="row" style={{ marginRight: '0px', marginLeft: '0px' }}>
                                        {
                                            this.state._searchRecord && this.state._searchRecord.length > 0 ?

                                                <ProductListing
                                                    colSize={this.state.layoutColumns}
                                                    product={this.state._record}
                                                    // records={this.props.productslist.records.data}
                                                    records={this.state._searchRecord}
                                                    mainSlug={this.state._mainSlug}
                                                    // breadcrum={this.state._searchRecord}
                                                    updateDataAfterAction={() => this._search()}
                                                />
                                                :
                                                <div className="nodatafound">
                                                    <span>Sorry, no results found!</span>
                                                    <p>Please check the spelling or try searching for something else</p>
                                                </div>


                                        }
                                    </div>
                                    <nav className="toolbox toolbox-pagination">
                                        <div className="toolbox-item toolbox-show">
                                            <label>Show:</label>
                                            <div className="select-custom">
                                                <select name="count" className="form-control"
                                                    value={this.state._selectedPage}
                                                    onChange={(e) => this.changeShowValue(e)}>
                                                    <option value={0}>{this.state._per_page}</option>
                                                    <option value={1}>{this.state._per_page * 4}</option>
                                                    <option value={2}>{this.state._per_page * 8}</option>
                                                    <option value={3}>{this.state._per_page * 12.5}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="" >
                                            {this.state._noOfPage.length > 1 ?
                                                <ReactPaginate
                                                    previousLabel={'<'}
                                                    nextLabel={'>'}
                                                    breakLabel={'...'}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link'}
                                                    pageCount={this.state._noOfPage.length}
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={(page) => this.handlePagination(page)}
                                                    containerClassName={'pagination toolbox-item'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link'}
                                                    activeClassName={'active'}
                                                />
                                                : null}
                                        </div>
                                    </nav>

                                </div>

                                <div className="sidebar-overlay"></div>
                                <div className="sidebar-toggle"><i className="fas fa-sliders-h"></i></div>

                            </div>
                            :
                            <div className="nodatafound">
                                <span>Sorry, no results found!</span>
                                <p>Please check the spelling or try searching for something else</p>

                            </div>
                    }
                </div>
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        allState: state,
        userID: state.login.userID,
        cartListArray: state.login.userCartData.records.cart_list

    }
}

export default connect(mapStateToProps, null)(ProductSearch)