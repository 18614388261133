import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import Breadcrumb from "../common/breadcrumb";
import NewProduct from "../common/new-product";
import Filter from "./common/filter";
import FilterBar from "./common/filter-bar";
import ProductListing from "./common/product-listing";
import StickyBox from "react-sticky-box";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { getProductBySlug, getFilterBySlug } from '../../actions'
import { Helper } from '../../helper';
import { NaswizHelperApi } from '../../NaswizHelper';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import InputRange from 'react-input-range';
import './common/collection.css';
import Seo from '../Seo';

const FeaturedSlider = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
}

const MAXVALUE = 1000;
const MINVALUE = 100;

class CollectionLeftSidebar extends Component {


    constructor(props) {
        super(props);
        this.state = {
            slugs: props.match.params.id || 'unknown',
            layoutColumns: 3,
            searchResultArray: props.location.state,
            // 
            loading_getAllFilterData: false,
            loading_getProductBySlug: false,
            arr_getProductBySlug: [],
            value: { min: 100, max: 400 },
        }
    }

    componentDidMount() {   
        this._getProductBySlug(this.state.slugs, 'Did mount')
    }

    componentWillReceiveProps(nextProps) {
        this._getProductBySlug(nextProps, '');
    }

    _getProductBySlug = (slug, from) => {

        var userID = this.props.userID
        this.setState({ loading_getProductBySlug: true }, async () => {

            var body = new FormData()
            body.append('customer_id', userID)

            var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/product/'.concat(slug), userID ? body : null, 'Error => _getProductBySlug api')
            if (res && res.success === 1) {
                this.setState({
                    arr_getProductBySlug: res.records.data,
                    loading_getProductBySlug: false
                })
            }
            else {
                this.setState({
                    loading_getProductBySlug: false
                })
            }
        })
    }

    getAllFilterData = () => {

        this.setState({ loading_getAllFilterData: true }, async () => {

            var res = await Helper.POST('v1/product-filter/'.concat(this.state.slugs), null, "Error => getAllFilterData api.")
            if (res && res.success === 1) {
                this.setState({ loading_getAllFilterData: false })
            }
            else {
                this.setState({ loading_getAllFilterData: false })
            }
        })
    }


    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns: colums
        })
    }

    openFilter = () => {
        document.querySelector(".collection-filter").style = "left: -15px";
    }

    render() {
        var { arr_getProductBySlug } = this.state
        if (this.state.searchResultArray) {
            return (
                <div>

                    {/*SEO Support*/}
                    {/* <Helmet> 
                        <title>Search Result - Naswiz Retail Pvt Ltd</title>
                        <meta name="description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is already a direct seller in this company." />
                    </Helmet> */}
                    <Seo title="Search Result" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />

                    {/*SEO Support End */}

                    <Breadcrumb title={'Search Result'} />

                    <section className="section-b-space">
                        <div className="collection-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-3 collection-filter">

                                        <StickyBox offsetTop={20} offsetBottom={20}>
                                            <div>
                                                <Filter />
                                                <NewProduct />
                                                <div className="collection-sidebar-banner">
                                                    <a href="#">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/side-banner.png`} className="img-fluid" alt="" />
                                                    </a>
                                                </div>
                                            </div>
                                        </StickyBox>
                                        {/*side-bar banner end here*/}
                                    </div>
                                    <div className="collection-content">
                                        <div className="page-main-content ">
                                            <div className="">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="top-banner-wrapper">
                                                            {/* <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/mega-menu/2.jpg`} className="img-fluid" alt=""/></a> */}
                                                            <div className="top-banner-content small-section">
                                                            </div>
                                                        </div>
                                                        <div className="collection-product-wrapper">
                                                            <div className="product-top-filter" style={{ borderTop: '0px !important', borderBottom: '0px !important' }}>
                                                                <div className="container-fluid p-0">
                                                                    <div className="row">
                                                                        <div className="col-xl-12">
                                                                            <div className="filter-main-btn">
                                                                                <span onClick={this.openFilter}
                                                                                    className="filter-btn btn btn-theme"><i
                                                                                        className="fa fa-filter"
                                                                                        aria-hidden="true"></i> Filter</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <FilterBar onLayoutViewClicked={(colmuns) => this.LayoutViewClicked(colmuns)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/*Products Listing Component*/}
                                                            <ProductListing colSize={this.state.layoutColumns} records={this.state.searchResultArray} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            )
        }
        // else if (Object.keys(this.props.productslist).length != 0) {
        else if (this.state.arr_getProductBySlug.length > 0) {
            return (
                <div>
                    <Helmet>
                        <title>Collection of Products - Naswiz Retail Pvt Ltd</title>
                        <meta name="description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is already a direct seller in this company." />
                    </Helmet>
                    <div className="promo-section mb-5" data-parallax="{'speed': 1.8, 'enableOnMobile': true}" style={{ backgroundColor: '#22252A' }} data-image-src={`${process.env.PUBLIC_URL}/assets/images/banners/banner-5.jpg`}>
                        <div className="promo-content">
                            <h2 className="m-b-1">New Season Sale</h2>
                            <h3 className="white_txt">40% OFF</h3>
                            <hr className="divider-short-thick"></hr>
                            <a href="#" className="btn btn-light ls-n-25">Shop Now <i className="fas fa-long-arrow-alt-right ml-2"></i></a>
                        </div>
                    </div>
                    <div className="container">
                        <nav aria-label="breadcrumb" className="breadcrumb-nav">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="index.php">Home</a></li>
                                <li className="breadcrumb-item"><a href="#">Men</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Accessories</li>
                            </ol>
                        </nav>
                        <div className="row">
                            <div className="col-lg-9 main-content">
                                <nav className="toolbox">
                                    <div className="toolbox-left">
                                        <div className="toolbox-item toolbox-sort">
                                            <label>Sort By:</label>

                                            <div className="select-custom">
                                                <select name="orderby" className="form-control">
                                                    <option value="menu_order" defaultValue="selected">Default sorting</option>
                                                    <option value="popularity">Sort by popularity</option>
                                                    <option value="rating">Sort by average rating</option>
                                                    <option value="date">Sort by newness</option>
                                                    <option value="price">Sort by price: low to high</option>
                                                    <option value="price-desc">Sort by price: high to low</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="toolbox-right">
                                        <div className="toolbox-item toolbox-show">
                                            <label>Show:</label>
                                            <div className="select-custom">
                                                <select name="count" className="form-control">
                                                    <option value="48">48</option>
                                                    <option value="96">96</option>
                                                    <option value="144">144</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </nav>

                                {/* Product Listing  */}
                                <div className="row">                                   

                                    <ProductListing
                                        colSize={this.state.layoutColumns}
                                        // records={this.props.productslist.records.data}
                                        records={this.state.arr_getProductBySlug}
                                        updateDataAfterAction={() => this._getProductBySlug(this.state.slugs, 'Inner Component')}
                                    />


                                </div>

                                <nav className="toolbox toolbox-pagination">
                                    <div className="toolbox-item toolbox-show">
                                        <label>Show:</label>

                                        <div className="select-custom">
                                            <select name="count" className="form-control">
                                                <option value="48">48</option>
                                                <option value="96">96</option>
                                                <option value="144">144</option>
                                            </select>
                                        </div>
                                    </div>

                                    <ul className="pagination toolbox-item">
                                        <li className="page-item disabled">
                                            <a className="page-link page-link-btn" href="#"><i className="icon-angle-left"></i></a>
                                        </li>
                                        <li className="page-item active">
                                            <a className="page-link" href="#">1 <span className="sr-only">(current)</span></a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item"><a className="page-link" href="#">4</a></li>
                                        <li className="page-item"><a className="page-link" href="#">5</a></li>
                                        <li className="page-item"><span className="page-link">...</span></li>
                                        <li className="page-item">
                                            <a className="page-link page-link-btn" href="#"><i className="icon-angle-right"></i></a>
                                        </li>
                                    </ul>
                                </nav>

                            </div>
                            <div className="sidebar-overlay"></div>
                            <div className="sidebar-toggle"><i className="fas fa-sliders-h"></i></div>

                            <aside className="sidebar-shop col-lg-3 order-lg-first mobile-sidebar">
                                {/* <StickyBox offsetTop={20} offsetBottom={20}> */}
                                <div className="sidebar-wrapper">
                                    <div className="widget">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-1" role="button" aria-expanded="true" aria-controls="widget-body-1">Categories</a>
                                        </h3>
                                        <div className="collapse show" id="widget-body-1">
                                            <div className="widget-body">
                                                <ul className="cat-list">
                                                    <li><a href="#">Accessories</a></li>
                                                    <li><a href="#">Watch Fashion</a></li>
                                                    <li><a href="#">Tees, Knits &amp; Polos</a></li>
                                                    <li><a href="#">Pants &amp; Denim</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="widget">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-2" role="button" aria-expanded="true" aria-controls="widget-body-2">Flavor</a>
                                        </h3>
                                        <div className="collapse show" id="widget-body-2">
                                            <div className="widget-body">
                                                <ul className="cat-list">
                                                    <li><a href="#">Flavor1</a></li>
                                                    <li><a href="#">Flavor2</a></li>
                                                    <li><a href="#">Flavor3</a></li>
                                                    <li><a href="#">Flavor4</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="widget">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-3" role="button" aria-expanded="true" aria-controls="widget-body-3">Quantity</a>
                                        </h3>
                                        <div className="collapse show" id="widget-body-3">
                                            <div className="widget-body">
                                                <ul className="cat-list">
                                                    <li><a href="#">1 Kg</a></li>
                                                    <li><a href="#">2 Kg</a></li>
                                                    <li><a href="#">3 Kg</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="widget">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-4" role="button" aria-expanded="true" aria-controls="widget-body-4">Price</a>
                                        </h3>
                                        <div className="collapse show" id="widget-body-4">
                                            <div className="widget-body">
                                                <form action="#">
                                                    <div className="price-slider-wrapper"> 
                                                        <InputRange
                                                            maxValue={MAXVALUE}
                                                            minValue={MINVALUE}
                                                            formatLabel={value => `$${value}`}
                                                            value={this.state.value}
                                                            onChange={value => this.setState({ value })} />
                                                    </div>
                                                    <div className="filter-price-action d-flex align-items-center justify-content-between flex-wrap">
                                                        <button type="submit" className="btn theme-button">Filter</button>
                                                        <div className="filter-price-text">
                                                            Price:
                                                            <span id="filter-price-range"></span>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="widget">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-5" role="button" aria-expanded="true" aria-controls="widget-body-5">Size</a>
                                        </h3>
                                        <div className="collapse show" id="widget-body-5">
                                            <div className="widget-body">
                                                <ul className="cat-list">
                                                    <li><a href="#">Small</a></li>
                                                    <li><a href="#">Medium</a></li>
                                                    <li><a href="#">Large</a></li>
                                                    <li><a href="#">Extra Large</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="widget">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-6" role="button" aria-expanded="true" aria-controls="widget-body-6">Brand</a>
                                        </h3>
                                        <div className="collapse show" id="widget-body-6">
                                            <div className="widget-body">
                                                <ul className="cat-list">
                                                    <li><a href="#">Adidas</a></li>
                                                    <li><a href="#">Calvin Klein (26)</a></li>
                                                    <li><a href="#">Diesel (3)</a></li>
                                                    <li><a href="#">Lacoste (8)</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="widget">
                                        <h3 className="widget-title">
                                            <a data-toggle="collapse" href="#widget-body-7" role="button" aria-expanded="true" aria-controls="widget-body-7">Color</a>
                                        </h3>
                                        <div className="collapse show" id="widget-body-7">
                                            <div className="widget-body">
                                                <ul className="config-swatch-list">
                                                    <li className="active">
                                                        <a href="#" style={{ backgroundColor: '#000000' }}></a>
                                                        <span>Black</span>
                                                    </li>
                                                    <li>
                                                        <a href="#" style={{ backgroundColor: '#0188cc' }}></a>
                                                        <span>Blue</span>
                                                    </li>
                                                    <li>
                                                        <a href="#" style={{ backgroundColor: '#81d742' }}></a>
                                                        <span>Green</span>
                                                    </li>
                                                    <li>
                                                        <a href="#" style={{ backgroundColor: '#6085a5' }}></a>
                                                        <span>Indigo</span>
                                                    </li>
                                                    <li>
                                                        <a href="#" style={{ backgroundColor: '#ab6e6e' }}></a>
                                                        <span>Red</span>
                                                    </li>
                                                    <li>
                                                        <a href="#" style={{ backgroundColor: '#ddb373' }}></a>
                                                        <span>Brown</span>
                                                    </li>
                                                    <li>
                                                        <a href="#" style={{ backgroundColor: '#6b97bf' }}></a>
                                                        <span>Light-Blue</span>
                                                    </li>
                                                    <li>
                                                        <a href="#" style={{ backgroundColor: '#eded68' }}></a>
                                                        <span>Yellow</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="widget widget-featured">
                                        <h3 className="widget-title">Featured</h3>

                                        <div className="widget-body">
                                            <Slider className="widget-featured-products" {...FeaturedSlider}>

                                                <div className="featured-col">
                                                    <div className="product-default left-details product-widget">
                                                        <figure>
                                                            <a href="product.php">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/products/product-10.jpg`} alt="" />
                                                            </a>
                                                        </figure>
                                                        <div className="product-details">
                                                            <h2 className="product-title">
                                                                <a href="product.php">Product Short Name</a>
                                                            </h2>
                                                            <div className="ratings-container">
                                                                <div className="product-ratings">
                                                                    <span className="ratings" style={{ width: '100%' }}></span>
                                                                    <span className="tooltiptext tooltip-top"></span>
                                                                </div>
                                                            </div>
                                                            <div className="price-box">
                                                                <span className="product-price">₹49.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product-default left-details product-widget">
                                                        <figure>
                                                            <a href="product.php">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/products/product-11.jpg`} alt="" />
                                                            </a>
                                                        </figure>
                                                        <div className="product-details">
                                                            <h2 className="product-title">
                                                                <a href="product.php">Product Short Name</a>
                                                            </h2>
                                                            <div className="ratings-container">
                                                                <div className="product-ratings">
                                                                    <span className="ratings" style={{ width: '100%' }}></span>
                                                                    <span className="tooltiptext tooltip-top"></span>
                                                                </div>
                                                            </div>
                                                            <div className="price-box">
                                                                <span className="product-price">₹49.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product-default left-details product-widget">
                                                        <figure>
                                                            <a href="product.php">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/products/product-10.jpg`} alt="" />
                                                            </a>
                                                        </figure>
                                                        <div className="product-details">
                                                            <h2 className="product-title">
                                                                <a href="product.php">Product Short Name</a>
                                                            </h2>
                                                            <div className="ratings-container">
                                                                <div className="product-ratings">
                                                                    <span className="ratings" style={{ width: '100%' }}></span>
                                                                    <span className="tooltiptext tooltip-top"></span>
                                                                </div>
                                                            </div>
                                                            <div className="price-box">
                                                                <span className="product-price">₹49.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="featured-col">
                                                    <div className="product-default left-details product-widget">
                                                        <figure>
                                                            <a href="product.php">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/products/product-13.jpg`} alt="" />
                                                            </a>
                                                        </figure>
                                                        <div className="product-details">
                                                            <h2 className="product-title">
                                                                <a href="product.php">Product Short Name</a>
                                                            </h2>
                                                            <div className="ratings-container">
                                                                <div className="product-ratings">
                                                                    <span className="ratings" style={{ width: '100%' }}></span>
                                                                    <span className="tooltiptext tooltip-top"></span>
                                                                </div>
                                                            </div>
                                                            <div className="price-box">
                                                                <span className="product-price">₹49.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product-default left-details product-widget">
                                                        <figure>
                                                            <a href="product.php">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/products/product-14.jpg`} alt="" />
                                                            </a>
                                                        </figure>
                                                        <div className="product-details">
                                                            <h2 className="product-title">
                                                                <a href="product.php">Product Short Name</a>
                                                            </h2>
                                                            <div className="ratings-container">
                                                                <div className="product-ratings">
                                                                    <span className="ratings" style={{ width: '100%' }}></span>
                                                                    <span className="tooltiptext tooltip-top"></span>
                                                                </div>
                                                            </div>
                                                            <div className="price-box">
                                                                <span className="product-price">₹49.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product-default left-details product-widget">
                                                        <figure>
                                                            <a href="product.php">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/products/product-8.jpg`} alt="" />
                                                            </a>
                                                        </figure>
                                                        <div className="product-details">
                                                            <h2 className="product-title">
                                                                <a href="product.php">Product Short Name</a>
                                                            </h2>
                                                            <div className="ratings-container">
                                                                <div className="product-ratings">
                                                                    <span className="ratings" style={{ width: '100%' }}></span>
                                                                    <span className="tooltiptext tooltip-top"></span>
                                                                </div>
                                                            </div>
                                                            <div className="price-box">
                                                                <span className="product-price">₹49.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                                {/* </StickyBox> */}
                            </aside>
                        </div>
                    </div>
                    <div className="mb-3"></div>
                </div>
            )
        }
        else {
            return (<div></div>)
        }
    }
}


const mapStateToProps = (state) => ({
    productslist: state.productListData.productslist,
    symbol: '₹ ',
    userID: state.login.userID,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getProductBySlug: (slug) => getProductBySlug(slug),
    getFilterBySlug: (slug) => getFilterBySlug(slug),
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CollectionLeftSidebar);

const dummyArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]

///////////////////////////////////////////////////////////////////////
////////////////////// functional component ///////////////////////////
///////////////////////////////////////////////////////////////////////

// import React, { useState,useEffect } from 'react';
// import { Helmet } from 'react-helmet'
// import Breadcrumb from "../common/breadcrumb";
// import NewProduct from "../common/new-product";
// import Filter from "./common/filter";
// import FilterBar from "./common/filter-bar";
// import ProductListing from "./common/product-listing";
// import StickyBox from "react-sticky-box";
// import { connect } from "react-redux";
// import { bindActionCreators } from 'redux';
// import { addToCart, addToCartUnsafe, addToWishlist, getProductBySlug, getFilterBySlug } from '../../actions'
// import { Helper } from '../../helper';
// import { NaswizHelperApi } from '../../NaswizHelper';
// import Slider from 'react-slick';
// import { Link } from 'react-router-dom';
// import InputRange from 'react-input-range';
// import './common/collection.css';

// const FeaturedSlider = {
//     dots: false,
//     infinite: true,
//     speed: 3000,
//     slidesToShow: 1,
//     slidesToScroll: 1,
// }

// const MAXVALUE = 1000;
// const MINVALUE = 100;

// const CollectionLeftSidebar =(props)=>  {

//     const [slugs, setSlugs] = useState('unknown')
//     const [layoutColumns, setlayoutColumns] = useState(3)
//     const [searchResultArray, setsearchResultArray] = useState(props.location.state)
//     const [loading_getAllFilterData, setLoading_getAllFilterData] = useState(false)
//     const [loading_getProductBySlug, setLoading_getProductBySlug] = useState(false)
//     const [arr_getProductBySlug, setArr_getProductBySlug] = useState([])
//     const [value, setValue] = useState({min:100,max:400})

//         // this.state = {
//         //     slugs: props.match.params.id || 'unknown',
//         //     layoutColumns: 3,
//         //     searchResultArray: props.location.state,
//         //     loading_getAllFilterData: false,
//         //     loading_getProductBySlug: false,
//         //     arr_getProductBySlug: [],
//         //     value: { min: 100, max: 400 },
//         // }
    
//     useEffect(() => {
//         _getProductBySlug(slugs, 'Did mount')
//     },[''])
    
//     // componentDidMount() {   
//     //     this._getProductBySlug(this.state.slugs, 'Did mount')
//     // }
//     useEffect((nextProps) => {
//         _getProductBySlug(nextProps, '');
//     }, [''])
    

//     // componentWillReceiveProps(nextProps) {
//     //     this._getProductBySlug(nextProps, '');
//     // }

//     const _getProductBySlug =async (slug, from) => {

//         // var userID = this.props.userID
//          setLoading_getProductBySlug(true) 

//             var body = new FormData()
//             body.append('customer_id', props.userID)

//             var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/product/'.concat(slug), props.userID ? body : null, 'Error => _getProductBySlug api')
//             if (res && res.success === 1) {
//                     setArr_getProductBySlug(res.records.data)
//                     setLoading_getProductBySlug(false)
//             }
//             else {
//                     setLoading_getProductBySlug(false)
//             }
       
//     }

//     const getAllFilterData = async() => {

//         setLoading_getAllFilterData(true)

//             var res = await Helper.POST('v1/product-filter/'.concat(slugs), null, "Error => getAllFilterData api.")
//             if (res && res.success === 1) {
//                 setLoading_getAllFilterData(false) 
//             }
//             else {
//                  setLoading_getAllFilterData(false) 
//             }
//     }


//     const LayoutViewClicked= (colums)=> {
//             setlayoutColumns(colums)
//     }

//     const openFilter = () => {
//         document.querySelector(".collection-filter").style = "left: -15px";
//     }

//         // var { arr_getProductBySlug } = this.state
//         if (searchResultArray) {
//             return (
//                 <div>

//                     {/*SEO Support*/}
//                     <Helmet> 
//                         <title>Search Result - Naswiz Retail Pvt Ltd</title>
//                         <meta name="description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is already a direct seller in this company." />
//                     </Helmet>
//                     {/*SEO Support End */}

//                     <Breadcrumb title={'Search Result'} />

//                     <section className="section-b-space">
//                         <div className="collection-wrapper">
//                             <div className="container">
//                                 <div className="row">
//                                     <div className="col-sm-3 collection-filter">

//                                         <StickyBox offsetTop={20} offsetBottom={20}>
//                                             <div>
//                                                 <Filter />
//                                                 <NewProduct />
//                                                 <div className="collection-sidebar-banner">
//                                                     <a href="/">
//                                                         <img src={`${process.env.PUBLIC_URL}/assets/images/side-banner.png`} className="img-fluid" alt="" />
//                                                     </a>
//                                                 </div>
//                                             </div>
//                                         </StickyBox>
//                                         {/*side-bar banner end here*/}
//                                     </div>
//                                     <div className="collection-content">
//                                         <div className="page-main-content ">
//                                             <div className="">
//                                                 <div className="row">
//                                                     <div className="col-sm-12">
//                                                         <div className="top-banner-wrapper">
//                                                             {/* <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/mega-menu/2.jpg`} className="img-fluid" alt=""/></a> */}
//                                                             <div className="top-banner-content small-section">
//                                                             </div>
//                                                         </div>
//                                                         <div className="collection-product-wrapper">
//                                                             <div className="product-top-filter" style={{ borderTop: '0px !important', borderBottom: '0px !important' }}>
//                                                                 <div className="container-fluid p-0">
//                                                                     <div className="row">
//                                                                         <div className="col-xl-12">
//                                                                             <div className="filter-main-btn">
//                                                                                 <span onClick={openFilter}
//                                                                                     className="filter-btn btn btn-theme"><i
//                                                                                         className="fa fa-filter"
//                                                                                         aria-hidden="true"></i> Filter</span>
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>
//                                                                     <div className="row">
//                                                                         <div className="col-12">
//                                                                             <FilterBar onLayoutViewClicked={(colmuns) => LayoutViewClicked(colmuns)} />
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>

//                                                             {/*Products Listing Component*/}
//                                                             <ProductListing colSize={layoutColumns} records={searchResultArray} />

//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </section>

//                 </div>
//             )
//         }
//         // else if (Object.keys(this.props.productslist).length != 0) {
//         else if (arr_getProductBySlug.length > 0) {
//             return (
//                 <div>
//                     <Helmet>
//                         <title>Collection of Products - Naswiz Retail Pvt Ltd</title>
//                         <meta name="description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is already a direct seller in this company." />
//                     </Helmet>
//                     <div className="promo-section mb-5" data-parallax="{'speed': 1.8, 'enableOnMobile': true}" style={{ backgroundColor: '#22252A' }} data-image-src={`${process.env.PUBLIC_URL}/assets/images/banners/banner-5.jpg`}>
//                         <div className="promo-content">
//                             <h2 className="m-b-1">New Season Sale</h2>
//                             <h3 className="white_txt">40% OFF</h3>
//                             <hr className="divider-short-thick"></hr>
//                             <a href="#" className="btn btn-light ls-n-25">Shop Now <i className="fas fa-long-arrow-alt-right ml-2"></i></a>
//                         </div>
//                     </div>
//                     <div className="container">
//                         <nav aria-label="breadcrumb" className="breadcrumb-nav">
//                             <ol className="breadcrumb">
//                                 <li className="breadcrumb-item"><a href="index.php">Home</a></li>
//                                 <li className="breadcrumb-item"><a href="#">Men</a></li>
//                                 <li className="breadcrumb-item active" aria-current="page">Accessories</li>
//                             </ol>
//                         </nav>
//                         <div className="row">
//                             <div className="col-lg-9 main-content">
//                                 <nav className="toolbox">
//                                     <div className="toolbox-left">
//                                         <div className="toolbox-item toolbox-sort">
//                                             <label>Sort By:</label>

//                                             <div className="select-custom">
//                                                 <select name="orderby" className="form-control">
//                                                     <option value="menu_order" defaultValue="selected">Default sorting</option>
//                                                     <option value="popularity">Sort by popularity</option>
//                                                     <option value="rating">Sort by average rating</option>
//                                                     <option value="date">Sort by newness</option>
//                                                     <option value="price">Sort by price: low to high</option>
//                                                     <option value="price-desc">Sort by price: high to low</option>
//                                                 </select>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="toolbox-right">
//                                         <div className="toolbox-item toolbox-show">
//                                             <label>Show:</label>
//                                             <div className="select-custom">
//                                                 <select name="count" className="form-control">
//                                                     <option value="48">48</option>
//                                                     <option value="96">96</option>
//                                                     <option value="144">144</option>
//                                                 </select>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </nav>

//                                 {/* Product Listing  */}
//                                 <div className="row">                                   

//                                     <ProductListing
//                                         colSize={layoutColumns}
//                                         // records={this.props.productslist.records.data}
//                                         records={arr_getProductBySlug}
//                                         updateDataAfterAction={() => _getProductBySlug(slugs, 'Inner Component')}
//                                     />


//                                 </div>

//                                 <nav className="toolbox toolbox-pagination">
//                                     <div className="toolbox-item toolbox-show">
//                                         <label>Show:</label>

//                                         <div className="select-custom">
//                                             <select name="count" className="form-control">
//                                                 <option value="48">48</option>
//                                                 <option value="96">96</option>
//                                                 <option value="144">144</option>
//                                             </select>
//                                         </div>
//                                     </div>

//                                     <ul className="pagination toolbox-item">
//                                         <li className="page-item disabled">
//                                             <a className="page-link page-link-btn" href="#"><i className="icon-angle-left"></i></a>
//                                         </li>
//                                         <li className="page-item active">
//                                             <a className="page-link" href="#">1 <span className="sr-only">(current)</span></a>
//                                         </li>
//                                         <li className="page-item"><a className="page-link" href="#">2</a></li>
//                                         <li className="page-item"><a className="page-link" href="#">3</a></li>
//                                         <li className="page-item"><a className="page-link" href="#">4</a></li>
//                                         <li className="page-item"><a className="page-link" href="#">5</a></li>
//                                         <li className="page-item"><span className="page-link">...</span></li>
//                                         <li className="page-item">
//                                             <a className="page-link page-link-btn" href="#"><i className="icon-angle-right"></i></a>
//                                         </li>
//                                     </ul>
//                                 </nav>

//                             </div>
//                             <div className="sidebar-overlay"></div>
//                             <div className="sidebar-toggle"><i className="fas fa-sliders-h"></i></div>

//                             <aside className="sidebar-shop col-lg-3 order-lg-first mobile-sidebar">
//                                 {/* <StickyBox offsetTop={20} offsetBottom={20}> */}
//                                 <div className="sidebar-wrapper">
//                                     <div className="widget">
//                                         <h3 className="widget-title">
//                                             <a data-toggle="collapse" href="#widget-body-1" role="button" aria-expanded="true" aria-controls="widget-body-1">Categories</a>
//                                         </h3>
//                                         <div className="collapse show" id="widget-body-1">
//                                             <div className="widget-body">
//                                                 <ul className="cat-list">
//                                                     <li><a href="#">Accessories</a></li>
//                                                     <li><a href="#">Watch Fashion</a></li>
//                                                     <li><a href="#">Tees, Knits &amp; Polos</a></li>
//                                                     <li><a href="#">Pants &amp; Denim</a></li>
//                                                 </ul>
//                                             </div>
//                                         </div>
//                                     </div>

//                                     <div className="widget">
//                                         <h3 className="widget-title">
//                                             <a data-toggle="collapse" href="#widget-body-2" role="button" aria-expanded="true" aria-controls="widget-body-2">Flavor</a>
//                                         </h3>
//                                         <div className="collapse show" id="widget-body-2">
//                                             <div className="widget-body">
//                                                 <ul className="cat-list">
//                                                     <li><a href="#">Flavor1</a></li>
//                                                     <li><a href="#">Flavor2</a></li>
//                                                     <li><a href="#">Flavor3</a></li>
//                                                     <li><a href="#">Flavor4</a></li>
//                                                 </ul>
//                                             </div>
//                                         </div>
//                                     </div>

//                                     <div className="widget">
//                                         <h3 className="widget-title">
//                                             <a data-toggle="collapse" href="#widget-body-3" role="button" aria-expanded="true" aria-controls="widget-body-3">Quantity</a>
//                                         </h3>
//                                         <div className="collapse show" id="widget-body-3">
//                                             <div className="widget-body">
//                                                 <ul className="cat-list">
//                                                     <li><a href="#">1 Kg</a></li>
//                                                     <li><a href="#">2 Kg</a></li>
//                                                     <li><a href="#">3 Kg</a></li>
//                                                 </ul>
//                                             </div>
//                                         </div>
//                                     </div>

//                                     <div className="widget">
//                                         <h3 className="widget-title">
//                                             <a data-toggle="collapse" href="#widget-body-4" role="button" aria-expanded="true" aria-controls="widget-body-4">Price</a>
//                                         </h3>
//                                         <div className="collapse show" id="widget-body-4">
//                                             <div className="widget-body">
//                                                 <form action="#">
//                                                     <div className="price-slider-wrapper"> 
//                                                         <InputRange
//                                                             maxValue={MAXVALUE}
//                                                             minValue={MINVALUE}
//                                                             formatLabel={value => `$${value}`}
//                                                             value={value}
//                                                             onChange={value =>  setValue(value) } />
//                                                     </div>
//                                                     <div className="filter-price-action d-flex align-items-center justify-content-between flex-wrap">
//                                                         <button type="submit" className="btn theme-button">Filter</button>
//                                                         <div className="filter-price-text">
//                                                             Price:
//                                                             <span id="filter-price-range"></span>
//                                                         </div>
//                                                     </div>
//                                                 </form>
//                                             </div>
//                                         </div>
//                                     </div>

//                                     <div className="widget">
//                                         <h3 className="widget-title">
//                                             <a data-toggle="collapse" href="#widget-body-5" role="button" aria-expanded="true" aria-controls="widget-body-5">Size</a>
//                                         </h3>
//                                         <div className="collapse show" id="widget-body-5">
//                                             <div className="widget-body">
//                                                 <ul className="cat-list">
//                                                     <li><a href="#">Small</a></li>
//                                                     <li><a href="#">Medium</a></li>
//                                                     <li><a href="#">Large</a></li>
//                                                     <li><a href="#">Extra Large</a></li>
//                                                 </ul>
//                                             </div>
//                                         </div>
//                                     </div>

//                                     <div className="widget">
//                                         <h3 className="widget-title">
//                                             <a data-toggle="collapse" href="#widget-body-6" role="button" aria-expanded="true" aria-controls="widget-body-6">Brand</a>
//                                         </h3>
//                                         <div className="collapse show" id="widget-body-6">
//                                             <div className="widget-body">
//                                                 <ul className="cat-list">
//                                                     <li><a href="#">Adidas</a></li>
//                                                     <li><a href="#">Calvin Klein (26)</a></li>
//                                                     <li><a href="#">Diesel (3)</a></li>
//                                                     <li><a href="#">Lacoste (8)</a></li>
//                                                 </ul>
//                                             </div>
//                                         </div>
//                                     </div>

//                                     <div className="widget">
//                                         <h3 className="widget-title">
//                                             <a data-toggle="collapse" href="#widget-body-7" role="button" aria-expanded="true" aria-controls="widget-body-7">Color</a>
//                                         </h3>
//                                         <div className="collapse show" id="widget-body-7">
//                                             <div className="widget-body">
//                                                 <ul className="config-swatch-list">
//                                                     <li className="active">
//                                                         <a href="#" style={{ backgroundColor: '#000000' }}></a>
//                                                         <span>Black</span>
//                                                     </li>
//                                                     <li>
//                                                         <a href="#" style={{ backgroundColor: '#0188cc' }}></a>
//                                                         <span>Blue</span>
//                                                     </li>
//                                                     <li>
//                                                         <a href="#" style={{ backgroundColor: '#81d742' }}></a>
//                                                         <span>Green</span>
//                                                     </li>
//                                                     <li>
//                                                         <a href="#" style={{ backgroundColor: '#6085a5' }}></a>
//                                                         <span>Indigo</span>
//                                                     </li>
//                                                     <li>
//                                                         <a href="#" style={{ backgroundColor: '#ab6e6e' }}></a>
//                                                         <span>Red</span>
//                                                     </li>
//                                                     <li>
//                                                         <a href="#" style={{ backgroundColor: '#ddb373' }}></a>
//                                                         <span>Brown</span>
//                                                     </li>
//                                                     <li>
//                                                         <a href="#" style={{ backgroundColor: '#6b97bf' }}></a>
//                                                         <span>Light-Blue</span>
//                                                     </li>
//                                                     <li>
//                                                         <a href="#" style={{ backgroundColor: '#eded68' }}></a>
//                                                         <span>Yellow</span>
//                                                     </li>
//                                                 </ul>
//                                             </div>
//                                         </div>
//                                     </div>

//                                     <div className="widget widget-featured">
//                                         <h3 className="widget-title">Featured</h3>

//                                         <div className="widget-body">
//                                             <Slider className="widget-featured-products" {...FeaturedSlider}>

//                                                 <div className="featured-col">
//                                                     <div className="product-default left-details product-widget">
//                                                         <figure>
//                                                             <a href="product.php">
//                                                                 <img src={`${process.env.PUBLIC_URL}/assets/images/products/product-10.jpg`} alt="" />
//                                                             </a>
//                                                         </figure>
//                                                         <div className="product-details">
//                                                             <h2 className="product-title">
//                                                                 <a href="product.php">Product Short Name</a>
//                                                             </h2>
//                                                             <div className="ratings-container">
//                                                                 <div className="product-ratings">
//                                                                     <span className="ratings" style={{ width: '100%' }}></span>
//                                                                     <span className="tooltiptext tooltip-top"></span>
//                                                                 </div>
//                                                             </div>
//                                                             <div className="price-box">
//                                                                 <span className="product-price">₹49.00</span>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     <div className="product-default left-details product-widget">
//                                                         <figure>
//                                                             <a href="product.php">
//                                                                 <img src={`${process.env.PUBLIC_URL}/assets/images/products/product-11.jpg`} alt="" />
//                                                             </a>
//                                                         </figure>
//                                                         <div className="product-details">
//                                                             <h2 className="product-title">
//                                                                 <a href="product.php">Product Short Name</a>
//                                                             </h2>
//                                                             <div className="ratings-container">
//                                                                 <div className="product-ratings">
//                                                                     <span className="ratings" style={{ width: '100%' }}></span>
//                                                                     <span className="tooltiptext tooltip-top"></span>
//                                                                 </div>
//                                                             </div>
//                                                             <div className="price-box">
//                                                                 <span className="product-price">₹49.00</span>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     <div className="product-default left-details product-widget">
//                                                         <figure>
//                                                             <a href="product.php">
//                                                                 <img src={`${process.env.PUBLIC_URL}/assets/images/products/product-10.jpg`} alt="" />
//                                                             </a>
//                                                         </figure>
//                                                         <div className="product-details">
//                                                             <h2 className="product-title">
//                                                                 <a href="product.php">Product Short Name</a>
//                                                             </h2>
//                                                             <div className="ratings-container">
//                                                                 <div className="product-ratings">
//                                                                     <span className="ratings" style={{ width: '100%' }}></span>
//                                                                     <span className="tooltiptext tooltip-top"></span>
//                                                                 </div>
//                                                             </div>
//                                                             <div className="price-box">
//                                                                 <span className="product-price">₹49.00</span>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>

//                                                 <div className="featured-col">
//                                                     <div className="product-default left-details product-widget">
//                                                         <figure>
//                                                             <a href="product.php">
//                                                                 <img src={`${process.env.PUBLIC_URL}/assets/images/products/product-13.jpg`} alt="" />
//                                                             </a>
//                                                         </figure>
//                                                         <div className="product-details">
//                                                             <h2 className="product-title">
//                                                                 <a href="product.php">Product Short Name</a>
//                                                             </h2>
//                                                             <div className="ratings-container">
//                                                                 <div className="product-ratings">
//                                                                     <span className="ratings" style={{ width: '100%' }}></span>
//                                                                     <span className="tooltiptext tooltip-top"></span>
//                                                                 </div>
//                                                             </div>
//                                                             <div className="price-box">
//                                                                 <span className="product-price">₹49.00</span>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     <div className="product-default left-details product-widget">
//                                                         <figure>
//                                                             <a href="product.php">
//                                                                 <img src={`${process.env.PUBLIC_URL}/assets/images/products/product-14.jpg`} alt="" />
//                                                             </a>
//                                                         </figure>
//                                                         <div className="product-details">
//                                                             <h2 className="product-title">
//                                                                 <a href="product.php">Product Short Name</a>
//                                                             </h2>
//                                                             <div className="ratings-container">
//                                                                 <div className="product-ratings">
//                                                                     <span className="ratings" style={{ width: '100%' }}></span>
//                                                                     <span className="tooltiptext tooltip-top"></span>
//                                                                 </div>
//                                                             </div>
//                                                             <div className="price-box">
//                                                                 <span className="product-price">₹49.00</span>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     <div className="product-default left-details product-widget">
//                                                         <figure>
//                                                             <a href="product.php">
//                                                                 <img src={`${process.env.PUBLIC_URL}/assets/images/products/product-8.jpg`} alt="" />
//                                                             </a>
//                                                         </figure>
//                                                         <div className="product-details">
//                                                             <h2 className="product-title">
//                                                                 <a href="product.php">Product Short Name</a>
//                                                             </h2>
//                                                             <div className="ratings-container">
//                                                                 <div className="product-ratings">
//                                                                     <span className="ratings" style={{ width: '100%' }}></span>
//                                                                     <span className="tooltiptext tooltip-top"></span>
//                                                                 </div>
//                                                             </div>
//                                                             <div className="price-box">
//                                                                 <span className="product-price">₹49.00</span>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </Slider>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 {/* </StickyBox> */}
//                             </aside>
//                         </div>
//                     </div>
//                     <div className="mb-3"></div>
//                 </div>
//             )
//         }
//         else {
//             return (<div></div>)
//         }
    
// }


// const mapStateToProps = (state) => ({
//     productslist: state.productListData.productslist,
//     symbol: '₹ ',
//     userID: state.login.userID,
// })

// const mapDispatchToProps = (dispatch) => bindActionCreators({
//     getProductBySlug: (slug) => getProductBySlug(slug),
//     getFilterBySlug: (slug) => getFilterBySlug(slug),
// }, dispatch)

// export default connect(mapStateToProps, mapDispatchToProps)(CollectionLeftSidebar);

// const dummyArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]