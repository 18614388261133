import React, { Component } from 'react';

export default class payonline_response extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sucess: '',
            desc: '',
            amt: '',
            prod: '',
            bank_name: '',
            date: '',
            atomt_txn: '',
            customeraccount: '',
            transactionid: '',
            bank_txn: '',
            f_code: ''
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0)
        var value = await new URLSearchParams(this.props.location.search)

        await this.setState({
            sucess: value.get('message'),
            desc: value.get('desc'),
            amt: value.get('amt'),
            prod: value.get('prod'),
            bank_name: value.get('bank_name'),
            date: value.get('date'),
            atomt_txn: value.get('atomt_txn'),
            customeraccount: value.get('customeraccount'),
            transactionid: value.get('transactionid'),
            bank_txn: value.get('bank_txn'),
            f_code: value.get('f_code')
        })

        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <main className="main gap_genrate payment-response">
                    <div className="container">
                        <div className="listing-content">
                            <h4>TRANSACTION PROCESSED <span> {`${this.state.sucess}`}</span></h4>
                        </div>
                        <div className="row" >
                            <div className="col-6">
                                <label>Merchant Name:</label>
                            </div>
                            <div className="col-6">
                                <span>{this.state.customeraccount}</span>
                            </div>
                        </div>
                        <hr className="divider"></hr>

                        <div className="row" >
                            <div className="col-6">
                                <label>Product Id:</label>
                            </div>
                            <div className="col-6">
                                <span>{this.state.prod}</span>
                            </div>
                        </div>
                        <hr className="divider"></hr>

                        <div className="row" >
                            <div className="col-6">
                                <label>Id Number:</label>
                            </div>
                            <div className="col-6">
                                <span>{this.state.transactionid}</span>
                            </div>
                        </div>
                        <hr className="divider"></hr>

                        <div className="row" >
                            <div className="col-6">
                                <label>Amount:</label>
                            </div>
                            <div className="col-6">
                                <span>{this.state.amt}</span>
                            </div>
                        </div>
                        <hr className="divider"></hr>

                        <div className="row" >
                            <div className="col-6">
                                <label>Bank Name:</label>
                            </div>
                            <div className="col-6">
                                <span>{this.state.bank_name}</span>
                            </div>
                        </div>
                        <hr className="divider"></hr>

                        <div className="row" >
                            <div className="col-6">
                                <label>Tranaction Date:</label>
                            </div>
                            <div className="col-6">
                                <span>{this.state.date}</span>
                            </div>
                        </div>
                        <hr className="divider"></hr>

                        <div className="row" >
                            <div className="col-lg-6">
                                <label>Transaction Status:</label>
                            </div>
                            <div className="col-lg-6">
                                <span>{this.state.desc}</span>
                            </div>
                        </div>
                        <hr className="divider"></hr>

                        <div className="row" >
                            <div className="col-6">
                                <label>Bank Transaction Id:</label>
                            </div>
                            <div className="col-6">
                                <span>{this.state.bank_txn}</span>
                            </div>
                        </div>
                        <hr className="divider"></hr>

                        <div className="row" >
                            <div className="col-6">
                                <label>Atom Transaction Status:</label>
                            </div>
                            <div className="col-6">
                                <span>{this.state.atomt_txn}</span>
                            </div>
                        </div>
                        <hr className="divider"></hr>

                        <div className="row" >
                            <div className="col-6">
                                <label>Naswiz Transaction Status:</label>
                            </div>
                            <div className="col-6">
                                <span>{this.state.f_code}</span>
                            </div>
                        </div>
                        <hr className="divider"></hr>

                    </div>
                </main>
            </div>
        );
    }
}
