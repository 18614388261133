import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import { Link } from 'react-router-dom';

class HerbalProducts extends Component {

   constructor(props) {
      super(props)
   }

   render() {

      return (
         <div className="blog_page">
            <Breadcrumb title={'Herbal Products'} />

            {/*Blog Details section*/}

            <section className="blog-details-wrapper allCheckout pb-4">
               <div className="container">

                  <div className="text-center">
                     <div className="cms-page-title mb-3 line-effect position-relative">
                        <h2 className="d-inline bg-white pl-3 pr-3">Herbal Products</h2>
                     </div>
                     <p>Ayurveda is an ancient Indian traditional practice of medicine that is believed to be a holistic healing system. This alternative system of medicine promotes health and wellness by keeping mind, body and spirit in balance and harmony with the universe. Rather than fighting the disease, it focuses on preventing it by employing a wholesome approach combining diet, exercise and changes in lifestyle. Interestingly, some herbs, fruits and spices that are generally easily available in the nature around us play a vital role in making this balanced diet and the medicines used in its healing process. These ingredients have various health benefits, nourish and strengthen the body from various diseases by improving immunity, digestion and mental health.</p>
                     <img src="assets/images/blog/herbal-products/herbal-main-banner.jpg" alt="herbal-main-banner-img" className="img-fluid mt-3 mb-3" />
                     <p className="mt-3">Ayurvedic Ingredients and Products for a holistic lifestyle.</p>
                     <p>Here is a list of 4 such wonder ingredients that have Ayurvedic healing and nurturing properties to ensure the well-being of your family and make a move towards holistic living.</p>
                  </div>

                  <div className="blog-inside pt-30 pb-30">
                     <div className="listing-content">
                        <h4>Tulsi</h4>
                        <p>Tulsi can be referred as the most common and medicinally valued herb in Ayurveda. It’s an adaptogenic herb that can boost energy and relieve any stress, fatigue or anxiety. Its herbal formulations can help treat asthma, bronchitis, colds and flu. There are a number of beneficial compounds in Tulsi, which exhibits pain relieving properties, anti-ageing and anti-inflammatory properties. It has compounds of antioxidants and it contains flavonoids that remove body waste at the cellular level. Having discussed these benefits, there are so many ways that you can imbibe the goodness of Tulsi in your lifestyle. Tulsi is available in the form of tinctures, powders, and capsules or as herbal tea in the market today. Naswiz introduced a herbal products range recently where we aim to aid your health in the most organic and natural ways possible. </p>
                     </div>
                     <div className="row mt-md-4 mb-md-4 align-items-center">
                        <div className="col-md-8">
                           <div className="blog-imgarea position-relative left">
                              <img src="assets/images/blog/herbal-products/alerkyor-naturesplash-koffcalm-sarvtulsi.jpg" alt="alerkyor-naturesplash-koffcalm-sarvtulsi-img" className="pb-1 img-fluid" />
                           </div>
                        </div>
                        <div className="col-md-4">
                           <div className="blog-inner-wrapper">
                              <span className="sub-heading position-relative text-capitalize text-theme-primary">Herbal is futures</span>
                              <blockquote className="font-italic">“Many of our products use Tulsi as a main ingredient or complementing ingredient. AlerKyor, KoffCalm, Nature Splash and SarvTulsi are a few to name.”</blockquote>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="blog-inside pt-30 pb-30">
                     <div className="listing-content">
                        <h4>Ashwagandha</h4>
                        <p>Another adaptogen, ashwagandha is widely used as a stress reliever, brain function booster, and is known for controlling blood sugar and cortisol levels. It is a small woody plant of Indian and North African nativity. The roots and berries of this plant are popularly used for making ayurvedic remedies to cure stress and anxiety by reducing cortisol levels. Researchers say these spices can also enhance muscle growth, memory or concentration, boost male fertility, and reduce inflammation levels. It also reduces risk of heart disease by decreasing cholesterol levels and triglyceride levels and might even help reduce the risk of cancer.</p>
                     </div>
                     <div className="row mt-md-4 mb-md-4 align-items-center">
                        <div className="col-md-8 order-md-12">
                           <div className="blog-imgarea position-relative right">
                              <img src="assets/images/blog/herbal-products/crystalarrow-naturesplash-herbbycal.jpg" alt="crystalarrow-naturesplash-herbbycal-img" className="pb-1 img-fluid" />
                           </div>
                        </div>
                        <div className="col-md-4 order-md-1">
                           <div className="blog-inner-wrapper">
                              <span className="sub-heading position-relative text-capitalize text-theme-primary">Closer To Nature</span>
                              <blockquote className="font-italic">“Our products Crystal Arrow, HerbbyCal and Nature Splash have got this magic ingredient as a key component in case you choose to include it in your daily life.”</blockquote>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="blog-inside pt-30 pb-30">
                     <div className="listing-content">
                        <h4>Triphala</h4>
                        <p>Triphala is an ayurvedic concoction made up of three ayurvedic fruits namely Amla, haritaki and bibhitaki widely used as a remedy for various ailments from over 1000 years. This poly-herbal formulation emphasizes on prevention of diseases and promotion of health. Triphala is known to reduce inflammation caused by arthritis and even known to limit the growth of certain cancers. It functions as a natural laxative, improves gut health and treats constipation, making bowel movements consistent and improving their frequency. Also, they are excellent for your teeth health as they reduce plaque build up and prevent bacterial growth in the mouth which protects your teeth from cavities and gingivitis. </p>
                        <p>It is also usually advised for weight reduction and fat-loss. Triphala comes in capsules, liquid and powder forms usually. </p>
                     </div>
                     <div className="row mt-md-4 mb-md-4 align-items-center">
                        <div className="col-md-12 mb-4">
                           <div className="blog-inner-wrapper">
                              <span className="sub-heading position-relative text-capitalize text-theme-primary">Lets You Live Better.</span>
                              <blockquote className="font-italic">“Our products Vibewell, SheildOmmune, Modifit and Koffcalm use components of Triphala for various requirements. We also have our tablets Triphala that is a combination of the synergized components designed to balance Vata, Pitta and Kapha doshas in the body.”</blockquote>
                           </div>
                        </div>
                        <div className="col-md-12">
                           <div className="blog-imgarea position-relative left">
                              <img src="assets/images/blog/herbal-products/vibewell-shieldommune-triphala-modifit-koffcalm.jpg" alt="vibewell-shieldommune-triphala-modifit-koffcalm-img" className="pb-1 img-fluid" />
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="blog-inside pt-30 pb-30">
                     <div className="listing-content">
                        <h4>Giloy / Guduchi</h4>
                        <p>Well documented in the Ayurvedic literature, Guduchi/Giloy is best known for their immunity boosting properties. It accords longevity, promotes vitality, enhances youth and improves memory. It's a natural detoxifier that makes for a remedy for fatty liver and helps in proper functioning of the liver. It helps the liver by stimulating regeneration of damaged tissues. The plant oil from Giloy is known to treat skin ailments as well as improve complexion and lustre of skin. Apart from these benefits, it also helps in improvement of digestion, reduction of stress and enhancement of health. It won't be wrong to call it the does-it-all herb since it has got anti-ageing and anti-inflammatory properties as well. </p>
                     </div>
                     <div className="row mt-md-4 mb-md-4 align-items-center">
                        <div className="col-md-4">
                           <div className="blog-inner-wrapper">
                              <span className="sub-heading position-relative text-capitalize text-theme-primary">Immunity Booster</span>
                              <blockquote className="font-italic">“Acidoke, Alerkyor, Giloy Vati, Panch rasayan are a few of our products that contain giloy as an ingredient in them. Infact, Giloy Vati tablets are recommended by the Ministry of AYUSH Govt of India for prevention of Covid-19, fever and malaria.”</blockquote>
                           </div>
                        </div>
                        <div className="col-md-8">
                           <div className="blog-imgarea position-relative right">
                              <img src="assets/images/blog/herbal-products/giloyvati-alerkyor-panchrasrasayan-acidoke.jpg" alt="giloyvati-alerkyor-panchrasrasayan-acidoke.jpg-img" className="pb-1 img-fluid" />
                           </div>
                        </div>
                     </div>
                     <p className="mt-5">There are many other herbs and spices that are popular and have been an integral part of Indian medicine for centuries.</p>
                     <p>Check out our herbal range of products that are made from different ayurvedic combinations that could help make your life wholesome.</p>
                  </div>

                  <div className="blog-inside combinations-wrapper">
                     <div className="listing-content">
                        <h4>Herbal Combinations</h4>
                     </div>

                     <div className="row mt-md-4 mb-md-4">
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Diabtes.jpg" alt="Diabtes-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Diabetes</h4>
                                 <ul>
                                    <li>Giloy Vati 2BD</li>
                                    <li>Triphala Tab 4BD</li>
                                    <li>Panch Ras Rasayan - 4TBSP/BD</li>
                                    <li>Curcumin 1CAP</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/gastric-or-Gas.jpg" alt="gastric-or-Gas-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Gastric / Gas</h4>
                                 <ul>
                                    <li>Acidoke 2-3TBSP</li>
                                    <li>Triphala TAB 2TDS</li>
                                    <li>Giloy Vati 2BD</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Sexual-Disability.jpg" alt="Sexual-Disability-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Sexual Disability</h4>
                                 <ul>
                                    <li>Crystal Arrow 1BD</li>
                                    <li>Shieldommune 1BD</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Skin-problem.jpg" alt="Skin-problem-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Skin problem</h4>
                                 <ul>
                                    <li>Alerkyor 3TBSP BD</li>
                                    <li>Pancharas Rasayan</li>
                                    <li>Aloe Treat 4TBSP BD</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Allergy-of-skin.jpg" alt="Allergy-of-skin-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Allergy of skin / rashes</h4>
                                 <ul>
                                    <li>Alerkyor 3TBSP BD</li>
                                    <li>Pancharas Rasayan</li>
                                    <li>Aloe Treat 4TBSP BD</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Respiratory-problems.jpg" alt="Respiratory-problems-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Respiratory problems</h4>
                                 <ul>
                                    <li>Natursplash 2TIMES</li>
                                    <li>Koffcalm 2-3TIMES</li>
                                    <li>Alerkyor 2-3TIMES</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Constipation.jpg" alt="Constipation-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Constipation</h4>
                                 <ul>
                                    <li>Vibewell 10-20GM BCD</li>
                                    <li>Panchras 6-7TSR</li>
                                    <li>Triphala Tab 4-6 BD</li>
                                    <li>Aloe Treat</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Obesity.jpg" alt="Obesity-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Obesity</h4>
                                 <ul>
                                    <li>Modifit 1BD</li>
                                    <li>Panchras 5TBSP BD</li>
                                    <li>Triphala Tab 6TAB At Bedtime</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/post-corona-disability.jpg" alt="post-corona-disability-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Post – Corona Disability</h4>
                                 <ul>
                                    <li>Koffcalm 2-3TBSP</li>
                                    <li>Naturesplash BD</li>
                                    <li>Giloy Vati 2BD</li>
                                    <li>Sarv Tulsi Drops</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Corona.jpg" alt="Corona-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Corona</h4>
                                 <ul>
                                    <li>Koffcalm 2-3TBSP</li>
                                    <li>Naturesplash BD</li>
                                    <li>Giloy Vati 2BD</li>
                                    <li>Sarv Tulsi Drops</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Women-disorders-white-discharge-painful.jpg" alt="Women-disorders-white-discharge-painful-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Women disorders white discharge painful</h4>
                                 <ul>
                                    <li>Aloe Treat  4TBSP BD</li>
                                    <li>Herbbycal 1BD</li>
                                    <li>Panch Ras Rasayan 4TBSP BD</li>
                                    <li>Noni Capsuel 1BD</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Women.jpg" alt="Women-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Women</h4>
                                 <ul>
                                    <li>Shieldommune 1BD</li>
                                    <li>Giloy Vati 2BD</li>
                                    <li>Aloe Treat 4TBSP BD</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Children.jpg" alt="Children-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Children</h4>
                                 <ul>
                                    <li>Shieldommune 1BD</li>
                                    <li>Giloy Vati 2BD</li>
                                    <li>Aloe Treat 4TBSP BD</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Old-People.jpg" alt="Old-People-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Old People</h4>
                                 <ul>
                                    <li>Shieldommune 1BD</li>
                                    <li>Giloy Vati 2BD</li>
                                    <li>Aloe Treat 4TBSP BD</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Children-growth.jpg" alt="Children-growth-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Children growth</h4>
                                 <ul>
                                    <li>Herbbycal 1BD</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Immunity-booster-men.jpg" alt="Immunity-booster-men-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Immunity booster - men</h4>
                                 <ul>
                                    <li>Shieldommune 1BD</li>
                                    <li>Giloy Vati 2BD</li>
                                    <li>Triphala Tab 4BD</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Joint-pain.jpg" alt="Joint-pain-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Joint pain</h4>
                                 <ul>
                                    <li>Herbbycal 1BD</li>
                                    <li>Curcumin 1</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Hair-Fall.jpg" alt="Hair-Fall-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Hair Fall</h4>
                                 <ul>
                                    <li>Triphala Tab 4BD</li>
                                    <li>Panchras BD</li>
                                    <li>Herbbycal 1OD</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Health-supplement-for-nutrition.jpg" alt="Health-supplement-for-nutrition-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Health supplement for nutrition</h4>
                                 <ul>
                                    <li>Herbby Flax 4-8TBSP</li>
                                    <li>Spirulina 1BD</li>
                                    <li>Noni Cap 1BD</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Health-supplement-for-cancer-patient.jpg" alt="Health-supplement-for-cancer-patient-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Health supplement for Cancer Patient</h4>
                                 <ul>
                                    <li>Curcumin Cap 1BD</li>
                                    <li>Shieldommune 1BD</li>
                                    <li>Herbby Flax 4-8TBSP</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Health-supplement-for-Heart-patient.jpg" alt="Health-supplement-for-Heart-patient-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Health supplement for Heart Patient</h4>
                                 <ul>
                                    <li>Herbby Flax 4-8TBSP</li>
                                    <li>Shieldommune 1BD</li>
                                    <li>Nature Splash BD</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mb-3">
                           <div className="box-area d-flex flex-column h-100">
                              <div className="imgarea border-bottom">
                                 <img src="assets/images/blog/herbal-products/Liver-Health.jpg" alt="Liver-Health-img" className="img-fluid" />
                              </div>
                              <div className="detailsarea text-center">
                                 <h4 className="product-title text-capitalize text-theme-secondary mb-2">Liver Health</h4>
                                 <ul>
                                    <li>Acidoke BD</li>
                                    <li>Triphala Tab 4BD</li>
                                    <li>Giloy Tab 2BD</li>
                                    <li>Noni Capsuel 1BD</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                     <p>However, be mindful of the holistic approach of Ayurveda that includes physical activity, adequate sleep, meditation, and following a balanced diet.</p>
                  </div>

               </div>
            </section>
         </div>
      )
   }
}

export default HerbalProducts