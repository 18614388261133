import React, { Component } from 'react';
import { withTranslate } from 'react-redux-multilingual'
import Modal from 'react-responsive-modal';
import { NaswizHelperApi } from '../../../NaswizHelper';

import { Helmet } from 'react-helmet';
import ReactPaginate from 'react-paginate';
import Breadcrumb from '../../common/breadcrumb';
import Seo from '../../Seo';



class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            testimonial_alkaline: '',
            uniueId: '',
            _page: 1,
            _noOfPage: [],
        }
    }

    async componentDidMount() {
        var uniueId = await localStorage.getItem('deviceId')
        await this.setState({ uniueId: uniueId })
        await this.naswizGetHomeDataApi()

    }

    naswizGetHomeDataApi = async () => {

        var url = 'v1/get-home/' + this.state.uniueId + '/0?' + 'page=' + this.state._page
        var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(url, 'Error => v1/get-home')
        if (res && res.success === 1) {
            this.setState({
                testimonial_alkaline: res.records.testimonial_other_product.data
            })
            var tmp = []

            for (let i = 0; i < res.records.testimonial_other_product.last_page; i++) {
                tmp.push(i)
            }
            this.setState({ _noOfPage: tmp })
        }
        else {
            this.setState({
                loading_getHomeData: false
            })
        }
    }
    handlePagination = async (value2) => {
        var value = value2.selected + 1
        if (value <= this.state._noOfPage.length) {
            await this.setState({ _page: value })

            await this.naswizGetHomeDataApi()
        }

    }
    onOpenModal = () => {
        this.setState({ open: true });
    };
    onCloseModal = () => {
        this.setState({ open: false });
    };


    render() {
        const { translate, _data, top_bar_cms, social_arr } = this.props;
        return (
            <div>
                <Breadcrumb title={'Testimonial'} />
                {/* <Helmet>
                   
                    <title>Client Testimonial - Naswiz Retails Pvt Ltd</title>
                    <meta name="og_title" property="og:title" content="Client Testimonial - Naswiz Retails Pvt Ltd" />
                    <meta name="twitter:title" content="Client Testimonial - Naswiz Retails Pvt Ltd" />
                    <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                    <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                    <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                    <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                </Helmet> */}

                <Seo title="Client Testimonial" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." image="https://www.myshopwiz.com/assets/images/cms-page/phytowiz-banner-1.png" />

                <main className="main">

                    <section className="testimonial-wrapper pb-0">
                        <div className="container">
                            <div className="store-location-listing">
                                <div className="section-title">
                                    <h2 className="text-capitalize">Testimonial</h2>
                                </div>
                            </div>

                            <ul className="row">
                                {this.state.testimonial_alkaline.length > 0 ?
                                    this.state.testimonial_alkaline.map((item, index) => {
                                        return (
                                            <li className="col-md-3 col-sm-4">
                                                <div className="testimonial-box d-flex flex-column border h-100">
                                                    <div className="manufacturing-video position-relative">
                                                        {item.is_check === 0 ?
                                                            <img
                                                                onClick={async () => {
                                                                    await this.setState({
                                                                        _mediaType: item.is_check,
                                                                        _mediaUrl: item.image_full_path
                                                                    })
                                                                    if (item.image_full_path !== '') {
                                                                        await this.onOpenModal()

                                                                    }
                                                                }}
                                                                src={item.image_full_path === '' ? 'assets/images/cms-page/testimonial-video-poster-1.jpg' : item.image_full_path} alt="video-img" className="img-fluid img-center video-poster" style={{ maxHeight: '278px', minHeight: '278px', objectFit: 'cover', objectPosition: 'center' }} />
                                                            :
                                                            <img src={item.thumbnail_full_path} className="img-fluid img-center video-poster w-100" alt="video-img" style={{ maxHeight: '278px', minHeight: '278px', objectFit: 'cover', objectPosition: 'center' }} />
                                                        }
                                                        {item.is_check === 1 ?
                                                            <div className="play-video">
                                                                <a href="javascript:void(0)" onClick={async () => {
                                                                    await this.setState({
                                                                        _mediaType: item.is_check,
                                                                        _mediaUrl: item.youtube_link
                                                                    })
                                                                    await this.onOpenModal()
                                                                }}>
                                                                    <img src="assets/images/cms-page/play-video.png" className="img-fluid img-center" alt="play-video-icon-img" />
                                                                </a>
                                                            </div>
                                                            : null}
                                                    </div>
                                                    <div className="testimonial-content d-flex flex-column h-100">
                                                        <p className="pb-2 text-capitalize">{item.description}</p>
                                                        <div className="bottom-txt text-right">
                                                            <h5 className="text-capitalize text-truncate">{item.name} </h5>
                                                            <h6 className="text-capitalize text-truncate">{item.designation} </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    }) : null
                                }
                            </ul>
                            <Modal open={this.state.open} onClose={this.onCloseModal} center>
                                <div className="video-modal12">
                                    <div className="video-responsive122">
                                        {this.state._mediaType === 0 ?
                                            <img src={this.state._mediaUrl} alt="video-img" className="img-fluid" />
                                            :
                                            <iframe src={this.state._mediaUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        }
                                    </div>
                                </div>
                            </Modal>

                        </div>
                    </section>

                </main>

            </div>
        )
    }

}

export default withTranslate(Testimonial);
