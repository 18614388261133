const base_url = "https://shrayati.com/shrayati/api/"
const AUTH_BEARER = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvc2hyYXlhdGkuY29tXC9zaHJheWF0aVwvYXBpXC92MVwvbG9naW4iLCJpYXQiOjE2MDQ1NzM2ODEsImV4cCI6MTYwNDU3NzI4MSwibmJmIjoxNjA0NTczNjgxLCJqdGkiOiJlb0s3bmRJSmg3bDlhY0RqIiwic3ViIjo1LCJwcnYiOiI4YjQyMmU2ZjY1NzkzMmI4YWViY2IxYmYxZTM1NmRkNzZhMzY1YmYyIn0.HXciY2lrV11ZT-R2zmjGGOxmxu82TdcoMc8AJuccU8E"

var Helper = {
    POST: async function (trailUrl, body, errorMSG) {

        var token = localStorage.getItem('token')

        var url = base_url.concat(trailUrl)

        return fetch(url, {
            body: body ? body : null,
            method: 'post', headers: { "Accept": "application/x.naswiz.v1+json", "Authorization": "Bearer ".concat(token) }
        })
            .then(res => res.json())
            .then(resData => resData)
            .catch(e => {
                errorMSG ? console.log(errorMSG, e) : console.log("Error => Helper.js POST METHOD", e)
            })

    }
}


export { base_url, AUTH_BEARER, Helper }