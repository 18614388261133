import React, { Component } from 'react';
import { base_url, AUTH_BEARER } from '../../helper'
import Breadcrumb from "../common/breadcrumb";
import { setLogin, } from '../../actions'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Link, Redirect } from 'react-router-dom'
import Seo from '../Seo';

const RedirectToHome = () => {
    return <Link to={`${process.env.PUBLIC_URL}/`} />
}

class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: 'aliasgargandhi00@gmail.com',
            password: 'ali',
            submitted: false,
            redirect: false
        };
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount = () => {
   
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    async setLogin() {

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/x.shrayati.v1+json");

        var formdata = new FormData();
        formdata.append("email", this.state.email);
        formdata.append("password", this.state.password);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        var res = await fetch(`${base_url}v1/login`, requestOptions)
            .then(response => response.json())
            .then(result => result)
            .catch(error => console.log('error', error));

        if (res && res.success == 1) {
            // RedirectToHome()
            // this.setState({ redirect: true })
            this.props.history.goBack()
            localStorage.setItem('is_user_login', 'true')
            localStorage.setItem('token', res.token)
        }
        else {

        }
    }
    render() {
        const { email, password, submitted, redirect } = this.state;

        if (redirect) {
            return <Redirect to={`${process.env.PUBLIC_URL}/pages/contact`} />
        }
        return (
            <div>
                <Breadcrumb title={'Login'} />
                <Seo title="Login" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company."  />
                {/*Login section*/}
                <section className="login-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3>Login</h3>
                                <div className="theme-card">
                                    <form className="theme-form">
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input type="email" className="form-control" id="email" placeholder="Email"
                                                required="" name="email" value={email} onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="review">Password</label>
                                            <input type="password" className="form-control" id="review"
                                                placeholder="Enter your password" required="" name="password" value={password} onChange={this.handleChange} />
                                        </div>
                                        <a onClick={() => this.setLogin()} className="btn btn-solid">Login</a>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 right-login">
                                <h3>New Customer</h3>
                                <div className="theme-card authentication-right">
                                    <h6 className="title-font">Create A Account</h6>
                                    <p>Sign up for a free account at our store. Registration is quick and easy. It
                                    allows you to be able to order from our shop. To start shopping click
                                        register.</p>
                                    <a href="/" className="btn btn-solid">Create an Account</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {

}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setLogin: (params) => setLogin(params),


}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Login);