import React from 'react'
import { connect } from 'react-redux'
import Notification from '../../../Notification'
import { MAIN_URL, NaswizHelperApi } from '../../../NaswizHelper';
import moment from 'moment'
import './innerdashboard.css';
import _searchUponSelectedFilter from '../../../new_components/products_search'
import _getOrderList from './ID_MyOrders'

import paymenttttt from '../../../assets/images/payment.png'

import bg_img from '../../../assets/images/wallet_bg.jpg'
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Seo from '../../Seo';

class InnerDashboardMyWallet extends React.Component {

    constructor(props) {
        super(props)
        this.amountInputRef = React.createRef(null);
        this.state = {
            arr_wallet: [],
            arr_walletcustomer: '',
            loading_wallet: true,
            loading_walletcustomer: true,
            addPayment: false,
            _walletamount: '',
            _isAddAmountCheck: false,
            regexp: /^[0-9\b]+$/,
            _noOfPage: [],
            _page: 1,
            isPriceFilter: false,
            PaymentType: ''
        }
    }

    componentDidMount = async () => {

        this._getMyWallet()
        this._getMyWalletcustomers()
        var orderidWallet = localStorage.getItem('order_id_wallet')
        if (orderidWallet !== '' && orderidWallet !== null) {
            var formData = new FormData()
            formData.append('order_id', orderidWallet)
            var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/check-payment-advance-amount', formData, 'Error => v1/check-referral')
            if (res.success === 1) {
                Notification('success', 'Success!', res.message)
                this._getMyWallet()
                this._getMyWalletcustomers()
            } else {
                Notification('error', 'Error!', 'Payment is not Completed')
            }
            await localStorage.setItem('order_id_wallet', '')

        }
    }

    _getMyWallet = async () => {
        var res = await NaswizHelperApi.GET_WITH_TOKEN('v1/wallet/?page=' + this.state._page, "Error => v1/wallet api.")
        if (res && res.success === 1) {
            this.setState({
                arr_wallet: res.record.data,
                loading_wallet: false
            })
            var tmp = []
            for (let i = 0; i < res.record.last_page; i++) {
                tmp.push(i);
            }
            this.setState({ _noOfPage: tmp })
        }
        else {
            this.setState({
                loading_wallet: false
            })
        }
    }
    handlePagination = async (value2) => {
        var value = value2.selected + 1
        if (value <= this.state._noOfPage.length) {
            await this.setState({ _page: value })
            // if (this.state.isPriceFilter)
            //     this._searchUponSelectedFilter()
            // else
            this._getMyWallet()
        }

    }
    _getMyWalletcustomers = async () => {
        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/customers-detail', "Error => v1/customers-detail api.")
        if (res && res.success === 1) {
            this.setState({
                arr_walletcustomer: res.record,
                loading_wallet: false
            })
        }
        else {
            this.setState({
                loading_walletcustomer: false
            })
        }
    }

    onHandleTelephoneChange = e => {
        let telephone = e.target.value;
        // if value is not blank, then test the regex
        if (telephone === '' || this.state.regexp.test(telephone)) {
            this.setState({ _walletamount: telephone })
        }
        else {
            return;
        }
    };

    hadlesubmit = e => {
        if (e.keyCode === 13 || e.which === 13) {
            this._getOrderID()
        }
    }
    _getOrderID = async () => {
        this.setState({ _isAddAmountCheck: true })

        if (this.state.PaymentType === '') {
            Notification('error', 'Error!', 'Please Select Payment Method');
            return;
        }
        if (this.state._walletamount === '') {
            Notification('error', 'Error!', 'Please enter wallet amount');
            return;
        }

        var body = new FormData()
        body.append('amount', this.state._walletamount);
        body.append('payment_type', this.state.PaymentType);
        this.setState({ loading_wallet: true })
        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/advance-amount', body, "Error => v1/wallet-detail api.")
        this.setState({ addPayment: false })
        if (res && res.success === 1) {
            this.setState({ loading_wallet: false })
            if (this.state.PaymentType === "paytm") {
                await displayPaytm(res.response)
                this.setState({ _isAddAmountCheck: false })
                this.setState({ PaymentType: '' })
                this.setState({ _walletamount: '' })
            }
            else if (this.state.PaymentType === "atom") {
                // Notification('error', 'Error!', 'Atom Payment Not Working ,Please Choose Another Option to Pay!');
                window.openPay(res.response.atomTokenId, "330019", res.response.email_id, res.response.connumber, `${MAIN_URL}master-naswiz/naswiz/order/response/atom2`);
                this.setState({ _isAddAmountCheck: false })
                this.setState({ _walletamount: '' })
                this.setState({ PaymentType: '' })
            }
        }
        else {
        }
    }

    render() {
        return (
            <div className="col-lg-9 notification no-padding">
                {/* <Helmet>
                    <title>My Wallet - Naswiz Retails Pvt Ltd</title>
                    <meta name="og_title" property="og:title" content="My Wallet - Naswiz Retails Pvt Ltd" />
                    <meta name="twitter:title" content="My Wallet - Naswiz Retails Pvt Ltd" />
                    <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                    <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                    <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                    <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                </Helmet> */}
                <Seo title="My Wallet" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company."   />
                {this.state.loading_wallet ?
                    <div>
                        <div className="loader-wrapper">
                            <div className="loader"></div>
                        </div>
                    </div> :
                    <div className="inner_loyalty_point">
                        <div className="notification_head">
                            <h3>My Naswiz Wallet</h3>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-12 col-lg-6 col-sm-6 col-12 ">
                                <div className="not_div">
                                    <div className="top_wallet_div">
                                        <div className="wallet_div" style={{ backgroundImage: `url(${bg_img})` }}>
                                            <div className="wallet_logo">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/wallet_icon.png`} />
                                            </div>

                                            <div className="wallet_details">
                                                <p>My Wallet Balance</p>
                                                <h4><strong>Rs {this.state.arr_walletcustomer.wallet}</strong></h4>
                                                <Link to="/terms-and-condition">Terms and Condition</Link>
                                            </div>

                                            <a data-toggle="modal" data-target="#add_walletModal1" onClick={() => { this.setState({ addPayment: true }) }}>
                                                <button className="button_add_pref button_add_pref_add wallet_btn">+</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h4 className="col-md-12 col-lg-12 col-sm-12 col-12 wallet-transaction">Wallet Transaction</h4>


                            {/* Credit Wallet */}


                            {/* <div className="bottom_inside">
                                <div className="trans_details">
                                    <div className="trans_details_left">
                                        <div className="trans_logo_container">
                                            <img src="assets/images/wt_logo.png" />
                                        </div>
                                        <div className="refund">
                                            <p>
                                                Credit by Pradip Vaishnani {" "}
                                                <span className="badge badge-success">Success</span>{" "}
                                                <span className="badge badge-warning">Process</span>{" "}
                                                <span className="badge badge-danger">Fail</span>
                                            </p>
                                        </div>
                                        <div className="refund_date">
                                            <p className='mt-2'>
                                                Payment Mode: {" "}<strong>[Atom]</strong> <strong>[Paytm]</strong> <strong>[Wallet]</strong> | Transaction Date: { } <strong>07-09-2022 17:15 PM</strong>
                                            </p>
                                            <p className='mt-2'>
                                                Transaction ID: {" "}
                                                <strong>#222265656</strong>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="refund_amt">
                                        <p>+ ₹500</p>
                                    </div>
                                </div>
                            </div> */}

                            {/* Cancel Order  */}

                            {/* <div className="bottom_inside">
                                <div className="trans_details">
                                    <div className="trans_details_left">
                                        <div className="trans_logo_container">
                                            <img src="assets/images/wt_logo.png" />
                                        </div>
                                        <div className="refund">
                                            <p>
                                                Cancelled Order  #222265656 {" "}
                                                <span className="badge badge-success">Success</span>{" "}
                                                <span className="badge badge-warning">Process</span>{" "}
                                                <span className="badge badge-danger">Fail</span>
                                            </p>
                                        </div>
                                        <div className="refund_date">
                                            <p className='mt-2'>
                                                Payment Mode: {" "}<strong>[Atom]</strong> <strong>[Paytm]</strong> <strong>[Wallet]</strong> | Transaction Date: { } <strong>07-09-2022 17:15 PM</strong>
                                            </p>
                                            <p className='mt-2'>
                                                Transaction ID: {" "}
                                                <strong>#222265656</strong>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="refund_amt">
                                        <p>+ ₹500</p>
                                    </div>
                                </div>
                            </div> */}

                            {/* Place Order */}

                            {/* <div className="bottom_inside">
                                <div className="trans_details">
                                    <div className="trans_details_left">
                                        <div className="trans_logo_container">
                                            <img src="assets/images/wt_logo.png" />
                                        </div>
                                        <div className="refund">
                                            <p>
                                                Placed Order: #222265656{" "}
                                                <span className="badge badge-success">Success</span>{" "}
                                                <span className="badge badge-warning">Process</span>{" "}
                                                <span className="badge badge-danger">Fail</span>
                                            </p>
                                        </div>
                                        <div className="refund_date">
                                            <p className='mt-2'>
                                                Payment Mode: {" "}<strong>[Atom]</strong> <strong>[Paytm]</strong> <strong>[Wallet]</strong> | Transaction Date: { } <strong>07-09-2022 17:15 PM</strong>
                                            </p>
                                            <p className='mt-2'>
                                                Transaction ID: {" "}
                                                <strong>#222265656</strong>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="refund_amt">
                                        <p>- ₹5000</p>
                                    </div>
                                </div>
                            </div> */}

                            {/* Earned Loyalty Points */}

                            {/* <div className="bottom_inside">
                                <div className="trans_details">
                                    <div className="trans_details_left">
                                        <div className="trans_logo_container">
                                            <img src="assets/images/wt_logo.png" />
                                        </div>
                                        <div className="refund">
                                            <p>
                                                Earned by Redeemed Loyalty Points {" "}
                                                <span className="badge badge-success">Success</span>{" "}
                                                <span className="badge badge-warning">Process</span>{" "}
                                                <span className="badge badge-danger">Fail</span>
                                            </p>
                                        </div>
                                        <div className="refund_date">
                                            <p className='mt-2'>
                                                Payment Mode: {" "}<strong>[Atom]</strong> <strong>[Paytm]</strong> <strong>[Wallet]</strong> | Transaction Date: { } <strong>07-09-2022 17:15 PM</strong>
                                            </p>
                                            <p className='mt-2'>
                                                Transaction ID: {" "}
                                                <strong>#222265656</strong>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="refund_amt">
                                        <p>+ ₹500</p>
                                    </div>
                                </div>
                            </div> */}


                            {/* Old COde */}
                            {/* <div className="bottom_inside">

                                <div className="trans_details">
                                    <div className="trans_details_left">
                                        <div className="trans_logo_container">
                                            <img src="assets/images/wt_logo.png" />
                                        </div>
                                        <div className="refund">
                                            <p>
                                                {item.is_status === 1 ? 'Added' : item.is_status === 2 ? 'Order ID #' + item.order_number : item.is_status === 3 ? 'Order ID #' + item.order_number : null} {item.is_status === 1 ? 'to' : item.is_status === 2 || item.is_status === 3 ? '' : 'by'} {item.is_status === 2 || item.is_status === 3 ? '' : item.customer_name} <span className="badge badge-warning"> {item.is_status === 1 ? 'added' : item.is_status === 2 ? 'used' : item.is_status === 3 ? 'return' : null} {item.is_status === 1 || item.is_status === 3 ? 'to' : item.is_status === 2 ? '' : 'by'} {item.customer_name} </span>
                                            </p>
                                        </div>

                                        <div className="refund_date">
                                            <p>{moment(item.created_at).format('DD-MM-YYYY HH:mm A')}</p>
                                        </div>
                                    </div>
                                    <div className="refund_amt">
                                        <p>{item.is_status === 1 ? '+' : item.is_status === 2 ? '-' : item.is_status === 3 ? '+' : null} ₹{item.amount}</p>
                                    </div>

                                </div>
                            </div> */}
                            {this.state.arr_wallet.length > 0 ?
                                this.state.arr_wallet.map((item, index) => {
                                    return (
                                        <div key={index} className="col-md-12 col-lg-12 col-sm-12 col-12">

                                            <div className="bottom_inside">
                                                <div className="trans_details">
                                                    <div className="trans_details_left">
                                                        <div className="trans_logo_container">
                                                            <img src="assets/images/wt_logo.png" />
                                                        </div>
                                                        <div className="refund">
                                                            <p>
                                                                {item.is_status === 1 ?
                                                                    `Credit by ${item.customer_name}`
                                                                    : item.is_status === 2 ?
                                                                        `Placed Order: #${item.order_number}`
                                                                        : item.is_status === 3 ?
                                                                            `Cancelled Order  #${item.order_number}`
                                                                            : null
                                                                }
                                                                {/* {item.is_status === 1 ?
                                                                    <span className="badge badge-success"> Success </span>
                                                                    : null
                                                                } */}
                                                                {/* <span className="badge badge-warning">Process</span>{" "}
                                                                <span className="badge badge-danger">Fail</span> */}
                                                            </p>
                                                        </div>
                                                        <div className="refund_date">
                                                            <p className='mt-2'>
                                                                {item.is_status === 1 ?
                                                                    <>
                                                                        Payment Mode:
                                                                        {" "}<strong>

                                                                            {item.pay_via === "atom" ?
                                                                                "NTTDATA"
                                                                                : `${item.pay_via}`
                                                                            }
                                                                        </strong>
                                                                    </>
                                                                    : null
                                                                }

                                                            </p>
                                                            <p className='mt-2'>
                                                                Transaction Date: { } <strong>{moment(item.created_at).format('DD-MM-YYYY HH:mm A')}</strong>
                                                            </p>
                                                            <p className='mt-2'>
                                                                {item.is_status === 1 ?
                                                                    <>
                                                                        Transaction ID:
                                                                        {' '} <strong>
                                                                            {
                                                                                item.a_payment_reference ?
                                                                                    item.a_payment_reference
                                                                                    : item.a_atomTxnId ?
                                                                                        item.a_atomTxnId
                                                                                        : item.o_payment_reference ?
                                                                                            item.o_payment_reference
                                                                                            : null
                                                                            }
                                                                        </strong>
                                                                    </> : null
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="refund_amt">
                                                        <p className={item.is_status === 1 ? 'text-success' : item.is_status === 2 ? 'text-danger' : item.is_status === 3 ? 'text-success' : null}>{item.is_status === 1 ? '+' : item.is_status === 2 ? '-' : item.is_status === 3 ? '+' : null} ₹{item.amount}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                : null
                            }
                            {this.state._noOfPage.length > 1 ?
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                    pageCount={this.state._noOfPage.length}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={5}
                                    onPageChange={(page) => this.handlePagination(page)}
                                    containerClassName={'pagination toolbox-item mx-auto'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                // forcePage={this.state._page - 1}
                                />
                                : null}
                            <div className={this.state.addPayment ? 'addmany open' : 'addmany closeadd'}>
                                <div className="addoutside" onClick={() => { this.setState({ addPayment: false }) }}></div>
                                <div className="addmany-inner">
                                    <div className="close-icon">
                                        <i className="fas fa-times" onClick={() => { this.setState({ addPayment: false }) }}></i>
                                    </div>
                                    <div className="user-icon">
                                        <img src={paymenttttt} alt="" className="img-fluid" />
                                    </div>

                                    <div className="select-payment-method">
                                        <div className="row">

                                            <div className="col-12">
                                                <div className="form-check">
                                                    <div className="payment-radio-design radio">
                                                        <input className="form-check-input" type="radio" id="paytm" value="paytm" checked={this.state.PaymentType === "paytm"} onChange={(e) => { this.setState({ PaymentType: e.target.value }); }} />
                                                        <span className="radio-element"></span>
                                                        <div className="wallet-type"><img src="../../assets/images/paytm.png" /></div>
                                                    </div>
                                                    <p>Paytm / UPI / Wallet Payments</p>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="form-check">
                                                    <div className="payment-radio-design radio">
                                                        <input className="form-check-input" type="radio" id="atom" checked={this.state.PaymentType === "atom"} value="atom" onChange={(e) => { this.setState({ PaymentType: e.target.value }); }} />
                                                        <span className="radio-element"></span>
                                                        <div className="wallet-type"><img src="../../assets/images/atom.png" /></div>
                                                    </div>
                                                    <p>Debit Card / Credit Card / Net Banking / Paytm / UPI / Wallet Payments</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    {this.state._walletamount === '' && this.state._isAddAmountCheck ? <div className="addmany-test">
                                        <span><strong>Please Enter Wallet Amount</strong> </span>

                                    </div> : <div className="addmany-test">
                                        <span>Enter Wallet Amount</span>
                                    </div>}
                                    <div className="addmany-box">
                                        <span>₹</span>
                                        <input type="test"
                                            value={this.state._walletamount}
                                            onChange={e => this.onHandleTelephoneChange(e)}
                                            onKeyDown={e => this.hadlesubmit(e)}
                                            placeholder="00" maxLength="5" autoFocus={true} />

                                        <div className={this.state._walletamount === "" || this.state._walletamount >= 99999 ? "subment-button disable" : "subment-button"} onClick={() => this._getOrderID()}>
                                            <a href="javascript:void(0)" >Add Money</a>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div >
                }

            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        allState: state
    }
}

async function displayPaytm(response) {

    var res = await loadScript()
    if (!res) {
        alert('SDK failed to load. Are you online?')
        return
    }
    await localStorage.setItem('order_id_wallet', response.order_id)
    var config = {
        "root": "",
        "flow": "DEFAULT",
        "data": {
            "orderId": response.order_id + '', /* update order id */
            "token": response.body.txnToken, /* update token value */
            "tokenType": "TXN_TOKEN",
            "amount": response.amount + '', /* update amount */
            'callbackUrl': '/page/user/myorder', /*  */

        },
        "handler": {
            "notifyMerchant": async function (eventName, data) {
                if (eventName == 'APP_CLOSED') {

                    var formData = new FormData()
                    formData.append('order_id', response.order_id)
                    var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/check-payment-advance-amount', formData, 'Error => v1/check-referral')
                    if (res.success === 1) {
                        Notification('success', 'Success!', res.message)
                    } else {
                        Notification('error', 'Error!', 'Payment is not Completed')
                    }
                }
            }
        }
    };

    if (window.Paytm && window.Paytm.CheckoutJS) {
        window.Paytm.CheckoutJS.onLoad(async function excecuteAfterCompleteLoad() {
            // initialze configuration using init method 
            window.Paytm.CheckoutJS.init(config).then(async function onSuccess() {
                // after successfully updating configuration, invoke JS Checkout

                window.Paytm.CheckoutJS.invoke();
            }).catch(function onError(error) {

            });
        });
    }



}

function loadScript() {

    return new Promise(res => {
        var myScript = document.createElement('script')
        myScript.src = 'https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/Naswiz81262155606177.js'
        document.body.appendChild(myScript)
        myScript.onload = () => {
            res(true)
        }
        myScript.onerror = () => {
            res(false)
        }
        document.body.appendChild(myScript)
    })

}

export default connect(mapStateToProps, null)(InnerDashboardMyWallet)