import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";
import { Link } from "react-router-dom";
import renderHTML from 'react-render-html';
import { connect } from "react-redux";

class DetailsTopTabs extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        const { symbol, item } = this.props;
        return (
            // <div>
            //     THIS(DETAILS-TOP-TABS) PAGE IS COMMENTED ON 07-01-21 DUE TO CHANGES
            // </div>
            <section className="tab-product m-0">

                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <Tabs className="tab-content nav-material">
                            <TabList className="nav nav-tabs nav-material">
                                <Tab className="nav-item">
                                    <span className="nav-link active">
                                        <i className="icofont icofont-ui-home" />
                                        Description
                                    </span>
                                    <div className="material-border" />
                                </Tab>

                                <Tab className="nav-item">
                                    <span className="nav-link">
                                        <i className="icofont icofont-man-in-glasses" />
                                        More Info
                                    </span>
                                    <div className="material-border" />
                                </Tab>
                                <Tab className="nav-item">
                                    <span className="nav-link">
                                        <i className="icofont icofont-contacts" />
                                        tags
                                    </span>
                                    <div className="material-border" />
                                </Tab>
                                <Tab className="nav-item">
                                    <span className="nav-link">
                                        <i className="icofont icofont-contacts" />
                                        Reviews
                                    </span>
                                    <div className="material-border" />
                                </Tab>
                            </TabList>
                            <TabPanel className="tab-pane fade mt-4 show active">
                                <p className="mt-4 p-0">
                                    {renderHTML(String(`${item.description}`))}
                                    {/* {renderHTML(renderString)} */}
                                </p>
                                {/* <table className="table table-striped mb-0">
                        <tbody>
                          <tr>
                            <th>Ideal For :</th>
                            <td>Women's</td>
                          </tr>
                          <tr>
                            <th>Pattern :</th>
                            <td>Embroidered</td>
                          </tr>
                          <tr>
                            <th>Dress Fabric :</th>
                            <td>Silk</td>
                          </tr>
                          <tr>
                            <th>Type :</th>
                            <td>Ghagra, Choli, Dupatta Set</td>
                          </tr>
                          <tr>
                            <th>Neck :</th>
                            <td>Round Neck</td>
                          </tr>
                          <tr>
                            <th>Sleeve :</th>
                            <td>3/4 Sleeve</td>
                          </tr>
                          <tr>
                            <th>Work :</th>
                            <td>N/A</td>
                          </tr>
                        </tbody>
                      </table> */}
                            </TabPanel>
                            <TabPanel>
                                <p className="mt-4 p-0">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the
                                    industry's standard dummy text ever since the 1500s,
                                    when an unknown printer took a galley of type and
                                    scrambled it to make a type specimen book. It has
                                    survived not only five centuries, but also the leap into
                                    electronic typesetting, remaining essentially unchanged.
                                    It was popularised in the 1960s with the release of
                                    Letraset sheets containing Lorem Ipsum passages, and
                                    more recently with desktop publishing software like
                                    Aldus PageMaker including versions of Lorem Ipsum.
                      </p>
                            </TabPanel>
                            <TabPanel>
                                <div className="mt-4 ">
                                    <h4>Add Your Tag</h4>
                                    <div className="row col-md-12">
                                        <div className="col-md-4">

                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                placeholder="Enter Yor tag"
                                                required
                                            />

                                        </div>
                                        <div className="col-md-4">
                                            <button className="btn " type="submit" style={{ backgroundColor: 'black', color: 'white' }}>
                                                Add Tags
                            </button>

                                        </div>
                                    </div>


                                </div>

                            </TabPanel>
                            <TabPanel>

                                <div className="form-row">
                                    <div className="col-md-6">
                                        <div style={{ height: '100px', width: '100%' }} key={0}>
                                            <div className="media" key={0}>

                                                <Link to={`${process.env.PUBLIC_URL}/left-sidebar/product/1`}>

                                                    <img style={{ height: '100px' }} className="img-fluid" src={"https://www.actbus.net/fleetwiki/images/8/84/Noimage.jpg"} alt="" />
                                                    {/* } */}
                                                </Link>
                                                <div className="media-body align-self-center">
                                                    <div className="rating">
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                    </div>
                                                    <Link to={`${process.env.PUBLIC_URL}/left-sidebar/product/1`}><h6>name</h6></Link>
                                                    <p>description...</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">

                                        <form className="theme-form mt-4 " style={{ backgroundColor: '#fbfbfb' }}>
                                            <div className="col-md-12 ">
                                                <div className="media m-0">
                                                    <label>Rating</label>
                                                    <div className="media-body ml-3">
                                                        <div className="rating three-star">
                                                            <i className="fas fa-star" />
                                                            <i className="fas fa-star" />
                                                            <i className="fas fa-star" />
                                                            <i className="fas fa-star" />
                                                            <i className="fas fa-star" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="name">Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    placeholder="Enter Your name"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="email">Email</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="email"
                                                    placeholder="Email"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="review">Review Title</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="review"
                                                    placeholder="Enter your Review Subjects"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="review">Review Title</label>
                                                <textarea
                                                    className="form-control"
                                                    placeholder="Wrire Your Testimonial Here"
                                                    id="exampleFormControlTextarea1"
                                                    rows="6"
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <button className="btn btn-solid" type="submit">
                                                    Submit YOur Review
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        // allState: state
    }
}

export default connect(mapStateToProps, null)(DetailsTopTabs)

const renderString = String(`<!DOCTYPE html>
<html>
<title>W3.CSS</title>
<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
<body>

<div className="w3-container w3-teal">
<h1>Summer Holiday</h1>
</div>

<div className="w3-row-padding w3-margin-top">

<div className="w3-third">
<div className="w3-card">
<img src="img_5terre.jpg" style="width:100%">
<div className="w3-container">
<h4>5 Terre</h4>
</div>
</div>
</div>

<div className="w3-third">
<div className="w3-card">
<img src="img_monterosso.jpg" style="width:100%">
<div className="w3-container">
<h4>Monterosso</h4>
</div>
</div>
</div>

<div className="w3-third">
<div className="w3-card">
<img src="img_vernazza.jpg" style="width:100%">
<div className="w3-container">
<h4>Vernazza</h4>
</div>
</div>
</div>

</div>
<div className="w3-row-padding w3-margin-top">

<div className="w3-third">
<div className="w3-card">
<img src="img_manarola.jpg" style="width:100%">
<div className="w3-container">
<h4>Manarola</h4>
</div>
</div>
</div>

<div className="w3-third">
<div className="w3-card">
<img src="img_corniglia.jpg" style="width:100%">
<div className="w3-container">
<h4>Corniglia</h4>
</div>
</div>
</div>

<div className="w3-third">
<div className="w3-card">
<img src="img_riomaggiore.jpg" style="width:100%">
<div className="w3-container">
<h4>Riomaggiore</h4>
</div>
</div>
</div>

</div>

</body>
</html>
`)