import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux'

import { getBestSeller, getMensWear, getWomensWear } from '../../../services/index'
import { addToCart, addToWishlist, addToCompare } from "../../../actions/index";
import ProductItem from './product-item';

class SpecialProducts extends Component {
    render() {
        const { bestSeller, mensWear, womensWear, symbol, addToCart, addToWishlist, addToCompare } = this.props
        return (
            <div>
                <div className="title1 section-t-space">
                    <h2 className="title-inner1">Our Products</h2>
                </div>
                <section className="section-b-space p-t-0">
                    <div className="container">
                        <div className=" row">
                            {
                                bestSeller.map((product, index) =>
                                    <>
                                        {/* {console.log(product)} */}
                                        <ProductItem
                                            symbol={symbol}
                                            product={product}
                                            onAddToCompareClicked={() => addToCompare(product)}
                                            onAddToWishlistClicked={() => addToWishlist(product)}
                                            onAddToCartClicked={() => addToCart(product, 1)} key={index}
                                        />
                                    </>
                                )
                            }
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

    bestSeller: state.data.products,
    symbol: '₹'
})

export default connect(mapStateToProps, { addToCart, addToWishlist, addToCompare })(SpecialProducts);