import { faBell, faGift, faHeart, faLocationArrow, faShoppingBasket, faUserCircle, faUsers, faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import Seo from '../Seo';


class Dashboard extends Component {

    constructor(props) {
        super(props)
    }


    componentDidMount = () => {

        /* document.getElementById("common").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/common.css`);
*/
        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000); 

    }

    render() {


        return (
            <div>
                <Seo  description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company."   />
                <section className="pt-3 banner-6 ratio2_1 section-b-space section-t-space">
                    <div className="container">
                        <div className="row partition4">
                            <div className="col-md-3" >

                                <div className="collection-banner p-center" >
                                    <a href={`${process.env.PUBLIC_URL}/pages/user`}>
                                        <div className="img-part">
                                            <img style={{ height: '130px' }} src={`${process.env.PUBLIC_URL}/assets/images/download1.jpeg`}
                                                className="img-fluid blur-up lazyload bg-img" alt="" />
                                        </div>
                                        <div className="contain-banner banner-3 text-center">
                                            <div>
                                                <FontAwesomeIcon color={greyColor} size={'4x'} icon={faUserCircle} />
                                                <h6>My Account</h6>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="collection-banner p-center ">
                                    <a href={`${process.env.PUBLIC_URL}/pages/user`}>
                                        <div className="img-part">
                                            <img style={{ height: '130px' }} src={`${process.env.PUBLIC_URL}/assets/images/download1.jpeg`}
                                                className="img-fluid blur-up lazyload bg-img" alt="" />
                                        </div>
                                        <div className="contain-banner banner-3 text-center">
                                            <div>
                                                <FontAwesomeIcon color={greyColor} size={'4x'} icon={faGift} />
                                                <h6>My Orders</h6>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="collection-banner p-center">
                                    <a href={`${process.env.PUBLIC_URL}/pages/user`}>
                                        <div className="img-part">
                                            <img style={{ height: '130px' }} src={`${process.env.PUBLIC_URL}/assets/images/download1.jpeg`}
                                                className="img-fluid blur-up lazyload bg-img" alt="" />
                                        </div>
                                        <div className="contain-banner banner-3 text-center">
                                            <div>
                                                <FontAwesomeIcon color={greyColor} size={'4x'} icon={faLocationArrow} />

                                                <h6>My Addresses</h6>
                                            </div>
                                        </div>

                                    </a>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="collection-banner p-center">
                                    <a href={`${process.env.PUBLIC_URL}/pages/user`}>
                                        <div className="img-part">
                                            <img style={{ height: '130px' }} src={`${process.env.PUBLIC_URL}/assets/images/download1.jpeg`}
                                                className="img-fluid blur-up lazyload bg-img" alt="" />
                                        </div>
                                        <div className="contain-banner banner-3 text-center">
                                            <div>
                                                <FontAwesomeIcon color={greyColor} size={'4x'} icon={faWallet} />

                                                <h6>My Naswiz Wallet</h6>
                                            </div>
                                        </div>

                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row partition4 banner-top-cls">
                            <div className="col-md-3">
                                <div className="collection-banner p-center">
                                    <a href={`${process.env.PUBLIC_URL}/pages/user`}>
                                        <div className="img-part">
                                            <img style={{ height: '130px' }} src={`${process.env.PUBLIC_URL}/assets/images/download1.jpeg`}
                                                className="img-fluid blur-up lazyload bg-img" alt="" />
                                        </div>
                                        <div className="contain-banner banner-3 text-center">
                                            <div>
                                                <FontAwesomeIcon color={greyColor} size={'4x'} icon={faBell} />

                                                <h6>Notifications</h6>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="collection-banner p-center ">
                                    <a href={`${process.env.PUBLIC_URL}/pages/user`}>
                                        <div className="img-part">
                                            <img style={{ height: '130px' }} src={`${process.env.PUBLIC_URL}/assets/images/download1.jpeg`}
                                                className="img-fluid blur-up lazyload bg-img" alt="" />
                                        </div>
                                        <div className="contain-banner banner-3 text-center">
                                            <div>
                                                <FontAwesomeIcon color={greyColor} size={'4x'} icon={faHeart} />
                                                <h6>My Favourites</h6>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="collection-banner p-center">
                                    <a href={`${process.env.PUBLIC_URL}/pages/user`}>
                                        <div className="img-part">
                                            <img style={{ height: '130px' }} src={`${process.env.PUBLIC_URL}/assets/images/download1.jpeg`}
                                                className="img-fluid blur-up lazyload bg-img" alt="" />
                                        </div>
                                        <div className="contain-banner banner-3 text-center">
                                            <div>
                                                <FontAwesomeIcon color={greyColor} size={'4x'} icon={faShoppingBasket} />
                                                <h6>My Cart</h6>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="collection-banner p-center">
                                    <a href={`${process.env.PUBLIC_URL}/pages/user`}>
                                        <div className="img-part">
                                            <img style={{ height: '130px' }} src={`${process.env.PUBLIC_URL}/assets/images/download1.jpeg`}
                                                className="img-fluid blur-up lazyload bg-img" alt="" />
                                        </div>
                                        <div className="contain-banner banner-3 text-center">
                                            <div>
                                                <FontAwesomeIcon color={greyColor} size={'4x'} icon={faUsers} />

                                                <h6>Refer a Friend</h6>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>               

            </div>
        )
    }
}

export default Dashboard
const greyColor = '#727071';