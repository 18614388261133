import { faAt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { NaswizHelperApi } from '../../NaswizHelper';
import Breadcrumb from "../common/breadcrumb";
import { send } from 'emailjs-com';
import Notification from '../../Notification'
import renderHTML from 'react-render-html';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Seo from '../Seo';


class Contact extends Component {

    constructor(props) {
        super(props)
        this.state = {
            _detail: [],
            _address: '',
            _email: '',
            _phone: '',

            _fname: '',
            _laname: '',
            _replyEmail: '',
            _state: '',
            _city: '',
            _number: '',
            _descriptionmsg: '',
            arr_state: [],
            arr_city: [],
            selectedStateId: '',
            selectedCityID: '',
            selectedCountryId: '101',
            _countword: 0,
            _totalword: 500,
            limWords: null,
            wordCount: 0,
            isLoading: false,
            Loading: true,
        }

    }

    componentDidMount = () => {
        this.naswizGetHomeDataApi();
        this._getStateUponCountry();
        // this._checkmail();
        // var temp = "Hello is is World!";
        window.scrollTo(0, 0)
        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
    }

    countWords(event) {

        const wordCount =
            event.target.value === "" ? 0 : event.target.value.split(" ").length;
        this.setState({
            _descriptionmsg: event.target.value,
            wordCount: wordCount,
            limWords:
                this.state._totalword - wordCount < 0
                    ? this.state._descriptionmsg.length
                    : null
        });
    }

    naswizGetHomeDataApi = () => {
        var userID = this.props.userID
        var url = userID ? `v1/get-home/${userID}+'/0` : 'v1/get-home/0/0'

        this.setState({ loading_getHomeData: true }, async () => {
            var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(url, 'Error => v1/get-home')
            if (res && res.success == 1) {
                this.setState({
                    _detail: res.records.detail
                })
                this.setState({
                    Loading: false
                })
                if (this.state._detail.length > 0) {
                    var add = res.records.detail.find(item => item.name == 'address');
                    var email = res.records.detail.find(item => item.name == 'email');
                    var phone = res.records.detail.find(item => item.name == 'whatsapp');
                    this.setState({
                        _address: add.value,
                        _email: email.value,
                        _phone: phone.value,
                    })
                }


            }
            else {
                this.setState({
                    loading_getHomeData: false
                })
            }
        })
    }

    _getStateUponCountry = async () => {



        var formData = new FormData()
        formData.append('country_id', this.state.selectedCountryId)

        var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/state', formData, "Error => Get state upon county api")

        if (res && res.success == 1) {
            this.setState({
                arr_state: res.message
            })

            if (res.message.length > 0) {
                // this._getCityUponState(res.message[0].state_id)
            }
        }
    }

    _getCityUponState = async (sId) => {

        this.setState({ selectedStateId: sId })

        var formData = new FormData()
        formData.append('state_id', sId)
        formData.append('country_id', this.state.selectedCountryId)

        var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/cities', formData, "Error => Get state upon county api")
        if (res && res.success == 1) {
            this.setState({
                arr_city: res.message
            })
            if (res.message.length > 0) {
                // this.setState({ selectedCityId: res.message[0].city_id })

            }
        }
    }

    sendMail = async () => {

        var isValide = true;
        if (this.state._fname == '') {
            Notification('error', 'Error!', 'Please enter first name. !')
            isValide = false;
        }
        if (this.state._laname == '') {
            Notification('error', 'Error!', 'Please enter last name. !')
            isValide = false;
        }
        if (this.state._descriptionmsg == '') {
            Notification('error', 'Error!', 'Please enter message. !')
            isValide = false;
        }
        if (this.state._replyEmail == '') {
            Notification('error', 'Error!', 'Please enter email. !')
            isValide = false;
        } if (this.state.selectedStateId == '') {
            Notification('error', 'Error!', 'Please select state. !')
            isValide = false;
        } if (this.state.selectedCityID == '') {
            Notification('error', 'Error!', 'Please select city. !')
            isValide = false;
        }
        if (isValide) {
            var formData = new FormData()
            formData.append('firstname', this.state._fname)
            formData.append('lastname', this.state._laname)
            formData.append('email', this.state._replyEmail)
            formData.append('number', this.state._number)
            formData.append('message', this.state._descriptionmsg)
            formData.append('country_id', this.state.selectedCountryId)
            formData.append('state_id', this.state.selectedStateId)
            formData.append('city_id', this.state.selectedCityID)

            for (var pair of formData.entries()) {
            }

            this.setState({ isLoading: true })
            var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/contact-us', formData, "Error => Get state upon county api")
            this.setState({ isLoading: false })

            if (res && res.success == 1) {
                Notification('success', 'Success!', 'Message Send Successfully.')
                this.setState({
                    _fname: '',
                    _laname: '',
                    _replyEmail: '',
                    _state: '',
                    _city: '',
                    _number: '',
                    _descriptionmsg: '',
                    selectedStateId: '',
                    selectedCityID: ''
                })


            }
        }
    }


    render() {
        const { _fname, _laname, _replyEmail, _city, _state, _number, _descriptionmsg } = this.state
        // const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //     this._checkmail = ()=>{
        //         if (!_replyEmail=== '' ||re.test(_replyEmail) == false) {
        //             Notification('error', 'Error!', 'Invaid Email !')
        //         }
        //         else{
        //             return _replyEmail
        //         }
        //     }



        return (
            <div>

                {/* <Breadcrumb title={'Contact Us'} /> */}
                {/* <Helmet>
                    <title>Help Center | Customer Care Support  - Naswiz Retails Pvt Ltd</title>
                    <meta name="og_title" property="og:title" content="Help Center | Customer Care Support  - Naswiz Retails Pvt Ltd" />
                    <meta name="twitter:title" content="Help Center | Customer Care Support  - Naswiz Retails Pvt Ltd" />
                    <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                    <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                    <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                    <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                </Helmet> */}
                <Seo title="Contact Us" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company."   />
                {/*Forget Password section*/}

                {!this.state.Loading ?
                    <section className="contact-page pb-3">
                        <div className="container">
                            <div className="row mb-30">
                                <div className="col-lg-7 map">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.861125060559!2d77.14753161549612!3d28.693800588055343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d023323c40535%3A0xf65f01fc5f865c34!2sAggarwal%20Millenium%20Tower-I!5e0!3m2!1sen!2sin!4v1617274408464!5m2!1sen!2sin" allowFullScreen="" loading="lazy"></iframe>
                                </div>
                                <div className="col-lg-5">
                                    <div className="contact-right pt-4 pb-4">
                                        <ul className="d-flex align-content-center flex-column">
                                            <li className="d-flex align-items-center">
                                                <div className="contact-icon text-center">
                                                    {/* <FontAwesomeIcon size={'2x'} color={appColor} icon={faWhatsapp} /> */}
                                                    <i style={{ fontSize: '2em', color: '#bd3042' }} className="fab fa-whatsapp"></i>
                                                    <h6 style={{ color: appColor }} className="text-uppercase">WhatsApp</h6>
                                                </div>
                                                <div className="media-body">
                                                    {/* <a href={`tel:${this.state._phone}`}><p>{this.state._phone}</p></a> */}
                                                    <a href={`https://wa.me/${this.state._phone}`} target="_blank"><p className="mb-0">{this.state._phone}</p></a>
                                                </div>
                                            </li>
                                            <li className="d-flex align-items-center">
                                                <div className="contact-icon text-center">
                                                    <FontAwesomeIcon size={'2x'} color={appColor} icon={faEnvelope} />
                                                    <h6 style={{ color: appColor }} className="text-uppercase">Address</h6>
                                                </div>
                                                <div className="media-body">
                                                    {/* {renderHTML(String(`${this.state._address}`))} */}
                                                    <p className="address-txt">{renderHTML(String(`${this.state._address}`))}</p>
                                                </div>
                                            </li>
                                            <li className="d-flex align-items-center">
                                                <div className="contact-icon text-center">
                                                    <FontAwesomeIcon size={'2x'} color={appColor} icon={faAt} />
                                                    <h6 style={{ color: appColor, }} className="text-uppercase">Email</h6>
                                                </div>
                                                <div className="media-body">
                                                    <a href={`mailto:${this.state._email}`}><p className="mb-0">{this.state._email}</p></a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-30">
                                <div className="col-sm-12">
                                    <form className="contact-form ">
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="name">First Name</label>
                                                    <input type="text" className="form-control" id="_fname" value={_fname}
                                                        onChange={e => this.setState({ _fname: e.target.value })}
                                                        placeholder="Enter Your name" required="" autoFocus />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="email">Last Name</label>
                                                    <input type="text" className="form-control" id="_lname" value={_laname}
                                                        onChange={e => this.setState({ _laname: e.target.value })}
                                                        placeholder="Last Name" required="" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="review">Phone number</label>
                                                    <input type="number" autoComplete='tel' maxLength={10} minLength="10" className="form-control" id="_number" value={_number}
                                                        onChange={e => this.setState({ _number: e.target.value })}
                                                        placeholder="Enter your number" required="" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="email">Email</label>

                                                    <input type="email" className="form-control" id="_replyEmail" value={_replyEmail}
                                                        onChange={e => this.setState({ _replyEmail: e.target.value })} placeholder="Email"
                                                        required />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">State<sup className="top_star text-danger">*</sup></label>
                                                    <select
                                                        onChange={(e) => this._getCityUponState(e.target.value)} className="form-control">
                                                        <option value={''}>Select State</option>
                                                        {

                                                            this.state.selectedCountryId !== undefined ?
                                                                this.state.arr_state.map((item, index) => {
                                                                    return (
                                                                        <option key={index} value={item.state_id}
                                                                            checked={this.state.selectedStateId === item.state_id}

                                                                        >{item.name}</option>
                                                                    )
                                                                })
                                                                :
                                                                <option value={undefined}>First Select Country</option>

                                                        }
                                                    </select>

                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">City<sup className="top_star text-danger">*</sup></label>
                                                    <select
                                                        onChange={(e) => this.setState({ selectedCityID: e.target.value })} className="form-control">
                                                        <option value={''}>Select City</option>
                                                        {
                                                            this.state.selectedStateId !== undefined ?
                                                                this.state.arr_city.map((item, index) => {
                                                                    return (
                                                                        <option key={index} value={item.city_id}
                                                                            checked={this.state.selectedCityID === item.city_id}
                                                                        >{item.name}</option>
                                                                    )
                                                                })
                                                                :
                                                                <option value={undefined}>First Select State</option>

                                                        }
                                                    </select>
                                                </div>

                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="review">Write Your Message</label>
                                                    <textarea
                                                        maxLength={this.state.limWords} //500 words near
                                                        className="form-control" placeholder="Write Your Message"
                                                        id="_descriptionmsg" value={_descriptionmsg}
                                                        onChange={e => this.countWords(e)}></textarea>
                                                    {this.state.wordCount > this.state._totalword ? <h6>*Allow 500 words</h6> : null}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                {/* <div className="subment-button" onClick={() => this.sendMail()}> */}
                                                <div onClick={() => this.sendMail()}>
                                                    {this.state.isLoading ?
                                                        <a className="btninner" href="javascript:void(0)">
                                                            <div className="loader-wrapper">
                                                                <div className="loader"></div>
                                                            </div>
                                                        </a> :
                                                        <a className="btn theme-button corner-button
                                                    " href="javascript:void(0)">Send Your Message</a>

                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                    : <div className="loader-wrapper">
                        <div className="loader"></div>
                    </div>
                }

            </div>
        )
    }
}

export default Contact

const appColor = '#bd3042'