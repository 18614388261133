import React, { Component } from 'react';
import Slider from "react-slick"
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
// import Custom Components
import { Row, Tooltip, Col, Checkbox, Icon, Progress, Input, Tag, Select, Rate } from 'antd';
import {
    getBestSellerProducts,
    getNewProducts,
    getTrendingCollection
} from "../../../services";
import './fashion.css'

class MultipleSlider extends Component { 
 
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    redirectToNextPage = (item) => {
        return (
            <Redirect to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.slug}` }} />
        )
    }


    render() {
        const { featureSliderData, bestSellerSliderData, latestSliderData, topRatedSliderData, items, newProducts, featureProducts, bestSeller, onSell, symbol, multiSliderData } = this.props;
        return (
 
            <div className="product-categorized-wrapper mt-30 mb-30">
                <div className="row"> 
                    <div className="col-lg-3 col-sm-6">
                        {
                            featureSliderData && featureSliderData.length > 0 ? <h4 className="text-uppercase">Featured Products</h4> : null}

                        {
                            featureSliderData && featureSliderData.length > 0 ?
                                featureSliderData.map((item, index) => {
                                    return (
                                        index < 3 ?
                                            <div key={index} className="d-flex align-items-center product-widget">
                                                <figure className="border">
                                                    <Link to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.categories[0].slug}/${item.slug}`, state: [] }}>
                                                        <img className={'feature_prod_small_img prod_four_list_small_img'} src={item.product_images[0] ? item.product_images[0].image : `${process.env.PUBLIC_URL}/assets/images/nia.png`} />
                                                    </Link>
                                                </figure>
                                                <div className="product-details text-truncate">
                                                    <h2 className="text-capitalize text-truncate">
                                                        <Link to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.categories[0].slug}/${item.slug}`, state: [] }}
                                                        >{item.name}</Link>
                                                    </h2>
                                                    <div className="ratings-container">
                                                        <Rate className="rate" defaultValue={item.avg_rating} disabled />
                                                    </div>
                                                    <div className="price-box">
                                                        <span className="product-price">₹{item.wholesale_price > 0 ? item.wholesale_price : item.price}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    )
                                })
                                : null
                        }
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        {
                            bestSellerSliderData && bestSellerSliderData.length > 0 ? <h4 className="text-uppercase">Best Selling Products</h4> : null}

                        {
                            bestSellerSliderData && bestSellerSliderData.length > 0 ?
                                bestSellerSliderData.map((item, index) => {
                                    return (
                                        index < 3 ?
                                            <div key={index} className="d-flex align-items-center product-widget">
                                                <figure className="border">
                                                    <Link to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.categories[0].slug}/${item.slug}`, state: [] }}>
                                                        <img className={'feature_prod_small_img prod_four_list_small_img'} src={item.product_images[0] ? item.product_images[0].image : `${process.env.PUBLIC_URL}/assets/images/nia.png`} />
                                                    </Link>
                                                </figure>
                                                <div className="product-details text-truncate">
                                                    <h2 className="text-capitalize text-truncate">

                                                        <Link to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.categories[0].slug}/${item.slug}`, state: [] }}
                                                        >{item.name}</Link>
                                                    </h2>
                                                    <div className="ratings-container">
                                                        <Rate className="rate" defaultValue={item.avg_rating} disabled />
                                                    </div>
                                                    <div className="price-box">
                                                        <span className="product-price">₹{item.wholesale_price > 0 ? item.wholesale_price : item.price}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    )
                                })
                                : null
                        }
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        {
                            latestSliderData && latestSliderData.length > 0 ? <h4 className="text-uppercase">Latest Products</h4> : null}

                        {
                            latestSliderData && latestSliderData.length > 0 ?
                                latestSliderData.map((item, index) => {
                                    return (
                                        index < 3 ?
                                            <div key={index} className="d-flex align-items-center product-widget">
                                                <figure className="border">
                                                    <Link to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.categories[0].slug}/${item.slug}`, state: [] }}>
                                                        <img className={'feature_prod_small_img prod_four_list_small_img'} src={item.product_images[0] ? item.product_images[0].image : `${process.env.PUBLIC_URL}/assets/images/nia.png`} />
                                                    </Link>
                                                </figure>
                                                <div className="product-details text-truncate">
                                                    <h2 className="text-capitalize text-truncate">

                                                        <Link to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.categories[0].slug}/${item.slug}`, state: [] }}
                                                        >{item.name}</Link>
                                                    </h2>
                                                    <div className="ratings-container">
                                                        <Rate className="rate" defaultValue={item.avg_rating} disabled />
                                                    </div>
                                                    <div className="price-box">
                                                        <span className="product-price">₹{item.wholesale_price > 0 ? item.wholesale_price : item.price}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    )
                                })
                                : null
                        }

                    </div>
                    <div className="col-lg-3 col-sm-6">
                        {
                            topRatedSliderData && topRatedSliderData.length > 0 ? <h4 className="text-uppercase">Top Rated Products</h4> : null}

                        {
                            topRatedSliderData && topRatedSliderData.length > 0 ?
                                topRatedSliderData.map((item, index) => {
                                    return (
                                        index < 3 ?
                                            <div key={index} className="d-flex align-items-center product-widget">
                                                <figure className="border">
                                                    <Link to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.categories[0].slug}/${item.slug}`, state: [] }}>
                                                        <img className={'feature_prod_small_img prod_four_list_small_img'} src={item.product_images[0] ? item.product_images[0].image : `${process.env.PUBLIC_URL}/assets/images/nia.png`} />
                                                    </Link>
                                                </figure>
                                                <div className="product-details text-truncate">
                                                    <h2 className="text-capitalize text-truncate">

                                                        <Link to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.categories[0].slug}/${item.slug}`, state: [] }}
                                                        >{item.name}</Link>
                                                    </h2>
                                                    <div className="ratings-container">
                                                        <Rate className="rate" defaultValue={item.avg_rating} disabled />
                                                    </div>
                                                    <div className="price-box">
                                                        <span className="product-price">₹{item.wholesale_price > 0 ? item.wholesale_price : item.price}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    )
                                })
                                : null
                        }

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({

    symbol: state.data.symbol,
    All_Data: state,
})




export default connect(mapStateToProps)(MultipleSlider);
