import React from 'react'
import { connect } from 'react-redux'
import { NaswizHelperApi } from '../../../NaswizHelper';
import { Spin } from 'antd'
import { Modal, Input, DatePicker, Button, Radio } from 'antd'
import moment from "moment";
import './innerdashboard.css';
import Notification from '../../../Notification'
import { InlineReactionButtons } from 'sharethis-reactjs';
import { InlineShareButtons } from 'sharethis-reactjs';
import { StickyShareButtons } from 'sharethis-reactjs';
import { InlineFollowButtons } from 'sharethis-reactjs';

import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    

    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
} from "react-share";
import { Helmet } from 'react-helmet';
import Seo from '../../Seo';


class InnerDashboardReferFriends extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id_no: '',
            loading_idNumber: true,
            cancleOrderModalVisibility: false,
            loading_wallet: true
        }
    }

    componentDidMount = () => {
        this._getReferCode()
    }

    _getReferCode = async () => {

        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/customers-detail', null, "Error => v1/customers-detail api.")    

        if (res && res.success == 1) {
            this.setState({
                id_no: res.record.id_no,
                loading_idNumber: false,
                loading_wallet: false
            })
        }
        else {
            this.setState({
                id_no: 'Unable to load.',
                loading_idNumber: false
            })
        }
    }
    _resetCreateOrderIssueState = () => {
        this.setState({
            open_order_number: '',
            open_order_subject: '',
            open_order_description: '',
            createOrderIssueModal: false
        })
    }
    render() {

        return (
            
            <div className="col-lg-9 order-lg-last dashboard-content">
                {/* <Helmet> 
                <title>Refer a Friend - Naswiz Retails Pvt Ltd</title>
                <meta name="og_title" property="og:title" content="My Account - Naswiz Retails Pvt Ltd" />
                <meta name="twitter:title" content="My Account - Naswiz Retails Pvt Ltd" />
                <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />		
                </Helmet> */}
                    <Seo title="Refer a Friend" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company."   />

                {
                    this.state.loading_wallet ?
                        <div>
                            <div className="loader-wrapper">
                                <div className="loader"></div>
                            </div>
                        </div> :
                        <div className="inside_notifications">
                            <img src="assets/images/refer.jpg" alt="" />
                            <p><span>REFER A FRIEND</span> AND EARN 100 LOYALTY POINTS</p>
                            <div className="note_area">
                                <div className="left_noti">
                                    {
                                        this.state.loading_idNumber ?
                                            <Spin /> :
                                            <>
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/referral_code.png`} alt="" />
                                                <h4 onClick={() =>{  
                                                    navigator.clipboard.writeText(this.state.id_no)
                                                    Notification('success', 'Success!', 'Referral code Copied!')
                                                    }}>{this.state.id_no}</h4>
                                                
                                            </>
                                    }
                                </div> 
                            </div>

                        </div>
                }
                

                
                            <div className="inside_ticket bg-transparent">                               

                                <div className="product-single-share">
                                    <div className="social-icons">
                                        <FacebookShareButton
                                            url="http://myshopwiz.com/"
                                            quote={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""
                                        >
                                            <a className="social-icon social-facebook icon-facebook" target="_blank" title="Facebook"></a>

                                            {/* <FacebookIcon size={36} /> */}
                                        </FacebookShareButton>
                                        <TwitterShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""
                                        //  className={classes.socialMediaButton}
                                        >
                                            <a className="social-icon social-twitter icon-twitter" target="_blank" title="Twitter"></a>
                                        </TwitterShareButton>
                                        <WhatsappShareButton
                                            url="http://myshopwiz.com/"
                                            separator=":: "
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            
                                        //  className={classes.socialMediaButton}
                                        >
                                            {/* <a className="social-icon social-Whatsapp fab fa-Whatsapp-in" target="_blank" title="Whatsapp"></a> */}
                                            <WhatsappIcon size={28} />
                                        </WhatsappShareButton>
                                        <LinkedinShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            summary={""}
                                            
                                        >
                                            <a className="social-icon social-linkedin fab fa-linkedin-in" target="_blank" title="Linkedin"></a>
                                        </LinkedinShareButton>
                                        <EmailShareButton
                                            // url="http://myshopwiz.com/"
                                            body="http://myshopwiz.com/"
                                            subject={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            separator=" "
                                        //  className={classes.socialMediaButton}
                                        >
                                            <a className="social-icon social-mail icon-mail-alt" target="_blank" title="Mail"></a>
                                        </EmailShareButton>
                                        <HatenaShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <HatenaIcon className="hateicon" size={28} />
                                        </HatenaShareButton>

                                        <InstapaperShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <InstapaperIcon className="instapaper" size={28} />
                                        </InstapaperShareButton>
                                        <LineShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <LineIcon className="linkicon" size={28} />
                                        </LineShareButton>
                                        <LivejournalShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <LivejournalIcon className="liveiocn" size={28} />
                                        </LivejournalShareButton>
                                        <MailruShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <MailruIcon className="mailruicon" size={28} />
                                        </MailruShareButton>
                                        <OKShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <OKIcon className="okicon" size={28} />
                                        </OKShareButton>
                                        <PinterestShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <PinterestIcon className="pinteresticon" size={28} />
                                        </PinterestShareButton>
                                        <PocketShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <PocketIcon className="pocketicon" size={28} />
                                        </PocketShareButton>
                                        <RedditShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <RedditIcon className="redditicon" size={28} />
                                        </RedditShareButton>
                                        <TelegramShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <TelegramIcon className="telegramicon" size={28} />
                                        </TelegramShareButton>
                                        <TumblrShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <TumblrIcon className="tumblricon" size={28} />
                                        </TumblrShareButton>
                                        <ViberShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <ViberIcon className="vibericon" size={28} />
                                        </ViberShareButton>
                                        <VKShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <VKIcon className="vkicon" size={28} />
                                        </VKShareButton>
                                        <WorkplaceShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <WorkplaceIcon className="workicon" size={28} />
                                        </WorkplaceShareButton>
                                    </div>
                                </div>
                            </div>
                <Modal className="share-button"
                    // title="Share Referral"
                    footer={null}                    
                    visible={this.state.cancleOrderModalVisibility}
                    onClick={() => { this.setState({ cancleOrderModalVisibility: false }) }}
                >
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="inside_ticket">
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                        <div className="notification_head">
                                            <span>  <h3>Share Referral</h3></span>
                                        </div>
                                        <button type="button" className="ant-modal-close" onClick={() => { this.setState({ cancleOrderModalVisibility: false }) }}>
                                            <span className="ant-modal-close-x">
                                                <span role="img" className="anticon anticon-close ant-modal-close-icon">
                                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg>
                                                </span>
                                            </span>
                                        </button>
                                    </div>
                                </div>

                                <div className="product-single-share">
                                    <div className="social-icons">
                                        <FacebookShareButton
                                            url="http://myshopwiz.com/"
                                            quote={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""
                                        >
                                            <a className="social-icon social-facebook icon-facebook" target="_blank" title="Facebook"></a>

                                            {/* <FacebookIcon size={36} /> */}
                                        </FacebookShareButton>
                                        <TwitterShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""
                                        //  className={classes.socialMediaButton}
                                        >
                                            <a className="social-icon social-twitter icon-twitter" target="_blank" title="Twitter"></a>
                                        </TwitterShareButton>
                                        <WhatsappShareButton
                                            url="http://myshopwiz.com/"
                                            separator=":: "
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            
                                        //  className={classes.socialMediaButton}
                                        >
                                            {/* <a className="social-icon social-Whatsapp fab fa-Whatsapp-in" target="_blank" title="Whatsapp"></a> */}
                                            <WhatsappIcon size={28} />
                                        </WhatsappShareButton>
                                        <LinkedinShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            summary={""}
                                            
                                        >
                                            <a className="social-icon social-linkedin fab fa-linkedin-in" target="_blank" title="Linkedin"></a>
                                        </LinkedinShareButton>
                                        <EmailShareButton
                                            // url="http://myshopwiz.com/"
                                            body="http://myshopwiz.com/"
                                            subject={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            separator=" "
                                        //  className={classes.socialMediaButton}
                                        >
                                            <a className="social-icon social-mail icon-mail-alt" target="_blank" title="Mail"></a>
                                        </EmailShareButton>
                                        <HatenaShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <HatenaIcon size={28} />
                                        </HatenaShareButton>

                                        <InstapaperShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <InstapaperIcon size={28} />
                                        </InstapaperShareButton>
                                        <LineShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <LineIcon size={28} />
                                        </LineShareButton>
                                        <LivejournalShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <LivejournalIcon size={28} />
                                        </LivejournalShareButton>
                                        <MailruShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <MailruIcon size={28} />
                                        </MailruShareButton>
                                        <OKShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <OKIcon size={28} />
                                        </OKShareButton>
                                        <PinterestShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <PinterestIcon size={28} />
                                        </PinterestShareButton>
                                        <PocketShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <PocketIcon size={28} />
                                        </PocketShareButton>
                                        <RedditShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <RedditIcon size={28} />
                                        </RedditShareButton>
                                        <TelegramShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <TelegramIcon size={28} />
                                        </TelegramShareButton>
                                        <TumblrShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <TumblrIcon size={28} />
                                        </TumblrShareButton>
                                        <ViberShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <ViberIcon size={28} />
                                        </ViberShareButton>
                                        <VKShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <VKIcon size={28} />
                                        </VKShareButton>
                                        <WorkplaceShareButton
                                            url="http://myshopwiz.com/"
                                            title={`Hello Friend. This is myshopwiz referral code ${this.state.id_no}. Use this code during sign up. `}
                                            hashtag=""                                        
                                        >
                                            <WorkplaceIcon size={28} />
                                        </WorkplaceShareButton>
                                    </div>
                                </div>
                                <button
                                    onClick={() => { this.setState({ cancleOrderModalVisibility: false }) }}
                                    type='button'
                                    className="button_desing">
                                    CANCEL
                                    </button>
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        allState: state
    }
}

export default connect(mapStateToProps, null)(InnerDashboardReferFriends)