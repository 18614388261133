import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import NewProduct from "../common/new-product";
import Filter from "./common/filter";
import FilterBar from "./common/filter-bar";
import ProductListing from "./common/product-listing";
import StickyBox from "react-sticky-box";
import Seo from '../Seo';


class CollectionRightSidebar extends Component {

    state = {
        layoutColumns: 3
    }

    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns: colums
        })
    }
    openFilter = () => {
        document.querySelector(".collection-filter").style = "left: -15px";
    }
    render() {
        return (
            <div>
                <Seo title="Collection" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                <Breadcrumb title={'Collection'} />
                <section className="section-b-space">
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="collection-content">
                                    <div className="page-main-content">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="top-banner-wrapper">
                                                        <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/mega-menu/2.jpg`} className="img-fluid" alt="" /></a>
                                                        <div className="top-banner-content small-section">
                                                            <h4>fashion</h4>
                                                            <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h5>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
                                                        </div>
                                                    </div>
                                                    <div className="collection-product-wrapper">
                                                        <div className="product-top-filter">
                                                            <div className="container-fluid p-0">
                                                                <div className="row">
                                                                    <div className="col-xl-12">
                                                                        <div className="filter-main-btn">
                                                                            <span onClick={this.openFilter}
                                                                                className="filter-btn btn btn-theme"><i
                                                                                    className="fa fa-filter"
                                                                                    aria-hidden="true"></i> Filter</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <FilterBar onLayoutViewClicked={(colmuns) => this.LayoutViewClicked(colmuns)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*Products Listing Component*/}
                                                        <ProductListing colSize={this.state.layoutColumns} />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 collection-filter">

                                    <StickyBox offsetTop={20} offsetBottom={20}>
                                        <div>
                                            <Filter />
                                            <NewProduct />
                                        </div>
                                    </StickyBox>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default CollectionRightSidebar;


//////////////////////////////////////////////////////////////////////////
///////////////////// functional component ///////////////////////////////
//////////////////////////////////////////////////////////////////////////

// import React, {  useState } from 'react';
// import Breadcrumb from "../common/breadcrumb";
// import NewProduct from "../common/new-product";
// import Filter from "./common/filter";
// import FilterBar from "./common/filter-bar";
// import ProductListing from "./common/product-listing";
// import StickyBox from "react-sticky-box";


// const  CollectionRightSidebar = (props)=> {
//     const [layoutColumns, setLayoutColumns] = useState(3)
//     // state = {
//     //     layoutColumns: 3
//     // }

//     const LayoutViewClicked= (colums)=> {
//             setLayoutColumns(colums)
//     }
//     const openFilter = () => {
//         document.querySelector(".collection-filter").style = "left: -15px";
//     }
    
//         return (
//             <div>
//                 <Breadcrumb title={'Collection'} />
//                 <section className="section-b-space">
//                     <div className="collection-wrapper">
//                         <div className="container">
//                             <div className="row">
//                                 <div className="collection-content">
//                                     <div className="page-main-content">
//                                         <div className="">
//                                             <div className="row">
//                                                 <div className="col-sm-12">
//                                                     <div className="top-banner-wrapper">
//                                                         <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/mega-menu/2.jpg`} className="img-fluid" alt="" /></a>
//                                                         <div className="top-banner-content small-section">
//                                                             <h4>fashion</h4>
//                                                             <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h5>
//                                                             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
//                                                         </div>
//                                                     </div>
//                                                     <div className="collection-product-wrapper">
//                                                         <div className="product-top-filter">
//                                                             <div className="container-fluid p-0">
//                                                                 <div className="row">
//                                                                     <div className="col-xl-12">
//                                                                         <div className="filter-main-btn">
//                                                                             <span onClick={openFilter}
//                                                                                 className="filter-btn btn btn-theme"><i
//                                                                                     className="fa fa-filter"
//                                                                                     aria-hidden="true"></i> Filter</span>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                                 <div className="row">
//                                                                     <div className="col-12">
//                                                                         <FilterBar onLayoutViewClicked={(colmuns) => LayoutViewClicked(colmuns)} />
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>

//                                                         {/*Products Listing Component*/}
//                                                         <ProductListing colSize={layoutColumns} />

//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-sm-3 collection-filter">

//                                     <StickyBox offsetTop={20} offsetBottom={20}>
//                                         <div>
//                                             <Filter />
//                                             <NewProduct />
//                                         </div>
//                                     </StickyBox>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </section>

//             </div>
//         )
    
// }

// export default CollectionRightSidebar;