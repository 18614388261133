import React, { Component } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faStar } from '@fortawesome/free-solid-svg-icons';
import { getBestSeller } from "../../services";
import { Slider4 } from "../../services/script";
import Modal from 'react-responsive-modal';

class RealatedProducts extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            stock: 'InStock',
            quantity: 1,
            image: '',
        }
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };


    render() {
        const { items, symbol, onAddToCartClicked } = this.props;
        var arrays = [];
        let RatingStars = []
        for (var i = 0; i < 5; i++) {
            RatingStars.push(<i className="fas fa-star" key={i}></i>)
        }
        return (            
            <div style={{ paddingTop: '50px' }} className="theme-card">
                <h4 className="title-border">Related Products</h4>
                <Slider {...Slider4} className="offer-slider related_pro slide-1">
                    {
                        items.map((product, i) =>

                            <div className="product-box">
                                <div className="img-wrapper">

                                    <div className="front">
                                        <Link to={`${process.env.PUBLIC_URL}/product-detail/${product.slug}`} >
                                            <img
                                                src={product.product_images[0] ? product.product_images[0].image : `${process.env.PUBLIC_URL}/assets/images/nia.png`}
                                                className="img-fluid"
                                                alt="" />
                                        </Link>
                                    </div>
                                    <div className="features cart-info cart-wrap" style={{ margin: '15px 15px 15px 0' }}>
                                        <button className="btn-solid" data-toggle="modal"
                                            data-target="#quick-view"
                                            title="Quick View"
                                            onClick={this.onOpenModal}>
                                            Quick View
                                            </button>                                       
                                    </div>                                   

                                </div>
                                <div className="product-detail">
                                    <div>

                                        <h6 style={{ color: '#bd3042' }}>{product.name}</h6>

                                        <Link to={`${process.env.PUBLIC_URL}/product-detail/${product.slug}`}>

                                            <h6>{product.name}</h6>

                                        </Link>
                                        <div className="rating">
                                            {RatingStars}
                                        </div>

                                        {
                                            product.wholesale_price ?
                                                <h4>{"₹"}{product.wholesale_price}
                                                    <del><span className="money"> {symbol} {product.price}</span></del>
                                                </h4> :
                                                <h4>{"₹"}{product.price}
                                                    {/* <del><span className="money">{symbol}{product.price}</span></del> */}
                                                </h4>
                                        }
                                    </div>
                                </div>
                                <Modal open={this.state.open} onClose={this.onCloseModal} center>
                                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                                        <div className="modal-content quick-view-modal">
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-lg-6  col-xs-12">
                                                        <div className="quick-view-img">
                                                            <img
                                                                src={product.product_images[0] ? product.product_images[0].image : `${process.env.PUBLIC_URL}/assets/images/nia.png`}
                                                                className="img-fluid"
                                                                alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 rtl-text">
                                                        <div className="product-right">
                                                            <h2> {product.name} </h2>
                                                            {
                                                                product.wholesale_price ?
                                                                    <h4>{"₹"}{product.wholesale_price}
                                                                        <del><span className="money"> {symbol} {product.price}</span></del>
                                                                    </h4> :
                                                                    <h4>{"₹"}{product.price}
                                                                        {/* <del><span className="money">{symbol}{product.price}</span></del> */}
                                                                    </h4>
                                                            }                                                            
                                                            <div className="border-product">
                                                                <h6 className="product-title">product details</h6>
                                                                <p>{product.description}</p>
                                                            </div>
                                                            <div className="product-description border-product">
                                                                {product.size ?
                                                                    <div className="size-box">
                                                                        <ul>
                                                                            {product.size.map((size, i) => {
                                                                                return <li key={i}><a href="#">{size}</a></li>
                                                                            })}
                                                                        </ul>
                                                                    </div> : ''}
                                                                <h6 className="product-title">quantity</h6>
                                                                <div className="qty-box">
                                                                    <div className="input-group">
                                                                        <span className="input-group-prepend">
                                                                            <button type="button" className="btn quantity-left-minus" onClick={this.minusQty} data-type="minus" data-field="">
                                                                                <i className="fa fa-angle-left"></i>
                                                                            </button>
                                                                        </span>
                                                                        <input type="text" name="quantity" value={this.state.quantity} onChange={this.changeQty} className="form-control input-number" />
                                                                        <span className="input-group-prepend">
                                                                            <button type="button" className="btn quantity-right-plus" onClick={this.plusQty} data-type="plus" data-field="">
                                                                                <i className="fa fa-angle-right"></i>
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="product-buttons">
                                                                <button className="btn btn-solid" onClick={() => onAddToCartClicked(product, this.state.quantity)} >add to cart</button>
                                                                <Link to={`${process.env.PUBLIC_URL}/product-detail/${product.id}`} className="btn btn-solid">view detail</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        )}
                </Slider>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        // items: state.data.products,
        // symbol: state.data.symbol
    }
}

export default connect(mapStateToProps, null)(RealatedProducts)

//////////////////////////////////////////////////////////////////////////////////////
/////////////////////////// functional component /////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////

// import React, { useState } from 'react';
// import Slider from 'react-slick';
// import { connect } from 'react-redux';
// import { Link } from 'react-router-dom'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleLeft, faAngleRight, faStar } from '@fortawesome/free-solid-svg-icons';
// import { getBestSeller } from "../../services";
// import { Slider4 } from "../../services/script";
// import Modal from 'react-responsive-modal';

// const RealatedProducts = (props) => {


//     const [open, setOpen] = useState(false)
//     const [quantity, setQuantity] = useState(1)
//     const [stock, setStock] = useState('InStock')
//     const [image, setImage] = useState('')

//     // this.state = {
//     //     open: false,
//     //     stock: 'InStock',
//     //     quantity: 1,
//     //     image: '',
//     // }

//    const  onOpenModal = () => {
//         setOpen(true) 
//     };

//     const onCloseModal = () => {
//         setOpen(false)
//     };

//         const { items, symbol, onAddToCartClicked } = props;
//         var arrays = [];
//         let RatingStars = []
//         for (var i = 0; i < 5; i++) {
//             RatingStars.push(<i className="fas fa-star" key={i}></i>)
//         }
//         return (
//             <div style={{ paddingTop: '50px' }} className="theme-card">
//                 <h4 className="title-border">Related Products</h4>
//                 <Slider {...Slider4} className="offer-slider related_pro slide-1">
//                     {
//                         items.map((product, i) =>

//                             <div className="product-box">
//                                 <div className="img-wrapper">

//                                     <div className="front">
//                                         <Link to={`${process.env.PUBLIC_URL}/product-detail/${product.slug}`} >
//                                             <img
//                                                 src={product.product_images[0] ? product.product_images[0].image : `${process.env.PUBLIC_URL}/assets/images/nia.png`}
//                                                 className="img-fluid"
//                                                 alt="" />
//                                         </Link>
//                                     </div>
//                                     <div className="features cart-info cart-wrap" style={{ margin: '15px 15px 15px 0' }}>
//                                         <button className="btn-solid" data-toggle="modal"
//                                             data-target="#quick-view"
//                                             title="Quick View"
//                                             onClick={onOpenModal}>
//                                             Quick View
//                                         </button>
//                                     </div>

//                                 </div>
//                                 <div className="product-detail">
//                                     <div>

//                                         <h6 style={{ color: '#bd3042' }}>{product.name}</h6>

//                                         <Link to={`${process.env.PUBLIC_URL}/product-detail/${product.slug}`}>

//                                             <h6>{product.name}</h6>

//                                         </Link>
//                                         <div className="rating">
//                                             {RatingStars}
//                                         </div>

//                                         {
//                                             product.wholesale_price ?
//                                                 <h4>{"₹"}{product.wholesale_price}
//                                                     <del><span className="money"> {symbol} {product.price}</span></del>
//                                                 </h4> :
//                                                 <h4>{"₹"}{product.price}
//                                                     {/* <del><span className="money">{symbol}{product.price}</span></del> */}
//                                                 </h4>
//                                         }
//                                     </div>
//                                 </div>
//                                 <Modal open={open} onClose={onCloseModal} center>
//                                     <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
//                                         <div className="modal-content quick-view-modal">
//                                             <div className="modal-body">
//                                                 <div className="row">
//                                                     <div className="col-lg-6  col-xs-12">
//                                                         <div className="quick-view-img">
//                                                             <img
//                                                                 src={product.product_images[0] ? product.product_images[0].image : `${process.env.PUBLIC_URL}/assets/images/nia.png`}
//                                                                 className="img-fluid"
//                                                                 alt="" />
//                                                         </div>
//                                                     </div>
//                                                     <div className="col-lg-6 rtl-text">
//                                                         <div className="product-right">
//                                                             <h2> {product.name} </h2>
//                                                             {
//                                                                 product.wholesale_price ?
//                                                                     <h4>{"₹"}{product.wholesale_price}
//                                                                         <del><span className="money"> {symbol} {product.price}</span></del>
//                                                                     </h4> :
//                                                                     <h4>{"₹"}{product.price}
//                                                                         {/* <del><span className="money">{symbol}{product.price}</span></del> */}
//                                                                     </h4>
//                                                             }
//                                                             <div className="border-product">
//                                                                 <h6 className="product-title">product details</h6>
//                                                                 <p>{product.description}</p>
//                                                             </div>
//                                                             <div className="product-description border-product">
//                                                                 {product.size ?
//                                                                     <div className="size-box">
//                                                                         <ul>
//                                                                             {product.size.map((size, i) => {
//                                                                                 return <li key={i}><a href="#">{size}</a></li>
//                                                                             })}
//                                                                         </ul>
//                                                                     </div> : ''}
//                                                                 <h6 className="product-title">quantity</h6>
//                                                                 <div className="qty-box">
//                                                                     <div className="input-group">
//                                                                         <span className="input-group-prepend">
//                                                                             <button type="button" className="btn quantity-left-minus" onClick={minusQty} data-type="minus" data-field="">
//                                                                                 <i className="fa fa-angle-left"></i>
//                                                                             </button>
//                                                                         </span>
//                                                                         <input type="text" name="quantity" value={quantity} onChange={changeQty} className="form-control input-number" />
//                                                                         <span className="input-group-prepend">
//                                                                             <button type="button" className="btn quantity-right-plus" onClick={plusQty} data-type="plus" data-field="">
//                                                                                 <i className="fa fa-angle-right"></i>
//                                                                             </button>
//                                                                         </span>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                             <div className="product-buttons">
//                                                                 <button className="btn btn-solid" onClick={() => onAddToCartClicked(product, quantity)} >add to cart</button>
//                                                                 <Link to={`${process.env.PUBLIC_URL}/product-detail/${product.id}`} className="btn btn-solid">view detail</Link>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </Modal>
//                             </div>
//                         )}
//                 </Slider>
//             </div>
//         )
    

// }

// function mapStateToProps(state) {
//     return {
//         // items: state.data.products,
//         // symbol: state.data.symbol
//     }
// }

// export default connect(mapStateToProps, null)(RealatedProducts)