import { faList, faStar, faTh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { filterSort } from '../../../actions'
import { getVisibleproducts } from '../../../services';

class FilterBar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selected_sortingFilter: undefined
        }
    }

    listLayout() {
        document.querySelector(".collection-grid-view").style = "opacity:0";
        document.querySelector(".product-wrapper-grid").style = "opacity:0.2";
        document.querySelector(".product-wrapper-grid").classList.add("list-view");
        var elems = document.querySelector(".infinite-scroll-component .row").childNodes;
        [].forEach.call(elems, function (el) {
            el.className = '';
            el.classList.add('col-lg-12');
        });
        setTimeout(function () {
            document.querySelector(".product-wrapper-grid").style = "opacity: 1";
        }, 500);
    }

    //Grid Layout View
    gridLayout() {
        document.querySelector(".collection-grid-view").style = "opacity:1";
        document.querySelector(".product-wrapper-grid").classList.remove("list-view");
        var elems = document.querySelector(".infinite-scroll-component .row").childNodes;
        [].forEach.call(elems, function (el) {
            el.className = '';
            el.classList.add('col-lg-3');
        });
    }

    // Layout Column View
    LayoutView = (colSize) => {
        if (!document.querySelector(".product-wrapper-grid").classList.contains("list-view")) {
            var elems = document.querySelector(".infinite-scroll-component .row").childNodes;
            [].forEach.call(elems, function (el) {
                el.className = '';
                el.classList.add('col-lg-' + colSize);
            });
        }

        this.props.onLayoutViewClicked(colSize);
    }

    render() {
        return (
            <div className="product-filter-content">



                <div className="product-page-filter">

                    <select defaultValue='Sorting items' className="form-control"
                        onChange={e => this.onChangeSortingFilter(e)}>
                        <option value={0} >Sorting items</option>                       
                        <option value="HighToLow">Price: High to Low</option>
                        <option value="LowToHigh">Price: Low to High</option>
                        <option value="Newest">Newest Items</option>
                        <option value="AscOrder">Sort By Name: A To Z</option>
                        <option value="DescOrder">Sort By Name: Z To A</option>

                    </select>
                </div>
            </div>
        )
    }

    onChangeSortingFilter = e => {
        this.setState({
            selected_sortingFilter: e.target.value
        })
    }
}

const mapStateToProps = state => ({
    products: getVisibleproducts(state.data, state.filters),
    filters: state.filters
})

export default connect(mapStateToProps, { filterSort })(FilterBar);


const SortingItemsArray = [
    {
        name: 'Sort By Name: A To Z',
        value: "ATOZ"
    },
    {
        name: 'Sort By Name: Z To A',
        value: "ZTOA"
    }
]

///////////////////////////////////////////////////////////////////////////////////
///////////////////////// functional component ////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////// 

// import { faList, faStar, faTh } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import React, { useState } from 'react';
// import { connect } from 'react-redux'
// import { filterSort } from '../../../actions'
// import { getVisibleproducts } from '../../../services';

// const  FilterBar =(props)=> {

//         const [selected_sortingFilter, setselected_sortingFilter] = useState(undefined)
//         // this.state = {
//         //     selected_sortingFilter: undefined
//         // }
    
//     const listLayout= ()=> {
//         document.querySelector(".collection-grid-view").style = "opacity:0";
//         document.querySelector(".product-wrapper-grid").style = "opacity:0.2";
//         document.querySelector(".product-wrapper-grid").classList.add("list-view");
//         var elems = document.querySelector(".infinite-scroll-component .row").childNodes;
//         [].forEach.call(elems, function (el) {
//             el.className = '';
//             el.classList.add('col-lg-12');
//         });
//         setTimeout(function () {
//             document.querySelector(".product-wrapper-grid").style = "opacity: 1";
//         }, 500);
//     }

//     //Grid Layout View
//    const  gridLayout =()=> {
//         document.querySelector(".collection-grid-view").style = "opacity:1";
//         document.querySelector(".product-wrapper-grid").classList.remove("list-view");
//         var elems = document.querySelector(".infinite-scroll-component .row").childNodes;
//         [].forEach.call(elems, function (el) {
//             el.className = '';
//             el.classList.add('col-lg-3');
//         });
//     }

//     // Layout Column View
//     const LayoutView = (colSize) => {
//         if (!document.querySelector(".product-wrapper-grid").classList.contains("list-view")) {
//             var elems = document.querySelector(".infinite-scroll-component .row").childNodes;
//             [].forEach.call(elems, function (el) {
//                 el.className = '';
//                 el.classList.add('col-lg-' + colSize);
//             });
//         }

//         props.onLayoutViewClicked(colSize);
//     }

//     const onChangeSortingFilter = e => {
//             setselected_sortingFilter(e.target.value)
//     }
//         return (
//             <div className="product-filter-content">



//                 <div className="product-page-filter">

//                     <select defaultValue='Sorting items' className="form-control"
//                         onChange={e => onChangeSortingFilter(e)}>
//                         <option value={0} >Sorting items</option>                       
//                         <option value="HighToLow">Price: High to Low</option>
//                         <option value="LowToHigh">Price: Low to High</option>
//                         <option value="Newest">Newest Items</option>
//                         <option value="AscOrder">Sort By Name: A To Z</option>
//                         <option value="DescOrder">Sort By Name: Z To A</option>

//                     </select>
//                 </div>
//             </div>
//         )

   
// }

// const mapStateToProps = state => ({
//     products: getVisibleproducts(state.data, state.filters),
//     filters: state.filters
// })

// export default connect(mapStateToProps, { filterSort })(FilterBar);


// const SortingItemsArray = [
//     {
//         name: 'Sort By Name: A To Z',
//         value: "ATOZ"
//     },
//     {
//         name: 'Sort By Name: Z To A',
//         value: "ZTOA"
//     }
// ]