import React, { Component } from 'react';
import { connect } from 'react-redux'
import { MAIN_URL, NaswizHelperApi } from '../../../NaswizHelper';
import Notification from '../../../Notification'
import { addToCart, addToWishlist, addToCompare, removeFromWishlist, getWishlistData, getCartData, getCartDataWithoutLogin } from "../../../actions/index";
import 'antd/dist/antd.css';
import ProductListItem from '../../collection/common/product-list-item';
import OwlCarousel from 'react-owl-carousel';
const colorStyle = { border: '1px solid #BD3042', backgroundColor: "#BD3042", color: "#ffffff" }

class TopCollection extends Component {

    constructor(props) {
        super(props)

        this.state = {
            open: false,
            stock: 'InStock',
            quantity: 1,
            image: '',
            quick_product_detail: [],
            quick_related_record: [],
            quick_product_id: null,
            quickviewModalVisibility: false,
            selectedColorId: null,
            selectedColorIndex: null,
            selectedSizeId: null,
            selectedSizeIndex: null,
            product_detail: [],
            quick_qty: 0,
            _mainSlug: this.props.mainSlug,
            _breadcrum: this.props.breadcrum,
            _wishlistcleare: false,
            isSizeSelected: false,
            notSelectedText: '',
            isColorSelected: false,
            colorNotSelectText: '',
            notQty: '',
            _isColorPicker: false,
            _selectColorId: 0,
            _isSizePicker: false,
            _selectSizeId: 0,
            uniueId: '',
            _isBtnEnble: true,
            _maxQty: 0,
            selectedColorIdQuick: null,
            selectedSizeIdQuick: null,
            isColorSelectedQuick: false,
            isSizeSelectedQuick: false,
            _product_type: '',
            wishlistitemremove: false,
            _product_description2: '',
            _product_description: '',
            _slug_quick: '',
            _current_Url: '',
            _readMore: false,
            _deleteID: '',
            cartVisible: false,
            _selectSizeIndex: 0,
        }
    }



    componentDidMount = () => {
        var uniueId = localStorage.getItem('deviceId')
        this.setState({ uniueId: uniueId })
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
            quicknav1: this.quickslider1,
            quicknav2: this.quickslider2,
            _current_Url: window.location.href
        });
        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    onClickHandle(img) {
        this.setState({ image: img });
    }

    _increaseQTY = async (id) => {

        var plus = this.state.quantity + 1

        var body = new FormData()
        body.append('cart_id', id)
        body.append('quantity', plus)

        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/update-cart', body, "Error => Update(Increase) cart qty api.")

        if (res && res.success === 1) {
            this.setState({
                quantity: plus
            })
        }
        else {
            Notification('error', 'Error!', res.message + '')
        }

    }

    _deCreaseQTY = async (id) => {

        if (this.state.quantity === 1) {
            // Notification('success', 'Success!', res.message ? res.message : 'Product deleted successfully.')
            var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(`v1/delete-cart/${id}`, "Error => Remove item from cart api.")

            if (res && res.success === 1) {
            }
            else {
                alert(res && res.message ? res.message : 'Error while remove or de-crease quantity')
            }

        }
        else {

            var minus = this.state.quantity - 1

            var body = new FormData()
            body.append('cart_id', id)
            body.append('quantity', minus)

            var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/update-cart', body, "Error => Update(Decrease) cart qty api.")

            if (res && res.success === 1) {
                this.setState({
                    quantity: minus
                })
            }
            else {
                Notification('error', 'Error!', res.message + '')
            }
        }
    }

    changeQty = (e) => {
        this.setState({ quantity: parseInt(e.target.value) })
    }

    quickviewrelatedfu = async (slug_quick) => {
        // console.log("slug :", slug_quick)
        var _shareurl = this.state._current_Url + '/' + slug_quick

        await this.setState({
            _slug_quick: slug_quick,
            // selectedColorIdQuick: null,
            // selectedSizeIdQuick: null,
            // quick_qty: 0,
            _cartItem: [],
            _current_Url: _shareurl
        })
        var userID = this.props.userID
        var url = userID ? `v1/product-detail/${slug_quick}/${this.state.uniueId}/${userID}` : `v1/product-detail/${slug_quick}/${this.state.uniueId}/0`

        this.setState({ loading_detail: true }, async () => {

            var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(url, 'Error => products details with slug api.')

            if (res && res.success === 1) {

                this.setState({
                    quick_product_detail: res.records,
                    quick_related_record: res.related_product,
                    quick_product_id: res.records.product_id,
                    _product_type: res.records.product_type,
                    _cartItem: res.records.cart,
                    quick_qty: 0,
                })
                if (
                    res.records.description.length > 150
                ) {
                    this.setState({ _product_description: res.records.description.substring(0, 150), _product_description2: res.records.description })
                } else {
                    this.setState({ _product_description: res.records.description, _product_description2: res.records.description })
                }
                if (this.state.selectedColorIdQuick === '' || this.state.selectedColorIdQuick === 0 || this.state.selectedColorIdQuick === null) {

                    if (res.records.details.length > 0 && (res.records.product_type === '3' || res.records.product_type === '4')) {

                        await this.setState({ _maxQty: res.records.details[0].max_order_qty, selectedColorIdQuick: res.records.details[0].color_id, isColorSelectedQuick: true, colorNotSelectText: 'Color Selected' })
                        //    check stock 0 then set btn disable
                        var isBtnEnbl = res.records.details[0].inventory - res.records.details[0].used
                        if (isBtnEnbl === 0) {
                            this.setState({ _isBtnEnble: false })
                        }

                    }
                }

                if (this.state.selectedSizeIdQuick === '' || this.state.selectedSizeIdQuick === 0 || this.state.selectedSizeIdQuick === null) {
                    if (res.records.details.length > 0 && (res.records.product_type === '1' || res.records.product_type === '2' || res.records.product_type === '4')) {
                        await this.setState({ _maxQty: res.records.details[0].max_order_qty, selectedSizeIdQuick: res.records.details[0].size_id, isSizeSelectedQuick: true, notSelectedText: 'Size Selected' })
                        var isBtnEnbl = res.records.details[0].inventory - res.records.details[0].used
                        if (isBtnEnbl === 0) {
                            this.setState({ _isBtnEnble: false })
                        }
                    }
                }

                this.checkCartItem(this.state.selectedSizeIdQuick, this.state.selectedSizeIdQuick)
                this.setState({ quickviewModalVisibility: true });
            }
            else {
                this.setState({ quickviewModalVisibility: false });
                this.setState({
                    loading_detail: false
                })
            }

        })
    }

    handleClose = () => {
        this.setState({
            quickviewModalVisibility: false,
            selectedColorId: null,
            selectedColorIndex: null,
            selectedSizeId: null,
            selectedSizeIndex: null,
            quick_qty: 0
        })
    }

    quickViewQuantityChange = async (qty, item) => {
        var selectedColorId = this.state.selectedColorId;
        var selectedSizeId = this.state.selectedSizeId;
        if (selectedColorId === undefined || selectedColorId === null) {
            Notification('error', 'Error!', 'Please select color first!')
        }
        else if (selectedSizeId === undefined || selectedSizeId === null) {
            Notification('error', 'Error!', 'Please select size first!')
        }
        if (qty === '' || qty === '0') {
            Notification('error', 'Error!', 'Please enter quantity!')
        }
        else {
            if (item.details.length > 0) {
                item.details.map((i, index) => {
                    if (i.color_id === selectedColorId && i.size_id === selectedSizeId) {
                        if (i.min_order_qty > qty) {
                            Notification('error', 'Error!', 'Qyantity should be greater than or euale to ' + i.min_order_qty)
                        }
                        else if (i.max_order_qty < qty) {
                            Notification('error', 'Error!', 'Qyantity should be less than or euale to ' + i.max_order_qty)
                        }
                        else {
                            setTimeout(() => {
                            }, 1000);
                        }
                    }
                })
            }
        }
    }

    _removeFromWishListApi = async (item) => {
        this.setState({ wishlistitemremove: true })
        // console.log(item);
        if (!this.props.isUserLogin) {
            this.setState({ loginModalVisibility: true })
            Notification('error', 'Error!', 'Please Login First!')
        }
        else {
            if (this.props._from === "from_wishlist") {
                var res = await NaswizHelperApi.GET_WITH_TOKEN('v1/removewishlist/'.concat(item.wishlist_id), "Error => remove item from user wishlist")
                if (res && res.success === 1) {
                    this.props.getWishlistData('v1/wishlist-details/' + this.state.uniueId, "Error => Get all wishlist data");
                    this.props.updateDataAfterAction();
                } else {
                    this.props.getWishlistData('v1/wishlist-details/' + this.state.uniueId, "Error => Get all wishlist data");
                    this.props.updateDataAfterAction();
                }
            }
            else {
                var res = await NaswizHelperApi.GET_WITH_TOKEN(`v1/removewishlist/${item.wishlist_id}`, "Error => remove item from user wishlist")
                if (res && res.success === 1) {
                    await this.props.getWishlistData('v1/wishlist-details/' + this.state.uniueId, "Error => Get all wishlist data");
                    Notification('success', 'Success!', 'Item removed from Favourites!')
                    this.quickviewrelatedfu(this.state._slug_quick);
                    this.props.updateDataAfterAction();
                }
            }
        }
    }

    _addToWishlistWithApi = async (item) => {
        // console.log(item);
        if (!this.props.isUserLogin) {
            this.setState({ loginModalVisibility: true })
            Notification('error', 'Error!', 'Please Login First!')
        }
        else {

            var res = await NaswizHelperApi.GET_WITH_TOKEN('v1/wishlist/'.concat(item.product_id), "Error => Add to wishlist api")
            if (res && res.success === 1) {
                if (res.message === 'Product is already in Wishlist.') {
                    Notification('error', 'Error!', res.message)
                }
                else {
                    Notification('success', 'Success!', 'Product added to Favourites')
                    this.quickviewrelatedfu(this.state._slug_quick)
                    this.props.updateDataAfterAction();
                    setTimeout(() => {
                        this.props.getWishlistData('v1/wishlist-details/' + this.state.uniueId, "Error => Get all wishlist data")
                    }, 500)
                }
            }

        }
    }

    _addToCart = async (product_detail, selectedColorId, selectedSizeId, pType, qtyUpdate) => {

        if (!this.props.isUserLogin) {
            Notification('error', 'Error!', 'Please Login First!')
        }
        else {

            if (pType === 3 || pType === 4) {
                if (selectedSizeId === undefined || selectedSizeId === null) {
                    Notification('error', 'Error!', 'Please select size first!')
                    return;
                }

            }
            if (pType === 2 || pType === 4) {
                if (selectedSizeId === undefined || selectedSizeId === null) {
                    Notification('error', 'Error!', 'Please select size first!')
                    return;
                }

            }


            var body = new FormData()
            body.append('product_id', product_detail.product_id)
            body.append('color_id', selectedColorId)
            if (pType === 2) {
                body.append('size_id', selectedSizeId)
            }
            body.append('main_price', product_detail.price)
            body.append('price', product_detail.wholesale_price)
            body.append('device_id', this.state.uniueId)

            var qtyvalue = 1;
            if (qtyUpdate === 1) {
                if (product_detail.cart[0].total_quantity > 1) {
                    qtyvalue = product_detail.cart[0].total_quantity - 1
                } else {
                    this._deCreaseQTY(product_detail.cart[0].id)
                    this.props.getCartData('v1/get-cart-list', formdata, "Error => GetCartList api.")

                    return;
                }

            } else if (qtyUpdate === 2) {
                qtyvalue = product_detail.cart[0].total_quantity + 1
                // console.log(qtyvalue);

            } else {
                qtyvalue = product_detail.cart[0] ? product_detail.cart[0].total_quantity + 1 : 1
            }
            if (product_detail.details.length > 0) {
                // console.log(product_detail);
                if (product_detail.details[0].color_id === selectedColorId || product_detail.details[0].size_id === selectedSizeId) {
                    body.append('quantity', qtyvalue)
                }
                else {
                    body.append('quantity', 1)
                }
            }
            else {
                body.append('quantity', 1)
            }
            if (product_detail.product_images.length > 0) {
                var temp = product_detail.product_images[0].image.includes(`${MAIN_URL}master-naswiz//`) ? `${MAIN_URL}master-naswiz//` : product_detail.product_images[0].image.includes(`${MAIN_URL}master-naswiz/`) ? `${MAIN_URL}master-naswiz/` : null
                body.append('image', product_detail.product_images[0].image.replace(temp, ''))
            }
            else {
                body.append('image', `storage/uploads/product/Medium${process.env.PUBLIC_URL}/assets/images/nia.png`)
            }

            for (var pair of body.entries()) {
            }

            var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/add-usercart', body, "Error => v1/add-usercart")
            // console.log("----login cart : ", res)
            if (res && res.success === 1) {
                Notification('success', 'Success!', res.message ? res.message : 'Product Add to Cart Successfully.')
                var formdata = new FormData()
                formdata.append('device_id', this.state.uniueId)
                this.props.getCartData('v1/get-cart-list', formdata, "Error => GetCartList api.")
                this.props.updateDataAfterAction();

                this.setState({
                    selectedColorId: null,
                    selectedColorIndex: null,
                    selectedSizeId: null,
                    selectedSizeIndex: null
                })
                if (pType === 3) {
                    this.setState({
                        _isColorPicker: false
                    })
                }
                if (pType === 2) {
                    this.setState({
                        _isSizePicker: false
                    })
                }
            }
            else {
                Notification('error', 'Error!', res.message + '')
            }


        }
    }

    //direct fom list
    _addToCartWihoutLogin = async (product_detail, selectedColorId, selectedSizeId, pType, qtyUpdate) => {

        if (pType === 3 || pType === 4) {
            if (selectedSizeId === undefined || selectedSizeId === null) {
                Notification('error', 'Error!', 'Please select size first!')
                return;
            }

        }
        if (pType === 2 || pType === 4) {
            if (selectedSizeId === undefined || selectedSizeId === null) {
                Notification('error', 'Error!', 'Please select size first!')
                return;
            }

        }


        var body = new FormData()
        body.append('product_id', product_detail.product_id)
        body.append('device_id', this.state.uniueId)

        body.append('color_id', selectedColorId)
        if (pType === 2) {
            body.append('size_id', selectedSizeId)
        }
        body.append('main_price', product_detail.price)
        body.append('price', product_detail.wholesale_price)


        var qtyvalue = 1
        if (qtyUpdate === 1) {
            if (product_detail.cart[0].total_quantity > 1) {
                qtyvalue = product_detail.cart[0].total_quantity - 1

            } else {
                this._deCreaseQTY(product_detail.cart[0].id)
                this.props.getCartData('v1/get-cart-list', formdata, "Error => GetCartList api.")

                return;
            }

        } else if (qtyUpdate === 2) {
            qtyvalue = product_detail.cart[0].total_quantity + 1

        } else {
            qtyvalue = product_detail.details[0].min_order_qty
        }
        if (product_detail.details.length > 0) {
            if (product_detail.details[0].color_id === selectedColorId && product_detail.details[0].size_id === selectedSizeId) {
                body.append('quantity', qtyvalue)
            }
            else {
                body.append('quantity', 1)
            }
        }
        else {
            body.append('quantity', 1)
        }

        if (product_detail.product_images.length > 0) {

            var temp = product_detail.product_images[0].image.includes(`${MAIN_URL}master-naswiz//`) ? `${MAIN_URL}master-naswiz//` : product_detail.product_images[0].image.includes(`${MAIN_URL}master-naswiz/`) ? `${MAIN_URL}master-naswiz/` : null
            body.append('image', product_detail.product_images[0].image.replace(temp, ''))
        }
        else {
            body.append('image', `storage/uploads/product/Medium${process.env.PUBLIC_URL}/assets/images/nia.png`)
        }

        for (var pair of body.entries()) {
        }

        var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/add-usercart-without-login', body, "Error => v1/add-usercart")
        // console.log("----without login cart : ", res)

        if (res && res.success === 1) {
            Notification('success', 'Success!', res.message ? res.message : 'Product Add to Cart Successfully.')

            var formdata = new FormData()
            formdata.append('device_id', this.state.uniueId)
            this.props.getCartDataWithoutLogin('v1/get-cart-list-without-login', formdata, "Error => get-cart-list-without-login.")
            this.props.updateDataAfterAction()
            this.setState({
                selectedColorId: null,
                selectedColorIndex: null,
                selectedSizeId: null,
                selectedSizeIndex: null
            })
            if (pType === 3) {
                this.setState({
                    _isColorPicker: false
                })
            }
            if (pType === 2) {
                this.setState({
                    _isSizePicker: false
                })
            }
        }
        else {
            Notification('error', 'Error!', res.message + '')
        }

    }

    deleteCart = async (id) => {


        var res = await NaswizHelperApi.GET_WITHOUT_TOKEN('v1/delete-cart/' + id, body, "Error => v1/add-usercart")
        if (res && res.success === 1) {
            Notification('success', 'Success!', res.message ? res.message : 'Product deleted successfully.')
            if (this.props.isUserLogin) {
                var body = new FormData()
                body.append('device_id', this.state.uniueId)
                this.props.getCartData('v1/get-cart-list', body, "Error => GetCartList api.")

            } else {
                var body = new FormData()
                body.append('device_id', this.state.uniueId)
                this.props.getCartDataWithoutLogin('v1/get-cart-list-without-login', body, "Error => get-cart-list-without-login.")

            }
            this.quickviewrelatedfu(this.state._slug_quick)


        }
        else {
            Notification('error', 'Error!', res.message ? res.message : 'Login First')
        }


    }

    decrease = () => {
        // console.log('decrease quick_qty.....', this.state.quick_qty)
        if (this.state.quick_qty > 1) {
            if (this.props.isUserLogin) {

                this.quickViewAddToCart(2)

            } else {
                this.quickViewAddToCartWithoutLogin(2)

                // }
            }
        } else {

            // console.log('delete id ', this.state._deleteID)
            if (this.state._deleteID) {
                this.deleteCart(this.state._deleteID)

            }
        }
    }

    increase = async () => {
        if (this.props.isUserLogin) {
            this.quickViewAddToCart(1)
        } else {
            this.quickViewAddToCartWithoutLogin(1)

            // }
        }
    }

    async checkCartItem(colorid, sizeid) {
        var isMatch = false;

        for (let i = 0; i < this.state._cartItem.length; i++) {

            if (this.state._product_type === '4' && colorid != '' && sizeid != '') {
                if (colorid === this.state._cartItem[i].color_id && sizeid === this.state._cartItem[i].size_id) {
                    this.setState({ quick_qty: this.state._cartItem[i].total_quantity, _deleteID: this.state._cartItem[i].id })
                    isMatch = true;
                }
            } else if (this.state._product_type === '3' && colorid != '') {
                if (colorid === this.state._cartItem[i].color_id) {
                    this.setState({ quick_qty: this.state._cartItem[i].total_quantity, _deleteID: this.state._cartItem[i].id })
                    isMatch = true;
                }
            } else if (this.state._product_type === '2' && sizeid != '') {

                if (sizeid === this.state._cartItem[i].size_id) {
                    this.setState({ quick_qty: this.state._cartItem[i].total_quantity, _deleteID: this.state._cartItem[i].id })
                    isMatch = true;
                }
            } else if (this.state._product_type === '1') {
                this.setState({ quick_qty: this.state._cartItem[i].total_quantity, _deleteID: this.state._cartItem[i].id })
                isMatch = true;
            }
        }
        if (!isMatch) {
            this.setState({ quick_qty: 0 })

        }
    }
    quickViewAddToCart = async (type) => {


        var { quick_product_detail, selectedColorIdQuick, selectedSizeIdQuick } = this.state
        if ((quick_product_detail.product_type === '3' || quick_product_detail.product_type === '4')) {
            if (selectedColorIdQuick === null || !this.state.isColorSelectedQuick) {
                Notification('error', 'Error!', 'Please select color !')
                return;
            }
        }
        if ((quick_product_detail.product_type === '2' || quick_product_detail.product_type === '4')) {
            if (selectedSizeIdQuick === null || !this.state.isSizeSelectedQuick) {
                Notification('error', 'Error!', 'Please select size !')
                return;
            }
        }

        var qty = this.state.quick_qty
        if (type === 2) {
            qty = this.state.quick_qty - 1

        } else {
            qty = this.state.quick_qty + 1

        }
        var body = new FormData()
        body.append('product_id', quick_product_detail.product_id)
        body.append('color_id', selectedColorIdQuick)
        body.append('size_id', selectedSizeIdQuick)
        body.append('main_price', quick_product_detail.price)
        body.append('price', quick_product_detail.wholesale_price)
        body.append('quantity', qty)
        body.append('device_id', this.state.uniueId)

        if (quick_product_detail.product_images.length > 0) {

            var temp = quick_product_detail.product_images[0].image.includes(`${MAIN_URL}master-naswiz//`) ? `${MAIN_URL}master-naswiz//` : quick_product_detail.product_images[0].image.includes(`${MAIN_URL}master-naswiz/`) ? `${MAIN_URL}master-naswiz/` : null
            body.append('image', quick_product_detail.product_images[0].image.replace(temp, ''))
        }
        else {
            body.append('image', `storage/uploads/product/Medium${process.env.PUBLIC_URL}/assets/images/nia.png`)
        }

        for (var pair of body.entries()) {
        }

        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/add-usercart', body, "Error => v1/add-usercart")

        if (res && res.success === 1) {
            Notification('success', 'Success!', res.message ? res.message : 'Product Add to Cart Successfully.')
            var formdata = new FormData()
            formdata.append('device_id', this.state.uniueId)
            this.props.getCartData('v1/get-cart-list', formdata, "Error => GetCartList api.")

            var qty = this.state.quick_qty
            if (type === 2) {
                await this.setState({ quick_qty: this.state.quick_qty - 1 });

            } else {
                await this.setState({ quick_qty: this.state.quick_qty + 1 });

            }
            this.quickviewrelatedfu(this.state._slug_quick)

        }
        else {
            Notification('error', 'Error!', res.message ? res.message : 'Login First')
        }

    }

    quickViewAddToCartWithoutLogin = async (type) => {

        var { quick_product_detail, selectedColorIdQuick, selectedSizeIdQuick } = this.state
        if ((quick_product_detail.product_type === '3' || quick_product_detail.product_type == '4')) {
            if (selectedColorIdQuick === null || !this.state.isColorSelectedQuick) {
                Notification('error', 'Error!', 'Please select color !')
                return;
            }
        }
        if ((quick_product_detail.product_type === '2' || quick_product_detail.product_type === '4')) {
            if (selectedSizeIdQuick === null || !this.state.isSizeSelectedQuick) {
                Notification('error', 'Error!', 'Please select size !')
                return;
            }
        }
        var qty = this.state.quick_qty
        if (type === 2) {
            qty = this.state.quick_qty - 1

        } else {
            qty = this.state.quick_qty + 1

        }
        var body = new FormData()
        body.append('product_id', quick_product_detail.product_id)
        body.append('color_id', selectedColorIdQuick)
        body.append('size_id', selectedSizeIdQuick)
        body.append('main_price', quick_product_detail.price)
        body.append('price', quick_product_detail.wholesale_price)
        body.append('device_id', this.state.uniueId)
        body.append('quantity', qty)


        if (quick_product_detail.product_images.length > 0) {

            var temp = quick_product_detail.product_images[0].image.includes(`${MAIN_URL}master-naswiz//`) ? `${MAIN_URL}master-naswiz//` : quick_product_detail.product_images[0].image.includes(`${MAIN_URL}master-naswiz/`) ? `${MAIN_URL}master-naswiz/` : null
            body.append('image', quick_product_detail.product_images[0].image.replace(temp, ''))
        }
        else {
            body.append('image', `storage/uploads/product/Medium${process.env.PUBLIC_URL}/assets/images/nia.png`)
        }
        for (var pair of body.entries()) {
        }

        var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/add-usercart-without-login', body, "Error => v1/add-usercart")

        if (res && res.success === 1) {
            Notification('success', 'Success!', res.message ? res.message : 'Product Add to Cart Successfully.')
            var body = new FormData()
            body.append('device_id', this.state.uniueId)
            this.props.getCartDataWithoutLogin('v1/get-cart-list-without-login', body, "Error => get-cart-list-without-login.")

            var qty = this.state.quick_qty
            if (type === 2) {
                await this.setState({ quick_qty: this.state.quick_qty - 1 });

            } else {
                await this.setState({ quick_qty: this.state.quick_qty + 1 });

            }
            this.quickviewrelatedfu(this.state._slug_quick)

        }
        else {
            Notification('error', 'Error!', res.message ? res.message : 'Login First')
        }
    }

    checkCart = async (value, index) => {
        // console.log(value)
        var count = value.inventory - value.used
        // console.log(count)
        if (count === 0)
            this.setState({ cartVisible: false })
        else
            this.setState({ cartVisible: true })
    }




    render() {

        const { product, topCollectionItems } = this.props;
        const { open, quick_product_detail } = this.state;
        var colorsFilter = quick_product_detail && quick_product_detail.details ? Array.from(new Set(quick_product_detail.details.map(s => s.color_code))).map(id => {
            return {
                color_name: id,
                color_id: quick_product_detail.details.find(item => {
                    if (id === item.color_code) {
                        return item.color_id
                    }
                })
            }
        }) : null

        var sizeFilter = quick_product_detail && quick_product_detail.details ? Array.from(new Set(quick_product_detail.details.map(s => s.size_name))).map(id => {
            return {
                size_name: id,
                size_id: quick_product_detail.details.find(item => {
                    if (id === item.size_name) {
                        return item.size_id
                    }
                })
            }
        }) : null

        var quickviewrelated = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            fade: true
        };

        var quickproductsnav = {
            slidesToShow: 3,
            slidesToScroll: 1,
            swipeToSlide: true,
            arrows: false,
            dots: false,
            focusOnSelect: true
        };

        const options = {
            margin: 20,
            items: 5,
            responsiveClass: true,
            nav: true,
            dots: false,
            navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
            autoplay: true,
            smartSpeed: 2000,
            responsive: {
                0: {
                    items: 1,
                },
                480: {
                    items: 2,
                },
                992: {
                    items: 3,
                },
                1200: {
                    items: 4,
                },
                1400: {
                    items: 5,

                }
            },
        }
        return (
            <>
                <OwlCarousel className='owl-theme nav-outer show-nav-hover nav-image-center' {...options}>
                    {
                        topCollectionItems && topCollectionItems.length > 0 ?
                            topCollectionItems.map((item, index) => {
                                return (
                                    <ProductListItem
                                        _from={this.props._from}
                                        product={item}
                                        symbol={"₹"}
                                        // onAddToCompareClicked={() => addToCompare(records)}
                                        // onAddToCartClicked={() => this._addToCart(records)}
                                        // onRemoveFromCartClicked={() => this._onRemoveFromCartClicked()}
                                        // onAddToWishlistClicked={() => this._addToWishlistWithApi(records)}
                                        // onRemoveFromWishlistClicked={() => this._removeFromWishListApi(records, page)}
                                        // page={page}
                                        quickViewAddToCart={() => this.quickViewAddToCart(item)}
                                        quickViewQuantityChange={() => this.quickViewQuantityChange()}
                                        key={index}
                                        mainSlug={this.state._mainSlug}
                                        breadcrum={this.state._breadcrum}
                                        onClickCart={this.props.onClickCart}
                                        updateDataAfterAction={this.props.updateDataAfterAction}
                                    />
                                )
                            })
                            : null
                    }
                </OwlCarousel>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    isUserLogin: state.login.isUserLogin,
    wishlistArray: state.login.userWishlistData.data,
    userID: state.login.userID,
})

export default connect(mapStateToProps, { addToCart, addToWishlist, addToCompare, removeFromWishlist, getWishlistData, getCartData, getCartDataWithoutLogin })(TopCollection);



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                                    //Old Code//
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


// import React, { Component } from 'react';
// import SliderSlick from 'react-slick';
// import { connect } from 'react-redux'
// import { Link } from 'react-router-dom';
// import { getTrendingCollection } from '../../../services/index'
// import { Product4, Product5 } from '../../../services/script'
// import { addToCart, addToWishlist, addToCompare, removeFromWishlist, getWishlistData, getCartData, getCartDataWithoutLogin } from "../../../actions/index";
// import { NaswizHelperApi, colors } from '../../../NaswizHelper';
// import ProductItem from '../common/product-item';
// import './fashion.css'
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Notification from '../../../Notification'
// import Modal from 'react-responsive-modal';
// import { Row, Tooltip, Col, Checkbox, Icon, Progress, Input, Tag, Select, Rate } from 'antd';
// import 'antd/dist/antd.css';
// import SmallImages from '../../products/common/product/small-image'
// import renderHTML from 'react-render-html';
// import { CarouselProvider, Slider, Slide, Dot, ImageWithZoom, Image, ButtonBack, ButtonNext } from 'pure-react-carousel';
// import 'pure-react-carousel/dist/react-carousel.es.css';
// import OwlCarousel from 'react-owl-carousel';

// import LightGallery from 'lightgallery/react';
// // import styles
// import 'lightgallery/css/lightgallery.css';
// import {
//     EmailShareButton,
//     FacebookShareButton,
//     LinkedinShareButton,
//     TwitterShareButton,
//     WhatsappShareButton, WhatsappIcon
// } from "react-share";
// const colorStyle = { border: '2px solid #bd3042', backgroundColor: '#bd3042', color: '#ffffff' }

// const options = {
//     margin: 20,
//     items: 5,
//     responsiveClass: true,
//     nav: true,
//     dots: false,
//     navText: ['<i class="icon-angle-left">', '<i class="icon-angle-right">'],
//     autoplay: true,
//     smartSpeed: 2000,
//     responsive: {
//         0: {
//             items: 1,
//         },
//         480: {
//             items: 2,
//         },
//         992: {
//             items: 3,
//         },
//         1200: {
//             items: 4,
//         },
//         1400: {
//             items: 5,

//         }
//     },
// }

// class TopCollection extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             quick_product_detail: [],
//             quick_related_record: [],
//             quick_product_id: null,
//             quickviewModalVisibility: false,
//             selectedColorId: null,
//             selectedColorIndex: null,
//             selectedSizeId: null,
//             selectedSizeIndex: null,
//             product_detail: [],
//             quick_qty: 0,
//             value: 1,
//             isSizeSelected: false,
//             notSelectedText: '',
//             isColorSelected: false,
//             colorNotSelectText: '',
//             notQty: '',
//             _isColorPicker: false,
//             _selectColorId: 0,
//             _isSizePicker: false,
//             _selectSizeId: 0,
//             _selectSizeIndex: 0,
//             uniueId: '',
//             _isBtnEnble: true,
//             _maxQty: 0,
//             selectedColorIdQuick: null,
//             selectedSizeIdQuick: null,
//             isColorSelectedQuick: false,
//             isSizeSelectedQuick: false,
//             _product_type: '',
//             _current_Url: '',
//             _slug_quick: '',
//             _readMore: false,
//             _product_description2: '',
//             _product_description: '',
//             _deleteID: '',
//             cartVisible : false,
//         }
//     }
//     deleteCart = async (id) => {


//         var res = await NaswizHelperApi.GET_WITHOUT_TOKEN('v1/delete-cart/' + id, body, "Error => v1/add-usercart")
//         if (res && res.success === 1) {
//             Notification('success', 'Success!', res.message ? res.message : 'Product deleted successfully.')
//             if (this.props.isUserLogin) {
//                 var body = new FormData()
//                 body.append('device_id', this.state.uniueId)
//                 this.props.getCartData('v1/get-cart-list', body, "Error => GetCartList api.")

//             } else {
//                 var body = new FormData()
//                 body.append('device_id', this.state.uniueId)
//                 this.props.getCartDataWithoutLogin('v1/get-cart-list-without-login', body, "Error => get-cart-list-without-login.")

//             }
//             this.quickviewrelatedfu(this.state._slug_quick)


//         }
//         else {
//             Notification('error', 'Error!', res.message ? res.message : 'Login First')
//         }


//     }
//     decrease = () => {
//         if (this.state.quick_qty > 1) {
//             if (this.props.isUserLogin) {

//                 this.quickViewAddToCart(2)

//             } else {

//                 this.quickViewAddToCartWithoutLogin(2)

//                 // }
//             }
//         } else {

//             console.log('delete id ', this.state._deleteID)
//             if (this.state._deleteID) {
//                 this.deleteCart(this.state._deleteID)

//             }
//         }
//     }

//     increase = async () => {
//         if (this.props.isUserLogin) {
//             this.quickViewAddToCart(1)

//         } else {
//             this.quickViewAddToCartWithoutLogin(1)

//             // }
//         }
//     }

//     _deCreaseQTY = async (id) => {
//         var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(`v1/delete-cart/${id}`, "Error => Remove item from cart api.")
//         if (res && res.success === 1) {
//             this.props.updateDataAfterAction()
//             var formdata = new FormData()
//             formdata.append('device_id', this.state.uniueId)
//             this.props.getCartData('v1/get-cart-list', formdata, "Error => GetCartList api.")

//         }
//         else {
//             alert(res && res.message ? res.message : 'Error while remove or de-crease quantity')
//         }


//     }


//     quickviewrelatedfu = async (slug_quick) => {
//         console.log("slug :", slug_quick)
//         var _shareurl = this.state._current_Url + '/' + slug_quick

//         await this.setState({
//             _slug_quick: slug_quick,
//             // selectedColorIdQuick: null,
//             // selectedSizeIdQuick: null,
//             // quick_qty: 0,
//             _cartItem: [],
//             _current_Url: _shareurl

//         })
//         var userID = this.props.userID
//         var url = userID ? `v1/product-detail/${slug_quick}/${this.state.uniueId}/${userID}` : `v1/product-detail/${slug_quick}/${this.state.uniueId}/0`

//         this.setState({ loading_detail: true }, async () => {

//             var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(url, 'Error => products details with slug api.')

//             if (res && res.success === 1) {

//                 this.setState({
//                     quick_product_detail: res.records,
//                     quick_related_record: res.related_product,
//                     quick_product_id: res.records.product_id,
//                     _product_type: res.records.product_type,
//                     _cartItem: res.records.cart,
//                     quick_qty: 0,
//                 })
//                 if (
//                     res.records.description.length > 150
//                 ) {
//                     this.setState({ _product_description: res.records.description.substring(0, 150), _product_description2: res.records.description })
//                 } else {
//                     this.setState({ _product_description: res.records.description, _product_description2: res.records.description })
//                 }
//                 if (this.state.selectedColorIdQuick === '' || this.state.selectedColorIdQuick === 0 || this.state.selectedColorIdQuick === null) {
//                     if (res.records.details.length > 0 && (res.records.product_type === '3' || res.records.product_type === '4')) {

//                         await this.setState({ _maxQty: res.records.details[0].max_order_qty, selectedColorIdQuick: res.records.details[0].color_id, isColorSelectedQuick: true, colorNotSelectText: 'Color Selected' })
//                         //    check stock 0 then set btn disable
//                         var isBtnEnbl = res.records.details[0].inventory - res.records.details[0].used
//                         if (isBtnEnbl === 0) {
//                             this.setState({ _isBtnEnble: false })
//                         }

//                     }
//                 }

//                 if (this.state.selectedSizeIdQuick === '' || this.state.selectedSizeIdQuick === 0 || this.state.selectedSizeIdQuick === null) {
//                     if (res.records.details.length > 0 && (res.records.product_type === '2' || res.records.product_type === '4')) {
//                         await this.setState({ _maxQty: res.records.details[0].max_order_qty, selectedSizeIdQuick: res.records.details[0].size_id, isSizeSelectedQuick: true, notSelectedText: 'Size Selected' })
//                         var isBtnEnbl = res.records.details[0].inventory - res.records.details[0].used
//                         if (isBtnEnbl === 0) {
//                             this.setState({ _isBtnEnble: false })
//                         }
//                     }
//                 }
//                 this.checkCartItem(this.state.selectedSizeIdQuick, this.state.selectedSizeIdQuick)
//                 this.setState({ quickviewModalVisibility: true });
//             }
//             else {
//                 this.setState({ quickviewModalVisibility: false });
//                 this.setState({
//                     loading_detail: false
//                 })
//             }

//         })
//     }
//     async componentDidMount() {

//         var uniueId = localStorage.getItem('deviceId')
//         this.setState({ uniueId: uniueId, _current_Url: window.location.href })
//     }
//     quickViewQuantityChange = async (qty, item) => {
//         var selectedColorId = this.state.selectedColorId;
//         var selectedSizeId = this.state.selectedSizeId;
//         if (selectedColorId === undefined || selectedColorId === null) {
//             Notification('error', 'Error!', 'Please select color first!')
//         }
//         else if (selectedSizeId === undefined || selectedSizeId === null) {
//             Notification('error', 'Error!', 'Please select size first!')
//         }
//         if (qty === '' || qty === '0') {
//             Notification('error', 'Error!', 'Please enter quantity!')
//         }
//         else {
//             if (item.details.length > 0) {
//                 item.details.map((i, index) => {
//                     if (i.color_id === selectedColorId && i.size_id === selectedSizeId) {
//                         if (i.min_order_qty > qty) {
//                             Notification('error', 'Error!', 'Qyantity should be greater than or euale to ' + i.min_order_qty)
//                         }
//                         else if (i.max_order_qty < qty) {
//                             Notification('error', 'Error!', 'Qyantity should be less than or euale to ' + i.max_order_qty)
//                         }
//                         else {
//                             setTimeout(() => {
//                                 this.setState({
//                                     quick_qty: qty
//                                 });
//                             }, 1000);
//                         }
//                     }
//                 })
//             }
//         }
//     }

//     async checkCartItem(colorid, sizeid) {
//         var isMatch = false;
//         for (let i = 0; i < this.state._cartItem.length; i++) {
//             if (this.state._product_type === '4' && colorid != '' && sizeid != '') {
//                 if (colorid === this.state._cartItem[i].color_id && sizeid === this.state._cartItem[i].size_id) {
//                     this.setState({ quick_qty: this.state._cartItem[i].total_quantity, _deleteID: this.state._cartItem[i].id })
//                     isMatch = true;
//                 }
//             } else if (this.state._product_type === '3' && colorid != '') {
//                 if (colorid === this.state._cartItem[i].color_id) {
//                     this.setState({ quick_qty: this.state._cartItem[i].total_quantity, _deleteID: this.state._cartItem[i].id })
//                     isMatch = true;
//                 }
//             } else if (this.state._product_type === '2' && sizeid != '') {

//                 if (sizeid === this.state._cartItem[i].size_id) {
//                     this.setState({ quick_qty: this.state._cartItem[i].total_quantity, _deleteID: this.state._cartItem[i].id })
//                     isMatch = true;
//                 }
//             } else if (this.state._product_type === '1') {
//                 this.setState({ quick_qty: this.state._cartItem[i].total_quantity, _deleteID: this.state._cartItem[i].id })
//                 isMatch = true;
//             }
//         }
//         if (!isMatch) {
//             this.setState({ quick_qty: 0 })
//         }
//     }
//     quickViewAddToCart = async (type) => {
//         var { quick_product_detail, selectedColorIdQuick, selectedSizeIdQuick } = this.state
//         if ((quick_product_detail.product_type === '3' || quick_product_detail.product_type === '4')) {
//             if (selectedColorIdQuick === null || !this.state.isColorSelectedQuick) {
//                 Notification('error', 'Error!', 'Please select color !')
//                 return;
//             }
//         }
//         if ((quick_product_detail.product_type === '2' || quick_product_detail.product_type === '4')) {
//             if (selectedSizeIdQuick === null || !this.state.isSizeSelectedQuick) {
//                 Notification('error', 'Error!', 'Please select size !')
//                 return;
//             }
//         }
//         var qty = this.state.quick_qty
//         if (type === 2) {
//             qty = this.state.quick_qty - 1

//         } else {
//             qty = this.state.quick_qty + 1

//         }
//         var body = new FormData()
//         body.append('product_id', quick_product_detail.product_id)
//         body.append('color_id', selectedColorIdQuick)
//         body.append('size_id', selectedSizeIdQuick)
//         body.append('main_price', quick_product_detail.price)
//         body.append('price', quick_product_detail.wholesale_price)
//         body.append('quantity', qty)
//         body.append('device_id', this.state.uniueId)

//         if (quick_product_detail.product_images.length > 0) {

//             var temp = quick_product_detail.product_images[0].image.includes('https://newdemo.myshopwiz.com/master-naswiz//') ? 'https://newdemo.myshopwiz.com/master-naswiz//' : quick_product_detail.product_images[0].image.includes('https://newdemo.myshopwiz.com/master-naswiz/') ? 'https://newdemo.myshopwiz.com/master-naswiz/' : null
//             body.append('image', quick_product_detail.product_images[0].image.replace(temp, ''))
//         }
//         else {
//             body.append('image', `storage/uploads/product/Medium${process.env.PUBLIC_URL}/assets/images/nia.png`)
//         }
//         for (var pair of body.entries()) {
//         }
//         var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/add-usercart', body, "Error => v1/add-usercart")

//         if (res && res.success === 1) {
//             Notification('success', 'Success!', res.message ? res.message : 'Product Add to Cart Successfully.')
//             var formdata = new FormData()
//             formdata.append('device_id', this.state.uniueId)
//             this.props.getCartData('v1/get-cart-list', formdata, "Error => GetCartList api.")
//             this.props.updateDataAfterAction()
//             var qty = this.state.quick_qty
//             if (type === 2) {
//                 await this.setState({ quick_qty: this.state.quick_qty - 1 });

//             } else {
//                 await this.setState({ quick_qty: this.state.quick_qty + 1 });

//             }
//             this.quickviewrelatedfu(this.state._slug_quick)

//         }
//         else {
//             Notification('error', 'Error!', res.message ? res.message : 'Login First')
//         }

//     }
//     _addToCart = async (product_detail, selectedColorId, selectedSizeId, pType, qtyUpdate) => {

//         if (!this.props.isUserLogin) {
//             Notification('error', 'Error!', 'Please Login First!')
//         }
//         else {
//             if (pType === 3 || pType === 4) {
//                 if (selectedSizeId === undefined || selectedSizeId === null) {
//                     Notification('error', 'Error!', 'Please select size first!')
//                     return;
//                 }

//             }
//             if (pType === 2 || pType === 4) {
//                 if (selectedSizeId === undefined || selectedSizeId === null) {
//                     Notification('error', 'Error!', 'Please select size first!')
//                     return;
//                 }
//             }


//             var body = new FormData()
//             body.append('product_id', product_detail.product_id)
//             body.append('color_id', selectedColorId)
//             if (pType === 2) {
//                 body.append('size_id', selectedSizeId)
//             }
//             body.append('main_price', product_detail.price)
//             body.append('price', product_detail.wholesale_price)
//             body.append('device_id', this.state.uniueId)

//             var qtyvalue = 1
//             if (qtyUpdate === 1) {
//                 if (product_detail.cart[0].total_quantity > 1) {
//                     qtyvalue = product_detail.cart[0].total_quantity - 1

//                 } else {
//                     this._deCreaseQTY(product_detail.cart[0].id)
//                     return;
//                 }

//             } else if (qtyUpdate === 2) {
//                 qtyvalue = product_detail.cart[0].total_quantity + 1

//             } else {
//                 qtyvalue = product_detail.details[0].min_order_qty
//             }
//             if (product_detail.details.length > 0) {
//                 if (product_detail.details[0].color_id === selectedColorId && product_detail.details[0].size_id === selectedSizeId) {
//                     body.append('quantity', qtyvalue)
//                 }
//                 else {
//                     body.append('quantity', 1)
//                 }
//             }
//             else {
//                 body.append('quantity', 1)
//             }
//             if (product_detail.product_images.length > 0) {

//                 var temp = product_detail.product_images[0].image.includes('https://newdemo.myshopwiz.com/master-naswiz//') ? 'https://newdemo.myshopwiz.com/master-naswiz//' : product_detail.product_images[0].image.includes('https://newdemo.myshopwiz.com/master-naswiz/') ? 'https://newdemo.myshopwiz.com/master-naswiz/' : null
//                 body.append('image', product_detail.product_images[0].image.replace(temp, ''))
//             }
//             else {
//                 body.append('image', `storage/uploads/product/Medium${process.env.PUBLIC_URL}/assets/images/nia.png`)
//             }

//             for (var pair of body.entries()) {
//             }

//             var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/add-usercart', body, "Error => v1/add-usercart")

//             if (res && res.success === 1) {
//                 this.props.updateDataAfterAction()

//                 Notification('success', 'Success!', res.message ? res.message : 'Product Add to Cart Successfully.')
//                 var formdata = new FormData()
//                 formdata.append('device_id', this.state.uniueId)
//                 this.props.getCartData('v1/get-cart-list', formdata, "Error => GetCartList api.")

//                 this.setState({
//                     selectedColorId: null,
//                     selectedColorIndex: null,
//                     selectedSizeId: null,
//                     selectedSizeIndex: null
//                 })
//                 if (pType === 3) {
//                     this.setState({
//                         _isColorPicker: false
//                     })
//                 }
//                 if (pType === 2) {
//                     this.setState({
//                         _isSizePicker: false
//                     })
//                 }
//             }
//             else {
//                 Notification('error', 'Error!', res.message + '')
//             }


//         }
//     }

//     //ADD to cart without login
//     quickViewAddToCartWithoutLogin = async (type) => {

//         var { quick_product_detail, selectedColorIdQuick, selectedSizeIdQuick } = this.state
//         if ((quick_product_detail.product_type === '3' || quick_product_detail.product_type === '4')) {
//             if (selectedColorIdQuick === null || !this.state.isColorSelectedQuick) {
//                 Notification('error', 'Error!', 'Please select color !')
//                 return;
//             }
//         }
//         if ((quick_product_detail.product_type === '2' || quick_product_detail.product_type === '4')) {
//             if (selectedSizeIdQuick === null || !this.state.isSizeSelectedQuick) {
//                 Notification('error', 'Error!', 'Please select size !')
//                 return;
//             }
//         }
//         var qty = this.state.quick_qty
//         if (type === 2) {
//             qty = this.state.quick_qty - 1

//         } else {
//             qty = this.state.quick_qty + 1

//         }


//         var body = new FormData()
//         body.append('product_id', quick_product_detail.product_id)
//         body.append('color_id', selectedColorIdQuick)
//         body.append('size_id', selectedSizeIdQuick)
//         body.append('main_price', quick_product_detail.price)
//         body.append('price', quick_product_detail.wholesale_price)
//         body.append('device_id', this.state.uniueId)
//         body.append('quantity', qty)
//         // body.append('image', quick_product_detail.product_images[0].image)


//         if (quick_product_detail.product_images.length > 0) {

//             var temp = quick_product_detail.product_images[0].image.includes('https://newdemo.myshopwiz.com/master-naswiz//') ? 'https://newdemo.myshopwiz.com/master-naswiz//' : quick_product_detail.product_images[0].image.includes('https://newdemo.myshopwiz.com/master-naswiz/') ? 'https://newdemo.myshopwiz.com/master-naswiz/' : null
//             body.append('image', quick_product_detail.product_images[0].image.replace(temp, ''))
//         }
//         else {
//             body.append('image', `storage/uploads/product/Medium${process.env.PUBLIC_URL}/assets/images/nia.png`)
//         }
//         for (var pair of body.entries()) {
//         }

//         var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/add-usercart-without-login', body, "Error => v1/add-usercart")
//         if (res && res.success === 1) {
//             Notification('success', 'Success!', res.message ? res.message : 'Product Add to Cart Successfully.')
//             var body = new FormData()
//             body.append('device_id', this.state.uniueId)
//             this.props.getCartDataWithoutLogin('v1/get-cart-list-without-login', body, "Error => get-cart-list-without-login.")

//             var qty = this.state.quick_qty
//             if (type === 2) {
//                 await this.setState({ quick_qty: this.state.quick_qty - 1 });

//             } else {
//                 await this.setState({ quick_qty: this.state.quick_qty + 1 });

//             }
//             this.quickviewrelatedfu(this.state._slug_quick)

//         }
//         else {
//             Notification('error', 'Error!', res.message ? res.message : 'Login First')
//         }



//     }
//     //direct fom list
//     _addToCartWihoutLogin = async (product_detail, selectedColorId, selectedSizeId, pType, qtyUpdate) => {

//         if (pType === 3 || pType === 4) {
//             if (selectedSizeId === undefined || selectedSizeId === null) {
//                 Notification('error', 'Error!', 'Please select size first!')
//                 return;
//             }

//         }
//         if (pType === 2 || pType === 4) {
//             if (selectedSizeId === undefined || selectedSizeId === null) {
//                 Notification('error', 'Error!', 'Please select size first!')
//                 return;
//             }

//         }


//         var body = new FormData()
//         body.append('product_id', product_detail.product_id)
//         body.append('device_id', this.state.uniueId)

//         body.append('color_id', selectedColorId)
//         if (pType === 2) {
//             body.append('size_id', selectedSizeId)
//         }
//         body.append('main_price', product_detail.price)
//         body.append('price', product_detail.wholesale_price)


//         var qtyvalue = 1
//         if (qtyUpdate === 1) {
//             if (product_detail.cart[0].total_quantity > 1) {
//                 qtyvalue = product_detail.cart[0].total_quantity - 1

//             } else {
//                 this._deCreaseQTY(product_detail.cart[0].id)
//                 this.props.updateDataAfterAction()

//                 return;
//             }

//         } else if (qtyUpdate === 2) {
//             qtyvalue = product_detail.cart[0].total_quantity + 1

//         } else {
//             qtyvalue = product_detail.details[0].min_order_qty
//         }
//         if (product_detail.details.length > 0) {
//             if (product_detail.details[0].color_id === selectedColorId && product_detail.details[0].size_id === selectedSizeId) {
//                 body.append('quantity', qtyvalue)
//             }
//             else {
//                 body.append('quantity', 1)
//             }
//         }
//         else {
//             body.append('quantity', 1)
//         }
//         if (product_detail.product_images.length > 0) {

//             var temp = product_detail.product_images[0].image.includes('https://newdemo.myshopwiz.com/master-naswiz//') ? 'https://newdemo.myshopwiz.com/master-naswiz//' : product_detail.product_images[0].image.includes('https://newdemo.myshopwiz.com/master-naswiz/') ? 'https://newdemo.myshopwiz.com/master-naswiz/' : null
//             body.append('image', product_detail.product_images[0].image.replace(temp, ''))
//         }
//         else {
//             body.append('image', `storage/uploads/product/Medium${process.env.PUBLIC_URL}/assets/images/nia.png`)
//         }

//         for (var pair of body.entries()) {
//         }

//         var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/add-usercart-without-login', body, "Error => v1/add-usercart")


//         if (res && res.success === 1) {
//             Notification('success', 'Success!', res.message ? res.message : 'Product Add to Cart Successfully.')
//             this.props.updateDataAfterAction()

//             var formdata = new FormData()
//             formdata.append('device_id', this.state.uniueId)
//             this.props.getCartDataWithoutLogin('v1/get-cart-list-without-login', formdata, "Error => get-cart-list-without-login.")
//             this.setState({
//                 selectedColorId: null,
//                 selectedColorIndex: null,
//                 selectedSizeId: null,
//                 selectedSizeIndex: null
//             })
//             if (pType === 3) {
//                 this.setState({
//                     _isColorPicker: false
//                 })
//             }
//             if (pType === 2) {
//                 this.setState({
//                     _isSizePicker: false
//                 })
//             }
//         }
//         else {
//             Notification('error', 'Error!', res.message + '')

//         }



//     }

//     handleClose = () => {
//         this.setState({
//             quickviewModalVisibility: false,
//             selectedColorId: null,
//             selectedColorIndex: null,
//             selectedSizeId: null,
//             selectedSizeIndex: null,
//             quick_qty: 0,
//             notQty: '',
//             colorNotSelectText: '',
//             notSelectedText: '', notQty: '',
//             colorNotSelectText: '',
//             notSelectedText: '',
//             isSizeSelected: false,
//             isColorSelected: false,
//         })
//     }

//     Handleonchange = (e) => {
// 		this.setState({
// 			[e.target.id]: e.target.name
// 		})
// 	}
//     // Handleonchange=(e)=>{
//     //     e.target.value
//     // }
//     checkCart = async (value, index) => {
//         // console.log(value)
//         var count = value.inventory - value.used
//         console.log(count)
//         if (count === 0)
//             this.setState({ cartVisible: false })
//         else
//             this.setState({ cartVisible: true })
//     }

//     render() {

//         const { items, symbol, addToCart, addToWishlist, addToCompare, type, topCollectionItems } = this.props;
//         const { product_detail, related_record, quick_product_detail, quick_product_id, quick_related_record } = this.state;

//         var colorsFilter = quick_product_detail && quick_product_detail.details ? Array.from(new Set(quick_product_detail.details.map(s => s.color_code))).map(id => {
//             return {
//                 color_name: id,
//                 color_id: quick_product_detail.details.find(item => {
//                     if (id === item.color_code) {
//                         return item.color_id
//                     }
//                 })
//             }
//         }) : null

//         var sizeFilter = quick_product_detail && quick_product_detail.details ? Array.from(new Set(quick_product_detail.details.map(s => s.size_name))).map(id => {
//             return {
//                 size_name: id,
//                 size_id: quick_product_detail.details.find(item => {
//                     if (id === item.size_name) {
//                         return item.size_id
//                     }
//                 })
//             }
//         }) : null

//         var quickviewrelated = {
//             slidesToShow: 1,
//             slidesToScroll: 1,
//             dots: false,
//             arrows: true,
//             fade: true
//         };
//         var quickproductsnav = {
//             slidesToShow: 3,
//             slidesToScroll: 1,
//             swipeToSlide: true,
//             arrows: false,
//             dots: false,
//             focusOnSelect: true
//         };

//         var properties;
//         if (type === 'kids') {
//             properties = Product5
//         } else {
//             properties = Product4
//         }

//         return (

//             <div className="FeaturedSlider-content">
//                 {/* <OwlCarousel className='owl-theme nav-outer show-nav-hover nav-image-center' {...options}> */}
// <div className='row'>
//                     {

//                         topCollectionItems && topCollectionItems.length > 0 ?
//                             topCollectionItems.map((item, index) => {
//                                 return (
// <div key={index} className='col-md-3'>
//                                     <div  className="inner-quickview inner-icon feachered_products_slider_home">
//                                         <div className="product-default">
//                                             <figure>
//                                                 <Link
//                                                     to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.categories[0].slug}/${item.slug}`, state: [] }}
//                                                 >
//                                                     <img src={item.product_images[0] ? item.product_images[0].image : `${process.env.PUBLIC_URL}/assets/images/nia.png`} />
//                                                 </Link>
//                                                 <div className="btn-icon-group search_top">
//                                                     <button onClick={() => this.quickviewrelatedfu(item.slug)} className="btn-icon btn-quickview" title="Quick View">
//                                                         <i className="icon-search"></i>
//                                                     </button>
//                                                 </div>
//                                                 {item.product_type == '3' ?
//                                                 <div>
//                                                     <div className="product-action new_styles_ff new_btnsty">
//                                                         <a href="javascript:void(0);" id="show_pm123" className="btn btn-dark add-cart new_shades" style={{ backgroundColor: '#000!important' }} title="View Details" onClick={() => this.setState({ _isColorPicker: !this.state._isColorPicker })}>Add Color</a>
//                                                     </div>
//                                                     <div className={this.state._isColorPicker ? "shades_area both_btn active" : "shades_area both_btn"}  >
//                                                         <div className="product-filters-container scroll_cont">
//                                                             <label htmlFor="">Select Color</label>
//                                                             <div className="product-single-filter">
//                                                                 <ul className="config-swatch-list detail-color" id="mylist">
//                                                                     {
//                                                                         item.details && item.details.length > 0 ?

//                                                                             item.details.map((subitem, index) => {
//                                                                                 return (
//                                                                                     <div key={index}>
//                                                                                         {/* <li key={index}><input className={this.state._selectColorId == subitem.color_id ? 'active' : ''} type="radio" name="name" onClick={() => this.setState({ _selectColorId: subitem.color_id })} />

//                                                                                             <a style={{ backgroundColor: `${subitem.color_code}`, marginLeft: '5px' }}></a>
//                                                                                         </li> */}
//                                                                                             <input className={this.state._selectColorId == subitem.color_id ? 'active' : ''} type="radio" name="name" onChange={(event)=>{this.Handleonchange(event)}} onClick={async() => {
//                                                                                                 await  this.setState({ _selectColorId: subitem.color_id })
//                                                                                                 await this.checkCart(subitem, index)
//                                                                                                 }} />
//                                                                                     </div>
//                                                                                 )
//                                                                             }) : null
//                                                                     }
//                                                                 </ul>
//                                                             </div>
//                                                         </div>
//                                                         <div className="cross_icon" id="close_id">
//                                                             <a href="javascript:void(0)" onClick={async() =>{
//                                                                 await this.setState({ _isColorPicker: !this.state._isColorPicker })
//                                                                 }}>X</a>
//                                                         </div>
//                                                         <div className="details_cart">
//                                                             <div className="left_view">
//                                                                 <Link to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.slug}/${item.slug}`, state: [] }}>
//                                                                     View Details
//                                                                 </Link>
//                                                             </div>
//                                                             <div className={this.state.cartVisible ? "right_cart" : "right_cart disable"}>
//                                                                 <a href="javascript:void(0)" id="show_in_ar" onClick={() => {
//                                                                     if (this.state._selectColorId !== 0) {
//                                                                         if (this.props.isUserLogin) {
//                                                                             this._addToCart(item, this.state._selectColorId, '', 3)
//                                                                         } else {
//                                                                             this._addToCartWihoutLogin(item, this.state._selectColorId, '', 3)

//                                                                         }
//                                                                     } else {
//                                                                         Notification('error', 'Error!', 'Please select color first!')

//                                                                     }

//                                                                 }

//                                                                 // } >Add to Cart</a>
//                                                                 } >{this.state.cartVisible ? 'Add to Cart' : 'Out of stock'}</a>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div> : item.product_type == '2' ?
//                                                 <div>
//                                                     <div className="product-action new_styles_ff new_btnsty" key={index} >
//                                                             <a href="javascript:void(0);" id="show_pm" className="btn btn-dark add-cart new_shades" style={{ backgroundColor: '#000!important' }} title="View Details"
//                                                                 // onClick={() => this.setState({ _isSizePicker: !this.state._isSizePicker, _selectSizeIndex: index })}>
//                                                                 onClick={async () => {
//                                                                     await this.setState({ _isSizePicker: !this.state._isSizePicker })
//                                                                     await this.setState({ _selectSizeIndex: index, })
//                                                                 }}>
//                                                                 Add Size</a>
//                                                         </div>
//                                                     <div className={this.state._isSizePicker && this.state._selectSizeIndex == index ? "shades_area both_btn active" : "shades_area both_btn"}  >
//                                                         <div className="product-filters-container scroll_cont">
//                                                             <label htmlFor="">Select Size</label>
//                                                             <div className="product-single-filter">
//                                                                 <ul className="config-swatch-list detail-color" id="mylist">
//                                                                     {
//                                                                         item.details && item.details.length > 0 ?

//                                                                             item.details.map((subitem, indexsize) => {
//                                                                                 return (
//                                                                                     <li key={indexsize} >
//                                                                                         <div className="size_box"
//                                                                                         onClick={() => {
//                                                                                             this.setState({ _selectSizeId: subitem.size_id })
//                                                                                             this.checkCart(subitem, index)
//                                                                                         }}
//                                                                                             style={this.state._selectSizeId == subitem.size_id && this.state._selectSizeIndex == index ? colorStyle : null}>
//                                                                                             <li key={indexsize}><a>{subitem.size_name}</a></li>
//                                                                                         </div>
//                                                                                     </li>
//                                                                                 )
//                                                                             }) : null
//                                                                     }
//                                                                 </ul>
//                                                             </div>
//                                                         </div>
//                                                         <div className="cross_icon" id="close_id">
//                                                             <a href="javascript:void(0)" onClick={async() => {
//                                                                 await this.setState({ _isSizePicker: !this.state._isSizePicker})
//                                                                 console.log("closed clicked");
//                                                                 console.log(this.state._isSizePicker);
//                                                                 }
//                                                         } >X</a>
//                                                         </div>
//                                                         <div className="details_cart">
//                                                             <div className="left_view">
//                                                                 <Link to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.slug}/${item.slug}`, state: [] }}>
//                                                                     View Details
//                                                                 </Link>
//                                                             </div>
//                                                             <div className={this.state.cartVisible ? "right_cart" : "right_cart disable"}>
//                                                                 <a href="javascript:void(0)" id="show_in_ar" onClick={() => {
//                                                                     if (this.state._selectSizeId != 0) {
//                                                                         if (this.props.isUserLogin) {
//                                                                             this._addToCart(item, '', this.state._selectSizeId, 2)
//                                                                         } else {
//                                                                             this._addToCartWihoutLogin(item, '', this.state._selectSizeId, 2)

//                                                                         }
//                                                                     } else {
//                                                                         Notification('error', 'Error!', 'Please select color first!')

//                                                                     }
//                                                                 }
//                                                                 // } >Add to Cart</a>
//                                                                 } >{this.state.cartVisible ? 'Add to Cart' : 'Out of stock'}</a>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div> :
//                                                         item.details && item.details.length === 1 ?
//                                                             item.details.map((color, index) => {
//                                                                 return (
//                                                                     <div className="product-action new_styles_ff new_btnsty" key={index}>
//                                                                         {item.cart != null && item.cart.length > 0 ?
//                                                                             <div className="def-number-input number-input test">
//                                                                                 <button onClick={() => {
//                                                                                     if (this.props.isUserLogin) {
//                                                                                         this._addToCart(item, color.color_id, color.size_id, 4, 1)

//                                                                                     } else {
//                                                                                         this._addToCartWihoutLogin(item, color.color_id, color.size_id, 4, 1)

//                                                                                     }
//                                                                                 }} className="minus"></button>
//                                                                                 <input className="quantity" name="quantity" value={item.cart[0].total_quantity} type="number" />
//                                                                                 <button onClick={() => {
//                                                                                     if (this.props.isUserLogin) {

//                                                                                         this._addToCart(item, color.color_id, color.size_id, 4, 2)
//                                                                                     } else {
//                                                                                         this._addToCartWihoutLogin(item, color.color_id, color.size_id, 4, 2)

//                                                                                     }
//                                                                                 }} className="plus"></button>
//                                                                             </div> :
//                                                                             <a href="javascript:void(0);" onClick={() => {
//                                                                                 if (this.props.isUserLogin) {

//                                                                                     this._addToCart(item, color.color_id, color.size_id, 4)
//                                                                                 } else {
//                                                                                     this._addToCartWihoutLogin(item, color.color_id, color.size_id, 4)

//                                                                                 }
//                                                                             }
//                                                                             //} id="show_pm" className="btn btn-dark add-cart new_shades" title="Add to Cart">Add to Cart (disable)</a>}
//                                                                             } id="show_pm" className={item.details[0].inventory - item.details[0].used > 0 ? "btn btn-dark add-cart new_shades" : "btn btn-dark add-cart new_shades disable"} title="Add to Cart">{item.details[0].inventory - item.details[0].used > 0 ? 'Add to Cart' : 'Out of stock'}</a>}
//                                                                             {/* className= title="Add to Cart"> */}

//                                                                     </div>
//                                                                 )
//                                                             })
//                                                             :
//                                                             <Link to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.slug}/${item.slug}`, state: [] }}>
//                                                                 <div className="product-action new_styles_ff new_btnsty">
//                                                                     <a href="javascript:void(0);" id="show_pm" className="btn btn-dark add-cart new_shades view_details" style={{ backgroundColor: '#000!important' }} title="View Details">View Details</a>
//                                                                 </div>
//                                                             </Link>
//                                                 }

//                                             </figure>
//                                             <div className="product-detail featured_products_details">
//                                                 <div className="category-wrap">
//                                                     <div className="category-list">
//                                                         <Link
//                                                             to={{ pathname: `${process.env.PUBLIC_URL}/Products/${item.categories[0].slug}`, state: item.categories[0].slug }}
//                                                         >{item.categories[0].name}</Link>
//                                                     </div>

//                                                     <a>
//                                                     {/* onClick={async () => {
//                                                                     await this.setState({ _selectSizeIndex: index, })
//                                                                     await this.setState({ _isSizePicker: true })
//                                                                 }}> */}
//                                                         <i onClick={async() => await item.wishlist == 1 ? this._removeFromWishListApi(item, index) : this._addToWishlistWithApi(item, index)} className={item.wishlist == 1 ? "fa fa-heart" : "icon-heart"} ></i>
//                                                     </a>
//                                                 </div>
//                                                 <h2 className="product-title">
//                                                     <Link
//                                                         to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.categories[0].slug}/${item.slug}`, state: [] }}
//                                                     >{item.name}</Link>
//                                                 </h2>
//                                                 <div className="ratings-container">
//                                                     <Rate className="rate" defaultValue={item.avg_rating} disabled />
//                                                     <span className="tooltiptext tooltip-top">5.00</span>

//                                                 </div>
//                                                 <h3 className="product-title-ip">
//                                                     <a href="javascript:void(0)"> Incentive Point (IP) <span>{item.incentive_point}</span></a>
//                                                 </h3>
//                                                 <div className="price-box">
//                                                     <span className="product-price">₹{item.wholesale_price > 0 ? item.wholesale_price : item.price}</span>
//                                                     <span className="old-price">₹{item.wholesale_price > 0 ? item.price : ''}</span>
//                                                     <span className="percentage-dis 0000">({item.off}%)</span>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     </div>
//                                 )
//                             })
//                             : null
//                     }
//                     </div>
//                 {/* </OwlCarousel> */}
//                 <Modal
//                     open={this.state.quickviewModalVisibility}
//                     onClose={this.handleClose} center>
//                     <div className="product-single-container product-single-default product-quick-view">
//                         <div className="row row-sparse">
//                             <div className="col-lg-6 product-single-gallery">
//                                 <div className="product-slider-container">
//                                     {quick_product_detail.product_images && quick_product_detail.product_images.length > 0 ? <div>
//                                         <CarouselProvider
//                                             visibleSlides={1}
//                                             totalSlides={quick_product_detail.product_images.length}
//                                             naturalSlideWidth={800}
//                                             naturalSlideHeight={800}
//                                             interval={3000}
//                                             isPlaying={false}
//                                             infinite
//                                         >
//                                             <Slider>
//                                                 <LightGallery
//                                                     onInit={this.onInit}
//                                                     speed={500}
//                                                 >
//                                                     {quick_product_detail.product_images.map((data, index) => {
//                                                         return (
//                                                             <Slide index={index} key={index} data-src={data.image}>
//                                                                 <ImageWithZoom src={data.image} className='d-block w-100 product-card-image' />
//                                                             </Slide>
//                                                         )
//                                                     })}
//                                                 </LightGallery>
//                                             </Slider>

//                                             <div className="carousel-next-previous-btn">
//                                                 <ButtonBack className='btn-back'>‹</ButtonBack>
//                                                 <ButtonNext className='btn-forward'>›</ButtonNext>
//                                             </div>

//                                             {<div className="all-dots">
//                                                 {quick_product_detail.product_images.map((data, index) => {
//                                                     return (
//                                                         <Dot slide={index} index={index}>
//                                                             <Image className='d-block w-100 product-card-image' src={data.image} />
//                                                         </Dot>
//                                                     )
//                                                 })}
//                                             </div>
//                                             }

//                                         </CarouselProvider>

//                                     </div> : null}
//                                     {/* {
//                                         quick_product_detail.product_images && quick_product_detail.product_images.length > 0 ?
//                                             <SliderSlick {...quickviewrelated} asNavFor={this.state.quicknav2} ref={slider => (this.quickslider1 = slider)} className="product-slick">
//                                                 {
//                                                     quick_product_detail.product_images.map((data, index) => {
//                                                         return (
//                                                             <div className="product-item" key={index}>
//                                                                 <img src={data.image} />
//                                                             </div>
//                                                         )
//                                                     })
//                                                 }
//                                             </SliderSlick>
//                                             :
//                                             <div className="product-item">
//                                                 <img src={`${process.env.PUBLIC_URL}/assets/images/nia.png`} style={{ minWidth: '305px' }} />
//                                             </div>
//                                     }
//                                     {
//                                         quick_product_detail && quick_product_detail.product_images && quick_product_detail.product_images.length > 1 ?
//                                             <SmallImages item={quick_product_detail.product_images} settings={quickproductsnav} navOne={this.state.quicknav1} ref={slider => (this.quickslider2 = slider)} /> :
//                                             null
//                                     } */}
//                                 </div>
//                             </div>
//                             <div className="col-lg-6 product-single-details mb-0">
//                                 <h1 className="product-title">{quick_product_detail.name}</h1>
//                                 <div className="ratings-container test2">
//                                     <Rate defaultValue={quick_product_detail.avg_rating} disabled />
//                                     <a className="rating-link">( {quick_product_detail.reviewrating ? quick_product_detail.reviewrating.length : ''} Reviews )</a>
//                                 </div>
//                                 <hr className="short-divider"></hr>
//                                 <div className="price-box">
//                                     <span className="ippoints">Incentive Point (IP) {quick_product_detail.incentive_point}</span>
//                                     <span className="product-price">₹{quick_product_detail.wholesale_price > 0 ? quick_product_detail.wholesale_price : quick_product_detail.price}</span>
//                                     {
//                                         quick_product_detail.wholesale_price > 0 ?
//                                             <span className="old-price">₹{quick_product_detail.price}</span>
//                                             : null
//                                     }
//                                     <span className="percentage-dis">({quick_product_detail.off}%)</span>
//                                 </div>
//                                 <div className={this.state._readMore ? "product-desc active" : "product-desc"}>
//                                     {this.state._product_description2.length > 150 ? this.state._readMore ? <div dangerouslySetInnerHTML={{ __html: this.state._product_description2 }} /> :
//                                         <div dangerouslySetInnerHTML={{ __html: this.state._product_description }} />
//                                         : <div dangerouslySetInnerHTML={{ __html: this.state._product_description }} />
//                                     }
//                                 </div>
//                                 {this.state._product_description2.length > 150 ? <button onClick={() => this.setState({ _readMore: !this.state._readMore })}>({this.state._readMore ? "Less More" : "Read More"})</button> : null}


//                                 <div className="product-filters-container">

//                                     {
//                                         quick_product_detail.product_type === '1' || quick_product_detail.product_type === '2' ?
//                                             null :
//                                             <div className="product-single-filter testecce">
//                                                 <label>Colors:</label>
//                                                 <ul className="config-swatch-list detail-color">
//                                                     {
//                                                         colorsFilter && colorsFilter.length > 0 ?
//                                                             colorsFilter.map((color, index) => {

//                                                                 return (
//                                                                     <li key={index}>
//                                                                         <input type="radio" name="name"
//                                                                             checked={this.state.selectedColorIdQuick === color.color_id.color_id}

//                                                                             onClick={() => {
//                                                                                 this.setState({ selectedColorIndex: index, selectedColorIdQuick: color.color_id.color_id, isColorSelectedQuick: true, colorNotSelectText: 'Color Selected' })
//                                                                                 this.checkCartItem(color.color_id.color_id, this.state.selectedSizeIdQuick)

//                                                                             }} />
//                                                                         <a style={{ backgroundColor: `${color.color_name}` }}></a>
//                                                                     </li>
//                                                                 )
//                                                                 // }
//                                                             }) : null
//                                                     }
//                                                 </ul>
//                                                 {/* {!this.state.isColorSelected ? <p className="color-red">{this.state.colorNotSelectText}</p> : <p className="color-green">{this.state.colorNotSelectText}</p>} */}
//                                             </div>
//                                     }
//                                 </div>
//                                 <div className="product-filters-container">
//                                     {
//                                         quick_product_detail.product_type === '1' || quick_product_detail.product_type === '3' ?
//                                             null :
//                                             <div className="product-single-filter p-0">
//                                                 <label>Select Size:</label>
//                                                 <ul className="config-size-list">
//                                                     {
//                                                         sizeFilter && sizeFilter.length > 0 ?

//                                                             sizeFilter.map((size, index) => {
//                                                                 return (
//                                                                     <li>
//                                                                         <div className="TEST"
//                                                                             onClick={() => {
//                                                                                 this.setState({ _isBtnEnble: true, _maxQty: size.size_id.max_order_qty, selectedSizeIdQuick: size.size_id.size_id, isSizeSelectedQuick: true, notSelectedText: 'Size Selected' })
//                                                                                 this.checkCartItem(this.state.selectedColorIdQuick, size.size_id.size_id)
//                                                                                 if ((size.size_id.inventory - size.size_id.used) === 0) {
//                                                                                     this.setState({ _isBtnEnble: false })
//                                                                                 }
//                                                                             }}
//                                                                         >
//                                                                             <li key={index}><a style={this.state.selectedSizeIdQuick === size.size_id.size_id ? colorStyle : null}>{size.size_name}</a></li>

//                                                                         </div>
//                                                                     </li>
//                                                                 )
//                                                                 // }
//                                                             }) : null
//                                                     }
//                                                 </ul>
//                                                 {/* {!this.state.isSizeSelected ? <p className="color-red">{this.state.notSelectedText}</p> : <p className="color-green">{this.state.notSelectedText}</p>} */}
//                                             </div>
//                                     }

//                                 </div>
//                                 <hr className="divider"></hr>

//                                 <p className="color-red">{this.state.notQty}</p>
//                                 <div className="product-action custom-design ">
//                                     {this.state.quick_qty > 0 ? <div className="def-number-input number-input">
//                                         <button onClick={() => this.decrease()} className="minus"></button>
//                                         <input className="quantity" name="quantity" value={this.state.quick_qty} type="number" />
//                                         <button onClick={() => this.increase()} className={this.state.quick_qty == this.state._maxQty ? "plus disable" : "plus"}></button>
//                                     </div> :

//                                         <a onClick={() => {

//                                             if (this.props.isUserLogin) {
//                                                 this.quickViewAddToCart(1)
//                                             } else {
//                                                 this.quickViewAddToCartWithoutLogin(1)
//                                             }

//                                         // }} className={this.state._isBtnEnble ? "btn btn-dark add-cart icon-shopping-cart" : "btn btn-dark add-cart icon-shopping-cart disable"} title="Add to Cart">{this.state._isBtnEnble ? 'Add to Cart' : 'Out of stock'}</a>
//                                     }} className={this.state.cartVisible ? "btn btn-dark add-cart icon-shopping-cart" : "btn btn-dark add-cart icon-shopping-cart disable"} title="Add to Cart">{this.state.cartVisible ? 'Add to Cart' : 'Out of stock'}</a>

//                                     }
//                                 </div>
//                                 <hr className="divider mb-1"></hr>
//                                 <div className="product-single-share">
//                                     <label className="sr-only">Share:</label>
//                                     <div className="social-icons mr-2">
//                                         <FacebookShareButton
//                                             url={this.state._current_Url}
//                                             quote={"Share"}
//                                             hashtag=""
//                                         >
//                                             <a className="social-icon social-facebook icon-facebook" target="_blank" title="Facebook"></a>
//                                         </FacebookShareButton>
//                                         <TwitterShareButton
//                                             url={this.state._current_Url}
//                                             title={"Share "}
//                                             hashtag="#test"
//                                         >
//                                             <a className="social-icon social-twitter icon-twitter" target="_blank" title="Twitter"></a>
//                                         </TwitterShareButton>
//                                         <WhatsappShareButton
//                                             url={this.state._current_Url}
//                                             title={"Share "}
//                                             separator=":: "
//                                         >
//                                             {/* <WhatsappIcon size={28} /> */}
//                                             <a className="social-icon social-whatsapp icon-whatsapp" target="_blank" title="Whatsapp"></a>
//                                         </WhatsappShareButton>
//                                         <LinkedinShareButton
//                                             url={this.state._current_Url}
//                                             title={"Share "}
//                                             summary={""}
//                                         >
//                                             <a className="social-icon social-linkedin fab fa-linkedin-in" target="_blank" title="Linkedin"></a>
//                                         </LinkedinShareButton>
//                                         <EmailShareButton
//                                             body={this.state._current_Url}
//                                             subject={"Share "}
//                                             separator=" "
//                                         >
//                                             <a className="social-icon social-mail icon-mail-alt" target="_blank" title="Mail"></a>
//                                         </EmailShareButton>
//                                     </div>
//                                     <a href="javascript:void(0)"
//                                     onClick={() => quick_product_detail.wishlist == 1 ? this._removeFromWishListApi(quick_product_detail, 1) : this._addToWishlistWithApi(quick_product_detail)}
//                                     className={quick_product_detail.wishlist == 1 ? 'fa fa-heart' : 'icon-heart add-wishlist'}>
//                                        <span>Add to Wishlist</span>
//                                         </a>

//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </Modal>
//             </div>

//         )
//     }

//     _addToWishlistWithApi = async (item, index) => {
//         if (!this.props.isUserLogin) {
//             this.setState({ loginModalVisibility: true })
//             Notification('error', 'Error!', 'Please Login First!')
//         }
//         else {

//             var res = await NaswizHelperApi.GET_WITH_TOKEN('v1/wishlist/'.concat(item.product_id), "Error => Add to wishlist api")

//             if (res && res.success === 1) {
//                 if (res.message === 'Product is already in Wishlist.') {


//                     Notification('error', 'Error!', res.message)
//                 }
//                 else {
//                     item.wishlist = 1
//                     Notification('success', 'Success!', 'Product added to Favourites successfully!')
//                     this.quickviewrelatedfu(this.state._slug_quick)

//                     setTimeout(() => {
//                         this.props.updateDataAfterAction()
//                         this.props.getWishlistData('v1/wishlist-details/' + this.state.uniueId, "Error => Get all wishlist data")
//                     }, 1000)
//                 }
//             }
//             else {

//             }

//         }
//     }

//     _removeFromWishListApi = async (item, index) => {
//         this.setState({ wishlistitemremove: true })
//         if (!this.props.isUserLogin) {
//             this.setState({ loginModalVisibility: true })
//             Notification('error', 'Error!', 'Please Login First!')

//         }
//         else {
//             if (this.props._from === "from_wishlist") {
//                 var res = await NaswizHelperApi.GET_WITH_TOKEN('v1/removewishlist/'.concat(item.id), "Error => remove item from user wishlist")
//                 if (res && res.success === 1) {
//                     item.wishlist = 0

//                     setTimeout(() => {
//                         this.props.getWishlistData('v1/wishlist-details/' + this.state.uniueId, "Error => Get all wishlist data")
//                     }, 1000)
//                 } else {
//                     setTimeout(() => {
//                         this.props.getWishlistData('v1/wishlist-details/' + this.state.uniueId, "Error => Get all wishlist data")
//                     }, 1000)

//                 }
//             }
//             else {
//                 var res = await NaswizHelperApi.GET_WITH_TOKEN(`v1/removewishlist/${item.wishlist_id}`, "Error => remove item from user wishlist")
//                 if (res && res.success === 1) {
//                     item.wishlist = 0
//                     Notification('success', 'Success!', 'Item removed from Favourites!')
//                     this.quickviewrelatedfu(this.state._slug_quick)

//                     setTimeout(() => {
//                         this.props.updateDataAfterAction()
//                         this.props.getWishlistData('v1/wishlist-details/' + this.state.uniueId, "Error => Get all wishlist data")
//                     }, 1000)
//                 }
//             }
//         }
//     }
// }

// const mapStateToProps = (state, ownProps) => ({
//     items: getTrendingCollection(state.data.products, ownProps.type),
//     symbol: '₹ ',
//     isUserLogin: state.login.isUserLogin,
//     wishlistArray: state.login.userWishlistData.data,
//     userID: state.login.userID,
// })

// export default connect(mapStateToProps, { addToCart, addToWishlist, addToCompare, removeFromWishlist, getWishlistData, getCartData, getCartDataWithoutLogin })(TopCollection);