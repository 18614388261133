import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../common/breadcrumb";
import { NaswizHelperApi } from '../../../NaswizHelper';
import $ from 'jquery';
import Seo from "../../Seo";
class Cms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slugs: props.match.params.id || "unknown",
      items: props.location.state,
      _disc:
        props.location.state != undefined
          ? props.location.state.description
          : "",
      _title: "",
    };
    this.myRef = React.createRef()
  }
  //update content using api start
  naswizGetHomeDataApi = async () => {
    var userID = ''

    
    var url = userID ? `v1/get-home/${userID}/0` : 'v1/get-home/0/0'
    var res = await NaswizHelperApi.GET_WITHOUT_TOKEN(url, 'Error => v1/get-home')
    // console.log(res);
    // console.log(this.props.match.params.page);
    if (res && res.success == 1) {
        
      if(res.records.thecompany_cms.length && res.records.thecompany_cms.length > 0)
      {
          res.records.thecompany_cms.map((company, index) => {
            if(company.slug==this.props.match.params.page)
            {
              var data = company;
              this.setState({ items: data, _title: data.name, _disc: data.description });
            }
          }); 
      }

    }
}
//update content using api end

  componentDidMount = async () => {
    
    this.setState({
      _title:
        this.state.items === "" ||
          this.state.items === undefined ||
          this.state.items === "undefined"
          ? ""
          : this.state.items.name,
    });
    //update content start
    this.naswizGetHomeDataApi();
    //update content end comment below code

    /* console.log(localStorage.getItem('company'));
    var data = await JSON.parse(localStorage.getItem('company'))
    console.log("---data :", data)
    if (data !== null)
      await this.setState({ items: data, _title: data.name, _disc: data.description }) */
    // this.myRef.current.scrollTo(0, 0);
    //this.myRef.current.scrollIntoView();
    setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
  }, 2000); 

  };
  
  componentDidUpdate() {
    // console.log("update") 
     this.myRef.current.scrollTo(0, 0);
    //this.myRef.current.scrollIntoView();

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const data = nextProps.location.state
    if (nextProps.location.hasOwnProperty("state") === false) {
      return { items: '', _title: '', _disc: '' }
    }
    else if (nextProps.location.state === undefined || nextProps.location.state === null) {
      return {
        items: prevState.items, _title: prevState._title, _disc: prevState._disc
      }
    }
    else {
      return {
        items: data, _title: data.name, _disc: data.description
      }
    }
    
    // this.setState({ items: prevState.items, _title: prevState._title, _disc: prevState._disc })
  }

  componentWillReceiveProps(nextProps) {
    // console.log("testtt", nextProps.location.state);
    this.myRef.current.scrollIntoView();
    this.setState({
      slugs: nextProps.match.params.id,
      items:
        nextProps.location.state === undefined ||
          nextProps.location.state === "undefined"
          ? ""
          : nextProps.location.state,
      _disc:
        nextProps.location.state == undefined ||
          nextProps.location.state === "undefined"
          ? ""
          : nextProps.location.state.description,
    });

    this.setState({
      _title:
        nextProps.location.state == undefined ||
          nextProps.location.state === "undefined"
          ? ""
          : nextProps.location.state.name,
    });
    // window.scrollTo(0, 0)

  }

  render() {
    const { translate, _data, top_bar_cms, social_arr } = this.props;
    // console.log("--------", this.state._title)
    return (
      <div ref={this.myRef}>
        {this.state._title === undefined ?
          null :
          // <Helmet>
          //   <title>{`${this.state._title} Naswiz Retails Pvt Ltd`}</title>
          //   <meta name="og_title" property="og:title" content={this.state._title} />
          //   <meta name="twitter:title" content={this.state._title} />
          //   <meta name="Keywords" content={this.state._title} /> 
          //   <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
          //   <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
          //   <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
          // </Helmet>
          <Seo title={this.state._title} description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company."  />
        }
        {this.state._title == "" || this.state._title === undefined ? null : (
          <Breadcrumb title={this.state._title} />
        )}
        {this.state._title == "" ? null : (
          <section className="cms-wrapper pb-3">
            <div className="container">
            
              <div className="cms-inner-wrapper">

                {/* {this.state._disc != null ? renderHTML(this.state._disc) : <div className="no-data">no data found html template</div>} */}
                <div dangerouslySetInnerHTML={{ __html: this.state._disc }} />
              </div>

            </div>
          </section>
          // <div className="clearfix" >
          //   <div dangerouslySetInnerHTML={{ __html: this.state._disc }} />
          // </div>
        )}
      </div>
    );
  }
}

export default withTranslate(Cms);
