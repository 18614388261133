import React from 'react'
import { Helmet } from 'react-helmet'

const Seo = (props) => {
    return (
        <Helmet>
            <title>{props.title ? `${props.title} | Naswiz Retails Pvt Ltd` : "Naswiz Retails Pvt Ltd"}</title>
            <meta name="og_title" property="og:title" content={props.title ? `${props.title} | Naswiz Retails Pvt Ltd` : "Naswiz Retails Pvt Ltd"} />
            <meta name="twitter:title" content={`${props.title} | Naswiz Retails Pvt Ltd`} />
            <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
            <meta name="Description" content={props.description} />
            <meta property="og:description" content={props.description} />
            <meta name="twitter:description" content={props.description} />

            <meta charset="utf-8" />
            <meta name="description"
                content={props.description} />
            <meta content={`${props.title} | Naswiz Retails Pvt Ltd`}
                property="og:title" />
            <meta content={props.description ? props.description : "Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company."} property="og:description" />
            <meta content={props.image ? props.image : '../../public//assets/icon/Naswiz.png'} property="og:image" />
            <meta content={props.image ? props.image : '../../public//assets/icon/Naswiz.png'} property="twitter:image" />
            <meta content="400" property="og:image:width" />
            <meta content="400" property="og:image:height" />
            <meta content={window.location.href + window.location.search}
                property="og:url" />
            <link rel="canonical"
                href={window.location.href + window.location.search} />
            <link rel="alternate" href="Image Not Found" hreflang="IN" />
            <meta name="next-head-count" content="49" />
            <link rel="shortcut icon" sizes="16x16" href="../../public/assets/icon/favicon-16.png" />
            <link rel="shortcut icon" sizes="32x32" href="../../public/assets/icon/favicon-32.png" />
            <link rel="apple-touch-icon" sizes="57x57" href="../../public/assets/icon/favicon-57.png" />
            <link rel="apple-touch-icon" sizes="72x72" href="../../public/assets/icon/favicon-72.png" />
            <link rel="apple-touch-icon" sizes="114x114" href="../../public/assets/icon/favicon-114.png" />
            <link rel="apple-touch-icon" sizes="120x120" href="../../public/assets/icon/favicon-120.png" />
            <link rel="apple-touch-icon" sizes="128x128" href="../../public/assets/icon/favicon-128.png" />
            <link rel="apple-touch-icon" sizes="128x128" href="../../public/assets/icon/favicon-128.png" />
            <link rel="apple-touch-icon" sizes="144x144" href="../../public/assets/icon/favicon-144.png" />
            <link rel="apple-touch-icon" sizes="152x152" href="../../public/assets/icon/favicon-152.png" />
            <link rel="apple-touch-icon" sizes="167x167" href="../../public/assets/icon/favicon-167.png" />
            <link rel="apple-touch-icon" sizes="180x180" href="../../public/assets/icon/favicon-180.png" />
            <link rel="icon" sizes="128x128" href="../../public/assets/icon/favicon-128.png" />
            <link rel="icon" sizes="144x144" href="../../public/assets/icon/favicon-144.png" />
            <link rel="icon" sizes="152x152" href="../../public/assets/icon/favicon-152.png" />
            <link rel="icon" sizes="167x167" href="../../public/assets/icon/favicon-167.png" />
            <link rel="icon" sizes="180x180" href="../../public/assets/icon/favicon-180.png" />
            <link rel="icon" sizes="192x192" href="../../public/assets/icon/favicon-192.png" />
        </Helmet>
    )
}

export default Seo