import React from 'react'
import { notification } from 'antd';

const createNotification = (type, message, description) => {
    notification[type]({
        message,
        description,
    });
};
export default createNotification;
