import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";

class BlogDetails extends Component {

    constructor (props) {
        super (props)
    }

    render (){


        return (
            <div>
                <Breadcrumb title={'Blog - Details'}/> 


                {/*Blog Details section*/}
                <section className="blog-detail-page section-b-space">
<div className="container">                    
<div className="progression-single-container">
	<div className="progression-studios-feaured-image">
		<a> <img width="900" height="480" src="https://149354054.v2.pressablecdn.com/wp-content/uploads/2015/02/photodune-2417815-controls-computer-room-l-900x480.jpg" className="attachment-progression-studios-blog-index size-progression-studios-blog-index wp-post-image" alt="" loading="lazy" /> </a>
	</div>
	<div className="progression-blog-content progression-blog-single-content">
		<div className="progression-studios-blog-excerpt">
			<h2 className="progression-blog-title">Cross Browser Compatible</h2>
			<ul className="progression-post-meta">
				<li className="blog-meta-date-list"><a href="https://solus.progressionstudios.com/2015/02/">February 1, 2015</a></li>
				<li className="blog-meta-author-display">By <a href="https://solus.progressionstudios.com/author/progressionstudios/">Mike</a></li>
				<li className="blog-meta-category-list">In <a href="https://solus.progressionstudios.com/category/uncategorized/" rel="category tag">Uncategorized</a></li>
				<li className="blog-meta-comments"><i className="fa fa-comments" aria-hidden="true"></i> <a href="https://solus.progressionstudios.com/2015/02/cross-browser-compatible/#comments">1 comment</a></li>
			</ul>
			<div className="clearfix-pro"></div>
			<p>Always strive for better work. Never stop learning. Have fun a clear plan for a new project or just an idea on a napkin? &nbsp;Sky, land, and sea disappear together out of the world.</p>
			<p><span id="more-77"></span>The dawn breaks high behind the towering and serrated wall of the&nbsp;<em>Cordillera</em>, a clear-cut vision of dark peaks rearing their steep slopes on a lofty pedestal of forest rising from the very edge of the shore. Amongst them the white head of Higuerota rises majestically upon the blue. Bare clusters of enormous.</p>
			<h5>Above it all</h5>
			<p>The wasting edge of the&nbsp;<a href="#">cloud-bank always strives for</a>, but seldom wins, the middle of the gulf. The sun—<em>as the sailors say</em>—is eating it up. Unless perchance a sombre thunder-head breaks away from the main body to career all over the gulf till it escapes into the offing beyond Azuera, where it bursts suddenly.</p>
			<blockquote>
				<p>People think focus means saying yes to the thing you’ve got to focus on. But that’s not what it means at all. It means saying no to the hundred other good ideas that there are. You have to pick carefully.
					<br /> <em>Steve Jobs – Apple Worldwide Developers’ Conference, 1997</em></p>
			</blockquote>
			<p>At night the body of clouds advancing higher up the sky smothers the whole quiet gulf below with an impenetrable darkness, in which the sound of the falling showers can be heard beginning and ceasing&nbsp;abruptly—now here, now there. Indeed, these cloudy nights are proverbial with the seamen along the whole west coast of a great continent.</p>
			<ul>
				<li>The dawn breaks high behind the towering and serrated wall</li>
				<li>A clear-cut vision of dark peaks rearing their steep slopes</li>
				<li>Amongst them the white head of Higuerota</li>
				<li>Bare clusters of enormous rocks sprinkle with tiny black dots</li>
			</ul>
			<p>The gulf. The sun—as the sailors say—is eating it up. Unless perchance a sombre thunder-head breaks away from the main body to career all over the gulf till it escapes into the offing beyond Azuera, where it bursts suddenly into flame and crashes like a sinster pirate-ship of the air, hove-to above the horizon, engaging the sea.</p>
			<blockquote className="alignleft below-entry-meta">
				<p>Indeed, these cloudy nights are proverbial with the seamen along the whole west coast of a great continent.
					<br /> <em>John Doe</em></p>
			</blockquote>
			<p>At night the body of clouds advancing higher up the sky smothers the whole quiet gulf below with an impenetrable darkness, in which the sound of the falling showers can be heard beginning and ceasing abruptly—now here, now there. Indeed, these cloudy nights are proverbial with the seamen along the whole west coast of a great continent. Sky, land, and sea disappear together out of the world when the Placido—as the saying is—goes to sleep under its.
				<br /> The Indian girls, with hair like flowing black manes, and dressed only in a shift and short petticoat, stared dully from under the square-cut fringes on their foreheads; the noisy frizzling of fat had stopped, the fumes floated upwards in sunshine, a strong smell of burnt onions hung in the drowsy heat, enveloping the house; and the eye lost itself in a vast flat expanse of grass to the west, as if the plain between the Sierra overtopping Sulaco and the coast range away there towards Esmeralda had been as big as half the world.
				<br /> The Italian drivers saluted him from the foot-plate with raised hand, while the negro brakesmen sat carelessly on the brakes, looking straight forward, with the rims of their big hats flapping in the wind. In return Giorgio would give a slight sideways jerk of the head, without unfolding his arms.</p>
			<div className="clearfix-pro"></div>
			<div className="clearfix-pro"></div>
		</div>
	</div>
	<div id="comments" className="comments-area">
		<h4 className="comments-title">
			1 comment on “<span>Cross Browser Compatible</span>”		</h4>
		<ol className="comment-list">
			<li className="comment byuser comment-author-progressionstudios bypostauthor even thread-even depth-1" id="comment-13172">
				<div id="div-comment-13172" className="comment-body">
					<div className="comment-author vcard"> <img alt="" src="https://secure.gravatar.com/avatar/ee3843771ee603f94482fc2e9a0a7a81?s=80&amp;d=mm&amp;r=g" srcset="https://secure.gravatar.com/avatar/ee3843771ee603f94482fc2e9a0a7a81?s=160&amp;d=mm&amp;r=g 2x" className="avatar avatar-80 photo" height="80" width="80" loading="lazy" /> <cite className="fn">ProgressionStudios</cite> <span className="says">says:</span> </div>
					<div className="comment-meta commentmetadata"> <a href="https://solus.progressionstudios.com/2015/02/cross-browser-compatible/#comment-13172">September 21, 2018 at 4:47 am</a> </div>
					<p>Hi, this is a comment.
						<br /> To get started with moderating, editing, and deleting comments, please visit the Comments screen in the dashboard.</p>
					<div className="reply"><a rel="nofollow" className="comment-reply-link" href="https://solus.progressionstudios.com/2015/02/cross-browser-compatible/?replytocom=13172#respond" data-commentid="13172" data-postid="77" data-belowelement="div-comment-13172" data-respondelement="respond" data-replyto="Reply to ProgressionStudios" aria-label="Reply to ProgressionStudios">Reply</a></div>
				</div>
			</li>
		</ol>
		<div id="respond" className="comment-respond">
			<h3 id="reply-title" className="comment-reply-title">Leave a Reply </h3>
			<form id="commentform" className="comment-form">
				<p className="comment-notes"><span id="email-notes">Your email address will not be published.</span> Required fields are marked <span className="required">*</span></p>
				<div className="input-row">
                <p className="comment-form-comment full-column">
					<label htmlFor="comment">Comment</label>
					<textarea id="comment" name="comment" cols="45" rows="8" maxlength="65525" required="required"></textarea>
				</p>                
				<div className="half-column comment-form-author">
					<label htmlFor="author">Name <span className="required">*</span></label>
					<input id="author" name="author" type="text"  />
				</div>
				<div className="half-column comment-form-email">
					<label htmlFor="email">Email <span className="required">*</span></label>
					<input id="email" name="email" type="text"  />
				</div>
				<div className="half-column comment-form-url">
					<label htmlFor="url">Website</label>
					<input id="url" name="url" type="text" />
				</div>
				<div className="full-column form-submit">
					<input name="submit" type="submit" id="submit" className="submit" value="Post Comment" />
                </div>
                </div>                        
			</form>
		</div>
	</div>
	<div className="clearfix-pro"></div>
</div>
</div>

                    {/*
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 blog-detail">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/about/about%20us.jpg`} className="img-fluid" alt=""/>
                                <h3>Remaining essentially unchanged.</h3>
                                <ul className="post-social">
                                    <li>25 January 2018</li>
                                    <li>Posted By : Admin Admin</li>
                                    <li><i className="fa fa-heart"></i> 5 Hits</li>
                                    <li><i className="fa fa-comments"></i> 10 Comment</li>
                                </ul>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <p>Fusce scelerisque augue a viverra semper. Etiam nisi nibh, vestibulum quis augue id,
                                            imperdiet fringilla dolor. Nulla sed nisl vel nisi cursus finibus. Vivamus ut augue
                                            nec justo viverra laoreet. Nunc efficitur, arcu ac cursus gravida, lorem elit
                                            commodo urna, id viverra libero tellus non ipsum. Fusce molestie ultrices nibh
                                            feugiat pretium. Donec pulvinar arcu metus, et dapibus odio condimentum id. Quisque
                                            malesuada mauris sit amet dui feugiat, ut pretium mauris luctus. Ut aliquam, tellus
                                            nec molestie condimentum, tellus arcu dignissim quam, a gravida nunc nulla vel
                                            magna. Sed pulvinar tortor et euismod blandit. Proin accumsan orci ac nunc fermentum
                                            vehicula.</p>
                                    </div>
                                    <div className="col-sm-4">
                                        <p>Fusce scelerisque augue a viverra semper. Etiam nisi nibh, vestibulum quis augue id,
                                            imperdiet fringilla dolor. Nulla sed nisl vel nisi cursus finibus. Vivamus ut augue
                                            nec justo viverra laoreet. Nunc efficitur, arcu ac cursus gravida, lorem elit
                                            commodo urna, id viverra libero tellus non ipsum. Fusce molestie ultrices nibh
                                            feugiat pretium. Donec pulvinar arcu metus, et dapibus odio condimentum id. Quisque
                                            malesuada mauris sit amet dui feugiat, ut pretium mauris luctus. Ut aliquam, tellus
                                            nec molestie condimentum, tellus arcu dignissim quam, a gravida nunc nulla vel
                                            magna. Sed pulvinar tortor et euismod blandit. Proin accumsan orci ac nunc fermentum
                                            vehicula.</p>
                                    </div>
                                    <div className="col-sm-4">
                                        <p>Fusce scelerisque augue a viverra semper. Etiam nisi nibh, vestibulum quis augue id,
                                            imperdiet fringilla dolor. Nulla sed nisl vel nisi cursus finibus. Vivamus ut augue
                                            nec justo viverra laoreet. Nunc efficitur, arcu ac cursus gravida, lorem elit
                                            commodo urna, id viverra libero tellus non ipsum. Fusce molestie ultrices nibh
                                            feugiat pretium. Donec pulvinar arcu metus, et dapibus odio condimentum id. Quisque
                                            malesuada mauris sit amet dui feugiat, ut pretium mauris luctus. Ut aliquam, tellus
                                            nec molestie condimentum, tellus arcu dignissim quam, a gravida nunc nulla vel
                                            magna. Sed pulvinar tortor et euismod blandit. Proin accumsan orci ac nunc fermentum
                                            vehicula.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row section-b-space blog-advance">
                            <div className="col-lg-6">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/blog/1.jpg`} className="img-fluid" alt=""/>
                            </div>
                            <div className="col-lg-6">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/blog/2.jpg`} className="img-fluid" alt=""/>
                            </div>
                            <div className="col-lg-6">
                                <ul>
                                    <li> Donec ut metus sit amet elit consectetur facilisis id vel turpis.</li>
                                    <li> Aenean in mi eu elit mollis tincidunt.</li>
                                    <li> Etiam blandit metus vitae purus lacinia ultricies.</li>
                                    <li> Nunc quis nulla sagittis, faucibus neque a, tempus metus.</li>
                                    <li> In scelerisque libero ut mi ornare, a porttitor neque pulvinar.</li>
                                    <li> Morbi molestie lacus blandit interdum sodales.</li>
                                    <li> Curabitur eleifend velit molestie eleifend interdum.</li>
                                    <li> Vestibulum fringilla tortor et lorem sagittis,</li>
                                    <li> In scelerisque libero ut mi ornare, a porttitor neque pulvinar.</li>
                                    <li> Morbi molestie lacus blandit interdum sodales.</li>
                                    <li> Curabitur eleifend velit molestie eleifend interdum.</li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <p>Nulla quam turpis, commodo et placerat eu, mollis ut odio. Donec pellentesque egestas
                                    consequat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
                                    cubilia Curae; Nunc at urna dolor. Mauris odio nisi, rhoncus ac justo eget, lacinia
                                    iaculis lectus. Pellentesque id dapibus justo. Nunc venenatis non odio sed luctus.
                                    Etiam sit amet elit massa.</p>

                                <p>Phasellus quis lorem eros. Aliquam non tincidunt nibh. Nulla quis interdum neque.
                                    Mauris volutpat neque eu nunc ornare ullamcorper. Sed neque velit, lobortis eget
                                    tellus in, pellentesque ornare nibh. Vestibulum ante ipsum primis in faucibus orci
                                    luctus et ultrices posuere cubilia Curae; Maecenas rutrum nisi non nibh egestas
                                    lacinia. Cras ut tellus sit amet lacus consequat dictum nec id sapien. Ut
                                    pellentesque ac ex ut elementum. Morbi mollis volutpat neque eu volutpat.</p>

                                <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis
                                    fringilla bibendum at at leo. Proin molestie ac nisi eu laoreet. Integer faucibus
                                    enim nec ullamcorper tempor. Aenean nec felis dui. Integer tristique odio mi, in
                                    volutpat metus posuere eu. Aenean suscipit ipsum nunc, id volutpat lorem hendrerit
                                    ac. Sed id elit quam. In ac mauris arcu. Praesent eget lectus sit amet diam
                                    vestibulum varius. Suspendisse dignissim mattis leo, nec facilisis erat tempor quis.
                                    Vestibulum eu vestibulum ex. Maecenas luctus orci sed blandit fermentum. In
                                    vulputate eu metus a faucibus. Suspendisse feugiat condimentum congue.</p>
                            </div>
                        </div>
                        <div className="row section-b-space">
                            <div className="col-sm-12">
                                <ul className="comment-section">
                                    <li>
                                        <div className="media">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/avtar.jpg`} alt="Generic placeholder image" />
                                                <div className="media-body">
                                                    <h6>Mark Jecno <span>( 12 Jannuary 2018 at 1:30AM )</span></h6>
                                                    <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est
                                                        sit amet felis fringilla bibendum at at leo. Proin molestie ac
                                                        nisi eu laoreet. Integer faucibus enim nec ullamcorper tempor.
                                                        Aenean nec felis dui. Integer tristique odio mi, in volutpat
                                                        metus posuere eu. Aenean suscipit ipsum nunc, id volutpat lorem
                                                        hendrerit ac. Sed id elit quam. In ac mauris arcu. Praesent eget
                                                        lectus sit amet diam vestibulum varius. Suspendisse dignissim
                                                        mattis leo, nec facilisis erat tempor quis. Vestibulum eu
                                                        vestibulum ex. </p>
                                                </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="media">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/2.jpg`} alt="Generic placeholder image" />
                                                <div className="media-body">
                                                    <h6>Mark Jecno <span>( 12 Jannuary 2018 at 1:30AM )</span></h6>
                                                    <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est
                                                        sit amet felis fringilla bibendum at at leo. Proin molestie ac
                                                        nisi eu laoreet. Integer faucibus enim nec ullamcorper tempor.
                                                        Aenean nec felis dui. Integer tristique odio mi, in volutpat
                                                        metus posuere eu. Aenean suscipit ipsum nunc, id volutpat lorem
                                                        hendrerit ac. Sed id elit quam. In ac mauris arcu. Praesent eget
                                                        lectus sit amet diam vestibulum varius. Suspendisse dignissim
                                                        mattis leo, nec facilisis erat tempor quis. Vestibulum eu
                                                        vestibulum ex. </p>
                                                </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="media">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/20.jpg`} alt="Generic placeholder image" />
                                                <div className="media-body">
                                                    <h6>Mark Jecno <span>( 12 Jannuary 2018 at 1:30AM )</span></h6>
                                                    <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est
                                                        sit amet felis fringilla bibendum at at leo. Proin molestie ac
                                                        nisi eu laoreet. Integer faucibus enim nec ullamcorper tempor.
                                                        Aenean nec felis dui. Integer tristique odio mi, in volutpat
                                                        metus posuere eu. Aenean suscipit ipsum nunc, id volutpat lorem
                                                        hendrerit ac. Sed id elit quam. In ac mauris arcu. Praesent eget
                                                        lectus sit amet diam vestibulum varius. Suspendisse dignissim
                                                        mattis leo, nec facilisis erat tempor quis. Vestibulum eu
                                                        vestibulum ex. </p>
                                                </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="row blog-contact">
                            <div className="col-sm-12">
                                <h2>Leave Your Comment</h2>
                                <form className="theme-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <label htmlFor="name">Name</label>
                                            <input type="text" className="form-control" id="name"
                                                   placeholder="Enter Your name" required="" />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="email">Email</label>
                                            <input type="text" className="form-control" id="email" placeholder="Email"
                                                   required="" />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="exampleFormControlTextarea1">Comment</label>
                                            <textarea className="form-control" placeholder="Write Your Comment"
                                                      id="exampleFormControlTextarea1" rows="6"></textarea>
                                        </div>
                                        <div className="col-md-12">
                                            <button className="btn btn-solid" type="submit">Post Comment</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    */}
                </section>

            </div>
        )
    }
}

export default BlogDetails