import {
    GET_HOME_DATA
} from "../constants/ActionTypes";


const initialState = {
    homeData: {},
};

const homeDataReducers = (state = initialState, action) => {
    switch (action.type) {

        case GET_HOME_DATA:
            return {
                ...state,
                homeData: action.getHomeData
            };

        default:
            return state;
    }
};
export default homeDataReducers;