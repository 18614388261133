import {
    CHANGE_LOGIN_STATE,
    ADD_USER_DATA,
    REMOVE_USER_DATA,
    MANAGE_USER_ID_STATE,
    GET_WISHLIST_DATA_FROM_API,
    GET_CART_DATA_FROM_API,
    SET_MASTER_CATEGORY_LIST_DATA,
    SET_ALL_MASTER_CATEGORY_LIST_DATA,
    CATEGORY_DATA_ON_HOVER
} from "../constants/ActionTypes";


const initialState = {
    isUserLogin: false,
    userData: null,
    userID: null,
    userWishlistData: {
        data: []
    },
    userCartData: {
        records: {
            cart_list: []
        }

    },
    masterCategoryList: [],
    allMasterCategoryList: [],
    categoryDataWhenOnHover: []
};

const loginReducer = (state = initialState, action) => {

    switch (action.type) {
        case CHANGE_LOGIN_STATE:
            return {
                ...state,
                isUserLogin: action.value
            };
        case ADD_USER_DATA:
            return {
                ...state,
                userData: action.userDataObject
            }
        case REMOVE_USER_DATA:
            return {
                ...state,
                userData: null
            }
        case MANAGE_USER_ID_STATE: {

            if (action.userID == false) {
                return { ...state, userID: null }
            }
            else {
                return {
                    ...state,
                    userID: action.userID
                }
            }

        }
        case GET_WISHLIST_DATA_FROM_API: {

            var res = action.wishListDataObject

            if (res && res.status === 'Token is Invalid') {
                return state
            }
            else if (res && res.success == 1) {
                if (res && res.data && res.data.data.length > 0) {
                    return {
                        ...state,
                        // userWishlistData: res
                        userWishlistData: {
                            data: res.data.data
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        userWishlistData: {
                            data: []
                        }
                    }
                }
            }
            else {
                return {
                    ...state,
                    userWishlistData: {
                        data: []
                    }
                }
            }
        }

        case GET_CART_DATA_FROM_API: {

            var res = action.cartDataObject
            if (res && res.records && res.records.cart_list && res.records.cart_list.length > 0) {

                return {
                    ...state,
                    userCartData: res
                }
            }
            else {
                var temp = {
                    records: {
                        cart_list: []
                    }
                }
                return {
                    ...state,
                    userCartData: temp
                }
            }
        }
        // Set Master Categoty List Data
        case SET_MASTER_CATEGORY_LIST_DATA: {
            return {
                ...state,
                masterCategoryList: action.data
            }
        }
        // Set All Master Categoty List Data
        case SET_ALL_MASTER_CATEGORY_LIST_DATA: {
            return {
                ...state,
                allMasterCategoryList: action.data
            }
        }
        // Category Data On Hover
        case CATEGORY_DATA_ON_HOVER: {
            return {
                ...state,
                categoryDataWhenOnHover: action.data
            }
        }
        default:
            return state;
    }
};

export default loginReducer;