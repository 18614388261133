import React, { Component } from 'react';

import Breadcrumb from "../common/breadcrumb";
import { setRegister, } from '../../actions'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Seo from '../Seo';
class Register extends Component {

    constructor(props) {
        super(props)
        this.state = {
            lname: 'tesst',
            fname: 'divya',
            email: 'aa@aa.aa',
            password: '123456',
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);

    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    // handleSubmit(e) {
    //     e.preventDefault();

    //     this.setState({ submitted: true });
    //     const { username, password } = this.state;
    //     if (username && password) {
    //         this.props.login(username, password);
    //     }
    // }
    async setRegisterData() {
        var formdata = new FormData();
        formdata.append("email", this.state.email);
        formdata.append("password", this.state.password);
        formdata.append("name", this.state.fname);

        await this.props.setRegister(formdata)
    }

    render() {
        const { fname, lname, email, password, submitted } = this.state;


        return (
            <div>
                <Seo title="Register" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                <Breadcrumb title={'create account'} />

                {/*Regsiter section*/}
                <section className="register-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h3>create account</h3>
                                <div className="theme-card">
                                    <form className="theme-form">
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="email">First Name</label>
                                                <input type="text" className="form-control" id="fname" name="fname"
                                                    placeholder="First Name" required="" value={fname} onChange={this.handleChange} />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="email">Last Name</label>
                                                <input type="text" className="form-control" id="lname" name="lname"
                                                    placeholder="Last Name" required="" value={lname} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="email">email</label>
                                                <input type="text" className="form-control" id="email" name="email"
                                                    placeholder="Email" required="" value={email} onChange={this.handleChange} />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="review">Password</label>
                                                <input type="password" className="form-control" id="password" name="password"
                                                    placeholder="Enter your password" required="" value={password} onChange={this.handleChange} />
                                            </div>
                                            {/* <a href="#" className="btn btn-solid" onClick={()=>this.setRegisterData()}>create Account</a> */}
                                            <a className="btn btn-solid" onClick={() => this.setRegisterData()}>create Account</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {


}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setRegister: (params) => setRegister(params),


}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Register);