import {
    RECEIVE_SLIDER
} from "../constants/ActionTypes";


const initialState = {
    sliders: [],
};

const slidersReducer = (state = initialState, action) => {
    switch (action.type) {
       
        case RECEIVE_SLIDER:
            return {
                ...state,
                sliders: action.sliders
            };

        default:
            return state;
    }
};
export default slidersReducer;