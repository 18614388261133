import React, { Component, useState } from 'react';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { getCartData } from '../../actions'
import { base_url } from '../../helper';
import { NaswizHelperApi } from '../../NaswizHelper';
import Notification from '../../Notification'
import Seo from '../Seo';

class order_confirm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            arr_userOrder: [],
            _order_id: '',
            uniueId: '',
            transaction_dt: '',
            TransactionID: '',
            AtomTransaction : '',
            Payment:'',
            Banknm :'',
            Amount : '',
            Cmobile : '',
            CEmail : '',
            TStatus : '',
            SStatus : '',
        }
    }
    componentDidMount = async () => {
        this.props.location.search.split("&").map(async(search)=>{
            if (search.split("=")[0] === "?merchTxnDate") {
                var Transactiondt = search.split("=")[1];
                await  this.setState({
                    transaction_dt: Transactiondt,
                });
            }
            if (search.split("=")[0] === "merchTxnId") {
                await  this.setState({
                    TransactionID: search.split("=")[1],
                });
            }
            if (search.split("=")[0] === "atomTxnId") {
                await  this.setState({
                    AtomTransaction: search.split("=")[1],
                });
            }
            if (search.split("=")[0] === "payMode") {
                await  this.setState({
                    Payment: search.split("=")[1],
                });
            }
            if (search.split("=")[0] === "bankName") {
                await  this.setState({
                    Banknm: decodeURI(search.split("=")[1]),
                });
            }
            if (search.split("=")[0] === "totalAmount") {
                await  this.setState({
                    Amount: search.split("=")[1],
                });
            }
            if (search.split("=")[0] === "custMobile") {
                await  this.setState({
                    Cmobile: search.split("=")[1],
                });
            }
            if (search.split("=")[0] === "custEmail") {
                await  this.setState({
                    CEmail: search.split("=")[1],
                });
            }
            if (search.split("=")[0] === "txnStatus") {
                await  this.setState({
                    TStatus: search.split("=")[1],
                });
            }
            if (search.split("=")[0] === "txnDesc") {
                await  this.setState({
                    SStatus: decodeURI(search.split("=")[1]),
                });
            }
        })
        var orderid = await localStorage.getItem('order_id')
        var uniueId = await localStorage.getItem('deviceId')
        var payment_type = await localStorage.getItem('payment_type')
        var online_type = await localStorage.getItem('online_type')
        await this.setState({ uniueId: uniueId })
        await this.setState({ _order_id: orderid })
        if (orderid != '' && orderid != null) {
            if (online_type === 'atom') {
                await localStorage.setItem('payment_type', '')
                await localStorage.setItem('online_type','');
                var formData = new FormData()
                formData.append('order_id', orderid)
                // formData.append('device_id', uniueId)

                var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/check-payment-atom', formData, 'Error => v1/check-referral')
                // console.log("----=====", res)
                if (res.success === 1) {
                    Notification('success', 'Success!', res.message)
                    await localStorage.setItem('order_id', '')
                    await localStorage.removeItem('online_type', '')
                    this.getCartList()
                }
                await localStorage.setItem('payment_type', '');
            }
            else if (payment_type == 'online') {
                await localStorage.setItem('payment_type', '')
                await localStorage.setItem('online_type', '')
                var formData = new FormData()
                formData.append('order_id', orderid)
                formData.append('device_id', uniueId)

                var res = await NaswizHelperApi.POST_WITHOUT_TOKEN('v1/check-payment', formData, 'Error => v1/check-referral')

                if (res.success === 1) {
                    Notification('success', 'Success!', res.message)
                    await localStorage.setItem('order_id', '');
                    await localStorage.removeItem('online_type', '')
                    this.getCartList()
                } else {
                    Notification('error', 'Error!', '' + res.message)

                }
                await localStorage.setItem('payment_type', '');
                await localStorage.setItem('online_type','');
            }

        }
        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000); 
    }

    async getCartList() {
        var formdata = new FormData()
        formdata.append('device_id', this.state.uniueId)
        this.props.getCartData('v1/get-cart-list', formdata, "Error => GetCartList api.")

    }

    _getOrderList = async () => {

        var formData = new FormData()
        formData.append('device_id', this.state.uniueId)
        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/place-order', formData, "Error => v1/get-order-list api.")

        if (res && res.success === 1) {
            this.setState({
                arr_userOrder: res.records,
                loading_wallet: false
            })
        }
        else {
            this.setState({
                loading_wallet: true,
            })
        }
    }

    render() {
        return (
            <>
                <Seo title="Order-Confirm" description="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
            <div className="order-confirm-page">
                 <section className="section-b-space light-layout pb-0">
                    <div className="container">
                        <div className="row">
                            <div className={this.props.location.search ? `col-md-7` : `col-md-12`}>
                                <div className="success-text">
                                    <p className="small-title">THANK YOU FOR YOUR PURCHASE!</p>
                                    <h2>Your Order Has Been Received.</h2>
                                    <div className="content">
                                        <ul>
                                            <li>Your Order # is: <Link to="page/user/myorder">{this.state._order_id}.</Link></li>
                                            <li>You Will receive an Order confirmation email with datails of your order and a link to track its progress</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {this.props.location.search ?
                                <div className="col-md-5">     
                                    <ul className='transaction-box'>  
                                        {  
                                        this.state.transaction_dt ? 
                                            <li>
                                                <label>Transaction Date & Time:</label>
                                                <span>{this.state.transaction_dt}</span>
                                            </li>
                                        :null
                                        }
                                        {this.state.TransactionID ?
                                        <li>
                                            <label>Transaction ID:</label>
                                            <span>{this.state.TransactionID}</span>
                                        </li>
                                        :null
                                        }
                                        {this.state.AtomTransaction ?
                                        <li>
                                            <label>Atom Transaction No. :</label>
                                            <span>{this.state.AtomTransaction}</span>
                                        </li>
                                        :null
                                        }
                                        {this.state.Payment ?
                                        <li>
                                            <label>Payment Mode:</label>
                                            <span>{this.state.Payment}</span>
                                        </li>
                                        :null
                                        }
                                        {this.state.Banknm ?
                                        <li>
                                            <label>Bank Name:</label>
                                            <span>{this.state.Banknm}</span>
                                        </li>
                                        :null
                                        }
                                        {this.state.Amount ?
                                        <li>
                                            <label>Amount:</label>
                                            <span>{this.state.Amount}</span>
                                        </li>
                                        :null
                                        }
                                        {this.state.Cmobile ?
                                        <li>
                                            <label>Customer Mobile:</label>
                                            <span>{this.state.Cmobile}</span>
                                        </li>
                                        :null
                                        }
                                        {this.state.CEmail ?
                                        <li>
                                            <label>Customer Email Id:</label>
                                            <span>{this.state.CEmail}</span>
                                        </li>
                                        :null
                                        }
                                        {this.state.TStatus ?
                                            <li>
                                            <label>Transaction Status:</label>
                                            <span className={this.state.TStatus ==="SUCCESS" ? `badge badge-success`: `badge badge-danger`}>{this.state.TStatus}</span>
                                        </li>
                                        :null
                                        }
                                        {this.state.SStatus ?
                                        <li>
                                            <label>Status Desc:</label>
                                            <span>{this.state.SStatus}</span>
                                        </li>
                                        :null
                                        }
                                    </ul> 
                                </div>
                            :null
                            }
                        </div>
                    </div>
                </section>
            </div>
            </>
        )
    }


}
const mapStateToProps = (state) => ({
    isUserLogin: state.login.isUserLogin,
    cartListArray: state.login.userCartData.records.cart_list,

})

export default connect(mapStateToProps, { getCartData })(order_confirm)

