import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Breadcrumb from "../common/breadcrumb";
import { getCartTotal } from "../../services";
import { removeFromCart, incrementQty, decrementQty, getCartData, getCartDataWithoutLogin } from '../../actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Spin } from 'antd'
import { NaswizHelperApi } from '../../NaswizHelper';
import Notification from '../../Notification'

import carticon from '../../assets/images/emptycart.jpeg';
import Seo from '../Seo';

class cartComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoing: '',
            loading: true,
            uniueId: ''
        }
    }

    componentDidMount = () => {
        this.checkUserLoginStatus()
       /*  document.getElementById("common").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/common.css`);*/
        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000); 
        if (this.props.cartListArray.length > 0) {
            this.setState({
                loading: false
            })
        }


    }

    checkUserLoginStatus = () => {
        var isLoing = localStorage.getItem('is_user_login')
        var uniueId = localStorage.getItem('deviceId')
        this.setState({ isLoing: isLoing, uniueId: uniueId })
    }

    qtyUpdate = async () => {

    }

    handleClick = async (item, qty) => {
        var body = new FormData()
        body.append('cart_id', item.cart_id)
        body.append('quantity', item.total_quantity + qty)

        for (var pair of body.entries()) {
        }

        var res = await NaswizHelperApi.POST_WITH_TOKEN('v1/update-cart', body, "Error => v1/add-usercart")

        if (res && res.success == 1) {
            Notification('success', 'Success!', res.message ? res.message : 'Product update successfully.')
            if (this.props.isUserLogin) {
                var body = new FormData()
                body.append('device_id', this.state.uniueId)
                setTimeout(() => {
                    this.props.getCartData('v1/get-cart-list', body, "Error => GetCartList api.");
                }, 1000);

            } else {
                var body = new FormData()
                body.append('device_id', this.state.uniueId);
                setTimeout(() => {
                    this.props.getCartDataWithoutLogin('v1/get-cart-list-without-login', body, "Error => get-cart-list-without-login.")
                }, 1000);

            }

        }
        else {
            Notification('error', 'Error!', res.message ? res.message : 'Login First')
        }


    }
    deleteCart = async (item) => {


        var res = await NaswizHelperApi.GET_WITHOUT_TOKEN('v1/delete-cart/' + item.cart_id, body, "Error => v1/add-usercart")

        if (res && res.success == 1) {
            Notification('success', 'Success!', res.message ? res.message : 'Product deleted successfully.')
            if (this.props.isUserLogin) {
                var body = new FormData()
                body.append('device_id', this.state.uniueId)
                this.props.getCartData('v1/get-cart-list', body, "Error => GetCartList api.")

            } else {
                var body = new FormData()
                body.append('device_id', this.state.uniueId)
                this.props.getCartDataWithoutLogin('v1/get-cart-list-without-login', body, "Error => get-cart-list-without-login.")

            }

        }
        else {
            Notification('error', 'Error!', res.message ? res.message : 'Login First')
        }


    }

    render() {

        const { cartItems, symbol, total } = this.props;
        return (
            <div>
                {/* <Helmet>                
                <title>Shopping Cart -  Naswiz Retails Pvt Ltd</title>
                <meta name="og_title" property="og:title" content="Shopping Cart - Naswiz Retails Pvt Ltd"/>
                <meta name="twitter:title" content="Shopping Cart - Naswiz Retails Pvt Ltd" />
                <meta name="Keywords" content="Naswiz, Apparels, Personal Care, Packages/Offers, Herbal, Groceries, House Keeping" />
                <meta name="Description" content="Naswiz retail private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                <meta property="og:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />
                <meta name="twitter:description" content="Naswiz retail private limited is indian based direct sales company. The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company." />		
                </Helmet> */}

                <Seo title="Shopping Cart" description="Naswiz retails private limited is indian based direct sales company.  The company works on binary system. To associate with company is very easy but only after recommended by a person who is  already a direct seller in this company."  />
                {/*SEO Support End */}

                {/* <Breadcrumb title={'Cart Page'} /> */}

                {

                    this.state.loading ?
                        <div className="emptycart-icon">
                            <img src={carticon} alt="" style={{ marginTop: '50px' }} />
                            {/* <h3>
                                    Your Cart Is Empty
                                    </h3> */}
                        </div> :
                        this.props.cartTotalArray ?
                            <div className="gap_genrate">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="whole_cart_left">
                                                <div className="crt_lrt">
                                                    <div className="left_txt_crt">
                                                        <h4>My Basket <span>({this.props.cartListArray.length} items)</span></h4>
                                                    </div>
                                                    <div className="right_txt_crt">
                                                        <h4>{this.props.cartTotalArray.format_discounted_priceINR}</h4>
                                                    </div>
                                                </div>
                                                {
                                                    this.props.cartListArray && this.props.cartListArray.length > 0 ?
                                                        this.props.cartListArray.map((item, index) => {
                                                            return (
                                                                <div className="inside_crt">
                                                                    <div className="media_cart">
                                                                        <Link to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.slug}/${item.slug}`, state: [{ 'size': item.size, 'color': item.color_name, 'size_id': item.size_id, 'color_id': item.color_id }] }}>
                                                                            <img src={`${item.image}`} alt="product" />
                                                                        </Link>
                                                                    </div>
                                                                    <div className="list_iten_cart">

                                                                        <div className="cart_content">
                                                                            <h3 className="product-name">  
                                                                                <Link to={{ pathname: `${process.env.PUBLIC_URL}/product-detail/${item.slug}/${item.slug}`, state: [{ 'size': item.size, 'color': item.color_name, 'size_id': item.size_id, 'color_id': item.color_id }] }}>{item.product_name} </Link>
                                                                            </h3>
                                                                            <p className="price-list">
                                                                                <span className="product-price" >₹{item.price}</span>
                                                                                <span className="old-price">₹{item.main_price}</span>
                                                                                <span className="off-price">You Save ₹{Number(item.main_price - item.price)}</span>
                                                                            </p>

                                                                            {item.color_name != 'No' ? 
                                                                                <div className="product-filter">Color : <span>{item.color_name}</span></div>
                                                                             : null}

                                                                            {item.size != 'No' ? 
                                                                             <div className="product-filter">Size : <span>{item.size}</span></div>                                                                            
                                                                            : null}

                                                                            <div className="product-filter">IP Point <span>{item.total_incentive_points}</span></div>                                                                            
                                                                        </div>

                                                                    </div> 
                                                                    <div className="increase_product circle_mp">
                                                                        <div className="product-action">
                                                                            <div className="def-number-input number-input">
                                                                                <button className="minus" onClick={() => {
                                                                                    if (item.total_quantity > 1) {
                                                                                        this.handleClick(item, -1)
                                                                                    } else {
                                                                                        this.deleteCart(item)
                                                                                    }
                                                                                }}></button>
                                                                                <input className="quantity" name="quantity" type="number" value={item.total_quantity} />
                                                                                <button className={item.total_quantity == item.max_order_qty ? "plus disable" : "plus"} onClick={() => {
                                                                                    if (item.total_quantity == item.max_order_qty) {
                                                                                    } else {
                                                                                        this.handleClick(item, 1)
                                                                                    }
                                                                                }}></button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="cart-deleteicon"> 
                                                                            <i className="fas fa-trash-alt"  onClick={() => this.deleteCart(item)}></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }) : null
                                                }

                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="process_cart">
                                                <ul>
                                                    <li className="active"><a href="javascript:void(0);"><i className="fa fa-shopping-cart" aria-hidden="true"></i> Your Cart</a></li>
                                                    <li className="active"><a href="javascript:void(0);"><i className="fa fa-file" aria-hidden="true"></i> Order Summary</a></li>
                                                    <li><a href="javascript:void(0);"><i className="fa fa-credit-card" aria-hidden="true"></i> Payment</a></li>
                                                </ul>
                                            </div>
                                            <div className="right_cart_inside">
                                                <h4>Payment Details</h4>
                                                <ul>
                                                    <li>IP Points <span>{this.props.cartTotalArray.total_ip}</span></li>
                                                    <li>MRP Total <span>₹{this.props.cartTotalArray.main_price}</span></li>
                                                    <li>Discount <span>- ₹{this.props.cartTotalArray.discount_price}</span></li>
                                                    <li>Total Price <span>₹{this.props.cartTotalArray.price_without_shipping}</span></li>
                                                    <li>Delivery charges <label className='delivery-charges'>Delivery charges will be shown after you select the Delivery Address during checkout</label> <span>₹{this.props.cartTotalArray.shipping_charge}</span></li>
                                                    <li className="border-bottom"><label> Total Amount <span>{this.props.cartTotalArray.format_total_priceINR}</span> </label></li>
                                                    <li><label>Total IP <span>{this.props.cartTotalArray.total_ip}</span> </label></li>
                                                </ul>
                                            </div>
                                            <div className="crat_bt">
                                                <Link to={{ pathname: `${process.env.PUBLIC_URL}/checkout` }}>Place Order</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                            : <div className="emptycart-icon">
                                <img src={carticon} alt="" style={{ marginTop: '50px' }} />
                            </div>
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: '₹',
    total: getCartTotal(state.cartList.cart),
    cartListArray: state.login.userCartData.records.cart_list,
    cartTotalArray: state.login.userCartData.records.total,
    isUserLogin: state.login.isUserLogin,
})

export default connect(mapStateToProps, { removeFromCart, incrementQty, decrementQty, getCartData, getCartDataWithoutLogin })(cartComponent)